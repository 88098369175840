import { Stack } from '@mui/material';
import React from 'react';
import ProductionChart from '../dashboard/overview/ProductionChart';
// import ProdcutionReportTable from './ProdcutionReportTable';
import ProductionReportDetails from './ProductionReportDetails';

const ProductionReport = () => {
  return (
    <Stack>
      <Stack direction={{ lg: 'row', md: 'column' }} spacing={2}>
        <Stack width="100%" mx="auto">
          <ProductionChart reportTab={true} />
        </Stack>
      </Stack>

      {/*ProdcutionReportTable  disabled for now might be shown in future  */}
      {/* <Stack mt={2}>
        <ProdcutionReportTable />
      </Stack> */}

      <Stack mt={2}>
        <ProductionReportDetails />
      </Stack>
    </Stack>
  );
};

export default ProductionReport;
