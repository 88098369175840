import React, { useRef, useState } from 'react';
import {
  Box,
  Chip,
  CircularProgress,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { batchPhaseColor, getBatchStatus } from '../../../utils/batch';
import {
  ArticleTwoTone,
  CancelTwoTone,
  DeleteTwoTone,
  DoDisturbOnTwoTone as DoDisturbOn,
  DoneAllTwoTone,
  EditTwoTone,
  ExpandLessTwoTone as ExpandLess,
  ExpandMoreTwoTone as ExpandMore,
  PauseCircleTwoTone as PauseCircle,
  QrCodeTwoTone as QrIcons,
  PrecisionManufacturingTwoTone,
  PrintTwoTone,
  ReceiptTwoTone as Receipt,
  TimelineTwoTone,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import BatchApproveModal from './BatchApproveModal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  deleteBatch,
  rejectBatch,
  validateBatchApproval,
  changeBatchStatus,
} from '../../../api/batch';
import { formatTime, milisecondsToTime } from '../../../utils/time';
import { formatNumber } from '../../../utils/number';
import {
  BATCH,
  BATCH_CARD_PRINT,
  BATCH_EDIT,
  BATCH_REPORT_PRINT,
} from '../../../constants/routeUrls';
import Button from '../../../widgets/Button';
import ConcentModal from '../ConcentModal';
import { successNoitif } from '../../../utils/notification';
import BatchRemarksDrawer from './BatchRemarksDrawer';
import MachineReAssignModel from './MachineReAssignModel';
import colors from '../../../constants/colors';
import { useReactToPrint } from 'react-to-print';
import LogInfo from './timeline/LogInfo';

const PushButton = styled(IconButton)({
  backgroundColor: '#171820',
  color: '#fff',
  borderRadius: 5,
  '&:hover': {
    backgroundColor: '#303030',
  },
  '& MuiIconButton-root.Mui-disabled': {
    backgroundColor: '#e7e7e7',
  },
});

const BasicInfo = ({ batch, update, socket }) => {
  const roles = useSelector((state) => state.auth.user.roles);
  const { lotNumber } = useParams();
  const navigate = useNavigate();

  const [statusChangeMenuAnchor, setStatusChangeMenuAnchor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validationFlags, setValidationFLags] = useState(null);
  const [showBatchApproveModal, setShowBatchApproveModal] = useState(false);
  const [showBatchRejectionModal, setShowBatchRejectionModal] = useState(false);
  const [showBatchDeleteModal, setShowBatchDeleteModal] = useState(false);
  const [selectedChangeStatus, setSelectedChangeStatus] = useState(null);
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
  const [showBatchRemarksDrawer, setShowBatchRemarksDrawer] = useState(false);
  const [showMachineChangeModal, setShowMachineChangeModal] = useState(false);
  const [showBatchLogsModal, setShowBatchLogModal] = useState(false);

  const openStatusChangeDropDown = Boolean(statusChangeMenuAnchor);

  const handleCloseStatusChangeMenu = () => {
    setStatusChangeMenuAnchor(null);
  };

  const handleOpenStatusChangeMenu = (event) =>
    setStatusChangeMenuAnchor(event.currentTarget);

  const isApprover = () => {
    const approvers = ['ADMIN', 'SUPER ADMIN', 'MANAGER'];
    const rolesArr = roles.split(',');

    let isApprover = true;

    rolesArr.map((item) => {
      if (approvers.includes(item)) {
        isApprover = false;
      }
    });

    return isApprover;
  };

  const handleBatchApproveValidation = async () => {
    setIsLoading(true);
    const response = await validateBatchApproval(lotNumber);
    setIsLoading(false);

    if (!response) {
      return;
    }

    setValidationFLags(response);
    setShowBatchApproveModal(true);
  };

  const totalStandardTime = () => {
    const total = batch?.taskInfo?.tasks?.reduce((prev, task) => {
      const hour = task.standardTimeHour ? task.standardTimeHour : 0;
      const minute = task.standardTimeMinute ? task.standardTimeMinute / 60 : 0;

      const durationInHour = hour + minute;

      const hourToMs = durationInHour * 60 * 60 * 1000;

      return prev + hourToMs;
    }, 0);

    return total;
  };

  const calculateWaitingTime = () => {
    let totalWaiting = 0;

    if (batch?.taskInfo?.tasks?.length < 0) {
      return totalWaiting;
    }

    const filteredTasks = batch?.taskInfo?.tasks?.filter(
      (task) => task.taskType !== 'DYEING' && task.status === 'DONE',
    );

    let index = 0;
    filteredTasks.map((task) => {
      if (index < filteredTasks.length) {
        const nextTask = filteredTasks[index + 1];
        if (nextTask) {
          const completeTime = new Date(task.completeTime);
          const startTime = new Date(nextTask.startTime);
          const waitingTime = Math.abs(
            startTime.getTime() - completeTime.getTime(),
          );

          totalWaiting += waitingTime;
        }
      }
      index++;
    });
    return totalWaiting;
  };

  const SmallStatCard = ({ title, content, bgcolor }) => {
    return (
      <Stack
        sx={{ mr: 1, mb: 0.5 }}
        spacing={1}
        px={1}
        py={1}
        borderRadius={2}
        border={2}
        borderColor="rgba(0,0,0,0.1)"
        minWidth={100}
        bgcolor={bgcolor}>
        <Typography fontSize="0.75rem" color="grey.600" fontWeight={500}>
          {title}
        </Typography>
        <Typography fontWeight={700} fontSize={13}>
          {content}
        </Typography>
      </Stack>
    );
  };

  const shouldShowEditButton = () => {
    const editableStatus = ['IN QUEUE', 'DRAFT', 'WAITING FOR APPROVAL'];

    if (editableStatus.includes(batch?.status)) {
      return true;
    }
    return false;
  };

  const shouldShowDeleteButton = () => {
    const deleteableStatus = ['IN QUEUE', 'DRAFT', 'WAITING FOR APPROVAL'];

    if (deleteableStatus.includes(batch?.status)) {
      return true;
    }
    return false;
  };

  const shouldShowBatchStatusChangeDropDown = () => {
    const changeableStatus = ['IN QUEUE', 'STARTED', 'ON HOLD', 'IS PAUSED'];

    if (changeableStatus.includes(batch?.status)) {
      return true;
    }

    return false;
  };

  const handleBatchRejection = async () => {
    const response = await rejectBatch(lotNumber);
    if (response) {
      socket.emit('batch details', { lotNumber });
      successNoitif(response);
      return;
    }
  };

  const handleBatchDelete = async () => {
    const response = await deleteBatch(lotNumber);
    if (response) {
      navigate(BATCH);
      successNoitif(response);
      return;
    }
  };

  const handleStatusChange = async (reason) => {
    const response = await changeBatchStatus(
      lotNumber,
      selectedChangeStatus,
      reason,
    );
    if (response) {
      socket.emit('batch details', { lotNumber });
      successNoitif(`${response} to ${selectedChangeStatus}`);
      setSelectedChangeStatus(null);
      return;
    }
  };

  const shouldShowMachineReAssignButton = () => {
    const showStatuses = [
      'DRAFT',
      'WAITING FOR APPROVAL',
      'ON HOLD',
      'IS PAUSED',
    ];

    if (showStatuses.includes(batch.status)) {
      return true;
    }

    return false;
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Box
      flex={0.5}
      minWidth={300}
      border={2}
      borderRadius={3}
      borderColor="rgba(0,0,0,0.1)"
      minHeight={315}
      height="100%">
      <BatchApproveModal
        show={showBatchApproveModal}
        onClose={() => setShowBatchApproveModal(false)}
        validationFlags={validationFlags}
        update={update}
        socket={socket}
      />

      <BatchRemarksDrawer
        show={showBatchRemarksDrawer}
        onClose={() => setShowBatchRemarksDrawer(false)}
        batch={batch}
        socket={socket}
      />
      <MachineReAssignModel
        machine={batch.machineInfo}
        totalWeight={batch.totalFabricWeight}
        show={showMachineChangeModal}
        onClose={() => setShowMachineChangeModal(false)}
        socket={socket}
      />
      <ConcentModal
        show={showBatchRejectionModal}
        onClose={() => setShowBatchRejectionModal(false)}
        title="Reject Batch"
        text="Are you sure you want to reject this batch?"
        submissionHandler={handleBatchRejection}
      />
      <ConcentModal
        show={showBatchDeleteModal}
        onClose={() => setShowBatchDeleteModal(false)}
        title="Delete Batch"
        text="Are you sure you want to delete this batch?"
        submissionHandler={handleBatchDelete}
      />
      <ConcentModal
        show={showStatusChangeModal}
        onClose={() => setShowStatusChangeModal(false)}
        title="Change Batch Status"
        text={`Are you sure you want to change batch status to "${selectedChangeStatus}?"`}
        submissionHandler={handleStatusChange}
        showReasonCaptureField={selectedChangeStatus === 'ON HOLD'}
      />
      <Drawer
        open={showBatchLogsModal}
        anchor="right"
        sx={{ zIndex: 10000 }}
        onClose={() => setShowBatchLogModal(false)}>
        <Stack width={400} bgcolor="#fff" height="100%">
          <LogInfo logs={batch?.batchLogInfo} />
        </Stack>
      </Drawer>
      <Stack width="100%" height="100%" spacing={1}>
        <Stack width="100%" spacing={1} px={2} pt={1.5}>
          <Stack spacing={2} direction="row">
            <Box
              px={2}
              py={1.5}
              bgcolor="#EAF6F6"
              borderRadius={2}
              sx={{
                width: 'max-content',
                height: 'max-content',
              }}>
              {batch?.id ? (
                <QRCode size={80} value={batch.id.toString()} />
              ) : (
                <CircularProgress />
              )}
            </Box>

            <Stack display="none" width="100%" heigh="100%">
              <Stack
                ref={componentRef}
                spacing={1}
                width="100%"
                heigh="100%"
                direction="column"
                alignItems="center"
                justifyContent="center">
                <QRCode size={250} value={batch.id.toString()} />
                <Typography> Batch #{batch.lotNumber.split('-')[1]}</Typography>
              </Stack>
            </Stack>

            <Stack direction="row" width="100%" justifyContent="space-between">
              <Stack spacing={0.5}>
                <Typography fontSize={18} fontWeight={800}>
                  Batch #{batch.lotNumber.split('-')[1]}
                </Typography>
                <Typography fontSize="0.85rem" fontWeight={700}>
                  Weight {formatNumber(batch.totalFabricWeight)} Kg
                </Typography>
                <Stack direction="row" flexWrap="wrap">
                  {batch?.shiftInfo && (
                    <Chip
                      label={batch?.shiftInfo?.name}
                      size="small"
                      color="error"
                      sx={{ mr: 0.6, mb: 0.6 }}
                    />
                  )}
                  {batch?.taskInfo && (
                    <Chip
                      label={batch?.taskInfo?.batchPhase}
                      size="small"
                      color={batchPhaseColor(batch?.taskInfo?.batchPhase)}
                      sx={{ mr: 0.6, mb: 0.6 }}
                    />
                  )}
                  <Chip
                    color={getBatchStatus(batch.status).color}
                    label={getBatchStatus(batch.status).label}
                    size="small"
                    sx={{ maxWidth: 'max-content', mr: 0.6, mb: 0.6 }}
                  />
                  <Chip
                    label={batch.batchType}
                    size="small"
                    sx={{ mr: 0.6, mb: 0.6 }}
                  />
                  <Chip
                    label={batch.finishingType}
                    size="small"
                    sx={{ mr: 0.6, mb: 0.6 }}
                  />
                </Stack>
              </Stack>

              <Stack spacing={2}>
                {shouldShowBatchStatusChangeDropDown() && (
                  <Stack>
                    <Button
                      variant="contained"
                      id="basic-button"
                      aria-controls={
                        openStatusChangeDropDown ? 'basic-menu' : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        openStatusChangeDropDown ? 'true' : undefined
                      }
                      endIcon={
                        openStatusChangeDropDown ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      }
                      onClick={handleOpenStatusChangeMenu}>
                      Change Status
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={statusChangeMenuAnchor}
                      open={openStatusChangeDropDown}
                      onClose={handleCloseStatusChangeMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      {batch?.status !== 'IN QUEUE' && (
                        <MenuItem
                          onClick={() => {
                            setShowStatusChangeModal(true);
                            setSelectedChangeStatus('IN QUEUE');
                            handleCloseStatusChangeMenu();
                          }}>
                          Push To Queue
                        </MenuItem>
                      )}
                      {batch?.status !== 'ON HOLD' && (
                        <MenuItem
                          onClick={() => {
                            setShowStatusChangeModal(true);
                            setSelectedChangeStatus('ON HOLD');
                            handleCloseStatusChangeMenu();
                          }}>
                          Halt
                        </MenuItem>
                      )}
                      {batch?.status !== 'IS PAUSED' && (
                        <MenuItem
                          onClick={() => {
                            setShowStatusChangeModal(true);
                            setSelectedChangeStatus('IS PAUSED');
                            handleCloseStatusChangeMenu();
                          }}>
                          Pause
                        </MenuItem>
                      )}
                    </Menu>
                  </Stack>
                )}
                <Stack
                  spacing={0.5}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end">
                  <Tooltip title="View Batch Logs" arrow placement="bottom">
                    <PushButton
                      size="small"
                      onClick={() => setShowBatchLogModal(true)}
                      sx={{
                        backgroundColor: '#0D4C92',
                        ':hover': {
                          backgroundColor: '#0D4C92',
                        },
                      }}>
                      <TimelineTwoTone />
                    </PushButton>
                  </Tooltip>
                  {batch?.status === 'WAITING FOR APPROVAL' && (
                    <Tooltip
                      title="Approve this batch"
                      arrow
                      placement="bottom">
                      <PushButton
                        size="small"
                        sx={{
                          backgroundColor: colors.success,
                          ':hover': {
                            backgroundColor: '#7FB77E',
                          },
                        }}
                        disabled={isApprover()}
                        onClick={handleBatchApproveValidation}>
                        {isLoading ? <CircularProgress /> : <DoneAllTwoTone />}
                      </PushButton>
                    </Tooltip>
                  )}
                  {batch?.status === 'IN QUEUE' ||
                  batch?.status === 'STARTED' ? (
                    <Tooltip
                      title="Print Batch QR Code"
                      arrow
                      placement="bottom">
                      <PushButton
                        size="small"
                        onClick={handlePrint}
                        sx={{
                          backgroundColor: colors.secondary,
                          ':hover': {
                            backgroundColor: '#817ac2',
                          },
                        }}>
                        <QrIcons />
                      </PushButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}

                  {batch?.isComplete && (
                    <Tooltip
                      title="Print Batch Report"
                      arrow
                      placement="bottom">
                      <PushButton
                        size="small"
                        component={Link}
                        to={`${BATCH_REPORT_PRINT}/${batch?.lotNumber}`}
                        target="_blank"
                        sx={{
                          backgroundColor: '#2e7d32',
                          ':hover': {
                            backgroundColor: '#7FB77E',
                          },
                        }}>
                        <PrintTwoTone />
                      </PushButton>
                    </Tooltip>
                  )}
                  {batch?.isApproved && (
                    <Tooltip title="Print Batch Card" arrow placement="bottom">
                      <PushButton
                        size="small"
                        component={Link}
                        to={`${BATCH_CARD_PRINT}/${batch?.lotNumber}`}
                        target="_blank"
                        sx={{
                          backgroundColor: '#4562f7',
                          ':hover': {
                            backgroundColor: '#4562ff',
                          },
                        }}>
                        <ArticleTwoTone />
                      </PushButton>
                    </Tooltip>
                  )}
                  {shouldShowMachineReAssignButton() && (
                    <Tooltip
                      title="Change Assigned Machine"
                      arrow
                      placement="bottom">
                      <PushButton
                        size="small"
                        sx={{
                          backgroundColor: '#4562f7',
                          ':hover': {
                            backgroundColor: '#3AB4F2',
                          },
                        }}
                        disabled={isApprover()}
                        onClick={() => setShowMachineChangeModal(true)}>
                        <PrecisionManufacturingTwoTone />
                      </PushButton>
                    </Tooltip>
                  )}

                  <Tooltip title="Update Remarks" arrow placement="bottom">
                    <PushButton
                      size="small"
                      sx={{
                        backgroundColor: '#4562f7',
                        ':hover': {
                          backgroundColor: '#3AB4F2',
                        },
                      }}
                      disabled={isApprover()}
                      onClick={() => setShowBatchRemarksDrawer(true)}>
                      <Receipt />
                    </PushButton>
                  </Tooltip>

                  {shouldShowEditButton() && (
                    <Tooltip title="Edit Batch" arrow placement="bottom">
                      <PushButton
                        size="small"
                        sx={{
                          backgroundColor: '#4562f7',
                          ':hover': {
                            backgroundColor: '#3AB4F2',
                          },
                        }}
                        disabled={isApprover()}
                        component={Link}
                        to={`${BATCH_EDIT}/${batch?.lotNumber}`}>
                        <EditTwoTone />
                      </PushButton>
                    </Tooltip>
                  )}
                  {batch?.status !== 'REJECTED' && (
                    <Tooltip title="Reject Batch" arrow placement="bottom">
                      <PushButton
                        size="small"
                        sx={{
                          backgroundColor: '#F96666',
                          ':hover': {
                            backgroundColor: '#FA7070',
                          },
                        }}
                        onClick={() => setShowBatchRejectionModal(true)}
                        disabled={isApprover()}>
                        <CancelTwoTone />
                      </PushButton>
                    </Tooltip>
                  )}
                  {shouldShowDeleteButton() && (
                    <Tooltip title="Delete Batch" arrow placement="bottom">
                      <PushButton
                        size="small"
                        sx={{
                          backgroundColor: '#F96666',
                          ':hover': {
                            backgroundColor: '#FA7070',
                          },
                        }}
                        disabled={isApprover()}
                        onClick={() => setShowBatchDeleteModal(true)}>
                        <DeleteTwoTone />
                      </PushButton>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Typography fontSize={14}>
            <b> Scheduled At - </b>
            {formatTime(batch.loadingTime)}
          </Typography>
          {batch.actualStartTime && (
            <Typography fontSize={14}>
              <b> Loaded At - </b> {formatTime(batch.actualStartTime)}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" flexWrap="wrap" alignItems="center" px={2}>
          <SmallStatCard
            title="Machine"
            content={`${batch.machineInfo.name} (${(
              (batch.totalFabricWeight / batch.machineInfo.maxCapacity) *
              100
            ).toFixed()} %)`}
          />
          <SmallStatCard
            title="STD. Runtime"
            content={milisecondsToTime(totalStandardTime())}
          />
          <SmallStatCard
            title="EST. Completion"
            content={formatTime(batch.estEndTime)}
          />
          <SmallStatCard
            bgcolor="#FFD1D1"
            title="Delay"
            content={milisecondsToTime(batch.delay)}
          />
          <SmallStatCard
            title="Time Saved"
            content={milisecondsToTime(batch.early)}
          />
          <SmallStatCard
            title="Waiting Time"
            content={milisecondsToTime(calculateWaitingTime())}
          />
        </Stack>
        <Stack direction="row" flexWrap="wrap" alignItems="center" px={2}>
          {batch.creatorInfo && (
            <Chip
              label={`Creator: ${batch.creatorInfo?.name}`}
              sx={{ mb: 0.5, mr: 1, cursor: 'pointer' }}
              size="small"
            />
          )}
          {batch.approverInfo && (
            <Chip
              label={`Approver: ${batch.approverInfo?.name}`}
              sx={{ mb: 0.5, mr: 1, cursor: 'pointer' }}
              size="small"
            />
          )}
          {batch.rejectedByInfo && (
            <Chip
              label={`Rejected By: ${batch.rejectedByInfo?.name}`}
              sx={{ mb: 0.5, mr: 1, cursor: 'pointer' }}
              size="small"
            />
          )}
          {batch.haltedByInfo && (
            <Chip
              label={`Halted By: ${batch.haltedByInfo?.name}`}
              sx={{ mb: 0.5, mr: 1, cursor: 'pointer' }}
              size="small"
            />
          )}
          {batch.pasuedByInfo && (
            <Chip
              label={`Paused By: ${batch.pasuedByInfo?.name}`}
              sx={{ mb: 0.5, mr: 1, cursor: 'pointer' }}
              size="small"
            />
          )}
        </Stack>
        {batch?.status === 'ON HOLD' && (
          <Stack
            direction="row"
            spacing={1}
            bgcolor="#F96666"
            borderRadius="0 0 12px 12px"
            boxSizing="border-box"
            px={1}
            color="#171820"
            py={1}>
            <DoDisturbOn />
            <Typography fontSize={12}>
              Batch has been halted <b>By: {batch?.haltedByInfo?.name}</b>{' '}
              <br /> <b>Reason: {batch?.haltReason}</b>
            </Typography>
          </Stack>
        )}
        {batch?.status === 'IS PAUSED' && (
          <Stack
            direction="row"
            spacing={1}
            bgcolor="#F1A661"
            borderRadius="0 0 10px 10px"
            boxSizing="border-box"
            px={1}
            color="#272739"
            py={1}>
            <PauseCircle />
            <Typography>Batch has been paused</Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default BasicInfo;
