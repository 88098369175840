import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { fetchBatchProductionChartData } from '../../../api/batch';
import { FiberManualRecordTwoTone } from '@mui/icons-material';

const ProductionChart = ({ reportTab }) => {
  const [chartDateRange, setChartDateRange] = useState('week');

  const [stats, setStats] = useState();
  const [chartDataset, setChartDataSet] = useState([]);
  const [labels, setLabels] = useState([]);

  const fetchData = async () => {
    const response = await fetchBatchProductionChartData(chartDateRange);
    setStats(response.stats);
    setChartDataSet(response.datasets);
    setLabels(response.labels);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, [chartDateRange]);

  useEffect(() => {
    const interval = setInterval(
      async () => await fetchData(),
      15 * 60 * 10000,
    );

    return () => clearInterval(interval);
  }, []);

  const Stat = ({ label, content, bgcolor, dotColor, ...rest }) => {
    return (
      <Stack
        height="max-content"
        width="100%"
        maxWidth={200}
        border="2px solid rgba(0,0,0,0.2)"
        borderRadius={2}
        justifyContent="flex-start"
        alignItems="flex-start"
        bgcolor={bgcolor}
        {...rest}
        p={1}>
        <Stack direction="row" spacing={1}>
          <Typography fontSize={14} fontWeight={700}>
            {label}
          </Typography>
          <FiberManualRecordTwoTone sx={{ fill: dotColor || '#4562f7' }} />
        </Stack>
        <Typography fontWeight={800} fontSize={26} component="div">
          {content}
        </Typography>
      </Stack>
    );
  };

  const chartDateRangeValueMapper = () => {
    const values = {
      month: 'This month',
      year: 'This year',
      week: 'Last 7 days',
    };

    return values[chartDateRange];
  };

  return (
    <Stack
      width="100%"
      px={2}
      py={2}
      borderRadius={2}
      border="2px solid rgba(0,0,0,0.2)"
      height={reportTab ? 350 : 510}
      spacing={1}>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Typography variant="h6" fontWeight={800}>
          Production{' '}
          <span style={{ fontSize: 12, fontWeight: 600 }}>
            ({chartDateRangeValueMapper()})
          </span>
        </Typography>
        <Select
          size="small"
          defaultValue={chartDateRange}
          onChange={(event) => setChartDateRange(event.target.value)}>
          <MenuItem value="week">Last 7 days</MenuItem>
          <MenuItem value="month">This Month</MenuItem>
          <MenuItem value="year">This Year</MenuItem>
        </Select>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Stat
          label="Production (Kg)"
          content={stats?.production}
          dotColor="#7FB77E"
        />
        <Stat
          label="Reprocessed (Kg)"
          content={stats?.reProcessed}
          dotColor="#F1A661"
        />
        <Stat label="Processing (Kg)" content={stats?.processing} />
        <Stat
          label="Rejected (Kg)"
          content={stats?.rejected}
          dotColor="#FA7070"
        />
      </Stack>
      <Box width="100%" height={reportTab ? 200 : 350} mt={1}>
        {labels && chartDataset ? (
          <ResponsiveBar
            data={chartDataset}
            keys={[
              'Processing (Kg)',
              'Processed (Kg)',
              'Reprocessed (Kg)',
              'Rejected (Kg)',
            ]}
            indexBy="dates"
            margin={{ top: 30, right: 150, bottom: 80, left: 10 }}
            padding={0.4}
            innerPadding={2}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#4562f7', '#7FB77E', '#F1A661', '#FA7070']}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: chartDateRange === 'month' ? 90 : 0,
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={null}
            enableGridY={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={function (e) {
              return (
                e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
              );
            }}
          />
        ) : (
          <Stack>
            <Typography>Ops! Something Went Wrong</Typography>
            <Typography maxWidth={350}>
              Sorry the production chart could not be loaded
            </Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default ProductionChart;
