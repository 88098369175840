import { Stack, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import LiveTrackingIndex from '../../components/dashboard/live-tracking/LiveTrackingIndex';
import OverviewIndex from '../../components/dashboard/overview/OverviewIndex';
import TimeLineIndex from '../../components/dashboard/timeline/TimeLineIndex';
import PageTitle from '../../utils/PageTitle';
import UpcomingTasksIndex from '../../components/dashboard/upcoming-tasks/UpcomingTasksIndex';
import PageHeader from '../../widgets/PageHeader';

const Dashboard = ({ socket }) => {
  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (event, tab) => setActiveTab(tab);

  const tabs = [
    {
      Element: <OverviewIndex />,
    },
    {
      Element: <LiveTrackingIndex socket={socket} />,
    },
    {
      Element: <TimeLineIndex socket={socket} />,
    },
    {
      Element: <UpcomingTasksIndex socket={socket} />,
    },
  ];

  const currentTab = tabs[activeTab];

  return (
    <Stack
      width="100%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      spacing={2}
      px={2}
      py={1}>
      <PageTitle title="Dashboard" />
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <PageHeader>Dashboard</PageHeader>
        <Tabs value={activeTab} onChange={toggleTab}>
          <Tab
            label="Overview"
            sx={{
              textTransform: 'capitalize',
              padding: '15px',
              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#303030',
                borderRadius: '3px',
                transition: '0.3s ease',
              },
            }}
          />
          <Tab
            label="Live Tracking"
            sx={{
              textTransform: 'capitalize',
              padding: '15px',
              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#303030',
                borderRadius: '3px',
                transition: '0.3s ease',
              },
            }}
          />
          <Tab
            label="Timeline"
            sx={{
              textTransform: 'capitalize',
              padding: '15px',
              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#303030',
                borderRadius: '3px',
                transition: '0.3s ease',
              },
            }}
          />
          <Tab
            label="Upcoming Tasks"
            sx={{
              textTransform: 'capitalize',
              padding: '15px',
              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#303030',
                borderRadius: '3px',
                transition: '0.3s ease',
              },
            }}
          />
        </Tabs>
      </Stack>
      <Stack width="100%">{currentTab.Element}</Stack>
    </Stack>
  );
};

export default Dashboard;
