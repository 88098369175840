import { Divider, Grid, Stack, Typography } from '@mui/material';

import { formatNumber } from '../../utils/number';

const BatchOrderFabricDetails = ({ order, fabricList }) => {
  const findOrderFabrics = () => {
    let orderFabrics = [];
    fabricList.forEach((fabric) => {
      if (fabric.orderId === order.id) {
        orderFabrics.push(fabric);
      }
    });

    return orderFabrics;
  };

  const calculateFabricQuantity = () => {
    let totalQuantity = 0;
    findOrderFabrics().forEach((fabric) => {
      totalQuantity += fabric.batchFabricAmount;
    });
    return totalQuantity;
  };

  const calculateFabricAmount = () => {
    let totalAmount = 0;
    findOrderFabrics().forEach((fabric) => {
      totalAmount += fabric.batchFabricAmount * fabric.rate;
    });
    return totalAmount;
  };

  return (
    <>
      <Stack>
        {/* new design  */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction="column" justifyContent="flex-start">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography fontSize={15} sx={{ fontWeight: 'bold' }}>
                  #{order?.fcNumber} ({order?.partyName}),
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <Stack width="100%">
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={2}>
                  <Typography textAlign="center">FABRIC TYPE</Typography>
                </Grid>
                <Grid width="100%" item xs={1}></Grid>
                <Grid item xs={2}>
                  <Typography textAlign="center">QUANTITY(KG)</Typography>
                </Grid>
                <Grid width="100%" item xs={1}></Grid>
                <Grid item xs={2}>
                  <Typography textAlign="center">RATE</Typography>
                </Grid>
                <Grid width="100%" item xs={1}></Grid>
                <Grid item xs={3}>
                  <Typography textAlign="end">AMOUNT</Typography>
                </Grid>
              </Grid>
              {findOrderFabrics().map((fabric) => {
                return (
                  <Grid
                    container
                    spacing={1}
                    key={fabric?.id}
                    alignItems="center">
                    <Grid item xs={2}>
                      <Typography textAlign="center">
                        {fabric?.fabricType}
                      </Typography>
                    </Grid>
                    <Grid width="100%" item xs={1}>
                      <Stack
                        width="100%"
                        sx={{ background: 'gray', opacity: 0.2 }}>
                        <Divider></Divider>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography textAlign="center">
                        {fabric?.batchFabricAmount}{' '}
                      </Typography>
                    </Grid>
                    <Grid width="100%" item xs={1}>
                      <Stack
                        width="100%"
                        sx={{ background: 'gray', opacity: 0.2 }}>
                        <Divider></Divider>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography textAlign="center">
                        ৳{formatNumber(fabric?.rate)}
                      </Typography>
                    </Grid>
                    <Grid width="100%" item xs={2}>
                      <Stack
                        width="100%"
                        sx={{ background: 'gray', opacity: 0.2 }}>
                        <Divider></Divider>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography textAlign="end">
                        ৳
                        {formatNumber(fabric?.batchFabricAmount * fabric?.rate)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    mt={1}
                    width="100%"
                    sx={{ background: 'gray', opacity: 0.5 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography textAlign="end" fontWeight={700}>
                    Order Quantity:
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography textAlign="center" fontWeight={700}>
                    {calculateFabricQuantity()} KG
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography textAlign="end">
                    <Typography fontWeight={700} textAlign="end">
                      Order Amount:
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontWeight={700} textAlign="end">
                    ৳{formatNumber(calculateFabricAmount())}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default BatchOrderFabricDetails;
