import Dashboard from '../pages/dashboard/Dashboard';
import SignIn from '../pages/auth/SignIn';
import * as urls from './routeUrls';
import Batch from '../pages/batches/Batch';
import User from '../pages/user/User';
import Settings from '../pages/settings/Settings';
import Machine from '../pages/machine/Machine';
import Order from '../pages/order/Order';
import Client from '../pages/client/Client';
import ClientDetails from '../pages/client/ClientDetails';
import AddNewBatch from '../pages/batches/AddNewBatch';
import UpdateBatch from '../pages/batches/UpdateBatch';
import CreateQuotation from '../pages/client/CreateQuotation';
import EditQuotation from '../pages/client/EditQuotation';
import PrintQuotation from '../pages/client/PrintQuotation';
import CreateOrder from '../pages/order/CreateOrder';
import OrderDetails from '../pages/order/OrderDetails';
import CreateReProcessBatch from '../pages/batches/CreateReProcessBatch';
import BatchDetails from '../pages/batches/BatchDetails';
import UpdateOrder from '../pages/order/UpdateOrder';
import OrderDelivery from '../pages/order/OrderDelivery';
import UpdateDelivery from '../pages/order/UpdateDelivery';
import PrintDelivery from '../pages/order/PrintDelivery';
import Approval from '../pages/approval/Approval';
import MachineDetails from '../pages/machine/MachineDetails';

import StoreManagement from '../pages/store-management/StoreManagement';
import AddChemical from '../pages/store-management/AddChemical';
import StockDetailsView from '../pages/store-management/StockDetailsView';
import RecipeTemplating from '../pages/batches/RecipeTemplating';
import RecipePrintPreview from '../pages/batches/RecipePrintPreview';
import BatchReportPrintPreview from '../pages/batches/BatchReportPrintPreview';
import BatchCardPrintPreview from '../pages/batches/BatchCardPrintPreview';
import UserDetails from '../pages/user/UserDetails';
import AddChemicalStock from '../pages/store-management/AddChemicalStock';
import UtilityDetails from '../pages/settings/UtilityDetails';
import UserQRPrint from '../pages/user/UserQRPrint';
import TablePdfPreview from '../pages/global/TablePdfPreview';
import { MachinesQrPrint } from '../pages/machine/MachinesQrPrint';
import OrderCsvStepperForm from '../pages/order/OrderCsvStepperForm';
import UpdateBulkOrder from '../pages/order/UpdateBulkOrder';
import Account from '../pages/account/Account';
import OrderAccountDetails from '../pages/account/OrderAccountDetails';
import OrderAccountPdfToPrint from '../pages/account/OrderAccountPdfToPrint';
import BatchesQrPrint from '../pages/batches/BatchesQrPrint';
import BatchAccountDetails from '../components/account/BatchAccountDetails';
import UnAuthorizedPage from '../pages/global/UnAuthorizedPage';
import OrderBillDetails from '../pages/order/OrderBillDetails';
import Reports from '../pages/reports/Reports';

export const routes = [
  {
    isProtected: false,
    label: 'Signin',
    path: urls.SIGNIN,
    Element: SignIn,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Dashboard',
    path: urls.DASHBOARD,
    Element: Dashboard,
    isIndexedUrl: false,
  },

  // * BATCHES
  {
    isProtected: true,
    label: 'Batches',
    path: urls.BATCH,
    Element: Batch,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'New Batch',
    path: urls.BATCH_ADD,
    Element: AddNewBatch,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Re Process Batch',
    path: urls.RE_PROCESS_BATCH_CREATE,
    Element: CreateReProcessBatch,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'Update Batch',
    path: urls.BATCH_EDIT + '/:lotNumber',
    Element: UpdateBatch,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Update Batch',
    path: urls.BATCH_DETAILS + '/:lotNumber',
    Element: BatchDetails,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Configure Recipe',
    path: urls.RECIPE_MANAGEMENT + '/:lotNumber',
    Element: RecipeTemplating,
    isIndexedUrl: false,
  },

  // * USERS
  {
    isProtected: true,
    label: 'Users',
    path: urls.USERS,
    Element: User,
    isIndexedUrl: false,
  },
  // * SETTINGS
  {
    isProtected: true,
    label: 'Settings',
    path: urls.SETTINGS + '/:tab',
    Element: Settings,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Utility',
    path: urls.UTILITY_SETTINGS + '/:uid',
    Element: UtilityDetails,
    isIndexedUrl: false,
  },
  // * MACHINE
  {
    isProtected: true,
    label: 'Machine',
    path: urls.MACHINE,
    Element: Machine,
    isIndexedUrl: false,
  },

  //Machine Details
  {
    isProtected: true,
    label: 'Machine Details',
    path: urls.MACHINE_DETAILS + '/:name',
    Element: MachineDetails,
    isIndexedUrl: false,
  },

  // * ORDER
  {
    isProtected: true,
    label: 'Order',
    path: urls.ORDER,
    Element: Order,
    isIndexedUrl: false,
  },

  // ORDER CREATION
  {
    isProtected: true,
    label: 'Order',
    path: urls.CREATE_ORDER,
    Element: CreateOrder,
    isIndexedUrl: false,
  },
  //for ORDER DETAILS

  {
    isProtected: true,
    label: 'Order Details',
    path: urls.ORDER_DETAILS + '/:fcNumber' + '/:tab',
    Element: OrderDetails,
    isIndexedUrl: false,
  },

  // Clinet
  {
    isProtected: true,
    label: 'Order',
    path: urls.CLIENT,
    Element: Client,
    isIndexedUrl: false,
  },

  //for Client DETAILS
  {
    isProtected: true,
    label: 'Client Details',
    path: urls.CLIENT_DETAILS + '/:uid',
    Element: ClientDetails,
    isIndexedUrl: false,
  },

  //for edit order
  {
    isProtected: true,
    label: 'Edit Order',
    path: urls.EDIT_ORDER + '/:id',
    Element: UpdateOrder,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'Edit Bulk Order',
    path: urls.EDIT_BULK_ORDER + '/:id',
    Element: UpdateBulkOrder,
    isIndexedUrl: false,
  },

  //for create quotation
  {
    isProtected: true,
    label: 'Create Quotation',
    path: urls.CREATE_QUOTATION + '/:uid',
    Element: CreateQuotation,
    isIndexedUrl: false,
  },

  //for edit quotation
  {
    isProtected: true,
    label: 'Create Quotation',
    path: urls.EDIT_QUOTATION + '/:id',
    Element: EditQuotation,
    isIndexedUrl: false,
  },

  //for Print quotation
  {
    isProtected: true,
    label: 'Create Quotation',
    path: urls.PRINT_QUOTATION + '/:id',
    Element: PrintQuotation,
    isIndexedUrl: false,
  },

  //for Order Delivery
  {
    isProtected: true,
    label: 'Order Delivery',
    path: urls.ORDER_DELIVERY + '/:fcNumber',
    Element: OrderDelivery,
    isIndexedUrl: false,
  },

  //for update Delivery

  {
    isProtected: true,
    label: 'Order Delivery',
    path: urls.UPDATE_DELIVERY + '/:deliveryId',
    Element: UpdateDelivery,
    isIndexedUrl: false,
  },

  //for Print Delivery
  {
    isProtected: true,
    label: 'Create Quotation',
    path: urls.PRINT_DELIVERY + '/:deliveryId',
    Element: PrintDelivery,
    isIndexedUrl: false,
  },

  //for approval

  {
    isProtected: true,
    label: 'Approval',
    path: urls.APPROVAL + '/:tab',
    Element: Approval,
    isIndexedUrl: false,
  },
  // * Store Management
  {
    isProtected: true,
    label: 'Store Management',
    path: urls.STORE_MANAGEMENT + '/:tab',
    Element: StoreManagement,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Add Inventory',
    path: urls.ADD_CHEMICAL,
    Element: AddChemical,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'Add Inventory',
    path: urls.ADD_CHEMICAL_STOCK + '/:sku',
    Element: AddChemicalStock,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'Inventory Detials',
    path: urls.STOCK_DETAILS + '/:sku',
    Element: StockDetailsView,
    isIndexedUrl: false,
  },

  // * PRINT
  {
    isProtected: true,
    label: 'Recipe Print Preview',
    path: urls.RECIPE_PRINT + '/:lotNumber',
    Element: RecipePrintPreview,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'Batch Report Print Preview',
    path: urls.BATCH_REPORT_PRINT + '/:lotNumber',
    Element: BatchReportPrintPreview,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'Batch Card Print Preview',
    path: urls.BATCH_CARD_PRINT + '/:lotNumber',
    Element: BatchCardPrintPreview,
    isIndexedUrl: false,
  },

  //for machine details
  //for Client DETAILS
  {
    isProtected: true,
    label: 'User Details',
    path: urls.USER_DETAILS + '/:uid',
    Element: UserDetails,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'Print User QR',
    path: urls.PRINT_USERQR,
    Element: UserQRPrint,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Print Machines QR',
    path: urls.PRINT_MACHINEQR,
    Element: MachinesQrPrint,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Print Batches QR',
    path: urls.PRINT_BATCHESQR,
    Element: BatchesQrPrint,
    isIndexedUrl: false,
  },
  {
    isProtected: true,
    label: 'Print Table',
    path: urls.PRINT_TABLE,
    Element: TablePdfPreview,
    isIndexedUrl: false,
  },

  {
    isProtected: true,
    label: 'Print Payment Table',
    path: urls.PRINT_ACCOUNT_TABLE,
    Element: OrderAccountPdfToPrint,
    isIndexedUrl: false,
  },

  //for upload csv

  {
    isProtected: true,
    label: 'Print Table',
    path: urls.UPLOAD_ORDER,
    Element: OrderCsvStepperForm,
    isIndexedUrl: false,
  },

  //for account
  {
    isProtected: true,
    label: 'Account',
    path: urls.ACCOUNT + '/:tab',
    Element: Account,
    isIndexedUrl: false,
  },

  //for account details
  {
    isProtected: true,
    label: 'Accounts Details',
    path: urls.ACCOUNT_ORDER_DETAILS + '/:uid' + '/:tab',
    Element: OrderAccountDetails,
    isIndexedUrl: false,
  },

  //for account  delivery details
  {
    isProtected: true,
    label: 'Account Delivery Details',
    path: urls.ACCOUNT_DELIVERY_DETAILS + '/:uid',
    Element: OrderBillDetails,
    isIndexedUrl: false,
  },

  //for batch account details
  {
    isProtected: true,
    label: 'Batch Accounts Details',
    path: urls.ACCOUNT_BATCH_DETAILS + '/:lotNumber',
    Element: BatchAccountDetails,
    isIndexedUrl: false,
  },
  {
    isProtected: false,
    label: 'Unauthorized',
    path: urls.UNAUTHORIZED,
    Element: UnAuthorizedPage,
  },

  {
    isProtected: true,
    label: 'Account',
    path: urls.REPORT + '/:tab',
    Element: Reports,
    isIndexedUrl: false,
  },
];
