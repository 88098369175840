import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { updateClient } from '../../api/client';
import { successNoitif } from '../../utils/notification';
import { ClientCreationValidator } from '../../validators/client';
import Button from '../../widgets/Button';

const EditClient = ({ show, client, toggleModal, setIsUpdated }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onClose = () => {
    handleUpdateFormClose();
  };

  const resolver = yupResolver(ClientCreationValidator);
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      uid: client?.uid,
      name: '',
      shortCode: '',
      contactPerson: '',
      contactNumber: '',
      status: '',
    },
    resolver,
  });

  const handleUpdateFormClose = () => {
    reset({
      uid: client?.uid,
      name: client.clientName,
      shortCode: client.shortCode,
      contactPerson: client.contactPerson,
      contactNumber: client.contactNumber,
      status: client.status,
    });
    toggleModal(false);
  };

  const handelClientFormSubmission = () => {
    toggleModal(false);
    setShowConfirmation(true);
  };

  //colse confrimation dialog
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  //handel confrimationDesision
  const handelConfrimationDecision = (data) => {
    setShowConfirmation(false);
    handleClientUpdate(data);
    reset(onClose());
  };

  //async calling api
  const handleClientUpdate = async (data) => {
    const response = await updateClient(data);

    if (response) {
      successNoitif('Clint has been Updated');
      setIsUpdated(true);
    }
  };

  useEffect(() => {
    setValue('uid', client?.uid);
    setValue('name', client?.clientName);
    setValue('shortCode', client?.shortCode);
    setValue('contactPerson', client?.contactPerson);
    setValue('contactNumber', client?.contactNumber);
    setValue('status', client?.status);
  }, [client]);

  return (
    <>
      {client?.uid ? (
        <>
          <Dialog
            onClose={handleUpdateFormClose}
            open={show}
            fullWidth
            maxWidth="sm"
            noHandler={onClose}>
            <DialogTitle id="simple-dialog-title">
              Edit {client.clientName}
            </DialogTitle>
            <form>
              <Stack
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  paddingTop: 3,
                  paddingBottom: 3,
                }}>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <TextField
                    label="Client Name"
                    required
                    placeholder="Client Name"
                    {...register('name')}
                    error={errors?.name}
                    helperText={errors?.name?.message}
                  />
                </Stack>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <TextField
                    label="Clinet Shortcode"
                    required
                    placeholder="Clinet Shortcode"
                    {...register('shortCode')}
                    error={errors?.shortCode}
                    helperText={errors?.capacity?.message}
                  />
                </Stack>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <TextField
                    label="Contact Person"
                    required
                    placeholder="Contact Person"
                    {...register('contactPerson')}
                    error={errors?.contactPerson}
                    helperText={errors?.contactPerson?.message}
                  />
                </Stack>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <TextField
                    label="Contact Number"
                    required
                    placeholder="Contact Number"
                    {...register('contactNumber')}
                    error={errors?.contactNumber}
                    helperText={errors?.contactNumber?.message}
                  />
                </Stack>

                {/* Client status  */}
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <FormControl fullWidth error={errors?.status}>
                    <InputLabel id="type123">Client Status</InputLabel>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={status}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Client Status"
                          required
                          {...register('status')}
                          placeholder="Machine Status">
                          <MenuItem value="ACTIVE">Active</MenuItem>
                          <MenuItem value="INACTIVE">In Active</MenuItem>
                        </Select>
                      )}
                    />
                    {errors?.status && (
                      <FormHelperText>{errors?.status?.message}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                gap={1}
                justifyContent="flex-end"
                sx={{ paddingRight: 2, paddingBottom: 2 }}>
                <Button
                  variant="outlined"
                  sx={{ borderColor: 'red' }}
                  color="error"
                  onClick={handleUpdateFormClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={!isDirty ? true : false}
                  onClick={handleSubmit(handelClientFormSubmission)}>
                  Update
                </Button>
              </Stack>
            </form>
          </Dialog>
          <Stack>
            <Dialog
              onClose={onCloseCongfirmation}
              open={showConfirmation}
              maxWidth="sm"
              title="Are you sure"
              yesText="Yes"
              noHandler={onCloseCongfirmation}>
              <DialogTitle id="simple-dialog-title">
                Are you sure you want to update Client?
              </DialogTitle>
              <Stack
                direction="row"
                gap={1}
                justifyContent="flex-end"
                sx={{ paddingRight: 2, paddingBottom: 2 }}>
                <Button
                  variant="outlined"
                  sx={{ borderColor: 'red', color: 'red' }}
                  onClick={() => {
                    setShowConfirmation(false);
                    toggleModal(true);
                  }}>
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit(handelConfrimationDecision)}>
                  Yes
                </Button>
              </Stack>
            </Dialog>
          </Stack>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditClient;
