import React from 'react';

import exportFromJSON from 'export-from-json';

import { IconButton, Stack } from '@mui/material';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone';
import GridOnTwoToneIcon from '@mui/icons-material/GridOnTwoTone';

import { PRINT_TABLE } from '../../constants/routeUrls';
import { Link } from 'react-router-dom';
const FileExporterArrary = ({ dataList, title, vertical, mapKey }) => {
  const handleExport = async () => {
    exportFromJSON({
      data: dataList,
      fileName: `AREX-${title}-Report`,
      exportType: exportFromJSON.types.xls,
    });
  };

  return (
    <>
      {vertical ? (
        <Stack
          direction="column"
          px={0.5}
          spacing={2}
          bgcolor="#f2f2f2"
          borderRadius={2}>
          <IconButton
            title="Preview PDF"
            component={Link}
            to={`${PRINT_TABLE}`}
            disabled={dataList?.length < 1 ? true : false}
            state={{ data: dataList, title: title, mapKey: mapKey }}>
            <PictureAsPdfTwoToneIcon />
          </IconButton>
          <IconButton
            title="Download Excel"
            disabled={dataList?.length < 1 ? true : false}
            onClick={() => handleExport()}>
            <GridOnTwoToneIcon />
          </IconButton>
        </Stack>
      ) : (
        <Stack
          direction="row"
          px={0.5}
          spacing={2}
          bgcolor="#f2f2f2"
          borderRadius={2}>
          <IconButton
            title="Preview PDF"
            component={Link}
            to={`${PRINT_TABLE}`}
            disabled={dataList?.length < 1 ? true : false}
            state={{ data: dataList, title: title, mapKey: mapKey }}>
            <PictureAsPdfTwoToneIcon />
          </IconButton>
          <IconButton
            title="Download Excel"
            disabled={dataList?.length < 1 ? true : false}
            onClick={() => handleExport()}>
            <GridOnTwoToneIcon />
          </IconButton>
        </Stack>
      )}
    </>
  );
};

export default FileExporterArrary;
