import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import VisibilityIcon from '@mui/icons-material/Visibility';

import Button from '../../widgets/Button';
import colors from '../colors';
const setBackgroundColor = (status) => {
  if (status == 'IN USE') {
    return { backgroundColor: '#4caf50' };
  } else if (status == 'MAINTANENCE') {
    return { backgroundColor: '#ffcf33' };
  } else {
    return { backgroundColor: '#ff3d00' };
  }
};

export const setMachineStatusColor = (status) => {
  switch (status) {
    case 'IDLE':
      return colors.info;
    case 'MAINTANENCE':
      return colors.warning;
    case 'OUT OF ORDER':
      return colors.error;
    case 'IN USE':
      return colors.success;
  }
};

export const DummyMachineList = [
  {
    field: 'ACTIONS',
    headerName: '#',
    width: 70,
    type: 'date',
    renderCell: () => (
      <Stack direction="row" alignItems="flex-start">
        <Button>
          <VisibilityIcon />
        </Button>
      </Stack>
    ),
  },
  {
    field: 'logId',
    headerName: 'LOG ID',
    width: 150,
    filterable: false,
    sortable: false,
  },

  {
    field: 'machineStatus',
    headerName: 'STATUS',
    width: 150,
    renderCell: (params) => {
      const status = params?.value;

      return (
        <Chip
          size="small"
          label={status}
          sx={{ backgroundColor: setBackgroundColor(status), color: 'white' }}
        />
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'time',
    headerName: 'TIME',
    width: 200,
    type: 'date',
    renderCell: (params) =>
      params.value && `${dayjs(params.value).format('DD/MMM/YYYY h:mm A')}`,
  },

  {
    field: 'reason',
    headerName: 'REASON',
    width: 250,
    renderCell: (params) => {
      return (
        <Stack>
          {params?.value?.length > 25 ? (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value.substr(0, 25)}...</Typography>
              </Stack>
            </Tooltip>
          ) : (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value}</Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
      );
    },
    filterable: false,
    sortable: false,
  },

  {
    field: 'remark',
    headerName: 'REMARK',
    width: 250,
    renderCell: (params) => {
      return (
        <Stack>
          {params?.value?.length > 25 ? (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value.substr(0, 25)}...</Typography>
              </Stack>
            </Tooltip>
          ) : (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value}</Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
      );
    },
    filterable: false,
    sortable: false,
  },
];

export const DummyMachineLogs = {
  status: 200,
  machineLogs: [
    {
      logId: 'pikrQxoaWW',
      machineStatus: 'IN USE',
      time: 'Sun, Oct 9, 2022 3:02 PM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4628-9570 est completion 6 hour',
    },
    {
      logId: 'TTNhn5Qi3f',
      machineStatus: 'IDLE',
      time: 'Sun, Oct 9, 2022 2:43 PM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4628-9570 est completion 6 hour',
    },
    {
      logId: '9NURWPlhYk',
      machineStatus: 'IDLE',
      time: 'Sat, Oct 8, 2022 9:22 PM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4628-9570 est completion 6 hour',
    },
    {
      logId: 'kNw2C4sOul',
      machineStatus: 'IN USE',
      time: 'Fri, Oct 7, 2022 9:05 PM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4628-9570 est completion 6 hour',
    },
    {
      logId: 'zV8vqNSoMQ',
      machineStatus: 'IN USE',
      time: 'Fri, Oct 7, 2022 9:09 AM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4628-9570 est completion 6 hour',
    },
    {
      logId: 'LCrZYKTk2D',
      machineStatus: 'MAINTANENCE',
      time: 'Thu, Oct 6, 2022 8:56 PM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4629-9535 est completion 6 hour',
    },
    {
      logId: 'GOT85ztNSA',
      machineStatus: 'IN USE',
      time: 'Thu, Oct 6, 2022 2:58 PM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4629-9535 est completion 6 hour',
    },
    {
      logId: 'yI1Ea0CPOh',
      machineStatus: 'OUT OF ORDER',
      time: 'Wed, Oct 5, 2022 9:15 PM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4629-9535 est completion 6 hour',
    },
    {
      logId: 'OfpRiK5b9x',
      machineStatus: 'OUT OF ORDER',
      time: 'Tue, Oct 4, 2022 6:55 PM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4639-9518 est completion 6 hour',
    },
    {
      logId: 'nazr3huAVU',
      machineStatus: 'IN USE',
      time: 'Mon, Oct 3, 2022 9:15 PM',
      reason: 'Runnig batch',
      remark: 'Machine use for #BATCH 4639-9499 est completion 6 hour',
    },
  ],
  totalEntries: 194,
};

export const setMachineNameStatsColor = (status) => {
  switch (status) {
    case 'IDLE':
      return { backgroundColor: colors.info };
    case 'MAINTANENCE':
      return { backgroundColor: colors.warning };
    case 'OUT OF ORDER':
      return { backgroundColor: colors.error };
    case 'IN USE':
      return { backgroundColor: colors.success };
  }
};
