import {
  DoneAllTwoTone,
  InfoTwoTone,
  WarningTwoTone,
} from '@mui/icons-material';
import { toast } from 'react-hot-toast';

const toatstOptions = { duration: 3 * 1000, position: 'top-center' };

/**
 *
 * @param {string} message
 * @returns
 */
export const errorNoitif = (message) =>
  toast.error(message, {
    ...toatstOptions,
    icon: <WarningTwoTone sx={{ fill: '#fff' }} />,
    style: {
      borderRadius: 1000,
      background: '#FF5858',
      fontSize: 12,
      fontWeight: 600,
      color: '#fff',
    },
  });

/**
 *
 * @param {string} message
 * @returns
 */
export const successNoitif = (message) =>
  toast.success(message, {
    ...toatstOptions,
    icon: <DoneAllTwoTone sx={{ fill: '#fff' }} />,
    style: {
      borderRadius: 1000,
      background: '#7FB77E',
      fontSize: 12,
      fontWeight: 600,
      color: '#fff',
    },
  });

/**
 *
 * @param {string} message
 * @returns
 */
export const warningNoitif = (message) =>
  toast(message, {
    ...toatstOptions,
    icon: <WarningTwoTone sx={{ fill: '#303030' }} />,
    style: {
      borderRadius: 1000,
      background: '#F1A661',
      fontSize: 12,
      fontWeight: 600,
    },
  });

/**
 *
 * @param {string} message
 * @returns
 */
export const infoNoitif = (message) =>
  toast(message, {
    ...toatstOptions,
    icon: <InfoTwoTone sx={{ fill: '#fff' }} />,
    style: {
      color: '#fff',
      borderRadius: 10000,
      background: '#4562f7',
      fontSize: 12,
      fontWeight: 600,
    },
  });

export const loading = (message, errorMesage, promise) => {
  toast.promise(promise, {
    loading: 'Making your request',
    success: message,
    error: errorMesage,
  });
};
