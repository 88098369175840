import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchChemicalStocks } from '../../api/inventory';
import { ChemicalStockColumns } from '../../constants/table-column/inventory';

const LotList = () => {
  const { sku } = useParams();
  const [lots, setLots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const fetchDetails = async () => {
    setIsLoading(true);
    const response = await fetchChemicalStocks(sku);
    setLots(response.stocks);
    setTotal(response.total);
    setIsLoading(false);
  };

  const fetchDetailsWithPagination = async () => {
    setIsLoading(true);
    const response = await fetchChemicalStocks(sku, { page, pageSize });
    setLots(response.stocks);
    setTotal(response.total);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await fetchDetails())();
  }, [sku]);

  useEffect(() => {
    (async () => await fetchDetailsWithPagination())();
  }, [page, pageSize]);

  return (
    <Box flex={1} height="51vh" mr={1} mb={2}>
      <DataGrid
        columns={ChemicalStockColumns}
        rows={lots}
        rowCount={total}
        loading={isLoading}
        paginationMode="server"
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 15, 20, 30, 40, 50]}
      />
    </Box>
  );
};

export default LotList;
