import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftTwoTone';
import ChevronRightIcon from '@mui/icons-material/ChevronRightTwoTone';
import { Outlet } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/MenuTwoTone';
import { Badge, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import Sidebar from '../components/layout/Sidebar';
import { Navbar } from '../components/layout/Navbar';
import dayjs from 'dayjs';
import colors from '../constants/colors';
import { PushPinOutlined, PushPinTwoTone } from '@mui/icons-material';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function PrivateLayout({ socket }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [notice, setNotice] = React.useState(null);
  const [showNotice, setShowNotice] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    socket.emit('notice');
  }, [socket]);

  React.useEffect(() => {
    socket.on('notice-data', (data) => {
      setNotice(data || null);
    });
    return () => {
      socket.off('notice-data');
    };
  }, [socket]);

  const getBgColor = () => {
    const bgcolors = {
      ALERT: colors.error,
      ANNOUNCEMENT: colors.info,
    };

    return bgcolors[notice?.type || 'ANNOUNCEMENT'];
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <Navbar />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ '& .MuiPaper-root': { borderRight: 'none' } }}>
        <DrawerHeader sx={{ bgcolor: '#171820' }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon sx={{ fill: '#fff' }} />
            ) : (
              <ChevronLeftIcon sx={{ fill: '#fff' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Sidebar isOpen={open} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <DrawerHeader />
        <Outlet />
        {notice &&
          (showNotice ? (
            <Stack
              width="calc(100% - 65px)"
              position="fixed"
              bottom={0}
              direction="row"
              spacing={1}
              bgcolor={getBgColor()}
              px={2}
              py={1}
              color="#f2f2f2"
              justifyContent="space-between"
              alignItems="center">
              <Typography fontSize={18} fontWeight={800}>
                {notice?.title}{' '}
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    textTransform: 'capitalize',
                  }}>
                  ({notice?.type})
                </span>
              </Typography>
              <Typography fontSize={14} fontWeight={600}>
                {`${notice?.message}`.substring(0, 50)}
              </Typography>
              <Typography fontSize={12} fontWeight={700}>
                By {notice?.createdByInfo?.name}
              </Typography>
              <Typography fontSize={12} fontWeight={700}>
                {dayjs(notice?.createdAt).format('hh:mm A DD, ddd MMMM')}
              </Typography>
              <Tooltip title="Hide alert for now">
                <IconButton onClick={() => setShowNotice(false)}>
                  <PushPinOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            <Tooltip title="Show notice" arrow placement="left">
              <IconButton
                onClick={() => setShowNotice(true)}
                sx={{
                  position: 'fixed',
                  bottom: 30,
                  left: 80,
                  bgcolor: colors.error,
                  color: '#303030',
                  ':hover': {
                    bgcolor: colors.primary,
                    color: '#fff',
                  },
                }}>
                <Badge color="info" variant="dot">
                  <PushPinTwoTone />
                </Badge>
              </IconButton>
            </Tooltip>
          ))}
      </Box>
    </Box>
  );
}
