import * as yup from 'yup';

export const NoticeCreateSchema = yup.object().shape({
  title: yup.string().required('Must provide a title').max(40),
  message: yup.string().required('Must provide a message').max(120),
  type: yup
    .string()
    .oneOf(['ALERT', 'ANNOUNCEMENT'])
    .required('Must select a type'),
});
