import { Pageview } from '@mui/icons-material';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { formatTime } from '../../utils/time';
import OrderBatchDetails from './OrderBatchDetails';

export const OrderBatchTranscript = ({
  open,
  setOpen,
  batchList,
  orderInfo,
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: `AREX-${orderInfo?.fcNumber}-QR-${formatTime(new Date())}`,
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (open) {
      handlePrint();
      setOpen(false);
    }
  }, [open]);
  const checkBatchStatus = (batch) => {
    switch (batch?.batchInfo?.status) {
      case 'WAITING FOR DELIVERY':
        return true;
      case 'READY FOR DELIVERY':
        return true;
      case 'DELIVERED':
        return true;
      case 'PARTIAL DELIVERED':
        return true;
      default:
        return false;
    }
  };

  return (
    <div style={{ display: 'none' }}>
      <Box
        mx="auto"
        width="1080px"
        my={2}
        mt={3}
        ref={componentRef}
        boxSizing="border-box"
        padding="15px 30px"
        class="page">
        {/* header section  */}
        <Grid my={2} container spacing={2} alignItems="center">
          <Grid item xs={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}>
              <Box
                component="img"
                sx={{
                  width: 150,
                }}
                alt="The house from the offer."
                src="/assets/images/logo.svg"
              />
              <Stack>
                <Typography fontSize={18}>2/1,Gadda,Savar,Dhaka</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack width="100%">
              <Typography fontSize={25} fontWeight={700}>
                Batch Receipt
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        {/* body section  */}
        <Stack mt={2} mb={1}>
          <Stack mb={2} width="100%">
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}>
                  <Typography>FC Number:</Typography>
                  <Typography
                    style={{
                      borderBottom: '1px dashed #000',
                      textDecoration: 'none',
                    }}>
                    {orderInfo?.fcNumber}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    {' '}
                    <Typography textAlign="end">Issue Date:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        borderBottom: '1px dashed #000',
                        textDecoration: 'none',
                      }}
                      textAlign="end">
                      {dayjs(orderInfo?.issueDate).format('DD/MMM/YYYY ')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}>
                  <Typography>Party Name:</Typography>
                  <Typography
                    style={{
                      borderBottom: '1px dashed #000',
                      textDecoration: 'none',
                    }}>
                    {orderInfo?.partyName}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    {' '}
                    <Typography textAlign="end">Client Ref No:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        borderBottom: '1px dashed #000',
                        textDecoration: 'none',
                      }}
                      textAlign="end">
                      {orderInfo?.clientRefNo}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}>
                  <Typography>Sales Person:</Typography>
                  <Typography
                    style={{
                      borderBottom: '1px dashed #000',
                      textDecoration: 'none',
                    }}>
                    {orderInfo?.salesPerson}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    {' '}
                    <Typography textAlign="end">Delivery Date:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      style={{
                        borderBottom: '1px dashed #000',
                        textDecoration: 'none',
                      }}
                      textAlign="end">
                      {dayjs(orderInfo?.deliveryDate).format('DD/MMM/YYYY ')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>

          {batchList?.length < 1 ? (
            <Stack
              justifyContent="center"
              pt={10}
              width="100%"
              height="100%"
              alignItems="center">
              <Stack
                direction="Column"
                justifyContent="center"
                alignItems="center">
                <Pageview sx={{ width: 120, height: 120 }} />
                <Typography fontSize={18} fontWeight={800}>
                  No Batches
                </Typography>
                <Typography maxWidth={450}>No batches created yet!</Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack alignItems="left" justifyContent="flex-start" width="100%">
              <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
                Batch List:
              </Typography>
              <Stack alignItems="left" justifyContent="flex-start" width="100%">
                <Stack
                  flexWrap="wrap"
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start">
                  {batchList.map((batch) =>
                    checkBatchStatus(batch) ? (
                      <OrderBatchDetails key={batch?.id} batch={batch} />
                    ) : (
                      <></>
                    ),
                  )}
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
        {/* signature  */}
        <Grid className="singnature-class" container spacing={2} mt={8}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Divider width="80%"></Divider>
            <Stack width="90%" justifyContent="center" alignItems="center">
              <Stack my={0.5}>
                <Typography fontSize={15}>Authorized Signature</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
