import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import colors from '../../constants/colors';
import Button from '../../widgets/Button';
import { Link } from 'react-router-dom';
import { DASHBOARD } from '../../constants/routeUrls';

const UnAuthorizedPage = () => {
  return (
    <Stack
      width="100%"
      height="100vh"
      bgcolor={colors.error}
      color="#171820"
      alignItems="center"
      justifyContent="center"
      spacing={2}>
      <Typography fontWeight={700} fontSize={36}>
        You are not authorized.
      </Typography>
      <Box>
        <Divider orientation="horizontal">Or</Divider>
      </Box>
      <Typography fontWeight={800} fontSize={14}>
        You do not have proper permission to access this page. Please contact{' '}
        <b>abir@manush.tech</b>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={DASHBOARD}>
        Go to Home
      </Button>
    </Stack>
  );
};

export default UnAuthorizedPage;
