import React, { useEffect, useRef, useState } from 'react';
import '../../styles/recipe-print.css';
import { useParams, Link } from 'react-router-dom';
import { fetchBatchDetail } from '../../api/batch';
import { Stack, Typography } from '@mui/material';
import Button from '../../widgets/Button';
import {
  ChevronLeftTwoTone as ChevronLeft,
  PrintTwoTone as Print,
} from '@mui/icons-material';
import { DASHBOARD } from '../../constants/routeUrls';
import { formatTime } from '../../utils/time';
import { formatNumber } from '../../utils/number';
import ReactToPrint from 'react-to-print';

const RecipePrintPreview = () => {
  const [batch, setBatch] = useState(null);
  const { lotNumber } = useParams();
  const fetchBtach = async () => {
    const response = await fetchBatchDetail(lotNumber);

    if (response) {
      setBatch(response);
    }
  };

  useEffect(() => {
    if (lotNumber) {
      (async () => await fetchBtach())();
    }
  }, [lotNumber]);
  const formatTotalAmount = (amount) => {
    const kg = Math.floor(amount * 0.001);
    const gm = Math.floor(
      (amount * 0.001 - Math.floor(amount * 0.001)).toFixed(3) * 1000,
    );

    const mg =
      (
        (amount * 0.001 - Math.floor(amount * 0.001)) * 1000 -
        Math.floor((amount * 0.001 - Math.floor(amount * 0.001)) * 1000)
      ).toFixed(3) * 1000;

    return `${kg} kg ${gm} gm ${mg} mg`;
  };

  let printDocRef = useRef();
  if (!batch) {
    return (
      <Stack
        width="100%"
        height="80vh"
        spacing={1}
        alignItems="center"
        justifyContent="center">
        <Typography variant="h4" fontWeight={700}>
          Batch Not Found
        </Typography>
        <Typography>
          Sorry could not load information to generate dyeing report
        </Typography>
        <Button
          startIcon={<ChevronLeft />}
          variant="contained"
          component={Link}
          to={`${DASHBOARD}`}>
          Return TO Dashboard
        </Button>
      </Stack>
    );
  }

  return (
    <div className="container">
      <ReactToPrint
        trigger={() => (
          <Button endIcon={<Print />} variant="contained" sx={{ mb: 3 }}>
            Print Report
          </Button>
        )}
        content={() => printDocRef}
      />

      <div
        id="print-content"
        ref={(el) => (printDocRef = el)}
        style={{ margin: '0.2cm auto', width: '100%' }}>
        <div
          className="row"
          style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <div>
            <img src="/assets/images/logo.svg" alt="" width="150" />
            <p>2/1 Gadda, Savar-1340, Dhaka</p>
            <h5 style={{ marginTop: '10px' }}>
              Machine: {batch.machineInfo?.name} (
              {batch?.machineInfo?.maxCapacity} Kg)
            </h5>
            <h5 style={{ marginTop: '10px' }}>
              Loading Time: {formatTime(batch.loadingTime)}
            </h5>
          </div>
          <div>
            <h2 className="title" style={{ textAlign: 'right' }}>
              Dyeing Report
            </h2>
            <h2 className="sub-title" style={{ textAlign: 'right' }}>
              Batch #{lotNumber?.split('-')?.[1]}
            </h2>
            <h5 style={{ marginTop: '10px', textAlign: 'right' }}>
              Fabric Weight: {formatNumber(batch.totalFabricWeight)} Kg
            </h5>
            <h5 style={{ marginTop: '10px', textAlign: 'right' }}>
              Liquor Ratio: {formatNumber(batch.liquorRatio)}
            </h5>
          </div>
        </div>

        <h3 style={{ marginTop: '10px' }}>Orders</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <td>FC Number</td>
              <td>Party Name</td>
            </tr>
          </thead>
          <tbody>
            {batch.orderInfo?.map((item) => (
              <tr key={item.fcNumber}>
                <td>{item.fcNumber}</td>
                <td>{item.partyName}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3 style={{ marginTop: '10px' }}>Fabrics</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <td>Fabric Type</td>
              <td>Color</td>
              <td>Shade Group</td>
              <td>Shade (%)</td>
              <td>GSM</td>
              <td>DIA</td>
              <td>Quantity (KG)</td>
            </tr>
          </thead>
          <tbody>
            {batch.fabricInfo?.map((item) => (
              <tr key={item.id}>
                <td>{item.fabricType}</td>
                <td>{item.color}</td>
                <td>{item.shadeGroup}</td>
                <td>{(item.shade * 100).toFixed(2)}</td>
                <td>{item.gsm}</td>
                <td>{item.dia}</td>
                <td>{formatNumber(item.usedQty)}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>{formatNumber(batch.totalFabricWeight)}</td>
            </tr>
          </tbody>
        </table>
        <h3 style={{ marginTop: '10px' }}>Chemicals</h3>
        {batch.recipeInfo?.map((item) => (
          <div key={item.id}>
            <h4 style={{ marginTop: '10px' }}>{item.taskName}</h4>
            {item.recipe?.length > 0 ? (
              <table className="styled-table">
                <thead>
                  <tr>
                    <td>Chemical Name</td>
                    <td>Dye Group</td>
                    <td>Quantity</td>
                    <td>Addition</td>
                    <td>Unit</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  {item.recipe.map((rcp) => (
                    <tr key={rcp.id}>
                      <td>{rcp.chemicalName}</td>
                      <td>{rcp.dyeGroupName}</td>
                      <td>{rcp.quantity}</td>
                      <td>{rcp.addition || 0}</td>
                      <td>{rcp.unit === 'percent' ? '%' : rcp.unit}</td>
                      <td>{formatTotalAmount(rcp.total)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>Chemical recipe not configured for this task</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecipePrintPreview;
