import { Box, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ORDER } from '../../constants/routeUrls';
import { AllCSVListColumns } from '../../constants/table-column/order';
import Button from '../../widgets/Button';
import NoDataOverlay from '../global/NoDataOverlay';

const PreviewUploadedOrders = ({ uploadedOrders }) => {
  const navigation = useNavigate();
  const handleGoBack = () => {
    navigation(ORDER);
  };
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center">
      <Stack
        width="80%"
        heigh="100%"
        mx="auto"
        borderRadius={1.5}
        px={2}
        py={2.5}
        spacing={2.5}
        sx={{
          border: '2px solid rgba(0,0,0,0.2)',
        }}>
        <Typography variant="h5" fontWeight={800}>
          Uploaded Orders Preview
        </Typography>
        <Stack
          mt={1}
          width="100%"
          direction="row"
          justifyContent="center"
          alignItems="center">
          {uploadedOrders.length > 0 ? (
            <Stack width="100%" mt={1}>
              <Box width="100%" height={250}>
                <DataGrid
                  columns={AllCSVListColumns}
                  rows={uploadedOrders}
                  rowCount={uploadedOrders.length}
                  getRowId={(row) => row.fcNumber}
                  hideFooter={true}
                  components={{
                    NoRowsOverlay: NoDataOverlay,
                  }}
                />
              </Box>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          spacing={2}
          mt={3}>
          <Button onClick={handleGoBack} variant="contained">
            Back to Orders Page
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PreviewUploadedOrders;
