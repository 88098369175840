import { DeleteTwoTone as Delete } from '@mui/icons-material';
import {
  Autocomplete,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';

const ColorChemicalForm = ({
  index,
  recipes,
  control,
  register,
  remove,
  errors,
  options,
  dyeGroupOptions,
  setFormValue,
  liquorRatio,
  totalFabricWeight,
}) => {
  const selectedChemical = useWatch({
    control,
    name: `recipe.${index}.chemical`,
  });

  const selectedDyeGroup = useWatch({
    control,
    name: `recipe.${index}.dyeGroup`,
  });

  const unit = useWatch({ control, name: `recipe.${index}.unit` });

  const calculateAndSetTotal = () => {
    if (unit === 'g/l') {
      const total =
        parseFloat(recipes?.[index]?.quantity) *
        liquorRatio *
        totalFabricWeight;

      setFormValue(`recipe.${index}.total`, total.toFixed(3), {
        shouldDirty: true,
        shouldTouch: true,
      });
    } else if (unit === 'percent') {
      const total =
        totalFabricWeight * parseFloat(recipes?.[index]?.totalChange) * 10;
      setFormValue(`recipe.${index}.total`, total.toFixed(3), {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const calculateAndSetTotalChange = () => {
    if (unit === 'percent') {
      setFormValue(
        `recipe.${index}.totalChange`,
        parseFloat(recipes?.[index]?.quantity) +
          (parseFloat(recipes?.[index]?.changeBy) || 0) * 0.01,
        { shouldDirty: true, shouldTouch: true },
      );
    }
  };

  useEffect(
    () => calculateAndSetTotalChange(),
    [unit, recipes?.[index]?.quantity, recipes?.[index]?.changeBy],
  );

  useEffect(
    () => calculateAndSetTotal(),
    [
      recipes?.[index]?.quantity,
      recipes?.[index]?.totalChnage,
      liquorRatio,
      unit,
    ],
  );

  useEffect(() => {
    if (selectedChemical) {
      selectedDyeGroup.name = selectedDyeGroup.dyeGroupName;
    }
  }, [selectedChemical]);

  return (
    <TableRow>
      <TableCell>
        <Autocomplete
          defaultValue={selectedDyeGroup}
          options={dyeGroupOptions}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => (option ? `${option?.name}` : '')}
          onChange={(event, value) => {
            setFormValue(`recipe.${index}.dyeGroup`, value, {
              shouldDirty: true,
              shouldTouch: true,
            });

            setFormValue(`recipe.${index}.chemical`, null, {
              shouldDirty: true,
              shouldTouch: true,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ width: 150 }}
              error={!!errors?.recipe?.[index]?.chemical}
              helperText={errors?.recipe?.[index]?.chemical?.message}
            />
          )}
        />
      </TableCell>

      <TableCell>
        <Autocomplete
          options={options?.filter(
            (item) => item?.dyeGroupId === selectedDyeGroup?.id,
          )}
          value={selectedChemical}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => (option ? `${option?.name} ` : '')}
          onChange={(event, value) => {
            setFormValue(`recipe.${index}.chemical`, value, {
              shouldDirty: true,
              shouldTouch: true,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ width: 150 }}
              error={!!errors?.recipe?.[index]?.chemical}
              helperText={errors?.recipe?.[index]?.chemical?.message}
            />
          )}
        />
      </TableCell>
      {recipes?.[index]?.unit === 'g/l' ? (
        <TableCell>
          <TextField
            size="small"
            type="number"
            placeholder="Quantity"
            sx={{ width: 100 }}
            {...register(`recipe.${index}.quantity`)}
            error={!!errors?.recipe?.[index]?.quantity}
            helperText={errors?.recipe?.[index]?.quantity?.message}
          />
        </TableCell>
      ) : (
        <TableCell>
          <Stack spacing={0.5}>
            <TextField
              size="small"
              type="number"
              sx={{ width: 100 }}
              placeholder="Quantity"
              {...register(`recipe.${index}.quantity`)}
              error={!!errors?.recipe?.[index]?.quantity}
              helperText={errors?.recipe?.[index]?.quantity?.message}
            />
            <TextField
              size="small"
              type="number"
              placeholder="Change by"
              {...register(`recipe.${index}.changeBy`)}
              sx={{ width: 100 }}
            />
            <TextField
              size="small"
              type="number"
              sx={{ width: 100 }}
              placeholder="Total change"
              {...register(`recipe.${index}.totalChange`)}
              disabled
            />
          </Stack>
        </TableCell>
      )}
      <TableCell>
        <Controller
          control={control}
          name={`recipe.${index}.unit`}
          defaultValue={recipes?.[index]?.unit}
          render={({ field }) => {
            return (
              <Select
                {...field}
                value={field.value}
                size="small"
                sx={{ width: 100 }}>
                <MenuItem value="g/l">g/l</MenuItem>
                <MenuItem value="percent">%</MenuItem>
              </Select>
            );
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          size="small"
          disabled
          sx={{ width: 140 }}
          value={recipes?.[index]?.total}
          onChange={calculateAndSetTotal}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          size="small"
          sx={{ width: 140 }}
          {...register(`recipe.${index}.addition`)}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => remove(index)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ColorChemicalForm;
