import {
  AddCircleTwoTone,
  CancelTwoTone,
  PageviewTwoTone,
  SaveTwoTone,
  VisibilityTwoTone,
} from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  TextField,
  Chip,
  IconButton,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { createUtility, fetchUtilities } from '../../api/factory-utility';
import { UTILITY_SETTINGS } from '../../constants/routeUrls';
import {
  utilityScaleColorMapper,
  utilityScaleNumberToStringMapper,
} from '../../utils/factory-utility';
import { successNoitif } from '../../utils/notification';
import Button from '../../widgets/Button';
import PageHeader from '../../widgets/PageHeader';
import Switch from '../../widgets/Switch';

const UtilityManagement = () => {
  const [utilities, setUtilities] = useState([]);
  const [showUtilityCreationModal, setShowUtilityCreationModal] =
    useState(false);

  const fetchData = async (query) => {
    const response = await fetchUtilities(query);
    setUtilities(response);
  };

  useEffect(() => {
    (async () => await fetchData({}))();
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const handleSave = async (data) => {
    const { name, shouldCaptureValue } = data;
    const response = await createUtility({ name, shouldCaptureValue });
    if (response) {
      successNoitif('Utiliy added');
      setUtilities((current) => [...current, response]);
      reset({ name: '' });
    }

    setShowUtilityCreationModal(false);
  };

  const handleCancel = () => {
    reset({ name: '' });
    setShowUtilityCreationModal(false);
  };

  return (
    <Stack width="85%" mx="auto" minHeight="85vh" spacing={2}>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={showUtilityCreationModal}
        onClose={() => setShowUtilityCreationModal(false)}>
        <DialogContent>
          <Stack py={1} spacing={1}>
            <Typography variant="h6" fontWeight={700}>
              Add New Utility
            </Typography>
            <TextField
              size="small"
              label="Name"
              placeholder="Name of the utility"
              {...register('name', {
                required: 'Must provide name of utility',
              })}
              error={!!errors?.name}
              helperText={errors?.name?.message}
            />
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              justifyContent="flex-end">
              <Switch
                color="secondary"
                onChange={(event) => {
                  setValue('shouldCaptureValue', event.target.checked);
                }}
              />
              <Typography fontSize={14} fontWeight={700}>
                Should Capture value?
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" spacing={0.7}>
              <Button
                endIcon={<CancelTwoTone />}
                color="error"
                variant="outlined"
                size="small"
                onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                endIcon={<SaveTwoTone />}
                color="info"
                variant="contained"
                size="small"
                onClick={handleSubmit(handleSave)}>
                Save
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack direction="row" justifyContent="space-between">
        <PageHeader>Utilities</PageHeader>
        <Button
          variant="contained"
          endIcon={<AddCircleTwoTone />}
          sx={{ height: 'max-content', width: 'max-content' }}
          onClick={() => setShowUtilityCreationModal(true)}>
          Add Utility
        </Button>
      </Stack>
      <Stack spacing={2}>
        {utilities?.length <= 0 ? (
          <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="45vh">
            <PageviewTwoTone sx={{ width: 120, height: 120 }} />
            <Typography variant="h5" fontWeight={700}>
              No Utilities Found
            </Typography>
            <Typography>Please add a utility</Typography>
          </Stack>
        ) : (
          utilities.map((item) => (
            <Stack
              key={item}
              direction="row"
              px={1}
              py={1}
              spacing={2}
              justifyContent="space-between"
              borderRadius={1}
              bgcolor="#f2f2f2">
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography fontSize={18} fontWeight={800}>
                  {item.name}
                </Typography>
                <Chip
                  label={utilityScaleNumberToStringMapper(item.scale)}
                  color={utilityScaleColorMapper(item.scale)}
                  size="small"
                />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography fontSize={14} fontWeight={800}>
                  {item.value || 0}{' '}
                  <span style={{ fontSize: 11, fontWeight: 400 }}>
                    ({item.name})
                  </span>
                </Typography>
                <Chip
                  label={`Updated By: ${item.updatedByInfo.name}`}
                  color="info"
                  size="small"
                />
                <Typography fontSize={12} fontWeight={400}>
                  Last Updated:{' '}
                  {dayjs(item.updatedAt).format('hh:mm A - DD, MMM')}
                </Typography>
                <IconButton
                  title="View details"
                  component={Link}
                  to={`${UTILITY_SETTINGS}/${item.uid}`}>
                  <VisibilityTwoTone />
                </IconButton>
              </Stack>
            </Stack>
          ))
        )}
      </Stack>
    </Stack>
  );
};

export default UtilityManagement;
