import { Epg, Layout, useEpg } from 'planby';
import React, { useEffect, useState } from 'react';
import ChannelItem from './planby/ChannelItem';
import ProgramItem from './planby/ProgramItem';
import { Timeline } from './planby/Timeline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Stack, TextField } from '@mui/material';
import { format } from 'date-fns';
import { fetchSingleMachineLogs } from '../../api/machine';
import dayjs from 'dayjs';

const theme = {
  primary: {
    600: '#1a202c',
    900: 'white',
  },
  grey: { 300: '#d1d1d1' },
  white: '#fff',
  green: {
    300: '#2C7A7B',
  },
  loader: {
    teal: '#5DDADB',
    purple: '#3437A2',
    pink: '#F78EB6',
    bg: '#171923db',
  },
  scrollbar: {
    border: '#ffffff',
    thumb: {
      bg: '#e1e1e1',
    },
  },
  gradient: {
    blue: {
      300: '#002eb3',
      600: '#002360',
      900: '#051937',
    },
  },
  text: {
    grey: {
      300: '#a0aec0',
      500: '#718096',
    },
  },
  timeline: {
    divider: {
      bg: '#718096',
    },
  },
};

const MachineTimeLine = ({ machineName }) => {
  const getStartOfTheDay = () => {
    const startOfDay = new Date();
    startOfDay.setUTCHours(0, 0, 0, 0);
    return startOfDay;
  };
  const getEndOfTheDay = () => {
    const endoftheDay = new Date();
    endoftheDay.setHours(23, 59, 59, 999);
    return endoftheDay;
  };
  const [machineLogs, setMachineLogs] = useState([
    {
      channelUuid: machineName,
      id: 1234567890,
      since: dayjs(getStartOfTheDay()).format('YYYY-MM-DDTHH:mm:ss'),
      till: dayjs(getEndOfTheDay()).format('YYYY-MM-DDTHH:mm:ss'),
      title: 'IDLE',
    },
  ]);

  const [epg, setEpg] = useState([]);
  const [searchDate, setSearchDate] = React.useState(new Date());

  const fecthMachineLogs = async (startDate, endDate) => {
    if (!startDate & !endDate) {
      startDate = getStartOfTheDay();
      endDate = getEndOfTheDay();
    }

    const response = await fetchSingleMachineLogs({
      name: machineName,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(startDate, 'yyyy-MM-dd'),
    });

    //check if machinelogs array is empty or not
    if (response.machineLogs.length > 0) {
      let tempLogs = JSON.parse(JSON.stringify(response.machineLogs));

      let tempMachineLogs = [];

      //take the length of the machine logs
      let index = 0;
      //before the logs start
      let firstItem = {
        channelUuid: machineName,
        id: tempLogs[index].uid,
        since: format(startDate, 'yyyy-MM-dd'),
        till: dayjs(tempLogs[index].createdAt).format('YYYY-MM-DDTHH:mm:ss'),
        title: 'IDLE',
      };
      tempMachineLogs.push(firstItem);
      tempLogs.map(() => {
        if (index < tempLogs.length) {
          let item = {};
          if (tempLogs[index + 1]) {
            item = {
              channelUuid: machineName,
              id: tempLogs[index].uid,
              since: dayjs(tempLogs[index].createdAt).format(
                'YYYY-MM-DDTHH:mm:ss',
              ),
              till: dayjs(tempLogs[index + 1].createdAt).format(
                'YYYY-MM-DDTHH:mm:ss',
              ),
              title: tempLogs[index].status,
              batchNo: tempLogs[index]?.message.split('-')[0],
            };
          } else {
            item = {
              channelUuid: machineName,
              id: tempLogs[index].uid,
              since: dayjs(tempLogs[index].createdAt).format(
                'YYYY-MM-DDTHH:mm:ss',
              ),
              till: dayjs(getEndOfTheDay()).format('YYYY-MM-DDTHH:mm:ss'),
              title: tempLogs[index].status,
              batchNo: tempLogs[index]?.message.split('-')[0],
            };
          }
          index++;
          tempMachineLogs.push(item);
        }
      });
      setMachineLogs(tempMachineLogs);
    } else {
      startDate.setUTCHours(0, 0, 0, 0);
      startDate.setHours(1);
      endDate.setHours(23, 59, 59, 999);
      const tempMachineLogs = [
        {
          channelUuid: machineName,
          id: 1234567890,
          since: format(startDate, 'yyyy-MM-dd'),
          till: dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss'),
          title: 'IDLE',
        },
      ];
      setMachineLogs(tempMachineLogs);
    }
  };

  useEffect(() => {
    (async () => await fecthMachineLogs())();
  }, []);

  const channels = React.useMemo(
    () => [
      {
        logo: 'https://cdn-icons-png.flaticon.com/512/237/237416.png',
        uuid: machineName,
        name: machineName,
      },
    ],
    [],
  );

  useEffect(() => {
    setEpg(machineLogs);
  }, [machineLogs]);

  const handleLogSearchSubmit = (event) => {
    event.preventDefault();
  };

  const handelChange = async (newValue) => {
    (async () => await fecthMachineLogs(newValue, newValue))();
    setSearchDate(newValue);
  };

  const { getEpgProps, getLayoutProps } = useEpg({
    epg,
    channels,
    theme,
    isBaseTimeFormat: true,
    dayWidth: 2000,
    sidebarWidth: 150,
    itemHeight: 80,
    isSidebar: true,
    isTimeline: true,
    isLine: true,
    startDate: format(searchDate, 'yyyy-MM-dd'),
  });

  return (
    <>
      <form onSubmit={handleLogSearchSubmit}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          alignItems="center"
          pr={2}>
          <Stack width={{ md: '15%', xs: '35%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Search Date"
                value={searchDate}
                onChange={(newValue) => {
                  handelChange(newValue);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>
      </form>
      <Stack
        height="26vh"
        width={{ md: '74vw', xs: '60vw' }}
        direction="row"
        justifyContent="flex-start"
        style={{ paddingLeft: '0px', marginLeft: '0px' }}>
        <Epg {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            renderTimeline={(props) => <Timeline {...props} />}
            renderChannel={({ channel }) => (
              <ChannelItem key={channel.uuid} channel={channel} />
            )}
            renderProgram={({ program, ...rest }) => (
              <ProgramItem key={program.data.id} program={program} {...rest} />
            )}
          />
        </Epg>
      </Stack>
    </>
  );
};

export default MachineTimeLine;
