import {
  Grid,
  Stack,
  Typography,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
  useTheme,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import { Box } from '@mui/system';
import SelectOrderCsv from '../../components/order/SelectOrderCsv';
import CheckCsvOrders from '../../components/order/CheckCsvOrders';
import PreviewUploadedOrders from '../../components/order/PreviewUploadedOrders';
import PageTitle from '../../utils/PageTitle';

const Steper = styled(MuiStepper)({
  '& .Mui-completed': {
    fill: '#66bb6a',
  },
});

const Step = styled(MuiStep)({});
const StepLabel = styled(MuiStepLabel)({});

const OrderCsvStepperForm = () => {
  //new
  const [activeStep, setActiveStep] = useState(0);
  const [validOrders, setValidOrders] = useState([]);
  const [errorOrders, setErrorOrders] = useState([]);
  const [uploadedOrders, setUploadedOrders] = useState([]);

  const steps = [
    {
      label: 'Select CSV',
      description: 'Select order CSV file',
      step: (
        <SelectOrderCsv
          next={setActiveStep}
          setValidOrders={setValidOrders}
          setErrorOrders={setErrorOrders}
        />
      ),
    },
    {
      label: 'Check & Upload File',
      description: 'Check and upload the valid files',
      step: (
        <CheckCsvOrders
          validOrders={validOrders}
          errorOrders={errorOrders}
          next={setActiveStep}
          setUploadedOrders={setUploadedOrders}
        />
      ),
    },
    {
      label: 'Completion',
      description: 'Preview all successfully uploaded file',
      step: <PreviewUploadedOrders uploadedOrders={uploadedOrders} />,
    },
  ];

  const theme = useTheme();
  const currentStep = steps[activeStep];

  return (
    <Grid container width="100%" height="auto" minHeight="calc(100vh - 64px)">
      <PageTitle title="Upload Order" />
      <Grid
        item
        xs={12}
        display={{ xs: 'block', md: 'none' }}
        minHeight="25vh"
        height="auto">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100%', px: 2, py: 3 }}>
          <Typography variant="h4" fontWeight={800} textAlign="center">
            {currentStep.label}
          </Typography>
          <Typography sx={{ my: 2 }} textAlign="center">
            {currentStep.description}
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        display={{ xs: 'none', md: 'block' }}
        md={2}
        bgcolor={theme.palette.mode === 'light' ? '#f3f3f3' : '#303030'}>
        <Stack
          sx={{
            px: 2,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <Steper activeStep={activeStep} orientation="vertical">
            {steps.map((item, index) => {
              const isActive = index === activeStep;
              if (isActive) {
                return (
                  <Step key={item.label}>
                    <StepLabel></StepLabel>
                    <Typography variant="h5" fontWeight={800}>
                      {item.label}
                    </Typography>
                    <Typography sx={{ my: 2 }}>{item.description}</Typography>
                  </Step>
                );
              } else {
                return (
                  <Step key={item.label}>
                    <StepLabel>{item.label}</StepLabel>
                  </Step>
                );
              }
            })}
          </Steper>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={10}
        height="auto"
        minHeight={{ xs: '75vh', md: 'calc(100vh - 64px)' }}>
        <Box
          px={5}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center">
          {steps[activeStep].step}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderCsvStepperForm;
