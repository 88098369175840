import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Stack } from '@mui/material';
import LiveTrackingStats from './LiveTrackingStats';
import BatchList from './BatchList';
import { useSelector } from 'react-redux';
import LiveTrackingFilterModule from './LiveTrackingFilterModule';

const LiveTrackingIndex = ({ socket }) => {
  const [showFullScreenTracer, setShowFullScreenTracker] = useState(false);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [batches, setBatches] = useState([]);
  const [filters, setFilters] = useState(undefined);
  const [preloadedFilters, setPreloadedFilters] = useState(false);

  const username = useSelector((state) => state.auth.user.username);
  useEffect(() => preloadFilter(), []);

  const preloadFilter = () => {
    const storage = localStorage;
    const filter = storage.getItem('batchLiveTrackingFilters');
    if (filter) {
      const filtersToJson = JSON.parse(filter);
      setFilters(filtersToJson);
      setPreloadedFilters(true);
      return;
    }
    setPreloadedFilters(true);
    setFilters(undefined);
  };

  useEffect(() => {
    if (preloadedFilters) {
      socket.emit('batch-tracker-list', { user: username, ...filters });
    }
    return () => {
      socket.off('connect');
    };
  }, [socket, preloadedFilters]);

  useEffect(() => {
    socket.on('batch-tracker-list-data', (data) => {
      if (data.user === username || data.user === 'ALL') {
        setBatches(data.batches);
      }
    });
    return () => {
      socket.off('batch-tracker-list-data');
    };
  }, [socket]);

  const cleanUpFilterObject = (data) => {
    let obj = {};
    Object.keys(data).map((item) => {
      if (data?.[item]?.length > 0) {
        obj[item] = data[item];
      }
    });

    return obj;
  };

  const handleFilterDataChange = (key, value) => {
    let filterItem = { ...filters, [key]: value };
    setFilters(filterItem);
  };

  const saveFilters = () => {
    const cleanedUpFilters = cleanUpFilterObject(filters);
    const filterJson = JSON.stringify(cleanedUpFilters);
    localStorage.setItem('batchLiveTrackingFilters', filterJson);
    setFilters(cleanedUpFilters);
  };

  const clearFilter = () => {
    localStorage.removeItem('batchLiveTrackingFilters');
    setFilters(undefined);
    setFilters(undefined);
  };

  const handleTrackerFiltering = (isClearing) => {
    if (isClearing) {
      socket.emit('batch-tracker-list', { user: username });
      return;
    }
    socket.emit('batch-tracker-list', { user: username, ...filters });
  };

  const toggleFullScreen = () => {
    const trackerDiv = document.querySelector('#batch-live-tracker');
    if (!showFullScreenTracer) {
      trackerDiv.requestFullscreen();
      setShowFullScreenTracker(true);
    } else {
      document.exitFullscreen();
      setShowFullScreenTracker(false);
    }
  };

  const toggleFilterMenu = (val) => setShowFilterMenu(val);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}>
        <LiveTrackingFilterModule
          show={showFilterMenu}
          onClose={() => setShowFilterMenu(false)}
          filter={filters}
          saveFilters={saveFilters}
          clearFilter={clearFilter}
          handleFilter={handleTrackerFiltering}
          handleChange={handleFilterDataChange}
        />
        <Stack
          width="100%"
          minHeight="77vh"
          height="auto"
          spacing={3}
          id="batch-live-tracker"
          bgcolor="#fff"
          px={showFullScreenTracer ? 2 : 0}
          py={showFullScreenTracer ? 2 : 0}
          boxSizing="border-box">
          <LiveTrackingStats
            hasFilters={!!filters}
            toggleFullScreen={toggleFullScreen}
            toggleFilterMenu={toggleFilterMenu}
            batches={batches}
          />
          <BatchList batches={batches} />
        </Stack>
      </motion.div>
    </AnimatePresence>
  );
};

export default LiveTrackingIndex;
