import React from 'react';
import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import colors from '../../constants/colors';
import dayjs from 'dayjs';
import { FilterListOffTwoTone, PushPinTwoTone } from '@mui/icons-material';
import { pinNotice, unPinNotice } from '../../api/notice';
import { successNoitif } from '../../utils/notification';

const NoticeItem = ({ notice, socket, refreshData }) => {
  const getBgColor = () => {
    const bgcolors = {
      ALERT: colors.error,
      ANNOUNCEMENT: colors.info,
    };

    return bgcolors[notice?.type || 'ANNOUNCEMENT'];
  };

  const handlePinNotice = async () => {
    let response;

    if (notice?.shouldPin) {
      response = await unPinNotice(notice?.uid);
    } else {
      response = await pinNotice(notice?.uid);
    }
    if (response) {
      successNoitif(response);
      await refreshData();
      socket?.emit('notice');
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      bgcolor={getBgColor()}
      px={2}
      py={1}
      color="#f2f2f2"
      borderRadius={1}
      justifyContent="space-between"
      alignItems="center">
      <Stack spacing={1} alignItems="flex-start" width="40%">
        <Typography fontSize={14} fontWeight={800}>
          {notice?.title}{' '}
        </Typography>

        <Chip
          label={notice?.type}
          size="small"
          sx={{ color: '#fff', bgcolor: '#303030' }}
        />
      </Stack>
      <Tooltip sx={{ flex: 1, textAlign: 'left' }} title={notice?.message}>
        <Typography fontSize={12} fontWeight={700}>
          {`${notice?.message}`.substring(0, 50)}{' '}
          {notice?.message?.length > 50 ? '...' : ''}
        </Typography>
      </Tooltip>
      <Typography
        fontSize={14}
        fontWeight={600}
        sx={{ flex: 1, textAlign: 'left' }}>
        By {notice?.createdByInfo?.name}
      </Typography>
      <Typography
        fontSize={12}
        fontWeight={700}
        sx={{ flex: 1, textAlign: 'left' }}>
        {dayjs(notice?.createdAt).format('hh:mm A DD, ddd MMMM')}
      </Typography>
      <IconButton
        title={notice?.shouldPin ? 'Unpin this notice' : 'Pin this notice'}
        sx={{ color: '#fff' }}
        onClick={handlePinNotice}>
        {!notice?.shouldPin ? <PushPinTwoTone /> : <FilterListOffTwoTone />}
      </IconButton>
    </Stack>
  );
};

export default NoticeItem;
