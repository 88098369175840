import { Chip, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import colors from '../../../constants/colors';
import { calculateMiliseconds, formatTime } from '../../../utils/time';

const TaskCard = ({ batch }) => {
  const [blinkState, setBlickState] = useState(false);

  const caculateDelay = (batch) => {
    const currentTime = new Date();
    const estEndTime = new Date(batch?.estEndTime);

    if (estEndTime < currentTime) {
      const delay = currentTime - estEndTime;

      return 'DELAYED BY ' + calculateMiliseconds(delay);
    } else {
      return 'ON GOING';
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBlickState(!blinkState);
    }, 1000);
    return () => clearInterval(interval);
  }, [blinkState]);

  return (
    <Stack
      direction="column"
      className="task-card"
      sx={{
        height: 'auto',
        minHeight: 200,
        width: '100%',
        maxWidth: { md: '100%', xs: '100%' },
        mr: 2,
        mb: 2,
        borderRadius: 1.5,
        backgroundColor:
          caculateDelay(batch) === 'ON GOING'
            ? colors.info
            : blinkState
            ? colors.error
            : colors.warning,
      }}>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        boxSizing="border-box"
        px={1}
        pt={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography fontSize={20} color="#ffff" whiteSpace="nowrap">
            #{batch?.lotNumber.split('-')[1]}
          </Typography>
          <Chip
            size="small"
            label={caculateDelay(batch)}
            sx={{
              backgroundColor: colors.error,
              display: caculateDelay(batch) === 'ON GOING' ? 'none' : 'flex',
              width: 'max-content',
              color: 'white',
              fontSize: 10,
            }}
          />
        </Stack>
        <Stack direction="column" alignItems="flex-end">
          <Typography fontSize={20} color="#ffff">
            {batch?.machineName}
          </Typography>
        </Stack>
      </Stack>
      <Stack px={1} pb={1}>
        {batch?.nextTask ? (
          <Stack>
            <Typography fontSize="1.5rem" color="white">
              {batch?.nextTask}
            </Typography>
            <Typography fontSize="1rem" color="white">
              Est. Start Time: {formatTime(batch?.estEndTime)}
            </Typography>
          </Stack>
        ) : (
          <Stack>
            <Typography fontSize="1.7rem" color="white">
              No Upcoming Tasks
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack
        flexGrow={1}
        sx={{ background: '#171820', borderRadius: '0px 0px 5px 5px' }}>
        <Stack
          px={1}
          pt={2}
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start">
          <Typography fontSize="1rem" color="white">
            {batch?.currentTaskName}
          </Typography>
          {batch?.startTime && (
            <>
              <Typography fontSize="0.8rem" color="white">
                Started at {formatTime(batch?.startTime)}
              </Typography>
              <Typography fontSize="0.8rem" color="white">
                Started by {batch?.startedBy}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TaskCard;
