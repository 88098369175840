import {
  InfoTwoTone as Info,
  VisibilityTwoTone as Visibility,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { BATCH_DETAILS } from '../../../constants/routeUrls';
import { getBatchStatus } from '../../../utils/batch';
import { formatNumber } from '../../../utils/number';
import { milisecondsToTime } from '../../../utils/time';

const BatchListItem = ({ showBatchRemarks, batch }) => {
  const batchStatusBGColorMapper = (status) => {
    const colors = {
      'IN QUEUE': '#FFF6BF',
      'ON HOLD': '#FA7070',
      'IS PAUSED': '#FF884B',
      'WAITING FOR APPROVAL': '#B1B2FF',
    };

    return colors[status] || '#f2f2f2';
  };

  const batchStatusTEXTColorMapper = (status) => {
    const colors = {
      'IN QUEUE': '#303330',
      'ON HOLD': '#fff',
      'IS PAUSED': '#303330',
      'WAITING FOR APPROVAL': '#303330',
    };

    return colors[status] || '#303030';
  };

  const InfoBlock = ({ label, content, bgcolor, width }) => {
    return (
      <Stack
        width={width}
        alignItems="flex-start"
        justifyContent="center"
        spacing={1}
        px={1.5}
        flexGrow={1}
        bgcolor={bgcolor}>
        <Typography fontSize={12} fontWeight={600}>
          {label}
        </Typography>
        <Typography fontSize={12} fontWeight={800} component="div">
          {content}
        </Typography>
      </Stack>
    );
  };

  const calcMachineUtilization = () => {
    const util = (batch.totalFabricWeight / batch.machineCapacity) * 100;

    return util.toFixed(1);
  };

  const getPhaseColor = () => {
    const colors = {
      DYEING: '#FA7070',
      FINISHING: '#FFD384',
      QC: '#5F9DF7',
    };

    return colors[batch?.batchPhase || 'DYEING'];
  };

  const calcBatchUnloadingTime = () => {
    let unloadingTime = new Date(batch?.actualStartTime || batch?.loadingTime);

    unloadingTime.setMilliseconds(
      unloadingTime.getMilliseconds() + batch?.stdDyeTime,
    );

    return unloadingTime;
  };

  return (
    <Stack
      width="100%"
      direction="row"
      bgcolor={batchStatusBGColorMapper(batch?.status)}
      borderRadius={2}
      color={batchStatusTEXTColorMapper(batch?.status)}>
      <Stack
        width={100}
        py={2}
        borderRadius="5px 0 0 5px"
        bgcolor="#4562f7"
        color="#fff"
        px={2}>
        <Typography fontSize={12} fontWeight={600}>
          Machine
        </Typography>
        <Typography fontSize={12} fontWeight={800}>
          {batch.machineName}
        </Typography>
        <Typography fontSize={12} fontWeight={800}>
          ({calcMachineUtilization()} %)
        </Typography>
      </Stack>
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="Batch"
        content={batch?.lotNumber?.split('-')[1]}
        width={50}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label={`${batch?.processType} - ${batch?.finishingType}`}
        content={batch?.batchType}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label={'Orders'}
        content={
          <Stack
            width={80}
            overflow="auto"
            direction="row"
            spacing={1}
            py={0.5}>
            {batch?.orders?.map((item) => (
              <Tooltip key={item.fcNumber} title={item.partyName}>
                <Chip
                  label={item.fcNumber}
                  size="small"
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    px: 1,
                    py: 0.5,
                    color: '#fff',
                    bgcolor: '#303330',
                  }}
                />
              </Tooltip>
            ))}
          </Stack>
        }
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label={'Phase'}
        content={
          <Chip
            label={batch?.batchPhase}
            size="small"
            sx={{
              fontSize: 12,
              fontWeight: 700,
              bgcolor: getPhaseColor(),
              color: '#fff',
            }}
          />
        }
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label={'Status'}
        content={
          <Chip
            label={getBatchStatus(batch?.status).label}
            size="small"
            color={getBatchStatus(batch?.status).color}
            sx={{ fontSize: 12, fontWeight: 700, color: '#fff' }}
          />
        }
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="Current Task"
        content={`${batch?.currentTaskName || ''}`}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="Weight"
        content={`${formatNumber(batch?.totalFabricWeight || 0)} Kg`}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="Loading"
        content={`${dayjs(batch?.actualStartTime || batch?.loadingTime).format(
          'hh:mm A DD, MMM',
        )}`}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="Expected Unloading"
        content={`${dayjs(calcBatchUnloadingTime()).format('hh:mm A DD, MMM')}`}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="STD (Dyeing)"
        content={`${milisecondsToTime(batch.stdDyeTime)}`}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="Runtime (Dyeing)"
        content={`${milisecondsToTime(batch.runtimeDyeing)}`}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="STD."
        content={`${milisecondsToTime(batch.standardTime)}`}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="Runtime"
        content={`${milisecondsToTime(batch.runtime)}`}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="Extra (Dyeing)"
        content={`${milisecondsToTime(batch.extraTimeDyeing)}`}
        bgcolor={batch.extraTime > 0 ? '#FA7070' : ''}
      />
      <Box>
        <Divider orientation="vertical" />
      </Box>
      <InfoBlock
        label="Extra"
        content={`${milisecondsToTime(batch.extraTime)}`}
        bgcolor={batch.extraTime > 0 ? '#FA7070' : ''}
      />
      <Stack
        flexGrow={1}
        px={0.5}
        bgcolor="#f2f2f2"
        borderRadius="0 6px 6px 0"
        alignItems="flex-end"
        justifyContent="center">
        <IconButton
          title="View Details"
          component={Link}
          to={`${BATCH_DETAILS}/${batch?.lotNumber}`}>
          <Visibility />
        </IconButton>
        <IconButton
          title="View Additional Information"
          onClick={() => showBatchRemarks(batch)}>
          <Info />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default BatchListItem;
