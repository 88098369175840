import Request from './request';

const axiosV2 = new Request(2);

export const fetchAllShifts = async () => {
  const response = await axiosV2.Get('/shift');

  if (response) {
    return response.shifts;
  } else {
    return null;
  }
};

export const createShifts = async (shifts) => {
  const response = await axiosV2.Post('/shift', { shifts });
  if (response) {
    return response.shifts;
  } else {
    return null;
  }
};

export const deleteAllShifts = async () => {
  const response = await axiosV2.Delete('/shift');
  if (response) {
    return response;
  } else {
    return null;
  }
};
