export const roles = [
  {
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    label: 'Accounts',
    value: 'ACC',
  },
  {
    value: 'SI',
    label: 'Store Incharge',
  },
  {
    value: 'DPO',
    label: 'Dyeing Supervisor',
  },
  {
    value: 'FS',
    label: 'Finishing Supervisor',
  },
  {
    value: 'QCS',
    label: 'Quality Supervisor',
  },
  {
    value: 'BM',
    label: 'Batch Manager',
  },
  {
    label: 'Manager',
    value: 'MANAGER',
  },
  {
    label: 'Supervisor',
    value: 'SUPERVISOR',
  },
  {
    value: 'DI',
    label: 'Delivery Incharge',
  },
];

export const dummyClient = {
  uid: 'QQJ1KRKHDC',
  clientName: 'Test Client 5',
  shortCode: 'TC5',
  contactPerson: 'mehedi hasan shuvon',
  contactNumber: '+8801731876347',
  status: 'ACTIVE',
  createdAt: '2022-09-07T06:13:15.653Z',
};

export const clientNameList = [
  {
    uid: 'QQJ1KRKHDC',
    clientName: 'Test Client 5',
  },
  {
    uid: 'UFUHVNYNUP',
    clientName: 'Dragon Textile Ltd',
  },
  {
    uid: 'RQISONSKVT',
    clientName: 'New Client',
  },
  {
    uid: 'SCB0ST7WMS',
    clientName: 'Test Client 2',
  },
  {
    uid: '1112',
    clientName: 'Mehedi Garments Ltd',
  },
  {
    uid: '1111',
    clientName: 'Abir Garments Ltd',
  },
];

export const dummyQuotationList = [
  {
    uid: 'MGL-9227189',
    issueDate: '2022-09-27T10:10:34.000Z',
    expiryDate: '2022-10-13T10:10:34.000Z',
    status: 'WAITING FOR APPROVAL',
    process: 'D+F+E+C',
    issuedBy: 'Jamilur Rahman',
    approvedBy: null,
  },
  {
    uid: 'MHL-9226295',
    issueDate: '2022-09-08T00:00:00.000Z',
    expiryDate: '2022-10-08T00:00:00.000Z',
    status: 'WAITING FOR APPROVAL',
    process: 'D+F+E+C',
    issuedBy: 'Jamilur Rahman',
    approvedBy: null,
  },
  {
    uid: 'MGL-9227581',
    issueDate: '2022-09-08T00:00:00.000Z',
    expiryDate: '2022-10-08T00:00:00.000Z',
    status: 'WAITING FOR APPROVAL',
    process: 'D+F+E+C',
    issuedBy: 'Jamilur Rahman',
    approvedBy: null,
  },
  {
    uid: 'MGL123-9224572',
    issueDate: '2022-09-16T09:40:01.000Z',
    expiryDate: '2022-09-28T09:40:01.000Z',
    status: 'WAITING FOR APPROVAL',
    process: 'Only Dye + Dryer',
    issuedBy: 'Jamilur Rahman',
    approvedBy: null,
  },
  {
    uid: 'MGL123-9227236',
    issueDate: '2022-09-13T07:26:44.100Z',
    expiryDate: '2022-09-28T07:26:44.000Z',
    status: 'WAITING FOR APPROVAL',
    process: 'Only Dye + Dryer',
    issuedBy: 'Jamilur Rahman',
    approvedBy: null,
  },
  {
    uid: 'MGL-9222476',
    issueDate: '2022-09-13T10:05:56.000Z',
    expiryDate: '2022-09-24T10:05:56.000Z',
    status: 'WAITING FOR APPROVAL',
    process: 'D+F+E+C',
    issuedBy: 'Jamilur Rahman',
    approvedBy: null,
  },
  {
    uid: 'MGL123-9223366',
    issueDate: '2022-09-15T07:20:33.000Z',
    expiryDate: '2022-09-22T07:20:33.000Z',
    status: 'WAITING FOR APPROVAL',
    process: 'D+F+E+C',
    issuedBy: 'Jamilur Rahman',
    approvedBy: null,
  },
  {
    uid: 'MGL123-9222620',
    issueDate: '2022-09-12T09:31:52.790Z',
    expiryDate: '2022-09-12T09:31:52.790Z',
    status: 'WAITING FOR APPROVAL',
    process: 'Only Dye + Dryer',
    issuedBy: 'Jamilur Rahman',
    approvedBy: null,
  },
  {
    uid: 'MGL-9226357',
    issueDate: '2022-09-08T08:46:56.734Z',
    expiryDate: '2022-09-08T08:46:56.734Z',
    status: 'WAITING FOR APPROVAL',
    process: 'D+F+E+C',
    issuedBy: null,
    approvedBy: null,
  },
];

export const dummyProcessDetails = {
  additionalStepCharges: {
    heatSetCharge: 18,
    siliconCharge: 4,
    stenterCharge: 10,
    compactingCharge: 15,
  },
  clientShades: {
    id: 13,
    shadeName: 'AVG',
    shadeRange: 'Below 3%',
    open: 100,
    tube: 90,
    lycraOpen: 135,
    doublePartOpen: 140,
    doublePartTube: 160,
    remarks: '',
  },
};

export const dummyShadeGroup = [
  {
    shadeId: 0,
    shadeName: 'AVG',
    shadeRange: '1 to 5%',
    color: [
      {
        colorId: 0,
        colorName: 'Red',
      },
      {
        colorId: 1,
        colorName: 'Blue',
      },
    ],
  },
  {
    shadeId: 1,
    shadeName: 'Light',
    shadeRange: '0 to 2%',
    color: [
      {
        colorId: 0,
        colorName: 'Light Red',
      },
      {
        colorId: 1,
        colorName: 'Light Blue',
      },
    ],
  },
  {
    shadeId: 2,
    shadeName: 'Dark',
    shadeRange: '5 to 10%',
    color: [
      {
        colorId: 0,
        colorName: 'Dark Red',
      },
      {
        colorId: 1,
        colorName: 'Dark Blue',
      },
    ],
  },
];

export const dummyFabricType = [
  {
    fabricId: 0,
    fabricName: 'f1',
  },
  {
    fabricId: 1,
    fabricName: 'f2',
  },
  {
    fabricId: 2,
    fabricName: 'f3',
  },
];

export const dummyProcessDescriptionList = [
  {
    processId: 0,
    description:
      'Hello This is Desrciption no 1 but my actual id is 0. you can change me if you want!',
  },
  {
    processId: 1,
    description:
      'Hello This is Desrciption no 2 but my actual id is 1. you can change me if you want!',
  },
  {
    processId: 2,
    description:
      'Hello This is Desrciption no 3 but my actual id is 2. you can change me if you want!',
  },
];

export const machine = [
  {
    id: '0',
    name: 'machine 1',
    status: 'idle',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '1',
    machineType: 'DYEING',
  },
  {
    id: '1',
    name: 'machine 2',
    status: 'in use',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '2',
    machineType: 'FINISHING',
  },
  {
    id: '2',
    name: 'machine 3',
    status: 'maintenance',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '3',
    machineType: 'DYEING',
  },
  {
    id: '3',
    name: 'machine 4',
    status: 'out of order',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '4',
    machineType: 'FINISHING',
  },
  {
    id: '4',
    name: 'machine 5',
    status: 'idle',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '5',
    machineType: 'DYEING',
  },
  {
    id: '5',
    name: 'machine 6',
    status: 'maintenance',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '6',
    machineType: 'DYEING',
  },
  {
    id: '6',
    name: 'machine 7',
    status: 'idle',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '1',
    machineType: 'FINISHING',
  },
  {
    id: '7',
    name: 'machine 8',
    status: 'in use',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '2',
    machineType: 'DYEING',
  },
  {
    id: '8',
    name: 'machine 9',
    status: 'maintenance',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '3',
    machineType: 'DYEING',
  },
  {
    id: '9',
    name: 'machine 10',
    status: 'out of order',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '4',
    machineType: 'FINISHING',
  },
  {
    id: '10',
    name: 'machine 11',
    status: 'idle',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '5',
    machineType: 'DYEING',
  },
  {
    id: '11',
    name: 'machine 12',
    status: 'maintenance',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '6',
    machineType: 'FINISHING',
  },
  {
    id: '12',
    name: 'machine 13',
    status: 'maintenance',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '3',
    machineType: 'FINISHING',
  },
  {
    id: '13',
    name: 'machine 14',
    status: 'out of order',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '4',
    machineType: 'DYEING',
  },
  {
    id: '14',
    name: 'machine 15',
    status: 'idle',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '5',
    machineType: 'FINISHING',
  },
  {
    id: '15',
    name: 'machine 16',
    status: 'maintenance',
    maxCapacity: '700',
    capacity: '600',
    minCapacity: '500',
    batchNo: '6',
    machineType: 'DYEING',
  },
];

export const client = [
  {
    uid: '1',
    name: 'Client1',
    shortCode: 'C1',
    status: 'ACTIVE',
    contactPerson: 'Mehedi Hasan',
    contactNumber: '01731876347',
  },
  {
    uid: '2',
    name: 'Client2',
    shortCode: 'C2',
    status: 'INACTIVE',
    contactPerson: 'Mehedi Hasan',
    contactNumber: '01731876347',
  },
  {
    uid: '3',
    name: 'Client3',
    shortCode: 'C3',
    status: 'ACTIVE',
    contactPerson: 'Mehedi Hasan',
    contactNumber: '01731876347',
  },
  {
    uid: '4',
    name: 'Client4',
    shortCode: 'C4',
    status: 'INACTIVE',
    contactPerson: 'Mehedi Hasan',
    contactNumber: '01731876347',
  },
  {
    uid: '5',
    name: 'Client5',
    shortCode: 'C5',
    status: 'ACTIVE',
    contactPerson: 'Mehedi Hasan',
    contactNumber: '01731876347',
  },
  {
    uid: '6',
    name: 'Client6',
    shortCode: 'C6',
    status: 'INACTIVE',
    contactPerson: 'Mehedi Hasan',
    contactNumber: '01731876347',
  },
  {
    uid: '7',
    name: 'Client7',
    shortCode: 'C7',
    status: 'ACTIVE',
    contactPerson: 'Mehedi Hasan',
    contactNumber: '01731876347',
  },
];

export const quotation = [
  {
    id: '1',
    issueDate: '2022-08-10T12:21:36.014Z',
    validDate: '2022-08-18T01:05:33.000Z',
    status: 'VALID',
    issuedBy: 'Mehedi Hasan ',
    approvedBy: 'Mehedi Hasan ',
  },
  {
    id: '2',
    issueDate: '2022-08-10T12:21:36.014Z',
    validDate: '2022-08-18T01:05:33.000Z',
    status: 'EXPIRED',
    issuedBy: 'Mehedi Hasan ',
    approvedBy: 'Mehedi Hasan ',
  },
  {
    id: '3',
    issueDate: '2022-08-10T12:21:36.014Z',
    validDate: '2022-08-18T01:05:33.000Z',
    status: 'VALID',
    issuedBy: 'Mehedi Hasan ',
    approvedBy: 'Mehedi Hasan ',
  },
  {
    id: '4',
    issueDate: '2022-08-10T12:21:36.014Z',
    validDate: '2022-08-18T01:05:33.000Z',
    status: 'EXPIRED',
    issuedBy: 'Mehedi Hasan ',
    approvedBy: 'Mehedi Hasan ',
  },
  {
    id: '5',
    issueDate: '2022-08-10T12:21:36.014Z',
    validDate: '2022-08-18T01:05:33.000Z',
    status: 'VALID',
    issuedBy: 'Mehedi Hasan ',
    approvedBy: 'Mehedi Hasan ',
  },
];

//------------------------------- orders------------------//
export const orders = {
  status: 200,
  orders: [
    {
      id: 1,
      fcNumber: '4564',
      partyName: 'BuildX',
      clientRefNo: 'Abir Rahman',
      status: 'IN QUEUE',
      remarks: null,
      slaesPerson: 'Abir Rahman',
      deliveryDate: '2022-08-25T07:30:54.953Z',
      clientContantPerson: 'Abir Rahman',
      clientAddress: 'Arex Foundation',
      clientPhone: '+88015851520',
      clientId: 1,
      createdAt: '2022-08-01T07:42:50.996Z',
      updatedAt: '+222022-08-02T05:07:53.000Z',
      fabricInfo: [
        {
          id: 1,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 1200,
          processed: 0,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 1,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-02T04:38:17.000Z',
        },
        {
          id: 3,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 1100,
          processed: 0,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 1,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-02T04:59:52.000Z',
        },
        {
          id: 2,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 250,
          processed: 0,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 1,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-02T05:01:27.000Z',
        },
        {
          id: 4,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 200,
          processed: 150,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 1,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-02T04:53:43.000Z',
        },
      ],
      batchInfo: [],
    },
    {
      id: 3,
      fcNumber: '4566',
      partyName: 'Space X',
      clientRefNo: 'Abir Rahman',
      status: 'IN QUEUE',
      remarks: null,
      slaesPerson: 'Abir Rahman',
      deliveryDate: '2022-08-25T07:30:54.953Z',
      clientContantPerson: 'Abir Rahman',
      clientAddress: 'Arex Foundation',
      clientPhone: '+88015851520',
      clientId: 1,
      createdAt: '2022-08-01T07:42:50.996Z',
      updatedAt: '+222022-08-10T09:05:28.000Z',
      fabricInfo: [
        {
          id: 9,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 6,
          processed: 0,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 3,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-10T09:05:28.000Z',
        },
        {
          id: 10,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 1055,
          processed: 0,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 3,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-07T05:35:50.000Z',
        },
        {
          id: 11,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 150,
          processed: 50,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 3,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-10T09:05:28.000Z',
        },
      ],
      batchInfo: [
        {
          id: 27,
          lotNumber: '8049-1324',
          totalFabricWeight: 657,
          processType: 'BULK',
          status: 'PARTIAL DELIVERED',
          isApproved: false,
          deliveryDate: '2022-08-05T09:53:18.000Z',
          delay: 0,
          priority: 'LOW',
          finishingType: 'TUBE',
          isComplete: true,
          early: 0,
          actualStartTime: null,
          actualEndTime: null,
          estEndTime: null,
          loadingTime: '2022-08-10T09:15:18.000Z',
          batchType: 'Batch To Batch',
          dyeingRemark: null,
          finishingRemark: null,
          qcRemark: null,
          deliveryRemark: null,
          batchRejectionRemark: null,
          orderId: null,
          approverId: null,
          creatorId: 3,
          machineId: 2,
          reRunBatchRef: null,
          createdAt: '2022-08-04T10:20:31.080Z',
          updatedAt: '+222022-08-29T09:19:53.000Z',
          shiftId: null,
          delivered: 0,
          remaining: 657,
        },
        {
          id: 28,
          lotNumber: '4290-12345',
          totalFabricWeight: 265,
          processType: 'BULK',
          status: 'PARTIAL DELIVERED',
          isApproved: false,
          deliveryDate: '2022-08-24T05:08:40.000Z',
          delay: 0,
          priority: 'LOW',
          finishingType: 'TUBE',
          isComplete: true,
          early: 0,
          actualStartTime: null,
          actualEndTime: null,
          estEndTime: null,
          loadingTime: '2022-08-15T10:08:40.000Z',
          batchType: 'Batch To Batch',
          dyeingRemark: null,
          finishingRemark: null,
          qcRemark: null,
          deliveryRemark: null,
          batchRejectionRemark: null,
          orderId: null,
          approverId: null,
          creatorId: 3,
          machineId: 1,
          reRunBatchRef: null,
          createdAt: '2022-08-07T05:12:57.695Z',
          updatedAt: '+222022-08-29T09:55:43.000Z',
          shiftId: null,
          delivered: 20,
          remaining: 245,
        },
        {
          id: 25,
          lotNumber: '1384-2500',
          totalFabricWeight: 0,
          processType: 'SAMPLE',
          status: 'DRAFT',
          isApproved: false,
          deliveryDate: '2022-08-31T05:45:56.000Z',
          delay: 0,
          priority: 'LOW',
          finishingType: 'TUBE',
          isComplete: true,
          early: 0,
          actualStartTime: null,
          actualEndTime: null,
          estEndTime: '2022-08-23T18:25:56.000Z',
          loadingTime: '2022-08-23T18:00:56.000Z',
          batchType: 'Batch To Batch',
          dyeingRemark: null,
          finishingRemark: null,
          qcRemark: null,
          deliveryRemark: null,
          batchRejectionRemark: null,
          orderId: null,
          approverId: null,
          creatorId: 3,
          machineId: 3,
          reRunBatchRef: null,
          createdAt: '2022-08-02T05:46:26.495Z',
          updatedAt: '+222022-08-10T09:01:35.000Z',
          shiftId: null,
          delivered: 0,
          remaining: 0,
        },
        {
          id: 34,
          lotNumber: '8292-12602',
          totalFabricWeight: 300,
          processType: 'RE PROCESS',
          status: 'DRAFT',
          isApproved: false,
          deliveryDate: '2022-08-25T07:58:17.000Z',
          delay: 0,
          priority: 'LOW',
          finishingType: 'TUBE',
          isComplete: true,
          early: 0,
          actualStartTime: null,
          actualEndTime: null,
          estEndTime: '2022-08-29T10:16:17.000Z',
          loadingTime: '2022-08-29T06:00:17.000Z',
          batchType: 'Batch To Batch',
          dyeingRemark: null,
          finishingRemark: null,
          qcRemark: null,
          deliveryRemark: null,
          batchRejectionRemark: null,
          orderId: null,
          approverId: null,
          creatorId: 3,
          machineId: 3,
          reRunBatchRef: '1384-2500',
          createdAt: '2022-08-10T08:12:54.082Z',
          updatedAt: '+222022-08-14T08:22:21.000Z',
          shiftId: null,
          delivered: 0,
          remaining: 300,
        },
      ],
    },
    {
      id: 2,
      fcNumber: '4565',
      partyName: 'Tesla',
      clientRefNo: 'Abir Rahman',
      status: 'IN QUEUE',
      remarks: null,
      slaesPerson: 'Abir Rahman',
      deliveryDate: '2022-08-25T07:30:54.953Z',
      clientContantPerson: 'Abir Rahman',
      clientAddress: 'Arex Foundation',
      clientPhone: '+88015851520',
      clientId: 1,
      createdAt: '2022-08-01T07:42:50.996Z',
      updatedAt: '+222022-08-10T10:19:01.000Z',
      fabricInfo: [
        {
          id: 8,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 66,
          processed: 0,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 2,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-10T10:19:01.000Z',
        },
        {
          id: 5,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 34,
          processed: 0,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 2,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-10T10:19:01.000Z',
        },
        {
          id: 6,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 566,
          processed: 0,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 2,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-07T05:35:50.000Z',
        },
        {
          id: 7,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 416,
          processed: 400,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 2,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-07T05:35:50.000Z',
        },
      ],
      batchInfo: [
        {
          id: 27,
          lotNumber: '8049-1324',
          totalFabricWeight: 657,
          processType: 'BULK',
          status: 'PARTIAL DELIVERED',
          isApproved: false,
          deliveryDate: '2022-08-05T09:53:18.000Z',
          delay: 0,
          priority: 'LOW',
          finishingType: 'TUBE',
          isComplete: true,
          early: 0,
          actualStartTime: null,
          actualEndTime: null,
          estEndTime: null,
          loadingTime: '2022-08-10T09:15:18.000Z',
          batchType: 'Batch To Batch',
          dyeingRemark: null,
          finishingRemark: null,
          qcRemark: null,
          deliveryRemark: null,
          batchRejectionRemark: null,
          orderId: null,
          approverId: null,
          creatorId: 3,
          machineId: 2,
          reRunBatchRef: null,
          createdAt: '2022-08-04T10:20:31.080Z',
          updatedAt: '+222022-08-29T09:19:53.000Z',
          shiftId: null,
          delivered: 0,
          remaining: 657,
        },
        {
          id: 28,
          lotNumber: '4290-12345',
          totalFabricWeight: 265,
          processType: 'BULK',
          status: 'PARTIAL DELIVERED',
          isApproved: false,
          deliveryDate: '2022-08-24T05:08:40.000Z',
          delay: 0,
          priority: 'LOW',
          finishingType: 'TUBE',
          isComplete: true,
          early: 0,
          actualStartTime: null,
          actualEndTime: null,
          estEndTime: null,
          loadingTime: '2022-08-15T10:08:40.000Z',
          batchType: 'Batch To Batch',
          dyeingRemark: null,
          finishingRemark: null,
          qcRemark: null,
          deliveryRemark: null,
          batchRejectionRemark: null,
          orderId: null,
          approverId: null,
          creatorId: 3,
          machineId: 1,
          reRunBatchRef: null,
          createdAt: '2022-08-07T05:12:57.695Z',
          updatedAt: '+222022-08-29T09:55:43.000Z',
          shiftId: null,
          delivered: 20,
          remaining: 245,
        },
        {
          id: 26,
          lotNumber: '9279-65602',
          totalFabricWeight: 1100,
          processType: 'SAMPLE',
          status: 'PARTIAL DELIVERED',
          isApproved: false,
          deliveryDate: '2022-08-24T06:22:46.000Z',
          delay: 0,
          priority: 'LOW',
          finishingType: 'OPEN',
          isComplete: true,
          early: 0,
          actualStartTime: null,
          actualEndTime: null,
          estEndTime: '2022-08-22T11:38:46.000Z',
          loadingTime: '2022-08-22T08:01:46.000Z',
          batchType: 'New Shade',
          dyeingRemark: null,
          finishingRemark: null,
          qcRemark: null,
          deliveryRemark: null,
          batchRejectionRemark: null,
          orderId: null,
          approverId: null,
          creatorId: 3,
          machineId: 1,
          reRunBatchRef: null,
          createdAt: '2022-08-02T06:23:09.594Z',
          updatedAt: '+222022-08-29T10:17:39.000Z',
          shiftId: null,
          delivered: 10,
          remaining: 1090,
        },
        {
          id: 35,
          lotNumber: '1799-5000',
          totalFabricWeight: 30,
          processType: 'BULK',
          status: 'DRAFT',
          isApproved: false,
          deliveryDate: '2022-08-30T10:13:06.000Z',
          delay: 0,
          priority: 'LOW',
          finishingType: 'TUBE',
          isComplete: true,
          early: 0,
          actualStartTime: null,
          actualEndTime: null,
          estEndTime: null,
          loadingTime: '2022-08-22T06:00:06.000Z',
          batchType: 'Batch To Batch',
          dyeingRemark: null,
          finishingRemark: null,
          qcRemark: null,
          deliveryRemark: null,
          batchRejectionRemark: null,
          orderId: null,
          approverId: null,
          creatorId: 3,
          machineId: 1,
          reRunBatchRef: null,
          createdAt: '2022-08-10T10:19:01.714Z',
          updatedAt: '2022-08-10T10:19:01.714Z',
          shiftId: null,
          delivered: 0,
          remaining: 30,
        },
      ],
    },
    {
      id: 5,
      fcNumber: '4568',
      partyName: 'Google',
      clientRefNo: 'Abir Rahman',
      status: 'IN QUEUE',
      remarks: null,
      slaesPerson: 'Abir Rahman',
      deliveryDate: '2022-08-25T07:30:54.953Z',
      clientContantPerson: 'Abir Rahman',
      clientAddress: 'Arex Foundation',
      clientPhone: '+88015851520',
      clientId: 1,
      createdAt: '2022-08-01T07:42:50.996Z',
      updatedAt: '2022-08-01T07:42:50.996Z',
      fabricInfo: [],
      batchInfo: [],
    },
    {
      id: 4,
      fcNumber: '4567',
      partyName: 'Apple',
      clientRefNo: 'Abir Rahman',
      status: 'DELIVERED',
      remarks: null,
      slaesPerson: 'Abir Rahman',
      deliveryDate: '2022-08-25T07:30:54.953Z',
      clientContantPerson: 'Abir Rahman',
      clientAddress: 'Arex Foundation',
      clientPhone: '+88015851520',
      clientId: 1,
      createdAt: '2022-08-01T07:42:50.996Z',
      updatedAt: '2022-08-01T07:42:50.996Z',
      fabricInfo: [
        {
          id: 12,
          color: 'RED',
          shadeGroup: 'RED',
          fabricType: 'Lakra',
          shade: 0.55,
          gsm: 346,
          dia: 56,
          quantity: 10000,
          processing: 1200,
          processed: 150,
          status: 'ASSIGNED',
          processDescription: '4634',
          labDipRef: '34645',
          orderId: 4,
          createdAt: '2022-08-01T07:47:40.964Z',
          updatedAt: '+222022-08-02T04:38:17.000Z',
        },
      ],
      batchInfo: [],
    },
  ],
  totalEntries: 5,
};

//----------for testing---------------------//
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
export const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: 'Schindlers List', year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
];

//-----------------------------------fabric info------------------------------//
export const fabricInformation = {
  status: 200,
  fabrics: [
    {
      id: 2182,
      color: 'Begonia Pink-2720-0237/SGL-22-0954',
      shadeGroup: 'Avg',
      fabricType: 'S-Jersey (.)',
      shade: 1,
      gsm: 155,
      dia: 68,
      quantity: 282.5,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+Open+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2183,
      color: 'Begonia Pink-2720-0237/SGL-22-0954',
      shadeGroup: 'Avg',
      fabricType: '1X1 L-RIB (.)',
      shade: 1,
      gsm: 200,
      dia: 35,
      quantity: 25.22,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+T+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2184,
      color: 'Snow White-2720-0238/SGL-22-0955',
      shadeGroup: 'White',
      fabricType: 'S-Jersey (.)',
      shade: 1,
      gsm: 155,
      dia: 76,
      quantity: 383.27,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+Open+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2185,
      color: 'Snow White-2720-0238/SGL-22-0955',
      shadeGroup: 'White',
      fabricType: '1X1 L-RIB (.)',
      shade: 1,
      gsm: 200,
      dia: 1,
      quantity: 24.89,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+T+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2186,
      color: 'Begonia Pink-2720-0238/SGL-22-0955',
      shadeGroup: 'Avg',
      fabricType: 'S-Jersey (.)',
      shade: 1,
      gsm: 155,
      dia: 76,
      quantity: 191.71,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+Open+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2187,
      color: 'Begonia Pink-2720-0238/SGL-22-0955',
      shadeGroup: 'Avg',
      fabricType: '1X1 L-RIB (.)',
      shade: 1,
      gsm: 200,
      dia: 1,
      quantity: 12.45,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+T+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2188,
      color: 'Purple Rose-2720-0239/SGL-22-0956',
      shadeGroup: 'Avg',
      fabricType: 'S-Jersey (.)',
      shade: 1,
      gsm: 155,
      dia: 66,
      quantity: 319.98,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+Open+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2189,
      color: 'Purple Rose-2720-0239/SGL-22-0956',
      shadeGroup: 'Avg',
      fabricType: '1X1 L-RIB (.)',
      shade: 1,
      gsm: 200,
      dia: 1,
      quantity: 28.83,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+T+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2190,
      color: 'Placid Blue-2720-0239/SGL-22-0956',
      shadeGroup: 'Avg',
      fabricType: 'S-Jersey (.)',
      shade: 1,
      gsm: 155,
      dia: 66,
      quantity: 279.98,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+Open+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2191,
      color: 'Placid Blue-2720-0239/SGL-22-0956',
      shadeGroup: 'Avg',
      fabricType: '1X1 L-RIB (.)',
      shade: 1,
      gsm: 200,
      dia: 1,
      quantity: 25.22,
      processed: 0,
      status: 'IN QUEUE',
      processDescription: 'D+F+Enz+T+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-18T12:13:37.763Z',
      labDipRef: '',
      batchInfo: [],
    },
    {
      id: 2180,
      color: 'Snow White-2720-0237/SGL-22-0954',
      shadeGroup: 'White',
      fabricType: 'S-Jersey (.)',
      shade: 1,
      gsm: 155,
      dia: 68,
      quantity: 322.86,
      processed: 322.86,
      status: 'PROCESSED',
      processDescription: 'D+F+Enz+Open+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-22T03:48:48.000Z',
      labDipRef: '',
      batchInfo: [
        {
          processingQuantity: 322.86,
          lotNumber: '4558-8928',
          status: 'WAITING FOR DELIVERY',
          loadTime: '17:00:00',
          loadDate: '2022-08-20T00:00:00.000Z',
          isComplete: true,
          priority: 'HIGH',
        },
      ],
    },
    {
      id: 2181,
      color: 'Snow White-2720-0237/SGL-22-0954',
      shadeGroup: 'White',
      fabricType: '1X1 L-RIB (.)',
      shade: 1,
      gsm: 200,
      dia: 35,
      quantity: 28.83,
      processed: 28.83,
      status: 'PROCESSED',
      processDescription: 'D+F+Enz+T+',
      customerId: 336,
      createdAt: '2022-08-18T12:13:37.763Z',
      updatedAt: '2022-08-22T03:48:48.000Z',
      labDipRef: '',
      batchInfo: [
        {
          processingQuantity: 28.83,
          lotNumber: '4558-8928',
          status: 'WAITING FOR DELIVERY',
          loadTime: '17:00:00',
          loadDate: '2022-08-20T00:00:00.000Z',
          isComplete: true,
          priority: 'HIGH',
        },
      ],
    },
  ],
};

//---------------------dummy order----------------
export const dummyOrder = {
  clientUid: 'Y3DQVUY5SG',
  clientName: 'Liberty Knitwear Ltd',
  quotationUid: 'LKL-9220959',
  fcNumber: '123123',
  issueDate: '2022-09-22T06:40:53.775Z',
  clientRefNo: 'LKL',
  remarks: 'this is a remaks',
  salesPerson: 'mehedi',
  deliveryDate: '2022-09-22T06:40:53.775Z',
  clientContactPerson: 'Mr. Rahim Sharker',
  deliveryAddress: 'banani',
  clientContactPhone: '+8801711355057',
  fabricInfo: [
    {
      shadeGroupName: 'White',
      shadeGroupId: 1,
      colourName: 'orange',
      colourId: 10,
      shadeRange: '2-3$',
      fabricTypeName: 'Lycra',
      fabricTypeId: 1,
      gsm: 1,
      dia: 1,
      processName: 'Only Dye + Dryer',
      processDetails: {
        processCharge: {
          doublePartTube: 108,
        },
        additionalStepCharge: {
          compactingCharge: 12,
        },
      },
      rate: 120,
      quantity: 5,
      labDipRef: '1',
    },
    {
      shadeGroupName: 'AVG',
      shadeGroupId: 2,
      colourName: 'pink',
      colourId: 2,
      shadeRange: '4-6%',
      fabricTypeName: 'CVC',
      fabricTypeId: 2,
      gsm: 2,
      dia: 2,
      processName: 'Only Dye + Dryer',
      processDetails: {
        processCharge: {
          open: 100,
        },
        additionalStepCharge: {
          siliconCharge: 4,
          stenterCharge: 18,
        },
      },
      rate: 122,
      quantity: 2,
      labDipRef: '2',
    },
  ],
};

//--------------------------------------order delivery------------------------------//

export const orderDelivery = {
  fabricInfo: [
    {
      fabricId: 1,
      color: 'RED',
      dia: 56,
      shadeGroup: 'RED',
      gsm: 346,
      fabricName: 'Fabric 1',
      TotalQuantity: 900,
      TotalDeliverable: 500,
      batchInfo: [
        {
          batchId: 11,
          batchTotal: 500,
          batchDeliverable: 300,
          tempDeliverable: 0,
          remaining: 300,
          delivered: 0,
        },
        {
          batchId: 12,
          batchTotal: 200,
          batchDeliverable: 150,
          tempDeliverable: 0,
          remaining: 150,
          delivered: 0,
        },
        {
          batchId: 13,
          batchTotal: 200,
          batchDeliverable: 50,
          tempDeliverable: 0,
          remaining: 50,
          delivered: 0,
        },
      ],
    },
    {
      fabricId: 2,
      fabricName: 'Fabric 2',
      color: 'RED',
      dia: 56,
      shadeGroup: 'RED',
      gsm: 346,
      TotalQuantity: 700,
      TotalDeliverable: 400,
      batchInfo: [
        {
          batchId: 21,
          batchTotal: 500,
          batchDeliverable: 300,
          tempDeliverable: 0,
          remaining: 300,
          delivered: 0,
        },
        {
          batchId: 22,
          batchTotal: 100,
          batchDeliverable: 70,
          tempDeliverable: 0,
          remaining: 70,
          delivered: 0,
        },
        {
          batchId: 23,
          batchTotal: 100,
          batchDeliverable: 30,
          tempDeliverable: 0,
          remaining: 30,
          delivered: 0,
        },
      ],
    },
    {
      fabricId: 3,
      fabricName: 'Fabric 3',
      color: 'RED',
      dia: 56,
      shadeGroup: 'RED',
      gsm: 346,
      TotalQuantity: 800,
      TotalDeliverable: 450,
      batchInfo: [
        {
          batchId: 31,
          batchTotal: 500,
          batchDeliverable: 200,
          tempDeliverable: 0,
          remaining: 200,
          delivered: 0,
        },
        {
          batchId: 32,
          batchTotal: 200,
          batchDeliverable: 200,
          tempDeliverable: 0,
          remaining: 200,
          delivered: 0,
        },
        {
          batchId: 33,
          batchTotal: 100,
          batchDeliverable: 50,
          tempDeliverable: 0,
          remaining: 50,
          delivered: 0,
        },
      ],
    },
  ],
};

export const DummyDeliveryTable = {
  DeliveryList: [
    {
      deliveryDate: '2022-09-22T06:40:53.775Z',
      DeliveryId: 0,
      DeliveryQuantity: 100,
      DeliveryContactNumber: '+8801711355057',
      DeliveryContactPerson: 'Mr. Rahim Sharker',
      deliveryAddress: 'banani',
      deliveryStatus: 'WATITING FOR APPROVAL',
    },
    {
      deliveryDate: '2022-09-22T06:40:53.775Z',
      DeliveryId: 1,
      DeliveryQuantity: 200,
      DeliveryContactNumber: '+8801711355057',
      DeliveryContactPerson: 'Mehedi Hasan Shuvon',
      deliveryAddress: 'banani',
      deliveryStatus: 'WAITING FOR DELIVERY',
    },
    {
      deliveryDate: '2022-09-22T06:40:53.775Z',
      DeliveryId: 2,
      DeliveryQuantity: 150,
      DeliveryContactNumber: '+8801711355057',
      DeliveryContactPerson: 'Mr. Rahim Sharker',
      deliveryAddress: 'banani',
      deliveryStatus: 'DELIVERED',
    },
  ],
  totalEntries: 3,
};

export const DummyPrintDeliveryInformation = {
  partyName: 'Liberty Knitwear Ltd',
  vehicleNumber: '',
  deliveryContatct: 'MGL123',
  deliveryContatctNumber: '+8801711355057',
  deliveryAddress: 'AREX Factory Pickup random text random text random text',
  deliveryDate: '2022-09-22T00:00:00.000Z',
  deliveryInfo: [
    {
      fabricId: 26,
      fabricName: 'Lycra',
      batchInfo: [
        {
          tempDeliverable: 60,
          lotNumber: '69696',
          processQuantity: 500,
          rolls: 5,
          reProcessQty: 0,
          fabricStatus: 'PROCESSING',
          batchStatus: 'IN QUEUE',
          batchDeliverable: 100,
        },
        {
          tempDeliverable: 40,
          lotNumber: '13213',
          processQuantity: 600,
          rolls: 4,
          reProcessQty: 0,
          fabricStatus: 'PROCESSING',
          batchStatus: 'IN QUEUE',
          batchDeliverable: 100,
        },
      ],
    },

    {
      fabricId: 26,
      fabricName: 'Lycra',
      batchInfo: [
        {
          tempDeliverable: 60,
          lotNumber: '69696',
          processQuantity: 500,
          rolls: 5,
          reProcessQty: 0,
          fabricStatus: 'PROCESSING',
          batchStatus: 'IN QUEUE',
          batchDeliverable: 100,
        },
        {
          tempDeliverable: 40,
          lotNumber: '13213',
          processQuantity: 600,
          rolls: 4,
          reProcessQty: 0,
          fabricStatus: 'PROCESSING',
          batchStatus: 'IN QUEUE',
          batchDeliverable: 100,
        },
      ],
    },
  ],
};

export const dummyMachineInfo = {
  machineId: 'Y3DQVUY5SG',
  machineName: 'AK-47',
  fcNumber: '123123',
  issueDate: '2022-09-22T06:40:53.775Z',
  machineRefNo: 'LKL',
  status: 'IDLE',
  machineType: 'Dyeing',
  capacity: 800,
  maxCapacity: 700,
  minCapacity: 500,
  remarks:
    'This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.This is a big remarks.',
  salesPerson: 'Mehedi Hasan',
  machineAddress: 'Arex Factory',
  deliveryDate: '2022-09-22T06:40:53.775Z',
  clientContactPerson: 'Mr. Rahim Sharker',
  deliveryAddress: 'banani',
  clientContactPhone: '+8801711355057',
  machineInfo: [
    {
      uid: '1',
      status: 'IDLE',
      capacity: 800,
      maxCapacity: 700,
      minCapacity: 500,
      machineType: 'Dyeing',
    },
    {
      uid: '2',
      status: 'IDLE',
      capacity: 800,
      maxCapacity: 700,
      minCapacity: 500,
      machineType: 'Dyeing',
    },
  ],
};

export const program = [
  {
    title: 'title 1',
    sinceTime: '2022-09-22T06:40:53.775Z',
    tillTime: '2022-09-22T06:50:53.775Z',
  },
];
