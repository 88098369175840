import * as yup from 'yup';
export const MachineCreationValidator = yup.object().shape({
  name: yup.string().min(3).required('Must enter name'),
  capacity: yup
    .number()
    .typeError('Must be greater than 0')
    .positive('Must be greater than 0')
    .required('Must enter capacity'),
  maxCapacity: yup
    .number()
    .typeError('Must be greater than 0')
    .positive('Must be greater than 0')
    .max(yup.ref('capacity'), 'Max capacity can not exceed capacity')
    .required('Must enter max capacity'),
  minCapacity: yup
    .number()
    .typeError('Must be greater than 0')
    .positive('must be greater than 0')
    .max(yup.ref('maxCapacity'), 'Min capacity can not exceed Max capacity')
    .required('Must enter min capacity'),
  machineType: yup.string().min(3).required('Must select machine type'),
});

export const MachineUpdateValidator = yup.object().shape({
  name: yup.string().min(3).required('Must enter name'),
  capacity: yup
    .number()
    .typeError('Must be greater than 0')
    .positive('Must be greater than 0')
    .required('Must enter capacity'),
  maxCapacity: yup
    .number()
    .typeError('Must be greater than 0')
    .positive('must be greater than 0')
    .max(yup.ref('capacity'), 'Max capacity can not exceed capacity')
    .required('Must enter max capacity'),
  minCapacity: yup
    .number()
    .typeError('Must be greater than 0')
    .positive('Must be greater than 0')
    .max(yup.ref('maxCapacity'), 'Min capacity can not exceed Max capacity')
    .required('Must enter min capacity'),
  machineType: yup.string().min(3).required('Must select machine type'),
});
