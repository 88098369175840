export const SIGNIN = '/';

export const DASHBOARD = '/dashboard';

export const BATCH = DASHBOARD + '/batches';
export const BATCH_ADD = BATCH + '/add';
export const BATCH_EDIT = BATCH + '/edit';
export const RE_PROCESS_BATCH_CREATE = BATCH + '/reprocess';
export const BATCH_DETAILS = BATCH + '/details';

export const USERS = DASHBOARD + '/users';
export const USER_DETAILS = USERS + '/details';

export const SETTINGS = DASHBOARD + '/settings';
export const UTILITY_SETTINGS = DASHBOARD + '/settings/utility/';

export const MACHINE = DASHBOARD + '/mechine';
export const MACHINE_DETAILS = MACHINE + '/details';

export const ORDER = DASHBOARD + '/order';
export const CREATE_ORDER = ORDER + '/add';
export const CLIENT = DASHBOARD + '/client';
export const CLIENT_DETAILS = CLIENT + '/details';
export const ORDER_DETAILS = ORDER + '/details';

export const EDIT_ORDER = CLIENT + '/editOrder';
export const EDIT_BULK_ORDER = CLIENT + '/editBulkOrder';
export const CREATE_QUOTATION = CLIENT + '/createQuotation';
export const EDIT_QUOTATION = CLIENT + '/editQuotation';
export const PRINT_QUOTATION = CLIENT + '/printQuotation';
export const ORDER_DELIVERY = ORDER + '/delivery';
export const UPDATE_DELIVERY = ORDER + '/updateDelivery';
export const PRINT_DELIVERY = CLIENT + '/printDelivery';

export const APPROVAL = DASHBOARD + '/approval';
export const STORE_MANAGEMENT = DASHBOARD + '/inventrory';
export const ADD_CHEMICAL = STORE_MANAGEMENT + '/add';
export const ADD_CHEMICAL_STOCK = STORE_MANAGEMENT + '/add/stock';
export const STOCK_DETAILS = STORE_MANAGEMENT + '/details';

export const RECIPE_MANAGEMENT = BATCH + '/recipe';

export const PRINT = '/print/preview';

export const BATCH_REPORT_PRINT = PRINT + '/batch-report';
export const BATCH_CARD_PRINT = PRINT + '/batch-card';
export const RECIPE_PRINT = PRINT + '/recipe';

export const PRINT_USERQR = USERS + '/print-user-qr';
export const PRINT_TABLE = PRINT + '/print-table';
export const PRINT_ACCOUNT_TABLE = PRINT + '/account-payment-table';

export const PRINT_MACHINEQR = MACHINE + '/print-machines-qr';
export const PRINT_BATCHESQR = BATCH + '/print-batches-qr';

export const UPLOAD_ORDER = ORDER + '/upload-order-csv';

export const ACCOUNT = DASHBOARD + '/account';
export const ACCOUNT_ORDER_DETAILS = ACCOUNT + '/order-details';
export const ACCOUNT_BATCH_DETAILS = ACCOUNT + '/batch-details';

export const ACCOUNT_DELIVERY_DETAILS = ACCOUNT + '/account-delivery-details';

export const UNAUTHORIZED = '/unauthorized';

export const REPORT = DASHBOARD + '/report';
