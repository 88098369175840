import { VisibilityTwoTone as Visibility } from '@mui/icons-material';
import { Chip, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { STOCK_DETAILS } from '../routeUrls';

export const ChemicalTableColumns = ({ tab }) => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 70,
      renderCell: (params) => (
        <IconButton
          component={Link}
          to={`${STOCK_DETAILS}/${params.row.sku}`}
          state={{ tab: tab }}>
          <Visibility />
        </IconButton>
      ),
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: 200,
    },
    {
      field: 'name',
      headerName: 'NAME',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 150,
      renderCell: (params) =>
        params.value === 'IN STOCK' ? (
          <Chip size="small" label={params.value} color="success" />
        ) : (
          <Chip size="small" label={params.value} color="error" />
        ),
    },
    {
      field: 'unit',
      headerName: 'UNIT',
      width: 100,
    },
    {
      field: 'avgCostPerUnit',
      headerName: 'PER UNIT COST (AVG)',
      width: 130,
      renderCell: (params) =>
        `BDT ${params.value.toFixed(2)} / ${params.row.unit}`,
    },
    {
      field: 'totalQuantity',
      headerName: 'AVAILABLE QUANTITY',
      width: 150,
      renderCell: (params) => `${params.value.toFixed(2)}  ${params.row.unit}`,
    },
    {
      field: 'updatedAt',
      headerName: 'LAST UPDATED',
      renderCell: (params) =>
        dayjs(params.value).format('DD MMM, YYYY hh:mm A'),
      width: 150,
    },
  ];
};

export const DyeTableColumns = ({ tab }) => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 70,
      renderCell: (params) => (
        <IconButton
          component={Link}
          to={`${STOCK_DETAILS}/${params.row.sku}`}
          state={{ tab: tab }}>
          <Visibility />
        </IconButton>
      ),
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: 200,
    },

    {
      field: 'name',
      headerName: 'NAME',
      width: 200,
    },
    {
      field: 'dyeGroup',
      headerName: 'DYE GROUP',
      width: 200,
      renderCell: (params) => params?.value?.name,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 150,
      renderCell: (params) =>
        params.value === 'IN STOCK' ? (
          <Chip size="small" label={params.value} color="success" />
        ) : (
          <Chip size="small" label={params.value} color="error" />
        ),
    },
    {
      field: 'unit',
      headerName: 'UNIT',
      width: 100,
    },
    {
      field: 'avgCostPerUnit',
      headerName: 'PER UNIT COST (AVG)',
      width: 130,
      renderCell: (params) =>
        `BDT ${params.value.toFixed(2)} / ${params.row.unit}`,
    },
    {
      field: 'totalQuantity',
      headerName: 'AVAILABLE QUANTITY',
      width: 150,
      renderCell: (params) => `${params.value.toFixed(2)}  ${params.row.unit}`,
    },
    {
      field: 'updatedAt',
      headerName: 'LAST UPDATED',
      renderCell: (params) =>
        dayjs(params.value).format('DD MMM, YYYY hh:mm A'),
      width: 150,
    },
  ];
};

export const ChemicalStockColumns = [
  {
    field: 'uid',
    headerName: 'Stock Code',
    width: 120,
  },
  {
    field: 'brand',
    headerName: 'Brand',
    width: 120,
  },
  {
    field: 'received',
    headerName: 'Received',
    width: 100,
    renderCell: (params) => `${params.row.quantity + params.row.usedQuantity}`,
  },
  {
    field: 'cost',
    headerName: 'Total Cost',
    width: 180,
  },
  {
    field: 'perUnitCost',
    headerName: 'Cost Per Unit',
    width: 120,
    renderCell: (params) => `${params.value.toFixed(2)}`,
  },
  {
    field: 'quantity',
    headerName: 'Available',
    width: 100,
  },
  {
    field: 'usedQuantity',
    headerName: 'Used',
    width: 100,
  },

  {
    field: 'createdAt',
    headerName: 'Stock Added On',
    renderCell: (params) => dayjs(params.value).format('DD MMM, YYYY'),
    width: 200,
  },
];
