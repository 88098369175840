import {
  CloseTwoTone as Close,
  DoneAllTwoTone as DoneAll,
} from '@mui/icons-material';
import { Stack, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchMedia } from '../../../../api/media';

const RenderFormValues = ({ field, batch, task }) => {
  const [image, setImage] = useState([]);

  const fetchImages = async () => {
    const response = await fetchMedia(
      'BATCH-TASK-IMAGE',
      `${batch.id}-${task.uid}`,
    );
    setImage(response);
  };

  useEffect(() => {
    if (field.fieldType === 'IMAGE') {
      (async () => fetchImages())();
    }
  }, []);

  switch (field.fieldType) {
    case 'TEXT':
      return (
        <Stack
          spacing={2}
          px={1}
          py={1}
          borderRadius={2}
          border={1}
          borderColor="rgba(0,0,0,0.1)">
          <Typography fontSize="0.9rem" fontWeight={500}>
            {field.label}: {field[field.inputKeyName]}
          </Typography>
        </Stack>
      );

    case 'TEXTAREA':
      return (
        <Stack
          spacing={2}
          px={1}
          py={1}
          borderRadius={2}
          border={1}
          borderColor="rgba(0,0,0,0.1)">
          <Typography fontSize="0.9rem" fontWeight={500}>
            {field.label}: {field[field.inputKeyName]}
          </Typography>
        </Stack>
      );
    case 'NUMBER':
      return (
        <Stack
          spacing={2}
          px={1}
          py={1}
          borderRadius={2}
          border={1}
          borderColor="rgba(0,0,0,0.1)">
          <Typography fontSize="0.9rem" fontWeight={500}>
            {field.label}: {field[field.inputKeyName]}
          </Typography>
        </Stack>
      );

    case 'YES/NO':
      return (
        <Stack
          spacing={2}
          px={1}
          py={1}
          borderRadius={2}
          border={1}
          borderColor="rgba(0,0,0,0.1)"
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Typography fontSize="0.9rem" fontWeight={700}>
            {field.label}
          </Typography>
          <Stack
            bgcolor={field[field.inputKeyName] ? '#59CE8F' : '#E94560'}
            px={0.5}
            py={0.5}
            width={35}
            height={35}
            borderRadius={200}
            alignItems="center"
            justifyContent="center">
            {field[field.inputKeyName] ? <DoneAll /> : <Close />}
          </Stack>
        </Stack>
      );
    case 'IMAGE':
      return (
        <Stack
          spacing={2}
          px={1}
          py={1}
          borderRadius={2}
          border={1}
          borderColor="rgba(0,0,0,0.1)">
          <Typography>{field.label}</Typography>
          {image?.map((img) => (
            <Box component="img" src={img.url} key={img.url} width={200} />
          ))}
        </Stack>
      );
    default:
      return (
        <Stack
          spacing={2}
          px={1}
          py={1}
          borderRadius={2}
          border={1}
          borderColor="rgba(0,0,0,0.1)">
          <Typography fontSize="0.9rem" fontWeight={500}>
            {field.label}: {field?.[field.inputKeyName]}
          </Typography>
        </Stack>
      );
  }
};

export default RenderFormValues;
