import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';

const Button = styled(LoadingButton)({
  boxShadow: ['none'],
  borderRadius: 4,
  fontWeight: 700,
  letterSpacing: 1,
  textTransform: 'capitalize',
});

export default Button;
