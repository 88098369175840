import { Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Pageview } from '@mui/icons-material';
import BatchOrderFabricDetails from './BatchOrderFabricDetails';
import { convertNumberToWords, formatNumber } from '../../utils/number';

const BatchAccountOrderDetails = ({ orderList, fabricList }) => {
  const calculateFabricQuantity = () => {
    let totalQuantity = 0;
    fabricList.forEach((fabric) => {
      totalQuantity += fabric.batchFabricAmount;
    });
    return totalQuantity;
  };

  const calculateFabricAmount = () => {
    let totalAmount = 0;
    fabricList.forEach((fabric) => {
      totalAmount += fabric.batchFabricAmount * fabric.rate;
    });
    return totalAmount;
  };

  return (
    <Stack>
      <Stack mt={3}>
        {orderList?.length < 1 ? (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <Stack
              direction="Column"
              justifyContent="center"
              alignItems="center">
              <Pageview sx={{ width: 120, height: 120 }} />
              <Typography fontSize={18} fontWeight={800}>
                No Orders
              </Typography>
              <Typography maxWidth={450}>No order created yet!</Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems="left" justifyContent="flex-start" width="100%">
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
              Order List:
            </Typography>
            <Stack alignItems="left" justifyContent="flex-start" width="100%">
              <Stack
                direction="column"
                sx={{
                  height: 'auto',
                  minHeight: 250,
                  width: '100%',
                  // maxWidth: { md: 500, xs: '100%' },
                  border: '1px solid gray',
                  borderStyle: 'dashed',
                  mr: 2,
                  mb: 2,
                  p: 2,
                  borderRadius: 1.5,
                }}>
                {orderList.map((order) => (
                  <BatchOrderFabricDetails
                    key={order?.id}
                    order={order}
                    fabricList={fabricList}
                  />
                ))}
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack
                      mt={2}
                      width="100%"
                      sx={{ background: 'gray', opacity: 0.5 }}>
                      <Divider></Divider>
                    </Stack>
                  </Grid>
                  <Grid item xs={10.5}>
                    <Typography textAlign="end" fontWeight={700} fontSize={18}>
                      Total Amount:
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography textAlign="end" fontWeight={700} fontSize={18}>
                      ৳{formatNumber(calculateFabricAmount())}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontWeight={700} fontSize={15}>
                        Total Quantity:
                      </Typography>
                      <Typography fontSize={15}>
                        {calculateFabricQuantity()} KG
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontWeight={700} fontSize={15}>
                        In Words:
                      </Typography>
                      <Typography fontSize={15}>
                        {convertNumberToWords(calculateFabricAmount())} Only
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default BatchAccountOrderDetails;
