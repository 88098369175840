import {
  ClearTwoTone as Clear,
  DeleteTwoTone as Delete,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchBatchTaskTemplates } from '../../../api/batch';
import Button from '../../../widgets/Button';

const TemplatePreLoadManagement = ({
  template,
  loadTemplate,
  deleteTemplate,
  clearTemplate,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  const [showTemplatePreloadingModal, setShowTemplatePreloadingModal] =
    useState(false);
  const [showTemplateClearningModal, setShowTemplateClearningModal] =
    useState(false);
  const [showTemplateDeletingModal, setShowTemplateDeletingModal] =
    useState(false);

  const fetchTemplates = async (query) => {
    const response = await fetchBatchTaskTemplates(query);
    setOptions(response);
  };

  useEffect(() => {
    (async () => await fetchTemplates())();
  }, []);

  const handleTemplatePreloading = () => {
    loadTemplate(selectedOption);
    setShowTemplatePreloadingModal(false);
  };

  const handleClear = () => {
    clearTemplate();
    setShowTemplateClearningModal(false);
  };

  const handleDelete = async () => {
    await deleteTemplate();
    setShowTemplateDeletingModal(false);
  };

  return (
    <Stack
      width="100%"
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between">
      <Dialog
        open={showTemplatePreloadingModal}
        onClose={() => setShowTemplatePreloadingModal(false)}>
        <DialogContent>
          <Stack px={1} py={2} spacing={2}>
            <Typography fontSize={20} fontWeight={800}>
              Are you sure?
            </Typography>
            <Typography>
              Are your sure you want to load this template?
            </Typography>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => {
                  setSelectedOption(null);
                  setShowTemplatePreloadingModal(false);
                }}>
                No
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={handleTemplatePreloading}>
                Yes
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showTemplateClearningModal}
        onClose={() => setShowTemplateClearningModal(false)}>
        <DialogContent>
          <Stack px={1} py={2} spacing={2}>
            <Typography fontSize={20} fontWeight={800}>
              Are you sure?
            </Typography>
            <Typography>
              Are your sure you want to clear this template?
            </Typography>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => setShowTemplateClearningModal(false)}>
                No
              </Button>
              <Button size="small" variant="contained" onClick={handleClear}>
                Yes
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showTemplateDeletingModal}
        onClose={() => setShowTemplateDeletingModal(false)}>
        <DialogContent>
          <Stack px={1} py={2} spacing={2}>
            <Typography fontSize={20} fontWeight={800}>
              Are you sure?
            </Typography>
            <Typography>
              Are your sure you want to delete this template?
            </Typography>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => setShowTemplateDeletingModal(false)}>
                No
              </Button>
              <Button size="small" variant="contained" onClick={handleDelete}>
                Yes
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Typography variant="h5" fontWeight={800} sx={{ flexGrow: 0 }}>
        Batch Planning
      </Typography>
      <Stack
        direction="row"
        spacing={1.2}
        flexGrow={1}
        justifyContent="flex-end">
        {template && (
          <>
            <Button
              size="small"
              variant="contained"
              endIcon={<Clear />}
              onClick={() => setShowTemplateClearningModal(true)}>
              Clear Template
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              endIcon={<Delete />}
              onClick={() => setShowTemplateDeletingModal(true)}>
              Delete Template
            </Button>
          </>
        )}
        <Box width="100%" maxWidth={550}>
          <Autocomplete
            options={options}
            value={template}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) =>
              `${option.name} - ${option.description?.substring(0, 10)}...`
            }
            onInputChange={async (event, query) => {
              if (query.length > 2 || query.length <= 0) {
                await fetchTemplates({ name: query });
              }
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label="Load from template"
                size="small"
                placeholder="You can load task information from template"
              />
            )}
            onChange={(event, value) => {
              if (value) {
                setSelectedOption(value);
                setShowTemplatePreloadingModal(true);
              }
            }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default TemplatePreLoadManagement;
