import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignInSchema } from '../../../validators/auth';
import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import Button from '../../../widgets/Button';
import {
  VisibilityOffTwoTone as VisibilityOff,
  VisibilityTwoTone as Visibility,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { signin } from '../../../store/actions/auth';
import TextField from '../../../widgets/TextField';

const SigninForm = () => {
  const [filedType, setFieldType] = useState('password');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const resolver = yupResolver(SignInSchema);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver,
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const handleSignIn = (data) => {
    setLoading(true);
    const { username, password } = data;
    dispatch(signin({ username, password }));
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(handleSignIn)}>
      <Stack
        direction="row"
        sx={{
          position: 'relative',
        }}>
        <Box flexGrow={0.2} height="100vh">
          <Typography
            sx={{
              position: 'absolute',
              top: '0',
              left: '50%',
              transform: 'translateX(-50%)',
              color: 'red',
              fontSize: '20px',
              fontWeight: 'bold',
              padding: '10px',
              zIndex: 100,
            }}>
            Due to unpaid server bills, services have been suspended.
          </Typography>
          <Box
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
            width="100%"
            height="100%">
            <Box
              component="img"
              src="/assets/images/signin-bg.svg"
              width="100%"
              height="100%"
            />
          </Box>
        </Box>
        <Stack
          flexGrow={1}
          height="100vh"
          alignItems="center"
          justifyContent="center"
          spacing={6}>
          <Box component="img" src="/assets/images/logo.svg" width="200px" />
          <Typography variant="h5" fontWeight={800} textAlign="center">
            Welcome to AREX
            <br />
            <Typography fontSize="12px" fontWeight={500}>
              Powered by OmniPro
            </Typography>
          </Typography>

          <Stack width={300} spacing={1.5}>
            <TextField
              size="medium"
              {...register('username')}
              error={errors?.username}
              helperText={errors?.username?.message}
              label="Username"
              placeholder="Username, ie - abc1450"
              fullWidth
              disabled={true}
            />
            <TextField
              size="medium"
              type={filedType}
              {...register('password')}
              error={errors?.password}
              helperText={errors?.password?.message}
              label="Password"
              placeholder="Please enter your password"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setFieldType((previousFieldType) => {
                        if (previousFieldType === 'password') {
                          return 'text';
                        } else {
                          return 'password';
                        }
                      });
                    }}>
                    {filedType === 'password' ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </InputAdornment>
                ),
              }}
              disabled={true}
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              loading={loading}
              disabled={true}>
              Sign In
            </Button>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              download={true}
              href={`${process.env.REACT_APP_API_BASEURL}/app/download`}
              disabled={true}>
              Download App
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default SigninForm;
