import { Stack, Chip, Typography, Tooltip, IconButton } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { milisecondsToTime } from '../../../../utils/time';
import {
  AlarmTwoTone as Alarm,
  SportsScoreTwoTone as SportsScore,
  WavesTwoTone as Waves,
  EditTwoTone as Edit,
} from '@mui/icons-material';
import EditTaskAdditionalRemarksModal from './EditTaskAdditionalRemarksModal';

const TaskInformations = ({ task, socket }) => {
  const getStatusColor = (status) => {
    const colors = {
      DONE: 'success',
      RUNNING: 'info',
      DUE: 'warning',
    };

    return colors[status || 'DUE'];
  };

  const calculateDuration = () => {
    const duration = new Date(task.completeTime) - new Date(task.startTime);
    return milisecondsToTime(duration);
  };

  const calculateHourBasedDuration = (hours, minutes) => {
    //covert minutes to hour
    const hour = minutes / 60;
    const totalHour = hours + hour;
    return totalHour.toFixed(2);
  };

  const [additionalModalOpen, setAdditionalModalOpen] = useState(false);

  const checkEarly = () => {
    const duration = new Date(task.completeTime) - new Date(task.startTime);
    if (duration > 300000) {
      return false;
    } else {
      return true;
    }
  };

  const getDetails = () => {
    if (task.status === 'DUE') {
      return (
        <Stack spacing={1}>
          {task.standardTimeHour > 0 ? (
            <Typography fontWeight={300} fontSize="0.85rem">
              Should Take{' '}
              {calculateHourBasedDuration(
                task.standardTimeHour,
                task.standardTimeMinute,
              )}{' '}
              hours
            </Typography>
          ) : (
            <Typography fontWeight={300} fontSize="0.85rem">
              Should Take {task.standardTimeMinute} mins
            </Typography>
          )}
        </Stack>
      );
    } else if (task.status === 'RUNNING') {
      return (
        <Stack spacing={1}>
          <Typography fontWeight={300} fontSize="0.85rem">
            Started At {dayjs(task.startTime).format('DD MMM, YYYY hh:mm A')}
          </Typography>
          <Typography fontWeight={300} fontSize="0.85rem">
            {task.standardTimeHour > 0 ? (
              <Typography fontWeight={300} fontSize="0.85rem">
                Should Take{' '}
                {calculateHourBasedDuration(
                  task.standardTimeHour,
                  task.standardTimeMinute,
                )}{' '}
                hours
              </Typography>
            ) : (
              <Typography fontWeight={300} fontSize="0.85rem">
                Should Take {task.standardTimeMinute} mins
              </Typography>
            )}
          </Typography>
          <Stack
            flex={1}
            direction="row"
            alignItems="flex-start"
            spacing={2}
            px={1}
            py={1}
            borderRadius={2}
            border={1}
            borderColor="rgba(0,0,0,0.1)">
            <Stack>
              <Typography fontWeight={800} fontSize="0.8rem">
                Started By
              </Typography>
              <Typography fontSize="14px">
                {task.startedByInfo?.name}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={1}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}>
            <Typography fontWeight={500} fontSize="0.85rem">
              Standard Time:
            </Typography>
            {task.standardTimeHour > 0 ? (
              <Typography fontWeight={500} fontSize="0.85rem">
                {' '}
                {calculateHourBasedDuration(
                  task.standardTimeHour,
                  task.standardTimeMinute,
                )}{' '}
                hours
              </Typography>
            ) : (
              <Typography fontWeight={500} fontSize="0.85rem">
                {task.standardTimeMinute} mins
              </Typography>
            )}
          </Stack>

          <Typography fontWeight={700} fontSize="0.85rem">
            Duration: {calculateDuration()}
          </Typography>
          <Stack direction="row" flexWrap="wrap">
            <Tooltip title="Started At">
              <Chip
                avatar={<Alarm />}
                label={dayjs(task.startTime).format('DD MMM, YYYY hh:mm A')}
                size="small"
                sx={{ width: 'max-content', mr: 1, mb: 1 }}
              />
            </Tooltip>
            <Tooltip title="Completed At">
              <Chip
                avatar={<SportsScore />}
                label={dayjs(task.completeTime).format('DD MMM, YYYY hh:mm A')}
                size="small"
                sx={{ width: 'max-content', mr: 1, mb: 1 }}
              />
            </Tooltip>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Stack
              flex={1}
              direction="row"
              alignItems="flex-start"
              spacing={2}
              px={1}
              py={1}
              borderRadius={2}
              border={1}
              borderColor="rgba(0,0,0,0.1)">
              <Stack>
                <Typography fontWeight={800} fontSize="0.8rem">
                  Started By
                </Typography>
                <Typography fontSize="14px">
                  {task.startedByInfo?.name}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              flex={1}
              direction="row"
              alignItems="flex-start"
              spacing={2}
              px={1}
              py={1}
              borderRadius={2}
              border={1}
              borderColor="rgba(0,0,0,0.1)">
              <Stack>
                <Typography fontWeight={800} fontSize="0.8rem">
                  Completed By
                </Typography>
                <Typography fontSize="14px">
                  {task.completedByInfo?.name}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              flex={1}
              direction="row"
              alignItems="flex-start"
              spacing={2}
              px={1}
              py={1}
              borderRadius={2}
              border={1}
              borderColor="rgba(0,0,0,0.1)">
              <Stack>
                <Typography fontWeight={800} fontSize="0.8rem">
                  Operator
                </Typography>
                <Typography fontSize="14px">
                  {task.operatorInfo?.name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography fontWeight={700} fontSize="0.85rem">
              <b>Additional Remarks:</b>{' '}
              {task.additionalRemarks ? task.additionalRemarks : 'N/A'}
            </Typography>
            <IconButton
              size="small"
              title="Edit"
              aria-label="edit"
              onClick={() => setAdditionalModalOpen(true)}
              sx={{
                bgcolor: '#fff',
                borderRadius: 1,
                ':hover': { bgcolor: '#f7f7f7' },
              }}>
              <Edit />
            </IconButton>
          </Stack>
          <EditTaskAdditionalRemarksModal
            toggleModal={setAdditionalModalOpen}
            show={additionalModalOpen}
            task={task}
            socket={socket}
          />
        </Stack>
      );
    }
  };

  return (
    <Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between">
        <Typography variant="h6" fontWeight={700}>
          {task.taskName}
        </Typography>
        <Stack direction="row" spacing={0.5}>
          {task.status === 'DONE' && checkEarly() ? (
            <Chip
              color={getStatusColor('RUNNING')}
              label={'Too Early'}
              size="small"
            />
          ) : (
            <></>
          )}
          <Chip
            color={getStatusColor(task.status)}
            label={task.status}
            size="small"
          />

          {task.isSkipped && (
            <Chip color="error" label="Skipped" size="small" />
          )}
          {task.status === 'DONE' && task.isDelayed && (
            <Chip
              color="error"
              label={'Delayed'}
              size="small"
              avatar={<Waves sx={{ fill: '#fff' }} />}
            />
          )}
        </Stack>
      </Stack>
      {getDetails()}
      <Stack direction="row" spacing={2} mt={1}>
        {task.isSkipped && (
          <Stack>
            <Stack
              width="max-content"
              direction="row"
              alignItems="flex-start"
              spacing={2}
              px={1}
              py={1}
              borderRadius={2}
              border={1}
              borderColor="rgba(0,0,0,0.1)">
              <Stack>
                <Typography fontWeight={800} fontSize="0.8rem">
                  Skipped By
                </Typography>
                <Typography fontSize="14px">
                  {task.skippedByInfo?.name}
                </Typography>
              </Stack>
            </Stack>
            <Stack mt={1} direction="row" alignItems="center" spacing={1}>
              <Typography fontWeight={700} fontSize="0.85rem">
                Reason:
              </Typography>
              <Typography fontSize="0.85rem">
                {task?.skipReasons ? task?.skipReasons : 'N/A'}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default TaskInformations;
