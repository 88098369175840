import { Pageview } from '@mui/icons-material';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { convertNumberToWords, formatNumber } from '../../utils/number';
import BatchTaskChemicalDetails from './BatchTaskChemicalDetails';

const BatchTaskDetails = ({ recipeList }) => {
  const calculateTaskQuantity = () => {
    let totalQuantity = 0;
    recipeList?.forEach((singleRecipe) => {
      singleRecipe.recipe.forEach((chemical) => {
        totalQuantity += chemical.total * 0.001;
      });
    });
    return totalQuantity.toFixed(2);
  };
  const calculateTaskCost = () => {
    let totalCost = 0;
    recipeList?.forEach((singleRecipe) => {
      totalCost += singleRecipe?.totalCost;
    });
    return totalCost;
  };
  return (
    <Stack>
      <Stack mt={3}>
        {recipeList?.length < 1 ? (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <Stack
              direction="Column"
              justifyContent="center"
              alignItems="center">
              <Pageview sx={{ width: 120, height: 120 }} />
              <Typography fontSize={18} fontWeight={800}>
                No Tasks
              </Typography>
              <Typography maxWidth={450}>No task created yet!</Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems="left" justifyContent="flex-start" width="100%">
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
              Chemical Cost:
            </Typography>
            <Stack alignItems="left" justifyContent="flex-start" width="100%">
              <Stack
                direction="column"
                sx={{
                  height: 'auto',
                  minHeight: 250,
                  width: '100%',
                  // maxWidth: { md: 500, xs: '100%' },
                  border: '1px solid gray',
                  borderStyle: 'dashed',
                  mr: 2,
                  mb: 2,
                  p: 2,
                  borderRadius: 1.5,
                }}>
                {recipeList?.map((singleRecipe) => (
                  <BatchTaskChemicalDetails
                    key={singleRecipe?.id}
                    singleRecipe={singleRecipe}
                  />
                ))}
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack
                      mt={2}
                      width="100%"
                      sx={{ background: 'gray', opacity: 0.5 }}>
                      <Divider></Divider>
                    </Stack>
                  </Grid>
                  <Grid item xs={10.5}>
                    <Typography textAlign="end" fontWeight={700} fontSize={18}>
                      Total Cost:
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography textAlign="end" fontWeight={700} fontSize={18}>
                      ৳{formatNumber(calculateTaskCost())}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontWeight={700} fontSize={15}>
                        Total Quantity:
                      </Typography>
                      <Typography fontSize={15}>
                        {calculateTaskQuantity()} KG
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontWeight={700} fontSize={15}>
                        In Words:
                      </Typography>
                      <Typography fontSize={15}>
                        {convertNumberToWords(calculateTaskCost())} Only
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default BatchTaskDetails;
