import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Switch,
  Table,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
// import {
//   // convertNumberToWords,
//   // formatNumber,
// } from '../../utils/number';
import Button from '../../widgets/Button';
import dayjs from 'dayjs';
import { reformateLotNumber } from '../../utils/order';
import { errorNoitif, successNoitif } from '../../utils/notification';
import { updateOrderDeliveryRate } from '../../api/order';
import { useReactToPrint } from 'react-to-print';
import { formatTime } from '../../utils/time';
import PrintIcon from '@mui/icons-material/Print';
import { formatNumber } from '../../utils/number';
import DeliveryBillTransactionModal from '../../components/account/DeliveryBillTransactionModal';
import OrderAccountLogsTab from '../../components/account/OrderAccountLogsTab';

const OrderBillDetails = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const delivery = location.state.delivery;
  const accountInformation = location.state.accountInformation;

  const [checked, setChecked] = useState(false);
  const [rateArray, setRateArray] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showTransactionForm, setShowTransactionForm] = useState(false);
  const [transactionUpdate, setTransactionUpdate] = useState(false);

  const handleRateChange = (input, batch) => {
    !input || input < 0 ? (input = 0) : input;

    const tempArray = rateArray.find(
      (item) => item.deliveryFabricBatchId === batch.id,
    );
    if (tempArray) {
      tempArray.fabricRate = input;
      setRateArray([...rateArray]);
      return;
    }
    const tempObject = {
      fabricRate: input,
      deliveryFabricBatchId: batch.id,
    };
    setRateArray([...rateArray, tempObject]);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const calculateAmount = (batch) => {
    let amount = 0;

    const tempArray = rateArray.find(
      (item) => item.deliveryFabricBatchId === batch.id,
    );
    if (tempArray) {
      batch.fabricRate = tempArray.fabricRate;
    }

    if (checked) {
      amount += batch.netQuantity * batch.fabricRate;
    } else {
      amount += batch.deliveryQuantity * batch.fabricRate;
    }

    return amount;
  };

  const calculateTotalQuantity = (fabric) => {
    let totalQuantity = 0;

    fabric.orderDeliveryFabricBatches.map((batch) => {
      totalQuantity += batch.netQuantity;
    });

    return totalQuantity;
  };

  const calculateTotalNetQuantity = () => {
    let totalQuantity = 0;
    delivery.orderDeliveryFabrics.map((fabric) => {
      totalQuantity += calculateTotalQuantity(fabric);
    });
    return totalQuantity;
  };

  const calculateTotalAmount = (fabric) => {
    let totalAmount = 0;
    if (checked) {
      fabric.orderDeliveryFabricBatches.map((batch) => {
        totalAmount += batch.netQuantity * batch.fabricRate;
      });
    } else {
      fabric.orderDeliveryFabricBatches.map((batch) => {
        totalAmount += batch.deliveryQuantity * batch.fabricRate;
      });
    }
    return totalAmount;
  };

  const calculateFullAmount = () => {
    let totalAmount = 0;

    delivery.orderDeliveryFabrics.map((fabric) => {
      totalAmount += calculateTotalAmount(fabric);
    });

    return totalAmount;
  };

  const handleUpdate = () => {
    setShowConfirmation(true);
  };
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    updateDeliveryRate();
  };

  const updateDeliveryRate = async () => {
    const response = await updateOrderDeliveryRate(rateArray);
    if (response.status === 200) {
      navigate(-1);
      successNoitif('Order delivery rate has been updated');
    } else {
      errorNoitif('Sorry! delivery rate has not been updated');
    }
  };

  const checkRateArray = () => {
    if (rateArray.length < 1) {
      return true;
    }

    let flag = false;
    rateArray.map((item) => {
      if (item.fabricRate === 0) {
        flag = true;
      }
    });
    return flag;
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: `AREX-${formatTime(new Date())}`,
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (delivery) {
      if (delivery?.quantityChoice === 'NET') {
        setChecked(true);
      }
    }
  }, []);

  useEffect(() => {
    if (transactionUpdate) {
      navigate(-1);
    }
  }, [transactionUpdate]);

  return (
    <>
      <Stack
        spacing={2}
        height="100%"
        minHeight="80vh"
        boxSizing="border-box"
        px={2}
        pt={3}>
        <Stack width={{ md: '80%', sm: '100%' }} mx="auto">
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}>
            <Stack>
              <Button onClick={() => navigate(-1)} variant="contained">
                <ArrowBackIosIcon fontSize="15px" />
                <Typography fontSize="15px">Back</Typography>
              </Button>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Button onClick={handlePrint} variant="contained">
                <Stack direction="row" spacing={0.5}>
                  <Typography fontSize="15px">Print</Typography>
                  <PrintIcon />
                </Stack>
              </Button>
              <Button
                variant="contained"
                disabled={
                  delivery?.billStatus === 'PAID' || rateArray.length !== 0
                    ? true
                    : false
                }
                onClick={() => setShowTransactionForm(true)}
                endIcon={<PaidTwoToneIcon />}>
                Add transaction
              </Button>
            </Stack>
          </Stack>
          <DeliveryBillTransactionModal
            toggleModal={setShowTransactionForm}
            show={showTransactionForm}
            accountInfo={accountInformation.account}
            deliveryInfo={delivery}
            checked={checked}></DeliveryBillTransactionModal>
          <Divider></Divider>
          {delivery?.partyName ? (
            <Box
              mx="auto"
              width="1080px"
              my={2}
              mt={3}
              boxSizing="border-box"
              padding="15px 30px"
              class="page">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mt={2}
                mb={2}>
                <Stack>
                  <Box
                    component="img"
                    sx={{
                      width: 150,
                    }}
                    alt="The house from the offer."
                    src="/assets/images/logo.svg"
                  />
                </Stack>
                <Stack>
                  <Typography fontSize={18}>2/1,Gadda,Savar,Dhaka</Typography>
                </Stack>
              </Stack>
              <Divider></Divider>
              <Stack
                width="100%"
                direction="row"
                justifyContent="Center"
                my={2}>
                <Typography fontSize={25} sx={{ fontWeight: '500' }}>
                  #{delivery?.uid}
                </Typography>
              </Stack>
              <Grid mb={1.5} container direction="row" spacing={1}>
                <Grid item xs={6}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={5}>
                      <Typography textAlign="left" style={{ fontWeight: 600 }}>
                        Party Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography textAlign="left">
                        {delivery.partyName}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography textAlign="left" style={{ fontWeight: 600 }}>
                        Delivery Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography textAlign="left">
                        {dayjs(delivery.deliveryDate).format('DD/MMM/YYYY ')}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography textAlign="left" style={{ fontWeight: 600 }}>
                        Contatct Number:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography textAlign="left">
                        {delivery.deliveryContatctNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={5}>
                      <Typography textAlign="left" style={{ fontWeight: 600 }}>
                        Delivery Contact:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography textAlign="left">
                        {delivery.deliveryContatct}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography textAlign="left" style={{ fontWeight: 600 }}>
                        Vehicle Number:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {delivery?.vehicleNumber.length == 0 ? (
                        <Typography textAlign="left">
                          No Vehicle Assigned
                        </Typography>
                      ) : (
                        <Typography textAlign="left">
                          {delivery?.vehicleNumber}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        textAlign="left"
                        style={{ fontWeight: 600, fontSize: '15px' }}>
                        Delivery Address:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography textAlign="left">
                        {delivery.deliveryAddress}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider></Divider>

              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}>
                <Typography>Calculate with Net Quantity?</Typography>
                <Switch
                  disabled={
                    delivery?.quantityChoice !== 'NOT ASSIGNED' ? true : false
                  }
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>

              {/* table section  */}
              <Stack className="table-section">
                {delivery?.orderDeliveryFabrics?.map((item, index) => {
                  if (item?.orderDeliveryFabricBatches.length > 0) {
                    return (
                      <Stack key={index}>
                        <Stack>
                          <Typography my={1} style={{ fontWeight: 600 }}>
                            Fabric: {item.fabricName}
                          </Typography>
                        </Stack>
                        <Table className="delivery-table">
                          <thead className="delivery-table-thead">
                            <tr className="delivery-table-tr">
                              <th className="delivery-table-th">SL</th>
                              <th className="delivery-table-th">Order #</th>
                              <th className="delivery-table-th">Batch #</th>
                              <th className="delivery-table-th">Shade Group</th>
                              <th className="delivery-table-th">
                                Process Description
                              </th>
                              <th className="delivery-table-th">
                                Quantity(KG)
                              </th>
                              <th className="delivery-table-th">
                                Net Quantity(KG)
                              </th>
                              <th className="delivery-table-th">Rate</th>
                              <th className="delivery-table-th">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.orderDeliveryFabricBatches?.map(
                              (row, index) => {
                                return (
                                  <tr
                                    className="delivery-table-tr"
                                    key={row.lotNumber}>
                                    <td className="delivery-table-td">
                                      {index + 1}
                                    </td>
                                    <td className="delivery-table-td">
                                      {accountInformation?.order?.fcNumber}
                                    </td>
                                    <td className="delivery-table-td">
                                      {reformateLotNumber(row.lotNumber)}
                                    </td>
                                    <td className="delivery-table-td">
                                      {item?.fabricInfo?.shadeGroup}
                                    </td>
                                    <td className="delivery-table-td">
                                      {item?.fabricInfo?.processDescription}
                                    </td>
                                    <td className="delivery-table-td">
                                      {row.deliveryQuantity}
                                    </td>
                                    <td className="delivery-table-td">
                                      {row.netQuantity}
                                    </td>
                                    <td
                                      className="delivery-table-td"
                                      width="300px">
                                      {/* {item.fabricRate} */}
                                      <Stack direction="row" spacing={1}>
                                        <TextField
                                          size="small"
                                          disabled={
                                            delivery?.billStatus !== 'UNPAID'
                                              ? true
                                              : false
                                          }
                                          InputProps={{
                                            inputProps: {
                                              type: 'number',
                                              min: 0,
                                              style: { textAlign: 'center' },
                                            },
                                          }}
                                          defaultValue={row.fabricRate}
                                          onKeyPress={(event) => {
                                            if (
                                              event?.key === '-' ||
                                              event?.key === '+'
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) =>
                                            // setInputNetQuantity(
                                            //   parseInt(event.target.value),
                                            // )

                                            handleRateChange(
                                              parseFloat(event.target.value),
                                              row,
                                            )
                                          }
                                          flexWrap="wrap"
                                          fullWidth
                                          id="outlined-basic"
                                          sx={{ maxWidth: 300 }}
                                          variant="outlined"
                                          error={
                                            // item.deliveryFabricBatchId === batch.id
                                            rateArray.find(
                                              (item) =>
                                                item.deliveryFabricBatchId ==
                                                row.id,
                                            )?.fabricRate <= 0
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            rateArray.find(
                                              (item) =>
                                                item.deliveryFabricBatchId ==
                                                row.id,
                                            )?.fabricRate <= 0
                                              ? 'Rate must be numaric & greater than 0'
                                              : ''
                                          }
                                        />
                                      </Stack>
                                    </td>
                                    <td className="delivery-table-td">
                                      ৳{formatNumber(calculateAmount(row))}
                                    </td>
                                  </tr>
                                );
                              },
                            )}
                            <tr className="delivery-table-tr">
                              <td
                                className="delivery-table-td"
                                colSpan="5"
                                style={{
                                  textAlign: 'center',
                                  fontWeight: 600,
                                }}>
                                Total Quantity(KG)
                              </td>
                              <td
                                colSpan="1"
                                className="delivery-table-td"
                                style={{ fontWeight: 600 }}>
                                {item?.totalDeliveryQuantity}
                              </td>
                              <td
                                colSpan="1"
                                className="delivery-table-td"
                                style={{ fontWeight: 600 }}>
                                {calculateTotalQuantity(item)}
                              </td>
                              <td
                                className="delivery-table-td"
                                colSpan="1"
                                style={{
                                  textAlign: 'center',
                                  fontWeight: 600,
                                }}>
                                Total Amount
                              </td>
                              <td
                                className="delivery-table-td"
                                colSpan="1"
                                style={{
                                  textAlign: 'center',
                                  fontWeight: 600,
                                }}>
                                ৳{formatNumber(calculateTotalAmount(item))}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Stack>
                    );
                  }
                })}
              </Stack>
            </Box>
          ) : (
            <></>
          )}

          <Stack mt={2}>
            <Divider></Divider>

            <Stack mt={2} direction="column" spacing={2}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Typography>
                  Total Gross Quantity: {delivery.totalDeliveryQuantity} KG
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Typography>
                  Total Net Quantity: {calculateTotalNetQuantity()} KG
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Typography>
                  Total Amount: ৳{formatNumber(calculateFullAmount())}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            mt={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            width="100%">
            <Button
              onClick={handleUpdate}
              disabled={checkRateArray()}
              variant="contained">
              Update
            </Button>
          </Stack>

          {/* bill log  */}
          <Stack mt={2}>
            <Divider></Divider>
            <OrderAccountLogsTab
              uid={accountInformation?.account?.uid}
              orderInfo={accountInformation?.order}
              accountInfo={accountInformation?.account}
              deliveryBillId={delivery?.id}
              transactionUpdate={transactionUpdate}
              setTransactionUpdate={setTransactionUpdate}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to update this delivery order bill?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => handelConfrimationDecision()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>

      <Stack display="none" width="100%" heigh="100%">
        {delivery?.partyName ? (
          <Box
            mx="auto"
            width="1080px"
            my={2}
            mt={3}
            ref={componentRef}
            boxSizing="border-box"
            padding="15px 30px"
            class="page">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              mb={2}>
              <Stack>
                <Box
                  component="img"
                  sx={{
                    width: 150,
                  }}
                  alt="The house from the offer."
                  src="/assets/images/logo.svg"
                />
              </Stack>
              <Stack>
                <Typography fontSize={18}>2/1,Gadda,Savar,Dhaka</Typography>
              </Stack>
            </Stack>
            <Divider></Divider>
            <Stack width="100%" direction="row" justifyContent="Center" my={2}>
              <Typography fontSize={25} sx={{ fontWeight: '500' }}>
                #{delivery?.uid}
              </Typography>
            </Stack>
            <Grid mb={1.5} container direction="row" spacing={1}>
              <Grid item xs={6}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Party Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {delivery.partyName}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Delivery Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {dayjs(delivery.deliveryDate).format('DD/MMM/YYYY ')}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Contatct Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {delivery.deliveryContatctNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Delivery Contact:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {delivery.deliveryContatct}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Vehicle Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {delivery?.vehicleNumber.length == 0 ? (
                      <Typography textAlign="left">
                        No Vehicle Assigned
                      </Typography>
                    ) : (
                      <Typography textAlign="left">
                        {delivery?.vehicleNumber}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      textAlign="left"
                      style={{ fontWeight: 600, fontSize: '15px' }}>
                      Delivery Address:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {delivery.deliveryAddress}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider></Divider>

            {/* table section  */}
            <Stack className="table-section">
              {delivery?.orderDeliveryFabrics?.map((item, index) => {
                if (item?.orderDeliveryFabricBatches.length > 0) {
                  return (
                    <Stack key={index}>
                      <Stack>
                        <Typography my={1} style={{ fontWeight: 600 }}>
                          Fabric: {item.fabricName}
                        </Typography>
                      </Stack>
                      <Table className="delivery-table">
                        <thead className="delivery-table-thead">
                          <tr className="delivery-table-tr">
                            <th className="delivery-table-th">SL</th>
                            <th className="delivery-table-th">Order #</th>
                            <th className="delivery-table-th">Batch #</th>
                            <th className="delivery-table-th">Shade Group</th>
                            <th className="delivery-table-th">
                              Process Description
                            </th>
                            <th className="delivery-table-th">Quantity(kG)</th>
                            <th className="delivery-table-th">
                              Net Quantity(kG)
                            </th>
                            <th className="delivery-table-th">Rate</th>
                            <th className="delivery-table-th">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.orderDeliveryFabricBatches?.map(
                            (row, index) => {
                              return (
                                <tr
                                  className="delivery-table-tr"
                                  key={row.lotNumber}>
                                  <td className="delivery-table-td">
                                    {index + 1}
                                  </td>
                                  <td className="delivery-table-td">
                                    {accountInformation?.order?.fcNumber}
                                  </td>
                                  <td className="delivery-table-td">
                                    {reformateLotNumber(row.lotNumber)}
                                  </td>
                                  <td className="delivery-table-td">
                                    {item?.fabricInfo?.shadeGroup}
                                  </td>
                                  <td className="delivery-table-td">
                                    {item?.fabricInfo?.processDescription}
                                  </td>
                                  <td className="delivery-table-td">
                                    {row.deliveryQuantity}
                                  </td>
                                  <td className="delivery-table-td">
                                    {row.netQuantity}
                                  </td>
                                  <td className="delivery-table-td">
                                    {row.fabricRate}
                                  </td>
                                  <td className="delivery-table-td">
                                    ৳{formatNumber(calculateAmount(row))}
                                  </td>
                                </tr>
                              );
                            },
                          )}
                          <tr className="delivery-table-tr">
                            <td
                              className="delivery-table-td"
                              colSpan="5"
                              style={{
                                textAlign: 'center',
                                fontWeight: 600,
                              }}>
                              Total Quantity(KG)
                            </td>
                            <td
                              colSpan="1"
                              className="delivery-table-td"
                              style={{ fontWeight: 600 }}>
                              {item?.totalDeliveryQuantity}
                            </td>
                            <td
                              colSpan="1"
                              className="delivery-table-td"
                              style={{ fontWeight: 600 }}>
                              {calculateTotalQuantity(item)}
                            </td>
                            <td
                              className="delivery-table-td"
                              colSpan="1"
                              style={{
                                textAlign: 'center',
                                fontWeight: 600,
                              }}>
                              Total Amount
                            </td>
                            <td
                              className="delivery-table-td"
                              colSpan="1"
                              style={{
                                textAlign: 'center',
                                fontWeight: 600,
                              }}>
                              ৳{formatNumber(calculateTotalAmount(item))}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Stack>
                  );
                }
              })}
            </Stack>

            <Stack
              mt={5}
              width="100%"
              direction="row"
              justifyContent="flex-start"
              alignItems="center">
              {checked ? (
                <Typography>***Calculation based on net quantity</Typography>
              ) : (
                <Typography>***Calculation based on gross quantity</Typography>
              )}
            </Stack>
          </Box>
        ) : (
          <></>
        )}
      </Stack>
    </>
  );
};

export default OrderBillDetails;
