import React, { useEffect, useState } from 'react';
import Button from '../../widgets/Button';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AllBatchListColumns } from '../../constants/table-column/batch';
import PageHeader from '../../widgets/PageHeader';
import SearchIcon from '@mui/icons-material/SearchTwoTone';
import RestartAltIcon from '@mui/icons-material/RestartAltTwoTone';
import {
  fetchBatchList,
  fetchBatchQrData,
  fetchBatchStatsForBatchIndexPage,
} from '../../api/batch';
import {
  AddCircleTwoTone as Add,
  RefreshTwoTone as Refresh,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {
  BATCH_ADD,
  PRINT_BATCHESQR,
  RE_PROCESS_BATCH_CREATE,
} from '../../constants/routeUrls';
import NoDataOverlay from '../../components/global/NoDataOverlay';
import { motion, AnimatePresence } from 'framer-motion';
import QrCodeTwoToneIcon from '@mui/icons-material/QrCodeTwoTone';
import PageTitle from '../../utils/PageTitle';

const Stat = ({ label, content, color }) => {
  return (
    <Stack
      px={1}
      py={1}
      bgcolor="#f2f2f2"
      boxSizing="border-box"
      borderLeft={8}
      borderColor={color}
      width={120}
      alignItems="center">
      <Typography fontSize="0.8rem" fontWeight={700}>
        {label}
      </Typography>
      <Typography fontSize="2.5rem" fontWeight={700}>
        {content}
      </Typography>
    </Stack>
  );
};

const Batch = () => {
  const [batchList, setBatchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  //my changes
  const [lotNumber, setLotNumber] = useState('');
  const [status, setStatus] = useState('');
  const [statusTextField, setStatusTextField] = useState(0);
  const [typeTextField, setTypeTextField] = useState(0);
  const textInput = React.useRef(null);

  const [totalCount, setTotalCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const [stats, setStast] = useState(null);

  const [qrData, setQrData] = useState([]);

  const open = Boolean(anchorEl);
  const openBatchCreationMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeCreationMenu = () => {
    setAnchorEl(null);
  };

  const fetchStats = async () => {
    const response = await fetchBatchStatsForBatchIndexPage();
    setStast(response);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
      await fetchStats();
    })();
  }, []);

  const fetchData = async (lotNumber, status, page, pageSize) => {
    setLoading(true);
    const response = await fetchBatchList({
      lotNumber: lotNumber,
      status: status,
      page: page,
      pageSize: pageSize,
    });
    setBatchList(response.batches);
    setTotalCount(response.total);
    setLoading(false);
  };

  const fetchQrData = async () => {
    const response = await fetchBatchQrData();

    setQrData(response.qrData);
  };

  useEffect(() => {
    (async () => await fetchData(lotNumber, status, page, pageSize))();
    (async () => await fetchQrData())();
  }, [page, pageSize]);

  // my functions
  const resetAll = () => {
    fetchData('', '', page, pageSize);
    setLotNumber('');
    setStatus('');
    textInput.current.value = '';
    setStatusTextField(0);
    setTypeTextField(0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData(lotNumber, status, page, pageSize);
    setLotNumber(lotNumber);
    setStatus(status);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}>
        <Stack
          spacing={2}
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="space-between"
          minHeight="80vh"
          boxSizing="border-box"
          px={2}
          py={3}>
          <PageTitle title="Batches" />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            spacing={2}>
            <PageHeader>Batches</PageHeader>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={closeCreationMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem
                onClick={closeCreationMenu}
                component={Link}
                to={BATCH_ADD}>
                <Stack direction="row" spacing={1.5}>
                  <Add />
                  <Typography fontSize="0.8rem" fontWeight={700}>
                    New Batch
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                onClick={closeCreationMenu}
                component={Link}
                to={RE_PROCESS_BATCH_CREATE}>
                <Stack direction="row" spacing={1.5}>
                  <Refresh />
                  <Typography fontSize="0.8rem" fontWeight={700}>
                    Re Process Batch
                  </Typography>
                </Stack>
              </MenuItem>
            </Menu>
            <Stack direction="row" spacing={1}>
              <Button
                component={Link}
                to={`${PRINT_BATCHESQR}`}
                state={{ data: qrData, title: 'Batches QR' }}
                disabled={qrData?.length === 0}
                variant="contained">
                <Stack direction="row" spacing={0.5}>
                  <Typography fontSize="15px">Preview QR Codes</Typography>
                  <QrCodeTwoToneIcon />
                </Stack>
              </Button>
              <Button
                variant="contained"
                id="basic-button"
                onClick={openBatchCreationMenu}>
                Add Batch
              </Button>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            width="100%">
            <Stat
              label="In Queue"
              content={stats?.draftBatches || 0}
              color="#FECD70"
            />
            <Stat
              label="Running"
              content={stats?.runningBatches || 0}
              color="#4562f7"
            />
            <Stat
              label="Halted"
              content={stats?.haltedBatches || 0}
              color="#FA7070"
            />
            <Stat
              label="Paused"
              content={stats?.pausedBatchs || 0}
              color="#F1A661"
            />
            <Stat
              label="In Delivery"
              content={stats?.readyForDeliveryBatches || 0}
              color="#7FB77E"
            />
          </Stack>

          {/* my search form   */}
          <Stack width="100%">
            <form onSubmit={handleSubmit}>
              <Stack
                mt={2}
                direction="row"
                width="100%"
                spacing={2}
                alignItems="center"
                justifyContent="left">
                <TextField
                  size="small"
                  onChange={(event) => setLotNumber(event.target.value)}
                  inputRef={textInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  flexWrap="wrap"
                  fullWidth
                  id="outlined-basic"
                  label="Search"
                  sx={{ maxWidth: 300 }}
                  variant="outlined"
                />

                <FormControl fullWidth sx={{ maxWidth: 200 }}>
                  <InputLabel id="SearchStatus">Status</InputLabel>
                  <Select
                    size="small"
                    defaultValue={statusTextField}
                    value={typeTextField}
                    labelId="SearchStatus"
                    id="demo-simple-select"
                    label="Status"
                    placeholder="Status"
                    onChange={(event) => {
                      setStatus(event.target.value);

                      setTypeTextField(event.target.value);
                    }}>
                    <MenuItem value="ON HOLD">Halted</MenuItem>
                    <MenuItem value="IS PAUSED">Paused</MenuItem>
                    <MenuItem value="IN QUEUE">In Queue</MenuItem>
                    <MenuItem value="DRAFT">Draft</MenuItem>
                    <MenuItem value="WAITING FOR APPROVAL">
                      Waiting For Approval
                    </MenuItem>
                    <MenuItem value="WAITING FOR DELIVERY">
                      Waiting For Delivery
                    </MenuItem>
                    <MenuItem value="READY FOR DELIVERY">
                      Ready For Delivery
                    </MenuItem>
                    <MenuItem value="DELIVERED">Delivered</MenuItem>
                    <MenuItem value="'PARTIAL DELIVERED">
                      Partial Delivered
                    </MenuItem>
                    <MenuItem value="'REJECTED">Rejected</MenuItem>
                  </Select>
                </FormControl>

                <IconButton
                  title="Edit"
                  aria-label="edit"
                  type="submit"
                  sx={{
                    bgcolor: '#303030',
                    color: 'white',
                    borderRadius: 1,
                    ':hover': { bgcolor: '#404040' },
                  }}>
                  <SearchIcon />
                </IconButton>

                {/* reset button  */}
                <IconButton
                  title="Edit"
                  aria-label="edit"
                  onClick={() => resetAll()}
                  sx={{
                    bgcolor: '#303030',
                    color: 'white',
                    borderRadius: 1,
                    ':hover': { bgcolor: '#404040' },
                  }}>
                  <RestartAltIcon />
                </IconButton>
              </Stack>
            </form>
          </Stack>

          <Box width="100%" height={500}>
            <DataGrid
              columns={AllBatchListColumns}
              rows={batchList}
              rowCount={totalCount}
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[1, 10, 15, 20, 25, 30]}
              paginationMode="server"
              filterMode="server"
              onFilterModelChange={(filterModel) => {
                console.log(filterModel);
              }}
              onPageChange={(value) => setPage(value)}
              onPageSizeChange={(value) => setPageSize(value)}
              loading={loading}
              components={{
                NoRowsOverlay: NoDataOverlay,
                NoResultsOverlay: NoDataOverlay,
              }}
            />
          </Box>
        </Stack>
      </motion.div>
    </AnimatePresence>
  );
};

export default Batch;
