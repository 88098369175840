import axios from 'axios';
import { UNAUTHORIZED } from '../constants/routeUrls';
import { store } from '../store';
import { authActions } from '../store/reducers/authReducer';
import { errorNoitif } from '../utils/notification';

// create axios instance

export default (baseURL) => {
  const instance = axios.create({
    baseURL,
  });

  //request interceptor to add the auth token header to requests
  instance.interceptors.request.use(
    (config) => {
      // checking if the access token exist in the localStorage
      const accessToken = store.getState().auth.accessToken;
      config.headers = {
        'Content-Type': config.headers['Content-Type']
          ? config.headers['Content-Type']
          : 'application/json',
        Authorization: `Bearer ${accessToken}`,
      };
      return config;
    },
    (error) => {
      if (error.response) {
        console.error(error.response);
      } else if (error.request) {
        errorNoitif('Internal server error');
      } else {
        errorNoitif(error?.response?.data?.msg);
      }
      return Promise.reject(error);
    },
  );

  // response interceptor to refresh token on receiving token expired error
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        // logging user out
        store.dispatch(authActions.logout());
        return;
      }
      if (error.response.status === 500) {
        errorNoitif(error?.response?.data?.msg);
      }
      if (error.response.status === 404) {
        errorNoitif(error?.response?.data?.msg);
      }
      if (error.response.status === 400) {
        errorNoitif(error?.response?.data?.msg);
      }

      if (error.response.status === 403) {
        errorNoitif(error?.response?.data?.msg);
        window.location.replace(UNAUTHORIZED);
      }
      if (error.response) {
        console.error(error.response);
      } else if (error.request) {
        errorNoitif('Internal server error');
      } else {
        errorNoitif('Internal server error');
      }
      return Promise.reject(error);
    },
  );

  return instance;
};
