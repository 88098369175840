import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Stack } from '@mui/material';
import Feed from './Feed';
import BasicInfo from './BasicInfo';
import Stats from './Stats';
import ProductionChart from './ProductionChart';

const OverviewIndex = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}>
        <Stack spacing={1}>
          <Stack direction={{ lg: 'row', md: 'column' }} spacing={2}>
            <Stack
              direction={{ lg: 'row', md: 'column' }}
              spacing={2}
              width="100%">
              <Stack width="100%" flex={1}>
                <BasicInfo />
              </Stack>
              <Stack width="100%" flex={2}>
                <Stats />
              </Stack>
              <Stack width="100%" flex={1}>
                <Feed />
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={{ lg: 'row', md: 'column' }} spacing={2}>
            <Stack width="100%">
              <ProductionChart />
            </Stack>
          </Stack>
        </Stack>
      </motion.div>
    </AnimatePresence>
  );
};

export default OverviewIndex;
