import { createSlice } from '@reduxjs/toolkit';
import { successNoitif } from '../../utils/notification';
import { otpinput, signin } from '../actions/auth';

const initialState = {
  isLoggedIn: false,
  user: null,
  refreshToken: null,
  accessToken: null,
  tempUserData: null,
  sessionId: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.refreshToken = null;
      state.accessToken = null;
      state.tempUserData = null;
      state.sessionId = null;
    },
    clearUserTempData: (state) => {
      state.tempUserData = null;
    },
    saveSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.fulfilled, (state, action) => {
        state.tempUserData = action.payload;
      })
      .addCase(otpinput.fulfilled, (state, action) => {
        if (action.payload) {
          const { accessToken, user, refreshToken, sessionId } = action.payload;
          successNoitif(`Welcome to factory pro ${user.name}`);
          state.isLoggedIn = true;
          state.accessToken = accessToken;
          state.refreshToken = refreshToken;
          state.sessionId = sessionId;
          state.user = user;
          state.tempUserData = null;
        } else {
          // errorNoitif('Invalid otp');
          state.isLoggedIn = false;
          state.user = null;
          state.refreshToken = null;
          state.accessToken = null;
          state.tempUserData = null;
          state.sessionId = null;
        }
      });
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
