import Request from './request';

export const createUtility = async (formData) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Post('/factory/utility', { ...formData });

  if (response) {
    return response.utility;
  }

  return null;
};

export const fetchUtilities = async (query) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/factory/utility', { ...query });

  if (response) {
    return response.utilities;
  }

  return [];
};

export const fetchUtilityLogs = async (query) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/factory/utility/logs', { ...query });

  if (response) {
    return response.utilityLogs;
  }

  return [];
};

export const fetchUtilityDetails = async (uid) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/factory/utility/${uid}`);

  if (response) {
    return {
      utility: response.utility,
      logs: response.logs,
    };
  }

  return {
    utility: null,
    logs: [],
  };
};

export const factoryUtilityCharts = async (uid) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/factory/utility/chart/${uid}`);

  if (response) {
    return response.chartConfig;
  }

  return null;
};

export const updateUtility = async ({ uid, formData }) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(`/factory/utility/${uid}`, {
    ...formData,
  });

  if (response) {
    return response.log;
  }

  return null;
};

export const factoryAllUtilityCharts = async () => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/factory/utility/merged/chart');

  if (response) {
    return response.chartData;
  }

  return [];
};
