import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Tabs from '../../widgets/Tabs';
import Tab from '../../widgets/Tab';

import SearchIcon from '@mui/icons-material/SearchTwoTone';
import { fetchAllShifts } from '../../api/shift';
import { DataGrid } from '@mui/x-data-grid';
import { banUsers, fetchAllUser } from '../../api/users';
import mapRoleTitle from '../../utils/mapRoleTitle';
import PageHeader from '../../widgets/PageHeader';
import Button from '../../widgets/Button';
import AddNewUser from '../../components/user/AddNewUser';

import { VisibilityTwoTone as Visibility } from '@mui/icons-material';
import Dialog from '../../widgets/Dialog';
import checkPermission from '../../utils/checkPermission';
import { BAN_USER } from '../../constants/permissionMapping';
import {
  errorNoitif,
  successNoitif,
  warningNoitif,
} from '../../utils/notification';
import { Link } from 'react-router-dom';
import { PRINT_USERQR, USER_DETAILS } from '../../constants/routeUrls';
import RestartAltIcon from '@mui/icons-material/RestartAltTwoTone';
import QrCodeTwoToneIcon from '@mui/icons-material/QrCodeTwoTone';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/reducers/userReducer';
import PermissionWrapper from '../wrapper/PermissionWrapper';
import PageTitle from '../../utils/PageTitle';

const User = () => {
  const [users, setUsers] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeShift, setActiveShift] = useState(null);
  const [isFetchingInfo, setIsFetchingInfo] = useState(false);
  const [selectedUsersOnTable, setSelectedUsersOnTable] = useState([]);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [userName, setUserName] = useState('');
  const nameInput = React.useRef(null);
  const [status, setStatus] = useState('');
  const [role, setRole] = useState('');
  const [statusTextField, setStatusTextField] = useState(0);
  const [typeTextField, setTypeTextField] = useState(0);
  const [roleTextField, setRoleTextField] = useState(0);
  const [roleTypeTextField, setRoleTypeTextField] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [showConfirmBanDialog, setShowConfirmBanDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const handleCreationFormClose = () => setShowAddUserForm(false);
  const handleCloseConfirmBanDialog = () => setShowConfirmBanDialog(false);
  const [userTotalEntries, setUserTotalEntires] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const dispatch = useDispatch();
  const fetchUsers = async (userName, status, role, page, pageSize) => {
    setIsFetchingInfo(true);
    const response = await fetchAllUser(
      userName,
      status,
      role,
      activeShift,
      page,
      pageSize,
    );
    if (response) {
      setUsers(response.users);
      setUserTotalEntires(response.total);
    }
    setIsFetchingInfo(false);
    dispatch(userActions.saveUserData(response.users));
  };

  const fetchShifts = async () => {
    setIsFetchingInfo(true);
    const response = await fetchAllShifts();
    if (response) {
      setShifts(response);
    }
    setIsFetchingInfo(false);
  };

  useEffect(() => {
    (async () => await fetchShifts())();
  }, []);

  useEffect(() => {
    (async () => await fetchUsers())();
    setRoleList([
      { name: 'ADMIN', displayName: 'Admin' },
      { name: 'ACC', displayName: 'Accounts' },
      { name: 'SI', displayName: 'Store Supervisor' },
      { name: 'DPO', displayName: 'Dyeing Supervisor' },
      { name: 'FS', displayName: 'Finishing Supervisor' },
      { name: 'QCS', displayName: 'Quality Supervisor' },
      { name: 'BM', displayName: 'Batch Manager' },
      { name: 'SUPERVISOR', displayName: 'Supervisor' },
      { name: 'MANAGER', displayName: 'Manager' },
      { name: 'DI', displayName: 'Delivery Incharge' },
    ]);
  }, [activeShift]);

  useEffect(() => {
    (async () => await fetchUsers(userName, status, role, page, pageSize))();
  }, [isUpdated, page, pageSize]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'INACTIVE':
        return 'warning';
      case 'BANNED':
        return 'error';
      default:
        return 'default';
    }
  };

  const getBatteryStatus = (percent) => {
    if (percent >= 70 && percent <= 100) {
      return 'success';
    } else if (percent >= 30 && percent < 70) {
      return 'warning';
    } else if (percent < 30) {
      return 'error';
    }
  };

  const banSelectedUsers = async () => {
    const hasPermission = checkPermission(BAN_USER);
    if (hasPermission) {
      const response = await banUsers();
      if (response) {
        setUsers(response);
        successNoitif('Selected users have been banned');
      } else {
        errorNoitif('Failed to ban users');
      }
      setActiveShift(null);
      setActiveTab(0);
    } else {
      warningNoitif('You do not have permission to do this');
    }

    setShowConfirmBanDialog(false);
  };

  const columns = [
    {
      field: 'actions',
      headerName: '',
      width: 40,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              component={Link}
              to={`${USER_DETAILS}/${params.row.uid}`}>
              <Visibility sx={{ width: 18, height: 18 }} />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      headerName: 'Username',
      field: 'username',
      width: 120,
    },
    {
      headerName: 'NAME',
      field: 'name',
      width: 200,
    },
    {
      headerName: 'EMAIL',
      field: 'email',
      width: 200,
    },
    {
      headerName: 'PHONE',
      field: 'phone',
      width: 150,
    },
    {
      headerName: 'STATUS',
      field: 'userStatus',
      type: 'singleSelect',
      valueOptions: ['ACTIVE', 'INACTIVE', 'BANNED'],
      width: 120,
      renderCell: (params) => (
        <Chip
          size="small"
          color={getStatusColor(params.value)}
          label={params.value}
          sx={{ textTransform: 'capitalize' }}
        />
      ),
    },
    {
      headerName: 'VERIFIED',
      field: 'isVerified',
      width: 120,
      renderCell: (params) => {
        if (params.value) {
          return <Chip size="small" label="Yes" color="success" />;
        }
        return <Chip size="small" label="No" color="error" />;
      },
    },
    {
      headerName: 'ACTIVE',
      field: 'isActive',
      width: 120,
      renderCell: (params) => {
        if (params.value) {
          return <Chip size="small" label="Yes" color="success" />;
        }
        return <Chip size="small" label="No" color="error" />;
      },
    },
    {
      headerName: 'BATTERY (%)',
      field: 'batteryPercent',
      width: 120,
      renderCell: (params) => (
        <Chip
          size="small"
          color={getBatteryStatus(params.value)}
          label={params.value}
          sx={{ textTransform: 'capitalize' }}
        />
      ),
    },
    {
      headerName: 'ROLES',
      field: 'roles',
      width: 400,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5}>
            {params.value.split(',').map((role) => (
              <Chip
                key={role}
                size="small"
                label={mapRoleTitle(role)}
                color="primary"
              />
            ))}
          </Stack>
        );
      },
    },
    {
      headerName: 'SHIFT',
      field: 'shiftName',
      width: 200,
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchUsers(userName, status, role, page, pageSize);
    setUserName(userName);
    setStatus(status);
    setRole(role);
  };
  const resetAll = () => {
    fetchUsers('', '', '', page, pageSize);
    setUserName('');
    nameInput.current.value = '';
    setStatus('');
    setRole('');
    setStatusTextField(0);
    setTypeTextField(0);
    setRoleTextField(0);
    setRoleTypeTextField(0);
  };

  const toPrint = () => {
    let array = [];
    if (selectedUsersOnTable.length > 0) {
      users.map((element) => {
        for (const selectedElement of selectedUsersOnTable) {
          if (element.id == selectedElement) {
            array.push(element);
          }
        }
      });
      dispatch(userActions.saveUserData(array));
    } else {
      dispatch(userActions.saveUserData(users));
    }
  };
  function CustomNoRowsOverlay() {
    return (
      <Stack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1 }}>
        No Data Found
      </Stack>
    );
  }

  return (
    <Box>
      <PageTitle title="Users" />
      <Dialog
        title="Are you sure?"
        onClose={handleCloseConfirmBanDialog}
        noHandler={handleCloseConfirmBanDialog}
        open={showConfirmBanDialog}
        yesHandler={banSelectedUsers}>
        <Typography sx={{ mt: 2 }}>
          Are you sure you want to ban selected user?
        </Typography>
      </Dialog>
      <AddNewUser
        // update={setUsers}
        shifts={shifts}
        show={showAddUserForm}
        handleClose={handleCreationFormClose}
        activeShift={activeShift}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}
      />

      <Stack
        pt={3}
        spacing={3}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '80vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Stack
          width="95%"
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <PageHeader>Users</PageHeader>
          <Stack spacing={2} direction="row">
            {selectedUsersOnTable.length > 0 && (
              <Button
                variant="contained"
                color="error"
                onClick={() => setShowConfirmBanDialog(true)}>
                Ban Selected
              </Button>
            )}
            <Button
              onClick={() => {
                toPrint();
              }}
              component={Link}
              to={`${PRINT_USERQR}`}
              target="_blank"
              rel="noreferrer noopener"
              variant="contained">
              <Stack direction="row" spacing={0.5}>
                <Typography fontSize="15px">Preview QR Codes</Typography>
                <QrCodeTwoToneIcon />
              </Stack>
            </Button>
            <PermissionWrapper acceptedRoles="ADMIN">
              <Button
                variant="contained"
                onClick={() => setShowAddUserForm(true)}>
                Add New
              </Button>
            </PermissionWrapper>
          </Stack>
        </Stack>
        <Stack width="95%">
          <Tabs value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
            <Tab label="All" onClick={() => setActiveShift(null)} />
            {shifts.map((shift) => (
              <Tab
                key={shift.uid}
                label={shift.name}
                onClick={() => setActiveShift(shift.id)}
              />
            ))}
          </Tabs>
          <Stack mt={3}>
            <form onSubmit={handleSubmit}>
              <Stack
                mt={2}
                direction="row"
                width="100%"
                spacing={2}
                alignItems="center"
                justifyContent="left">
                <TextField
                  size="small"
                  onChange={(event) => setUserName(event.target.value)}
                  inputRef={nameInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  flexWrap="wrap"
                  fullWidth
                  id="outlined-basic"
                  label="Search Name"
                  sx={{ maxWidth: 300 }}
                  variant="outlined"
                />

                {/* role search */}
                <FormControl fullWidth sx={{ maxWidth: 200 }}>
                  <InputLabel id="SearchStatus">Role</InputLabel>
                  <Select
                    size="small"
                    defaultValue={roleTextField}
                    value={roleTypeTextField}
                    labelId="SearchStatus"
                    id="demo-simple-select"
                    label="Role"
                    placeholder="Role"
                    onChange={(event) => {
                      setRole(event.target.value);

                      setRoleTypeTextField(event.target.value);
                    }}>
                    {roleList?.map((role, index) => (
                      <MenuItem key={index} value={role.name}>
                        {role?.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* status search  */}
                <FormControl fullWidth sx={{ maxWidth: 200 }}>
                  <InputLabel id="SearchStatus">Status</InputLabel>
                  <Select
                    size="small"
                    defaultValue={statusTextField}
                    value={typeTextField}
                    labelId="SearchStatus"
                    id="demo-simple-select"
                    label="Status"
                    placeholder="Status"
                    onChange={(event) => {
                      setStatus(event.target.value);

                      setTypeTextField(event.target.value);
                    }}>
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                    <MenuItem value="BANNED">Banned</MenuItem>
                  </Select>
                </FormControl>

                {/* Search button  */}
                <IconButton
                  title="Edit"
                  aria-label="edit"
                  type="submit"
                  sx={{
                    bgcolor: '#303030',
                    color: 'white',
                    borderRadius: 1,
                    ':hover': { bgcolor: '#404040' },
                  }}>
                  <SearchIcon />
                </IconButton>

                {/* reset button  */}
                <IconButton
                  title="Edit"
                  aria-label="edit"
                  onClick={() => resetAll()}
                  sx={{
                    bgcolor: '#303030',
                    color: 'white',
                    borderRadius: 1,
                    ':hover': { bgcolor: '#404040' },
                  }}>
                  <RestartAltIcon />
                </IconButton>
              </Stack>
            </form>
          </Stack>
          <Box height="450px" width="100%" mt={2}>
            <DataGrid
              columns={columns}
              rows={users}
              rowCount={userTotalEntries}
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
              paginationMode="server"
              filterMode="server"
              onPageChange={(value) => setPage(value)}
              onPageSizeChange={(value) => setPageSize(value)}
              density="compact"
              checkboxSelection
              onSelectionModelChange={(ids) => setSelectedUsersOnTable(ids)}
              loading={isFetchingInfo}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default User;
