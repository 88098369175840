import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBatchDetail } from '../../api/batch';

const BatchReportPrintPreview = () => {
  const [batch, setBatch] = useState(null);
  const { lotNumber } = useParams();
  const fetchBtach = async () => {
    const response = await fetchBatchDetail(lotNumber);

    if (response) {
      setBatch(response);
    }
  };

  useEffect(() => {
    if (lotNumber) {
      (async () => await fetchBtach())();
    }
  }, [lotNumber]);
  return (
    <div>
      <pre>{JSON.stringify(batch, null, 2)}</pre>
    </div>
  );
};

export default BatchReportPrintPreview;
