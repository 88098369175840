import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { OrderFabricInfo } from '../../constants/table-column/order';
import FabricInformationDetails from './FabricInformationDetails';

const FabricTab = ({
  fabricsInfo,
  isQuantityUpdated,
  setIsQuantityUpdated,
}) => {
  return (
    <Stack>
      <Stack mt={3}>
        {!fabricsInfo ? (
          <Stack
            justifyContent="center"
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack width="98%">
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
              Fabric Summary
            </Typography>
            <Box
              width="100%"
              height={300}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={OrderFabricInfo}
                rows={fabricsInfo}
                getRowId={(row) => row.fabricInfo.fabricId}
              />
            </Box>
          </Stack>
        )}
      </Stack>
      <Stack>
        {!fabricsInfo ? (
          <Stack
            justifyContent="center"
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Stack
              alignItems="left"
              justifyContent="flex-start"
              mt={4}
              width="100%">
              <Stack
                flexWrap="wrap"
                direction="row"
                alignItems="center"
                justifyContent="flex-start">
                {fabricsInfo.map((fabric) => (
                  <FabricInformationDetails
                    key={fabric?.fabricInfo?.fabricId}
                    fabric={fabric}
                    isQuantityUpdated={isQuantityUpdated}
                    setIsQuantityUpdated={setIsQuantityUpdated}
                  />
                ))}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default FabricTab;
