import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { updateOrderTransactionAmount } from '../../api/order';
import { successNoitif } from '../../utils/notification';
import { formatNumber } from '../../utils/number';
import { OrderTranscationEditAmountValidator } from '../../validators/order';
import Button from '../../widgets/Button';

const OrderTransactionUpdateModal = ({
  show,
  toggleModal,
  paymentEditData,
  accountInfo,
  transactionUpdate,
  setTransactionUpdate,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onClose = () => {
    handleCreationFormClose();
  };
  const handleCreationFormClose = () => {
    reset({
      transactionAmount: paymentEditData.paymentAmount,
    });
    toggleModal(false);
  };
  const handleTransactionChangeSubmission = () => {
    setShowConfirmation(true);
    toggleModal(false);
  };
  const onCloseConfirmation = () => {
    handleCloseConfirmation();
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const updateQuantityFormSubmit = async (data) => {
    data.deliveryBillId = paymentEditData?.deliveryBillId;

    const response = await updateOrderTransactionAmount({
      transactionId: paymentEditData?.transactionId,
      formData: data,
    });
    if (response) {
      setTransactionUpdate(!transactionUpdate);
      successNoitif('Transaction Amount has been Updated');
      setShowConfirmation(false);
    }
  };

  const resolver = yupResolver(OrderTranscationEditAmountValidator);
  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      transactionAmount: paymentEditData?.paymentAmount,
    },
    resolver,
  });

  const transactionAmount = useWatch({ control, name: 'transactionAmount' });

  useEffect(() => {
    if (
      transactionAmount > accountInfo?.totalAmount ||
      transactionAmount <= 0
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [transactionAmount]);

  useEffect(() => {
    setValue('transactionAmount', paymentEditData?.paymentAmount);
  }, [paymentEditData?.paymentAmount]);

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle>Update Payment Amount</DialogTitle>
        <form>
          <Stack
            sx={{
              paddingLeft: 3,
              paddingRight: 3,
              paddingTop: 3,
              paddingBottom: 3,
            }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>Payment Date:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {dayjs(paymentEditData?.paymentDate).format('DD/MMM/YYYY ')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Typography>Transaction Type:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  <Typography>{paymentEditData?.transactionType}</Typography>
                </Typography>
              </Grid>
            </Grid>
            {paymentEditData?.reason ? (
              <Grid container>
                <Grid item xs={4}>
                  <Typography>Transaction Reason:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    <Typography>{paymentEditData?.reason}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            <Grid container>
              <Grid item xs={4}>
                <Typography>Current Amount:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  <Typography>
                    {formatNumber(paymentEditData?.paymentAmount)}(BDT)
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Stack mt={2}></Stack>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item xs={4}>
                <Typography>Update Amount:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Stack>
                  <TextField
                    value={transactionAmount}
                    size="small"
                    required
                    placeholder="Transaction Amount"
                    {...register('transactionAmount')}
                    error={errors?.transactionAmount}
                    helperText={errors?.transactionAmount?.message}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 3, paddingBottom: 3 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red' }}
              color="error"
              onClick={handleCreationFormClose}>
              Cancel
            </Button>
            <Tooltip
              title={
                isValid
                  ? ''
                  : `Transaction ammount can not be greater than ${accountInfo?.totalAmount}BDT`
              }>
              <Stack>
                <Button
                  variant="contained"
                  disabled={!isDirty || !isValid ? true : false}
                  onClick={handleSubmit(handleTransactionChangeSubmission)}>
                  submit
                </Button>
              </Stack>
            </Tooltip>
          </Stack>
        </form>
      </Dialog>
      <Stack>
        <Dialog
          onClose={onCloseConfirmation}
          open={showConfirmation}
          maxWidth="sm">
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to update Transaction Amount?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
                toggleModal(true);
              }}>
              No
            </Button>

            <Button
              variant="contained"
              onClick={handleSubmit(updateQuantityFormSubmit)}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
    </>
  );
};

export default OrderTransactionUpdateModal;
