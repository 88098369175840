import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { fetchBatchList } from '../../api/batch';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { BatchApprovalListColumns } from '../../constants/table-column/batch';
import NoDataOverlay from '../global/NoDataOverlay';

const BatchApproval = () => {
  const [batchList, setBatchList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [batchPage, setBatchPage] = useState(0);
  const [batchPageSize, setBatchPageSize] = useState(10);
  const [lotNumber, setLotNumber] = useState('');
  const batchTextInput = React.useRef(null);
  const [batchTotalEntries, setBatchTotalEntries] = useState(0);
  const fetchBatches = async (lotNumber, batchPage, batchPageSize) => {
    setIsLoading(true);
    const response = await fetchBatchList({
      lotNumber: lotNumber,
      status: 'WAITING FOR APPROVAL',
      page: batchPage,
      pageSize: batchPageSize,
    });

    setBatchList(response.batches);
    setBatchTotalEntries(response.total);
    setIsLoading(false);
  };

  const resetBatch = () => {
    fetchBatches('', batchPage, batchPageSize);
    batchTextInput.current.value = '';
    setLotNumber('');
  };

  const handleBatchSubmit = (event) => {
    event.preventDefault();
    setLotNumber(lotNumber);
    fetchBatches(lotNumber, batchPage, batchPageSize);
  };

  useEffect(() => {
    (async () => {
      await fetchBatches();
    })();
  }, []);

  useEffect(() => {
    (async () => await fetchBatches(lotNumber, batchPage, batchPageSize))();
  }, [batchPage, batchPageSize]);

  return (
    <Stack>
      <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1}>
        Batches
      </Typography>
      <Stack my={1} width="100%">
        <Grid container width="100%">
          <Grid item xs={12} sm={7} md={8}>
            <form onSubmit={handleBatchSubmit}>
              <Stack
                direction={{ md: 'row', xs: 'column', sm: 'row' }}
                width="100%"
                spacing={2}
                alignItems="left"
                justifyContent="left">
                <TextField
                  size="small"
                  placeholder="Lot Number"
                  onChange={(event) => setLotNumber(event.target.value)}
                  inputRef={batchTextInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  flexWrap="wrap"
                  fullWidth
                  id="outlined-basic"
                  label="Search"
                  sx={{ maxWidth: 250 }}
                  variant="outlined"
                />
                <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
                  <IconButton
                    title="Edit"
                    aria-label="edit"
                    type="submit"
                    sx={{
                      bgcolor: '#171820',
                      color: 'white',
                      borderRadius: 1,
                      ':hover': { bgcolor: '#303030' },
                    }}>
                    <SearchIcon />
                  </IconButton>
                  {/* reset button  */}
                  <IconButton
                    title="Edit"
                    aria-label="edit"
                    onClick={() => resetBatch()}
                    sx={{
                      bgcolor: '#171820',
                      color: 'white',
                      borderRadius: 1,
                      ':hover': { bgcolor: '#303030' },
                    }}>
                    <RestartAltIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Stack>
      <Stack>
        {batchList ? (
          <>
            <Box
              pt={1}
              width="100%"
              height={450}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={BatchApprovalListColumns()}
                rows={batchList}
                rowCount={batchTotalEntries}
                // getRowId={(row) => row.uid}
                page={batchPage}
                pageSize={batchPageSize}
                rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                paginationMode="server"
                filterMode="server"
                onPageChange={(value) => setBatchPage(value)}
                onPageSizeChange={(value) => setBatchPageSize(value)}
                loading={isLoading}
                components={{
                  NoRowsOverlay: NoDataOverlay,
                }}
              />
            </Box>
          </>
        ) : (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default BatchApproval;
