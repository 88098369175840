import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogTitle, Stack, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { updateBatchOrderQuantity } from '../../api/order';
import { errorNoitif, successNoitif } from '../../utils/notification';
import { OrderTotalQuanityValidator } from '../../validators/order';
import Button from '../../widgets/Button';

const EditOrderDetails = ({
  toggleModal,
  show,
  fabric,
  isQuantityUpdated,
  setIsQuantityUpdated,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const resolver = yupResolver(OrderTotalQuanityValidator);
  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      totalQuantity: fabric?.fabricInfo?.quantity,
    },
    resolver,
  });
  const totalQuantity = useWatch({ control, name: 'totalQuantity' });

  const onCloseConfirmation = () => {
    handleCloseConfirmation();
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };
  const handleCreationFormClose = () => {
    reset({
      totalQuantity: fabric?.fabricInfo?.quantity,
    });

    toggleModal(false);
  };

  const handleQuantityChangeSubmission = () => {
    setShowConfirmation(true);
    toggleModal(false);
  };

  const updateQuantityFormSubmit = async (data) => {
    const response = await updateBatchOrderQuantity({
      fabricId: fabric?.fabricInfo?.fabricId,
      data: data,
    });

    if (response) {
      setIsQuantityUpdated(!isQuantityUpdated);
      successNoitif('Total Quantity has been Updated');
    } else {
      errorNoitif('Error occurred');
    }

    setShowConfirmation(false);
  };

  useEffect(() => {
    const totalProcess =
      fabric?.fabricInfo?.processed +
      fabric?.fabricInfo?.processing +
      fabric?.fabricInfo?.reProcessed +
      fabric?.fabricInfo?.reProcessing;
    if (totalQuantity <= totalProcess) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [totalQuantity]);
  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="simple-dialog-title">
          Change Total Quantity
        </DialogTitle>
        <form>
          <Stack
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 0,
              paddingBottom: 3,
            }}>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Total Quantity"
                required
                placeholder="Total Quantity"
                {...register('totalQuantity')}
                error={errors?.totalQuantity}
                helperText={errors?.totalQuantity?.message}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red' }}
              color="error"
              onClick={handleCreationFormClose}>
              Cancel
            </Button>
            <Tooltip
              title={
                isValid
                  ? ''
                  : `Total Quantity should be greater than 
                     processed, reProcessed, processing, reProcessed Quantity Total  ${
                       fabric?.fabricInfo?.processed +
                       fabric?.fabricInfo?.processing +
                       fabric?.fabricInfo?.reProcessed +
                       fabric?.fabricInfo?.reProcessing
                     } KG`
              }>
              <Stack>
                <Button
                  variant="contained"
                  disabled={!isDirty || !isValid ? true : false}
                  onClick={handleSubmit(handleQuantityChangeSubmission)}>
                  Update
                </Button>
              </Stack>
            </Tooltip>
          </Stack>
        </form>
      </Dialog>
      <Stack>
        <Dialog
          onClose={onCloseConfirmation}
          open={showConfirmation}
          maxWidth="sm">
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to update Total Quantity?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
                toggleModal(true);
              }}>
              No
            </Button>

            <Button
              variant="contained"
              onClick={handleSubmit(updateQuantityFormSubmit)}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
    </>
  );
};

export default EditOrderDetails;
