export const machineColorMapper = (status) => {
  let Borderstyle = {
    border: '1px solid black',
  };

  switch (status) {
    case 'IDLE':
      Borderstyle = {
        borderRadius: 1,
        border: '2px solid #FF8800',
        color: 'info.main',
        backgroundColor: '#33b5e5',
      };
      break;
    case 'MAINTANENCE':
      Borderstyle = {
        borderRadius: 1,
        border: '2px solid #CC0000',
        color: 'warning.main',
        backgroundColor: '#ffbb33',
      };
      break;
    case 'OUT OF ORDER':
      Borderstyle = {
        borderRadius: 1,
        border: '2px solid #CC0000',
        color: 'error.main',
        backgroundColor: '#ff4444',
      };
      break;
    case 'IN USE':
      Borderstyle = {
        borderRadius: 0.5,
        border: '2px solid',
        color: 'success.main',
        backgroundColor: '#00C851',
      };
      break;
  }
  return Borderstyle;
};
