import {
  PrintTwoTone as Print,
  EditTwoTone as Edit,
} from '@mui/icons-material';
import { Stack, Typography, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  RECIPE_MANAGEMENT,
  RECIPE_PRINT,
} from '../../../../constants/routeUrls';
import RecipeBody from './RecipeBody';

const RecipeInfo = ({ task, recipes }) => {
  const { lotNumber } = useParams();
  const [recipe, setRecipe] = useState();

  useEffect(() => {
    const currentRecipe = recipes.find((item) => item.taskUid === task?.uid);
    setRecipe(currentRecipe);
  }, [task, recipes]);

  return (
    <Stack width="100%" height="100%">
      <Stack
        direction="row"
        alignitems="center"
        justifyContent="space-between"
        px={2}
        py={1}>
        <Typography variant="h6" fontWeight={700}>
          Recipe Details
        </Typography>
        <Stack direction="row" spacing={1}>
          <IconButton
            disabled={recipes?.length <= 0}
            component={Link}
            to={`${RECIPE_PRINT}/${lotNumber}`}
            target="_blank">
            <Tooltip title="Print Recipe">
              <Print />
            </Tooltip>
          </IconButton>
          <IconButton component={Link} to={`${RECIPE_MANAGEMENT}/${lotNumber}`}>
            <Tooltip title="Edit Recipe">
              <Edit />
            </Tooltip>
          </IconButton>
        </Stack>
      </Stack>

      <RecipeBody
        task={task}
        recipe={recipe}
        lotNumber={lotNumber}
        recipes={recipes}
      />
    </Stack>
  );
};

export default RecipeInfo;
