import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { reformateLotNumber } from '../../utils/order';

const OrderDeliveryDetails = ({ batch, change }) => {
  useEffect(() => {}, [change]);

  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item xs={4} md={4}>
          <Stack>
            <Typography width="100%" variant="p" textAlign="left">
              {reformateLotNumber(batch.lotNumber)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} md={4}>
          <Stack>
            <Typography width="100%" variant="p" textAlign="center">
              {batch.processQuantity - batch.deliveredQuantity}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} md={4}>
          <Stack>
            <Typography width="100%" variant="p" textAlign="right">
              {batch.tempDeliverable}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderDeliveryDetails;
