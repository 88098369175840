import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import BasicInfo from '../../components/batch/batch-detail/BasicInfo';
import FabricInfo from '../../components/batch/batch-detail/FabricInfo';
import TaskInfo from '../../components/batch/batch-detail/TaskInfo';
import Button from '../../widgets/Button';
import { RefreshTwoTone } from '@mui/icons-material';
import PageTitle from '../../utils/PageTitle';

const BatchDetails = ({ socket }) => {
  const [batch, setBatch] = useState(null);

  const [isConnected, setIsConnected] = useState(socket.connected);

  const { lotNumber } = useParams();

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(socket.connected);
    });
    socket.emit('batch details', { lotNumber });
    return () => {
      socket.off('connect');
    };
  }, [socket]);

  useEffect(() => {
    socket.on('batch-detail-data', (data) => {
      if (data.lotNumber === lotNumber) {
        setBatch(data);
      }
    });
    return () => {
      socket.off('batch-detail-data');
    };
  }, [socket]);

  return (
    <Box width="98%" mx="auto" height="auto" minHeight="calc(100vh - 130px)">
      <PageTitle title="Batche Details" />
      {!isConnected ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="auto"
          minHeight="100vh"
          spacing={2}>
          <CircularProgress />
          <Typography
            fontSize={18}
            fontWeight={600}
            sx={{ maxWidth: 450, textAlign: 'center' }}>
            Trying to load information for batch #{lotNumber?.split('-')[1]}
            .Try refreshing the page.
          </Typography>
          <Button
            variant="contained"
            color="info"
            endIcon={<RefreshTwoTone />}
            onClick={() => {
              location.reload();
            }}>
            Refresh
          </Button>
        </Stack>
      ) : (
        <Box width="100%" height="auto" minHeight="calc(100vh - 130px)">
          {batch ? (
            <Stack
              width="100%"
              height="auto"
              minHeight="calc(100vh - 130px)"
              mt={2.5}
              spacing={2}>
              <Stack direction="row" spacing={2}>
                <Stack>
                  <BasicInfo batch={batch} update={setBatch} socket={socket} />
                </Stack>
                <Stack flex={1}>
                  <FabricInfo batch={batch} />
                </Stack>
              </Stack>

              <Stack width="100%" height="100%" flex={1}>
                <TaskInfo batch={batch} socket={socket} />
              </Stack>
            </Stack>
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="auto"
              minHeight="100vh"
              spacing={2}>
              <Box
                component="img"
                src="/assets/images/no-data.png"
                height={200}
              />
              <CircularProgress color="success" />
              <Typography
                fontSize={18}
                fontWeight={600}
                sx={{ maxWidth: 450, textAlign: 'center' }}>
                Trying to load information for batch #{lotNumber?.split('-')[1]}
                .Try refreshing the page.
              </Typography>
              <Button
                variant="contained"
                color="info"
                endIcon={<RefreshTwoTone />}
                onClick={() => {
                  location.reload();
                }}>
                Refresh
              </Button>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BatchDetails;
