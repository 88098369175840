import Request from './request';

export const fetchReRunBatchRefSuggestion = async (lotNumber) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get(`batches/suggestions/${lotNumber}`);
  if (response) {
    return response.batches;
  }
  return [];
};

export const fetchAllReRunBatchRefSuggestion = async () => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get('batches/re-process');
  if (response) {
    return response.batches;
  }
  return [];
};

export const fetchBatchByOrderIds = async (orderIds) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get('/batches/by-orders', { orderIds });
  if (!response) {
    return [];
  }
  return response.batches;
};

export const getFilteredMachineList = async (weight) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get(`/machine/by-weight/${weight}`);
  if (!response) {
    return [];
  }
  return response.machines;
};

export const fetchBatchesAssignedToMachine = async (machineId) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get(`/batches/by-machine/${machineId}`);
  if (!response) {
    return [];
  }
  return response.batches;
};

export const createInitialDraftBatch = async (batch) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Post('/batches/initial-draft', { batch });
  if (response.status === 500) {
    return null;
  }
  return response.batch;
};

export const fetchBatchList = async ({ lotNumber, status, page, pageSize }) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/batches', {
    lotNumber,
    status,
    page,
    pageSize,
  });

  if (!response) {
    return {
      batches: [],
      total: 0,
    };
  }
  return {
    batches: response.batches,
    total: response.total,
  };
};

export const fetchBatchQrData = async () => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/batches/qr-data');
  if (response) {
    return { qrData: response.qrData };
  } else {
    return { qrData: [] };
  }
};

export const fetchBatchDetail = async (lotNumber) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get(`/batches/detail/${lotNumber}`);
  if (response.status === 200) {
    return response.batch;
  } else {
    return null;
  }
};

export const addBatchTaskInfo = async ({
  lotNumber,
  taskInfo,
  saveType,
  shouldSave,
  templateInfo,
}) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Post('/batches/add-task-info', {
    lotNumber,
    taskInfo,
    saveType,
    shouldSave,
    templateInfo,
  });
  if (response.status === 200) {
    return response.batch;
  }
  return null;
};

export const validateBatchApproval = async (lotNumber) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get(`/batches/validate/${lotNumber}`);
  if (response) {
    return response.flags;
  }
  return null;
};

export const approveBatch = async (lotNumber) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Patch(`/batches/approve/${lotNumber}`);
  if (response) {
    return `#${lotNumber.split('-')[1]} has been approved`;
  }
  return null;
};

export const rejectBatch = async (lotNumber) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Patch(`/batches/reject/${lotNumber}`);

  if (response) {
    return response.msg;
  }

  return null;
};

export const deleteBatch = async (lotNumber) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Delete(`/batches/${lotNumber}`);

  if (response) {
    return response.msg;
  }

  return null;
};

export const changeBatchStatus = async (lotNumber, status, reason) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Patch(
    `/batches/status/change/${lotNumber}/${status}`,
    { reason },
  );

  if (response) {
    return response.msg;
  }

  return null;
};

export const addBatchRemarks = async (lotNumber, remarks) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(`/batches/remarks/${lotNumber}`, {
    remarks,
  });

  if (response) {
    return response.msg;
  }

  return null;
};

export const skipTask = async (lotNumber, taskUid, reason) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch('/batches/skip', {
    lotNumber,
    taskUid,
    reason,
  });
  if (response) {
    return response.msg;
  }

  return null;
};

export const reAssignMachine = async (machineName, lotNumber) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Patch('/batches/machine/change', {
    machineName,
    lotNumber,
  });

  if (response) {
    return response.msg;
  }

  return null;
};

export const fetchBatchLiveTrackingStats = async () => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get('/batches/tracking/stats');
  if (response) {
    return response.stats;
  }

  return null;
};

export const fetchBatchBasicInfoStats = async () => {
  const axisoV2 = new Request(2);
  const response = await axisoV2.Get('/batches/stats/basic');
  if (response) {
    return response;
  }

  return null;
};

export const fetchBatchLogsFeed = async () => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/batches/feed/logs');
  if (response) {
    return response.logs;
  }

  return [];
};

export const fetchBatchProductionStats = async () => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/batches/stats/production');
  if (response) {
    return response;
  }

  return {
    productionTarget: 0,
    dyeingTarget: 0,
    productionRate: 0,
    dyeingProductionRate: 0,
    finishingProductionRate: 0,
    delay: 0,
    early: 0,
  };
};

export const fetchBatchProductionChartData = async (type) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/batches/chart/production', { type });
  if (response) {
    return response;
  }

  return {
    status: {
      production: 0,
      processing: 0,
      reProcess: 0,
      rejected: 0,
    },

    datasets: [],
    labels: [],
  };
};

export const fetchBatchStatsForBatchIndexPage = async () => {
  const axisoV2 = new Request(2);
  const response = await axisoV2.Get('/batches/stats');
  if (response) {
    return response;
  }

  return null;
};

export const fetchBatchTaskTemplates = async (query) => {
  const axisoV2 = new Request(2);
  const response = await axisoV2.Get('/batches/templates', { ...query });
  if (response) {
    return response.templates;
  }

  return [];
};

export const deleteBatchTaskTemplate = async (uid) => {
  const axisoV2 = new Request(2);
  const response = await axisoV2.Delete(`/batches/template/${uid}`);
  if (response) {
    return response;
  }

  return null;
};

export const createBatchTaskTemplate = async (templateData) => {
  const axisoV2 = new Request(2);
  const response = await axisoV2.Post('/batches/recipe-template', templateData);
  if (response) {
    return response;
  }
  return null;
};

export const fetchBatchRecipeTemplate = async (taskName) => {
  const axisoV2 = new Request(2);
  const response = await axisoV2.Get('/batches/recipe-template', {
    taskName: taskName,
  });
  if (response) {
    return response;
  }
  return null;
};

export const fetchAllAccountBatches = async ({
  lotNumber,
  status,
  page,
  pageSize,
}) => {
  const axisoV2 = new Request(2);
  const response = await axisoV2.Get('/batches/accounts', {
    lotNumber,
    status,
    page,
    pageSize,
  });

  if (response) {
    return {
      batches: response.batches,
      total: response.totalEntries,
    };
  } else {
    return { batches: [], total: 0 };
  }
};

export const updateTaskAdditionalRemarks = async ({
  taskUid,
  additionalRemarks,
}) => {
  const axisoV2 = new Request(2);

  const response = await axisoV2.Patch('/batches/additional-remarks', {
    taskUid,
    additionalRemarks,
  });

  if (response) {
    return response;
  }
  return null;
};

export const checkValidLotNumber = async (lotNumber) => {
  const axisoV2 = new Request(2);

  const response = await axisoV2.Get(`/batches/check-lot-number/${lotNumber}`);

  if (response) {
    return response;
  }

  return null;
};

// fetchBatchProductionTableData
export const fetchBatchProductionTableData = async ({ startDate, endDate }) => {
  const axisoV2 = new Request(2);

  const response = await axisoV2.Get('/batches/production-report', {
    startDate,
    endDate,
  });

  if (response) {
    return response.datasets;
  } else {
    return [];
  }
};

export const fetchBatchProductionDetails = async ({ startDate }) => {
  const axisoV2 = new Request(2);

  const response = await axisoV2.Get('/batches/production-details', {
    startDate,
  });

  if (response) {
    return response;
  } else {
    return [];
  }
};
