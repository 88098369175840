import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { formatNumber } from '../../../utils/number';

const BasicInfo = ({ lotNumber, totalWeight, liquorRatio, setLiquorRatio }) => {
  return (
    <Stack
      alignItems="flex-start"
      justifyContent="space-between"
      height="100%"
      border={1}
      borderRadius={2}
      borderColor="rgba(0,0,0,0.1)"
      px={1}
      py={1}
      spacing={2}>
      <Typography fontSize={22} fontWeight={700}>
        Lot #{lotNumber?.split('-')[1]}
      </Typography>
      <Stack direction="row" spacing={1}>
        <Stack
          spacing={1}
          px={1}
          py={1}
          borderRadius={2}
          border={2}
          borderColor="rgba(0,0,0,0.2)"
          minWidth={100}>
          <Typography fontSize="0.75rem" color="grey.600" fontWeight={500}>
            Weight (Kg)
          </Typography>
          <Typography fontWeight={700} fontSize={13}>
            {totalWeight}
          </Typography>
        </Stack>
        <Stack
          spacing={1}
          px={1}
          py={1}
          borderRadius={2}
          border={2}
          borderColor="rgba(0,0,0,0.2)"
          minWidth={100}>
          <Typography fontSize="0.75rem" color="grey.600" fontWeight={500}>
            Liquor (g/l)
          </Typography>
          <Typography fontWeight={700} fontSize={13}>
            {formatNumber(totalWeight * liquorRatio)}
          </Typography>
        </Stack>
      </Stack>
      <TextField
        defaultValue={liquorRatio}
        value={liquorRatio}
        onChange={(event) => setLiquorRatio(parseInt(event.target.value))}
        type="number"
        label="Liquor Ratio"
        size="small"
      />
    </Stack>
  );
};

export default BasicInfo;
