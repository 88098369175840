import {
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { approveOrder } from '../../api/approval';
import { successNoitif } from '../../utils/notification';
import Button from '../../widgets/Button';
import DoneOutlineTwoToneIcon from '@mui/icons-material/DoneOutlineTwoTone';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import { checkChemicalValidation, deleteChemical } from '../../api/inventory';

const ChemicalDeleteModal = ({ show, toggleModal, data, setIsDeleted }) => {
  const [checked, setChecked] = useState(false);
  const [valid, setValid] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onClose = () => {
    handleCreationFormClose();
  };
  const handleCreationFormClose = () => {
    setChecked(false);
    toggleModal(false);
  };
  const handleApproval = async () => {
    const response = await deleteChemical(data?.sku);

    if (response) {
      successNoitif('chemical deleted successfully');
      setIsDeleted(true);
      toggleModal(false);
    }
  };

  const checkChemicalValidity = async () => {
    const response = await checkChemicalValidation(data?.sku);

    if (response?.valid === true) {
      setValid(true);
    }
  };

  useEffect(() => {
    if (data) {
      (async () => {
        await checkChemicalValidity();
      })();
    }
  }, [data]);

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">
          Delete Chemical &apos;{data?.name}&apos;
        </DialogTitle>
        <Stack
          sx={{
            paddingLeft: 3,
            paddingRight: 2,
            paddingBottom: 1,
          }}>
          <Typography>
            Are you sure you want to delete this chemical?
          </Typography>
          <form>
            <Stack
              my={2}
              direction="row"
              alignItems="flex-start"
              justifyContent="center">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingRight: 0,
                      margin: '0px',
                      border: '0px',
                      borderRadius: '0px',
                    }}
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="hello"
                  />
                }
              />
              <Stack
                sx={{
                  paddingRight: 1,
                }}>
                <Typography>
                  By checking this box I here by declare that I have checked all
                  the information and confirm my action.I also confirm that my
                  information will be stored.
                </Typography>
              </Stack>
            </Stack>
            {valid ? (
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <IconButton
                  aria-label="edit"
                  sx={{
                    bgcolor: '#fff',
                    borderRadius: 1,
                    color: 'green',
                    // ':hover': { bgcolor: '#f7f7f7' },
                  }}>
                  <DoneOutlineTwoToneIcon />
                </IconButton>
                <Typography>
                  Chemical is not assigned with any batch tasks
                </Typography>
              </Stack>
            ) : (
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <IconButton
                  aria-label="edit"
                  sx={{
                    bgcolor: '#fff',
                    borderRadius: 1,
                    color: 'red',
                    // ':hover': { bgcolor: '#f7f7f7' },
                  }}>
                  <ErrorOutlineTwoToneIcon />
                </IconButton>
                <Typography>Chemical is assigned to batch tasks</Typography>
              </Stack>
            )}
          </form>
        </Stack>
        <Stack
          mr={3}
          direction="row"
          gap={1}
          justifyContent="flex-end"
          sx={{ paddingRight: 1, paddingBottom: 2 }}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCreationFormClose}>
            Cancel
          </Button>
          <Button
            color="success"
            variant="contained"
            disabled={!checked || !valid}
            onClick={handleApproval}>
            Confirm
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default ChemicalDeleteModal;
