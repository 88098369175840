import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeader from '../../widgets/PageHeader';
import QuotationApproval from '../../components/approval/QuotationApproval';
import BatchApproval from '../../components/approval/BatchApproval';
import OrderApproval from '../../components/approval/OrderApproval';
import { fetchAllQuotations } from '../../api/client';
import { fetchAllOrder, fetchDeliveryList } from '../../api/order';
import { fetchBatchList } from '../../api/batch';
import DeliveryApproval from '../../components/approval/DeliveryApproval';
import { APPROVAL } from '../../constants/routeUrls';
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '../../widgets/Tabs';
import Tab from '../../widgets/Tab';
import PageTitle from '../../utils/PageTitle';

const Approval = () => {
  const [quotationLength, setQuotationLength] = useState(0);
  const [orderLength, setOrderLength] = useState(0);
  const [batchLength, setBatchLength] = useState(0);
  const [deliveryLength, setDeliveryLength] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const fetchQuotation = async () => {
    const response = await fetchAllQuotations({});
    setQuotationLength(response.total);
  };

  const fetchOrders = async () => {
    const response = await fetchAllOrder({
      isApproved: false,
    });
    setOrderLength(response.total);
  };
  const fetchBatches = async () => {
    const response = await fetchBatchList({
      status: 'WAITING FOR APPROVAL',
    });
    setBatchLength(response.total);
  };

  const fetchDeliveries = async () => {
    const response = await fetchDeliveryList({
      status: 'WAITING FOR APPROVAL',
    });
    setDeliveryLength(response.total);
  };

  useEffect(() => {
    async function loader() {
      await Promise.all([
        fetchQuotation(),
        fetchOrders(),
        fetchBatches(),
        fetchDeliveries(),
      ]);
    }
    loader();
  }, [isUpdated]);

  //for tab section
  let navigate = useNavigate();
  const { tab: tabParams } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    setActiveTab(parseInt(tabParams));
  }, [tabParams]);

  const tabs = [
    {
      Element: (
        <QuotationApproval isUpdated={isUpdated} setIsUpdated={setIsUpdated} />
      ),
    },
    {
      Element: (
        <OrderApproval isUpdated={isUpdated} setIsUpdated={setIsUpdated} />
      ),
    },
    {
      Element: <BatchApproval />,
    },
    {
      Element: (
        <DeliveryApproval isUpdated={isUpdated} setIsUpdated={setIsUpdated} />
      ),
    },
  ];
  const currentTab = tabs[activeTab];

  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
    navigate(`${APPROVAL}/${tab}`);
  };

  return (
    <Stack
      spacing={2}
      width="100%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <PageTitle title="Approvals" />
      {/* header section  */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        spacing={2}>
        <PageHeader>Approval</PageHeader>
      </Stack>

      {/* tab section  */}
      <Stack mt={4}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ width: 'max-content' }}>
          <Tab label={`QUOTATIONS(${quotationLength})`} />
          <Tab label={`ORDERS(${orderLength})`} />
          <Tab label={`BATCHES(${batchLength})`} />
          <Tab label={`DELIVERIES(${deliveryLength})`} />
        </Tabs>
      </Stack>
      <Stack width="100%" height={450}>
        {currentTab.Element}
      </Stack>
    </Stack>
  );
};

export default Approval;
