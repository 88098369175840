import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  EditTwoTone as Edit,
  HourglassBottomTwoTone as Halfview,
} from '@mui/icons-material';

import QRCode from 'react-qr-code';

import CompletedTask from '../../components/user/CompletedTask';
import UserLogs from '../../components/user/UserLogs';
import { useParams } from 'react-router-dom';
import { fetchSpecificUser, updateShiftId } from '../../api/users';
import { formatTime } from '../../utils/time';
import { fetchAllShifts } from '../../api/shift';
import { successNoitif } from '../../utils/notification';
import UpdateUser from '../../components/user/UpdateUser';
import UserStats from '../../components/user/UserStats';
import { setUserRoleBackgroundColor } from '../../constants/table-column/user';
import DownloadIcon from '@mui/icons-material/Download';

import { useReactToPrint } from 'react-to-print';
import PermissionWrapper from '../wrapper/PermissionWrapper';
import PageTitle from '../../utils/PageTitle';

const UserDetails = () => {
  const [userInformation, setUserInformation] = useState({});
  const [updateUserInfo, setUpdateUserInfo] = useState({});
  const [shiftList, setShiftList] = useState([]);
  const { uid } = useParams();
  const [currencyTypeField, setCurrencyTypeField] = useState(0);
  const [isShiftUpdated, setIsShiftUpdated] = useState(false);
  const [showUpdateUserForm, setShowUpdateUserForm] = useState(false);
  const [activeShift, setActiveShift] = useState(null);

  const fetchUserInformation = async () => {
    const response = await fetchSpecificUser(uid);
    let temp = JSON.parse(JSON.stringify(response.user));
    setUpdateUserInfo(temp);

    let tempUser = response.user;
    const roleArray = response?.user?.roles.split(',');
    tempUser.roles = roleArray;
    setUserInformation(tempUser);
  };

  const handleUpdateFormClose = () => {
    setShowUpdateUserForm(false);
  };
  const fetchShiftList = async () => {
    const response = await fetchAllShifts();
    setActiveShift(null);
    setShiftList(response);
  };

  const generateShiftName = () => {
    let shiftName = 'Not Assigned';
    for (const property in shiftList) {
      if (userInformation.shiftId == shiftList[property].id) {
        shiftName = shiftList[property].name;
      }
    }
    return shiftName;
  };

  const roleTitleMapper = (role) => {
    const roles = {
      ADMIN: 'Admin',
      ACC: 'Accounts',
      SI: 'Store Supervisor',
      DPO: 'Dyeing Supervisor',
      FS: 'Finishing Supervisor',
      QCS: 'Quality Supervisor',
      BM: 'Batch Manager',
      SUPERVISOR: 'Supervisor',
      MANAGER: 'Manager',
      DI: 'Delivery Incharge',
    };

    return roles[role];
  };
  const handelShiftChange = async (newValue) => {
    let shiftId = null;
    if (newValue == -1) {
      shiftId = null;
    } else {
      shiftId = newValue.id;
    }
    const shiftData = {
      uid: uid,
      shiftId: shiftId,
    };
    const response = await updateShiftId(shiftData);
    if (response) {
      successNoitif('User Shift has been Updated');
      setIsShiftUpdated(!isShiftUpdated);
      setCurrencyTypeField(0);
    }
  };
  useEffect(() => {
    (async () => {
      await fetchUserInformation();
      await fetchShiftList();
    })();
  }, [isShiftUpdated, showUpdateUserForm]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <PageTitle title="User Details" />
      <Box width="98%" mx="auto" height="auto" minHeight="calc(100vh - 130px)">
        <Grid mt={2} container spacing={2}>
          <Grid item xs={4}>
            {userInformation.username ? (
              <>
                <Stack
                  sx={{
                    height: '23vh',
                    minHeight: '200px',
                    maxHeight: '200px',
                    width: '100%',
                    mx: 'auto',
                    border: '2px solid rgba(0,0,0,0.2)',
                    p: 2,
                    borderRadius: 1.5,
                  }}>
                  <Stack
                    direction="column"
                    width="100%"
                    height="100%"
                    justifyContent="space-between">
                    {/* upper info  */}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="flex-start">
                        <Stack
                          sx={{
                            position: 'relative',
                          }}>
                          {userInformation?.username ? (
                            <QRCode
                              size={80}
                              value={userInformation?.username}
                            />
                          ) : (
                            <CircularProgress />
                          )}
                          <Stack display="none" width="100%" heigh="100%">
                            <Stack
                              ref={componentRef}
                              spacing={1}
                              width="100%"
                              heigh="100%"
                              direction="column"
                              alignItems="center"
                              justifyContent="center">
                              <QRCode
                                size={250}
                                value={userInformation?.username}
                              />
                              <Typography>{userInformation?.name}</Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            position="absolute"
                            width={80}
                            height={80}
                            bgcolor="rgba(0,0,0,0.5)"
                          />
                          <Stack
                            sx={{
                              position: 'absolute',
                              background: '#4562f7',
                              top: 0,
                              left: 0,

                              '&:hover': {
                                boxShadow: 3,
                                transition: '300ms ease',
                              },
                            }}>
                            <IconButton
                              sx={{
                                px: 1,
                                py: 1,
                                color: 'white',
                                opacity: 1,
                                ':hover': {
                                  boxShadow: 2,
                                },
                              }}
                              onClick={handlePrint}>
                              <DownloadIcon />
                            </IconButton>
                          </Stack>
                        </Stack>
                        <Stack direction="column" width="100%">
                          <Typography fontSize={18} fontWeight={800}>
                            {userInformation?.name}
                          </Typography>
                          <Stack direction="row" flexWrap="wrap">
                            {userInformation?.roles?.map((role, index) => (
                              <Stack key={index} mr={1} mb={0.5}>
                                <Chip
                                  size="small"
                                  label={roleTitleMapper(role)}
                                  sx={{
                                    backgroundColor:
                                      setUserRoleBackgroundColor(role),
                                    color: '#ffff',
                                  }}
                                />
                              </Stack>
                            ))}
                          </Stack>

                          <Typography fontSize={12} fontWeight={400} mt={0.5}>
                            Last Log In:{' '}
                            {formatTime(userInformation?.lastLogin)}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Chip
                          size="small"
                          label={generateShiftName()}
                          color="info"
                        />
                      </Stack>
                    </Stack>

                    {/* lower info  */}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={2}>
                      <PermissionWrapper acceptedRoles="ADMIN">
                        <Stack width="50%">
                          {shiftList.length > 0 ? (
                            <Stack>
                              <FormControl>
                                <Select
                                  value={currencyTypeField}
                                  size="small"
                                  defaultValue={0}
                                  onChange={(event) => {
                                    setCurrencyTypeField(event.target.value);
                                    handelShiftChange(event.target.value);
                                  }}
                                  labelId="CurrencyType"
                                  id="demo-simple-select">
                                  <MenuItem disabled value="0" open="false">
                                    Change Shift
                                  </MenuItem>
                                  {userInformation?.shiftId != null ? (
                                    <MenuItem value="-1" open="false">
                                      Not Assigned
                                    </MenuItem>
                                  ) : (
                                    <></>
                                  )}

                                  {shiftList?.map((shift) =>
                                    userInformation.shiftId != shift.id ? (
                                      <MenuItem key={shift.id} value={shift}>
                                        {shift.name}
                                      </MenuItem>
                                    ) : (
                                      <></>
                                    ),
                                  )}
                                </Select>
                              </FormControl>
                            </Stack>
                          ) : (
                            <></>
                          )}
                        </Stack>
                      </PermissionWrapper>
                      <PermissionWrapper acceptedRoles="ADMIN">
                        <Stack direction="row" spacing={0.5}>
                          <IconButton
                            onClick={() => {
                              setShowUpdateUserForm(true);
                            }}
                            size="small">
                            <Edit />
                          </IconButton>
                        </Stack>
                      </PermissionWrapper>
                    </Stack>
                  </Stack>
                </Stack>
              </>
            ) : (
              <>
                <Stack
                  justifyContent="center"
                  width="100%"
                  height="100%"
                  alignItems="center">
                  <CircularProgress />
                </Stack>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <Stack
              sx={{
                height: '100%',
                width: '100%',
                mx: 'auto',

                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              <UserStats uid={uid}></UserStats>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack
              sx={{
                height: '100%',
                width: '100%',
                mx: 'auto',

                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
                overflow: scroll,
              }}>
              <Stack
                justifyContent="center"
                width="100%"
                height="100%"
                alignItems="center">
                <Stack
                  direction="Column"
                  justifyContent="center"
                  alignItems="center">
                  <Halfview sx={{ width: 120, height: 120 }} />
                  <Typography fontSize={18} fontWeight={800}>
                    Coming Soon
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Grid mt={1} container spacing={2}>
          <Grid item xs={4}>
            <Stack
              sx={{
                height: 'calc(100vh - 38vh)',
                width: '100%',
                mx: 'auto',
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                User Logs
              </Typography>
              <UserLogs uid={uid}></UserLogs>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack
              sx={{
                height: 'calc(100vh - 38vh)',
                width: '100%',
                mx: 'auto',
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Completed Task
              </Typography>
              <CompletedTask uid={uid}></CompletedTask>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack
              sx={{
                height: 'calc(100vh - 38vh)',
                width: '100%',
                mx: 'auto',
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              <Stack
                justifyContent="center"
                pt={10}
                width="100%"
                height="100%"
                alignItems="center">
                <Stack
                  direction="Column"
                  justifyContent="center"
                  alignItems="center">
                  <Halfview sx={{ width: 120, height: 120 }} />
                  <Typography fontSize={18} fontWeight={800}>
                    Coming Soon
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {updateUserInfo.roles && shiftList.length > 0 ? (
        <UpdateUser
          selectedUser={updateUserInfo}
          shifts={shiftList}
          show={showUpdateUserForm}
          handleClose={handleUpdateFormClose}
          activeShift={activeShift}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default UserDetails;
