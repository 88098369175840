import { Popover, Stack, Typography } from '@mui/material';
import React from 'react';
import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  useProgram,
} from 'planby';
import dayjs from 'dayjs';
import { milisecondsToTime } from '../../../utils/time';

export const ProgramItem = ({ program, ...rest }) => {
  const { styles, formatTime, set12HoursTimeFormat, isLive, isMinWidth } =
    useProgram({
      program,
      ...rest,
    });

  const { data } = program;

  const { image, title, since, till } = data;

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

  const setBackGround = (title) => {
    if (title == 'IN USE') {
      return '#4caf50';
    } else if (title == 'MAINTENENCE') {
      return '#ffc107';
    } else {
      return '#f44336';
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const calcDuration = () => {
    const duration = new Date(till) - new Date(since);
    return milisecondsToTime(duration);
  };

  return (
    <>
      <ProgramBox
        width={styles.width}
        style={styles.position}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        <ProgramContent
          style={{ background: setBackGround(title) }}
          width={styles.width}
          isLive={isLive}>
          <ProgramFlex>
            {isLive && isMinWidth && image && (
              <ProgramImage src={image} alt="Preview" />
            )}
            <ProgramStack>
              <ProgramTitle style={{ color: 'white' }}>{title}</ProgramTitle>
              <ProgramText style={{ color: 'black' }}>
                {sinceTime} - {tillTime}
              </ProgramText>
            </ProgramStack>
          </ProgramFlex>
        </ProgramContent>
      </ProgramBox>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Stack spacing={2} color={'#303330'} px={2} py={2}>
          {title == 'IN USE' ? (
            <>
              <Stack>
                <Typography fontSize={18} fontWeight={700}>
                  {data.batchNo}
                </Typography>
                <Typography fontSize={12} fontWeight={600}>
                  Start: {dayjs(since).format('hh:mm A DD, MMM')} || End:{' '}
                  {dayjs(till).format('hh:mm A DD, MMM')}
                </Typography>
              </Stack>
              <Stack spacing={0.4}>
                <Typography fontSize={12} fontWeight={600}>
                  Duration: {calcDuration()}
                </Typography>
              </Stack>
            </>
          ) : (
            <>
              <Stack>
                <Typography fontSize={18} fontWeight={700}>
                  IDLE
                </Typography>
                <Typography fontSize={12} fontWeight={600}>
                  Start: {dayjs(since).format('hh:mm A DD, MMM')} || End:{' '}
                  {dayjs(till).format('hh:mm A DD, MMM')}
                </Typography>
              </Stack>
              <Stack spacing={0.4}>
                <Typography fontSize={12} fontWeight={600}>
                  Duration: {calcDuration()}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Popover>
    </>
  );
};

export default ProgramItem;
