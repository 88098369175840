import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#171820',
    },
    secondary: {
      main: '#645CAA',
    },
    success: {
      main: '#7FB77E',
      contrastText: '#fff',
    },
    warning: {
      main: '#F1A661',
      contrastText: '#303330',
    },
    error: {
      main: '#FF5858',
      contrastText: '#fff',
    },
    info: {
      main: '#4562f7',
      contrastText: '#fff',
    },
    text: {
      primary: '#303030',
    },
  },
  typography: {
    // eslint-disable-next-line quotes
    fontFamily: "'Poppins', sans-serif",
  },

  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontSize: 12,
          fontWeight: 700,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
  },
});

export default theme;
