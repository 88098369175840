import * as yup from 'yup';

export const ShiftGenerateSchema = yup.object().shape({
  shiftCount: yup
    .number()
    .typeError('Must be a number')
    .positive('Must be positive number')
    .min(1, 'Shift count can not be zero')
    .required('Must provide number of shifts'),
  hoursPerShift: yup
    .number()
    .typeError('Must be a number')
    .positive('Must be positive number')
    .min(1, 'Hour per shift can not be zero')
    .max(24, 'Hour per shift can not exceed 24 hour')
    .required('Must provide hours per shift'),
  startTime: yup
    .mixed('Must provide start time of your first shift')
    .required('Must provide start time of your first shift'),
});
