import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogTitle, TextField, Stack } from '@mui/material';

import { useForm } from 'react-hook-form';
import { createOrderShadeColor } from '../../api/order';
import { successNoitif } from '../../utils/notification';
import { OrderColorCreationValidator } from '../../validators/order';
import Button from '../../widgets/Button';

const CreateShadeColor = ({
  show,
  toggleModal,
  shadeIndex,
  shadeArray,
  setShadeArray,
  selectedClient,
}) => {
  const resolver = yupResolver(OrderColorCreationValidator);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      shadeColor: '',
    },
    resolver,
  });

  const handleCreationFormClose = () => {
    reset({
      shadeColor: '',
    });
    toggleModal(false);
  };
  const onClose = () => {
    handleCreationFormClose();
  };

  const handelShadeColorFormSubmission = (data) => {
    if (shadeArray[shadeIndex].id) {
      setColor(
        selectedClient.uid,
        shadeArray[shadeIndex]?.id,
        data.shadeColor,
        data,
      );
    } else {
      setColor(
        selectedClient.uid,
        shadeArray[shadeIndex]?.shadeGroupId,
        data.shadeColor,
        data,
      );
    }
  };

  const setColor = async (uid, shadeId, colorName, data) => {
    const response = await createOrderShadeColor({
      uid,
      shadeId,
      colorName,
    });
    if (response) {
      const modifiedData = {
        colourId: response.addedData.colourId,
        colourName: data.shadeColor,
      };

      let tempArray = shadeArray;

      tempArray[shadeIndex].colours = [
        ...tempArray[shadeIndex].colours,
        modifiedData,
      ];

      setShadeArray(tempArray);
      successNoitif('Colour has been Added');
      reset(onClose());
      toggleModal(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">
          Add New Shade Color for {shadeArray[shadeIndex]?.shadeName}
        </DialogTitle>
        <form>
          <Stack
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 3,
              paddingBottom: 3,
            }}>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Shade Color"
                required
                placeholder="Shade Color"
                {...register('shadeColor')}
                error={errors?.shadeColor}
                helperText={errors?.shadeColor?.message}
              />
            </Stack>
          </Stack>
        </form>
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button
            variant="outlined"
            sx={{ borderColor: 'red' }}
            color="error"
            onClick={handleCreationFormClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(handelShadeColorFormSubmission)}>
            Add
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default CreateShadeColor;
