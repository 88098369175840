import React, { useState } from 'react';
import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import {
  DoneAllTwoTone as DoneAll,
  RotateRightTwoTone as RotateRight,
  HourglassEmptyTwoTone as HourglassEmpty,
  AlarmTwoTone as Alarm,
  SportsScoreTwoTone as SportsScore,
  WavesTwoTone as Waves,
  PauseTwoTone as Pause,
  PlagiarismTwoTone,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import Button from '../../../../widgets/Button';
import ConcentModal from '../../ConcentModal';
import { skipTask } from '../../../../api/batch';
import { useParams } from 'react-router-dom';
import { successNoitif } from '../../../../utils/notification';

const TaskTimeline = ({ tasks, isSelected, select, socket }) => {
  const [showTaskSkipConfirmModal, setShowTaskSkipConfirmModal] =
    useState(false);
  const [taskToBeSkipped, setTaskToBeSkipped] = useState(null);

  const { lotNumber } = useParams();

  const TaskIcon = ({ task }) => {
    switch (task.status) {
      case 'DONE':
        return <DoneAll sx={{ width: 18, height: 18, fill: '#fff' }} />;
      case 'RUNNING':
        return <RotateRight sx={{ width: 18, height: 18, fill: '#fff' }} />;
      default:
        return <HourglassEmpty sx={{ width: 18, height: 18, fill: '#fff' }} />;
    }
  };

  const iconBgColor = (task) => {
    const colors = {
      DONE: '#7FB77E',
      RUNNING: '#4562f7',
      DUE: '#F1A661',
    };

    return colors[task.status || 'DUE'];
  };

  const getStatusColor = (status) => {
    const colors = {
      DONE: 'success',
      RUNNING: 'info',
      DUE: 'warning',
    };

    return colors[status || 'DUE'];
  };

  const standardTimeFormat = (task) => {
    const hour = task.standardTimeHour ? task.standardTimeHour : 0;
    const minute = task.standardTimeMinute ? task.standardTimeMinute : 0;

    return (
      <Stack spacing={1}>
        <Chip
          avatar={<Alarm />}
          label={`${hour} hour ${minute} mins`}
          size="small"
          sx={{ width: 'max-content' }}
        />
      </Stack>
    );
  };

  const formatTimeForRunningTask = (task) => {
    const startedAt = `${dayjs(new Date(task.startTime)).format(
      'hh:mm A, DD MMM, YYYY',
    )}`;
    const shouldCompleteTime = new Date(task.startTime);

    const hour = task.standardTimeHour ? task.standardTimeHour : 0;
    const minute = task.standardTimeMinute ? task.standardTimeMinute : 0;

    shouldCompleteTime.setHours(shouldCompleteTime.getHours() + hour);
    shouldCompleteTime.setMinutes(shouldCompleteTime.getMinutes() + minute);

    const shouldCompleteAt = `${dayjs(shouldCompleteTime).format(
      'hh:mm A, DD MMM, YYYY',
    )}`;

    const currentTime = new Date();

    return (
      <Stack flexWrap="wrap" direction="row" mt={1}>
        <Tooltip title="Started At">
          <Chip
            avatar={<Alarm />}
            label={startedAt}
            size="small"
            sx={{ width: 'max-content', mr: 1, mb: 1 }}
          />
        </Tooltip>
        <Tooltip title="Should Complete At">
          <Chip
            avatar={
              <SportsScore
                sx={{
                  fill: currentTime > shouldCompleteTime ? '#fff' : '#171820',
                }}
              />
            }
            label={shouldCompleteAt}
            color={currentTime > shouldCompleteTime ? 'warning' : 'default'}
            size="small"
            sx={{ width: 'max-content', mr: 1, mb: 1 }}
          />
        </Tooltip>
      </Stack>
    );
  };

  const formatTimeForDoneTask = (task) => {
    return (
      <Stack flexWrap="wrap" direction="row" mt={1}>
        <Tooltip title="Started At">
          <Chip
            avatar={<Alarm />}
            label={dayjs(task.startTime).format('DD MMM, YYYY hh:mm A')}
            size="small"
            sx={{ width: 'max-content', mr: 1, mb: 1 }}
          />
        </Tooltip>
        <Tooltip title="Completed At">
          <Chip
            avatar={<SportsScore />}
            label={dayjs(task.completeTime).format('DD MMM, YYYY hh:mm A')}
            size="small"
            sx={{ width: 'max-content', mr: 1, mb: 1 }}
          />
        </Tooltip>
      </Stack>
    );
  };

  const getTime = (task) => {
    const times = {
      DONE: formatTimeForDoneTask(task),
      RUNNING: formatTimeForRunningTask(task),
      DUE: standardTimeFormat(task),
    };

    return times[task.status || 'DUE'];
  };

  const shouldShowSkipButton = (task) => {
    if (
      task.status === 'DUE' &&
      !task.isSkipped &&
      task.taskName !== 'Fabric Loading' &&
      task.taskName !== 'Heat Set'
    ) {
      return true;
    }
    return false;
  };

  const handleTaskSkip = async (reason) => {
    const response = await skipTask(lotNumber, taskToBeSkipped.uid, reason);

    if (response) {
      successNoitif(response);
      socket.emit('batch details', { lotNumber });

      setShowTaskSkipConfirmModal(false);
      setTaskToBeSkipped(null);
      return;
    }

    setShowTaskSkipConfirmModal(false);
    setTaskToBeSkipped(null);
  };

  const checkEarly = (task) => {
    const duration = new Date(task.completeTime) - new Date(task.startTime);
    if (duration > 300000) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Stack spacing={0.5} height="100%">
      <ConcentModal
        show={showTaskSkipConfirmModal}
        onClose={() => setShowTaskSkipConfirmModal(false)}
        title="Skip Task"
        text={`Are your sure you want to skip "${taskToBeSkipped?.taskName}"`}
        submissionHandler={handleTaskSkip}
        showReasonCaptureField={true}
      />
      {tasks?.length <= 0 ? (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          spacing={2}>
          <PlagiarismTwoTone sx={{ width: 100, height: 100 }} />
          <Typography variant="h5" fontWeight={700}>
            Nothing To Show
          </Typography>
          <Typography textAlign="center">
            Batch template has not been configured yet
          </Typography>
        </Stack>
      ) : (
        tasks.map((task, index) => (
          <Stack
            key={task.uid}
            bgcolor={isSelected(task) ? '#e7e7e7' : '#fff'}
            onClick={() => select(task)}
            px={1}
            py={1}
            borderRadius={1.5}
            sx={{ cursor: 'pointer' }}>
            <Stack direction="row" spacing={1.5}>
              <Stack alignItems="center" spacing={0.5}>
                <Stack
                  bgcolor={iconBgColor(task)}
                  px={0.5}
                  py={0.5}
                  width={30}
                  height={30}
                  borderRadius={200}
                  alignItems="center"
                  justifyContent="center">
                  <TaskIcon task={task} />
                </Stack>
                {index !== tasks?.length - 1 && (
                  <Box
                    width={4}
                    height={45}
                    bgcolor="#171820"
                    borderRadius={2000}
                  />
                )}
              </Stack>
              <Stack flexGrow={1}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography fontSize="16px" fontWeight={700}>
                    {task.taskName}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {task.status === 'DONE' && checkEarly(task) ? (
                      <Chip
                        color={getStatusColor('RUNNING')}
                        label={'Too Early'}
                        size="small"
                      />
                    ) : (
                      <></>
                    )}
                    {task.isDelayed && (
                      <Chip
                        color="error"
                        label="Delayed"
                        size="small"
                        avatar={<Waves sx={{ fill: '#fff' }} />}
                      />
                    )}
                    {task.isSkipped && (
                      <Chip color="error" label="Skipped" size="small" />
                    )}
                    <Chip
                      color={getStatusColor(task.status)}
                      label={task.status}
                      size="small"
                    />
                  </Stack>
                </Stack>
                {getTime(task)}
                {shouldShowSkipButton(task) && (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<Pause />}
                    sx={{
                      width: 'max-content',
                      mt: 1,
                      height: 25,
                      bgcolor: '#F96666',
                      ':hover': {
                        backgroundColor: '#F96666',
                        boxShadow: 'none',
                      },
                    }}
                    onClick={() => {
                      setTaskToBeSkipped(task);
                      setShowTaskSkipConfirmModal(true);
                    }}>
                    Skip
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        ))
      )}
    </Stack>
  );
};

export default TaskTimeline;
