import { useEffect } from 'react';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';

let socket;

export const useSocket = () => {
  const token = useSelector((state) => state.auth.accessToken);

  socket = io(process.env.REACT_APP_SOCKET_IP, {
    auth: { token },
    reconnectionAttempts: 3,
    reconnectionDelay: 1500,
    autoConnect: true,
    reconnection: true,
    rejectUnauthorized: true,
  });

  useEffect(() => {
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  return socket;
};
