import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { formatTime, milisecondsToTime } from '../../utils/time';
import BatchAccountOrderDetails from './BatchAccountOrderDetails';
import BatchTaskDetails from './BatchTaskDetails';
import '../../styles/batch-account-print.css';
import dayjs from 'dayjs';
import { formatNumber } from '../../utils/number';

const BatchAccountTranscript = ({ open, setOpen, batchDetails }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: `AREX-${batchDetails?.lotNumber}-QR-${formatTime(
      new Date(),
    )}`,
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (open) {
      handlePrint();
      setOpen(false);
    }
  }, [open]);
  const totalStandardTime = () => {
    const total = batchDetails?.taskInfo?.tasks?.reduce((prev, task) => {
      const hour = task.standardTimeHour ? task.standardTimeHour : 0;
      const minute = task.standardTimeMinute ? task.standardTimeMinute / 60 : 0;

      const durationInHour = hour + minute;

      const hourToMs = durationInHour * 60 * 60 * 1000;

      return prev + hourToMs;
    }, 0);

    return total;
  };
  return (
    <div style={{ display: 'none' }}>
      <Box
        mx="auto"
        width="1080px"
        my={2}
        mt={3}
        ref={componentRef}
        boxSizing="border-box"
        padding="15px 30px"
        class="page">
        {/* header section  */}
        <Grid my={2} container spacing={2} alignItems="center">
          <Grid item xs={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}>
              <Box
                component="img"
                sx={{
                  width: 150,
                }}
                alt="The house from the offer."
                src="/assets/images/logo.svg"
              />
              <Stack>
                <Typography fontSize={18}>2/1,Gadda,Savar,Dhaka</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack width="100%">
              <Typography fontSize={25} fontWeight={700}>
                Batch Receipt
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        {/* body section  */}
        <Stack mt={2} mb={1}>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}>
              <Typography>Lot Number:</Typography>
              <Typography
                style={{
                  borderBottom: '1px dashed #000',
                  textDecoration: 'none',
                }}>
                {batchDetails?.lotNumber}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}>
              <Typography>Delivery Date:</Typography>
              <Typography
                style={{
                  borderBottom: '1px dashed #000',
                  textDecoration: 'none',
                }}>
                {dayjs(batchDetails?.deliveryDate).format('DD/MMM/YYYY ')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack width="100%">
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}>
                <Typography>Weight:</Typography>
                <Typography
                  style={{
                    borderBottom: '1px dashed #000',
                    textDecoration: 'none',
                  }}>
                  {formatNumber(batchDetails.totalFabricWeight)} Kg
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}>
                <Typography>Machine:</Typography>
                <Typography
                  style={{
                    borderBottom: '1px dashed #000',
                    textDecoration: 'none',
                  }}>
                  {batchDetails?.machineInfo?.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}>
                <Typography>Status:</Typography>
                <Typography
                  style={{
                    borderBottom: '1px dashed #000',
                    textDecoration: 'none',
                  }}>
                  {batchDetails?.status}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}>
                <Typography>Runtime:</Typography>
                <Typography
                  style={{
                    borderBottom: '1px dashed #000',
                    textDecoration: 'none',
                  }}>
                  {milisecondsToTime(totalStandardTime())}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}>
                <Typography>Scheduled At:</Typography>
                <Typography
                  style={{
                    borderBottom: '1px dashed #000',
                    textDecoration: 'none',
                  }}>
                  {formatTime(batchDetails.loadingTime)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}>
                <Typography textAlign="end">Loaded At:</Typography>
                <Typography
                  textAlign="end"
                  style={{
                    borderBottom: '1px dashed #000',
                    textDecoration: 'none',
                  }}>
                  {formatTime(batchDetails.actualStartTime)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack className="batch-orders">
          <BatchAccountOrderDetails
            orderList={batchDetails?.orderInfo}
            fabricList={batchDetails?.fabricInfo}
          />
        </Stack>
        <Stack className="batch-tasks">
          <BatchTaskDetails
            taskList={batchDetails?.taskInfo}
            recipeList={batchDetails?.recipeInfo}
          />
        </Stack>
        {/* signature  */}
        <Grid className="singnature-class" container spacing={2} mt={8}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Divider width="80%"></Divider>
            <Stack width="90%" justifyContent="center" alignItems="center">
              <Stack my={0.5}>
                <Typography fontSize={15}>Authorized Signature</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BatchAccountTranscript;
