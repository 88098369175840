import colors from '../colors';

export const setUserStatsColor = (status) => {
  switch (status) {
    case 'DYEING':
      return { backgroundColor: colors.info };
    case 'FINISHING':
      return { backgroundColor: colors.success };
    case 'QC':
      return { backgroundColor: colors.warning };
    default:
      return { backgroundColor: colors.info };
  }
};

export const setUserLogsStatusColor = (status) => {
  const statusColor = {
    ERROR: colors.error,
    WARNING: colors.warning,
    INFO: colors.info,
    SUCCESS: colors.success,
  };
  return statusColor[status || 'INFO'];
};

export const setUserRoleBackgroundColor = (role) => {
  const roleColor = {
    ADMIN: colors.secondary,
    SUPER_ADMIN: colors.info,
    MANAGER: colors.success,
    ACC: colors.warning,
    SI: colors.success,
    DPO: colors.warning,
    FS: colors.info,
    QCS: colors.success,
    BM: colors.warning,
    SUPERVISOR: colors.info,
    DI: colors.warning,
  };

  return roleColor[role || 'ADMIN'];
};

export const setUserTaskTypeColor = (status) => {
  const statusColor = {
    DYEING: colors.info,
    FINISHING: colors.success,
    QC: colors.warning,
  };
  return statusColor[status || 'DYEING'];
};
