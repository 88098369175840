import Request from './request';

export const createNewMachine = async (data) => {
  const axiosV1 = new Request(1);
  const response = await axiosV1.Post('/machine', {
    name: data.name,
    capacity: data.capacity,
    maxCapacity: data.maxCapacity,
    minCapacity: data.minCapacity,
    machineType: data.machineType,
  });

  if (response) {
    return response.machine;
  }
  return null;
};

export const fetchAllMachine = async ({
  searchKey,
  machineType,
  status,
  page,
  pageSize = 10,
  pageless,
}) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/machine', {
    searchKey,
    machineType,
    status,
    page,
    pageSize,
    pageless,
  });
  if (response) {
    return { machines: response.machines, total: response.totalEntries };
  } else {
    return { machines: [], total: 0 };
  }
};

export const updateMachine = async (data) => {
  const axiosV1 = new Request(1);
  const response = await axiosV1.Patch('/machine', {
    name: data.name,
    capacity: data.capacity,
    maxCapacity: data.maxCapacity,
    minCapacity: data.minCapacity,
    machineType: data.machineType,
    status: data.status,
  });

  if (response) {
    return response.machine;
  }
  return null;
};

export const generateMachineQrCode = async (name) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(`/machine/${name}`);
  if (response) {
    return response.qrId;
  }
  return null;
};

export const getMachine = async (name) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/machine/${name}`);
  if (response) {
    return { machine: response.machine };
  } else {
    return { machine: {} };
  }
};

export const fetchSingleMachineLogs = async ({
  name,
  status,
  startDate,
  endDate,
}) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get(`/machine/logs/${name}`, {
    status,
    startDate,
    endDate,
  });

  if (response) {
    return { machineLogs: response.machineLogs };
  } else {
    return { machineLogs: [] };
  }
};

export const fetchMachineStats = async (name) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/machine/stats/${name}`);
  if (response) {
    return {
      stats: response.stats,
    };
  } else {
    return { stats: [] };
  }
};
