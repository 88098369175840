import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../widgets/Button';
import { useFieldArray, useWatch } from 'react-hook-form';
import TaskManagementForm from './TaskManagementForm';
import TemplatePreLoadManagement from './TemplatePreLoadManagement';
import { successNoitif, warningNoitif } from '../../../utils/notification';
import { calculateTotalDuration } from '../../../utils/batch';
import { milisecondsToTime } from '../../../utils/time';
import {
  InfoTwoTone as Info,
  WarningTwoTone as Warning,
} from '@mui/icons-material';
import { addBatchTaskInfo, deleteBatchTaskTemplate } from '../../../api/batch';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { BATCH } from '../../../constants/routeUrls';

const BatchTemplating = ({
  currentBatchState,
  isFormDirty,
  next,
  control,
  setFormValue,
  formErrors,
  register,
  submit,
  setIsUpdating,
  setCreatedBatch,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [saveTemplate, setSaveTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const {
    fields: tasks,
    append,
    remove,
  } = useFieldArray({ control, name: 'tasks' });

  const isWashRequired = useWatch({ control, name: 'isWashRequired' });
  const isHeatSetRequired = useWatch({ control, name: 'isHeatSetRequired' });
  const taskList = useWatch({ control, name: 'tasks' });

  const [templateInfo, setTemplateInfo] = useState({
    name: '',
    description: '',
  });

  const [showTemplateInfoErrorDialog, setShowTemplateInfoErrorDialog] =
    useState(false);
  const [showConfirmSaveDialog, setShowConfirmSaveDialog] = useState(false);
  const [showConfirmPublishDialog, setShowConfirmPublishDialog] =
    useState(false);

  const toggleTemplateSave = () => setSaveTemplate(!saveTemplate);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // * Force loading
  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 3000);
    () => clearTimeout(timeout);
  }, []);

  const validateReOrdering = (tasks, src, dest) => {
    const destTask = tasks[dest];
    const srcTask = tasks[src];

    let isValidPosition = true;

    if (
      srcTask.taskName === 'Fabric Loading' ||
      srcTask.taskName === 'Heat Set'
    ) {
      isValidPosition = false;
    }

    if (
      destTask.taskName === 'Fabric Loading' ||
      destTask.taskName === 'Heat Set'
    ) {
      isValidPosition = false;
    }

    if (srcTask.status === 'DONE' || srcTask.status === 'RUNNING') {
      isValidPosition = false;
    }

    if (destTask.status === 'DONE' || destTask.status === 'RUNNING') {
      isValidPosition = false;
    }
    if (
      !srcTask.taskName ||
      !destTask.taskName ||
      !srcTask.formFields ||
      !destTask.formFields
    ) {
      isValidPosition = false;
    }
    return isValidPosition;
  };

  const onDropEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const shouldReOrder = validateReOrdering(
      taskList || [],
      result.source.index,
      result.destination.index,
    );
    if (!shouldReOrder) {
      warningNoitif('You can not drop this task here');
      return;
    }
    const items = reorder(
      taskList,
      result.source.index,
      result.destination.index,
    );

    setFormValue('tasks', items, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const appendNewItemToTaskList = () => {
    append({
      taskType: 'DYEING',
      taskName: '',
      standardTimeMinute: 0,
      standardTimeHour: 0,
    });
  };

  const preLoadTemplate = (template) => {
    setSelectedTemplate(template);

    if (!template) {
      warningNoitif('Ops! something went wrong');
      return;
    }

    let tempTasksArray = [];

    template?.tasks?.map((item) => {
      // eslint-disable-next-line no-unused-vars
      const { uid, ...rest } = item;
      tempTasksArray.push({ ...rest });
    });

    setFormValue('tasks', tempTasksArray, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const clearTemplate = () => {
    setFormValue('tasks', [], { shouldDirty: true, shouldTouch: true });
    setSelectedTemplate(null);
  };

  const deleteTemplate = async () => {
    const response = await deleteBatchTaskTemplate(selectedTemplate?.uid);
    if (response) {
      setSelectedTemplate(null);
      successNoitif(response?.msg);
    }
  };

  const checkIfShouldSaveTemplate = (type) => {
    if (
      saveTemplate &&
      templateInfo.name.length <= 0 &&
      templateInfo.description.length <= 0
    ) {
      setShowTemplateInfoErrorDialog(true);
    } else {
      if (type === 'DRAFT') {
        setShowConfirmSaveDialog(true);
      } else {
        setShowConfirmPublishDialog(true);
      }
    }
  };

  const saveAsDraft = async (data) => {
    if (!isFormDirty) {
      navigate(BATCH);
      return;
    }
    const { lotNumber, tasks, isHeatSetRequired, isWashRequired } = data;

    const taskInfo = {
      tasks,
      isHeatSetRequired,
      isWashRequired,
    };
    const batch = await addBatchTaskInfo({
      lotNumber,
      taskInfo,
      saveType: 'DRAFT',
      shouldSave: saveTemplate,
      templateInfo,
    });

    if (batch) {
      setCreatedBatch(batch);
      setIsUpdating(true);
      successNoitif('Chnages saved successfully');
      navigate(BATCH);
    }
  };

  const saveAndPublish = async (data) => {
    if (
      !isFormDirty &&
      (currentBatchState?.status !== 'DRAFT') &
        (currentBatchState?.status !== 'WAITING FOR APPROVAL')
    ) {
      navigate(BATCH);
      return;
    }
    const { lotNumber, tasks, isHeatSetRequired, isWashRequired } = data;

    const taskInfo = {
      tasks,
      isHeatSetRequired,
      isWashRequired,
    };

    const batch = await addBatchTaskInfo({
      lotNumber,
      taskInfo,
      saveType: 'PUBLISH',
      shouldSave: saveTemplate,
      templateInfo,
    });

    if (batch) {
      setCreatedBatch(batch);
      setIsUpdating(true);
      successNoitif('Chnages saved successfully');
      navigate(BATCH);
    }
  };

  const batchinfo = useWatch({ control });

  // * calculate total fabric weight
  const calculateTotalFabricQuantity = () => {
    const totalFabricQuantity = batchinfo?.fabricInfo?.reduce((acc, fabric) => {
      return parseFloat(acc) + parseFloat(fabric.usedQty || 0);
    }, 0);
    return totalFabricQuantity;
  };

  // * calculate total fabric weight memo value
  const totalFabricWeight = useMemo(
    () => calculateTotalFabricQuantity(),
    [batchinfo.fabricInfo],
  );

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      spacing={1.5}
      py={2}>
      <Dialog
        open={showTemplateInfoErrorDialog}
        onClose={() => setShowTemplateInfoErrorDialog(false)}>
        <DialogContent>
          <Stack spacing={1.5} alignItems="center" justifyContent="center">
            <Warning color="warning" sx={{ width: 32, height: 32 }} />
            <Typography>
              You did not provide template name & description for saving
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showConfirmSaveDialog}
        onClose={() => setShowConfirmSaveDialog(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Stack spacing={1.5} alignItems="center" justifyContent="center">
            <Typography>
              Are you sure you want to save batch as draft?
            </Typography>
            <Stack
              direction="row"
              spacing={1.5}
              justifyContent="flex-end"
              width="100%">
              <Button
                variant="outlined"
                onClick={() => setShowConfirmSaveDialog(false)}>
                No
              </Button>
              <Button variant="contained" onClick={submit(saveAsDraft)}>
                Yes
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showConfirmPublishDialog}
        onClose={() => setShowConfirmPublishDialog(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Stack spacing={1.5} alignItems="center" justifyContent="center">
            <Typography>Are you sure you want to publish batch?</Typography>
            <Stack
              direction="row"
              spacing={1.5}
              justifyContent="flex-end"
              width="100%">
              <Button
                variant="outlined"
                onClick={() => setShowConfirmPublishDialog(false)}>
                No
              </Button>
              <Button variant="contained" onClick={submit(saveAndPublish)}>
                Yes
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack
        width="100%"
        bgcolor="#f7f7f7"
        borderRadius={1.5}
        px={2}
        py={2.5}
        spacing={1}
        boxShadow={2}>
        <Stack direction="row" flexWrap="wrap" alignItems="center">
          <Tooltip arrow title="Lot Number">
            <Chip
              sx={{ mr: 1, mb: 1 }}
              label={`Lot: ${batchinfo.lotNumber?.split('-')[1]}`}
            />
          </Tooltip>

          <Tooltip arrow title="Loading Time">
            <Chip
              sx={{ mr: 1, mb: 1 }}
              label={dayjs(batchinfo.loadingTime).format('DD MMM YYYY hh:mm A')}
            />
          </Tooltip>
          <Tooltip arrow title="Finishing Type">
            <Chip sx={{ mr: 1, mb: 1 }} label={batchinfo.finishingType} />
          </Tooltip>
          <Tooltip arrow title="Batch Type">
            <Chip sx={{ mr: 1, mb: 1 }} label={batchinfo.batchType} />
          </Tooltip>
          <Tooltip arrow title="Process Type">
            <Chip sx={{ mr: 1, mb: 1 }} label={batchinfo.processType} />
          </Tooltip>
          <Tooltip arrow title="Machine">
            <Chip sx={{ mr: 1, mb: 1 }} label={batchinfo.machine?.name} />
          </Tooltip>
          <Tooltip arrow title="Total Weight">
            <Chip
              sx={{ mr: 1, mb: 1 }}
              label={`Total Weight: ${totalFabricWeight} Kg`}
            />
          </Tooltip>
        </Stack>
        <Box height="auto" maxHeight={150} overflow="scroll">
          <Table size="small">
            <TableHead
              sx={{ position: 'sticky', top: '0', bgcolor: '#f7f7f7' }}>
              <TableRow>
                <TableCell>Fabric Type</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Shade (%)</TableCell>
                <TableCell>Weight (KG)</TableCell>
                <TableCell>Processed (KG)</TableCell>
                <TableCell>Rolls</TableCell>
                <TableCell>Assigned (KG)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {batchinfo.fabricInfo.map((fabric) => (
                <TableRow key={fabric.id}>
                  <TableCell>
                    <Tooltip arrow followCursor title={fabric.fabricType}>
                      <Typography fontSize="0.85rem">
                        {fabric.fabricType?.substring(0, 15)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip arrow followCursor title={fabric.colour}>
                      <Typography fontSize="0.85rem">
                        {fabric.colour?.substring(0, 15)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{fabric.shade}</TableCell>
                  <TableCell>{fabric.quantity}</TableCell>
                  <TableCell>{fabric.processed}</TableCell>
                  <TableCell>{fabric.rolls}</TableCell>
                  <TableCell>{fabric.usedQty}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Stack>

      <Stack
        width="100%"
        bgcolor="#f7f7f7"
        borderRadius={1.5}
        px={2}
        py={2.5}
        spacing={2.5}
        boxShadow={2}>
        <TemplatePreLoadManagement
          template={selectedTemplate}
          loadTemplate={preLoadTemplate}
          clearTemplate={clearTemplate}
          deleteTemplate={deleteTemplate}
        />
        {isLoading ? (
          <Stack
            width="100%"
            height="50vh"
            alignItems="center"
            justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <TaskManagementForm
              onDropEnd={onDropEnd}
              remove={remove}
              tasks={tasks}
              append={appendNewItemToTaskList}
              errors={formErrors}
              formControl={control}
              register={register}
              setFormValue={setFormValue}
            />
            {tasks?.length > 0 && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between">
                <Button variant="outlined" onClick={appendNewItemToTaskList}>
                  Add Another Task
                </Button>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Tooltip
                    arrow
                    title="Toggle this on if you require machine to be washed after batch is complete. Useful after colorful dyes.">
                    <Info />
                  </Tooltip>
                  <Typography>Requires machine wash</Typography>
                  <Switch
                    checked={isWashRequired}
                    value={isWashRequired}
                    onChange={(event) =>
                      setFormValue('isWashRequired', event.target.checked)
                    }
                  />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>Requires heat set</Typography>
                  <Switch
                    checked={isHeatSetRequired}
                    value={isHeatSetRequired}
                    onChange={(event) =>
                      setFormValue('isHeatSetRequired', event.target.checked)
                    }
                  />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>Save template</Typography>
                  <Switch
                    checked={saveTemplate}
                    onChange={toggleTemplateSave}
                  />
                </Stack>
              </Stack>
            )}
            {saveTemplate && (
              <Stack
                direction="column"
                alignItems="flex-end"
                spacing={2}
                width="100%">
                <Stack
                  direction="column"
                  alignItems="flex-end"
                  spacing={2}
                  width={{ md: '40%', xs: '100%' }}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Name"
                    placeholder="Please provide a name for your template"
                    value={templateInfo.name}
                    error={templateInfo?.name?.length <= 0}
                    helperText={
                      templateInfo?.name?.length <= 0 && 'Name is required'
                    }
                    onChange={(event) =>
                      setTemplateInfo({
                        ...templateInfo,
                        name: event.target.value,
                      })
                    }
                  />
                  <TextField
                    size="small"
                    fullWidth
                    label="Description"
                    placeholder="Any additional information describing this template"
                    multiline
                    value={templateInfo.description}
                    error={templateInfo?.description?.length <= 0}
                    helperText={
                      templateInfo?.description?.length <= 0 &&
                      'Description is required'
                    }
                    onChange={(event) =>
                      setTemplateInfo({
                        ...templateInfo,
                        description: event.target.value,
                      })
                    }
                  />
                </Stack>
              </Stack>
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start">
              <Typography>
                Total Duration -{' '}
                {milisecondsToTime(calculateTotalDuration(taskList))} (± 30 mins
                for fabric loading)
              </Typography>
              <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                <Button variant="contained" onClick={() => next(1)}>
                  Previous
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    submit(() => checkIfShouldSaveTemplate('DRAFT'))()
                  }>
                  Save & Continue
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    submit(() => checkIfShouldSaveTemplate('PUBLISH'))()
                  }>
                  Publish & Continue
                </Button>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default BatchTemplating;
