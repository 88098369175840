import {
  FilterAltTwoTone as FilterAlt,
  FullscreenTwoTone as Fullscreen,
} from '@mui/icons-material';
import { Badge, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Clock from '../Clock';
import StatCard from './StatCard';
import { fetchBatchLiveTrackingStats } from '../../../api/batch';
import FileExporterArrary from '../../global/FileExporterArrary';
import { tableDataReformat } from '../../../utils/table';

const LiveTrackingStats = ({
  toggleFullScreen,
  toggleFilterMenu,
  hasFilters,
  batches,
}) => {
  const [stats, setStats] = useState(null);

  const fetchStats = async () => {
    const response = await fetchBatchLiveTrackingStats();

    setStats(response);
  };

  useEffect(() => {
    (async () => await fetchStats())();
  }, []);

  const reformatBatches = () => {
    let tempData = JSON.parse(JSON.stringify(batches));
    let modifiedData = [];
    modifiedData = tableDataReformat(tempData);
    return modifiedData;
  };
  return (
    <Stack direction="row" spacing={2}>
      <Clock label={'Live Tracking'} />
      <StatCard
        label="In Queue"
        count={stats?.inQueueBatch || 0}
        borderColor="#FECD70"
      />
      <StatCard
        label="Running"
        count={stats?.runningBatch || 0}
        borderColor="#C6EBC5"
      />
      <StatCard
        label="Halted"
        count={stats?.haltedBatch || 0}
        borderColor="#FA7070"
      />
      <StatCard
        label="Paused"
        count={stats?.pausedBatch || 0}
        borderColor="#FFF38C"
      />
      <StatCard
        label="Completed"
        count={stats?.completedBatch || 0}
        borderColor="#5F9DF7"
      />
      <Stack px={0.5} spacing={2} bgcolor="#f2f2f2" borderRadius={2}>
        <IconButton title="Fullscreen mode" onClick={toggleFullScreen}>
          <Fullscreen />
        </IconButton>
        <IconButton title="Filter list" onClick={toggleFilterMenu}>
          {hasFilters ? (
            <Badge badgeContent="" color="info" variant="dot">
              <FilterAlt />
            </Badge>
          ) : (
            <FilterAlt />
          )}
        </IconButton>
      </Stack>
      <FileExporterArrary
        dataList={reformatBatches()}
        title={'Live-Tracking'}
        isUpdated={false}
        vertical={true}
        mapKey={'UID'}
      />
    </Stack>
  );
};

export default LiveTrackingStats;
