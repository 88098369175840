import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  CircularProgress,
  Autocomplete,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import PageHeader from '../../widgets/PageHeader';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '../../widgets/Button';
import {
  fetchAllProcess,
  fetchSpecificQuotation,
  updateQuotation,
} from '../../api/client';
import { Box } from '@mui/system';
import { QuotationCreationValidator } from '../../validators/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { successNoitif } from '../../utils/notification';
import PrintIcon from '@mui/icons-material/Print';
import { PRINT_QUOTATION } from '../../constants/routeUrls';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { AddCircleTwoTone } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import QuotationApprovalModal from '../../components/approval/QuotationApprovalModal';
import PermissionWrapper from '../wrapper/PermissionWrapper';
import CreateProcessForm from '../../components/client/CreateProcessForm';
import PageTitle from '../../utils/PageTitle';
const EditQuotation = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [currencyTypeField, setCurrencyTypeField] = useState('');
  const [searchCurrencyStatus, setSearchCurrencyStatus] = useState('');
  const [exchangeRateValue, SetExchangeRateValue] = useState(0);

  const [quotation, setQuotation] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [processDetails, setProcessDetails] = useState({});
  const [hasShedPricing, setHasShedPricing] = useState(true);
  const [fullFormData, setFullFormData] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [showAddProcessForm, setShowAddProcessForm] = useState(false);
  const [isProcessAdded, setIsProcessAdded] = useState(false);

  const handleEditModal = () => {
    const modalData = { uid: id };
    setRequestData(modalData);
    setShowQuotationModal(true);
  };

  const fetchProcess = async () => {
    const response = await fetchAllProcess();
    setProcessList(response.processes);
  };

  const changeExchangeRate = () => {
    if (searchCurrencyStatus == 'BDT') {
      SetExchangeRateValue(1);
    } else if (searchCurrencyStatus == 'USD') {
      SetExchangeRateValue(94);
    } else if (searchCurrencyStatus == 'GBP') {
      SetExchangeRateValue(108);
    } else if (searchCurrencyStatus == 'YEN') {
      SetExchangeRateValue(0.66);
    }
  };

  const resolver = yupResolver(QuotationCreationValidator);

  const {
    register,
    formState: { isDirty, errors },

    handleSubmit,
    setValue,

    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      issueDate: quotation.issueDate,
      validTillDate: quotation.expiryDate,
      remarks: quotation.remarks,
      CurrencyType: quotation.currency,
      shadePricing: [],
      heatSeatCharge: 0,
    },
    resolver,
  });

  // all useWatch
  const issueDate = useWatch({ control, name: 'issueDate' });
  const validTillDate = useWatch({ control, name: 'validTillDate' });
  const exchangeRate = useWatch({ control, name: 'exchangeRate' });
  useWatch({ control, name: 'shadePricing' });
  const heatSeatCharge = useWatch({ control, name: 'heatSeatCharge' });
  const siliconCharge = useWatch({ control, name: 'siliconCharge' });
  const stenterCharge = useWatch({ control, name: 'stenterCharge' });
  const compactingCharge = useWatch({ control, name: 'compactingCharge' });
  const remarks = useWatch({ control, name: 'remarks' });

  useWatch({ control, name: 'terms' });

  //fetching quotation information
  const fetchQuotation = async () => {
    const response = await fetchSpecificQuotation({ uid: id });

    setQuotation(response.quotations);
    setValue('issueDate', response.quotations.issueDate);
    setValue('validTillDate', response.quotations.expiryDate);
    setValue('exchangeRate', response.quotations.currencyExchangeRate);
    setSearchCurrencyStatus(response.quotations.currency);
    setCurrencyTypeField(response.quotations.currency);
    const renameingProcess = {
      id: response.quotations.process.id,
      processName: response.quotations.process.name,
      shortName: response.quotations.process.shortName,
    };
    setProcessDetails(renameingProcess);
  };

  //useArrayField
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shadePricing',
  });

  const {
    fields: termFileds,
    append: appendTerms,
    remove: removeTerms,
  } = useFieldArray({
    control,
    name: 'terms',
  });

  useEffect(() => {
    //fetching specific quotation
    (async () => {
      await fetchQuotation();
      await fetchProcess();
    })();
  }, []);
  useEffect(() => {
    (async () => {
      await fetchProcess();
    })();
  }, [isProcessAdded]);

  useEffect(() => {
    changeExchangeRate();
    setValue('exchangeRate', exchangeRateValue);
  }, [searchCurrencyStatus, exchangeRateValue]);

  useEffect(() => {
    setValue('shadePricing', quotation.clientShades);
    setValue('heatSeatCharge', quotation?.additionalStepCharges?.heatSetCharge);
    setValue('siliconCharge', quotation?.additionalStepCharges?.siliconCharge);
    setValue('stenterCharge', quotation?.additionalStepCharges?.stenterCharge);
    setValue(
      'compactingCharge',
      quotation?.additionalStepCharges?.compactingCharge,
    );
    setValue('terms', quotation.termsAndConditions);

    setValue('remarks', quotation.remarks);
  }, [quotation]);

  const handelFormSubmit = (data) => {
    if (data.shadePricing.length > 0) {
      setHasShedPricing(true);

      const modifiedDate = {
        quotationUid: id,
        issueDate: data.issueDate,
        expiryDate: data.validTillDate,
        currency: data.CurrencyType,
        remarks: data.remarks,
        processId: processDetails?.id,
        currencyExchangeRate: parseFloat(data.exchangeRate, 2),
        additionalStepCharges: {
          heatSetCharge: data.heatSeatCharge,
          siliconCharge: data.siliconCharge,
          stenterCharge: data.stenterCharge,
          compactingCharge: data.compactingCharge,
        },
        termsAndConditions: data.terms,
        clientShades: data.shadePricing,
      };

      setFullFormData(modifiedDate);
      setShowConfirmation(true);
    } else {
      setHasShedPricing(false);
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  //colse confrimation dialog
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    quotationFormSubmit();
  };

  //async calling api to post form data
  const quotationFormSubmit = async () => {
    const response = await updateQuotation(fullFormData);

    if (response) {
      successNoitif('Quotation has been Edited');
    }
  };

  const handelLoading = () => {
    setTimeout(() => {
      if (quotation.length == 0) {
        setNotFound(true);
      }
    }, 4000);
  };

  useEffect(() => {
    if (isUpdated) {
      navigate(-1);
    }
  }, [isUpdated]);

  return (
    <Stack
      spacing={2}
      width={{ md: '80%', sm: '100%' }}
      mx="auto"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <PageTitle title="Quotation Details" />
      <Stack mt={1} width="100%" direction="row" justifyContent="flex-start">
        <Button onClick={() => navigate(-1)} variant="contained">
          <ArrowBackIosIcon fontSize="15px" />
          <Typography fontSize="15px">Back</Typography>
        </Button>
      </Stack>
      <Divider></Divider>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader>Quotation #{id}</PageHeader>
        {quotation.quotationUid ? (
          <Stack direction="row" spacing={1}>
            <PermissionWrapper acceptedRoles="MANAGER,ADMIN">
              <Button
                disabled={quotation?.status == 'VALID' ? true : false}
                sx={{
                  backgroundColor: 'green',
                  ':hover': {
                    bgcolor: 'green',
                    color: '#fff',
                  },
                }}
                onClick={() => handleEditModal()}
                variant="contained">
                <Stack direction="row" spacing={0.5}>
                  <Typography>Approve</Typography>
                  <DoneIcon />
                </Stack>
              </Button>
            </PermissionWrapper>

            <Button
              variant="contained"
              component={Link}
              to={`${PRINT_QUOTATION}/${id}`}>
              <Stack direction="row" spacing={0.5}>
                <Typography>Preview</Typography>
                <PrintIcon />
              </Stack>
            </Button>
          </Stack>
        ) : (
          <></>
        )}
      </Stack>

      {quotation.quotationUid ? (
        <>
          <form>
            {/* header form  */}
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              spacing={{ md: 5, xs: 0 }}>
              <Stack
                sx={{
                  height: 'auto',
                  minHeight: 200,
                  width: { xs: '100%', md: '30%', sm: '60%' },
                }}>
                <Stack direction="column" spacing={2}>
                  {/* issue date  */}
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Issue Date"
                        value={issueDate}
                        onChange={(value) => setValue('issueDate', value)}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            type="date"
                            {...params}
                            style={{ width: '100%' }}
                            error={errors?.issueDate}
                            helperText={errors?.issueDate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>

                  {/* valid till date  */}
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Valid Till Date"
                        value={validTillDate}
                        onChange={(value) => setValue('validTillDate', value)}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            type="date"
                            {...params}
                            style={{ width: '100%' }}
                            error={errors?.validTillDate}
                            helperText={errors?.validTillDate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>

                  <Stack direction="column" spacing={2}>
                    {/* drop down currency  */}
                    <Stack>
                      <FormControl>
                        <InputLabel id="CurrencyType">
                          Select Currency
                        </InputLabel>
                        <Select
                          value={currencyTypeField}
                          size="small"
                          {...register('CurrencyType')}
                          onChange={(event) => {
                            setSearchCurrencyStatus(event.target.value);
                            setCurrencyTypeField(event.target.value);
                          }}
                          labelId="CurrencyType"
                          id="demo-simple-select"
                          label="Select Currency"
                          placeholder="Select Currency">
                          <MenuItem value="BDT">BDT</MenuItem>
                          <MenuItem value="USD">USD</MenuItem>
                          <MenuItem value="GBP">GBP</MenuItem>
                          <MenuItem value="YEN">YEN</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                    {/* Exchnage rate  */}
                    <Stack>
                      <TextField
                        label="Exchnage rate"
                        value={exchangeRate}
                        onChange={(event) => {
                          setValue('exchangeRate', event.target.value);
                        }}
                        size="small"
                        placeholder="Exchnage rate"
                        {...register('exchangeRate')}
                        error={errors?.exchangeRate}
                        helperText={errors?.exchangeRate?.message}
                      />
                    </Stack>
                    {/* Select Proccess */}
                    {processDetails?.processName ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        width="100%">
                        <Stack sx={{ width: '90%' }}>
                          <Autocomplete
                            disableClearable
                            disablePortal
                            id="combo-box-demo"
                            options={processList}
                            defaultValue={{
                              processName: processDetails?.processName,
                              shortName: processDetails?.shortName,
                            }}
                            getOptionLabel={(option) => option.processName}
                            size="small"
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}>
                                {option.processName} ({option.shortName})
                              </Box>
                            )}
                            onChange={(event, newValue) => {
                              setProcessDetails(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Process"
                                {...register('processList')}
                              />
                            )}
                          />
                        </Stack>
                        <Stack>
                          <IconButton
                            size="small"
                            title="Add Process"
                            aria-label="Add Process"
                            onClick={() => {
                              setShowAddProcessForm(true);
                            }}
                            sx={{
                              bgcolor: '#fff',
                              color: '#f44336',
                              borderRadius: 1,
                              ':hover': { bgcolor: '#f44336', color: '#fff' },
                            }}>
                            <AddCircleTwoTone />
                          </IconButton>
                        </Stack>
                      </Stack>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  height: 'auto',
                  minHeight: 200,
                  width: { xs: '100%', md: '30%', sm: '60%' },
                }}>
                {/* remarks  */}
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  pb={3}>
                  <TextField
                    label="Remarks"
                    style={{ width: '100%' }}
                    multiline
                    rows={5}
                    placeholder="Remarks"
                    value={remarks || ''}
                    onChange={(value) => setValue('remarks', value)}
                    {...register('remarks')}
                    error={errors?.remarks}
                    helperText={errors?.remarks?.message}
                  />
                </Stack>
              </Stack>
            </Stack>
            {/* shade pricing  */}
            <Stack my={4}>
              <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1}>
                Shade Pricing
              </Typography>
              {/* check at least one shed pricing  */}
              <Divider></Divider>
              <Typography
                mt={0.5}
                size="small"
                color={hasShedPricing ? 'black' : 'red'}>
                Please Add at least one shade pricing
              </Typography>
              <Stack mx={0}>
                {/* Additional Step charges  */}
                <TableContainer
                  mx={0}
                  sx={{
                    'td, th': {
                      border: 0,
                      padding: 1,
                      margin: 1,
                    },
                  }}>
                  <Table
                    sx={{ minWidth: 650, 'td, th': { border: 0 }, border: 0 }}
                    aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" sx={{ fontSize: '12px' }}>
                          #
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                          SHADE NAME
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                          SHADE %
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                          OPEN
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                          TUBE
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                          LYCRA OPEN
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                          DOUBLE PART OPEN
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                          DOUBLE PART TUBE
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: '12px' }}>
                          REMARKS
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontSize: '12px' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        tr: { borderTop: 0.5, borderBottom: 0.5 },
                        td: { borderTop: 0.5, borderBottom: 0.5 },
                      }}>
                      {fields.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell
                            width="40px"
                            align="left"
                            component="th"
                            scope="row">
                            <Typography>{index + 1}</Typography>
                          </TableCell>
                          <TableCell align="left" width="130px">
                            <TextField
                              size="small"
                              placeholder="Shade Name"
                              {...register(`shadePricing.${index}.shadeName`)}
                              error={errors?.shadePricing?.[index]?.shadeName}
                              helperText={
                                errors?.shadePricing?.[index]?.shadeName
                                  ?.message
                              }
                            />
                          </TableCell>
                          <TableCell align="center" width="130px">
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <TextField
                                size="small"
                                placeholder="Shade %"
                                {...register(
                                  `shadePricing.${index}.shadeRange`,
                                )}
                                error={
                                  errors?.shadePricing?.[index]?.shadeRange
                                }
                                helperText={
                                  errors?.shadePricing?.[index]?.shadeRange
                                    ?.message
                                }
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="center" width="130px">
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <TextField
                                size="small"
                                placeholder="Open"
                                {...register(`shadePricing.${index}.open`)}
                                error={errors?.shadePricing?.[index]?.open}
                                helperText={
                                  errors?.shadePricing?.[index]?.open?.message
                                }
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="center" width="130px">
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <TextField
                                size="small"
                                placeholder="Tube"
                                {...register(`shadePricing.${index}.tube`)}
                                error={errors?.shadePricing?.[index]?.tube}
                                helperText={
                                  errors?.shadePricing?.[index]?.tube?.message
                                }
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="center" width="130px">
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <TextField
                                size="small"
                                placeholder="Lycra Open"
                                {...register(`shadePricing.${index}.lycraOpen`)}
                                error={errors?.shadePricing?.[index]?.lycraOpen}
                                helperText={
                                  errors?.shadePricing?.[index]?.lycraOpen
                                    ?.message
                                }
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="center" width="150px">
                            {' '}
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <TextField
                                size="small"
                                placeholder="Double Part Open"
                                {...register(
                                  `shadePricing.${index}.doublePartOpen`,
                                )}
                                error={
                                  errors?.shadePricing?.[index]?.doublePartOpen
                                }
                                helperText={
                                  errors?.shadePricing?.[index]?.doublePartOpen
                                    ?.message
                                }
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="center" width="150px">
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <TextField
                                size="small"
                                placeholder="Double Part Tube"
                                {...register(
                                  `shadePricing.${index}.doublePartTube`,
                                )}
                                error={
                                  errors?.shadePricing?.[index]?.doublePartTube
                                }
                                helperText={
                                  errors?.shadePricing?.[index]?.doublePartTube
                                    ?.message
                                }
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="center" width="130px">
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <TextField
                                size="small"
                                placeholder="Remarks"
                                {...register(`shadePricing.${index}.remarks`)}
                                error={errors?.shadePricing?.[index]?.remarks}
                                helperText={
                                  errors?.shadePricing?.[index]?.remarks
                                    ?.message
                                }
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="center" width="130px">
                            <Stack
                              justifyContent="center"
                              alignItems="flex-end">
                              <IconButton
                                size="small"
                                title="Delete"
                                aria-label="Delete"
                                onClick={() => remove(index)}
                                sx={{
                                  bgcolor: '#fff',
                                  color: '#f44336',
                                  borderRadius: 1,
                                  ':hover': {
                                    bgcolor: '#f44336',
                                    color: '#fff',
                                  },
                                }}>
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* add button  */}
                <Stack
                  width="100%"
                  justifyContent="center"
                  alignItems="flex-start"
                  mt={2}>
                  <Button
                    type="button"
                    variant="contained"
                    disabled={
                      quotation?.status == 'VALID' ||
                      quotation?.status == 'EXPIRED'
                        ? true
                        : false
                    }
                    onClick={() => append()}>
                    Add
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              <Typography
                fontSize={25}
                sx={{ fontWeight: '500' }}
                my={1}
                mt={3}>
                Additional Step Charges
              </Typography>
              <Divider></Divider>
              <Stack
                mt={2}
                sx={{
                  height: 'auto',
                  minHeight: 200,
                  width: { xs: '100%', md: '30%', sm: '60%' },
                }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    {/* heat seat charge */}
                    <Stack>
                      <Typography>Heat Seat Charge: </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      size="small"
                      placeholder="Heat Seat Charge"
                      value={heatSeatCharge || ''}
                      onChange={(value) => setValue('heatSeatCharge', value)}
                      {...register('heatSeatCharge')}
                      error={errors?.heatSeatCharge}
                      helperText={errors?.heatSeatCharge?.message}
                    />
                  </Grid>

                  {/* silicon charge  */}
                  <Grid item xs={6} md={6}>
                    <Stack>
                      <Typography textAlign="left">Silicon Charge: </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      size="small"
                      value={siliconCharge || ''}
                      onChange={(value) => setValue('siliconCharge', value)}
                      {...register('siliconCharge')}
                      error={errors?.siliconCharge}
                      helperText={errors?.siliconCharge?.message}
                    />
                  </Grid>

                  {/* stenter charge  */}
                  <Grid item xs={6} md={6}>
                    <Stack>
                      <Typography>Stenter Charge: </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      size="small"
                      value={stenterCharge || ''}
                      onChange={(value) => setValue('stenterCharge', value)}
                      {...register('stenterCharge')}
                      error={errors?.stenterCharge}
                      helperText={errors?.stenterCharge?.message}
                    />
                  </Grid>

                  {/* comacting charge  */}
                  <Grid item xs={6} md={6}>
                    <Stack>
                      <Typography>Compacting Charge: </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      size="small"
                      value={compactingCharge || ''}
                      onChange={(value) => setValue('compactingCharge', value)}
                      {...register('compactingCharge')}
                      error={errors?.compactingCharge}
                      helperText={errors?.compactingCharge?.message}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
            {/* terms and condition  */}
            <Stack mt={4}>
              <Typography
                fontSize={25}
                sx={{ fontWeight: '500' }}
                my={1}
                mt={3}>
                Terms and Conditions
              </Typography>
              <Divider></Divider>
              {termFileds.length === 0 ? (
                <Typography mt={0.5} size="small">
                  No terms and conditions added
                </Typography>
              ) : (
                <></>
              )}
              <Stack>
                <ol>
                  {termFileds.map((item, index) => (
                    <li key={item.id}>
                      <Stack direction="row" justifyContent="space-between">
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          my={1}
                          sx={{ width: '400px' }}>
                          <TextField
                            size="small"
                            sx={{ width: '100%' }}
                            placeholder="Terms and Condition"
                            {...register(`terms.${index}.term`)}
                            error={errors?.terms?.[index]?.term}
                            helperText={errors?.terms?.[index]?.term?.message}
                          />
                        </Stack>

                        <Stack justifyContent="center">
                          <IconButton
                            size="small"
                            title="Delete"
                            aria-label="Delete"
                            onClick={() => {
                              removeTerms(index);
                            }}
                            sx={{
                              bgcolor: '#fff',
                              color: '#f44336',
                              borderRadius: 1,
                              ':hover': { bgcolor: '#f44336', color: '#fff' },
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </li>
                  ))}
                </ol>
                <Stack
                  width="100%"
                  justifyContent="center"
                  alignItems="flex-start"
                  mt={2}>
                  <Button
                    type="button"
                    variant="contained"
                    disabled={
                      quotation?.status == 'VALID' ||
                      quotation?.status == 'EXPIRED'
                        ? true
                        : false
                    }
                    onClick={() => {
                      appendTerms();
                    }}>
                    Add
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
          <Stack
            width="100%"
            justifyContent="center"
            alignItems="flex-end"
            mt={2}>
            <Button
              variant="contained"
              disabled={
                !isDirty ||
                quotation?.status == 'VALID' ||
                quotation?.status == 'EXPIRED'
                  ? true
                  : false
              }
              onClick={handleSubmit(handelFormSubmit)}>
              Update
            </Button>
          </Stack>
        </>
      ) : (
        <Stack
          justifyContent="center"
          pt={10}
          width="100%"
          height="100%"
          alignItems="center">
          {notFound ? (
            <Stack
              direction="Column"
              justifyContent="center"
              alignItems="center">
              <CloudOffIcon sx={{ fontSize: 100 }} />
              <Typography> Data not found</Typography>
            </Stack>
          ) : (
            <Stack>
              {handelLoading()}
              <CircularProgress />
            </Stack>
          )}
        </Stack>
      )}

      {/* confirmation dialoge  */}
      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to Edit this Quotation?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => handelConfrimationDecision()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
      <QuotationApprovalModal
        show={showQuotationModal}
        toggleModal={setShowQuotationModal}
        data={requestData}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}></QuotationApprovalModal>
      <CreateProcessForm
        toggleModal={setShowAddProcessForm}
        show={showAddProcessForm}
        isProcessAdded={isProcessAdded}
        setIsProcessAdded={setIsProcessAdded}></CreateProcessForm>
    </Stack>
  );
};

export default EditQuotation;
