import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Chip, IconButton, Stack, TextField, Typography } from '@mui/material';
import {
  Epg,
  Layout,
  useEpg,
  useProgram,
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ChannelBox,
  TimelineWrapper,
  TimelineBox,
  TimelineTime,
  TimelineDivider,
  TimelineDividers,
  useTimeline,
} from 'planby';
import epgTimelineTheme from '../../../widgets/epg-timeline-theme';
import dayjs from 'dayjs';
import { getBatchStatus } from '../../../utils/batch';
import { milisecondsToTime } from '../../../utils/time';
import { FullscreenTwoTone as Fullscreen } from '@mui/icons-material';
import Popover from '@mui/material/Popover';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

export function Timeline({
  isBaseTimeFormat,
  isSidebar,
  dayWidth,
  hourWidth,
  numberOfHoursInDay,
  offsetStartHoursRange,
  sidebarWidth,
}) {
  const { time, dividers, formatTime } = useTimeline(
    numberOfHoursInDay,
    isBaseTimeFormat,
  );

  const renderTime = (index) => (
    <TimelineBox key={index} width={hourWidth}>
      <TimelineTime>
        {formatTime(index + offsetStartHoursRange).toLowerCase()}
      </TimelineTime>
      <TimelineDividers>{renderDividers()}</TimelineDividers>
    </TimelineBox>
  );

  const renderDividers = () =>
    dividers.map((_, index) => (
      <TimelineDivider key={index} width={hourWidth} />
    ));

  return (
    <TimelineWrapper
      dayWidth={dayWidth}
      sidebarWidth={sidebarWidth}
      isSidebar={isSidebar}>
      {time.map((_, index) => renderTime(index))}
    </TimelineWrapper>
  );
}

const ChannelItem = ({ channel }) => {
  const { position, name } = channel;
  const getMachineStatusColor = () => {
    const colors = {
      'IN USE': 'success',
      IDLE: 'info',
      MAINTANENCE: 'error',
      'OUT OF ORDER': 'error',
      'IN WASH': 'warning',
    };

    return colors[channel?.status] || 'warning';
  };
  return (
    <ChannelBox {...position}>
      <Stack
        width="100%"
        height="100%"
        sx={{ maxHeight: 150, maxWidth: 100 }}
        alignItems="flex-start"
        justifyContent="center"
        socket
        spacing={0.5}>
        <Typography
          fontSize={18}
          fontWeight={700}
          color="#303330"
          textAlign="left">
          {name}
        </Typography>
        <Chip
          label={channel.status}
          size="small"
          color={getMachineStatusColor()}
        />
      </Stack>
    </ChannelBox>
  );
};

const Item = ({ program, ...rest }) => {
  const { styles, isLive } = useProgram({
    program,
    ...rest,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { data } = program;
  const { title, since, till } = data;

  const calcDuration = () => {
    const duration = new Date(till) - new Date(since);
    return milisecondsToTime(duration);
  };

  const setBackGround = (status, overlay) => {
    if (status == 'WAITING FOR DELIVERY' || status == 'READY FOR DELIVERY') {
      return '#4caf50';
    }
    if (overlay) {
      return bg;
    }
  };

  const [bg, setBg] = useState('#5DDADB');
  useEffect(() => {
    const colors = ['#5DDADB', '#3437A2', '#F78EB6', '#4dabf5', '#33ab9f'];
    setBg(colors[Math.floor(Math.random() * colors.length)]);
  }, []);

  return (
    <>
      <ProgramBox
        width={styles.width}
        style={styles.position}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        <ProgramContent
          style={{
            background: setBackGround(data.status, data.overlape),
            // top: data.overlape ? '20px' : 0,
            // height: data.overlape ? 'calc(100% + 20px)' : '100%',
          }}
          width={styles.width}
          isLive={isLive}>
          <ProgramFlex>
            <ProgramStack>
              <Stack
                direction="row"
                spacing={2}
                color={isLive ? '#fff' : '#303330'}>
                <Stack>
                  <Typography fontSize={18} fontWeight={700}>
                    Batch #{title?.split('-')[1]}
                  </Typography>
                  <Typography fontSize={12} fontWeight={600}>
                    Start: {dayjs(since).format('hh:mm A DD, MMM')} || End:{' '}
                    {dayjs(till).format('hh:mm A DD, MMM')}
                  </Typography>
                </Stack>
                <Stack spacing={0.4}>
                  <Chip
                    label={getBatchStatus(data.status).label}
                    color={getBatchStatus(data.status).color}
                    size="small"
                    sx={{ fontSize: 11, fontWeight: 600, width: 'max-content' }}
                  />
                  <Typography fontSize={12} fontWeight={600}>
                    Processing: {data.weight} Kg || Delay:{' '}
                    {milisecondsToTime(data.delay)} || Duration:{' '}
                    {calcDuration()}
                  </Typography>
                </Stack>
              </Stack>
            </ProgramStack>
          </ProgramFlex>
        </ProgramContent>
      </ProgramBox>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Stack spacing={2} color={'#303330'} px={2} py={2}>
          <Stack>
            <Typography fontSize={18} fontWeight={700}>
              Batch #{title?.split('-')[1]}
            </Typography>
            <Typography fontSize={12} fontWeight={600}>
              Start: {dayjs(since).format('hh:mm A DD, MMM')} || End:{' '}
              {dayjs(till).format('hh:mm A DD, MMM')}
            </Typography>
          </Stack>
          <Stack spacing={0.4}>
            <Chip
              label={getBatchStatus(data.status).label}
              color={getBatchStatus(data.status).color}
              size="small"
              sx={{ fontSize: 11, fontWeight: 600, width: 'max-content' }}
            />
            <Typography fontSize={12} fontWeight={600}>
              Processing: {data.weight} Kg || Delay:{' '}
              {milisecondsToTime(data.delay)} || Duration: {calcDuration()}
            </Typography>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

const TimeLineIndex = ({ socket }) => {
  const [channels, setChannels] = useState([]);
  const [epg, setEpg] = useState([]);
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  const [searchDate, setSearchDate] = useState(new Date());

  const handleLogSearchSubmit = (event) => {
    event.preventDefault();
  };

  const handelChange = async (newValue) => {
    setSearchDate(newValue);
  };

  const { getEpgProps, getLayoutProps } = useEpg({
    epg,
    channels,
    dayWidth: 2000,
    startDate: format(searchDate, 'yyyy-MM-dd'),
    sidebarWidth: 150,
    itemHeight: 105,
    height: isFullScreenMode ? 800 : 700,
    isSidebar: true,
    isTimeline: true,
    isLine: true,
    isBaseTimeFormat: true,
    theme: {
      ...epgTimelineTheme,
    },
  });

  useEffect(() => {
    socket.emit('batch-timeline-list');

    return () => {
      socket.off('connect');
    };
  }, [socket]);

  useEffect(() => {
    socket.on('batch-timeline-list-data', (data) => {
      setChannels(data.timeline.channels);
      setEpg(data.timeline.epg);
    });
    return () => {
      socket.off('batch-timeline-list-data');
    };
  }, [socket]);

  const toggleFullScreen = () => {
    const trackerDiv = document.querySelector('#batch-timeline-tracker');

    if (!isFullScreenMode) {
      trackerDiv.requestFullscreen();
      setIsFullScreenMode(true);
    } else {
      document.exitFullscreen();
      setIsFullScreenMode(false);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}>
        <Stack>
          <Stack
            id="batch-timeline-tracker"
            sx={{
              height: isFullScreenMode ? '100vh' : '77vh',
              width: 'calc(100vw - 100px)',
              position: 'relative',
              backgroundColor: '#ffff',
              marginTop: isFullScreenMode ? 100 : 0,
            }}>
            {isFullScreenMode && (
              <Stack
                mt={8}
                mx={1}
                direction="row"
                justifyContent="space-between">
                <Typography fontSize={40} fontWeight={800}>
                  TimeLine
                </Typography>
                <Typography fontSize={30} fontWeight={600}>
                  Date: {format(searchDate, 'MM/dd/yyyy')}
                </Typography>
              </Stack>
            )}
            {!isFullScreenMode && (
              <Stack alignSelf="end" width={{ md: '15%', xs: '35%' }}>
                <form onSubmit={handleLogSearchSubmit}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Search Date"
                      value={searchDate}
                      onChange={(newValue) => {
                        handelChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </form>
              </Stack>
            )}

            <Stack direction="row">
              <IconButton
                title="Fullscreen mode"
                onClick={toggleFullScreen}
                sx={{
                  position: 'absolute',
                  top: isFullScreenMode ? 150 : 60,
                  left: 25,
                  zIndex: 150,
                  bgcolor: '#303030',
                  color: '#fff',
                  borderRadius: 1,
                  ':hover': {
                    bgcolor: '#171820',
                  },
                }}>
                <Fullscreen />
              </IconButton>
            </Stack>

            <Epg {...getEpgProps()}>
              <Layout
                {...getLayoutProps()}
                renderProgram={({ program, ...rest }) => (
                  <Item key={program.data.id} program={program} {...rest} />
                )}
                renderChannel={({ channel }) => (
                  <ChannelItem key={channel.uuid} channel={channel} />
                )}
                renderTimeline={(props) => <Timeline {...props} />}
              />
            </Epg>
          </Stack>
        </Stack>
      </motion.div>
    </AnimatePresence>
  );
};

export default TimeLineIndex;
