import {
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Box,
  TextField,
  Typography,
  Autocomplete,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../widgets/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { fetchAllProcess } from '../../api/client';

const CreateProcessDescriptionForm = ({
  show,
  toggleModal,
  shadeArray,
  setShadeArray,
  shadeIndex,
  processFormArray,
  setProecessFormArray,
  additionalStepCharges,
  selectedQuotationId,
  initialOrderData,
}) => {
  const [processList, setProcessList] = useState([]);
  const [processDetails, setProcessDetails] = useState({});
  const [processDetailsList, setProcessDetailsList] = useState({});
  const [value, setValue] = useState('');
  const [totalRate, setTotalRate] = useState(0);
  const [checkstate, setCheckState] = useState({
    heatSetCharge: false,
    siliconCharge: false,
    stenterCharge: false,
    compactingCharge: false,
  });

  const fetchProcess = async () => {
    const response = await fetchAllProcess();
    setProcessList(response.processes);
  };

  const fetchProcessDetails = async () => {
    const tempObject = {
      additionalStepCharges: {
        compactingCharge: additionalStepCharges.compactingCharge,
        heatSetCharge: additionalStepCharges.heatSetCharge,
        siliconCharge: additionalStepCharges.siliconCharge,
        stenterCharge: additionalStepCharges.stenterCharge,
      },
      clientShades: {
        doublePartOpen: shadeArray[shadeIndex]?.doublePartOpen || 0,
        doublePartTube: shadeArray[shadeIndex]?.doublePartTube || 0,
        lycraOpen: shadeArray[shadeIndex]?.lycraOpen || 0,
        open: shadeArray[shadeIndex]?.open || 0,
        tube: shadeArray[shadeIndex]?.tube || 0,
      },
    };

    setProcessDetailsList(tempObject);
  };

  const handleCreationFormClose = () => {
    toggleModal(false);
  };
  const onClose = () => {
    handleCreationFormClose();
  };

  const {
    register,

    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      newFabric: '',
    },
  });

  const handelProcessDescriptionFormSubmission = () => {
    //for storing additional charges for in the custom array
    let additionalStepCharge = {};
    for (const id in checkstate) {
      if (checkstate[id] == true) {
        additionalStepCharge[`${id}`] =
          processDetailsList?.additionalStepCharges[`${id}`];
      }
    }

    //for storing processCharge for in the custom array
    let processCharge = {};

    processCharge[`${value}`] = processDetailsList?.clientShades[`${value}`];

    const modifiedData = {
      rate: totalRate,
      processInformation: processDetails,
      additionalStepCharge: additionalStepCharge,
      processCharge: processCharge,
    };

    let tempArray = JSON.parse(JSON.stringify(shadeArray));

    tempArray[shadeIndex].processInfo = modifiedData;
    setShadeArray(tempArray);

    const updateFormItem = processFormArray?.map((item, i) => {
      if (i == shadeIndex) {
        item.id = shadeIndex;
        item.checklist = checkstate;
        item.value = value;
        item.processDetails = processDetails;
      }
      return item;
    });
    setProecessFormArray(updateFormItem);
    if (processFormArray.length <= shadeIndex) {
      let newForm = {
        id: shadeIndex,
        checklist: checkstate,
        value: value,
        processDetails: processDetails,
        additionalStepCharge: additionalStepCharge,
      };

      setProecessFormArray((oldArray) => [...oldArray, newForm]);
    }

    toggleModal(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleCheckChange = (event) => {
    setCheckState({
      ...checkstate,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    (async () => {
      await fetchProcessDetails();
      await fetchProcess();
    })();
  }, [shadeArray, toggleModal, shadeIndex]);

  useEffect(() => {
    let exist = false;

    if (selectedQuotationId == initialOrderData?.quotationUid) {
      for (const item of processFormArray) {
        if (item.id == shadeIndex) {
          setCheckState(item.checklist);
          setValue(item.value);

          if (!item?.processDetails?.processName) {
            let tempProcessDetails = {};
            for (const element of processList) {
              if (element.shortName == item?.processDetails?.shortName) {
                tempProcessDetails = {
                  shortName: item?.processDetails?.shortName,
                  processName: element?.processName,
                  id: element?.id,
                };
                processFormArray[shadeIndex].processDetails =
                  tempProcessDetails;
              }
            }

            setProcessDetails(tempProcessDetails);
          } else {
            setProcessDetails(item.processDetails);
          }
          exist = true;
        }
      }
    }
    if (!exist) {
      setValue('');
      setProcessDetails({});
      setCheckState({
        heatSetCharge: false,
        siliconCharge: false,
        stenterCharge: false,
        compactingCharge: false,
      });
    }
  }, [show]);

  useEffect(() => {
    let tempTotal = 0;
    if (value == '') {
      tempTotal = tempTotal + 0;
    } else {
      tempTotal = tempTotal + processDetailsList?.clientShades[`${value}`];
    }
    if (processDetailsList?.additionalStepCharges) {
      for (const key in checkstate) {
        if (checkstate[key])
          tempTotal =
            tempTotal + processDetailsList?.additionalStepCharges[`${key}`];
      }
    }

    setTotalRate(tempTotal);
  }, [value, checkstate]);

  useEffect(() => {}, [processFormArray]);

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="md"
        width="70%"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">
          <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1} mt={1}>
            Process Selection
          </Typography>
        </DialogTitle>
        <Stack
          sx={{
            paddingLeft: 2,
            paddingRight: 2,

            paddingBottom: 3,
          }}>
          {processDetailsList?.clientShades ? (
            <>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack spacing={2} sx={{ mt: 2, ml: 2 }}>
                  <form>
                    <Stack>
                      {processList ? (
                        <Stack>
                          <Autocomplete
                            disableClearable
                            disablePortal
                            id="combo-box-demo"
                            options={processList}
                            defaultValue={{
                              processName:
                                processFormArray[shadeIndex]?.processDetails
                                  ?.shortName || '',
                              shortName:
                                processFormArray[shadeIndex]?.processDetails
                                  ?.shortName || '',
                            }}
                            getOptionLabel={(option) => option.shortName}
                            size="small"
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}>
                                {option.processName} ({option.shortName})
                              </Box>
                            )}
                            onChange={(event, newValue) => {
                              setProcessDetails(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                sx={{ width: '370px' }}
                                {...params}
                                label="Select Process"
                                {...register('processList')}
                              />
                            )}
                          />
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    <Typography
                      fontSize={20}
                      sx={{ fontWeight: '500' }}
                      my={1}
                      mt={1}>
                      Select Process Type
                    </Typography>
                    <Stack>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={value}
                          onChange={handleChange}>
                          <FormControlLabel
                            value="open"
                            control={<Radio />}
                            label="Open"
                          />
                          <FormControlLabel
                            value="tube"
                            control={<Radio />}
                            label="Tube"
                          />
                          <FormControlLabel
                            value="lycraOpen"
                            control={<Radio />}
                            label="Lycra Open"
                          />
                          <FormControlLabel
                            value="doublePartOpen"
                            control={<Radio />}
                            label="Double Part Open"
                          />
                          <FormControlLabel
                            value="doublePartTube"
                            control={<Radio />}
                            label="Double Part Tube"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>

                    <Typography
                      fontSize={20}
                      sx={{ fontWeight: '500' }}
                      my={1}
                      mt={1}>
                      Select Additional Step
                    </Typography>
                    <Stack>
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkstate?.heatSetCharge}
                                onChange={handleCheckChange}
                                name="heatSetCharge"
                              />
                            }
                            label="Heat Set"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkstate?.siliconCharge}
                                onChange={handleCheckChange}
                                name="siliconCharge"
                              />
                            }
                            label="Silicon"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkstate?.stenterCharge}
                                onChange={handleCheckChange}
                                name="stenterCharge"
                              />
                            }
                            label="Stenter"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkstate?.compactingCharge}
                                onChange={handleCheckChange}
                                name="compactingCharge"
                              />
                            }
                            label="Compacting"
                          />
                        </FormGroup>
                      </FormControl>
                    </Stack>
                  </form>
                </Stack>
                <Divider orientation="vertical" flexItem></Divider>
                <Stack
                  width="50%"
                  direction="column"
                  justifyContent="space-between">
                  <Stack>
                    <Typography
                      fontSize={20}
                      sx={{ fontWeight: '500' }}
                      my={1}
                      mt={1}>
                      {processDetails?.shortName ? (
                        <>Summary ( {processDetails?.shortName} )</>
                      ) : (
                        <>Summary </>
                      )}
                    </Typography>

                    {/* process select rate  */}
                    <Typography mt={2} color="#aba9a4">
                      Shade Rate
                    </Typography>
                    <Divider></Divider>
                    <Stack mr={3}>
                      <Grid container spacing={2} my={0.5}>
                        <Grid item xs={6} md={6}>
                          <Stack>
                            <Typography>
                              {value ? (
                                <>
                                  {(value[0].toUpperCase() + value.slice(1))
                                    .match(/[A-Z][a-z]+|[0-9]+/g)
                                    .join(' ')}
                                </>
                              ) : (
                                <></>
                              )}
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={6} md={6}>
                          <Stack>
                            <Typography
                              textAlign="right"
                              fontSize={18}
                              sx={{ fontWeight: '700' }}>
                              {processDetailsList?.clientShades[`${value}`]}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>

                    {/* additional charge rate  */}
                    <Typography mt={2} color="#aba9a4">
                      Additional Charge Rate
                    </Typography>
                    <Divider></Divider>
                    <Stack>
                      {Object.keys(checkstate).map((item, index) => {
                        return (
                          <Stack key={index} container spacing={2} mr={3}>
                            {checkstate[item] ? (
                              <Grid container spacing={2} my={0.5}>
                                <Grid item xs={6} md={6}>
                                  <Stack>
                                    <Typography>
                                      {' '}
                                      {(item[0].toUpperCase() + item.slice(1))
                                        .match(/[A-Z][a-z]+|[0-9]+/g)
                                        .join(' ')}
                                    </Typography>
                                  </Stack>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                  <Stack>
                                    <Typography
                                      textAlign="right"
                                      fontSize={18}
                                      sx={{ fontWeight: '700' }}>
                                      {
                                        processDetailsList
                                          ?.additionalStepCharges[`${item}`]
                                      }
                                    </Typography>
                                  </Stack>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>

                  <Stack mr={3}>
                    <Divider></Divider>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end">
                      <Typography
                        textAlign="right"
                        fontSize={18}
                        sx={{ fontWeight: '700' }}>
                        Total Rate :
                      </Typography>
                      <Typography
                        textAlign="right"
                        fontSize={18}
                        sx={{ fontWeight: '700' }}>
                        {totalRate.toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Stack>
        <Stack
          mr={3}
          direction="row"
          gap={1}
          justifyContent="flex-end"
          sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button
            variant="outlined"
            sx={{ borderColor: 'red' }}
            color="error"
            onClick={handleCreationFormClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={
              !processDetails.processName || value.length < 1 ? true : false
            }
            onClick={handleSubmit(handelProcessDescriptionFormSubmission)}>
            Confirm
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default CreateProcessDescriptionForm;
