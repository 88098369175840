import { Chip, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ViewportList from 'react-viewport-list';
import { fetchUserCompletedTask } from '../../api/users';
import { formatTime } from '../../utils/time';
import { PageviewTwoTone as Pageview } from '@mui/icons-material';
import { setUserTaskTypeColor } from '../../constants/table-column/user';
const CompletedTask = ({ uid }) => {
  const [completedTaskList, setCompletedTaskList] = useState([]);
  const completedTaskListRef = useRef(null);

  const fetchCompletedTaskList = async () => {
    const response = await fetchUserCompletedTask(uid);
    setCompletedTaskList(response.logs);
  };

  useEffect(() => {
    (async () => await fetchCompletedTaskList())();
  }, []);

  return (
    <>
      {completedTaskList.length > 0 ? (
        <Stack pt={0.2}>
          <Stack
            ref={completedTaskListRef}
            spacing={0.5}
            height="calc(100vh - 46vh)"
            overflow="auto">
            <ViewportList
              viewportRef={completedTaskListRef}
              items={completedTaskList}
              overscan={5}>
              {(item) => (
                <>
                  <Stack
                    key={item.uid}
                    direction="row"
                    px={1}
                    py={1}
                    borderRadius={1}
                    bgcolor="#f2f2f2"
                    mt={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        {item?.taskName.length > 20 ? (
                          <Tooltip title={item?.message}>
                            <Stack>
                              <Typography fontSize={13} fontWeight={800}>
                                {item?.taskName.substr(0, 20)}...
                              </Typography>
                            </Stack>
                          </Tooltip>
                        ) : (
                          <Typography fontSize={13} fontWeight={800}>
                            {item?.taskName}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography fontSize={15} fontWeight={800}>
                          <Chip
                            label={item?.taskType}
                            sx={{
                              color: 'white',
                              backgroundColor: setUserTaskTypeColor(
                                item?.taskType,
                              ),
                            }}
                            size="small"
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack
                          width="100%"
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center">
                          <Typography fontSize={12} fontWeight={400}>
                            {formatTime(item?.completeTime)}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              )}
            </ViewportList>
          </Stack>
        </Stack>
      ) : (
        <Stack
          justifyContent="center"
          pt={10}
          width="100%"
          height="100%"
          alignItems="center">
          <Stack direction="Column" justifyContent="center" alignItems="center">
            <Pageview sx={{ width: 120, height: 120 }} />
            <Typography fontSize={18} fontWeight={800}>
              No Completed Task
            </Typography>
            <Typography maxWidth={450}>No completed task to show</Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default CompletedTask;
