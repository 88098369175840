import { IconButton, Tooltip } from '@mui/material';
import Filter1TwoToneIcon from '@mui/icons-material/Filter1TwoTone';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import FormatAlignLeftTwoToneIcon from '@mui/icons-material/FormatAlignLeftTwoTone';
import AbcTwoToneIcon from '@mui/icons-material/AbcTwoTone';
import RuleTwoToneIcon from '@mui/icons-material/RuleTwoTone';

export const GenerateProcessIcon = (fieldType) => {
  switch (fieldType) {
    case 'NUMBER':
      return (
        <Tooltip title={fieldType}>
          <IconButton>
            <Filter1TwoToneIcon sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Tooltip>
      );
    case 'IMAGE':
      return (
        <Tooltip title={fieldType}>
          <IconButton>
            <ImageTwoToneIcon sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Tooltip>
      );
    case 'TEXTAREA':
      return (
        <Tooltip title={fieldType}>
          <IconButton>
            <FormatAlignLeftTwoToneIcon sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Tooltip>
      );
    case 'TEXT':
      return (
        <Tooltip title={fieldType}>
          <IconButton>
            <AbcTwoToneIcon sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Tooltip>
      );
    case 'YES/NO':
      return (
        <Tooltip title={fieldType}>
          <IconButton>
            <RuleTwoToneIcon sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={fieldType}>
          <IconButton>
            <Filter1TwoToneIcon sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Tooltip>
      );
  }
};
