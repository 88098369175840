import { ChevronLeftTwoTone, PrintTwoTone } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { fetchBatchDetail } from '../../api/batch';
import { DASHBOARD } from '../../constants/routeUrls';
import Button from '../../widgets/Button';
import ReactToPrint from 'react-to-print';
import '../../styles/batch-card-print.css';
import { formatNumber } from '../../utils/number';
import { formatTime } from '../../utils/time';

const BatchCardPrintPreview = () => {
  const [batch, setBatch] = useState(null);
  const { lotNumber } = useParams();
  const fetchBtach = async () => {
    const response = await fetchBatchDetail(lotNumber);

    if (response) {
      setBatch(response);
    }
  };

  useEffect(() => {
    if (lotNumber) {
      (async () => await fetchBtach())();
    }
  }, [lotNumber]);

  let printDocRef = useRef();

  if (!batch) {
    return (
      <Stack
        width="100%"
        height="80vh"
        spacing={1}
        alignItems="center"
        justifyContent="center">
        <Typography variant="h4" fontWeight={700}>
          Batch Not Found
        </Typography>
        <Typography>
          Sorry could not load information to generate dyeing report
        </Typography>
        <Button
          startIcon={<ChevronLeftTwoTone />}
          variant="contained"
          component={Link}
          to={`${DASHBOARD}`}>
          Return TO Dashboard
        </Button>
      </Stack>
    );
  }
  return (
    <div className="container">
      <ReactToPrint
        trigger={() => (
          <Button endIcon={<PrintTwoTone />} variant="contained" sx={{ mb: 3 }}>
            Print Batch Card
          </Button>
        )}
        content={() => printDocRef}
      />

      <div
        id="print-content"
        ref={(el) => {
          printDocRef = el;
        }}>
        <div
          className="row"
          style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <div>
            <img src="/assets/images/logo.svg" alt="" width="150" />
            <p>2/1 Gadda, Savar-1340, Dhaka</p>
            <h2 className="sub-title">Batch #{lotNumber?.split('-')?.[1]}</h2>
            <h5 style={{ marginTop: '10px' }}>
              Fabric Weight: {formatNumber(batch.totalFabricWeight)} Kg
            </h5>
            <h5 style={{ marginTop: '10px' }}>
              Liquor Ratio: {formatNumber(batch.liquorRatio)}
            </h5>
            <h5 style={{ marginTop: '10px' }}>
              Machine: {batch.machineInfo?.name} (
              {batch?.machineInfo?.maxCapacity} Kg)
            </h5>
            <h5 style={{ marginTop: '10px' }}>
              Loading Time: {formatTime(batch.loadingTime)}
            </h5>
          </div>
          <div>
            <div
              style={{
                width: 280,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}>
              <QRCode size={250} value={batch.id.toString()} />
            </div>
          </div>
        </div>

        <h3 style={{ marginTop: '10px' }}>Orders</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <td>FC Number</td>
              <td>Party Name</td>
            </tr>
          </thead>
          <tbody>
            {batch.orderInfo?.map((item) => (
              <tr key={item.fcNumber}>
                <td>{item.fcNumber}</td>
                <td>{item.partyName}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3 style={{ marginTop: '10px' }}>Fabrics</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <td>Fabric Type</td>
              <td>Color</td>
              <td>Shade Group</td>
              <td>Shade (%)</td>
              <td>GSM</td>
              <td>DIA</td>
              <td>Quantity (KG)</td>
            </tr>
          </thead>
          <tbody>
            {batch.fabricInfo?.map((item) => (
              <tr key={item.id}>
                <td>{item.fabricType}</td>
                <td>{item.colour}</td>
                <td>{item.shadeGroup}</td>
                <td>{(item.shade * 100).toFixed(2)}</td>
                <td>{item.gsm}</td>
                <td>{item.dia}</td>
                <td>{formatNumber(item.usedQty)}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>{formatNumber(batch.totalFabricWeight)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BatchCardPrintPreview;
