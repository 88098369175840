import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DyeTableColumns } from '../../constants/table-column/inventory';
import { fetchAllChemicals } from '../../api/inventory';

const DyeList = ({ query, searchStatus, dyeGroup, tab }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [chemicals, setChemicals] = useState([]);
  const [totalEntires, setTotalEntries] = useState(0);

  const fetchChemicals = async (query) => {
    const response = await fetchAllChemicals('Dye', {
      searchKey: query?.searchKey,
      status: query?.status,
      dyeGroup: query?.dyeGroup,
      page,
      pageSize,
    });

    setChemicals(response.chemicals);
    setTotalEntries(response.total);
  };

  useEffect(() => {
    (async () => await fetchChemicals({}))();
  }, []);

  useEffect(() => {
    if (query?.length > 1 || query?.length <= 0) {
      (async () => await fetchChemicals({ searchKey: query }))();
    }
  }, [query]);

  useEffect(() => {
    if (searchStatus) {
      (async () => await fetchChemicals({ status: searchStatus }))();
    }
  }, [searchStatus]);

  useEffect(() => {
    (async () => await fetchChemicals())();
  }, [pageSize]);

  useEffect(() => {
    (async () => await fetchChemicals())();
  }, [page]);

  useEffect(() => {
    (async () => await fetchChemicals({ dyeGroup }))();
  }, [dyeGroup]);

  return (
    <DataGrid
      columns={DyeTableColumns({ tab })}
      rows={chemicals}
      paginationMode="server"
      onPageChange={(value) => setPage(value)}
      onPageSizeChange={(value) => setPageSize(value)}
      rowCount={totalEntires}
      page={page}
      pageSize={pageSize}
      rowsPerPageOptions={[10, 15, 20, 30, 40, 50]}
    />
  );
};

export default DyeList;
