import {
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ACCOUNT, ACCOUNT_ORDER_DETAILS } from '../../constants/routeUrls';
import Button from '../../widgets/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { fetchOrderAccountDetails } from '../../api/order';
import dayjs from 'dayjs';

import OrderAccountBatchTab from '../../components/account/OrderAccountBatchTab';
import OrderAccountLogsTab from '../../components/account/OrderAccountLogsTab';
import { formatNumber } from '../../utils/number';
import { setAccountStatusBackgroundColor } from '../../utils/account';
import { setOrderStatusColor } from '../../utils/order';
import colors from '../../constants/colors';
import PrintIcon from '@mui/icons-material/Print';
import { OrderBatchTranscript } from '../../components/account/OrderBatchTranscript';
import PageTitle from '../../utils/PageTitle';
import OrderAccoutBillsTab from '../../components/account/OrderAccoutBillsTab';
// import { OrderBatchTranscript } from './OrderBatchTranscript';

const OrderAccountDetails = () => {
  const { uid } = useParams();
  const [orderInfo, setOrderInfo] = useState({});
  const [batchList, setBatchList] = useState([]);
  const [accountInfo, setAccountInfo] = useState({});

  const [transactionUpdate, setTransactionUpdate] = useState(false);
  const [open, setOpen] = useState(false);

  const fetchDetails = async () => {
    const response = await fetchOrderAccountDetails(uid);

    if (response) {
      setOrderInfo(response.order);
      setBatchList(response.batches);
      setAccountInfo(response.account);
    }
  };

  const Stat = ({ label, content, bgcolor, ...rest }) => {
    return (
      <Stack
        height={100}
        width="100%"
        border="2px solid rgba(0,0,0,0.2)"
        borderRadius={2}
        justifyContent="center"
        alignItems="center"
        bgcolor={bgcolor}
        {...rest}
        p={1}>
        <Typography
          color="#ffff"
          fontSize={12}
          fontWeight={700}
          textAlign="center">
          {label}
        </Typography>
        <Typography
          color="#ffff"
          fontWeight={800}
          fontSize={26}
          component="div">
          {content}
        </Typography>
      </Stack>
    );
  };

  const calculatePaymentableAmount = () => {
    let total = 0;
    batchList.map((batch) => {
      batch.fabricList.map((fabric) => {
        total += fabric.batchFabricAmount * fabric.rate;
      });
    });
    return total;
  };

  const calculateCompletedQuantity = () => {
    let total = 0;
    batchList.map((batch) => {
      batch.fabricList.map((fabric) => {
        total += fabric.batchFabricAmount;
      });
    });
    return total;
  };

  useEffect(() => {
    (async () => await fetchDetails())();
  }, [transactionUpdate]);

  //for tab section
  const { tab: tabParams } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    setActiveTab(parseInt(tabParams));
  }, [tabParams]);
  const tabs = [
    {
      Element: <OrderAccountBatchTab batchList={batchList} />,
    },
    {
      Element: (
        <OrderAccountLogsTab
          uid={uid}
          transactionUpdate={transactionUpdate}
          orderInfo={orderInfo}
          accountInfo={accountInfo}
          setTransactionUpdate={setTransactionUpdate}
        />
      ),
    },
    {
      Element: <OrderAccoutBillsTab batchList={batchList} uid={uid} />,
    },
  ];
  const currentTab = tabs[activeTab];
  let navigate = useNavigate();
  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
    navigate(`${ACCOUNT_ORDER_DETAILS}/${uid}/${tab}`);
  };

  return (
    <Stack
      width="80%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      mx="auto"
      pt={3}>
      <PageTitle title="Order Account Details" />
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        mt={1}
        mb={2}>
        <Stack>
          <Button component={Link} to={`${ACCOUNT}/0`} variant="contained">
            <ArrowBackIosIcon fontSize="15px" />
            <Typography fontSize="15px">Back</Typography>
          </Button>
        </Stack>
      </Stack>
      <Divider></Divider>
      {!orderInfo?.fcNumber || !accountInfo?.uid ? (
        <Stack
          justifyContent="center"
          width="100%"
          height="100%"
          alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Grid
          mt={2}
          container
          spacing={2}
          width="100%"
          justifyContent="flex-start">
          <Grid item xs={11} md={4}>
            <Stack
              width="100%"
              direction="column"
              justifyContent="space-between"
              alignItems="space-between"
              sx={{
                height: '100%',
                width: '100%',
                mx: 'auto',
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              <Stack direction="column">
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    justifyContent="flex-start"
                    alignItems="center">
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      FC # {orderInfo?.fcNumber}
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <Chip
                      label={orderInfo?.status}
                      size="small"
                      sx={{
                        color: '#ffff',
                        backgroundColor: setOrderStatusColor(orderInfo?.status),
                      }}
                    />
                    <Chip
                      label={accountInfo?.status}
                      size="small"
                      sx={{
                        color: '#ffff',
                        backgroundColor: setAccountStatusBackgroundColor(
                          accountInfo?.status,
                        ),
                      }}
                    />
                  </Stack>
                </Stack>

                {/* Showing order status  */}

                <Typography pt={0.5} fontSize={15}>
                  {dayjs(orderInfo?.issueDate).format('DD/MMM/YYYY ')}
                </Typography>

                <Typography noWrap>
                  {orderInfo?.partyName} ({orderInfo?.clientRefNo})
                </Typography>
                <Typography>{orderInfo?.clientContactPhone}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              sx={{
                height: '100%',
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              <Grid container spacing={0.5} direction="row" alignItems="center">
                <Grid item xs={6} md={4.5}>
                  <Stack>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Sales Person:
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography>{orderInfo?.salesPerson}</Typography>
                </Grid>
                <Grid item xs={6} md={4.5}>
                  <Stack>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Delivery Date:{' '}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={5}>
                  {orderInfo?.deliveryDate ? (
                    <Typography>
                      {dayjs(orderInfo?.deliveryDate).format('DD/MMM/YYYY ')}
                    </Typography>
                  ) : (
                    <Typography>Delivery Date: Invalid Date</Typography>
                  )}
                </Grid>
                <Grid item xs={6} md={4.5}>
                  <Stack>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Client Address:
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={5}>
                  {orderInfo?.clientAddress?.length > 20 ? (
                    <Tooltip title={orderInfo?.clientAddress}>
                      <Typography>
                        {orderInfo?.clientAddress.substr(0, 20)}...
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography>{orderInfo?.clientAddress}</Typography>
                  )}
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={1} md={3}>
            <Stack
              width="100%"
              direction="row"
              justifyContent="flex-end"
              alignItems="center">
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography>Print</Typography>
                  <PrintIcon />
                </Stack>
              </Button>
            </Stack>
          </Grid>
          <Grid width="100%" item xs={12} md={12}>
            {orderInfo?.remarks?.length > 1 ? (
              <Stack
                sx={{
                  height: '100%',
                  border: '2px solid rgba(0,0,0,0.2)',
                  p: 2,
                  borderRadius: 1.5,
                  overflow: scroll,
                }}>
                <Grid
                  container
                  spacing={0.5}
                  direction="row"
                  alignItems="center">
                  <Grid item xs={12} md={12}>
                    <Stack>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Remarks:
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextareaAutosize
                      maxRows={4}
                      disabled
                      aria-label="maximum height"
                      placeholder="Maximum 4 rows"
                      defaultValue={orderInfo?.remarks}
                      style={{
                        resize: 'none',
                        width: '100%',
                        border: 0,
                        color: 'black',
                        background: 'white',
                        fontSize: '15px',
                        fontFamily: 'sans-serif',
                      }}
                    />
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Stat
                  label="Total Quantity(KG)"
                  content={accountInfo?.totalQuantity}
                  bgcolor={colors.secondary}
                />
              </Grid>
              <Grid item xs={2}>
                <Stat
                  label="Total Est. Amount(BDT)"
                  content={formatNumber(accountInfo?.totalAmount)}
                  bgcolor={colors.info}
                />
              </Grid>

              <Grid item xs={2}>
                <Stat
                  label="Completed Quantity(KG)"
                  content={calculateCompletedQuantity()}
                  bgcolor={colors.error}
                />
              </Grid>
              <Grid item xs={2}>
                <Stat
                  label="Completed Est. Amount(BDT)"
                  content={formatNumber(calculatePaymentableAmount())}
                  bgcolor={colors.warning}
                />
              </Grid>
              <Grid item xs={2}>
                <Stat
                  label="Total Paid(BDT)"
                  content={formatNumber(accountInfo?.totalPaid)}
                  bgcolor={colors.success}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Stack mt={4}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ width: 'max-content' }}>
          <Tab
            label="Batches"
            sx={{
              px: 1,

              textTransform: 'capitalize',
              fontSize: 14,
              fontWeight: 700,

              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#4562f7',
                transition: '0.3s ease',
              },
            }}
          />
          <Tab
            label="Payments"
            sx={{
              px: 1,

              textTransform: 'capitalize',
              fontSize: 14,
              fontWeight: 700,

              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#4562f7',
                transition: '0.3s ease',
              },
            }}
          />
          <Tab
            label="Delivery Bills"
            sx={{
              px: 1,

              textTransform: 'capitalize',
              fontSize: 14,
              fontWeight: 700,

              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#4562f7',
                transition: '0.3s ease',
              },
            }}
          />
        </Tabs>
      </Stack>
      <Stack width="100%" height={450}>
        {currentTab.Element}
      </Stack>

      <OrderBatchTranscript
        open={open}
        setOpen={setOpen}
        batchList={batchList}
        orderInfo={orderInfo}
      />
    </Stack>
  );
};

export default OrderAccountDetails;
