import { CircularProgress, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import ReactPinField from 'react-pin-field';
import { useDispatch, useSelector } from 'react-redux';
import { otpinput } from '../../../store/actions/auth';
import { authActions } from '../../../store/reducers/authReducer';
import { warningNoitif } from '../../../utils/notification';
import Button from '../../../widgets/Button';

const OtpFrom = () => {
  const [otpValid, setOtpValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const tempUserData = useSelector((state) => state.auth.tempUserData);
  const handleOtp = (otp) => {
    setLoading(true);
    dispatch(
      otpinput({
        userData: tempUserData,
        otpToken: otp,
        otpType: 'login-verify',
      }),
    );
    setLoading(false);
  };
  return (
    <form>
      <Stack
        spacing={2}
        width="100%"
        height="100vh"
        alignItems="center"
        justifyContent="center">
        <Stack
          minWidth={300}
          spacing={2}
          alignItems="center"
          justifyContent="center">
          <Typography fontWeight={500} fontSize="20px" textAlign="center">
            Please enter OTP <br /> Sent to your verified email address
          </Typography>
          <Stack direction="row">
            {loading ? (
              <CircularProgress />
            ) : (
              <ReactPinField
                className="pin-field"
                length={6}
                onComplete={handleOtp}
              />
            )}
          </Stack>
          {otpValid ? (
            <Countdown
              zeroPadTime={2}
              date={Date.now() + 240000}
              onComplete={() => {
                setOtpValid(false);
                dispatch(authActions.clearUserTempData());
                warningNoitif('OTP has expired');
              }}
              renderer={({ minutes, seconds }) => (
                <Stack spacing={2} direction="row">
                  <Typography mb={15} fontSize={16} fontWeight="bold">
                    Valid till
                  </Typography>
                  <Typography fontWeight="bold" ml={15} color="red">
                    {minutes}:{seconds === 0 ? '00' : seconds}
                  </Typography>
                </Stack>
              )}
            />
          ) : (
            <Button color="error" variant="contained" fullWidth>
              Try Again
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default OtpFrom;
