import * as urls from './routeUrls';
import {
  AssignmentTwoTone,
  GroupsTwoTone,
  VerifiedUserTwoTone,
  DashboardTwoTone as Dashboard,
  AutoAwesomeMotionTwoTone as AutoAwesomeMotion,
  PrecisionManufacturingTwoTone as PrecisionManufacturing,
  SupervisedUserCircleTwoTone as SupervisedUserCircle,
  SettingsTwoTone as Settings,
  BookmarkBorderTwoTone,
  ScienceTwoTone as Science,
  ScienceTwoTone,
  AlarmTwoTone,
  DynamicFormTwoTone,
  AddRoadTwoTone,
  SpatialAudioOffTwoTone,
  FormatPaintTwoTone,
  ReceiptLongTwoTone as AccountIcon,
  AssessmentTwoTone as ReportIcon,
} from '@mui/icons-material';

const sidebarItems = [
  {
    label: 'Dashboard',
    path: urls.DASHBOARD,
    Icon: Dashboard,
  },
  {
    label: 'Batches',
    path: urls.BATCH,
    Icon: AutoAwesomeMotion,
  },
  {
    label: 'Machines',
    path: urls.MACHINE,
    Icon: PrecisionManufacturing,
  },
  {
    label: 'Order',
    path: urls.ORDER,
    Icon: AssignmentTwoTone,
  },
  {
    label: 'Client',
    path: urls.CLIENT,
    Icon: GroupsTwoTone,
  },
  {
    label: 'Approval',
    path: urls.APPROVAL,
    Icon: BookmarkBorderTwoTone,
  },
  {
    label: 'Inventory',
    path: urls.STORE_MANAGEMENT,
    Icon: Science,
  },
];

const sidebarItems3 = (roles) => {
  let roleArray = [];
  if (roles) {
    roleArray = roles.split(',');
  }

  if (
    roleArray.includes('MANAGER') ||
    roleArray.includes('ADMIN') ||
    roleArray.includes('ACC')
  ) {
    return [
      {
        label: 'Dashboard',
        path: urls.DASHBOARD,
        Icon: Dashboard,
      },
      {
        label: 'Batches',
        path: urls.BATCH,
        Icon: AutoAwesomeMotion,
      },
      {
        label: 'Machines',
        path: urls.MACHINE,
        Icon: PrecisionManufacturing,
      },
      {
        label: 'Order',
        path: urls.ORDER,
        Icon: AssignmentTwoTone,
      },
      {
        label: 'Client',
        path: urls.CLIENT,
        Icon: GroupsTwoTone,
      },
    ];
  } else {
    return [
      {
        label: 'Dashboard',
        path: urls.DASHBOARD,
        Icon: Dashboard,
      },
      {
        label: 'Batches',
        path: urls.BATCH,
        Icon: AutoAwesomeMotion,
      },
      {
        label: 'Machines',
        path: urls.MACHINE,
        Icon: PrecisionManufacturing,
      },
      {
        label: 'Order',
        path: urls.ORDER,
        Icon: AssignmentTwoTone,
      },
    ];
  }
};

const sidebarItems4 = (roles) => {
  let roleArray = [];
  if (roles) {
    roleArray = roles.split(',');
  }

  if (roleArray.includes('MANAGER') || roleArray.includes('ADMIN')) {
    return [
      {
        label: 'Account',
        path: urls.ACCOUNT + '/0',
        Icon: AccountIcon,
      },
      {
        label: 'Reports',
        path: urls.REPORT + '/0',
        Icon: ReportIcon,
      },

      {
        label: 'Chemical Store',
        path: urls.STORE_MANAGEMENT + '/0',
        Icon: ScienceTwoTone,
        menuItems: [
          {
            label: 'Chemicals',
            path: urls.STORE_MANAGEMENT + '/0',
            Icon: ScienceTwoTone,
          },
          {
            label: 'Dyes',
            path: urls.STORE_MANAGEMENT + '/1',
            Icon: FormatPaintTwoTone,
          },
        ],
      },
      {
        label: 'Approval',
        path: urls.APPROVAL + '/0',
        Icon: VerifiedUserTwoTone,
      },
      {
        label: 'Users',
        path: urls.USERS,
        Icon: SupervisedUserCircle,
      },
      {
        label: 'Settings',
        path: urls.SETTINGS + '/0',
        Icon: Settings,
        menuItems: [
          {
            label: 'Shifts',
            path: urls.SETTINGS + '/0',
            Icon: AlarmTwoTone,
          },
          {
            label: 'Process',
            path: urls.SETTINGS + '/1',
            Icon: DynamicFormTwoTone,
          },
          {
            label: 'Utility',
            path: urls.SETTINGS + '/2',
            Icon: AddRoadTwoTone,
          },
          {
            label: 'Notice',
            path: urls.SETTINGS + '/3',
            Icon: SpatialAudioOffTwoTone,
          },
        ],
      },
    ];
  } else if (roleArray.includes('ACC')) {
    return [
      {
        label: 'Account',
        path: urls.ACCOUNT + '/0',
        Icon: AccountIcon,
      },
    ];
  } else {
    return [
      {
        label: 'Chemical Store',
        path: urls.STORE_MANAGEMENT + '/0',
        Icon: ScienceTwoTone,
        menuItems: [
          {
            label: 'Chemicals',
            path: urls.STORE_MANAGEMENT + '/0',
            Icon: ScienceTwoTone,
          },
          {
            label: 'Dyes',
            path: urls.STORE_MANAGEMENT + '/1',
            Icon: FormatPaintTwoTone,
          },
        ],
      },
    ];
  }
};

export default {
  sidebarItems,
  sidebarItems3,
  // sidebarItems2,
  sidebarItems4,
};
