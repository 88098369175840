import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import React from 'react';
import TemplateFields from './TemplateFields';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '../../../widgets/Button';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightyellow' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#fff',
  borderRadius: '5px',
  padding: grid,
});

const TaskManagementForm = ({
  onDropEnd,
  remove,
  tasks,
  append,
  errors,
  formControl,
  register,
  setFormValue,
}) => {
  return (
    <Box width="100%" height="100%">
      {tasks?.length > 0 ? (
        <DragDropContext onDragEnd={onDropEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Box
                width="100%"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {tasks.map((task, index) => (
                  <Draggable
                    key={`${task.taskName}-${index}`}
                    draggableId={`${task.taskName}-${index}`}
                    index={index}>
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}>
                        <TemplateFields
                          index={index}
                          remove={remove}
                          errors={errors}
                          control={formControl}
                          register={register}
                          setFormValue={setFormValue}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Stack
          width="100%"
          height="30vh"
          alignItems="center"
          justifyContent="center"
          spacing={2}>
          <Typography variant="h5" fontWeight={800} sx={{ flexGrow: 0 }}>
            No task added
          </Typography>
          <Typography>
            No task has been added yet. Please add your first task to get
            started.
          </Typography>
          <Button variant="contained" onClick={append}>
            Add Task
          </Button>
          {errors.tasks && (
            <FormHelperText sx={{ color: 'red' }}>
              {errors?.tasks?.message}
            </FormHelperText>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default TaskManagementForm;
