import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

const NoData = ({ title, description }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2.5}
      width="100%">
      <Box component="img" src="/assets/icons/no-data.png" height="120px" />
      <Typography variant="h4" fontWeight={800}>
        {title}
      </Typography>
      <Typography variant="body2" fontWeight={500} color="#909090">
        {description}
      </Typography>
    </Stack>
  );
};

export default NoData;
