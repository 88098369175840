import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RecipeInfo from './timeline/RecipeInfo';
import TaskDetail from './timeline/TaskDetail';
import TaskTimeline from './timeline/TaskTimeline';

const TaskInfo = ({ batch, socket }) => {
  const [selectedTask, setSelectedTask] = useState(null);

  const isTaskSelected = (task) => {
    return task?.uid === selectedTask?.uid;
  };

  const selectOrDeSelectTask = (task) => {
    if (selectedTask && selectedTask?.uid === task.uid) {
      setSelectedTask(null);
      return;
    }
    setSelectedTask(task);
  };

  useEffect(() => {
    if (selectedTask) {
      batch.taskInfo?.tasks?.map((item) => {
        if (item.uid === selectedTask.uid) {
          setSelectedTask(item);
        }
      });
    }
  }, [batch]);

  return (
    <>
      {batch?.taskInfo ? (
        <Stack
          width="100%"
          height="calc(100vh - 431px)"
          overflow="auto"
          direction={{ xs: 'column', md: 'column', lg: 'row' }}
          spacing={2}>
          <Stack
            flex={{ xs: 1, md: 1, lg: 0.5 }}
            overflow="auto"
            px={1}
            py={1}
            border={2}
            borderRadius={3}
            borderColor="rgba(0,0,0,0.1)">
            <TaskTimeline
              tasks={batch?.taskInfo?.tasks}
              isSelected={isTaskSelected}
              select={selectOrDeSelectTask}
              socket={socket}
            />
          </Stack>
          <Stack
            flex={{ xs: 1, md: 1, lg: 0.7 }}
            width="100%"
            border={2}
            borderRadius={3}
            borderColor="rgba(0,0,0,0.1)">
            <TaskDetail task={selectedTask} batch={batch} socket={socket} />
          </Stack>
          <Stack
            flex={{ xs: 1, md: 1, lg: 0.7 }}
            border={2}
            borderRadius={3}
            borderColor="rgba(67,67,67,0.1)">
            <RecipeInfo task={selectedTask} recipes={batch?.recipeInfo} />
          </Stack>
        </Stack>
      ) : (
        <Stack
          width="100%"
          height="100%"
          minHeight="calc(100vh - 431px)"
          alignItems="center"
          justifyContent="center">
          <Typography variant="h4" fontWeight={800}>
            No batch template
          </Typography>
          <Typography variant="body1">
            No batch template has been created yet
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default TaskInfo;
