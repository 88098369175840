import React from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Button from './Button';

const Dialog = ({
  title,
  yesText,
  noText,
  yesHandler,
  noHandler,
  onClose,
  open,
  children,
  hideButtons,
  ...props
}) => {
  return (
    <MuiDialog open={open} onClose={onClose} {...props}>
      <DialogTitle>{title || 'Are you sure?'}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {!hideButtons && (
        <DialogActions>
          <Button variant="outlined" onClick={noHandler} color="error">
            {noText || 'Cancel'}
          </Button>
          <Button variant="contained" onClick={yesHandler}>
            {yesText || 'Yes'}
          </Button>
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
