import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import { AllListColumns } from '../../constants/table-column/client';
import PageHeader from '../../widgets/PageHeader';
import { AddNewClient } from '../../components/client/AddNewClient';
import { fetchAllClients } from '../../api/client';
import Button from '../../widgets/Button';
import NoDataOverlay from '../../components/global/NoDataOverlay';
import FileExporter from '../../components/global/FileExporter';
import PageTitle from '../../utils/PageTitle';

const Client = () => {
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [uid, setUid] = useState('');
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [statusTextField, setStatusTextField] = useState(0);
  const [typeTextField, setTypeTextField] = useState(0);
  const uidInput = React.useRef(null);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isAdded, setIsAdded] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  //fetching clinet api
  const fetchClients = async (uid, status, page, pageSize) => {
    setIsloading(true);
    const response = await fetchAllClients({
      searchKey: uid,
      status: status,
      page: page,
      pageSize: pageSize,
      pageless: false,
    });

    setClientList(response.clients);
    setTotalEntries(response.total);
    setIsloading(false);
    setIsUpdated(!isUpdated);
  };

  useEffect(() => {
    (async () => {
      await fetchClients();
    })();
    setIsAdded(false);
  }, [isAdded]);

  useEffect(() => {
    (async () => await fetchClients(uid, status, page, pageSize))();
  }, [page, pageSize]);

  //form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    fetchClients(uid, status, page, pageSize);

    setUid(uid);
    setStatus(status);
  };

  const resetAll = () => {
    fetchClients('', '', page, pageSize);
    setUid('');
    setStatus('');
    uidInput.current.value = '';

    setStatusTextField(0);
    setTypeTextField(0);
  };

  const fetchPagelessData = async () => {
    const response = await fetchAllClients({
      searchKey: uid,
      status: status,
      pageless: true,
    });

    return response.clients;
  };

  return (
    <Stack
      spacing={2}
      width="100%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <PageTitle title="Clients" />
      {/* header section  */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        spacing={2}>
        <PageHeader>Clients</PageHeader>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={() => setShowAddClientForm(true)}>
            Add Client <AddIcon />
          </Button>
        </Stack>
      </Stack>
      <AddNewClient
        toggleModal={setShowAddClientForm}
        show={showAddClientForm}
        update={setClientList}
        setIsAdded={setIsAdded}
      />

      {/* search section  */}
      <form onSubmit={handleSubmit}>
        <Stack
          mt={2}
          direction="row"
          width="100%"
          spacing={2}
          alignItems="center"
          justifyContent="left">
          {/* for uid search  */}
          <TextField
            size="small"
            onChange={(event) => setUid(event.target.value)}
            inputRef={uidInput}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            flexWrap="wrap"
            fullWidth
            id="outlined-basic"
            label="Search"
            sx={{ maxWidth: 300 }}
            variant="outlined"
          />

          {/* for status search  */}

          <FormControl fullWidth sx={{ maxWidth: 200 }}>
            <InputLabel id="SearchStatus">Status</InputLabel>
            <Select
              size="small"
              defaultValue={statusTextField}
              value={typeTextField}
              labelId="SearchStatus"
              id="demo-simple-select"
              label="Status"
              placeholder="Status"
              onChange={(event) => {
                setStatus(event.target.value);

                setTypeTextField(event.target.value);
              }}>
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">In Active</MenuItem>
            </Select>
          </FormControl>

          {/* Search button  */}
          <IconButton
            title="Edit"
            aria-label="edit"
            type="submit"
            sx={{
              bgcolor: '#171820',
              color: 'white',
              borderRadius: 1,
              ':hover': { bgcolor: '#303030' },
            }}>
            <SearchIcon />
          </IconButton>
          {/* reset button  */}
          <IconButton
            title="Edit"
            aria-label="edit"
            onClick={() => resetAll()}
            sx={{
              bgcolor: '#171820',
              color: 'white',
              borderRadius: 1,
              ':hover': { bgcolor: '#303030' },
            }}>
            <RestartAltIcon />
          </IconButton>
          {/* downloadExcelSection  */}
          <FileExporter
            apiHandle={fetchPagelessData}
            title={'Clients'}
            isUpdated={isUpdated}
            mapKey={'UID'}
          />
        </Stack>
      </form>

      {/* Table section */}
      {!clientList ? (
        <Stack
          justifyContent="center"
          pt={10}
          width="100%"
          height="100%"
          alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Box
            width="100%"
            height={500}
            sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <DataGrid
              columns={AllListColumns}
              rows={clientList}
              rowCount={totalEntries}
              getRowId={(row) => row.uid}
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
              paginationMode="server"
              filterMode="server"
              onPageChange={(value) => setPage(value)}
              onPageSizeChange={(value) => setPageSize(value)}
              loading={isLoading}
              components={{
                NoRowsOverlay: NoDataOverlay,
              }}
            />
          </Box>
        </>
      )}
    </Stack>
  );
};

export default Client;
