import { Stack, Typography, FormHelperText } from '@mui/material';
import React from 'react';
import Button from '../../../widgets/Button';

const NoChemicalAdded = ({ taskName, append, errors }) => {
  return (
    <Stack
      spacing={1}
      width="100%"
      height="40vh"
      alignItems="center"
      justifyContent="center">
      <Typography variant="h6" fontWeight={700}>
        No Chemical Added
      </Typography>
      <Typography maxWidth={300} textAlign="center">
        No chemicals added for {taskName}. Start by adding your first chemical
      </Typography>
      <Button onClick={append} variant="contained">
        Add Chemical
      </Button>
      {errors?.recipe && (
        <FormHelperText sx={{ color: 'red' }}>
          {errors?.recipe?.message}
        </FormHelperText>
      )}
    </Stack>
  );
};

export default NoChemicalAdded;
