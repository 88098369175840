import { Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { formatNumber } from '../../utils/number';
import '../../styles/batch-account-print.css';
const BatchTaskChemicalDetails = ({ singleRecipe }) => {
  const calculateChemicalQuantity = () => {
    let totalQuantity = 0;
    singleRecipe.recipe.forEach((chemical) => {
      totalQuantity += chemical.total * 0.001;
    });
    return totalQuantity.toFixed(2);
  };

  const calculateCostAmount = () => {
    let totalAmount = 0;
    singleRecipe.recipe.forEach((chemical) => {
      totalAmount += chemical.cost;
    });
    return totalAmount;
  };
  return (
    <Stack>
      {/* new design  */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Stack direction="column" justifyContent="flex-start">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography fontSize={15} sx={{ fontWeight: 'bold' }}>
                #{singleRecipe?.lotNumber}({singleRecipe?.taskName}),
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <Stack width="100%">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2}>
                <Typography textAlign="center">CHEMICAL NAME</Typography>
              </Grid>
              <Grid width="100%" item xs={0.5}></Grid>
              <Grid item xs={2}>
                <Typography textAlign="center">UNIT</Typography>
              </Grid>
              <Grid width="100%" item xs={0.5}></Grid>
              <Grid item xs={2}>
                <Typography textAlign="center">QUANTITY(KG)</Typography>
              </Grid>
              <Grid width="100%" item xs={0.5}></Grid>
              <Grid item xs={2}>
                <Typography textAlign="center">ADDITIONAL</Typography>
              </Grid>
              <Grid width="100%" item xs={0.5}></Grid>
              <Grid item xs={2}>
                <Typography textAlign="end">COST</Typography>
              </Grid>
            </Grid>
            {singleRecipe?.recipe.map((recipeInfo) => {
              return (
                <Grid
                  container
                  spacing={1}
                  key={recipeInfo?.id}
                  alignItems="center">
                  <Grid item xs={2}>
                    <Typography textAlign="center">
                      {recipeInfo?.chemicalName}
                    </Typography>
                  </Grid>
                  <Grid width="100%" item xs={0.5}>
                    <Stack
                      width="100%"
                      sx={{ background: 'gray', opacity: 0.2 }}>
                      <Divider></Divider>
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography textAlign="center">
                      {recipeInfo?.unit}
                    </Typography>
                  </Grid>
                  <Grid width="100%" item xs={0.5}>
                    <Stack
                      width="100%"
                      sx={{ background: 'gray', opacity: 0.2 }}>
                      <Divider></Divider>
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    {recipeInfo?.total > 0 ? (
                      <Typography textAlign="center">
                        {(recipeInfo?.total * 0.001).toFixed(2)}
                      </Typography>
                    ) : (
                      <Typography textAlign="center">N/A</Typography>
                    )}
                  </Grid>
                  <Grid width="100%" item xs={0.5}>
                    <Stack
                      width="100%"
                      sx={{ background: 'gray', opacity: 0.2 }}>
                      <Divider></Divider>
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    {recipeInfo?.addition > 0 ? (
                      <Typography textAlign="center">
                        {(recipeInfo?.addition * 0.001).toFixed(2)}
                      </Typography>
                    ) : (
                      <Typography textAlign="center">N/A</Typography>
                    )}
                  </Grid>
                  <Grid width="100%" item xs={0.5}>
                    <Stack
                      width="100%"
                      sx={{ background: 'gray', opacity: 0.2 }}>
                      <Divider></Divider>
                    </Stack>
                  </Grid>
                  <Grid item xs={2}>
                    {recipeInfo?.cost > 0 ? (
                      <Typography textAlign="end">
                        ৳{formatNumber(recipeInfo?.cost)}
                      </Typography>
                    ) : (
                      <Typography textAlign="end">N/A</Typography>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack
                  mt={1}
                  width="100%"
                  sx={{ background: 'gray', opacity: 0.5 }}>
                  <Divider></Divider>
                </Stack>
              </Grid>
              <Grid item xs={5}>
                <Typography textAlign="end" fontWeight={700}>
                  Task Quantity:
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography textAlign="center" fontWeight={700}>
                  {calculateChemicalQuantity()} KG
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography textAlign="end">
                  <Typography fontWeight={700} textAlign="end">
                    Task Cost:
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography fontWeight={700} textAlign="end">
                  ৳{formatNumber(calculateCostAmount())}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default BatchTaskChemicalDetails;
