import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChevronLeftTwoTone as ChevronLeft,
  DeleteTwoTone as Delete,
} from '@mui/icons-material';
import {
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import Barcode from 'react-barcode/lib/react-barcode';
import { useFieldArray, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  addStockToExsistingChemicalLot,
  fetchStockDetails,
} from '../../api/inventory';
import { STOCK_DETAILS } from '../../constants/routeUrls';
import { successNoitif } from '../../utils/notification';
import { formatCurrency, formatNumber } from '../../utils/number';
import { AddStockSchema } from '../../validators/inventory';
import Button from '../../widgets/Button';
import TextField from '../../widgets/TextField';

const AddChemicalStock = () => {
  const { sku } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { tab } = location.state;
  const resolver = yupResolver(AddStockSchema);
  const [stockDetails, setStockDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      lots: [],
    },
    resolver,
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'lots' });

  const preloadData = async () => {
    setIsLoading(true);
    const response = await fetchStockDetails(sku);
    if (response) {
      setStockDetails(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await preloadData())();
  }, [sku]);

  const handleStockAdd = async (data) => {
    const { lots } = data;
    const response = await addStockToExsistingChemicalLot(sku, lots);
    if (response) {
      successNoitif('New stock information added');
      navigate(`${STOCK_DETAILS}/${sku}`, { state: { tab: tab } });
    }
  };

  return (
    <Container maxWidth="xl" ref={ref}>
      <Stack
        width="100%"
        height="auto"
        minHeight="calc(100vh - 65px)"
        py={3}
        spacing={2}>
        <Button
          variant="contained"
          startIcon={<ChevronLeft />}
          component={Link}
          to={`${STOCK_DETAILS}/${sku}`}
          state={{ tab: tab }}
          sx={{ width: 'max-content' }}>
          Back
        </Button>
        <Divider />
        {stockDetails !== null ? (
          !isLoading ? (
            <Stack direction="row" flexWrap="wrap" width="100%">
              <Stack
                spacing={1}
                alignItems="flex-start"
                width="max-content"
                sx={{ mr: 1, mb: 2 }}
                px={2}
                py={2}
                borderRadius={2}
                border={2}
                borderColor="rgba(0,0,0,0.2)">
                <Barcode
                  value="barcode-example"
                  displayValue={false}
                  height={80}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  maxWidth={410}
                  width="100%">
                  <Typography variant="h5" fontWeight={700}>
                    {stockDetails.sku}
                  </Typography>
                  <Typography variant="h5" fontWeight={700}>
                    {stockDetails.name}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Chip label={stockDetails.type} size="small" />
                  {stockDetails?.dyeGroup && (
                    <Chip label={stockDetails?.dyeGroup?.name} size="small" />
                  )}
                  <Chip
                    label={stockDetails.status}
                    size="small"
                    color={
                      stockDetails.status === 'IN STOCK' ? 'success' : 'error'
                    }
                  />
                  {stockDetails.totalQuantity <= 100 && (
                    <Chip label="Low In Stock" size="small" color="warning" />
                  )}
                </Stack>
                <Typography fontSize="0.85rem" fontWeight={400}>
                  Last updated:{' '}
                  {dayjs(stockDetails.updatedAt).format('DD, MMM YYYY hh:mm A')}
                </Typography>
              </Stack>
              <Stack
                px={2}
                py={2}
                sx={{ mr: 1, mb: 2 }}
                borderRadius={2}
                border={2}
                borderColor="rgba(0,0,0,0.2)"
                spacing={0.5}>
                <Typography fontSize="0.8rem" fontWeight={400}>
                  Available Quantity
                </Typography>
                <Typography fontSize="1.2rem" fontWeight={700}>
                  {formatNumber(stockDetails.totalQuantity)} {stockDetails.unit}
                </Typography>
                <Divider />
                <Typography fontSize="0.8rem" fontWeight={400}>
                  Avg Cost
                </Typography>
                <Typography fontSize="1.2rem" fontWeight={700}>
                  BDT {stockDetails.avgCostPerUnit.toFixed(2)} /{' '}
                  {stockDetails.unit}
                </Typography>
                <Divider />
                <Typography fontSize="0.8rem" fontWeight={400}>
                  Total Purchase (this month)
                </Typography>
                <Typography fontSize="1.2rem" fontWeight={700}>
                  {formatCurrency(stockDetails.purchaseThisMonth)}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack
              width="100%"
              height="calc(100vh - 65px)"
              alignItems="center"
              justifyContent="center">
              <CircularProgress />
            </Stack>
          )
        ) : (
          <Stack
            width="100%"
            height="calc(100vh - 65px)"
            alignItems="center"
            justifyContent="center">
            <Typography variant="h4" fontWeight={700}>
              OPS!!
            </Typography>
            <Typography fontSize="18px" maxWidth={310} textAlign="center">
              Something went wrong. Could not load information for SKU: &quot;
              {sku}&quot;
            </Typography>
          </Stack>
        )}
        <Typography variant="h5" fontWeight={700}>
          Add New Stock
        </Typography>

        {fields.length > 0 ? (
          <>
            <Table
              size="small"
              stickyHeader
              sx={{ width: '100%', overflow: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Brand</TableCell>
                  <TableCell>Stock Quantity</TableCell>
                  <TableCell>Total Cost</TableCell>
                  <TableCell width={60} sx={{ textAlign: 'center' }}>
                    #
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <TextField
                        size="small"
                        placeholder="Enter brand name"
                        error={!!errors?.lots?.[index]?.brand}
                        helperText={errors?.lots?.[index]?.brand?.message}
                        {...register(`lots.${index}.brand`)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        placeholder="Stock quantity"
                        error={!!errors?.lots?.[index]?.quantity}
                        helperText={errors?.lots?.[index]?.quantity?.message}
                        {...register(`lots.${index}.quantity`)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        placeholder="Total cost"
                        error={!!errors?.lots?.[index]?.cost}
                        helperText={errors?.lots?.[index]?.cost?.message}
                        {...register(`lots.${index}.cost`)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => remove(index)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              variant="contained"
              onClick={() => append({})}
              sx={{ width: 'max-content' }}>
              Add Lot
            </Button>
          </>
        ) : (
          <Stack width="100%" alignItems="center" spacing={2}>
            {errors?.lots && (
              <FormHelperText sx={{ color: 'red' }}>
                {errors?.lots?.message}
              </FormHelperText>
            )}
            <Typography variant="h6" fontWeight={700}>
              No Lots Added
            </Typography>
            <Typography maxWidth={350} textAlign="center">
              No lots added for this chemical added yet. Please add lots to
              manage inventory for this chemical.
            </Typography>
            <Button variant="contained" onClick={() => append({})}>
              Add Lot
            </Button>
          </Stack>
        )}
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <Button
            variant="contained"
            color="info"
            onClick={handleSubmit(handleStockAdd)}
            disabled={fields.length <= 0}
            sx={{ width: 'max-content' }}>
            Save Inventory Changes
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default AddChemicalStock;
