import {
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '../../widgets/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import OrderFabicDetails from '../../components/order/OrderFabicDetails';
import { createOrderDelivery, fetchOrderDetails } from '../../api/order';
import { useForm, useWatch } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrderDeliveryValidator } from '../../validators/order';
import dayjs from 'dayjs';
import OrderSummary from '../../components/order/OrderSummary';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { successNoitif } from '../../utils/notification';
import { ORDER_DETAILS } from '../../constants/routeUrls';
import PageTitle from '../../utils/PageTitle';

const OrderDelivery = () => {
  const { fcNumber } = useParams();

  let navigate = useNavigate();

  const [delivery, setDelivery] = useState('');

  const [parentCheck, SetParentCheck] = useState(0);
  const [perticularFabric, setPerticularFabric] = useState([]);
  const [newDelivery, setNewDelivery] = useState([]);
  const [orderInfo, setOrderInfo] = useState({});
  const [clientInfo, setClientInfo] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fullFormData, setFullFormData] = useState({});

  const resolver = yupResolver(OrderDeliveryValidator);
  //form for order information
  const {
    register,
    formState: { errors },

    handleSubmit,
    setValue,

    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      partyName: '',
      vehicleNumber: '',
      deliveryContatct: '',
      deliveryContatctNumber: '',
      deliveryAddress: '',
    },
    resolver,
  });
  const deliveryDate = useWatch({ control, name: 'deliveryDate' });
  const partyName = useWatch({ control, name: 'partyName' });
  const vehicleNumber = useWatch({ control, name: 'vehicleNumber' });
  const deliveryAddress = useWatch({ control, name: 'deliveryAddress' });
  const deliveryContatct = useWatch({ control, name: 'deliveryContatct' });
  const deliveryContatctNumber = useWatch({
    control,
    name: 'deliveryContatctNumber',
  });

  //fetching the order details
  const fetchDetails = async () => {
    const response = await fetchOrderDetails(fcNumber);
    if (response) {
      const { order, client, fabrics } = response;
      setOrderInfo(order);
      setClientInfo(client);
      setDelivery(fabrics);
    }
  };

  //for form submission
  const handelFormSubmit = (data) => {
    let modifiedFabricList = [];

    newDelivery.map((fabric) => {
      let modifiedFabric = {
        fabricId: fabric?.fabricId,
        fabricName: fabric?.fabricName,
        fabricRate: fabric?.fabricRate,
        fabricColor: fabric?.fabricColor || '',
        fabricDeliveryQuantity: calculateFabricTotalQuantity(fabric),
        batchInfo: fabric?.batchInfo,
      };
      modifiedFabricList.push(modifiedFabric);
    });

    const {
      partyName,
      vehicleNumber,
      deliveryContatct,
      deliveryContatctNumber,
      deliveryAddress,
      deliveryDate,
    } = data;

    const modifiedData = {
      partyName: partyName,
      vehicleNumber: vehicleNumber,
      deliveryContatct: deliveryContatct,
      deliveryContatctNumber: deliveryContatctNumber,
      deliveryAddress: deliveryAddress,
      deliveryDate: deliveryDate,
      totalDeliveryQuantity: calculateTotalQuantity(newDelivery),
      clientId: clientInfo?.clientId,
      orderId: orderInfo?.orderId,
      fabricList: modifiedFabricList,
    };
    setFullFormData(modifiedData);
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  //colse confrimation dialog
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    orderDeliveryFormSubmit();
  };

  //async calling api to post form data
  const orderDeliveryFormSubmit = async () => {
    const response = await createOrderDelivery(fullFormData);

    if (response) {
      successNoitif('Order delivery has been Created');
      navigate(-1);
    }
  };

  useEffect(() => {
    setValue('partyName', clientInfo?.partyName);

    setValue('deliveryContatctNumber', clientInfo?.clientContactPhone);
    setValue('deliveryAddress', clientInfo?.deliveryAddress);
    setValue('deliveryDate', orderInfo?.deliveryDate);
  }, [orderInfo]);

  useEffect(() => {
    (async () => await fetchDetails())();
  }, []);

  const calculateTotalQuantity = (newDelivery) => {
    let total = 0;
    for (const fabric of newDelivery) {
      for (const batch of fabric.batchInfo) {
        total = total + batch.tempDeliverable;
      }
    }
    return total;
  };

  const calculateFabricTotalQuantity = (fabric) => {
    const total = fabric?.batchInfo.reduce((sum, batch) => {
      return sum + batch.tempDeliverable;
    }, 0);

    return total;
  };

  const checkValidDeliveryExist = () => {
    let isValid = false;
    for (const element of delivery) {
      if (element?.fabricInfo?.processed > 0) {
        isValid = true;
      }
    }
    return isValid;
  };

  return (
    <Stack
      width={{ xs: '90%', md: '80%' }}
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      mx="auto"
      pt={3}>
      <PageTitle title="Create Order Delivery" />
      <Stack
        width="100%"
        direction="row"
        justifyContent="flex-start"
        mt={1}
        mb={2}>
        <Button
          component={Link}
          to={`${ORDER_DETAILS}/${fcNumber}/1`}
          variant="contained">
          <ArrowBackIosIcon fontSize="15px" />
          <Typography fontSize="15px">Back</Typography>
        </Button>
      </Stack>
      <Divider></Divider>
      <Typography mt={2} pb={2} variant="h5" sx={{ fontWeight: 'bold' }}>
        Create Delivery Order For #{fcNumber}
      </Typography>

      {/* for upper order information  */}
      {!orderInfo || !checkValidDeliveryExist() ? (
        <>
          <Stack
            justifyContent="center"
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
            <Typography>No valid delivery order exists</Typography>
          </Stack>
        </>
      ) : (
        <Stack
          sx={{
            height: 'auto',
            minHeight: 100,
            width: { xs: '100%', md: '98%' },
            p: 2,
            paddingBottom: 0,
            m: 2,
            marginLeft: { xs: 0, md: 5 },
          }}>
          <form>
            <Stack
              width={{ md: '80%', sm: '100%' }}
              direction="row"
              spacing={4}
              justifyContent="space-between"
              alignItems="flex-start">
              <Stack
                sx={{
                  height: 'auto',
                  minHeight: 200,
                  width: { xs: '100%', md: '40%', sm: '60%' },
                }}>
                <Typography
                  fontSize={20}
                  sx={{ fontWeight: '500' }}
                  my={1}
                  mt={1}>
                  Delivery Information
                </Typography>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  py={2}>
                  <Grid item xs={6} md={6}>
                    <Stack>
                      <Typography fontSize={15}>Delivery Date: </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        minDate={new Date()}
                        value={deliveryDate}
                        onChange={(value) => setValue('deliveryDate', value)}
                        renderInput={(params) => (
                          <TextField
                            // focused
                            size="small"
                            type="date"
                            {...params}
                            sx={{
                              maxWidth: {
                                md: '100%',
                                sm: '100%',
                                xs: '100%',
                              },
                            }}
                            error={errors?.deliveryDate}
                            helperText={errors?.deliveryDate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <Stack>
                      <Typography fontSize={15}>Vehicle number: </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      size="small"
                      sx={{
                        width: '100%',
                      }}
                      placeholder="Vehicle Number"
                      {...register('vehicleNumber')}
                      error={errors?.vehicleNumber}
                      helperText={errors?.vehicleNumber?.message}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Stack>
                      <Typography fontSize={15}>Delivery Address: </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={6} md={6}>
                    {/* delivery Address  */}
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center">
                      <TextField
                        size="small"
                        sx={{
                          width: '300px',
                        }}
                        {...register('deliveryAddress')}
                        error={errors?.deliveryAddress}
                        helperText={errors?.deliveryAddress?.message}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                sx={{
                  height: 'auto',
                  minHeight: 200,
                  width: { xs: '100%', md: '40%', sm: '60%' },
                }}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  pt={7}>
                  <Grid item xs={6} md={6}>
                    <Stack>
                      <Typography fontSize={15}>
                        Delivery Contact Number:{' '}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      size="small"
                      {...register('deliveryContatctNumber')}
                      error={errors?.deliveryContatctNumber}
                      helperText={errors?.deliveryContatctNumber?.message}
                    />
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <Stack>
                      <Typography fontSize={15}>
                        Delivery Contact Person:{' '}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <TextField
                      size="small"
                      {...register('deliveryContatct')}
                      error={errors?.deliveryContatct}
                      helperText={errors?.deliveryContatct?.message}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </form>
        </Stack>
      )}

      {/* edit batch wise fabric section  */}
      {!delivery || !checkValidDeliveryExist() ? (
        <></>
      ) : (
        <>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <Stack
              direction="column"
              flexWrap="wrap"
              alignItems="center"
              width={{ xs: '100%', md: '50%' }}
              justifyContent="flex-start">
              {delivery?.map((fabric, index) => {
                if (fabric?.fabricInfo?.processed > 0) {
                  return (
                    <OrderFabicDetails
                      key={fabric?.fabricInfo?.fabricId}
                      fabric={fabric}
                      index={index}
                      parentCheck={parentCheck}
                      SetParentCheck={SetParentCheck}
                      perticularFabric={perticularFabric}
                      setPerticularFabric={setPerticularFabric}
                      newDelivery={newDelivery}
                      setNewDelivery={setNewDelivery}></OrderFabicDetails>
                  );
                } else {
                  <></>;
                }
              })}
            </Stack>
            <Stack
              sx={{
                height: '97%',
                minHeight: 90,
                width: { xs: '100%', md: '50%' },
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                paddingBottom: 0,

                borderRadius: 1.5,
              }}>
              <Stack flex={1}>
                <Stack width="100%" justifyContent="center" alignItems="center">
                  <Typography
                    fontSize={{ xs: 18, md: 20 }}
                    sx={{ fontWeight: 'bold' }}>
                    Order Delivery Summary
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    pt={1}
                    sx={{ fontWeight: 'bold' }}
                    fontSize={{ xs: 15, md: 18 }}>
                    Order #{fcNumber}
                  </Typography>
                </Stack>
                <Grid container spacing={{ xs: 0, md: 2 }}>
                  <Grid item xs={12} md={6} sm={6}>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="p"
                        fontSize={15}
                        sx={{ fontWeight: 'bold' }}>
                        Party Name:
                      </Typography>
                      <Typography variant="p" fontSize={15}>
                        {partyName}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="p"
                        fontSize={15}
                        sx={{ fontWeight: 'bold' }}>
                        Vehicle No:
                      </Typography>
                      {vehicleNumber ? (
                        <Typography variant="p" fontSize={15}>
                          {vehicleNumber}
                        </Typography>
                      ) : (
                        <Typography variant="p" fontSize={15}>
                          Not provided
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="p"
                        fontSize={15}
                        sx={{ fontWeight: 'bold' }}>
                        Delivery Address:
                      </Typography>
                      <Typography variant="p" fontSize={15}>
                        {deliveryAddress}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="p"
                        fontSize={15}
                        sx={{ fontWeight: 'bold' }}>
                        Delivery Contact:
                      </Typography>
                      <Typography variant="p" fontSize={15}>
                        {deliveryContatct}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="p"
                        fontSize={15}
                        sx={{ fontWeight: 'bold' }}>
                        Delivery Number:
                      </Typography>
                      <Typography variant="p" fontSize={15}>
                        {deliveryContatctNumber}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={0.5}>
                      <Typography
                        variant="p"
                        fontSize={15}
                        sx={{ fontWeight: 'bold' }}>
                        Delivery Date:{' '}
                      </Typography>
                      <Typography variant="p" fontSize={15}>
                        {dayjs(deliveryDate).format('DD/MMM/YYYY ')}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>

                <Stack>
                  {!newDelivery ? (
                    <></>
                  ) : (
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      width="100%">
                      <Stack>
                        {newDelivery?.map((fabric) => (
                          <OrderSummary
                            key={fabric.fabricId}
                            fabric={fabric}></OrderSummary>
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" py={2}>
                <Typography variant="h6" fontSize={{ xs: 15, md: 18 }}>
                  Total Delivery Quantity: {calculateTotalQuantity(newDelivery)}{' '}
                  KG
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}

      {orderInfo && newDelivery && checkValidDeliveryExist() ? (
        <Stack
          width="100%"
          justifyContent="center"
          alignItems="flex-end"
          mt={2}>
          <Button
            variant="contained"
            disabled={!newDelivery.length ? true : false}
            onClick={handleSubmit(handelFormSubmit)}>
            Create
          </Button>
        </Stack>
      ) : (
        <></>
      )}

      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to create this delivery order?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => handelConfrimationDecision()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default OrderDelivery;
