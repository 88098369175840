import { AlarmTwoTone as Alarm } from '@mui/icons-material';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  Timeline,
  timelineItemClasses,
} from '@mui/lab';
import { Chip, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { formatTime } from '../../../../utils/time';

const LogInfo = ({ logs }) => {
  const sortedLogs = useMemo(() => {
    return logs?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [logs]);

  const getLogTypeColor = (type) => {
    const colors = {
      INFO: '#4562f7',
      WARN: '#F1A661',
      ERROR: '#F96666',
      SUCCESS: '#7FB77E',
      ACTION: '#6F38C5',
    };

    return colors[type || 'INFO'];
  };
  return (
    <Stack width="100%" height="100%" px={1} py={1} spacing={2} overflow="auto">
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}>
        {sortedLogs?.map((log, index) => (
          <TimelineItem key={log.id}>
            <TimelineSeparator>
              <TimelineDot
                sx={{ boxShadow: 'none', bgcolor: getLogTypeColor(log.type) }}>
                <Alarm
                  sx={{
                    fill: '#fff',
                    height: 15,
                    width: 15,
                  }}
                />
              </TimelineDot>
              {index < sortedLogs?.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Stack spacing={0.5}>
                <Typography fontSize="0.8rem" fontWeight={400}>
                  {formatTime(log?.createdAt)}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography fontSize="18px" fontWeight={700}>
                    {log.title}
                  </Typography>
                  {log.userInfo && (
                    <Chip
                      label={log.userInfo?.name}
                      size="small"
                      sx={{
                        width: 'max-content',
                        fontSize: '0.65rem',
                        fontWeight: 700,
                      }}
                    />
                  )}
                </Stack>
                <Typography fontSize="0.85rem" fontWeight={300}>
                  {log.message}
                </Typography>
              </Stack>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Stack>
  );
};

export default LogInfo;
