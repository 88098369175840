import React from 'react';
import { Typography } from '@mui/material';
export const MachineBatchno = ({ machine }) => {
  return (
    machine.status === 'IN USE' &&
    machine.assignedBatch && (
      <>
        <Typography variant="h6" sx={{ fontSize: '0.85rem' }}>
          Assigned To
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontSize: '1.25rem', fontWeight: '800' }}>
          Batch#{machine.assignedBatch.split('-')[0]}
        </Typography>
      </>
    )
  );
};
