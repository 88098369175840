import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { DASHBOARD, SIGNIN } from '../../constants/routeUrls';
import Screen from '../../widgets/Screen';

const PageNotFound = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <Screen>
      <Stack
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100vh"
        spacing={2}>
        <Box
          component="img"
          src="/assets/images/404-banner.png"
          width="300px"
        />
        <Typography variant="h1" fontSize="120px" fontWeight="bold">
          404
        </Typography>
        <Typography fontSize="18px" fontWeight="bold">
          You might be lost. Get help!!!
        </Typography>
        {auth.isLoggedIn ? (
          <Button variant="contained" component="a" href={DASHBOARD}>
            Dashboard
          </Button>
        ) : (
          <Button variant="contained" component="a" href={SIGNIN}>
            SIGN UP
          </Button>
        )}
      </Stack>
    </Screen>
  );
};

export default PageNotFound;
