import * as yup from 'yup';
export const ClientCreationValidator = yup.object().shape({
  name: yup.string().min(3).required('Must enter Client name'),
  shortCode: yup.string().min(3).required('Must enter Short Code '),
  contactPerson: yup.string().min(3).required('Must enter Contact Person'),
  contactNumber: yup
    .string()
    .min(3)
    .required('Must enter Contact Number')
    .matches(
      /^(?:\+88|01)?(?:\d{11}|\d{13})$/,
      'Invalid format for phone number',
    ),
  status: yup.string().min(3).required('Must select Status'),
});

export const QuotationCreationValidator = yup.object().shape({
  issueDate: yup
    .date()
    .required('Must select Issue Date')
    .typeError('Must select Issue Date')
    .max(yup.ref('validTillDate'), 'Issue Date can not exceed Valid Till Date'),
  heatSeatCharge: yup
    .number()
    .typeError('Must provide a number')
    .min(1)
    .required('Must be greater than 0')
    .positive('Must enter Heat Seat Charge'),
  siliconCharge: yup
    .number()
    .typeError('Must provide a number')
    .min(1)
    .required('Must be greater than 0')
    .positive('Must enter Heat Silicon Charge'),
  stenterCharge: yup
    .number()
    .typeError('Must provide a number')
    .min(1)
    .required('Must be greater than 0')
    .positive('Must enter Heat Stenter Charge'),
  compactingCharge: yup
    .number()
    .typeError('Must provide a number')
    .min(1)
    .required('Must be greater than 0')
    .positive('Must enter Heat Compacting Charge'),

  validTillDate: yup
    .date()
    .required('Must select Valid Till Date')
    .typeError('Must select Valid Till Date'),

  shadePricing: yup.array().of(
    yup.object().shape({
      shadeName: yup.string().required('Must enter Shade Name'),
      // shadeRange: yup.string().required('Must enter Shade Range'),
      open: yup
        .number()
        .typeError('Must provide a number')
        .min(0)
        .required('Must be greater than 0'),
      // .positive('Must enter Open Price'),
      tube: yup
        .number()
        .typeError('Must provide a number')
        .min(0)
        .required('Must be greater than 0'),
      // .positive('Must enter Tube Price'),
      lycraOpen: yup
        .number()
        .typeError('Must provide a number')
        .min(0)
        .required('Must be greater than 0'),
      // .positive('Must enter Tube Price'),
      doublePartOpen: yup
        .number()
        .typeError('Must provide a number')
        .min(0)
        .required('Must be greater than 0'),
      // .positive('Must enter Double Part Open Price'),
      doublePartTube: yup
        .number()
        .min(0)
        .typeError('Must provide a number')
        .required('Must be greater than 0'),
      // .positive('Must enter Double Part Tube Price'),
    }),
  ),
  // .min(1, 'At least one task is required')
  // .required('At least one task is required'),
  terms: yup.array().of(
    yup.object().shape({
      term: yup.string().required('Must enter terms'),
    }),
  ),
});

export const ProcessCreationValidator = yup.object().shape({
  processName: yup.string().required('Must enter Process Name'),
  shortName: yup.string().required('Must enter Shot Name'),
});
