/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { roles } from '../../constants/constants';
import { UserUpdatechema } from '../../validators/user';
import Dialog from '../../widgets/Dialog';
import TextField from '../../widgets/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBoxTwoTone';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import { useSelector } from 'react-redux';
import Button from '../../widgets/Button';
import {
  warningNoitif,
  errorNoitif,
  successNoitif,
} from '../../utils/notification';
import Countdown from 'react-countdown';
import ReactPinField from 'react-pin-field';
import checkPermission from '../../utils/checkPermission';
import { UPDATE_USER } from '../../constants/permissionMapping';
import { getOtpForUserUpdate, updateUser } from '../../api/users';

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const UpdateUser = ({
  show,
  selectedUser,
  handleClose,
  shifts,
  activeShift,
}) => {
  const resolver = yupResolver(UserUpdatechema);

  const [showOtpForm, setShowOtpForm] = useState(false);
  const [tempInfo, setTempInfo] = useState(null);
  const [postData, setPostData] = useState(null);
  const [otpValid, setOtpValid] = useState(true);
  const [savedOTP, setSavedOTP] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);

  const {
    register,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    reset,
    control,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      username: '',
      phone: '',
      password: '',
      roles: [],
      shift: '',
    },
    resolver,
  });
  const prefilledShifts = useWatch({ control, name: 'shift' });
  const prefilledRoles = useWatch({ control, name: 'roles' });
  const userStatus = useWatch({ control, name: 'userStatus' });
  const setUpdateFormValuses = () => {
    if (selectedUser) {
      const { name, email, username, shiftId, phone, userStatus } =
        selectedUser;

      const rolesFromArray = selectedUser.roles.split(',');

      let rolesObj = [];
      rolesFromArray.map((item) => {
        const role = roles.find((rl) => rl.value === item);

        rolesObj = [...rolesObj, role];
      });

      const shift = shifts.find((item) => item.id === shiftId);

      setValue('name', name);
      setValue('roles', rolesObj);
      setValue('email', email);
      setValue('username', username);
      setValue('phone', phone.slice(-11));
      setValue('shift', shift);
      setValue('userStatus', userStatus);
    }
  };

  useEffect(() => setUpdateFormValuses(), [selectedUser]);

  const checkifEmailRequired = () => {
    let emailRequired = false;
    const requiredEmialRoles = ['ADMIN', 'BM', 'MANAGER', 'SUPERVISOR'];

    prefilledRoles.map((item) => {
      if (requiredEmialRoles.includes(item.value)) {
        emailRequired = true;
      }
    });

    return emailRequired;
  };

  const handleOtpSubmission = async (otp) => {
    const {
      roles,
      name,
      email,
      username,
      phone,
      password,
      shiftId,
      userStatus,
    } = postData;
    let data = {
      name,
      email,
      username,
      phone,
      shiftId,
      userStatus,
      roles,
    };

    if (password.length > 0) {
      data = { ...data, password };
    }

    const response = await updateUser({
      uid: selectedUser.uid,
      data,
      username: auth.user.username,
      sessionId: savedOTP,
      phone: auth.user.phone,
      otpToken: otp,
    });

    if (response) {
      setTempInfo(null);
      setPostData(null);
      setShowOtpForm(false);
      successNoitif('User updated');
    } else {
      errorNoitif('Failed to verify user');
    }
    handleClose();
  };

  const formSubmit = async () => {
    const otp = await getOtpForUserUpdate();
    if (otp) {
      setSavedOTP(otp);
      setShowOtpForm(true);
    } else {
      errorNoitif('Failed to make update request');
    }
  };

  const handleSave = async (data) => {
    const {
      roles: selectedRoles,
      name,
      email,
      username,
      phone,
      password,
      shift,
      userStatus,
    } = data;

    const emailRequired = checkifEmailRequired();
    const hasPermission = checkPermission(UPDATE_USER);

    if (!hasPermission) {
      warningNoitif('You do not have permission to do this');
    }
    if (emailRequired && !email) {
      setError('email', {
        type: 'required',
        message:
          'Email is required for admin, batch manager, manager & supervisor',
      });
    }
    if (!isDirty) {
      warningNoitif('You have not changed anything');
    } else {
      const formatedRoles = selectedRoles.reduce((previous, current) => {
        if (previous?.length > 0) {
          return previous + ',' + current.value;
        } else {
          return current.value;
        }
      }, '');
      const patchData = {
        name,
        email,
        password,
        phone,
        username,
        shiftId: shift.id,
        roles: formatedRoles,
        userStatus,
      };
      setPostData(patchData);
    }
  };

  return (
    <>
      <Dialog
        open={postData}
        onClose={() => setPostData(null)}
        title="Are you sure?"
        noHandler={() => setPostData(null)}
        yesHandler={formSubmit}>
        <Typography sx={{ mt: 2 }}>
          Are you sure you want to Update this user?
        </Typography>
      </Dialog>
      <Dialog
        open={show && selectedUser}
        onClose={handleClose}
        noHandler={handleClose}
        yesHandler={handleSubmit(handleSave)}
        fullWidth
        maxWidth="sm"
        yesText="Save Changes"
        title="Update User Information">
        <Stack sx={{ mt: 2 }} spacing={2}>
          <TextField
            label="Name"
            required
            placeholder="Name of the user"
            {...register('name')}
            error={errors?.name}
            helperText={errors?.name?.message}
          />
          {/* <TextField
            label="Username"
            required
            disabled
            placeholder="Username - ie - name"
            {...register('username')}
            error={errors?.username}
            helperText={errors?.username?.message}
          /> */}
          <TextField
            label="Email"
            placeholder="User email (optional)"
            {...register('email')}
            error={errors?.email}
            helperText={errors?.email?.message}
          />
          <TextField
            label="Phone"
            required
            placeholder="Phone number  - ie - 017***"
            {...register('phone')}
            error={errors?.phone}
            helperText={errors?.phone?.message}
          />

          <Autocomplete
            options={roles}
            multiple
            value={prefilledRoles}
            disableCloseOnSelect
            onChange={(event, value) =>
              setValue('roles', value, { shouldDirty: true })
            }
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Roles"
                placeholder="Please select roles for this user"
                error={errors?.roles}
                helperText={errors?.roles?.message}
              />
            )}
          />
          <Autocomplete
            value={prefilledShifts}
            options={shifts}
            onChange={(event, value) =>
              setValue('shift', value, { shouldDirty: true })
            }
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Shift"
                placeholder="Please select shift for this user"
                error={errors?.shift}
                helperText={errors?.shift?.message}
              />
            )}
          />
          <FormControl>
            <InputLabel>Status</InputLabel>
            <Select
              {...register('userStatus')}
              value={userStatus}
              label="Status">
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">Inactive</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Password"
            placeholder="Updated password"
            {...register('password')}
          />
        </Stack>
      </Dialog>

      <Dialog
        hideButtons={true}
        onClose={() => setShowOtpForm(false)}
        open={showOtpForm}
        title={
          <Typography variant="h6" fontWeight={700} textAlign="center">
            Please enter OTP sent to your verified <br /> Email to complete user
            creation
          </Typography>
        }>
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          spacing={2}>
          <Box>
            <ReactPinField
              className="pin-field"
              length={6}
              onComplete={handleOtpSubmission}
            />
          </Box>
          {otpValid ? (
            <Countdown
              zeroPadTime={2}
              date={Date.now() + 240000}
              onComplete={() => {
                setOtpValid(false);
                warningNoitif('OTP has expired');
              }}
              renderer={({ minutes, seconds }) => (
                <Stack spacing={2} direction="row">
                  <Typography mb={15} fontSize={16} fontWeight="bold">
                    Valid till
                  </Typography>
                  <Typography fontWeight="bold" ml={15} color="red">
                    {minutes}:{seconds === 0 ? '00' : seconds}
                  </Typography>
                </Stack>
              )}
            />
          ) : (
            <Button
              color="error"
              variant="contained"
              fullWidth
              onClick={() => {
                setShowOtpForm(false);
                setTempInfo(null);
              }}>
              Try Again
            </Button>
          )}
        </Stack>
      </Dialog>
    </>
  );
};

export default UpdateUser;
