import { Stack, Typography } from '@mui/material';
import { ChannelBox } from 'planby';

export const ChannelItem = ({ channel }) => {
  const { position, name } = channel;

  return (
    <ChannelBox {...position} style={{ width: '5vw' }}>
      <Stack
        direction="row"
        width="100%"
        justifyContent="flex-start"
        alignItems="center">
        <Typography fontWeight={800} fontSize={17}>
          {name}
        </Typography>
      </Stack>
    </ChannelBox>
  );
};

export default ChannelItem;
