import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogTitle, Stack, TextField } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { createProcess } from '../../api/client';
import { successNoitif } from '../../utils/notification';
import { ProcessCreationValidator } from '../../validators/client';

const CreateProcessForm = ({
  show,
  toggleModal,
  isProcessAdded,
  setIsProcessAdded,
}) => {
  const resolver = yupResolver(ProcessCreationValidator);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      processName: '',
      shortName: '',
    },
    resolver,
  });
  const onClose = () => {
    handleCreationFormClose();
  };
  const handleCreationFormClose = () => {
    reset({
      processName: '',
      shortName: '',
    });

    toggleModal(false);
  };

  const handelProcessFormSubmission = async (data) => {
    const response = await createProcess({ data });
    if (response) {
      setIsProcessAdded(!isProcessAdded);
      toggleModal(false);
      successNoitif('Process has be added successfully');
      reset({
        processName: '',
        shortName: '',
      });
    }
  };
  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">Add New Process</DialogTitle>
        <form>
          <Stack
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 3,
              paddingBottom: 3,
            }}>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Process Name"
                required
                placeholder="Process Name"
                {...register('processName')}
                error={errors?.processName}
                helperText={errors?.processName?.message}
              />
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Short Name"
                required
                placeholder="Short Name"
                {...register('shortName')}
                error={errors?.shortName}
                helperText={errors?.shortName?.message}
              />
            </Stack>
          </Stack>
        </form>
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button
            variant="outlined"
            sx={{ borderColor: 'red' }}
            color="error"
            onClick={handleCreationFormClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(handelProcessFormSubmission)}>
            Add
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default CreateProcessForm;
