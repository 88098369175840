import {
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Tooltip,
} from '@mui/material';
import React from 'react';
import Button from '../../../widgets/Button';

const FabricAddDialog = ({
  allFabrics,
  alreadyAddedFabrics,
  update,
  show,
  onClose,
}) => {
  const isFabricAlreadyAdded = (fabric) => {
    const item = alreadyAddedFabrics.find((item) => item.id === fabric.id);
    return item ? true : false;
  };

  const handleSelection = (fabric, status) => {
    if (status === true) {
      const updatedFabris = [...alreadyAddedFabrics, fabric];
      update('fabricInfo', updatedFabris);
    } else {
      const updatedFabris = alreadyAddedFabrics.filter(
        (item) => item.id !== fabric.id,
      );
      update('fabricInfo', updatedFabris);
    }
  };

  const shouldDisableSelection = (fabric) => {
    return fabric.quantity === fabric.processed;
  };

  const isAllFabricSelected = () => {
    const allFabricsId = allFabrics.map((fabric) => fabric.id);
    const selectedFabricsId = alreadyAddedFabrics.map((fabric) => fabric.id);

    return allFabricsId.every((id) => selectedFabricsId.includes(id));
  };

  const handleAllFabricSelection = (value) => {
    if (value) {
      const fabricWithQuantityToBeProcessed = allFabrics.filter(
        (fabric) => fabric.quantity !== fabric.processed,
      );

      update('fabricInfo', fabricWithQuantityToBeProcessed);
    } else {
      update('fabricInfo', []);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={show} onClose={onClose}>
      <DialogTitle>Add Fabrics </DialogTitle>
      <DialogContent>
        <Box my={2}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={isAllFabricSelected()}
                    onChange={(event, value) => handleAllFabricSelection(value)}
                  />
                </TableCell>
                <TableCell>FC #</TableCell>
                <TableCell>Fabric Type</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Shade (%)</TableCell>
                <TableCell>Weight (KG)</TableCell>
                <TableCell>Processed (KG)</TableCell>
                <TableCell>Re Processed (KG)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allFabrics.map((fabric) => (
                <TableRow key={fabric.id}>
                  <TableCell>
                    <Checkbox
                      checked={isFabricAlreadyAdded(fabric)}
                      disabled={shouldDisableSelection(fabric)}
                      onChange={(event, value) =>
                        handleSelection(fabric, value)
                      }
                    />
                  </TableCell>
                  <TableCell>{fabric.fcNumber}</TableCell>
                  <TableCell>
                    <Tooltip arrow followCursor title={fabric.fabricType}>
                      <Typography fontSize="0.85rem">
                        {fabric.fabricType?.substring(0, 15)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip arrow followCursor title={fabric.colour}>
                      <Typography fontSize="0.85rem">
                        {fabric.colour?.substring(0, 15)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{fabric?.shade}</TableCell>
                  <TableCell>{fabric.quantity}</TableCell>
                  <TableCell>{fabric.processed}</TableCell>
                  <TableCell>{fabric.reProcessed}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}>
          <Button color="error" onClick={onClose} variant="outlined">
            Close
          </Button>
          <Button variant="contained" onClick={onClose}>
            Save
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FabricAddDialog;
