import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import OtpFrom from '../../components/auth/signin/OtpFrom';
import SigninForm from '../../components/auth/signin/SigninForm';
import { DASHBOARD } from '../../constants/routeUrls';
import Page from '../../widgets/Page';

const SignIn = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const tempUserData = useSelector((state) => state.auth.tempUserData);
  if (isLoggedIn) {
    return <Navigate to={DASHBOARD} />;
  } else {
    return <Page>{tempUserData ? <OtpFrom /> : <SigninForm />}</Page>;
  }
};

export default SignIn;
