import React from 'react';
import {
  Stack,
  Typography,
  Chip,
  IconButton,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditTwoTone';
import { MachineBatchno } from './MachineBatchno';

import { EditMachine } from './EditMachine';
import { useState } from 'react';
import { machineColorMapper } from '../../utils/machine';
import QRCode from 'react-qr-code';
import { RefreshTwoTone as Refresh } from '@mui/icons-material';
import Button from '../../widgets/Button';
import { generateMachineQrCode } from '../../api/machine';
import { Link } from 'react-router-dom';
import { MACHINE_DETAILS } from '../../constants/routeUrls';
import { VisibilityTwoTone as Visibility } from '@mui/icons-material';
import PermissionWrapper from '../../pages/wrapper/PermissionWrapper';

const MachineItem = ({ machine, update, isUpdated, setIsUpdated }) => {
  const [showEditMachineForm, setShowEditMachineForm] = useState(false);
  const [showQrCodeReGenerationDialog, setShowQrCodeReGEnerationDIalog] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleQrCodeGeneration = async () => {
    setIsLoading(true);
    const response = await generateMachineQrCode(machine.name);
    setShowQrCodeReGEnerationDIalog(false);
    setIsLoading(false);
    if (response) {
      update((machines) =>
        machines.map((item) =>
          item.name === machine.name ? { ...item, qrId: response } : item,
        ),
      );
    }
  };

  return (
    <Stack
      direction="column"
      sx={{
        height: 'auto',
        minHeight: 250,
        width: '100%',
        maxWidth: { md: 320, xs: '100%' },
        mr: 2,
        mb: 2,
        borderRadius: 1.5,
      }}>
      <Dialog
        open={showQrCodeReGenerationDialog}
        onClose={() => setShowQrCodeReGEnerationDIalog(false)}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to re generate QR code for &apos;
            {machine.name}&apos;. If so the QR code currently being used will be
            invalid
          </Typography>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => setShowQrCodeReGEnerationDIalog(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              loading={isLoading}
              onClick={handleQrCodeGeneration}>
              Yes
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ background: '#171820', borderRadius: '5px 5px 0 0' }}
        flexGrow={1}
        boxSizing="border-box"
        px={1}
        py={2}>
        <Box
          px={2}
          py={1.5}
          bgcolor="#EAF6F6"
          borderRadius={1}
          sx={{ '&:hover': { boxShadow: 3, transition: '300ms ease' } }}>
          {machine ? (
            <QRCode size={80} value={`${machine.qrId}`} />
          ) : (
            <CircularProgress />
          )}
        </Box>
        <Stack spacing={1}>
          <Stack alignItems="flex-end">
            <Tooltip title={machine.name}>
              <Typography
                fontSize={{ md: '1.4rem' }}
                fontWeight={700}
                color="#fff">
                {machine.name.substring(0, 6)} {machine.name.length > 6 && '..'}
              </Typography>
            </Tooltip>
            <Chip
              size="small"
              label={machine.status}
              sx={{
                backgroundColor: machineColorMapper(machine.status).color,
                width: 'max-content',
                color: 'white',
                marginTop: 1,
                fontSize: 10,
              }}
            />
          </Stack>
          <Stack direction="row" spacing={0.8} justifyContent="flex-start">
            <IconButton
              size="small"
              title="Re generate QR code"
              aria-label="edit"
              onClick={() => setShowQrCodeReGEnerationDIalog(true)}
              sx={{
                bgcolor: '#fff',
                borderRadius: 1,
                ':hover': { bgcolor: '#f7f7f7' },
              }}>
              <Refresh />
            </IconButton>
            <PermissionWrapper acceptedRoles="MANAGER,ADMIN">
              <IconButton
                size="small"
                title="Edit"
                aria-label="edit"
                onClick={() => setShowEditMachineForm(true)}
                sx={{
                  bgcolor: '#fff',
                  borderRadius: 1,
                  ':hover': { bgcolor: '#f7f7f7' },
                }}>
                <EditIcon />
              </IconButton>
            </PermissionWrapper>
            <IconButton
              size="small"
              title="Logs"
              aria-label="log"
              component={Link}
              to={`${MACHINE_DETAILS}/${machine.name}`}
              sx={{
                bgcolor: '#fff',
                borderRadius: 1,
                ':hover': { bgcolor: '#f7f7f7' },
              }}>
              <Visibility />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          background: machineColorMapper(machine.status).backgroundColor,
          borderRadius: '0 0 5px 5px',
        }}
        direction="row"
        justifyContent="space-between"
        flexGrow={1}
        boxSizing="border-box"
        px={1}
        py={2}>
        <Stack>
          <Typography variant="h6" sx={{ fontSize: '0.85rem' }}>
            Max Capacity
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: '1.25rem', fontWeight: '800' }}>
            {machine.maxCapacity}kg
          </Typography>
        </Stack>
        <Stack>
          <MachineBatchno machine={machine} />
        </Stack>
      </Stack>
      {machine && (
        <EditMachine
          toggleModal={setShowEditMachineForm}
          show={showEditMachineForm}
          machine={machine}
          update={update}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
        />
      )}
    </Stack>
  );
};

export default MachineItem;
