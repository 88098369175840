import React from 'react';
import { useSelector } from 'react-redux';

const PermissionWrapper = ({ children, acceptedRoles }) => {
  const user = useSelector((state) => state.auth.user);
  const checkPermission = () => {
    if (!acceptedRoles) {
      return false;
    }
    const acceptedRolesArr = acceptedRoles.split(',');
    const rolesArr = user.roles.split(',');

    let hasPermission = false;

    acceptedRolesArr.map((item) => {
      if (rolesArr.includes(item)) {
        hasPermission = true;
      }
    });

    return hasPermission;
  };
  return <>{checkPermission() ? children : <></>}</>;
};

export default PermissionWrapper;
