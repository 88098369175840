export const ADD_USER = 'ADD USER';
export const UPDATE_USER = 'UPDATE USER';
export const BAN_USER = 'BAN USER';

export const ADD_SHIFTS = 'ADD_SHIFTS';
export const CLEAR_SHIFT = 'CLEAR_SHIFT';

export default {
  ADMIN: [ADD_USER, UPDATE_USER, BAN_USER, ADD_SHIFTS, CLEAR_SHIFT],
  SUPER_ADMIN: [ADD_USER, UPDATE_USER, BAN_USER, ADD_SHIFTS, CLEAR_SHIFT],
  SUPERVISOR: [],
  BM: [],
  MANAGER: [],
};
