import { formatTime } from './time';

export const tableDataReformat = (data) => {
  let tempData = JSON.parse(JSON.stringify(data));
  let modifiedData = [];
  tempData.map((dataItem) => {
    delete dataItem['fabrics'];
    delete dataItem['orders'];
    delete dataItem['id'];
    delete dataItem['clientId'];
    delete dataItem['approvedBy'];
    delete dataItem['quotationId'];
    delete dataItem['userId'];
    delete dataItem['accountId'];
    Object.entries(dataItem).map((value) => {
      if (value[0] == 'totalQuantity') {
        dataItem['totalQuantity(kg)'] = dataItem['totalQuantity'];
        delete dataItem['totalQuantity'];
      }
      if (value[0] == 'finsihedStock') {
        dataItem['finishedStock(kg)'] = dataItem['finsihedStock'];
        delete dataItem['finsihedStock'];
      }
      if (value[0] == 'isApproved') {
        if (value[1] == true) {
          dataItem.isApproved = 'YES';
        } else {
          dataItem.isApproved = 'NO';
        }
      }
      if (value[0] == 'totalFabricWeight') {
        dataItem['totalFabricWeight(kg)'] = dataItem['totalFabricWeight'];
        delete dataItem['totalFabricWeight'];
      }
      if (
        value[0] == 'estEndTime' ||
        value[0] == 'loadingTime' ||
        value[0] == 'actualStartTime' ||
        value[0] == 'createdAt' ||
        value[0] == 'deliveryDate' ||
        value[0] == 'issueDate' ||
        value[0] == 'updatedAt' ||
        value[0] == 'expiryDate' ||
        value[0] == 'paymentDate' ||
        value[0] == 'chequeDate'
      ) {
        dataItem[`${value[0]}`] = formatTime(value[1]);
      }
      if (value[0] == 'isComplete') {
        if (value[1] == true) {
          dataItem.isComplete = 'YES';
        } else {
          dataItem.isComplete = 'NO';
        }
      }
      if (value[1] == null) {
        dataItem[`${value[0]}`] = '-';
      }
    });
    const newObj = Object.fromEntries(
      Object.entries(dataItem).map(([k, v]) => [
        k
          .replace(/([A-Z])/g, ' $1')
          .trim()
          .toUpperCase(),
        v,
      ]),
    );

    modifiedData.push(newObj);
  });

  return modifiedData;
};

export const paymentTableDataReformat = (data) => {
  let tempData = JSON.parse(JSON.stringify(data));
  let modifiedData = [];
  tempData.map((dataItem) => {
    Object.entries(dataItem).map((value) => {
      if (value[0] == 'paymentDate' || value[0] == 'chequeDate') {
        dataItem[`${value[0]}`] = formatTime(value[1]);
      }

      if (value[1] == null) {
        dataItem[`${value[0]}`] = '-';
      }
      if (value[0] == 'transactionType') {
        if (value[1] != 'OTHER') {
          dataItem['reason'] = '-';
        }
      }
    });
    const newObj = Object.fromEntries(
      Object.entries(dataItem).map(([k, v]) => [
        k
          .replace(/([A-Z])/g, ' $1')
          .trim()
          .toUpperCase(),
        v,
      ]),
    );

    modifiedData.push(newObj);
  });

  return modifiedData;
};
