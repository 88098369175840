const colors = {
  info: '#4562f7',
  success: '#7FB77E',
  error: '#FF5858',
  warning: '#F1A661',
  primary: '#171820',
  secondary: '#645CAA',
};

export default colors;
