import {
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  FormatPaintTwoTone as FormatPaint,
  CheckCircleTwoTone as CheckCircle,
  BiotechTwoTone as Biotech,
  AddCircleTwoTone as Add,
  EditTwoTone as Edit,
  ListTwoTone as List,
  SearchOffTwoTone as SearchOff,
} from '@mui/icons-material';
import PageHeader from '../../widgets/PageHeader';
import Button from '../../widgets/Button';
import AddNewForm from '../process-management/AddNewForm';
import UpdateForm from '../process-management/UpdateForm';
import { fetchAllTaskForms } from '../../api/task-form';
import phaseMappig from '../../utils/phaseMappig';
import { batchPhaseColor } from '../../utils/batch';
import { GenerateProcessIcon } from '../../utils/settings';

const ProcessManagement = () => {
  const [showAddProcessForm, setShowAddProcessForm] = React.useState(false);
  const [selectedForm, setSelectedForm] = React.useState(null);
  const [taskForms, setTaskForms] = React.useState([]);
  const [taskNameQuery, setTaskNameQuery] = React.useState('');
  const [taskTypeQuery, setTaskTypeQuery] = React.useState('');

  const fetchData = async (taskName, taskType) => {
    const response = await fetchAllTaskForms(taskName, taskType);
    setTaskForms(response);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  useEffect(() => {
    (async () => {
      if (taskNameQuery.length > 2 || taskNameQuery?.length <= 0) {
        await fetchData(taskNameQuery, taskTypeQuery);
      }
    })();
  }, [taskNameQuery]);

  useEffect(() => {
    (async () => await fetchData(taskNameQuery, taskTypeQuery))();
  }, [taskTypeQuery]);

  return (
    <Stack width={{ md: '75%', xs: '100%' }} height="auto" minHeight="90vh">
      <PageHeader>Process</PageHeader>
      <Stack
        width="100%"
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 2 }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1.5}
          width="100%">
          <FormControl fullWidth sx={{ maxWidth: 180 }} size="small">
            <InputLabel>Process Type</InputLabel>
            <Select
              value={taskTypeQuery}
              label="Process Type"
              hiddenLabel
              onChange={(event) => setTaskTypeQuery(event.target.value)}>
              <MenuItem value="">
                <Stack direction="row" spacing={1.5}>
                  <List />
                  <Typography fontSize="0.8rem" fontWeight={700}>
                    All
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem value="DYEING">
                <Stack direction="row" spacing={1.5}>
                  <FormatPaint />
                  <Typography fontSize="0.8rem" fontWeight={700}>
                    Dyeing
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem value="FINISHING">
                <Stack direction="row" spacing={1.5}>
                  <CheckCircle />
                  <Typography fontSize="0.8rem" fontWeight={700}>
                    Finishing
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem value="QC">
                <Stack direction="row" spacing={1.5}>
                  <Biotech />
                  <Typography fontSize="0.8rem" fontWeight={700}>
                    Quality Check
                  </Typography>
                </Stack>
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            label="Search"
            placeholder="Search process by name"
            fullWidth
            sx={{ minWidth: 180, maxWidth: 250 }}
            value={taskNameQuery}
            onChange={(event) => setTaskNameQuery(event.target.value)}
          />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            onClick={() => setShowAddProcessForm(true)}
            endIcon={<Add />}
            variant="contained"
            sx={{ width: 'max-content' }}>
            Add New
          </Button>
        </Stack>
      </Stack>
      <AddNewForm
        toggleForm={setShowAddProcessForm}
        open={showAddProcessForm}
        update={setTaskForms}
      />
      {selectedForm && (
        <UpdateForm
          open={selectedForm}
          toggleForm={setSelectedForm}
          formValue={selectedForm}
          update={setTaskForms}
        />
      )}

      <Stack
        width="100%"
        direction="row"
        flexWrap="wrap"
        sx={{ mt: 5 }}
        alignItems="center"
        justifyContent="flex-start">
        {taskForms.length > 0 ? (
          taskForms.map((taskForm, index) => (
            <Stack
              key={index}
              boxSizing="border-box"
              px={2}
              py={1}
              width={{ xs: '100%', md: '32.3%' }}
              borderRadius={1.5}
              border="2px solid rgba(0,0,0,0.2)"
              sx={{ mr: 1, mb: 1 }}
              spacing={0.5}>
              <Tooltip title={taskForm.taskName} placement="top">
                <Typography variant="h6" fontWeight={700}>
                  {taskForm?.taskName?.length < 20
                    ? taskForm.taskName
                    : `${taskForm.taskName.substring(0, 20)}...`}
                </Typography>
              </Tooltip>
              {/* generateProcessIcon */}
              <Stack
                direction="row"
                spacing={0.5}
                justifyContent="flex-start"
                alignItems="center">
                {taskForm?.formFields.map((formField, index) => (
                  <Stack key={index}>
                    {GenerateProcessIcon(formField.fieldType)}
                  </Stack>
                ))}
              </Stack>

              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}>
                <Chip
                  label={phaseMappig(taskForm.taskType)}
                  color={batchPhaseColor(taskForm.taskType)}
                />
                <IconButton
                  onClick={() => setSelectedForm(taskForm)}
                  size="small"
                  sx={{ bgcolor: 'grey.300' }}>
                  <Tooltip arrow title="Edit Details">
                    <Edit />
                  </Tooltip>
                </IconButton>
              </Stack>
            </Stack>
          ))
        ) : (
          <Stack
            width="100%"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            mt={10}>
            <SearchOff sx={{ width: 64, height: 64 }} />
            <Typography variant="h5" fontWeight={700}>
              No Process Templates
            </Typography>
            <Typography variant="body1" fontWeight={500}>
              No templates found for the selected criteria.
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default ProcessManagement;
