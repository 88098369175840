import React from 'react';
import { Outlet } from 'react-router-dom';
import Page from '../widgets/Page';

const PublicLayout = () => {
  return (
    <Page>
      <Outlet />
    </Page>
  );
};

export default PublicLayout;
