import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import RootRoute from './routes/RootRoute';
import { persistor, store } from './store';
import theme from './widgets/theme';
import { Toaster } from 'react-hot-toast';
const Root = () => {
  return (
    <PersistGate persistor={persistor} loading={null}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RootRoute />
          <Toaster />
        </ThemeProvider>
      </Provider>
    </PersistGate>
  );
};

export default Root;
