import { Divider, Grid, Stack, Typography } from '@mui/material';

import React from 'react';

import { convertNumberToWords, formatNumber } from '../../utils/number';
// import { setOrderBatchBackgroundColor } from '../../utils/account';

import BatchTaskChemicalDetails from './BatchTaskChemicalDetails';
import '../../styles/batch-account-print.css';

const OrderBatchDetails = ({ batch }) => {
  //set background color for status

  const calcaulateBatchWeight = (fabricList) => {
    let totalWeight = 0;
    fabricList.forEach((fabric) => {
      totalWeight += fabric.batchFabricAmount;
    });
    return totalWeight;
  };

  const calculateTaskCost = (fabricList) => {
    let totalCost = 0;
    fabricList.forEach((singleRecipe) => {
      totalCost += singleRecipe?.totalCost;
    });
    return totalCost;
  };

  const calcaulateBatchAmount = (taskInfo) => {
    let amount = 0;
    taskInfo.forEach((fabric) => {
      amount += fabric.batchFabricAmount * fabric.rate;
    });
    return amount;
  };

  const calculateTaskQuantity = (taskInfo) => {
    let totalQuantity = 0;
    taskInfo.forEach((singleRecipe) => {
      singleRecipe.recipe.forEach((chemical) => {
        totalQuantity += chemical.total * 0.001;
      });
    });
    return totalQuantity.toFixed(2);
  };

  return (
    <>
      <Stack
        direction="column"
        sx={{
          height: 'auto',
          minHeight: 250,
          width: '100%',
          // maxWidth: { md: 500, xs: '100%' },
          border: '1px solid gray',
          borderStyle: 'dashed',
          mr: 2,
          mb: 2,
          p: 2,
          borderRadius: 1.5,
        }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          {/* upper information   */}
          <Stack direction="column" justifyContent="flex-start">
            <Stack direction="row" spacing={1} pb={1} alignItems="center">
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                #{batch?.batchInfo?.lotNumber.split('-')[1]}
              </Typography>
              <Stack>
                <Typography>({batch?.batchInfo?.status})</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* new style for fabrics  */}
        <Grid container spacing={2} className="batch-orders">
          <Grid item xs={6}>
            <Stack direction="column" justifyContent="flex-start">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography fontSize={18} sx={{ fontWeight: 'bold' }}>
                  Fabric Cost:
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {/* <Grid item xs={1}></Grid> */}
          <Grid item xs={12}>
            <Stack
              width="100%"
              sx={{
                height: 'auto',
                minHeight: '100%',
                width: '100%',
                // maxWidth: { md: 500, xs: '100%' },
                border: '1px solid gray',
                borderStyle: 'dashed',
                mr: 2,
                mb: 2,
                p: 2,
                borderRadius: 1.5,
              }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={2}>
                  <Typography textAlign="center">FABRIC TYPE</Typography>
                </Grid>
                <Grid width="100%" item xs={1}></Grid>
                <Grid item xs={2}>
                  <Typography textAlign="center">QUANTITY(KG)</Typography>
                </Grid>
                <Grid width="100%" item xs={1}></Grid>
                <Grid item xs={2}>
                  <Typography textAlign="center">RATE</Typography>
                </Grid>
                <Grid width="100%" item xs={1}></Grid>
                <Grid item xs={3}>
                  <Typography textAlign="end">AMOUNT</Typography>
                </Grid>
              </Grid>
              {batch?.fabricList.map((fabric) => {
                return (
                  <Grid
                    container
                    spacing={1}
                    key={fabric?.id}
                    alignItems="center">
                    <Grid item xs={2}>
                      <Typography textAlign="center">
                        {fabric?.fabricType}
                      </Typography>
                    </Grid>
                    <Grid width="100%" item xs={1}>
                      <Stack
                        width="100%"
                        sx={{ background: 'gray', opacity: 0.2 }}>
                        <Divider></Divider>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography textAlign="center">
                        {fabric?.batchFabricAmount}{' '}
                      </Typography>
                    </Grid>
                    <Grid width="100%" item xs={1}>
                      <Stack
                        width="100%"
                        sx={{ background: 'gray', opacity: 0.2 }}>
                        <Divider></Divider>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography textAlign="center">
                        ৳{formatNumber(fabric?.rate)}
                      </Typography>
                    </Grid>
                    <Grid width="100%" item xs={2}>
                      <Stack
                        width="100%"
                        sx={{ background: 'gray', opacity: 0.2 }}>
                        <Divider></Divider>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography textAlign="end">
                        ৳
                        {formatNumber(fabric?.batchFabricAmount * fabric?.rate)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    mt={1}
                    width="100%"
                    sx={{ background: 'gray', opacity: 0.5 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography textAlign="end" fontWeight={700}>
                    Fabric Quantity:
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography textAlign="center" fontWeight={700}>
                    {calcaulateBatchWeight(batch?.fabricList)} KG
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography textAlign="end">
                    <Typography fontWeight={700} textAlign="end">
                      Fabric Amount:
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontWeight={700} textAlign="end">
                    ৳{formatNumber(calcaulateBatchAmount(batch?.fabricList))}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography fontWeight={700} fontSize={15}>
                      In Words:
                    </Typography>
                    <Typography fontSize={15}>
                      {convertNumberToWords(
                        calcaulateBatchAmount(batch?.fabricList),
                      )}
                      Only
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>

        {/* Task new design  */}
        <div className="single-recipe">
          <Stack mt={1} className="order-batch-task">
            {batch?.taskInfo.length < 1 ? (
              <></>
            ) : (
              <Stack alignItems="left" justifyContent="flex-start" width="100%">
                <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
                  Chemical Cost:
                </Typography>
                <Stack
                  alignItems="left"
                  justifyContent="flex-start"
                  width="100%">
                  <Stack
                    direction="column"
                    sx={{
                      height: 'auto',
                      minHeight: 250,
                      width: '100%',
                      // maxWidth: { md: 500, xs: '100%' },
                      border: '1px solid gray',
                      borderStyle: 'dashed',
                      mr: 2,
                      mb: 2,
                      p: 2,
                      borderRadius: 1.5,
                    }}>
                    {batch?.taskInfo.map((singleRecipe) => (
                      <Stack key={singleRecipe?.id}>
                        <BatchTaskChemicalDetails
                          singleRecipe={singleRecipe}
                          className="single-recipe"
                        />
                      </Stack>
                    ))}
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Stack
                          mt={2}
                          width="100%"
                          sx={{ background: 'gray', opacity: 0.5 }}>
                          <Divider></Divider>
                        </Stack>
                      </Grid>
                      <Grid item xs={10.5}>
                        <Typography
                          textAlign="end"
                          fontWeight={700}
                          fontSize={18}>
                          Total Cost:
                        </Typography>
                      </Grid>
                      <Grid item xs={1.5}>
                        <Typography
                          textAlign="end"
                          fontWeight={700}
                          fontSize={18}>
                          ৳{formatNumber(calculateTaskCost(batch?.taskInfo))}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography fontWeight={700} fontSize={15}>
                            Total Quantity:
                          </Typography>
                          <Typography fontSize={15}>
                            {calculateTaskQuantity(batch?.taskInfo)} KG
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography fontWeight={700} fontSize={15}>
                            In Words:
                          </Typography>
                          <Typography fontSize={15}>
                            {convertNumberToWords(
                              calculateTaskCost(batch?.taskInfo),
                            )}
                            Only
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </div>
      </Stack>
    </>
  );
};

export default OrderBatchDetails;
