import React from 'react';
import { useState, useEffect } from 'react';
import { errorNoitif, successNoitif } from '../../utils/notification';
import {
  Dialog,
  DialogTitle,
  Stack,
  TextField,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import Button from '../../widgets/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { MachineUpdateValidator } from '../../validators/machine';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { updateMachine } from '../../api/machine';

export const EditMachine = ({
  show,
  machine,
  toggleModal,
  update,
  isUpdated,
  setIsUpdated,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const resolver = yupResolver(MachineUpdateValidator);

  const handelMachineFormSubmission = () => {
    setShowConfirmation(true);
    toggleModal(false);
  };

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      capacity: '',
      maxCapacity: '',
      minCapacity: '',
      machineType: '',
      status: '',
    },
    resolver,
  });

  useEffect(() => {
    setLoading(true);
    setValue('machineType', machine.machineType);
    setValue('status', machine.status);
    setValue('name', machine.name);
    setValue('capacity', machine.capacity);
    setValue('maxCapacity', machine.maxCapacity);
    setValue('minCapacity', machine.minCapacity);

    setLoading(false);
  }, [machine]);

  const machineType = useWatch({ control, name: 'machineType' });
  const status = useWatch({ control, name: 'status' });
  const onClose = () => {
    handleUpdateFormClose();
  };

  const onCloseConfirmation = () => {
    handleCloseConfirmation();
  };

  const handleUpdateFormClose = () => {
    reset({
      name: '',
      capacity: '',
      maxCapacity: '',
      minCapacity: '',
      machineType: '',
      status: '',
    });
    toggleModal(false);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const updateMachineFormSubmit = async (data) => {
    const response = await updateMachine(data);

    if (response) {
      successNoitif('Machine has been Updated');
      setShowConfirmation(false);
      setIsUpdated(!isUpdated);
      update((machines) =>
        machines.map((machine) => {
          if (machine.name == response.name) {
            return { ...machine, ...response };
          }
          return machine;
        }),
      );
    } else {
      errorNoitif('Error occurred');
    }
  };

  return (
    <>
      <Dialog
        onClose={handleUpdateFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">Edit {machine.name}</DialogTitle>
        <form>
          {!loading && (
            <Stack
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 3,
                paddingBottom: 3,
              }}>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <TextField
                  label="Name"
                  required
                  disabled
                  placeholder="Machine Name"
                  {...register('name')}
                  error={errors?.name}
                  helperText={errors?.name?.message}
                />
              </Stack>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <TextField
                  label="Capacity"
                  required
                  placeholder="Machine Capacity"
                  {...register('capacity')}
                  error={errors?.capacity}
                  helperText={errors?.capacity?.message}
                />
              </Stack>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <TextField
                  label="Max Capacity"
                  required
                  placeholder="Machine Max Capacity"
                  {...register('maxCapacity')}
                  error={errors?.maxCapacity}
                  helperText={errors?.maxCapacity?.message}
                />
              </Stack>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <TextField
                  label="Minimum Capacity"
                  required
                  placeholder="Machine Minimum Capacity"
                  {...register('minCapacity')}
                  error={errors?.minCapacity}
                  helperText={errors?.minCapacity?.message}
                />
              </Stack>

              <Stack spacing={2} sx={{ mt: 2 }}>
                <FormControl fullWidth error={errors?.machineType}>
                  <InputLabel id="type123">Machine Type</InputLabel>
                  <Controller
                    name="machineType"
                    control={control}
                    defaultValue={machineType}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Machine Type"
                        required
                        {...register('machineType')}
                        placeholder="Select Machine Type">
                        <MenuItem value="DYEING">Dyeing Machine</MenuItem>
                        <MenuItem value="FINISHING">Finishing Machine</MenuItem>
                      </Select>
                    )}
                  />
                  {errors?.machineType && (
                    <FormHelperText>
                      {errors?.machineType?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              {/* machine status  */}
              <Stack spacing={2} sx={{ mt: 2 }}>
                <FormControl fullWidth error={errors?.status}>
                  <InputLabel id="type123">Machine Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={status}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Machine Status"
                        required
                        {...register('status')}
                        placeholder="Machine Status">
                        <MenuItem value="IDLE">Idle</MenuItem>
                        <MenuItem value="MAINTANENCE">Maintanence</MenuItem>
                        <MenuItem value="IN USE">In Use</MenuItem>
                        <MenuItem value="OUT OF ORDER">Out of Order</MenuItem>
                      </Select>
                    )}
                  />
                  {errors?.status && (
                    <FormHelperText>{errors?.status?.message}</FormHelperText>
                  )}
                </FormControl>
              </Stack>
            </Stack>
          )}
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red' }}
              color="error"
              onClick={handleUpdateFormClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!isDirty ? true : false}
              onClick={handleSubmit(handelMachineFormSubmission)}>
              Update
            </Button>
          </Stack>
        </form>
      </Dialog>
      <Stack>
        <Dialog
          onClose={onCloseConfirmation}
          open={showConfirmation}
          maxWidth="sm">
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to update this machine?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
                toggleModal(true);
              }}>
              No
            </Button>

            <Button
              variant="contained"
              onClick={handleSubmit(updateMachineFormSubmit)}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
    </>
  );
};
