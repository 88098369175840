import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { SIGNIN } from '../../constants/routeUrls';

const ProtectedPage = ({ children }) => {
  const location = useLocation();

  const isAuth = useSelector((state) => state.auth.isLoggedIn);

  if (!isAuth) {
    return <Navigate to={SIGNIN} state={{ from: location.pathname }} />;
  } else {
    return children;
  }
};

export default ProtectedPage;
