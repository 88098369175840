import {
  Dialog,
  DialogContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import Button from '../../../widgets/Button';
import BatchListItem from './BatchListItem';
import ViewportList from 'react-viewport-list';
import { PageviewTwoTone as Pageview } from '@mui/icons-material';

const BatchList = ({ batches }) => {
  const batchListRef = useRef(null);

  const [showRemarksDialog, setShowRemarksDialog] = useState(false);
  const [selecteBatch, setSelectedBatch] = useState(null);

  const openRemarksDialog = (batch) => {
    setSelectedBatch(batch);
    setShowRemarksDialog(true);
  };

  const closeRemarksDialog = () => {
    setSelectedBatch(null);
    setShowRemarksDialog(false);
  };

  return (
    <>
      {selecteBatch && (
        <Dialog open={showRemarksDialog} onClose={closeRemarksDialog}>
          <DialogContent>
            <Typography variant="h6" fontWeight={700}>
              Remarks
            </Typography>
            <Stack direction="row" spacing={1.5} sx={{ my: 2 }}>
              <Stack
                alignItems="flex-start"
                justifyContent="center"
                spacing={1}
                px={1}
                py={1}
                borderRadius={2}
                bgcolor="#f2f2f2">
                <Typography fontSize={14} fontWeight={600}>
                  Dyeing Remarks
                </Typography>
                <Typography fontSize={12} fontWeight={400} maxWidth={220}>
                  {selecteBatch?.dyeingRemark || 'N/A'}
                </Typography>
              </Stack>
              <Stack
                alignItems="flex-start"
                justifyContent="center"
                spacing={1}
                px={1}
                py={1}
                borderRadius={2}
                bgcolor="#f2f2f2">
                <Typography fontSize={14} fontWeight={600}>
                  Finishing Remarks
                </Typography>
                <Typography fontSize={12} fontWeight={400} maxWidth={220}>
                  {selecteBatch?.finishingRemark || 'N/A'}
                </Typography>
              </Stack>
              <Stack
                alignItems="flex-start"
                justifyContent="center"
                spacing={1}
                px={1}
                py={1}
                borderRadius={2}
                bgcolor="#f2f2f2">
                <Typography fontSize={14} fontWeight={600}>
                  QC Remarks
                </Typography>
                <Typography fontSize={12} fontWeight={400} maxWidth={220}>
                  {selecteBatch?.qcRemark || 'N/A'}
                </Typography>
              </Stack>
            </Stack>
            <Typography variant="h6" fontWeight={700}>
              Fabrics
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fabric Type</TableCell>
                  <TableCell>Color</TableCell>
                  <TableCell>Shade Group</TableCell>
                  <TableCell>Quantity (Kg)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selecteBatch?.fabrics?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.fabricType}</TableCell>
                    <TableCell>
                      {item.colour} ({item.shade} %)
                    </TableCell>
                    <TableCell>{item.shadeGroup}</TableCell>
                    <TableCell>{item.usedQty || item.reProcessQty}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Stack
              direction="row"
              width="100%"
              justifyContent="flex-end"
              sx={{ mt: 2 }}>
              <Button
                color="error"
                variant="outlined"
                onClick={closeRemarksDialog}>
                Close
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
      <Stack ref={batchListRef} spacing={0.5} height="59vh" overflow="auto">
        {batches?.length > 0 ? (
          <ViewportList viewportRef={batchListRef} items={batches} overscan={5}>
            {(batch, index) => (
              <BatchListItem
                batch={batch}
                key={index}
                showBatchRemarks={openRemarksDialog}
              />
            )}
          </ViewportList>
        ) : (
          <Stack
            border="2px solid rgba(0,0,0,0.2)"
            width="100%"
            height="100%"
            borderRadius={2}
            alignItems="center"
            justifyContent="center">
            <Pageview sx={{ width: 120, height: 120 }} />
            <Typography variant="h4" fontWeight={700}>
              No Batches
            </Typography>
            <Typography maxWidth={450}>No batches to show</Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default BatchList;
