import * as yup from 'yup';

export const UserCreationSchema = yup.object().shape({
  name: yup.string().min(3).required('Must enter name'),
  username: yup.string().min(6).required('Must enter username'),
  password: yup.string().min(6).required('Must enter password'),
  roles: yup
    .array()
    .of(yup.mixed())
    .min(1, 'Must select atleast one role')
    .required('Must select atleast one role'),
  shift: yup.mixed().required('Must select a shift for the user'),
  phone: yup
    .string()
    .matches(/^(?:|01)?(?:\d{9}|)$/, 'Invalid format for phone number')
    .required('Must enter phone number'),
});

export const UserUpdatechema = yup.object().shape({
  name: yup.string().min(3).required('Must enter name'),
  username: yup.string().min(4).required('Must enter username'),
  roles: yup
    .array()
    .of(yup.mixed())
    .min(1, 'Must select atleast one role')
    .required('Must select atleast one role'),
  shift: yup.mixed().required('Must select a shift for the user'),
  phone: yup
    .string()
    .matches(/^(?:|01)?(?:\d{9}|)$/, 'Invalid format for phone number')
    .required('Must enter phone number'),
});
