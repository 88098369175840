import ViewportList from 'react-viewport-list';
import { Chip, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { PageviewTwoTone as Pageview } from '@mui/icons-material';

import { fetchUserBatchLogs } from '../../api/users';
import { formatTime } from '../../utils/time';
import { setUserLogsStatusColor } from '../../constants/table-column/user';
const UserLogs = ({ uid }) => {
  const [userLogs, setUserLogs] = useState([]);
  const userLogsListRef = useRef(null);

  const fetchUserLogs = async () => {
    const response = await fetchUserBatchLogs(uid);
    setUserLogs(response.logs);
  };

  useEffect(() => {
    (async () => await fetchUserLogs())();
  }, []);

  return (
    <>
      {userLogs.length > 0 ? (
        <>
          {/* logs  */}
          <Stack>
            <Stack>
              <Stack
                ref={userLogsListRef}
                spacing={0.5}
                height="calc(100vh - 46vh)"
                overflow="auto">
                <ViewportList
                  viewportRef={userLogsListRef}
                  items={userLogs}
                  overscan={5}>
                  {(item) => (
                    <>
                      <Stack
                        key={item.uid}
                        direction="row"
                        px={1}
                        py={1}
                        borderRadius={1}
                        bgcolor="#f2f2f2"
                        mt={2}>
                        <Grid container spacing={1}>
                          <Grid item xs={3}>
                            {item?.title.length > 15 ? (
                              <Tooltip title={item?.title}>
                                <Stack>
                                  <Typography fontSize={13} fontWeight={800}>
                                    {item?.title.substr(0, 15)}...
                                  </Typography>
                                </Stack>
                              </Tooltip>
                            ) : (
                              <Typography fontSize={13} fontWeight={800}>
                                {item?.title}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={2.5}>
                            <Chip
                              label={item.type}
                              sx={{
                                color: 'white',
                                backgroundColor: setUserLogsStatusColor(
                                  item.type,
                                ),
                              }}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={3.5}>
                            {item?.message.length > 20 ? (
                              <Tooltip title={item?.message}>
                                <Stack>
                                  <Typography fontSize={12} fontWeight={400}>
                                    {item?.message.substr(0, 20)}...
                                  </Typography>
                                </Stack>
                              </Tooltip>
                            ) : (
                              <Typography fontSize={12} fontWeight={400}>
                                {item?.message}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography fontSize={12} fontWeight={400}>
                              {formatTime(item?.updatedAt)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                    </>
                  )}
                </ViewportList>
              </Stack>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack
          justifyContent="center"
          pt={10}
          width="100%"
          height="100%"
          alignItems="center">
          <Stack direction="Column" justifyContent="center" alignItems="center">
            <Pageview sx={{ width: 120, height: 120 }} />
            <Typography fontSize={18} fontWeight={800}>
              No logs
            </Typography>
            <Typography maxWidth={450}>No logs to show</Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default UserLogs;
