import dayjs from 'dayjs';

export const milisecondsToTime = (ms) => {
  let seconds = (ms / 1000).toFixed(1);
  let minutes = (ms / (1000 * 60)).toFixed(1);
  let hours = (ms / (1000 * 60 * 60)).toFixed(1);
  let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);

  if (seconds < 60) return seconds + ' s ';
  else if (minutes < 60) return minutes + ' m ';
  else if (hours < 24) return hours + ' h ';
  else return days + ' d ';
};

export const formatTime = (time) => {
  if (!time) {
    return '-';
  }
  return dayjs(time).format('DD, MMM YYYY hh:mm A');
};

export const calculateMiliseconds = (ms) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  if (days > 0) {
    return `${days}.${hours} Days`;
  } else if (hours > 0) {
    return `${hours}.${minutes} Hours`;
  } else if (minutes > 0) {
    return `${minutes}.${sec} Min`;
  } else {
    return `${sec} Sec`;
  }
};
