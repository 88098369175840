import { CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchMachineStats } from '../../api/machine';
import { setMachineNameStatsColor } from '../../constants/table-column/machine';
import { calculateMiliseconds } from '../../utils/time';
const MachineStats = ({ name }) => {
  const [statsList, setStatsList] = useState([]);

  const fetchMachineStatsList = async () => {
    const response = await fetchMachineStats(name);

    setStatsList(response.stats);
  };

  useEffect(() => {
    (async () => await fetchMachineStatsList())();
  }, []);

  const getCurrentMonth = () => {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const currentDate = new Date();
    let monthName = month[currentDate.getMonth()];
    return monthName;
  };

  return (
    <>
      <Stack
        sx={{
          height: '100%',
        }}>
        {statsList.length > 0 ? (
          <>
            <Stack
              height="100%"
              width="100%"
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              alignItems="center">
              {statsList?.map((stat) => (
                <Stack
                  px={1}
                  py={1}
                  bgcolor="#f2f2f2"
                  boxSizing="border-box"
                  borderRadius={1}
                  sx={{
                    height: '100%',
                    color: 'white',
                    borderColor: setMachineNameStatsColor(stat.status),
                  }}
                  width={220}
                  height="20vh"
                  alignItems="center"
                  justifyContent="center"
                  key={stat.id}>
                  <Typography
                    textAlign="center"
                    fontSize="1rem"
                    fontWeight={700}>
                    {stat.status}
                  </Typography>
                  <Typography
                    textAlign="center"
                    fontSize="2.0rem"
                    fontWeight={700}>
                    {calculateMiliseconds(stat.total)}
                  </Typography>
                  <Typography
                    textAlign="center"
                    fontSize="1rem"
                    fontWeight={700}>
                    {getCurrentMonth()}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </>
        ) : (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default MachineStats;
