import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { DataGrid } from '@mui/x-data-grid';
import { fetchAllOrder } from '../../api/order';
import { AllOrderApprovalListColumns } from '../../constants/table-column/order';
import OrderApprovalModal from './OrderApprovalModal';
import NoDataOverlay from '../global/NoDataOverlay';
const OrderApproval = ({ isUpdated, setIsUpdated }) => {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);
  const [page, setPage] = useState(0);
  const [fcNumber, setFcNumber] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [requestData, setRequestData] = useState({});
  const textInput = React.useRef(null);
  const [showOrderModal, setShowOrderModal] = useState(false);

  const fetchOrders = async (fcNumber, page, pageSize) => {
    setIsLoading(true);
    const response = await fetchAllOrder({
      fcNumber: fcNumber,
      status: '',
      isApproved: false,
      page: page,
      pageSize: pageSize,
    });
    setOrderList(response.orders);
    setTotalEntries(response.total);
    setIsLoading(false);
  };

  const handleOrderSubmit = (event) => {
    event.preventDefault();
    setFcNumber(fcNumber);
    fetchOrders(fcNumber, page, pageSize);
  };

  const resetOrders = () => {
    fetchOrders('', page, pageSize);
    setFcNumber('');
    textInput.current.value = '';
  };

  const handleEditModal = (value) => {
    setRequestData(value);
    setShowOrderModal(true);
  };

  useEffect(() => {
    (async () => {
      await fetchOrders();
    })();
  }, []);

  useEffect(() => {
    (async () => await fetchOrders(fcNumber, page, pageSize))();
  }, [isUpdated, page, pageSize]);

  return (
    <Stack>
      <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1}>
        Orders
      </Typography>
      <Stack my={1} width="100%">
        <Grid container width="100%">
          <Grid item xs={12} sm={7} md={8}>
            <form onSubmit={handleOrderSubmit}>
              <Stack
                direction={{ md: 'row', xs: 'column', sm: 'row' }}
                width="100%"
                spacing={2}
                alignItems="left"
                justifyContent="left">
                <TextField
                  size="small"
                  placeholder="Fc Number"
                  onChange={(event) => setFcNumber(event.target.value)}
                  inputRef={textInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  flexWrap="wrap"
                  fullWidth
                  id="outlined-basic"
                  label="Search"
                  sx={{ maxWidth: 250 }}
                  variant="outlined"
                />
                {/* Search button  */}
                <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
                  <IconButton
                    title="Edit"
                    aria-label="edit"
                    type="submit"
                    sx={{
                      bgcolor: '#171820',
                      color: 'white',
                      borderRadius: 1,
                      ':hover': { bgcolor: '#303030' },
                    }}>
                    <SearchIcon />
                  </IconButton>
                  {/* reset button  */}
                  <IconButton
                    title="Edit"
                    aria-label="edit"
                    onClick={() => resetOrders()}
                    sx={{
                      bgcolor: '#171820',
                      color: 'white',
                      borderRadius: 1,
                      ':hover': { bgcolor: '#303030' },
                    }}>
                    <RestartAltIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </form>
          </Grid>
          <Grid item xs={12} sm={5} md={4}></Grid>
        </Grid>

        {/* Table section */}
        {!orderList ? (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Box
              pt={1}
              width="100%"
              height={450}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={AllOrderApprovalListColumns({ handleEditModal })}
                rows={orderList}
                rowCount={totalEntries}
                page={page}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                paginationMode="server"
                filterMode="server"
                onPageChange={(value) => setPage(value)}
                onPageSizeChange={(value) => setPageSize(value)}
                loading={isLoading}
                components={{
                  NoRowsOverlay: NoDataOverlay,
                }}
              />
            </Box>
          </>
        )}
      </Stack>
      <OrderApprovalModal
        show={showOrderModal}
        toggleModal={setShowOrderModal}
        data={requestData}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}></OrderApprovalModal>
    </Stack>
  );
};

export default OrderApproval;
