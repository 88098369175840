import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
const fileTypes = ['CSV', 'csv'];
const CsvFileUploader = ({ setFile }) => {
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
  );
};

export default CsvFileUploader;
