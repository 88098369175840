import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const Clock = ({ label }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date()), 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  return (
    <Stack
      width={label != 'Upcoming Tasks' ? '180px' : '220px'}
      px={2}
      py={1}
      borderRadius={2}
      bgcolor="#182747"
      color="#fff">
      {label && (
        <Typography fontSize={18} fontWeight={600}>
          {label}
        </Typography>
      )}
      <Typography variant="h6" fontWeight={800}>
        {dayjs(currentTime).format('hh:mm A')}
      </Typography>
      <Typography fontSize={14} fontWeight={400}>
        {dayjs(currentTime).format('DD, MMMM YYYY')}
      </Typography>
    </Stack>
  );
};

export default Clock;
