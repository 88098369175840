import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '../../widgets/Button';
import NoData from '../global/NoData';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import PageHeader from '../../widgets/PageHeader';
import TextField from '../../widgets/TextField';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { ShiftGenerateSchema } from '../../validators/shift';
import { createShifts, deleteAllShifts, fetchAllShifts } from '../../api/shift';
import checkPermission from '../../utils/checkPermission';
import { ADD_SHIFTS, CLEAR_SHIFT } from '../../constants/permissionMapping';
import { warningNoitif } from '../../utils/notification';
import Dialog from '../../widgets/Dialog';

const ShiftManagement = () => {
  const generateFormResolver = yupResolver(ShiftGenerateSchema);
  const [isLoadedFromDb, setIsLoadedFromDb] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [showClearDialog, setShowClearDialog] = useState(false);

  const {
    control,
    setValue,
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      shiftCount: '',
      hoursPerShift: '',
      startTime: null,
    },
    mode: 'onChange',
    resolver: generateFormResolver,
  });

  const {
    control: shiftControl,
    setValue: setShiftsValue,
    register: shiftRegister,
    formState: { errors: shiftErrors },
    handleSubmit: submitShiftForm,
  } = useForm({
    defaultValues: { shifts: [] },
    mode: 'onChange',
  });
  const { fields } = useFieldArray({ control: shiftControl, name: 'shifts' });

  const fetchShift = async () => {
    setIsloading(true);
    const response = await fetchAllShifts();
    if (response && response?.length > 0) {
      setShiftsValue('shifts', response);
      setIsLoadedFromDb(true);
    }
    setIsloading(false);
  };

  useEffect(() => {
    (async () => fetchShift())();
  }, []);

  const shifts = useWatch({ control: shiftControl, name: 'shifts' });
  const startTime = useWatch({ control, name: 'startTime' });

  const generateShifts = (data) => {
    const { shiftCount, hoursPerShift, startTime } = data;
    const count = parseInt(shiftCount);
    const hours = parseInt(hoursPerShift);

    const total = count * hours;

    if (total > 24) {
      setError('hoursPerShift', {
        type: 'required',
        message: 'total hour per shift cannot be more than 24h',
      });
    } else {
      let generatedShifts = [];
      let totalTimeLeft = 24;
      let index = 0;

      while (totalTimeLeft >= hours && index !== count) {
        let shift = {
          name: `Shift ${index + 1}`,
          hoursPerShift: hours,
        };
        if (index === 0) {
          const endTime = dayjs(startTime).add(hours, 'hour');
          shift = {
            ...shift,
            startTime,
            endTime,
          };
          generatedShifts.push(shift);
        } else {
          const item = generatedShifts[index - 1];
          const start = item.endTime;
          const endTime = dayjs(start).add(hours, 'hour');
          shift = {
            ...shift,
            startTime: start,
            endTime,
          };

          generatedShifts.push(shift);
        }

        index = index + 1;
        totalTimeLeft = totalTimeLeft - hours;
      }

      setShiftsValue('shifts', generatedShifts);
    }
  };

  const removeShifts = async () => {
    if (isLoadedFromDb) {
      const hasPermission = checkPermission(CLEAR_SHIFT);
      if (hasPermission) {
        const response = await deleteAllShifts();
        if (response) {
          setShiftsValue('shifts', []);
          setIsLoadedFromDb(false);
        }
      } else {
        warningNoitif('You do not have permission to remove shifts');
      }
    } else {
      setShiftsValue('shifts', []);
    }
    setShowClearDialog(false);
  };

  const saveShifts = async (data) => {
    const { shifts } = data;

    const hasPermission = checkPermission(ADD_SHIFTS);
    if (hasPermission) {
      const response = await createShifts(shifts);
      if (response) {
        setShiftsValue('shifts', response);
        setIsLoadedFromDb(true);
      }
    } else {
      warningNoitif('You do not have permission to remove shifts');
    }
  };

  return (
    <Box minWidth={300}>
      <Dialog
        title="Are you sure?"
        open={showClearDialog}
        onClose={() => setShowClearDialog(false)}
        noHandler={() => setShowClearDialog(false)}
        yesHandler={removeShifts}>
        <Typography sx={{ mt: 0.5 }}>
          Are you sure you want to clear shifts
        </Typography>
      </Dialog>
      {isLoading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={2}>
            {shifts.length > 0 && (
              <>
                <Stack
                  width="100%"
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="space-between">
                  <PageHeader mb={2} textAlign="center">
                    Shifts
                  </PageHeader>
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="flex-end"
                    spacing={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setShowClearDialog(true)}>
                      Clear
                    </Button>
                    {!isLoadedFromDb && (
                      <Button
                        variant="contained"
                        onClick={submitShiftForm(saveShifts)}>
                        Save
                      </Button>
                    )}
                  </Stack>
                </Stack>
                {fields.map((field, index) => (
                  <Stack
                    key={JSON.stringify(field)}
                    width="100%"
                    direction="row"
                    alignItems="flex-start"
                    spacing={2}>
                    <TextField
                      InputProps={{
                        readOnly: isLoadedFromDb,
                      }}
                      label="Name"
                      placeholder="Name of the shift"
                      sx={{ flexGrow: 1 }}
                      {...shiftRegister(`shifts.${index}.name`)}
                      error={shiftErrors?.shifts?.[index]?.name}
                      helperText={shiftErrors?.shifts?.[index]?.name?.message}
                    />
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      label="Hours Per Shift"
                      placeholder="Hours per shift"
                      sx={{ flexGrow: 1 }}
                      {...shiftRegister(`shifts.${index}.hoursPerShift`)}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={field.startTime}
                        readOnly
                        label="Start Time"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={field.endTime}
                        readOnly
                        label="End Time"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                ))}
              </>
            )}
          </Stack>

          {shifts.length <= 0 && (
            <Stack
              width="100%"
              alignItems="center"
              justifyContent="center"
              spacing={3}>
              <NoData
                title="No Shift Added"
                description="No shift has been added yet start by adding your first shift"
              />
              <Stack
                direction="row"
                spacing={2}
                alignItems="flex-start"
                width="100%">
                <TextField
                  label="Shift Count"
                  placeholder="Number of shifts"
                  type="number"
                  sx={{ flexGrow: 1 }}
                  {...register('shiftCount')}
                  error={errors?.shiftCount}
                  helperText={errors?.shiftCount?.message}
                />
                <TextField
                  label="Hour Per Shift"
                  placeholder="Number of hours per shift"
                  type="number"
                  sx={{ flexGrow: 1 }}
                  {...register('hoursPerShift')}
                  error={errors?.hoursPerShift}
                  helperText={errors?.hoursPerShift?.message}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    onChange={(value) => setValue('startTime', value)}
                    value={startTime}
                    label="Start Time"
                    placeholder="Start time of first shift"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors?.startTime}
                        helperText={errors?.startTime?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack
                direction="row"
                alignItems="flex-start"
                width="100%"
                justifyContent="center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSubmit(generateShifts)}>
                  Generate Shifts
                </Button>
              </Stack>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};

export default ShiftManagement;
