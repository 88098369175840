import { Box } from '@mui/system';
import React from 'react';

const Screen = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100vw',
      }}>
      {children}
    </Box>
  );
};

export default Screen;
