import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClientReport from '../../components/report/ClientReport';
import ProductionReport from '../../components/report/ProductionReport';
import { REPORT } from '../../constants/routeUrls';
import PageTitle from '../../utils/PageTitle';
import PageHeader from '../../widgets/PageHeader';
import Tab from '../../widgets/Tab';
import Tabs from '../../widgets/Tabs';

const Reports = () => {
  //for tab section
  let navigate = useNavigate();
  const { tab: tabParams } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(parseInt(tabParams));
  }, [tabParams]);

  const tabs = [
    {
      Element: <ProductionReport />,
    },
    {
      Element: <ClientReport />,
    },
  ];

  const currentTab = tabs[activeTab];
  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
    navigate(`${REPORT}/${tab}`);
  };

  return (
    <Stack
      spacing={2}
      width="100%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <PageTitle title="Reports" />
      {/* header section  */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        spacing={2}>
        <PageHeader>Reports</PageHeader>
      </Stack>
      {/* tab section  */}
      <Stack mt={4}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ width: 'max-content' }}>
          <Tab label={'PRODUCTION'} />
          <Tab label={'CLIENT'} disabled />
        </Tabs>
      </Stack>
      <Stack width="100%" height={450}>
        {currentTab.Element}
      </Stack>
    </Stack>
  );
};

export default Reports;
