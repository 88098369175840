import Request from './request';

export const fetchAllTaskForms = async (taskName, taskType) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/taskform/', { taskName, taskType });
  if (response) {
    return response.taskForms;
  }
  return [];
};

export const createNewTaskForm = async (formData) => {
  const axiosV2 = new Request(2);

  const { taskName, taskType, formFields, isColorTemplate } = formData;
  const response = await axiosV2.Post('/taskform/', {
    taskForm: { taskName, taskType, formFields, isColorTemplate },
  });
  if (response) {
    return response.taskForm;
  }
  return null;
};

export const updateTaskForm = async (formData) => {
  const axiosV2 = new Request(2);

  const { taskName, taskType, formFields, uid, isColorTemplate } = formData;
  const response = await axiosV2.Patch('/taskform/', {
    taskForm: { taskName, taskType, formFields, uid, isColorTemplate },
  });
  if (response) {
    return response.taskForm;
  }
  return null;
};
