import {
  DoneAllTwoTone,
  ReportTwoTone,
  WarningTwoTone,
} from '@mui/icons-material';
import colors from '../constants/colors';

export const utilityScaleNumberToStringMapper = (scale) => {
  if (scale <= 3) {
    return 'Not Available';
  } else if (scale > 3 && scale <= 7) {
    return 'Running Low';
  } else {
    return 'Avalilable';
  }
};

export const utilityScaleColorMapper = (scale) => {
  if (scale <= 3) {
    return 'error';
  } else if (scale > 3 && scale <= 7) {
    return 'warning';
  } else {
    return 'success';
  }
};

export const utilityScaleIconMapper = (scale) => {
  if (scale <= 3) {
    return <ReportTwoTone sx={{ fill: colors.error }} />;
  } else if (scale > 3 && scale <= 7) {
    return <WarningTwoTone sx={{ fill: colors.warning }} />;
  } else {
    return <DoneAllTwoTone sx={{ fill: colors.success }} />;
  }
};
