import {
  Chip,
  Stack,
  Tooltip,
  Typography,
  LinearProgress,
  IconButton,
} from '@mui/material';
import Button from '../../widgets/Button';
import { Box } from '@mui/system';
// import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  VisibilityTwoTone as Visibility,
  EditTwoTone as Edit,
} from '@mui/icons-material';

import { Link } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  CLIENT_DETAILS,
  EDIT_QUOTATION,
  ORDER_DETAILS,
  PRINT_DELIVERY,
  UPDATE_DELIVERY,
} from '../routeUrls';
import dayjs from 'dayjs';
import {
  setDeliveryBackgroundColor,
  setOrderStatusColor,
} from '../../utils/order';
import {
  setClientStatusColor,
  setQuotationBackgroundColor,
} from '../../utils/client';

export const AllListColumns = [
  {
    field: 'ACTIONS',
    headerName: '',
    headerClassName: 'table-header-upperCase',
    width: 70,
    type: 'date',
    renderCell: (params) => (
      <Stack direction="row" spacing={1}>
        <IconButton
          size="small"
          component={Link}
          to={`${CLIENT_DETAILS}/${params.row.uid}`}>
          <Visibility sx={{ width: 18, height: 18 }} />
        </IconButton>
      </Stack>
    ),
  },
  {
    field: 'uid',
    headerName: 'UID',
    headerClassName: 'table-header-upperCase',
    width: 150,
    filterable: false,
    sortable: false,
  },
  {
    field: 'clientName',
    headerName: 'CLIENT NAME',
    headerClassName: 'table-header-upperCase',
    width: 250,
    renderCell: (params) => {
      if (params?.value?.length > 10) {
        <Tooltip title={params?.value}>
          return <Typography>{params?.value.substr(0, 20)}...</Typography>;
        </Tooltip>;
      } else {
        return <Typography>{params?.value}</Typography>;
      }
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'STATUS',
    headerClassName: 'table-header-upperCase',
    width: 150,
    renderCell: (params) => {
      const status = params?.value;

      return (
        <Chip
          size="small"
          label={status}
          sx={{ backgroundColor: setClientStatusColor(status), color: '#ffff' }}
        />
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'shortCode',
    headerName: 'SHORT CODE',
    headerClassName: 'table-header-upperCase',
    width: 180,

    filterable: false,
    sortable: false,
  },
  {
    field: 'contactPerson',
    headerName: 'CONTACT PERSON',
    headerClassName: 'table-header-upperCase',
    width: 220,

    filterable: false,
    sortable: false,
  },
  {
    field: 'contactNumber',
    headerName: 'CONTACT NUMBER',
    headerClassName: 'table-header-upperCase',
    width: 220,

    filterable: false,
    sortable: false,
  },
];

export const AllDeliveryListColumns = [
  {
    field: 'ACTIONS',
    headerName: '',
    width: 80,
    type: 'date',
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        {params.row.status == 'WAITING FOR APPROVAL' ? (
          <Button component={Link} to={`${UPDATE_DELIVERY}/${params.row.uid}`}>
            <Visibility />
          </Button>
        ) : (
          <Button
            size="small"
            component={Link}
            to={`${PRINT_DELIVERY}/${params.row.uid}`}>
            <Edit />
          </Button>
        )}
      </Stack>
    ),
  },
  {
    field: 'uid',
    headerName: 'DELIVERY #',
    width: 150,
    filterable: false,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'STATUS',
    width: 200,
    renderCell: (params) => {
      const status = params?.value;
      return (
        <Chip
          size="small"
          label={status}
          sx={{
            backgroundColor: setDeliveryBackgroundColor(status),
            color: '#ffff',
          }}
        />
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'totalDeliveryQuantity',
    headerName: 'QUANTITY(KG)',
    width: 120,
    filterable: false,
    sortable: false,
  },
  {
    field: 'deliveryContatctNumber',
    headerName: 'CONTACT NUMBER',
    width: 150,
    filterable: false,
    sortable: false,
  },
  {
    field: 'deliveryContatct',
    headerName: 'CONTACT PERSON',
    width: 150,
    filterable: false,
    sortable: false,
  },
  {
    field: 'deliveryAddress',
    headerName: 'DELIVERY ADDRESS',
    width: 150,
    filterable: false,
    sortable: false,
  },
  {
    field: 'deliveryDate',
    headerName: 'DELIVERY DATE',
    width: 170,
    type: 'date',
    renderCell: (params) => {
      if (params.value) {
        return params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`;
      } else {
        return 'Invalid Date';
      }
    },
  },
];

export const AllQuotationListColumns = [
  {
    field: 'ACTIONS',
    headerName: '',
    width: 70,
    type: 'date',
    renderCell: (params) => (
      <Stack direction="row" spacing={1}>
        <IconButton
          size="small"
          component={Link}
          to={`${EDIT_QUOTATION}/${params.row.uid}`}>
          <Visibility sx={{ width: 18, height: 18 }} />
        </IconButton>
      </Stack>
    ),
  },
  {
    field: 'uid',
    headerName: 'QUOTATION NO.',

    width: 150,
    filterable: false,
    sortable: false,
  },

  {
    field: 'issueDate',
    headerName: 'ISSUE DATE',
    width: 170,
    type: 'date',
    renderCell: (params) => {
      if (params.value) {
        return params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`;
      } else {
        return 'Invalid Date';
      }
    },
  },
  {
    field: 'expiryDate',
    headerName: 'VALID TILL',
    width: 170,
    type: 'date',
    renderCell: (params) => {
      if (params.value) {
        return params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`;
      } else {
        return 'Invalid Date';
      }
    },
  },
  {
    field: 'status',
    headerName: 'STATUS',
    width: 200,
    renderCell: (params) => {
      const status = params?.value;

      return (
        <Chip
          size="small"
          label={status}
          sx={{
            backgroundColor: setQuotationBackgroundColor(status),
            color: '#ffff',
          }}
        />
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'issuedBy',
    headerName: 'ISSUED BY',
    width: 220,
    renderCell: (params) => {
      if (params?.value?.length > 10) {
        <Tooltip title={params?.value}>
          return{' '}
          <Typography fontSize="small">
            {params?.value.substr(0, 20)}...
          </Typography>
          ;
        </Tooltip>;
      } else if (params?.value?.length > 1) {
        return <Typography fontSize={0.1}>{params?.value}</Typography>;
      } else {
        return <Typography fontSize={15}>Not Provided</Typography>;
      }
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'approvedBy',
    headerName: 'APPROVED BY',
    width: 220,
    renderCell: (params) => {
      if (params?.value?.length > 10) {
        <Tooltip title={params?.value}>
          return{' '}
          <Typography fontSize="small">
            {params?.value.substr(0, 20)}...
          </Typography>
          ;
        </Tooltip>;
      } else if (params?.value?.length > 1) {
        return <Typography fontSize="small">{params?.value}</Typography>;
      } else {
        return (
          <Typography fontSize={15} fontWeight="regular">
            Not Approved{' '}
          </Typography>
        );
      }
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'process',
    headerName: 'PROCESS ',
    width: 150,
    filterable: false,
    sortable: false,
  },
];

export const AllQuotationApprovalListColumns = ({ handleEditModal }) => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 80,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}>
          <IconButton
            size="small"
            component={Link}
            to={`${EDIT_QUOTATION}/${params.row.uid}`}>
            <Visibility sx={{ width: 18, height: 18 }} />
          </IconButton>
          <IconButton
            size="small"
            disabled={
              params.row.status != 'WAITING FOR APPROVAL' ? true : false
            }
            onClick={() => handleEditModal(params.row)}
            style={{
              paddingLeft: '0px !important',
              marginLeft: '0px !important',
              paddingRight: '0px !important',
              marginRight: '0px !important',
            }}>
            <CheckCircleIcon sx={{ width: 18, height: 18 }}></CheckCircleIcon>
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'uid',
      headerName: 'QUOTATION NO.',
      width: 150,
      filterable: false,
      sortable: false,
    },

    {
      field: 'issueDate',
      headerName: 'ISSUE DATE',
      width: 170,
      type: 'date',
      renderCell: (params) => {
        if (params.value) {
          return (
            params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`
          );
        } else {
          return 'Invalid Date';
        }
      },
    },
    {
      field: 'expiryDate',
      headerName: 'VALID TILL',
      width: 170,
      type: 'date',
      renderCell: (params) => {
        if (params.value) {
          return (
            params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`
          );
        } else {
          return 'Invalid Date';
        }
      },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 200,
      renderCell: (params) => {
        const status = params?.value;

        return (
          <Chip
            size="small"
            label={status}
            sx={{
              backgroundColor: setQuotationBackgroundColor(status),
              color: '#ffff',
            }}
          />
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'issuedBy',
      headerName: 'ISSUED BY',
      width: 220,
      renderCell: (params) => {
        if (params?.value?.length > 10) {
          <Tooltip title={params?.value}>
            return{' '}
            <Typography fontSize="small">
              {params?.value.substr(0, 20)}...
            </Typography>
            ;
          </Tooltip>;
        } else if (params?.value?.length > 1) {
          return <Typography fontSize={0.1}>{params?.value}</Typography>;
        } else {
          return <Typography fontSize={15}>Not Provided</Typography>;
        }
      },
      filterable: false,
      sortable: false,
    },

    {
      field: 'process',
      headerName: 'PROCESS ',
      width: 150,
      filterable: false,
      sortable: false,
    },
  ];
};

export const AllOrderListColumns = ({ uid }) => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 70,
      type: 'date',
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            component={Link}
            to={`${ORDER_DETAILS}/${params.row.fcNumber}/0`}
            state={{ clientUid: uid }}>
            <Visibility sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'fcNumber',
      headerName: 'FC NUMBER',
      width: 150,
      filterable: false,
      sortable: false,
    },

    {
      field: 'createdAt',
      headerName: 'ISSUE DATE',
      width: 170,
      type: 'date',
      renderCell: (params) =>
        params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`,
    },
    {
      field: 'deliveryDate',
      headerName: 'DELIVERY DATE',
      width: 170,
      type: 'date',
      renderCell: (params) => {
        if (params.value) {
          return (
            params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`
          );
        } else {
          return 'Invalid Date';
        }
      },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 150,
      renderCell: (params) => {
        const status = params?.value;

        return (
          <Chip
            size="small"
            label={status}
            sx={{
              backgroundColor: setOrderStatusColor(status),
              color: '#ffff',
            }}
          />
        );
      },
      filterable: false,
      sortable: false,
    },

    {
      field: 'batchInfo',
      headerName: 'PROGRESS',
      width: 200,

      renderCell: (params) => {
        let progressValue = 0;
        progressValue =
          (params?.row?.finsihedStock / params?.row?.totalQuantity) * 100;

        return (
          <Box sx={{ width: '100%' }}>
            <Typography>{progressValue.toFixed(2)}%</Typography>
            <LinearProgress variant="determinate" value={progressValue} />
          </Box>
        );
      },
    },
    {
      field: 'salesPerson',
      headerName: 'SALES PERSON',
      width: 220,

      filterable: false,
      sortable: false,
    },
    {
      field: 'totalQuantity',
      headerName: 'ORDER QUANTITY',
      width: 150,

      filterable: false,
      sortable: false,
    },
    {
      field: 'finsihedStock',
      headerName: 'FINISHED STOCK',
      width: 150,

      filterable: false,
      sortable: false,
    },
  ];
};
