import {
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../../widgets/PageHeader';
import DeleteIcon from '@mui/icons-material/Delete';

import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { QuotationCreationValidator } from '../../validators/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '../../widgets/Button';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { AddCircleTwoTone } from '@mui/icons-material';
import { createQuotation, fetchAllProcess } from '../../api/client';
import { successNoitif } from '../../utils/notification';
import CreateProcessForm from '../../components/client/CreateProcessForm';
import PageTitle from '../../utils/PageTitle';
const CreateQuotation = () => {
  const { uid } = useParams();
  let navigate = useNavigate();
  const [hasShedPricing, setHasShedPricing] = useState(true);
  const [currencyTypeField, setCurrencyTypeField] = useState(0);
  const [searchCurrencyStatus, setSearchCurrencyStatus] = useState('');
  const [exchangeRateValue, setExchangeRateValue] = useState(0);
  const [processList, setProcessList] = useState([]);
  const [processDetails, setProcessDetails] = useState({});
  const [termId, setTermId] = useState(1);
  const [fullFormData, setFullFormData] = useState({});
  const [showAddProcessForm, setShowAddProcessForm] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isProcessAdded, setIsProcessAdded] = useState(false);

  const fetchProcess = async () => {
    const response = await fetchAllProcess();
    setProcessList(response.processes);
  };

  useEffect(() => {
    (async () => {
      await fetchProcess();
    })();
  }, [isProcessAdded]);

  const changeExchangeRate = () => {
    if (searchCurrencyStatus == 'BDT') {
      setExchangeRateValue(1);
    } else if (searchCurrencyStatus == 'USD') {
      setExchangeRateValue(94);
    } else if (searchCurrencyStatus == 'GBP') {
      setExchangeRateValue(108);
    } else if (searchCurrencyStatus == 'YEN') {
      setExchangeRateValue(0.66);
    } else {
      setExchangeRateValue(0);
    }
  };

  const resolver = yupResolver(QuotationCreationValidator);

  //total form submission
  const {
    register,
    formState: { errors },

    handleSubmit,
    setValue,

    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      remarks: '',
    },
    resolver,
  });

  //useArrayField
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shadePricing',
  });
  const {
    fields: termFileds,
    append: appendTerms,
    remove: removeTerms,
  } = useFieldArray({
    control,
    name: 'terms',
  });

  useEffect(() => {
    (async () => await fetchProcess())();
    const date = new Date();

    setValue('issueDate', date);
    setValue('validTillDate', date);
  }, []);

  useEffect(() => {
    changeExchangeRate();
    setValue('exchangeRate', exchangeRateValue);
  }, [hasShedPricing, searchCurrencyStatus, exchangeRateValue]);

  const issueDate = useWatch({ control, name: 'issueDate' });
  const validTillDate = useWatch({ control, name: 'validTillDate' });
  //form submission
  const handelFormSubmit = (data) => {
    if (data.shadePricing.length > 0) {
      setHasShedPricing(true);

      const modifiedDate = {
        clientUid: uid,
        issueDate: data.issueDate,
        expiryDate: data.validTillDate,
        currency: data.CurrencyType,
        remarks: data.remarks,
        processId: processDetails?.id,
        currencyExchangeRate: parseFloat(data.exchangeRate, 2),
        additionalStepCharges: {
          heatSetCharge: data.heatSeatCharge,
          siliconCharge: data.siliconCharge,
          stenterCharge: data.stenterCharge,
          compactingCharge: data.compactingCharge,
        },
        termsAndConditions: data.terms,
        clientShades: data.shadePricing,
      };

      setFullFormData(modifiedDate);
      setShowConfirmation(true);
    } else {
      setHasShedPricing(false);
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  //close confrimation dialog
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    quotationFormSubmit();
  };

  //async calling api to post form data
  const quotationFormSubmit = async () => {
    const response = await createQuotation(fullFormData);

    if (response) {
      successNoitif('Quotation has been Added');
      navigate(-1);
    }
  };

  return (
    <Stack
      spacing={2}
      width={{ md: '80%', sm: '100%' }}
      mx="auto"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <PageTitle title="Create Quotation" />
      <Stack mt={1} width="100%" direction="row" justifyContent="flex-start">
        <Button onClick={() => navigate(-1)} variant="contained">
          <ArrowBackIosIcon fontSize="15px" />
          <Typography fontSize="15px">Back</Typography>
        </Button>
      </Stack>
      <Divider></Divider>
      <PageHeader>Create Quotation UID#{uid}</PageHeader>
      <form>
        {/* header form  */}
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={{ md: 5, xs: 0 }}>
          <Stack
            sx={{
              height: 'auto',
              minHeight: 200,
              width: { xs: '100%', md: '30%', sm: '60%' },
            }}>
            <Stack direction="column" spacing={2}>
              {/* issue date  */}
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    minDate={new Date()}
                    label="Issue Date"
                    value={issueDate}
                    onChange={(value) => setValue('issueDate', value)}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        type="date"
                        {...params}
                        style={{ width: '100%' }}
                        error={errors?.issueDate}
                        helperText={errors?.issueDate?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>

              {/* valid till date  */}
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    minDate={new Date()}
                    label="Valid Till Date"
                    value={validTillDate}
                    onChange={(value) => setValue('validTillDate', value)}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        type="date"
                        {...params}
                        style={{ width: '100%' }}
                        error={errors?.validTillDate}
                        helperText={errors?.validTillDate?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack direction="column" spacing={2}>
                {/* drop down currency  */}
                <Stack>
                  <FormControl>
                    <InputLabel id="CurrencyType">Select Currency</InputLabel>
                    <Select
                      value={currencyTypeField}
                      size="small"
                      {...register('CurrencyType')}
                      onChange={(event) => {
                        setSearchCurrencyStatus(event.target.value);
                        setCurrencyTypeField(event.target.value);
                      }}
                      labelId="CurrencyType"
                      id="demo-simple-select"
                      label="Select Currency"
                      placeholder="Select Currency">
                      <MenuItem value="BDT">BDT</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="GBP">GBP</MenuItem>
                      <MenuItem value="YEN">YEN</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                {/* Exchnage rate  */}
                <Stack>
                  <TextField
                    label="Exchnage rate"
                    onChange={(event) => {
                      setExchangeRateValue(event.target.value);
                    }}
                    size="small"
                    placeholder="Exchnage rate"
                    {...register('exchangeRate')}
                    error={errors?.exchangeRate}
                    helperText={errors?.exchangeRate?.message}
                  />
                </Stack>
                {/* Select Proccess */}
                {/* new proccess create  */}

                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  width="100%">
                  <Stack sx={{ width: '90%' }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={processList}
                      getOptionLabel={(option) => option?.processName}
                      size="small"
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}>
                          {option?.processName} ({option?.shortName})
                        </Box>
                      )}
                      onChange={(event, newValue) => {
                        setProcessDetails(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Process"
                          {...register('processList')}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <IconButton
                      size="small"
                      title="Add Process"
                      aria-label="Add Process"
                      onClick={() => {
                        setShowAddProcessForm(true);
                      }}
                      sx={{
                        bgcolor: '#fff',
                        color: '#f44336',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#f44336', color: '#fff' },
                      }}>
                      <AddCircleTwoTone />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              height: 'auto',
              minHeight: 200,
              width: { xs: '100%', md: '30%', sm: '60%' },
            }}>
            {/* remarks  */}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              pb={3}>
              <TextField
                label="Remarks"
                style={{ width: '100%' }}
                multiline
                rows={5}
                placeholder="Remarks"
                {...register('remarks')}
                error={errors?.remarks}
                helperText={errors?.remarks?.message}
              />
            </Stack>
          </Stack>
        </Stack>

        {/* shade pricing  */}
        <Stack my={4}>
          <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1}>
            Shade Pricing
          </Typography>
          {/* check at least one shed pricing  */}
          <Divider></Divider>
          <Typography
            mt={0.5}
            size="small"
            color={hasShedPricing ? 'black' : 'red'}>
            Please Add at least one shade pricing
          </Typography>

          <Stack>
            <TableContainer
              mx={0}
              sx={{
                'td, th': {
                  border: 0,
                  padding: 1,
                  margin: 1,
                },
              }}>
              <Table
                sx={{ minWidth: 650, 'td, th': { border: 0 }, border: 0 }}
                aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ fontSize: '12px' }}>
                      #
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px' }}>
                      SHADE NAME
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px' }}>
                      SHADE %
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px' }}>
                      OPEN
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px' }}>
                      TUBE
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px' }}>
                      LYCRA OPEN
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px' }}>
                      DOUBLE PART OPEN
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px' }}>
                      DOUBLE PART TUBE
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: '12px' }}>
                      REMARKS
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: '12px' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    tr: { borderTop: 0.5, borderBottom: 0.5 },
                    td: { borderTop: 0.5, borderBottom: 0.5 },
                  }}>
                  {fields.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell
                        width="40px"
                        align="left"
                        component="th"
                        scope="row">
                        <Typography>{index + 1}</Typography>
                      </TableCell>
                      <TableCell align="left" width="130px">
                        <TextField
                          size="small"
                          {...register(`shadePricing.${index}.shadeName`)}
                          error={errors?.shadePricing?.[index]?.shadeName}
                          helperText={
                            errors?.shadePricing?.[index]?.shadeName?.message
                          }
                        />
                      </TableCell>
                      <TableCell align="left" width="130px">
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                          <TextField
                            size="small"
                            {...register(`shadePricing.${index}.shadeRange`)}
                            error={errors?.shadePricing?.[index]?.shadeRange}
                            helperText={
                              errors?.shadePricing?.[index]?.shadeRange?.message
                            }
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="left" width="130px">
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                          <TextField
                            size="small"
                            {...register(`shadePricing.${index}.open`)}
                            error={errors?.shadePricing?.[index]?.open}
                            helperText={
                              errors?.shadePricing?.[index]?.open?.message
                            }
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="left" width="130px">
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                          <TextField
                            size="small"
                            {...register(`shadePricing.${index}.tube`)}
                            error={errors?.shadePricing?.[index]?.tube}
                            helperText={
                              errors?.shadePricing?.[index]?.tube?.message
                            }
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="left" width="130px">
                        {' '}
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                          <TextField
                            size="small"
                            {...register(`shadePricing.${index}.lycraOpen`)}
                            error={errors?.shadePricing?.[index]?.lycraOpen}
                            helperText={
                              errors?.shadePricing?.[index]?.lycraOpen?.message
                            }
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="left" width="130px">
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                          <TextField
                            size="small"
                            {...register(
                              `shadePricing.${index}.doublePartOpen`,
                            )}
                            error={
                              errors?.shadePricing?.[index]?.doublePartOpen
                            }
                            helperText={
                              errors?.shadePricing?.[index]?.doublePartOpen
                                ?.message
                            }
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="left" width="130px">
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                          <TextField
                            size="small"
                            {...register(
                              `shadePricing.${index}.doublePartTube`,
                            )}
                            error={
                              errors?.shadePricing?.[index]?.doublePartTube
                            }
                            helperText={
                              errors?.shadePricing?.[index]?.doublePartTube
                                ?.message
                            }
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="left" width="130px">
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                          <TextField
                            size="small"
                            {...register(`shadePricing.${index}.remarks`)}
                            error={errors?.shadePricing?.[index]?.remarks}
                            helperText={
                              errors?.shadePricing?.[index]?.remarks?.message
                            }
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="left" width="130px">
                        <Stack justifyContent="center" alignItems="flex-end">
                          <IconButton
                            size="small"
                            title="Delete"
                            aria-label="Delete"
                            onClick={() => remove(index)}
                            sx={{
                              bgcolor: '#fff',
                              color: '#f44336',
                              borderRadius: 1,
                              ':hover': { bgcolor: '#f44336', color: '#fff' },
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* add button  */}
            <Stack
              width="100%"
              justifyContent="center"
              alignItems="flex-start"
              mt={2}>
              <Button
                type="button"
                variant="contained"
                onClick={() => append()}>
                Add
              </Button>
            </Stack>
          </Stack>
        </Stack>

        {/* Additional Step charges  */}

        <Stack>
          <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1} mt={3}>
            Additional Step Charges
          </Typography>
          <Divider></Divider>

          <Stack
            mt={2}
            sx={{
              height: 'auto',
              minHeight: 200,
              width: { xs: '100%', md: '30%', sm: '60%' },
            }}>
            <Grid container spacing={2}>
              {/* heat seat charge */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography>Heat Seat Charge: </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  size="small"
                  placeholder="Heat Seat Charge"
                  {...register('heatSeatCharge')}
                  error={errors?.heatSeatCharge}
                  helperText={errors?.heatSeatCharge?.message}
                />
              </Grid>
              {/* silicon charge  */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography textAlign="left">Silicon Charge: </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  size="small"
                  placeholder="Silicon Charge"
                  {...register('siliconCharge')}
                  error={errors?.siliconCharge}
                  helperText={errors?.siliconCharge?.message}
                />
              </Grid>

              {/* stenter charge  */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography>Stenter Charge: </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  size="small"
                  placeholder="Stenter Charge"
                  {...register('stenterCharge')}
                  error={errors?.stenterCharge}
                  helperText={errors?.stenterCharge?.message}
                />
              </Grid>
              {/* comacting charge  */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography>Compacting Charge: </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  size="small"
                  placeholder="Compacting Charge"
                  {...register('compactingCharge')}
                  error={errors?.compactingCharge}
                  helperText={errors?.compactingCharge?.message}
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>

        {/* terms and condition  */}
        <Stack mt={4}>
          <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1} mt={3}>
            Terms and Conditions
          </Typography>
          <Divider></Divider>

          {termFileds.length === 0 ? (
            <Typography mt={0.5} size="small">
              No terms and conditions added
            </Typography>
          ) : (
            <></>
          )}
          <Stack>
            <ol>
              {termFileds.map((item, index) => (
                <li key={item.id}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      my={1}
                      sx={{ width: '400px' }}>
                      <TextField
                        size="small"
                        sx={{ width: '100%' }}
                        placeholder="Terms and Condition"
                        {...register(`terms.${index}.term`)}
                        error={errors?.terms?.[index]?.term}
                        helperText={errors?.terms?.[index]?.term?.message}
                      />
                    </Stack>

                    <Stack justifyContent="center">
                      <IconButton
                        size="small"
                        title="Delete"
                        aria-label="Delete"
                        onClick={() => {
                          removeTerms(index);
                          setTermId(termId - 1);
                        }}
                        sx={{
                          bgcolor: '#fff',
                          color: '#f44336',
                          borderRadius: 1,
                          ':hover': { bgcolor: '#f44336', color: '#fff' },
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                </li>
              ))}
            </ol>
            <Stack
              width="100%"
              justifyContent="center"
              alignItems="flex-start"
              mt={2}>
              <Button
                type="button"
                variant="contained"
                onClick={() => {
                  appendTerms({ id: termId });
                  setTermId(termId + 1);
                }}>
                Add
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>

      {/* submit button  */}
      <Stack width="100%" justifyContent="center" alignItems="flex-end" mt={2}>
        <Button variant="contained" onClick={handleSubmit(handelFormSubmit)}>
          Confirm
        </Button>
      </Stack>

      {/* confirmation dialoge  */}
      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to add this Quotation?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => handelConfrimationDecision()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
      <CreateProcessForm
        toggleModal={setShowAddProcessForm}
        show={showAddProcessForm}
        isProcessAdded={isProcessAdded}
        setIsProcessAdded={setIsProcessAdded}></CreateProcessForm>
    </Stack>
  );
};

export default CreateQuotation;
