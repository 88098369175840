import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
  Table,
  TextField,
  Dialog,
  DialogTitle,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import { Box } from '@mui/system';
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs';

import '../../styles/delivery-print.css';
import {
  fetchOrderDelivery,
  updateOrderDeliveryNetQuantity,
} from '../../api/order';
import { reformateLotNumber } from '../../utils/order';
import PageTitle from '../../utils/PageTitle';
import { errorNoitif, successNoitif } from '../../utils/notification';
import DoneIcon from '@mui/icons-material/Done';
import DeliveryNetQuantityApprovalModal from '../../components/approval/DeliveryNetQuantityApprovalModal';
import Button from '../../widgets/Button';

const PrintDelivery = () => {
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [netQuantityArray, setNetQuantityArray] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  let navigate = useNavigate();
  const { deliveryId } = useParams();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchDelivery = async () => {
    const result = await fetchOrderDelivery({ uid: deliveryId });
    setDeliveryInfo(result);
  };

  useEffect(() => {
    //fetching specific delivery
    (async () => {
      await fetchDelivery();
    })();
  }, [isUpdated]);

  const handleNetQuantityChange = (input, deliveryFabricBatch) => {
    // deliveryFabricBatch.netQuantity = input;
    !input || input < 0 ? (input = 0) : input;
    const tempArray = netQuantityArray.find(
      (item) => item.deliveryFabricId === deliveryFabricBatch.id,
    );
    if (tempArray) {
      tempArray.netQuantity = input;
      setNetQuantityArray([...netQuantityArray]);
      return;
    }

    const tempObject = {
      netQuantity: input,
      deliveryFabricId: deliveryFabricBatch.id,
      deliveryQuantity: deliveryFabricBatch.deliveryQuantity,
    };
    setNetQuantityArray([...netQuantityArray, tempObject]);
  };

  const checkNetQuantityArray = () => {
    if (netQuantityArray.length < 1) {
      return true;
    }
    let flag = false;
    netQuantityArray.map((item) => {
      if (item.netQuantity > item.deliveryQuantity) {
        flag = true;
      }
      if (item.netQuantity == 0) {
        flag = true;
      }
    });
    return flag;
  };

  const handleUpdate = () => {
    setShowConfirmation(true);
  };
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    updateDeliveryNetQuanity();
  };

  const updateDeliveryNetQuanity = async () => {
    const response = await updateOrderDeliveryNetQuantity(
      deliveryId,
      netQuantityArray,
    );
    if (response.status === 200) {
      successNoitif('Order delivery net quantity has been updated');
      setIsUpdated(!isUpdated);
    } else {
      errorNoitif('Sorry! delivery net quantity has not been updated');
    }
  };

  const [showNetQuantityApprovalModal, setShowNetQuantityApprovalModal] =
    useState(false);
  const handleConfrim = () => {
    setShowNetQuantityApprovalModal(true);
  };

  return (
    <Stack
      spacing={2}
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <Stack width={{ md: '80%', sm: '100%' }} mx="auto">
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}>
          <Stack>
            <Button onClick={() => navigate(-1)} variant="contained">
              <ArrowBackIosIcon fontSize="15px" />
              <Typography fontSize="15px">Back</Typography>
            </Button>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            {deliveryInfo.status === 'DELIVERED' && (
              <Button
                sx={{
                  backgroundColor: 'green',
                  ':hover': {
                    bgcolor: 'green',
                    color: '#fff',
                  },
                }}
                disabled={deliveryInfo.isNetQuantityConfirmed ? true : false}
                onClick={() => handleConfrim()}
                variant="contained">
                <Stack direction="row" spacing={0.5}>
                  <Typography>Confrim Net Quantity</Typography>
                  <DoneIcon />
                </Stack>
              </Button>
            )}

            <Button onClick={handlePrint} variant="contained">
              <Stack direction="row" spacing={0.5}>
                <Typography fontSize="15px">Print</Typography>
                <PrintIcon />
              </Stack>
            </Button>
          </Stack>
        </Stack>

        <DeliveryNetQuantityApprovalModal
          show={showNetQuantityApprovalModal}
          toggleModal={setShowNetQuantityApprovalModal}
          deliveryId={deliveryId}
          isUpdated={isUpdated}
          setIsUpdated={setIsUpdated}
        />

        <Divider></Divider>

        {deliveryInfo.partyName ? (
          <Box
            mx="auto"
            width="1080px"
            my={2}
            mt={3}
            boxSizing="border-box"
            padding="15px 30px"
            class="page">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              mb={2}>
              <Stack>
                <Box
                  component="img"
                  sx={{
                    width: 150,
                  }}
                  alt="The house from the offer."
                  src="/assets/images/logo.svg"
                />
              </Stack>
              <Stack>
                <Typography fontSize={18}>2/1,Gadda,Savar,Dhaka</Typography>
              </Stack>
            </Stack>
            <Divider></Divider>
            <Stack width="100%" direction="row" justifyContent="Center" my={2}>
              <Typography fontSize={25} sx={{ fontWeight: '500' }}>
                Delivery Order #{deliveryId}
              </Typography>
            </Stack>
            <Grid mb={1.5} container direction="row" spacing={1}>
              <Grid item xs={6}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Party Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {deliveryInfo.partyName}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Delivery Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {dayjs(deliveryInfo.deliveryDate).format('DD/MMM/YYYY ')}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Contatct Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {deliveryInfo.deliveryContatctNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Delivery Contact:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {deliveryInfo.deliveryContatct}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Vehicle Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {deliveryInfo?.vehicleNumber.length == 0 ? (
                      <Typography textAlign="left">
                        No Vehicle Assigned
                      </Typography>
                    ) : (
                      <Typography textAlign="left">
                        {deliveryInfo?.vehicleNumber}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      textAlign="left"
                      style={{ fontWeight: 600, fontSize: '15px' }}>
                      Delivery Address:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {deliveryInfo.deliveryAddress}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider></Divider>
            <Stack
              mt={1}
              width="89%"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}>
              <Typography style={{ fontWeight: 600 }}>
                Total Delivery Quantity:
              </Typography>
              <Typography style={{ fontWeight: 600 }}>
                {deliveryInfo.totalDeliveryQuantity} KG
              </Typography>
            </Stack>
            {/* table section  */}
            <Stack className="table-section">
              {deliveryInfo?.fabricList?.map((item, index) => {
                if (item?.deliveryBatchInfo.length > 0) {
                  return (
                    <Stack key={index}>
                      <Stack>
                        <Typography my={1} style={{ fontWeight: 600 }}>
                          Fabric: {item.fabricName}
                        </Typography>
                      </Stack>
                      <Table className="delivery-table">
                        <thead className="delivery-table-thead">
                          <tr className="delivery-table-tr">
                            <th className="delivery-table-th">SL</th>
                            <th className="delivery-table-th">Batch #</th>

                            <th className="delivery-table-th">Color</th>
                            <th className="delivery-table-th">GSM</th>
                            <th className="delivery-table-th">DIA</th>
                            <th className="delivery-table-th">Shade</th>
                            <th className="delivery-table-th">
                              Net Quantity (KG)
                            </th>
                            <th className="delivery-table-th">
                              Deliver Quanity (KG)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.deliveryBatchInfo?.map((row, index) => {
                            return (
                              <tr
                                className="delivery-table-tr"
                                key={row.lotNumber}>
                                <td className="delivery-table-td">
                                  {index + 1}
                                </td>
                                <td className="delivery-table-td">
                                  {reformateLotNumber(row.lotNumber)}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.colour}
                                </td>

                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.gsm}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.dia}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.shade}
                                </td>
                                <td className="delivery-table-td" width="300px">
                                  {/* {row.netQuantity} */}
                                  <Stack direction="row" spacing={1}>
                                    <TextField
                                      disabled={
                                        deliveryInfo?.isNetQuantityConfirmed ||
                                        deliveryInfo?.status == 'IN QUEUE'
                                          ? true
                                          : false
                                      }
                                      size="small"
                                      InputProps={{
                                        inputProps: {
                                          type: 'number',
                                          min: 0,
                                          style: { textAlign: 'center' },
                                        },
                                      }}
                                      defaultValue={row.netQuantity}
                                      onKeyPress={(event) => {
                                        if (
                                          event?.key === '-' ||
                                          event?.key === '+'
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onChange={(event) =>
                                        // setInputNetQuantity(
                                        //   parseInt(event.target.value),
                                        // )
                                        handleNetQuantityChange(
                                          parseInt(event.target.value),
                                          row,
                                        )
                                      }
                                      flexWrap="wrap"
                                      fullWidth
                                      id="outlined-basic"
                                      sx={{ maxWidth: 300 }}
                                      variant="outlined"
                                      error={
                                        row.deliveryQuantity <
                                          netQuantityArray.find(
                                            (item) =>
                                              item.deliveryFabricId === row.id,
                                          )?.netQuantity ||
                                        netQuantityArray.find(
                                          (item) =>
                                            item.deliveryFabricId === row.id,
                                        )?.netQuantity <= 0
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        row.deliveryQuantity <
                                          netQuantityArray.find(
                                            (item) =>
                                              item.deliveryFabricId === row.id,
                                          )?.netQuantity ||
                                        netQuantityArray.find(
                                          (item) =>
                                            item.deliveryFabricId === row.id,
                                        )?.netQuantity <= 0
                                          ? 'Net Quanity must be numaric and less than delivery quantity'
                                          : ''
                                      }
                                    />
                                  </Stack>
                                </td>

                                <td className="delivery-table-td">
                                  {' '}
                                  {row.deliveryQuantity}
                                </td>
                              </tr>
                            );
                          })}
                          <tr className="delivery-table-tr">
                            <td
                              className="delivery-table-td"
                              colSpan="7"
                              style={{ textAlign: 'center', fontWeight: 600 }}>
                              Sub-Total
                            </td>
                            <td
                              colSpan="2"
                              className="delivery-table-td"
                              style={{ fontWeight: 600 }}>
                              {item.totalDeliveryQuantity}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Stack>
                  );
                }
              })}
            </Stack>

            <Stack
              mt={2}
              width="89%"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}>
              <Typography style={{ fontWeight: 600 }}>
                Total Delivery Quantity:
              </Typography>
              <Typography style={{ fontWeight: 600 }}>
                {deliveryInfo?.totalDeliveryQuantity} KG
              </Typography>
            </Stack>

            <Stack
              mt={2}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              width="100%">
              <Button
                onClick={handleUpdate}
                disabled={
                  checkNetQuantityArray() ||
                  deliveryInfo?.isNetQuantityConfirmed
                }
                variant="contained">
                Update
              </Button>
            </Stack>

            <Stack>
              <Dialog
                onClose={onCloseCongfirmation}
                open={showConfirmation}
                maxWidth="sm"
                title="Are you sure"
                yesText="Yes"
                noHandler={onCloseCongfirmation}>
                <DialogTitle id="simple-dialog-title">
                  Are you sure you want to update Net Quantity?
                </DialogTitle>
                <Stack
                  direction="row"
                  gap={1}
                  justifyContent="flex-end"
                  sx={{ paddingRight: 2, paddingBottom: 2 }}>
                  <Button
                    variant="outlined"
                    sx={{ borderColor: 'red', color: 'red' }}
                    onClick={() => {
                      setShowConfirmation(false);
                    }}>
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handelConfrimationDecision()}>
                    Yes
                  </Button>
                </Stack>
              </Dialog>
            </Stack>

            {/* signature  */}
            <Grid className="singnature-class" container spacing={2} mt={8}>
              <Grid item xs={4}>
                <Divider width="80%"></Divider>
                <Stack width="90%" justifyContent="center" alignItems="center">
                  <Stack my={0.5}>
                    <Typography fontSize={15}>Authorized Signature</Typography>
                    <Typography fontSize={15}>Kishor Kumer Kejriwal</Typography>
                    <Typography fontSize={15}>Executive Director</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Divider width="80%"></Divider>
                <Stack width="90%" justifyContent="center" alignItems="center">
                  <Stack my={0.5}>
                    <Typography fontSize={15}>Authorized Signature</Typography>
                    <Typography fontSize={15}>Kanouj Chakma Ziko</Typography>
                    <Typography fontSize={15}>
                      GM (Dyeing & Finishing)
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Divider width="80%"></Divider>
                <Stack width="90%" justifyContent="center" alignItems="center">
                  <Stack my={0.5}>
                    <Typography fontSize={15}>Authorized Signature</Typography>
                    <Typography fontSize={15}>Md. Ekhlasur Rahman</Typography>
                    <Typography fontSize={15}>Technical Director</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            <Stack
              justifyContent="center"
              pt={10}
              width="100%"
              height="100%"
              alignItems="center">
              <CircularProgress />
            </Stack>
          </>
        )}
      </Stack>

      {/* for printing  */}
      <Stack display="none" width="100%" heigh="100%">
        {deliveryInfo.partyName ? (
          <Box
            mx="auto"
            width="1080px"
            my={2}
            mt={3}
            ref={componentRef}
            boxSizing="border-box"
            padding="15px 30px"
            class="page">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              mb={2}>
              <Stack>
                <Box
                  component="img"
                  sx={{
                    width: 150,
                  }}
                  alt="The house from the offer."
                  src="/assets/images/logo.svg"
                />
              </Stack>
              <Stack>
                <Typography fontSize={18}>2/1,Gadda,Savar,Dhaka</Typography>
              </Stack>
            </Stack>
            <Divider></Divider>
            <Stack width="100%" direction="row" justifyContent="Center" my={2}>
              <Typography fontSize={25} sx={{ fontWeight: '500' }}>
                Delivery Order #{deliveryId}
              </Typography>
            </Stack>
            <Grid mb={1.5} container direction="row" spacing={1}>
              <Grid item xs={6}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Party Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {deliveryInfo.partyName}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Delivery Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {dayjs(deliveryInfo.deliveryDate).format('DD/MMM/YYYY ')}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Contatct Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {deliveryInfo.deliveryContatctNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Delivery Contact:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {deliveryInfo.deliveryContatct}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontWeight: 600 }}>
                      Vehicle Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    {deliveryInfo?.vehicleNumber.length == 0 ? (
                      <Typography textAlign="left">
                        No Vehicle Assigned
                      </Typography>
                    ) : (
                      <Typography textAlign="left">
                        {deliveryInfo?.vehicleNumber}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      textAlign="left"
                      style={{ fontWeight: 600, fontSize: '15px' }}>
                      Delivery Address:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography textAlign="left">
                      {deliveryInfo.deliveryAddress}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider></Divider>
            <Stack
              mt={1}
              width="89%"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}>
              <Typography style={{ fontWeight: 600 }}>
                Total Delivery Quantity:
              </Typography>
              <Typography style={{ fontWeight: 600 }}>
                {deliveryInfo.totalDeliveryQuantity} KG
              </Typography>
            </Stack>
            {/* table section  */}
            <Stack className="table-section">
              {deliveryInfo?.fabricList?.map((item, index) => {
                if (item?.deliveryBatchInfo.length > 0) {
                  return (
                    <Stack key={index}>
                      <Stack>
                        <Typography my={1} style={{ fontWeight: 600 }}>
                          Fabric: {item.fabricName}
                        </Typography>
                      </Stack>
                      <Table className="delivery-table">
                        <thead className="delivery-table-thead">
                          <tr className="delivery-table-tr">
                            <th className="delivery-table-th">SL</th>
                            <th className="delivery-table-th">Batch #</th>
                            <th className="delivery-table-th">Color</th>
                            <th className="delivery-table-th">GSM</th>
                            <th className="delivery-table-th">DIA</th>
                            <th className="delivery-table-th">Shade</th>
                            <th className="delivery-table-th">
                              Net Quantity (KG)
                            </th>
                            <th className="delivery-table-th">
                              Deliver Quanity (KG)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.deliveryBatchInfo?.map((row, index) => {
                            return (
                              <tr
                                className="delivery-table-tr"
                                key={row.lotNumber}>
                                <td className="delivery-table-td">
                                  {index + 1}
                                </td>
                                <td className="delivery-table-td">
                                  {reformateLotNumber(row.lotNumber)}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.colour}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.gsm}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.dia}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.shade}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.gsm}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.dia}
                                </td>
                                <td className="delivery-table-td">
                                  {item?.fabricInfo?.shade}
                                </td>
                                <td className="delivery-table-td" width="170px">
                                  {/* {row.netQuantity} */}
                                  <Stack direction="row" spacing={1}>
                                    <TextField
                                      size="small"
                                      defaultValue={row.netQuantity}
                                      onChange={(event) =>
                                        // setInputNetQuantity(
                                        //   parseInt(event.target.value),
                                        // )
                                        handleNetQuantityChange(
                                          parseInt(event.target.value),
                                          row,
                                        )
                                      }
                                      flexWrap="wrap"
                                      fullWidth
                                      id="outlined-basic"
                                      sx={{ maxWidth: 170 }}
                                      variant="outlined"
                                    />
                                  </Stack>
                                </td>
                                <td className="delivery-table-td">
                                  {' '}
                                  {row.deliveryQuantity}
                                </td>
                              </tr>
                            );
                          })}
                          <tr className="delivery-table-tr">
                            <td
                              className="delivery-table-td"
                              colSpan="7"
                              style={{ textAlign: 'center', fontWeight: 600 }}>
                              Sub-Total
                            </td>
                            <td
                              colSpan="2"
                              className="delivery-table-td"
                              style={{ fontWeight: 600 }}>
                              {item.totalDeliveryQuantity}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Stack>
                  );
                }
              })}
            </Stack>

            <Stack
              mt={2}
              width="89%"
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}>
              <Typography style={{ fontWeight: 600 }}>
                Total Delivery Quantity:
              </Typography>
              <Typography style={{ fontWeight: 600 }}>
                {deliveryInfo?.totalDeliveryQuantity} KG
              </Typography>
            </Stack>

            {/* signature  */}
            <Grid className="singnature-class" container spacing={2} mt={8}>
              <Grid item xs={4}>
                <Divider width="80%"></Divider>
                <Stack width="90%" justifyContent="center" alignItems="center">
                  <Stack my={0.5}>
                    <Typography fontSize={15}>Authorized Signature</Typography>
                    <Typography fontSize={15}>Kishor Kumer Kejriwal</Typography>
                    <Typography fontSize={15}>Executive Director</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Divider width="80%"></Divider>
                <Stack width="90%" justifyContent="center" alignItems="center">
                  <Stack my={0.5}>
                    <Typography fontSize={15}>Authorized Signature</Typography>
                    <Typography fontSize={15}>Kanouj Chakma Ziko</Typography>
                    <Typography fontSize={15}>
                      GM (Dyeing & Finishing)
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Divider width="80%"></Divider>
                <Stack width="90%" justifyContent="center" alignItems="center">
                  <Stack my={0.5}>
                    <Typography fontSize={15}>Authorized Signature</Typography>
                    <Typography fontSize={15}>Md. Ekhlasur Rahman</Typography>
                    <Typography fontSize={15}>Technical Director</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
      </Stack>
      <PageTitle title="Print Order Delivery" />
    </Stack>
  );
};

export default PrintDelivery;
