import { yupResolver } from '@hookform/resolvers/yup';
import {
  AddCircleTwoTone as Add,
  ChevronLeftTwoTone as ChevronLeft,
  DeleteTwoTone as Delete,
} from '@mui/icons-material';
import {
  Autocomplete,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addDyeGroup,
  createChemicalAndLots,
  fetchAllDyeGroup,
} from '../../api/inventory';
import { STORE_MANAGEMENT } from '../../constants/routeUrls';
import {
  errorNoitif,
  successNoitif,
  warningNoitif,
} from '../../utils/notification';
import { CreateChemicalSchema } from '../../validators/inventory';
import Button from '../../widgets/Button';
import PageHeader from '../../widgets/PageHeader';
import TextField from '../../widgets/TextField';

const AddChemical = () => {
  const ref = useRef();
  const resolver = yupResolver(CreateChemicalSchema);
  const navigate = useNavigate();
  const location = useLocation();
  const { tab } = location.state;
  const [containerWidth, setContainerWidth] = useState(0);
  const [dyeGroups, setDyeGroups] = useState([]);
  const [dyeGroupName, setDyeGroupName] = useState('');
  const [dyeGroupAddDialogOpen, setDyeGroupDialogOpen] = useState(false);

  useEffect(
    () => setContainerWidth(ref.current?.offsetWidth),
    [ref.current?.offsetWidth],
  );

  const {
    control,
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: '',
      unit: '',
      chemicalType: 'Chemical',
      lots: [],
    },
    resolver,
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'lots' });
  const chemicalType = useWatch({ control, name: 'chemicalType' });

  const handleChemicalCraetion = async (formData) => {
    const { name, unit, chemicalType, lots } = formData;

    const chemicalInfo = {
      name,
      unit,
      chemicalType,
      dyeGroupId: formData?.dyeGroupId,
    };
    const response = await createChemicalAndLots(chemicalInfo, lots);

    if (response) {
      successNoitif('Chemicals added to inventory');
      navigate(STORE_MANAGEMENT + `/${tab}`);
    }
  };

  const fetchDyeGroups = async (name) => {
    const response = await fetchAllDyeGroup(name);
    setDyeGroups(response);
  };

  useEffect(() => {
    if (chemicalType === 'Dye') {
      (async () => await fetchDyeGroups())();
    }
  }, [chemicalType]);

  const handleDyeGroupAdd = async () => {
    if (dyeGroupName.length <= 0) {
      warningNoitif('Please provide name of dye group');
      setDyeGroupDialogOpen(false);

      return;
    }
    const response = await addDyeGroup(dyeGroupName);

    if (!response) {
      errorNoitif('Sorry could not add dye group');
      setDyeGroupDialogOpen(false);

      return;
    }

    setDyeGroups((groups) => [...groups, response]);
    setDyeGroupName('');
    setDyeGroupDialogOpen(false);
  };

  return (
    <Container maxWidth="xl" ref={ref}>
      <Dialog
        open={dyeGroupAddDialogOpen}
        onClose={() => setDyeGroupDialogOpen(false)}
        fullWidth
        maxWidth="sm">
        <DialogTitle>Add Dye Group</DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              paddingLeft: 3,
              paddingRight: 2,
              paddingBottom: 1,
            }}>
            <TextField
              label="Dye group name"
              placeholder="Please enter dye group name"
              sx={{ mt: 2 }}
              error={dyeGroupName.length <= 0}
              value={dyeGroupName}
              onChange={(event) => setDyeGroupName(event.target.value)}
            />
          </Stack>
          <Stack direction="row" spacing={1} mt={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="error"
              onClick={() => setDyeGroupDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDyeGroupAdd}>
              Add
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack
        width="100%"
        height="auto"
        minHeight="calc(100vh - 65px)"
        py={3}
        spacing={2}>
        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-start"
          mt={1}
          mb={2}>
          <Button
            onClick={() => navigate(-1)}
            variant="contained"
            startIcon={<ChevronLeft />}>
            <Typography fontSize="15px">Back</Typography>
          </Button>
        </Stack>

        <Divider />
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <PageHeader>Add Chemical</PageHeader>
          <Button
            variant="contained"
            onClick={handleSubmit(handleChemicalCraetion)}>
            Confirm & Add Chemical
          </Button>
        </Stack>
        <Typography variant="h6" fontWeight={600}>
          Chemical Information
        </Typography>
        <Stack direction="row" flexWrap="wrap" width="100%">
          <TextField
            label="Name"
            placeholder="Enter name of the chemical"
            {...register('name')}
            error={!!errors?.name}
            helperText={errors?.name?.message}
            sx={{
              width: containerWidth / 3 - 8 * 4,
              minWidth: 290,
              mr: 2,
              mb: 2,
            }}
          />
          <TextField
            label="Unit"
            placeholder="Enter unit of measurement for chemical"
            {...register('unit')}
            error={!!errors?.unit}
            helperText={errors?.unit?.message}
            sx={{
              width: containerWidth / 3 - 8 * 4,
              minWidth: 290,
              mr: 2,
              mb: 2,
            }}
          />
          <FormControl
            error={!!errors?.chemicalType}
            sx={{
              width: containerWidth / 3 - 8 * 4,
              minWidth: 290,
            }}>
            <InputLabel>Chemical Type</InputLabel>
            <Select
              defaultValue={chemicalType}
              {...register('chemicalType')}
              sx={{ height: 51 }}
              label="Chemical Type"
              placeholder="Please Select a chemical type">
              <MenuItem value="Chemical">Chemical</MenuItem>
              <MenuItem value="Dye">Dye</MenuItem>
            </Select>
            <FormHelperText>{errors?.chemicalType?.message}</FormHelperText>
          </FormControl>
          {chemicalType === 'Dye' && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              sx={{
                width: containerWidth / 3 - 8 * 4,
                minWidth: 290,
              }}>
              <Autocomplete
                fullWidth
                options={dyeGroups}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                onInputChange={async (event, name) => {
                  if (name.length > 1) {
                    await fetchDyeGroups(name);
                  } else if (name.length === 0) {
                    await fetchDyeGroups();
                  }
                }}
                renderOption={(props, option) => (
                  <Typography {...props}>{option.name}</Typography>
                )}
                onChange={(event, value) => {
                  setValue('dyeGroupId', value.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Select dye group"
                    error={!!errors?.dyeGroup}
                    helperText={errors?.dyeGroup?.message}
                  />
                )}
              />
              <IconButton
                sx={{ height: 'max-content' }}
                onClick={() => setDyeGroupDialogOpen(true)}>
                <Add />
              </IconButton>
            </Stack>
          )}
        </Stack>
        <Typography variant="h6" fontWeight={600}>
          Add Stock
        </Typography>

        {fields.length > 0 ? (
          <>
            <Table
              size="small"
              stickyHeader
              sx={{ width: '100%', overflow: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Brand</TableCell>
                  <TableCell>Stock Quantity</TableCell>
                  <TableCell>Total Cost</TableCell>
                  <TableCell width={60} sx={{ textAlign: 'center' }}>
                    #
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <TextField
                        size="small"
                        placeholder="Enter brand name"
                        error={!!errors?.lots?.[index]?.brand}
                        helperText={errors?.lots?.[index]?.brand?.message}
                        {...register(`lots.${index}.brand`)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        placeholder="Stock quantity"
                        error={!!errors?.lots?.[index]?.quantity}
                        helperText={errors?.lots?.[index]?.quantity?.message}
                        {...register(`lots.${index}.quantity`)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        placeholder="Total cost"
                        error={!!errors?.lots?.[index]?.cost}
                        helperText={errors?.lots?.[index]?.cost?.message}
                        {...register(`lots.${index}.cost`)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => remove(index)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              variant="contained"
              onClick={() => append({})}
              sx={{ width: 'max-content' }}>
              Add Lot
            </Button>
          </>
        ) : (
          <Stack width="100%" alignItems="center" spacing={2}>
            {errors?.lots && (
              <FormHelperText sx={{ color: 'red' }}>
                {errors?.lots?.message}
              </FormHelperText>
            )}
            <Typography variant="h6" fontWeight={700}>
              No Lots Added
            </Typography>
            <Typography maxWidth={350} textAlign="center">
              No lots added for this chemical added yet. Please add lots to
              manage inventory for this chemical.
            </Typography>
            <Button variant="contained" onClick={() => append({})}>
              Add Lot
            </Button>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default AddChemical;
