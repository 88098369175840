import { Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import ViewportList from 'react-viewport-list';
import { fetchBatchLogsFeed } from '../../../api/batch';

const Feed = () => {
  const logRef = useRef(null);
  const [feed, setFeed] = useState([]);

  const fetchData = async () => {
    const response = await fetchBatchLogsFeed();
    setFeed(response);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  useEffect(() => {
    const interval = setInterval(
      async () => await fetchData(),
      15 * 60 * 10000,
    );

    return () => clearInterval(interval);
  }, []);

  const getLogTypeColor = (type) => {
    const colors = {
      INFO: '#4562f7',
      WARN: '#F1A661',
      ERROR: '#F96666',
      SUCCESS: '#7FB77E',
      ACTION: '#6F38C5',
    };

    return colors[type || 'INFO'];
  };

  return (
    <Stack
      width="100%"
      px={2}
      py={2}
      borderRadius={2}
      border="2px solid rgba(0,0,0,0.2)"
      height="100%"
      maxHeight={300}
      spacing={1}>
      <Typography fontSize={18} fontWeight={800}>
        Batch Live Feed ({dayjs().format('DD, MMM YYYY')})
      </Typography>
      {feed?.length > 0 ? (
        <Stack overflow="auto" spacing={1} ref={logRef}>
          <ViewportList viewportRef={logRef} items={feed} overscan={5}>
            {(feed, index) => (
              <Stack
                key={index}
                px={1}
                py={1}
                bgcolor={getLogTypeColor(feed.type)}
                color="#fff"
                borderRadius={1.5}
                direction="row"
                justifyContent="space-between">
                <Stack>
                  <Typography fontSize={14} fontWeight={700}>
                    {feed.title}
                  </Typography>
                  <Tooltip title={feed.message}>
                    <Typography fontSize={12} fontWeight={400}>
                      {feed.message.substring(0, 45)}...
                    </Typography>
                  </Tooltip>
                </Stack>
                <Typography fontSize={18} fontWeight={400}>
                  {dayjs(feed.createdAt).format('hh:mm A')}
                </Typography>
              </Stack>
            )}
          </ViewportList>
        </Stack>
      ) : (
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center">
          <Typography variant="h6" fontWeight={700}>
            Nothing To Show
          </Typography>
          <Typography fontSize={14}>Batch feed is empty for today</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Feed;
