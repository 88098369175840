import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { FabricsAssignedToBatch } from '../../../constants/table-column/batch';

const FabricInfo = ({ batch }) => {
  const modifyFabricData = () => {
    return batch.fabricInfo.map((item) => {
      const order = batch.orderInfo.find((ord) => item.orderId === ord.id);
      return {
        ...item,
        fcNumber: order.fcNumber,
        partyName: order.partyName,
      };
    });
  };

  return (
    <Box
      width="100%"
      minWidth={300}
      minHeight={315}
      px={2}
      py={2}
      border={2}
      borderRadius={3}
      borderColor="rgba(0,0,0,0.1)"
      height="100%">
      <Typography variant="h6" fontWeight={700}>
        Fabrics
      </Typography>
      <Box height="calc(100% - 35px)">
        <DataGrid
          columns={FabricsAssignedToBatch}
          rows={modifyFabricData()}
          density="compact"
          components={{ Pagination: null }}
        />
      </Box>
    </Box>
  );
};

export default FabricInfo;
