import React, { useEffect, useState } from 'react';

import {
  InputAdornment,
  Stack,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { fetchAccountDeliveryList } from '../../api/order';
import NoDataOverlay from '../global/NoDataOverlay';
import { AllAccountDeliveryListColumns } from '../../constants/table-column/order';

const OrderAccoutBillsTab = ({ uid }) => {
  const [accountDeliveryList, setAccountDeliveryList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [accountInformation, setAccountInformation] = useState({});
  const textInput = React.useRef(null);
  const [deliveryStatus, setDeliveryStatus] = useState('');
  const [deliveryNumber, setDeliveryNumber] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);

  const fetchAccountDelivery = async ({
    deliveryNumber,
    deliveryStatus,
    page,
    pageSize,
  }) => {
    setIsloading(true);

    const response = await fetchAccountDeliveryList({
      uid: uid,
      searchKey: deliveryNumber,
      status: deliveryStatus,
      page: page,
      pageSize: pageSize,
    });
    setTotalEntries(response.totalEntries);
    setAccountDeliveryList(response.orderDeliveries);
    setAccountInformation(response);
    setIsloading(false);
  };

  const resetDeliverySearch = () => {
    setDeliveryStatus('');
    textInput.current.value = '';
    setDeliveryNumber('');
    fetchAccountDelivery('', '', page, pageSize);
  };

  const handleDeliverySubmit = (event) => {
    event.preventDefault();
    setDeliveryNumber(deliveryNumber);

    fetchAccountDelivery({ deliveryNumber, deliveryStatus, page, pageSize });
  };

  useEffect(() => {
    (async () =>
      await fetchAccountDelivery({
        deliveryNumber,
        deliveryStatus,
        page,
        pageSize,
      }))();
  }, [page, pageSize]);

  return (
    <Stack>
      <Stack mt={3}>
        <Stack alignItems="left" justifyContent="flex-start" width="100%">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
            Delivery Bill List:
          </Typography>
          <Stack my={1}>
            <form onSubmit={handleDeliverySubmit}>
              <Stack
                mt={2}
                direction={{ md: 'row', xs: 'column', sm: 'row' }}
                width="100%"
                spacing={2}
                alignItems="left"
                justifyContent="left">
                <TextField
                  size="small"
                  onChange={(event) => setDeliveryNumber(event.target.value)}
                  inputRef={textInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  flexWrap="wrap"
                  fullWidth
                  id="outlined-basic"
                  label="Search"
                  sx={{ maxWidth: 300 }}
                  variant="outlined"
                />
                {/* <FormControl fullWidth sx={{ maxWidth: 200 }}>
                  <InputLabel id="SearchStatus">Search Status</InputLabel>
                  <Select
                    size="small"
                    defaultValue={DeliveryStatusTextField}
                    value={deliveryTypeTextField}
                    labelId="SearchStatus"
                    id="demo-simple-select"
                    label="Search Status"
                    placeholder="Search Status"
                    onChange={(event) => {
                      setDeliveryStatus(event.target.value);

                      setDeliveryTypeTextField(event.target.value);
                    }}>
                    <MenuItem value="WAITING FOR APPROVAL">
                      Waiting for approval
                    </MenuItem>
                    <MenuItem value="IN QUEUE">In Queue</MenuItem>
                    <MenuItem value="DELIVERED">Delivered</MenuItem>
                    <MenuItem value="REJECTED">Rejected</MenuItem>
                  </Select>
                </FormControl> */}
                <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
                  <IconButton
                    title="Edit"
                    aria-label="edit"
                    type="submit"
                    sx={{
                      bgcolor: '#171820',
                      color: 'white',
                      borderRadius: 1,
                      ':hover': { bgcolor: '#303030' },
                    }}>
                    <SearchIcon />
                  </IconButton>

                  {/* reset button  */}
                  <IconButton
                    title="Edit"
                    aria-label="edit"
                    onClick={() => resetDeliverySearch()}
                    sx={{
                      bgcolor: '#171820',
                      color: 'white',
                      borderRadius: 1,
                      ':hover': { bgcolor: '#303030' },
                    }}>
                    <RestartAltIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </form>
          </Stack>
          <Stack alignItems="left" justifyContent="flex-start" width="100%">
            <Box
              width="100%"
              height={400}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={AllAccountDeliveryListColumns(accountInformation)}
                rows={accountDeliveryList}
                getRowId={(row) => row.id}
                rowCount={totalEntries}
                page={page}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                paginationMode="server"
                filterMode="server"
                onPageChange={(value) => setPage(value)}
                onPageSizeChange={(value) => setPageSize(value)}
                loading={isLoading}
                components={{
                  NoRowsOverlay: NoDataOverlay,
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrderAccoutBillsTab;
