import {
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '../../widgets/Button';
import AddIcon from '@mui/icons-material/Add';
import PageHeader from '../../widgets/PageHeader';

import { EditTwoTone, DeleteTwoTone } from '@mui/icons-material';

import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import {
  AllOrderListColumns,
  AllQuotationListColumns,
} from '../../constants/table-column/client';
import { CREATE_ORDER, CREATE_QUOTATION } from '../../constants/routeUrls';
import {
  deleteClient,
  fetchAllQuotation,
  fetchClientOrder,
  getClient,
} from '../../api/client';
import EditClient from '../../components/client/EditClient';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { successNoitif } from '../../utils/notification';
import PermissionWrapper from '../wrapper/PermissionWrapper';
import FileExporter from '../../components/global/FileExporter';
import NoDataOverlay from '../../components/global/NoDataOverlay';
import { setClientStatusColor } from '../../utils/client';
import PageTitle from '../../utils/PageTitle';
const ClientDetails = () => {
  const [clientInformation, setClientInformation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [quotationList, setQuotationList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [quotationPage, setQuotationPage] = useState(0);
  const [orderPage, setOrderPage] = useState(0);
  const [quotationPageSize, setQuotationPageSize] = useState(10);
  const [orderPageSize, setOrderPageSize] = useState(10);
  const [quotationStatus, setQuotationStatus] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [quotationStatusTextField, setQuotationStatusTextField] = useState(0);
  const [quotationTotalEntries, setQuotationTotalEntires] = useState(0);
  const [orderStatusTextField, setOrderStatusTextField] = useState(0);
  const [quotationTypeTextField, setQuotationTypeTextField] = useState(0);
  const [orderTypeTextField, setOrderTypeTextField] = useState(0);
  const [startdate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [dateValid, setDateValid] = useState(true);
  const [showEditClientForm, setShowEditClientForm] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isQuotationUpdated, setIsQuotationUpdated] = useState([]);
  const [isOrderUpdated, setIsOrderUpdated] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [orderTotalEntries, setOrderTotalEntries] = useState(0);
  const textInput = React.useRef(null);
  const quotationTextInput = React.useRef(null);
  const [fcNumber, setFcNumber] = useState('');
  const [quotationId, setQuotationId] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  let navigate = useNavigate();

  //taking the parameter value
  const { uid } = useParams();

  //fetching clinet api
  const fetchClients = async () => {
    setIsLoading(true);
    const response = await getClient(uid);

    setClientInformation(response.client);
    setIsLoading(false);
  };

  //fetching quotation api

  const fetchQuotation = async (
    uid,
    quotationId,
    quotationStatus,
    quotationPage,
    quotationPageSize,
  ) => {
    setIsLoading(true);

    const response = await fetchAllQuotation({
      uid: uid,
      searchKey: quotationId,
      status: quotationStatus,
      page: quotationPage,
      pageSize: quotationPageSize,
      quotationPageless: false,
    });
    setQuotationList(response.quotations);
    setQuotationTotalEntires(response.total);
    setIsLoading(false);
    setIsQuotationUpdated(!isQuotationUpdated);
  };

  //feching order api
  const fetchOrders = async (
    uid,
    fcNumber,
    orderStatus,
    orderPage,
    orderPageSize,
  ) => {
    setIsLoading(true);

    const response = await fetchClientOrder({
      uid: uid,
      fcNumber: fcNumber,
      status: orderStatus,
      page: orderPage,
      pageSize: orderPageSize,
      pageless: false,
    });

    setOrderList(response.orders);
    setOrderTotalEntries(response.totalEntries);
    setIsLoading(false);
    setIsOrderUpdated(!isOrderUpdated);
  };

  const handelDeleteClient = async () => {
    const response = await deleteClient({ uid });
    if (response) {
      successNoitif('client has been Deleted Successfully');
      setShowConfirmation(false);
      navigate(-1);
    }
  };

  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };
  useEffect(() => {
    (async () => {
      await fetchClients();
    })();
    setIsUpdated(false);
  }, [isUpdated]);

  useEffect(() => {
    (async () => {
      await fetchQuotation(uid);
      await fetchOrders(uid);
    })();
  }, []);

  useEffect(() => {
    (async () =>
      await fetchOrders(
        uid,
        fcNumber,
        orderStatus,
        orderPage,
        orderPageSize,
      ))();
  }, [uid, orderPage, orderPageSize]);

  useEffect(() => {
    (async () =>
      await fetchQuotation(
        uid,
        quotationId,
        quotationStatus,
        quotationPage,
        quotationPageSize,
      ))();
  }, [uid, quotationPage, quotationPageSize]);

  const resetQuotation = () => {
    fetchQuotation(uid, '', '', quotationPage, quotationPageSize);
    setQuotationStatus('');
    setQuotationStatusTextField(0);
    setQuotationTypeTextField(0);
    quotationTextInput.current.value = '';
    setQuotationId('');
  };

  const resetOrderSearch = () => {
    fetchOrders(uid, '', '', orderPage, orderPageSize);
    setOrderStatus('');
    setOrderStatusTextField(0);
    textInput.current.value = '';
    setOrderTypeTextField(0);
    setStartDate(null);
    setEndDate(null);
    setFcNumber('');
  };

  //quation search submit
  const handleQuotationSubmit = (event) => {
    event.preventDefault();
    setQuotationId(quotationId);
    fetchQuotation(
      uid,
      quotationId,
      quotationStatus,
      quotationPage,
      quotationPageSize,
    );
    setQuotationStatus(quotationStatus);
  };

  //order search submit

  const handleOrderSubmit = (event) => {
    event.preventDefault();
    setFcNumber(fcNumber);

    fetchOrders(uid, fcNumber, orderStatus, orderPage, orderPageSize);
  };

  const handelLoading = () => {
    setTimeout(() => {
      if (typeof clientInformation.length == 'undefined') {
        setNotFound(true);
      }
    }, 4000);
  };

  useEffect(() => {
    if (endDate) {
      if (startdate > endDate) {
        setDateValid(false);
      } else {
        setDateValid(true);
      }
    } else {
      setDateValid(true);
    }
  }, [startdate, endDate]);

  const fetchQuotationPagelessData = async () => {
    const response = await fetchAllQuotation({
      uid: uid,
      searchKey: quotationId,
      status: quotationStatus,
      quotationPageless: true,
    });

    return response.quotations;
  };

  const fetchOrderPagelessData = async () => {
    const response = await fetchClientOrder({
      uid: uid,
      fcNumber: fcNumber,
      status: orderStatus,
      pageless: true,
    });

    return response.orders;
  };
  return (
    <Stack
      spacing={2}
      width={{ md: '80%', sm: '80%', xs: '95%' }}
      mx="auto"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <PageTitle title="Client Details" />
      <Stack
        width="100%"
        direction="row"
        justifyContent="flex-start"
        mt={1}
        mb={2}>
        <Button onClick={() => navigate(-1)} variant="contained">
          <ArrowBackIosIcon fontSize="15px" />
          <Typography fontSize="15px">Back</Typography>
        </Button>
      </Stack>
      <Divider></Divider>
      {!clientInformation?.clientName ? (
        <Stack
          justifyContent="center"
          pt={10}
          width="100%"
          height="100%"
          alignItems="center">
          {notFound ? (
            <Stack
              direction="Column"
              justifyContent="center"
              alignItems="center">
              <CloudOffIcon sx={{ fontSize: 100 }} />
              <Typography> Data not found</Typography>
            </Stack>
          ) : (
            <Stack>
              {handelLoading()}
              <CircularProgress />
            </Stack>
          )}
        </Stack>
      ) : (
        <>
          {/* header section  */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            spacing={2}>
            <PageHeader>{clientInformation.clientName} </PageHeader>
          </Stack>

          <Stack
            sx={{
              height: 'auto',
              minHeight: 100,
              width: { xs: '100%', md: '47%', sm: '60%' },

              border: '2px solid rgba(0,0,0,0.2)',
              p: 3,
              paddingBottom: 0,
              m: 2,
              marginLeft: { xs: 0, md: 5 },
              borderRadius: 1.5,
            }}>
            <Stack direction="row" justifyContent="space-between" pb={2}>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontSize={{ xs: '0.7rem', md: '1rem' }}
                    sx={{ fontWeight: '500' }}>
                    Client UID:
                  </Typography>
                  <Typography fontSize={{ xs: '0.7rem', md: '1rem' }}>
                    {clientInformation.uid}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontSize={{ xs: '0.7rem', md: '1rem' }}
                    sx={{ fontWeight: '500' }}>
                    Short Code:
                  </Typography>
                  <Typography fontSize={{ xs: '0.7rem', md: '1rem' }}>
                    {clientInformation.shortCode}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontSize={{ xs: '0.7rem', md: '1rem' }}
                    sx={{ fontWeight: '500' }}>
                    Contact Person:
                  </Typography>
                  <Typography fontSize={{ xs: '0.7rem', md: '1rem' }}>
                    {clientInformation.contactPerson}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    fontSize={{ xs: '0.7rem', md: '1rem' }}
                    sx={{ fontWeight: '500' }}>
                    Contact Number:
                  </Typography>
                  <Typography fontSize={{ xs: '0.7rem', md: '1rem' }}>
                    {clientInformation.contactNumber}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="column" justifyContent="space-between">
                <Chip
                  size="small"
                  label={clientInformation.status}
                  sx={{
                    backgroundColor: setClientStatusColor(
                      clientInformation.status,
                    ),
                    color: '#ffff',
                  }}
                />
                <Stack
                  direction="row"
                  spacing={1}
                  width="100%"
                  justifyContent="flex-end">
                  <PermissionWrapper acceptedRoles="MANAGER,ADMIN,ACC">
                    <IconButton
                      onClick={() => setShowEditClientForm(true)}
                      aria-label="edit"
                      sx={{
                        bgcolor: '#fff',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#f7f7f7' },
                      }}>
                      <EditTwoTone />
                    </IconButton>
                    <IconButton
                      aria-label="log"
                      onClick={() => {
                        setShowConfirmation(true);
                      }}
                      sx={{
                        bgcolor: '#fff',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#f7f7f7' },
                      }}>
                      <DeleteTwoTone />
                    </IconButton>
                  </PermissionWrapper>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}

      {
        <EditClient
          show={showEditClientForm}
          toggleModal={setShowEditClientForm}
          client={clientInformation}
          setIsUpdated={setIsUpdated}
        />
      }

      {/* quotation section  */}
      <Stack>
        <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1}>
          Quotations
        </Typography>
        {/* Quotation search section  */}
        <Stack my={1} width="100%">
          <Grid container width="100%">
            <Grid item xs={12} sm={7} md={8}>
              <form onSubmit={handleQuotationSubmit}>
                <Stack
                  direction={{ md: 'row', xs: 'column', sm: 'row' }}
                  width="100%"
                  spacing={2}
                  alignItems="left"
                  justifyContent="left">
                  <TextField
                    size="small"
                    onChange={(event) => setQuotationId(event.target.value)}
                    inputRef={quotationTextInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    flexWrap="wrap"
                    fullWidth
                    id="outlined-basic"
                    label="Search"
                    sx={{ maxWidth: 250 }}
                    variant="outlined"
                  />
                  <FormControl fullWidth sx={{ maxWidth: 250 }}>
                    <InputLabel id="SearchStatus">Status</InputLabel>
                    <Select
                      size="small"
                      defaultValue={quotationStatusTextField}
                      value={quotationTypeTextField}
                      labelId="SearchStatus"
                      id="demo-simple-select"
                      label="Status"
                      placeholder="Status"
                      onChange={(event) => {
                        setQuotationStatus(event.target.value);
                        setQuotationTypeTextField(event.target.value);
                      }}>
                      <MenuItem value="VALID">Valid</MenuItem>
                      <MenuItem value="EXPIRED">Expired</MenuItem>
                      <MenuItem value="WAITING FOR APPROVAL">
                        Waiting For Apporval
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {/* Search button  */}
                  <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
                    <IconButton
                      title="Edit"
                      aria-label="edit"
                      type="submit"
                      sx={{
                        bgcolor: '#171820',
                        color: 'white',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#303030' },
                      }}>
                      <SearchIcon />
                    </IconButton>
                    {/* reset button  */}
                    <IconButton
                      title="Edit"
                      aria-label="edit"
                      onClick={() => resetQuotation()}
                      sx={{
                        bgcolor: '#171820',
                        color: 'white',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#303030' },
                      }}>
                      <RestartAltIcon />
                    </IconButton>
                    {/* downloadExcelSection  */}
                    <FileExporter
                      apiHandle={fetchQuotationPagelessData}
                      title={`${clientInformation?.clientName}-Client-Quotations`}
                      isUpdated={isQuotationUpdated}
                      mapKey={'UID'}
                    />
                  </Stack>
                </Stack>
              </form>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <Stack
                direction="row"
                width="100%"
                mt={{ md: '0px', sm: '0px', xs: '4px' }}
                justifyContent={{
                  xs: 'flex-start',
                  md: 'flex-end',
                  sm: 'flex-end',
                }}
                alignItems="center">
                <Button
                  variant="contained"
                  component={Link}
                  to={`${CREATE_QUOTATION}/${clientInformation?.uid}`}>
                  Create Quotation <AddIcon />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        {/* Table section */}
        {!quotationList ? (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Box
              pt={1}
              width="100%"
              height={300}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                density="compact"
                columns={AllQuotationListColumns}
                rows={quotationList}
                rowCount={quotationTotalEntries}
                getRowId={(row) => row.uid}
                page={quotationPage}
                pageSize={quotationPageSize}
                rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                paginationMode="server"
                filterMode="server"
                onPageChange={(value) => setQuotationPage(value)}
                onPageSizeChange={(value) => setQuotationPageSize(value)}
                loading={isLoading}
                components={{
                  NoRowsOverlay: NoDataOverlay,
                }}
              />
            </Box>
          </>
        )}
      </Stack>

      {/* order section  */}
      <Stack>
        <Typography fontSize={25} sx={{ fontWeight: '500' }}>
          Orders
        </Typography>

        {/* order search section  */}
        <Stack my={1} width="100%">
          <Grid container width="100%" alignItems="flex-end">
            <Grid item xs={12} sm={7} md={8}>
              <form onSubmit={handleOrderSubmit}>
                <Stack
                  mt={2}
                  direction={{ md: 'row', xs: 'column', sm: 'row' }}
                  width="100%"
                  spacing={2}
                  alignItems="left"
                  justifyContent="left">
                  {/* for order status search  */}
                  <TextField
                    size="small"
                    onChange={(event) => setFcNumber(event.target.value)}
                    inputRef={textInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    flexWrap="wrap"
                    fullWidth
                    id="outlined-basic"
                    label="Search"
                    sx={{ maxWidth: 250 }}
                    variant="outlined"
                  />
                  <FormControl fullWidth sx={{ maxWidth: 250 }}>
                    <InputLabel id="SearchStatus">Status</InputLabel>
                    <Select
                      size="small"
                      defaultValue={orderStatusTextField}
                      value={orderTypeTextField}
                      labelId="SearchStatus"
                      id="demo-simple-select"
                      label="Status"
                      placeholder="Status"
                      onChange={(event) => {
                        setOrderStatus(event.target.value);

                        setOrderTypeTextField(event.target.value);
                      }}>
                      <MenuItem value="NOT ASSIGNED">Not Assigned</MenuItem>
                      <MenuItem value="IN QUEUE">In Queue</MenuItem>
                      <MenuItem value="READY FOR DELIVERY">
                        Ready For Delivery
                      </MenuItem>
                      <MenuItem value="DELIVERED">Delivered</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Search button  */}
                  <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
                    <IconButton
                      disabled={!dateValid ? true : false}
                      title="Edit"
                      aria-label="edit"
                      type="submit"
                      sx={{
                        bgcolor: '#171820',
                        color: 'white',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#303030' },
                      }}>
                      <SearchIcon />
                    </IconButton>

                    {/* reset button  */}
                    <IconButton
                      title="Edit"
                      aria-label="edit"
                      onClick={() => resetOrderSearch()}
                      sx={{
                        bgcolor: '#171820',
                        color: 'white',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#303030' },
                      }}>
                      <RestartAltIcon />
                    </IconButton>
                    {/* downloadExcelSection  */}
                    <FileExporter
                      apiHandle={fetchOrderPagelessData}
                      title={`${clientInformation?.clientName}-Client-Orders`}
                      isUpdated={isOrderUpdated}
                      mapKey={'FC NUMBER'}
                    />
                  </Stack>
                </Stack>
              </form>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <Stack
                direction="row"
                width="100%"
                mt={{ md: '0px', sm: '0px', xs: '4px' }}
                justifyContent={{
                  xs: 'flex-start',
                  md: 'flex-end',
                  sm: 'flex-end',
                }}
                alignItems="center">
                <Button
                  variant="contained"
                  component={Link}
                  to={`${CREATE_ORDER}`}
                  endIcon={<AddIcon />}>
                  Add Order
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        {/* Table section */}
        {!orderList ? (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Box
              width="100%"
              mt={1}
              height={300}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={AllOrderListColumns({ uid })}
                rows={orderList}
                rowCount={orderTotalEntries}
                page={orderPage}
                pageSize={orderPageSize}
                rowsPerPageOptions={[1, 10, 15, 20, 25, 30, 35, 40, 45, 50]}
                paginationMode="server"
                filterMode="server"
                onPageChange={(value) => setOrderPage(value)}
                onPageSizeChange={(value) => setOrderPageSize(value)}
                loading={isLoading}
                components={{
                  NoRowsOverlay: NoDataOverlay,
                }}
              />
            </Box>
          </>
        )}
      </Stack>
      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to Delete Client?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
              }}>
              No
            </Button>
            <Button variant="contained" onClick={() => handelDeleteClient()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default ClientDetails;
