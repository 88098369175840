import React from 'react';
import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import Switch from '../../widgets/Switch';
import Button from '../../widgets/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { NoticeCreateSchema } from '../../validators/notice';
import { useState } from 'react';
import { createNotice } from '../../api/notice';
import { successNoitif } from '../../utils/notification';

const CreateNotice = ({ show, onClose, refreshData, socket }) => {
  const resolver = yupResolver(NoticeCreateSchema);
  const [postData, setPostData] = useState(null);

  const shouldShowConfrimCreationDialog = Boolean(postData);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: '',
      message: '',
      type: 'ANNOUNCEMENT',
    },
    mode: 'onChange',
    resolver,
  });

  const handleClose = () => {
    onClose();
    reset({
      title: '',
      message: '',
      type: 'ANNOUNCEMENT',
    });
  };

  const handleNoticeCreation = (data) => {
    setPostData(data);
    onClose();
  };

  const saveNotice = async () => {
    const { title, message, type, shouldPin } = postData;
    const response = await createNotice({
      title,
      message,
      type,
      shouldPin,
    });

    if (response) {
      await refreshData();
      successNoitif('Notice published');

      if (shouldPin) {
        socket.emit('notice');
      }
    }
    setPostData(null);
  };

  return (
    <>
      <Dialog open={shouldShowConfrimCreationDialog} onClose={handleClose}>
        <DialogContent>
          <Stack spacing={2}>
            <Typography fontSize={22} fontWeight={700}>
              Are you sure?
            </Typography>
            <Typography>
              Are you sure you want to publish this notice?
            </Typography>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => {
                  setPostData(null);
                }}>
                No
              </Button>
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={saveNotice}>
                Yes
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog fullWidth maxWidth="xs" open={show} onClose={handleClose}>
        <DialogContent>
          <Stack spacing={2} my={2}>
            <Typography fontSize={22} fontWeight={700}>
              Send Out New Notice
            </Typography>
            <TextField
              label="Title"
              size="small"
              placeholder="Title of notice"
              {...register('title')}
              error={!!errors?.title}
              helperText={errors?.title?.message}
            />
            <TextField
              label="Message"
              multiline
              size="small"
              minRows={3}
              maxRows={3}
              {...register('message')}
              error={!!errors?.message}
              helperText={errors?.message?.message}
            />
            <FormControl
              size="small"
              error={!!errors?.type}
              helperText={errors?.type?.message}>
              <InputLabel>Notice Type</InputLabel>
              <Select
                label="Notice Type"
                defaultValue="ANNOUNCEMENT"
                {...register('type')}
                error={!!errors?.type}
                helperText={errors?.type?.message}>
                <MenuItem value="ANNOUNCEMENT">Announcement</MenuItem>
                <MenuItem value="ALERT">Warning</MenuItem>
              </Select>
            </FormControl>
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              justifyContent="flex-end">
              <Switch color="secondary" {...register('shouldPin')} />
              <Typography fontSize={14} fontWeight={700}>
                Should Pin?
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={handleClose}>
                Clear
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={handleSubmit(handleNoticeCreation)}>
                Send Notice
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateNotice;
