import {
  AddCircleTwoTone,
  InfoTwoTone,
  SearchTwoTone,
} from '@mui/icons-material';
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchChemicalStats } from '../../api/inventory';
import FileExporter from '../../components/global/FileExporter';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAllDyeGroup } from '../../api/inventory';
import ChemicalList from '../../components/stock-management/ChemicalList';
import DyeList from '../../components/stock-management/DyeList';
import { ADD_CHEMICAL, STORE_MANAGEMENT } from '../../constants/routeUrls';
import PageHeader from '../../widgets/PageHeader';
import StatCard from '../../widgets/StatCard';
import PageTitle from '../../utils/PageTitle';

const StoreManagement = () => {
  const { tab: tabParams } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [stats, setStats] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [dyeGroups, setDygroups] = useState([]);
  const [selectedDyeGroup, setSelectedDyeGroup] = useState();

  const fetchDyeGroups = async (name) => {
    const response = await fetchAllDyeGroup(name);
    setDygroups(response);
  };

  const fetchStats = async () => {
    let response;
    if (activeTab === 0) {
      response = await fetchChemicalStats('Chemical');
    } else {
      response = await fetchChemicalStats('Dye');
    }

    setStats(response);
  };

  useEffect(() => {
    setActiveTab(parseInt(tabParams));
  }, [tabParams]);

  useEffect(() => {
    (async () => await fetchStats())();
  }, [activeTab]);

  useEffect(() => {
    (async () => await fetchDyeGroups())();
  }, []);

  const tabs = [
    {
      Element: (
        <ChemicalList
          query={searchQuery}
          searchStatus={searchStatus}
          tab={tabParams}
        />
      ),
    },
    {
      Element: (
        <DyeList
          query={searchQuery}
          searchStatus={searchStatus}
          dyeGroup={selectedDyeGroup}
          tab={tabParams}
        />
      ),
    },
  ];

  const currentTab = tabs[activeTab];

  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
    navigate(STORE_MANAGEMENT + `/${tab}`);
  };

  return (
    <Stack
      spacing={1.5}
      width="100%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      py={2}>
      <PageTitle title="Inventory" />
      <PageHeader>Inventory</PageHeader>
      <Stack
        direction="row"
        spacing={2}
        width="100%"
        alignItems="center"
        justifyContent="flex-start">
        {stats.map((item) => (
          <StatCard
            key={item.label}
            label={item.label}
            content={item.count}
            color="#4562f7"
          />
        ))}
        <Stack
          px={0.5}
          bgcolor="#f2f2f2"
          boxSizing="border-box"
          height="100%"
          justifyContent="space-evenly">
          <IconButton
            title="Add Stock"
            component={Link}
            to={ADD_CHEMICAL}
            state={{ tab: tabParams }}>
            <AddCircleTwoTone />
          </IconButton>
          <IconButton title="View Stock Status">
            <InfoTwoTone />
          </IconButton>
        </Stack>
        <Stack>
          <FileExporter
            apiHandle={'Dummy'}
            title={'Dummy'}
            isUpdated={'Dummy'}
            vertical={true}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        spacing={2}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ width: 'max-content' }}>
          <Tab
            label="Chemicals"
            sx={{
              px: 0.5,
              textTransform: 'capitalize',
              fontSize: 14,
              fontWeight: 700,
              bgcolor: '#e7e7e7',
              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#4562f7',
                transition: '0.3s ease',
              },
            }}
          />
          <Tab
            label="Dyes"
            sx={{
              px: 0.5,
              textTransform: 'capitalize',
              fontSize: 14,
              fontWeight: 700,
              bgcolor: '#e7e7e7',
              '&.Mui-selected': {
                color: '#fff',
                backgroundColor: '#4562f7',
                transition: '0.3s ease',
              },
            }}
          />
        </Tabs>
        <Stack
          width="50%"
          direction="row"
          justifyContent="flex-end"
          spacing={1}>
          <TextField
            size="small"
            label="Name/SKU"
            placeholder="Search by name or SKU"
            fullWidth
            sx={{ maxWidth: 300 }}
            onChange={(event) => setSearchQuery(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchTwoTone />
                </InputAdornment>
              ),
            }}
          />
          {activeTab === 1 && (
            <Autocomplete
              options={[{ name: 'All', id: 'all-dyes' }, ...dyeGroups]}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              onInputChange={async (event, name) => {
                if (name.length > 1) {
                  await fetchDyeGroups(name);
                } else if (name.length === 0) {
                  await fetchDyeGroups();
                }
              }}
              renderOption={(props, option) => (
                <Typography {...props}>{option.name}</Typography>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Dye Group"
                  placeholder="Search by dye group"
                  fullWidth
                  sx={{ width: 250 }}
                />
              )}
              onChange={(event, val) => setSelectedDyeGroup(val.id)}
            />
          )}
          <FormControl fullWidth size="small" sx={{ maxWidth: 300 }}>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={searchStatus}
              onChange={(event) => setSearchStatus(event.target.value)}>
              <MenuItem value="ALL">All</MenuItem>
              <MenuItem value="IN STOCK">In Stock</MenuItem>
              <MenuItem value="OUT OF STOCK">Out Of Stock</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack width="100%" height={450}>
        {currentTab.Element}
      </Stack>
    </Stack>
  );
};

export default StoreManagement;
