import React, { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import PageHeader from '../../widgets/PageHeader';
import Button from '../../widgets/Button';
import { AddCircleTwoTone } from '@mui/icons-material';
import { useState } from 'react';
import { fetchAllNotices, fetchPinnedNotice } from '../../api/notice';
import NoticeItem from '../notice-management/NoticeItem';
import CreateNotice from '../notice-management/CreateNotice';

const NoticeManagement = ({ socket }) => {
  const [showNoticeCreateModal, setShowNoticeCreateModal] = useState(false);
  const [pinnedNotice, setPinnedNotice] = useState(null);
  const [notices, setNotices] = useState({});

  const fetchData = async () => {
    const noticeData = await fetchAllNotices({});
    const pinnedNoticeData = await fetchPinnedNotice();

    if (noticeData) {
      setNotices(noticeData || {});
    }
    setPinnedNotice(pinnedNoticeData || null);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => fetchData(), 30 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Stack
      width="85%"
      mx="auto"
      px={2}
      py={2}
      boxSizing="border-box"
      minHeight="85vh"
      spacing={2}>
      <CreateNotice
        show={showNoticeCreateModal}
        onClose={() => setShowNoticeCreateModal(false)}
        refreshData={fetchData}
        socket={socket}
      />
      <Stack direction="row" justifyContent="space-between">
        <PageHeader>Notices</PageHeader>
        <Button
          onClick={() => setShowNoticeCreateModal(true)}
          variant="contained"
          endIcon={<AddCircleTwoTone />}
          sx={{ height: 'max-content', width: 'max-content' }}>
          Add Notice
        </Button>
      </Stack>
      <Typography fontSize={18} fontWeight={700}>
        Pinned Notice
      </Typography>
      {typeof pinnedNotice !== 'undefined' && pinnedNotice !== null ? (
        <NoticeItem
          notice={pinnedNotice}
          refreshData={fetchData}
          socket={socket}
        />
      ) : (
        <Stack
          px={2}
          py={2}
          border="2px solid rgba(0,0,0,0.2)"
          borderRadius={1}
          maxWidth={450}>
          <Typography variant="h5" fontWeight={700}>
            No Pinned Notice
          </Typography>
          <Typography fontSize={14} fontWeight={400}>
            No notice has been pinned yet
          </Typography>
        </Stack>
      )}
      <Stack spacing={2} maxHeight={550} overflow="auto">
        {notices ? (
          Object.keys(notices).map((item) => {
            const noticeList = notices[item];
            return (
              <Stack key={item} spacing={1}>
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  sx={{
                    bgcolor: '#fff',
                    color: '#303030',
                    position: 'sticky',
                    py: 1.5,
                    top: 0,
                  }}>
                  {item}
                </Typography>
                {noticeList.map((notice) => (
                  <NoticeItem
                    refreshData={fetchData}
                    key={notice?.uid}
                    notice={notice}
                    socket={socket}
                  />
                ))}
              </Stack>
            );
          })
        ) : (
          <Stack
            px={2}
            py={2}
            border="2px solid rgba(0,0,0,0.2)"
            borderRadius={1}
            maxWidth={450}>
            <Typography variant="h5" fontWeight={700}>
              No Notice
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              No notices to show at this moment
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default NoticeManagement;
