import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import menuItems from '../../constants/siderItems';
import { useSelector } from 'react-redux';

const SideBarItem = ({
  path,
  label,
  Icon,
  menuItems,
  isFullWidth,
  isSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (menuItems) {
    return (
      <>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {menuItems.map((item) => {
            const { Icon } = item;
            return (
              <MenuItem
                key={item.path}
                onClick={handleClose}
                component={Link}
                to={item.path}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-evenly">
                  <Icon />
                  <Typography fontSize={14} fontWeight={700}>
                    {item.label}
                  </Typography>
                </Stack>
              </MenuItem>
            );
          })}
        </Menu>
        <Tooltip key={path} title={isFullWidth ? '' : label} placement="right">
          <ListItem
            disablePadding
            sx={{ display: 'block' }}
            onClick={handleOpen}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}>
                <Icon
                  sx={{
                    fill: isSelected ? '#4562f7' : '#DDDDDD',
                  }}
                />
              </ListItemIcon>
              {isFullWidth && (
                <Typography
                  sx={{
                    ml: 2,
                    width: '100%',
                    color: isSelected ? '#4562f7' : '#fff',
                    textAlign: 'left',
                  }}>
                  {label}
                </Typography>
              )}
            </ListItemButton>
          </ListItem>
        </Tooltip>
      </>
    );
  }

  return (
    <Tooltip key={path} title={isFullWidth ? '' : label} placement="right">
      <ListItem
        disablePadding
        sx={{ display: 'block', color: '#fff' }}
        component={Link}
        to={path}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}>
            <Icon
              sx={{
                fill: isSelected ? '#4562f7' : '#DDDDDD',
              }}
            />
          </ListItemIcon>
          {isFullWidth && (
            <Typography
              sx={{
                ml: 2,
                width: '100%',
                color: isSelected ? '#4562f7' : '#fff',
                textAlign: 'left',
              }}>
              {label}
            </Typography>
          )}
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

const Sidebar = ({ isOpen }) => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const [sideMenu, setSideMenu] = useState([]);
  const [sideLowerMenu, setSideLowerMenu] = useState([]);

  useEffect(() => {
    const sideBar = menuItems.sidebarItems3(user?.roles);
    const sideBarLower = menuItems.sidebarItems4(user?.roles);
    setSideLowerMenu(sideBarLower);
    setSideMenu(sideBar);
  }, []);

  return (
    <Stack
      justifyContent="space-between"
      height="100%"
      sx={{ bgcolor: '#171820' }}>
      {sideMenu.length > 0 ? (
        <List sx={{ flexGrow: 1 }}>
          {sideMenu.map((item) => {
            const { path, label, Icon, menuItems } = item;
            const isSelected = location.pathname.endsWith(path);

            return (
              <SideBarItem
                isFullWidth={isOpen}
                key={path}
                path={path}
                label={label}
                Icon={Icon}
                menuItems={menuItems}
                isSelected={isSelected}
              />
            );
          })}
        </List>
      ) : (
        <></>
      )}

      <Divider />

      {sideLowerMenu.length > 0 ? (
        <List sx={{ flexGrow: 0 }}>
          {sideLowerMenu.map((item) => {
            const { path, label, Icon, menuItems } = item;
            const isSelected = location.pathname.endsWith(path);

            return (
              <SideBarItem
                isFullWidth={isOpen}
                key={path}
                path={path}
                label={label}
                Icon={Icon}
                menuItems={menuItems}
                isSelected={isSelected}
              />
            );
          })}
        </List>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default Sidebar;
