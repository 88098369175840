import Request from './request';

export const fetchAllNotices = async function (query) {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get('/notice', { ...query });
  if (response) {
    return response.notices;
  }

  return null;
};

export const fetchPinnedNotice = async function () {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/notice/pinned');
  if (response) {
    return response.notice;
  }

  return null;
};

export const createNotice = async function (formData) {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Post('/notice', formData);

  if (response) {
    return response.notice;
  }

  return null;
};

export const pinNotice = async (uid) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(`/notice/${uid}`);

  if (response) {
    return response.msg;
  }

  return null;
};

export const unPinNotice = async (uid) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(`/notice/unpin/${uid}`);

  if (response) {
    return response.msg;
  }

  return null;
};
