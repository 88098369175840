import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { reformateLotNumber } from '../../utils/order';

const OrderSummary = ({ fabric }) => {
  const calculateSubTotal = (fabric) => {
    let subTotal = 0;
    for (const batch of fabric.batchInfo) {
      subTotal = subTotal + batch.tempDeliverable;
    }
    return subTotal;
  };

  return (
    <>
      <Stack width="100%" pt={1}>
        <Stack>
          <Typography
            pb={1}
            fontSize={{ xs: 13, md: 15 }}
            sx={{ fontWeight: 'bold' }}>
            {fabric.fabricName} :
          </Typography>
        </Stack>
        <Stack>
          <TableContainer density="compact">
            <Table width="100%" aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Batch #</TableCell>
                  <TableCell align="center">Finished Stock (KG)</TableCell>
                  <TableCell align="center">Deliver Quanity (KG)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fabric?.batchInfo.map((row) => {
                  if (row.tempDeliverable > 0) {
                    return (
                      <TableRow
                        key={row.batchId}
                        sx={{
                          borderWidth: '1.1px',
                        }}>
                        <TableCell component="th" scope="row">
                          {reformateLotNumber(row.lotNumber)}
                        </TableCell>
                        <TableCell align="center">
                          {row.processQuantity - row.deliveredQuantity}
                        </TableCell>
                        <TableCell align="center">
                          {row.tempDeliverable}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Grid
          container
          spacing={1}
          pt={0.5}
          direction="row"
          alignItems="center">
          <Grid item xs={4} md={3}></Grid>
          <Grid item xs={4} md={4}>
            {' '}
            <Typography
              align="center"
              fontSize={{ xs: 12, md: 15 }}
              pr={{ md: '14%', xs: '8%' }}
              sx={{ fontWeight: 500 }}>
              Sub-Total Quantity:
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography
              align="right"
              pr={{ md: '26%', xs: '8%' }}
              fontSize={{ xs: 12, md: 15 }}
              sx={{ fontWeight: 500 }}>
              {calculateSubTotal(fabric)}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default OrderSummary;
