import * as yup from 'yup';

export const BatchCreateSchema = [
  yup.object().shape({
    orders: yup
      .array()
      .min(1, 'At least one customer is required')
      .of(yup.mixed())
      .required('At least one customer is required'),
    batchType: yup.string().required('Must select batch type'),
    processType: yup.string().required('Must select process type'),
    lotNumber: yup
      .string()
      .required('Must enter Lot number')
      .min(4, 'Must be at least 4 characters')
      .max(6, 'Cannot be more than 6 characters'),
    deliveryDate: yup.date().required('Must select delivery Date'),
    priority: yup.string(),
    loadingTime: yup.mixed().required('Must select Loading date'),
    reRunBatchRef: yup
      .string('Must enter reference Lot number')
      .when('processType', {
        is: 'RE PROCESS',
        then: yup.string().required('Must enter reference Lot number'),
      }),
  }),
  yup.object().shape({
    fabricInfo: yup
      .array()
      .of(
        yup.object().shape({
          usedQty: yup
            .number()
            .typeError('Must provide a number')
            .min(1)
            .required('Must be greater than 0')
            .positive('Must enter fabric weight'),

          rolls: yup
            .number()
            .typeError('Must provide roll count')
            .positive('Must be a positive number')
            .required('Must provide roll count'),
          fabricType: yup.string().required('Must enter fabric type'),
        }),
      )
      .min(1, 'At least one fabric is required')
      .required('At least one fabric is required'),
    machine: yup.mixed().required('Must select machine'),
  }),

  yup.object().shape({
    tasks: yup
      .array()
      .of(
        yup.object().shape({
          taskType: yup.string().required('Must select task type'),
          taskName: yup.string().required('Must select a task'),
          standardTimeHour: yup
            .number()
            .typeError('Invalid input for standard hour')
            .positive('Invalid input for standard hour')
            .min(0, 'Invalid input for standard hour'),
          standardTimeMinute: yup
            .number()
            .typeError('Invalid input for standard minute')
            .positive('Invalid input for standard minute')
            .min(0, 'Invalid input for standard minute')
            .max(60, 'Must not be greater than 60'),
        }),
      )
      .min(1, 'At least one task is required')
      .required('At least one task is required'),
  }),
];

export const BatchUpdateSchema = [
  yup.object().shape({
    orders: yup
      .array()
      .min(1, 'At least one customer is required')
      .of(yup.mixed())
      .required('At least one customer is required'),
    batchType: yup.string().required('Must select batch type'),
    processType: yup.string().required('Must select process type'),
    lotNumber: yup
      .string()
      .required('Must enter Lot number')
      .min(4, 'Must be at least 4 characters'),
    deliveryDate: yup.date().required('Must select delivery Date'),
    priority: yup.string(),
    loadingTime: yup.mixed().required('Must select Loading date'),
    reRunBatchRef: yup
      .string('Must enter reference Lot number')
      .when('processType', {
        is: 'RE PROCESS',
        then: yup.string().required('Must enter reference Lot number'),
      }),
  }),
  yup.object().shape({
    fabricInfo: yup
      .array()
      .of(
        yup.object().shape({
          usedQty: yup
            .number()
            .typeError('Must provide a number')
            .min(1)
            .required('Must be greater than 0')
            .positive('Must enter fabric weight'),
          rolls: yup
            .number()
            .typeError('Must provide roll count')
            .positive('Must be a positive number')
            .required('Must provide roll count'),
          fabricType: yup.string().required('Must enter fabric type'),
        }),
      )
      .min(1, 'At least one fabric is required')
      .required('At least one fabric is required'),
    machine: yup.mixed().required('Must select machine'),
  }),

  yup.object().shape({
    tasks: yup
      .array()
      .of(
        yup.object().shape({
          taskType: yup.string().required('Must select task type'),
          taskName: yup.string().required('Must select a task'),
          standardTimeHour: yup
            .number()
            .typeError('Invalid input for standard hour')
            .positive('Invalid input for standard hour')
            .min(0, 'Invalid input for standard hour'),
          standardTimeMinute: yup
            .number()
            .typeError('Invalid input for standard minute')
            .positive('Invalid input for standard minute')
            .min(0, 'Invalid input for standard minute')
            .max(60, 'Must not be greater than 60'),
        }),
      )
      .min(1, 'At least one task is required')
      .required('At least one task is required'),
  }),
];

export const BatchReProcessSchema = [
  yup.object().shape({
    orders: yup
      .array()
      .min(1, 'At least one customer is required')
      .of(yup.mixed())
      .required('At least one customer is required'),
    batchType: yup.string().required('Must select batch type'),
    processType: yup.string().required('Must select process type'),
    lotNumber: yup
      .string()
      .required('Must enter Lot number')
      .min(4, 'Must be at least 4 characters'),
    deliveryDate: yup.date().required('Must select delivery Date'),
    priority: yup.string(),
    loadingTime: yup.mixed().required('Must select Loading date'),
    reRunBatchRef: yup
      .string('Must enter reference Lot number')
      .when('processType', {
        is: 'RE PROCESS',
        then: yup.string().required('Must enter reference Lot number'),
      }),
  }),
  yup.object().shape({
    fabricInfo: yup
      .array()
      .of(
        yup.object().shape({
          reProcessQty: yup
            .number()
            .typeError('Must provide a number')
            .min(1)
            .max(
              yup.ref('usedQty'),
              'Re-process quantity can not exceed used quantity',
            )
            .required('Must be greater than 0')
            .positive('Must enter fabric re process weight'),
          rolls: yup
            .number()
            .typeError('Must provide roll count')
            .positive('Must be a positive number')
            .required('Must provide roll count'),
          fabricType: yup.string().required('Must enter fabric type'),
        }),
      )
      .min(1, 'At least one fabric is required')
      .required('At least one fabric is required'),
    machine: yup.mixed().required('Must select machine'),
  }),

  yup.object().shape({
    tasks: yup
      .array()
      .of(
        yup.object().shape({
          taskType: yup.string().required('Must select task type'),
          taskName: yup.string().required('Must select a task'),
          standardTimeHour: yup
            .number()
            .typeError('Invalid input for standard hour')
            .positive('Invalid input for standard hour')
            .min(0, 'Invalid input for standard hour'),
          standardTimeMinute: yup
            .number()
            .typeError('Invalid input for standard minute')
            .positive('Invalid input for standard minute')
            .min(0, 'Invalid input for standard minute')
            .max(60, 'Must not be greater than 60'),
        }),
      )
      .min(1, 'At least one task is required')
      .required('At least one task is required'),
  }),
];
