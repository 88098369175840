import {
  Autocomplete,
  Divider,
  Grid,
  Stack,
  TextField,
  IconButton,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '../../widgets/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../widgets/PageHeader';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import CreateShadeColor from '../../components/order/CreateShadeColor';
import CreateFabricType from '../../components/order/CreateFabricType';
import { Box } from '@mui/system';
import CreateProcessDescriptionForm from '../../components/order/CreateProcessDescriptionForm';

import {
  createOrder,
  fetchOrderClients,
  fetchOrderFabricInformation,
  fetchOrderQuotation,
} from '../../api/order';
import { fetchSpecificQuotation } from '../../api/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrderCreationValidator } from '../../validators/order';
import { successNoitif } from '../../utils/notification';
import PageTitle from '../../utils/PageTitle';
const CreateOrder = () => {
  let navigate = useNavigate();
  // const [showAddShadeGroupForm, setShowAddShadeGroupForm] = useState(false);
  const [showAddShadeColorForm, setShowAddColorForm] = useState(false);
  const [showAddFabricTypeForm, setShowAddFabricTypeForm] = useState(false);
  const [showProcessDescriptionForm, setShowProcessDescriptionForm] =
    useState(false);

  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [quotationList, setQuotationList] = useState([]);
  const [selectedQuotation, setSelectedQuotation] = useState({});
  const [shadeGroup, setShadeGroup] = useState([]);

  // const [colorList, setColorList] = useState([]);
  const [shadeArray, setShadeArray] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [fabricList, setFabricList] = useState([]);
  const [additionalStepCharges, setAdditionalStepCharges] = useState({});

  const [processFormArray, setProecessFormArray] = useState([]);
  const [shadeIndex, setShadeIndex] = useState(0);
  const [autoFieldRest, setAutoFieldRest] = useState(false);
  const [quotationChangeRest, setQuotationChangeRest] = useState(false);
  const [shadeChangeRest, setShadeChangeRest] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fullFormData, setFullFormData] = useState({});
  const [hasFabricAdded, setHasFabricAdded] = useState(false);
  // const [shadeArrayChecked, setShadeArrayChecked] = useState(false);

  const fetchClientNameList = async (uid) => {
    const response = await fetchOrderClients({
      searchKey: uid,
      searchType: 'live',
    });

    setClientList(response.clients);
  };

  const fetchQuotationList = async (uid) => {
    const response = await fetchOrderQuotation({
      uid: uid,
      pageless: true,
    });

    setQuotationList(response.quotations);
  };

  const fetchfabricList = async () => {
    const res = await fetchOrderFabricInformation();

    setFabricList(res.fabricInformation);
  };

  const fetchShadeDetails = async (uid) => {
    const response = await fetchSpecificQuotation({ uid: uid });

    setAdditionalStepCharges(response?.quotations?.additionalStepCharges);
    setShadeGroup(response?.quotations?.clientShades);
  };

  const resolver = yupResolver(OrderCreationValidator);
  const {
    register,
    formState: { errors },

    handleSubmit,
    setValue,

    reset,
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
    resolver,
  });

  //useArrayField
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'fabricInformation', // unique name for your Field Array
  });

  const deliveryDate = useWatch({ control, name: 'deliveryDate' });
  const issueDate = useWatch({ control, name: 'issueDate' });
  const clientContactPerson = useWatch({
    control,
    name: 'clientContactPerson',
  });
  const clientContactNumber = useWatch({
    control,
    name: 'clientContactNumber',
  });
  const clientReference = useWatch({
    control,
    name: 'clientReference',
  });

  const handelFormSubmit = (data) => {
    // TODO: Refactor later
    let tempArray = [];

    for (let index = 0; index < shadeArray.length; index++) {
      let seletecFabricIndex = 0;
      for (let fabIndex = 0; fabIndex < fabricList.length; fabIndex++) {
        if (
          fabricList[fabIndex].fabricName ==
          data.fabricInformation[index].fabricType
        ) {
          seletecFabricIndex = fabricList[fabIndex].fabricTypeId;
        }
      }
      let tempObject = {
        shadeGroupName: shadeArray[index].shadeName,
        shadeGroupId: shadeArray[index].id,
        colourName: shadeArray[index].selectedColor.colourName,
        colourId: shadeArray[index].selectedColor.colourId,
        shadeRange: shadeArray[index].shadeRange,
        fabricTypeName: data.fabricInformation[index].fabricType,
        fabricTypeId: seletecFabricIndex,
        gsm: data.fabricInformation[index].gsm,
        dia: data.fabricInformation[index].dia,
        processName: shadeArray[index].processInfo.processInformation.shortName,
        processDetails: {
          additionalStepCharge:
            shadeArray[index].processInfo.additionalStepCharge,
          processCharge: shadeArray[index].processInfo.processCharge,
        },
        rate: shadeArray[index].processInfo.rate,
        quantity: shadeArray[index].quantity,
        labDipRef: data.fabricInformation[index].labDip,
      };
      tempArray.push(tempObject);
    }

    const modifiedData = {
      clientUid: selectedClient.uid,
      quotationUid: data.quotationName,
      fcNumber: data.fcNumber,
      issueDate: data.issueDate,
      deliveryDate: data.deliveryDate,
      deliveryAddress: data?.deliveryAddress,
      clientRefNo: data?.clientReference,
      clientContactPerson: data?.clientContactPerson,
      clientContactPhone: data?.clientContactNumber,
      salesPerson: data?.salePersonName,
      remarks: data?.remarks,
      fabricInfo: tempArray,
    };

    setFullFormData(modifiedData);
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  //colse confrimation dialog
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    orderFormSubmit();
  };

  //async calling api to post form data
  const orderFormSubmit = async () => {
    const response = await createOrder(fullFormData);

    if (response) {
      successNoitif('Order has been Created');
      navigate(-1);
    }
  };

  const validationCheck = () => {
    setIsSubmitted(true);

    let count = 1;
    for (const element of shadeArray) {
      if (
        element.shadeName &&
        element.selectedColor &&
        element.quantity &&
        element.processInfo
      ) {
        continue;
      } else {
        count = 0;
      }
    }
    if (count == 1) {
      handleSubmit(handelFormSubmit)();
    }
  };

  const handelClientSelection = () => {
    if (selectedClient?.uid) {
      fetchQuotationList(selectedClient.uid);
    }
  };

  const handelClientSearch = (value) => {
    fetchClientNameList(value);
  };

  const handelQuotationSelection = () => {
    if (selectedQuotation?.uid) {
      fetchShadeDetails(selectedQuotation?.uid);
    }
  };

  const handelShadeGroupSelection = (index, newValue) => {
    if (
      shadeArray.length <= index &&
      typeof shadeArray[index]?.quantity == 'undefined' &&
      typeof shadeArray[index]?.rate == 'undefined' &&
      typeof shadeArray[index]?.processInformation?.processName == 'undefined'
    ) {
      setShadeArray((oldArray) => [...oldArray, newValue]);
    } else {
      const newItems = shadeArray.map((item, i) => {
        if (i == index) {
          item = newValue;
          item.quantity = shadeArray[index].quantity;
          item.rate = shadeArray[index].rate;
          item.processInformation = shadeArray[index].processInformation;
        }
        return item;
      });
      setShadeArray(newItems);
    }
  };

  const handelShadeColorSelection = (index, value) => {
    let tempArray = JSON.parse(JSON.stringify(shadeArray));
    tempArray[index].selectedColor = value;

    setShadeArray(tempArray);
  };

  const handelShadeRange = (index, newValue) => {
    let tempArray = JSON.parse(JSON.stringify(shadeArray));
    tempArray[index].shadeRange = newValue;
    setShadeArray(tempArray);
  };

  const handelQuantityChange = (index, newValue) => {
    const updateItem = shadeArray.map((item, i) => {
      if (i == index) {
        item.quantity = newValue;
      }
      return item;
    });
    setShadeArray(updateItem);
    if (shadeArray.length <= index) {
      let newShade = { quantity: 0 };
      newShade.quantity = newValue;
      setShadeArray((oldArray) => [...oldArray, newShade]);
    }
  };

  const handelRemove = (index) => {
    setShadeArray([
      ...shadeArray.slice(0, index),
      ...shadeArray.slice(index + 1),
    ]);
    setProecessFormArray([
      ...processFormArray.slice(0, index),
      ...processFormArray.slice(index + 1),
    ]);
    setTotalCount(totalCount - 1);
  };

  const handelAppend = () => {
    setTotalCount(totalCount + 1);
  };

  useEffect(() => {
    (async () => {
      await fetchClientNameList();
      await fetchfabricList();
    })();
    const date = new Date();
    setValue('deliveryDate', date);
    setValue('issueDate', date);

    append();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchfabricList();
    })();
  }, [hasFabricAdded]);

  useEffect(() => {
    setValue('clientContactPerson', selectedClient.contactPerson);
    setValue('clientContactNumber', selectedClient.contactNumber);
    setValue('clientReference', selectedClient.shortCode);
  }, [selectedClient]);

  useEffect(() => {}, [clientList]);

  useEffect(() => {
    let calTotalQuantity = 0;
    for (const element of shadeArray) {
      calTotalQuantity = calTotalQuantity + parseFloat(element?.quantity || 0);
    }
    setTotalQuantity(calTotalQuantity);
  }, [shadeArray]);

  useEffect(() => {
    if (shadeArray.length > 0) {
      reset();
      append();
      setTotalCount(0);
    }
    setShadeArray([]);
    setShadeChangeRest(!shadeChangeRest);
  }, [autoFieldRest, quotationChangeRest]);

  const checkRate = (rate) => {
    if (parseFloat(rate) == 0 || typeof rate == 'undefined') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Stack
      spacing={2}
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <Stack width={{ md: '80%', sm: '100%' }} mx="auto">
        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-start"
          mt={1}
          mb={2}>
          <Button onClick={() => navigate(-1)} variant="contained">
            <ArrowBackIosIcon fontSize="15px" />
            <Typography fontSize="15px">Back</Typography>
          </Button>
        </Stack>
        <Divider></Divider>
        <PageHeader>Create Order </PageHeader>
      </Stack>
      <form>
        <Stack
          width={{ md: '80%', sm: '100%' }}
          mx="auto"
          direction="row"
          spacing={4}
          justifyContent="space-between"
          alignItems="flex-start">
          <Stack
            sx={{
              height: 'auto',
              minHeight: 200,
              width: { xs: '100%', md: '40%', sm: '60%' },
            }}>
            <Typography fontSize={20} sx={{ fontWeight: '500' }} my={1} mt={1}>
              Basic Order Information
            </Typography>
            <Grid container spacing={2} direction="row" alignItems="center">
              {/* Client Name */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography fontSize={15}>
                    Client Name <sup style={{ color: 'red' }}>*</sup> :
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                {clientList ? (
                  <Stack>
                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="combo-box-demo"
                      options={clientList}
                      getOptionLabel={(option) => option?.clientName}
                      size="small"
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ fontSize: '12px', textAlign: 'left' }}
                          {...props}>
                          {option.clientName}
                        </Box>
                      )}
                      onChange={(event, newValue) => {
                        setSelectedClient(newValue);
                        setAutoFieldRest(!autoFieldRest);
                      }}
                      onSelect={(event) => {
                        handelClientSelection();
                        handelClientSearch(event.target.value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...register('ClientName')}
                          error={errors?.ClientName}
                          helperText={errors?.ClientName?.message}
                        />
                      )}
                    />
                  </Stack>
                ) : (
                  <></>
                )}
              </Grid>
              {/* select quotation   */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Select Quotation <sup style={{ color: 'red' }}>*</sup> :
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack>
                  <Autocomplete
                    key={autoFieldRest}
                    disablePortal
                    disableClearable
                    id="combo-box-demo"
                    noOptionsText={
                      <Typography fontSize={12}>No Options</Typography>
                    }
                    options={quotationList}
                    getOptionLabel={(option) => option.uid}
                    size="small"
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ fontSize: '12px', textAlign: 'left' }}
                        {...props}>
                        {option.uid}
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      setSelectedQuotation(newValue);
                      setQuotationChangeRest(!quotationChangeRest);
                    }}
                    onSelect={(event) => {
                      if (event.target.value.length > 3) {
                        handelQuotationSelection();
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...register('quotationName')}
                        error={errors?.quotationName}
                        helperText={errors?.quotationName?.message}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              {/* Fc Number */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    FC number <sup style={{ color: 'red' }}>*</sup> :
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  size="small"
                  sx={{ fontSize: '15px' }}
                  {...register('fcNumber')}
                  error={errors?.fcNumber}
                  helperText={errors?.fcNumber?.message}
                />
              </Grid>

              {/* Sales Person Name */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Sales Person Name:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  sx={{ fontSize: '15px' }}
                  size="small"
                  {...register('salePersonName')}
                  error={errors?.salePersonName}
                  helperText={errors?.salePersonName?.message}
                />
              </Grid>

              {/* issuDate  */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Issue Date <sup style={{ color: 'red' }}>*</sup> :{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    minDate={new Date()}
                    value={issueDate}
                    onChange={(value) => setValue('issueDate', value)}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        type="date"
                        {...params}
                        style={{ width: '100%' }}
                        error={errors?.issueDate}
                        helperText={errors?.issueDate?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              {/* Delivery Date */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Delivery Date <sup style={{ color: 'red' }}>*</sup> :{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    minDate={new Date()}
                    value={deliveryDate}
                    onChange={(value) => setValue('deliveryDate', value)}
                    renderInput={(params) => (
                      <TextField
                        sx={{ fontSize: '15px' }}
                        size="small"
                        type="date"
                        {...params}
                        style={{ width: '100%' }}
                        error={errors?.deliveryDate}
                        helperText={errors?.deliveryDate?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Stack>
          <Stack
            sx={{
              height: 'auto',
              minHeight: 200,
              width: { xs: '100%', md: '40%', sm: '60%' },
            }}>
            <Typography fontSize={20} sx={{ fontWeight: '500' }} my={1} mt={1}>
              Client Information
            </Typography>
            <Grid container spacing={2} direction="row" alignItems="center">
              {/* Client Reference */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Client Contact Person:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  sx={{ fontSize: '15px' }}
                  size="small"
                  value={clientContactPerson || ''}
                  onChange={(value) => setValue('clientContactPerson', value)}
                  {...register('clientContactPerson')}
                  error={errors?.clientContactPerson}
                  helperText={errors?.clientContactPerson?.message}
                />
              </Grid>

              {/* Client Reference */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Client Contact No:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  sx={{ fontSize: '15px' }}
                  size="small"
                  value={clientContactNumber || ''}
                  onChange={(value) => setValue('clientContactNumber', value)}
                  {...register('clientContactNumber')}
                  error={errors?.clientContactNumber}
                  helperText={errors?.clientContactNumber?.message}
                />
              </Grid>

              {/* Client Reference:*/}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Client Reference:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  sx={{ fontSize: '15px' }}
                  size="small"
                  value={clientReference || ''}
                  onChange={(value) => setValue('clientReference', value)}
                  {...register('clientReference')}
                  error={errors?.clientReference}
                  helperText={errors?.clientReference?.message}
                />
              </Grid>

              {/* Delivery Address */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Delivery Address:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  size="small"
                  sx={{ fontSize: '15px' }}
                  {...register('deliveryAddress')}
                  error={errors?.deliveryAddress}
                  helperText={errors?.deliveryAddress?.message}
                />
              </Grid>
            </Grid>
            {/* remarks  */}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              pb={3}
              mt={2}>
              <TextField
                label="Remarks"
                style={{ width: '100%' }}
                // focused
                multiline
                rows={5}
                placeholder="Remarks"
                {...register('remarks')}
                error={errors?.remarks}
                helperText={errors?.remarks?.message}
              />
            </Stack>
          </Stack>
        </Stack>

        {/* Fabric Information  */}
        <Stack>
          <Stack width={{ md: '80%', sm: '100%' }} mx="auto">
            <Typography fontSize={20} sx={{ fontWeight: '500' }} my={1}>
              Fabric Information
            </Typography>
          </Stack>

          <Divider></Divider>
          <TableContainer
            sx={{ 'td, th': { border: 0, padding: 1, margin: 1 } }}>
            <Table
              sx={{ minWidth: 650, 'td, th': { border: 0 }, border: 0 }}
              aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" sx={{ fontSize: '12px' }}>
                    #
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    SHADE GROUP <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    COLOR <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    SHADE % <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    FABRIC TYPE <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    GSM <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    DIA <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    PROCESS <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    RATE{' '}
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    QUANTITY (KG) <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    LAB DIP
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: '12px' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  tr: { borderTop: 0.5, borderBottom: 0.5 },
                  td: { borderTop: 0.5, borderBottom: 0.5 },
                }}>
                {fields.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell align="right" component="th" scope="row">
                      <Typography>{index + 1}</Typography>
                    </TableCell>
                    <TableCell align="center" width="130px">
                      <Autocomplete
                        noOptionsText={
                          <Typography fontSize={12}>No Options</Typography>
                        }
                        key={autoFieldRest}
                        disablePortal
                        disableClearable
                        options={shadeGroup}
                        sx={{
                          '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                            fontSize: 12,
                          },
                        }}
                        getOptionLabel={(option) => option.shadeName}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ fontSize: '12px', textAlign: 'left' }}
                            {...props}>
                            {option.shadeName}
                          </Box>
                        )}
                        size="small"
                        onChange={(event, newValue) => {
                          handelShadeGroupSelection(index, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{ width: '140px' }}
                            {...params}
                            error={
                              (shadeArray[index]?.shadeName == '' ||
                                typeof shadeArray[index]?.shadeName ==
                                  'undefined') &&
                              isSubmitted
                            }
                            helperText={
                              (shadeArray[index]?.shadeName == '' ||
                                typeof shadeArray[index]?.shadeName ==
                                  'undefined') &&
                              isSubmitted
                                ? 'invalid Name'
                                : ''
                            }
                          />
                        )}
                      />
                    </TableCell>

                    <TableCell align="center" width="130px">
                      <Stack direction="row" spacing={1}>
                        <Autocomplete
                          key={shadeChangeRest}
                          disablePortal
                          disableClearable
                          noOptionsText={
                            <Typography fontSize={12}>No Options</Typography>
                          }
                          id="combo-box-demo"
                          sx={{
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                              fontSize: 12,
                            },
                          }}
                          options={shadeArray[index]?.colours || []}
                          onChange={(event, value) => {
                            handelShadeColorSelection(index, value);
                          }}
                          getOptionLabel={(option) => option.colourName || ''}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ fontSize: '12px', textAlign: 'left' }}
                              {...props}>
                              {option.colourName}
                            </Box>
                          )}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: '120px' }}
                              {...params}
                              error={
                                (shadeArray[index]?.selectedColor?.colourName ==
                                  '' ||
                                  typeof shadeArray[index]?.selectedColor
                                    ?.colourName == 'undefined') &&
                                isSubmitted
                              }
                              helperText={
                                (shadeArray[index]?.selectedColor?.colourName ==
                                  '' ||
                                  typeof shadeArray[index]?.selectedColor
                                    ?.colourName == 'undefined') &&
                                isSubmitted
                                  ? 'invalid Color'
                                  : ''
                              }
                            />
                          )}
                        />
                        <IconButton
                          size="small"
                          title="Add"
                          aria-label="Add"
                          disabled={shadeArray[index] ? false : true}
                          onClick={() => {
                            setShowAddColorForm(true);
                            setShadeIndex(index);
                          }}
                          sx={{
                            bgcolor: '#fff',
                            color: '#f44336',
                            borderRadius: 1,
                            ':hover': { bgcolor: '#f44336', color: '#fff' },
                          }}>
                          <AddIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>

                    <TableCell align="center" width="150px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }} // font size of input text
                        value={shadeArray[index]?.shadeRange || ''}
                        onChange={(event) => {
                          handelShadeRange(index, event.target.value);
                        }}
                        // {...register(`fabricInformation.${index}.shadeRange`)}
                        error={errors?.fabricInformation?.[index]?.shadeRange}
                        helperText={
                          errors?.fabricInformation?.[index]?.shadeRange
                            ?.message
                        }
                      />
                    </TableCell>

                    <TableCell align="center" width="130px">
                      {fabricList ? (
                        <Stack direction="row" spacing={1} width="100%">
                          <Autocomplete
                            disablePortal
                            disableClearable
                            noOptionsText={
                              <Typography fontSize={12}>No Options</Typography>
                            }
                            id="combo-box-demo"
                            sx={{
                              '& .MuiAutocomplete-input, & .MuiInputLabel-root':
                                {
                                  fontSize: 12,
                                },
                            }}
                            options={fabricList}
                            getOptionLabel={(option) => option?.fabricName}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ fontSize: '12px', textAlign: 'left' }}
                                {...props}>
                                {option.fabricName}
                              </Box>
                            )}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                sx={{ width: '120px' }}
                                {...params}
                                {...register(
                                  `fabricInformation.${index}.fabricType`,
                                )}
                                error={
                                  errors?.fabricInformation?.[index]?.fabricType
                                }
                                helperText={
                                  errors?.fabricInformation?.[index]?.fabricType
                                    ?.message
                                }
                              />
                            )}
                          />
                          <IconButton
                            size="small"
                            title="Add"
                            aria-label="Add"
                            onClick={() => {
                              setShowAddFabricTypeForm(true);
                              setShadeIndex(index);
                            }}
                            sx={{
                              bgcolor: '#fff',
                              color: '#f44336',
                              borderRadius: 1,
                              ':hover': { bgcolor: '#f44336', color: '#fff' },
                            }}>
                            <AddIcon />
                          </IconButton>
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell align="center" width="110px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        {...register(`fabricInformation.${index}.gsm`)}
                        error={errors?.fabricInformation?.[index]?.gsm}
                        helperText={
                          errors?.fabricInformation?.[index]?.gsm?.message
                        }
                      />
                    </TableCell>
                    <TableCell align="center" width="110px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        {...register(`fabricInformation.${index}.dia`)}
                        error={errors?.fabricInformation?.[index]?.dia}
                        helperText={
                          errors?.fabricInformation?.[index]?.dia?.message
                        }
                      />
                    </TableCell>
                    <TableCell align="center" width="210px">
                      <Stack direction="row" spacing={1}>
                        <TextField
                          disabled
                          size="small"
                          inputProps={{ style: { fontSize: 12 } }}
                          value={
                            shadeArray[index]?.processInfo?.processInformation
                              ?.shortName || ''
                          }
                          error={
                            (shadeArray[index]?.processInfo?.shortName == '' ||
                              typeof shadeArray[index]?.processInfo
                                ?.processInformation?.shortName ==
                                'undefined') &&
                            isSubmitted
                          }
                          helperText={
                            (shadeArray[index]?.processInfo?.shortName == '' ||
                              typeof shadeArray[index]?.processInfo
                                ?.processInformation?.shortName ==
                                'undefined') &&
                            isSubmitted
                              ? 'invalid Type'
                              : ''
                          }
                        />
                        <IconButton
                          size="small"
                          title="Delete"
                          aria-label="Delete"
                          disabled={shadeArray[index] ? false : true}
                          onClick={() => {
                            setShowProcessDescriptionForm(true);
                            setShadeIndex(index);
                          }}
                          sx={{
                            bgcolor: '#fff',
                            color: '#f44336',
                            borderRadius: 1,
                            ':hover': { bgcolor: '#f44336', color: '#fff' },
                          }}>
                          <AddIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                    <TableCell align="center" width="150px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        value={shadeArray[index]?.processInfo?.rate || 0}
                        error={
                          checkRate(shadeArray[index]?.processInfo?.rate) &&
                          isSubmitted
                        }
                        helperText={
                          checkRate(shadeArray[index]?.processInfo?.rate) &&
                          isSubmitted
                            ? 'invalid rate'
                            : ''
                        }
                      />
                    </TableCell>
                    <TableCell align="center" width="150px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        onChange={(event) => {
                          handelQuantityChange(index, event.target.value);
                        }}
                        error={
                          (shadeArray[index]?.quantity &&
                            (isNaN(shadeArray[index]?.quantity) ||
                              parseInt(shadeArray[index]?.quantity) < 0)) ||
                          (isSubmitted &&
                            (isNaN(shadeArray[index]?.quantity) ||
                              parseInt(shadeArray[index]?.quantity) < 0 ||
                              shadeArray[index]?.quantity == ''))
                        }
                        helperText={
                          (shadeArray[index]?.quantity &&
                            (isNaN(shadeArray[index]?.quantity) ||
                              parseInt(shadeArray[index]?.quantity) < 0)) ||
                          (isSubmitted &&
                            (isNaN(shadeArray[index]?.quantity) ||
                              parseInt(shadeArray[index]?.quantity) < 0 ||
                              shadeArray[index]?.quantity == ''))
                            ? 'invalid quantity'
                            : ''
                        }
                      />
                    </TableCell>

                    <TableCell align="center" width="130px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        {...register(`fabricInformation.${index}.labDip`)}
                        error={errors?.fabricInformation?.[index]?.labDip}
                        helperText={
                          errors?.fabricInformation?.[index]?.labDip?.message
                        }
                      />
                    </TableCell>

                    <TableCell align="left">
                      <IconButton
                        size="small"
                        title="Delete"
                        disabled={totalCount < 1 ? true : false}
                        aria-label="Delete"
                        onClick={() => {
                          remove(index);
                          handelRemove(index);
                        }}
                        sx={{
                          bgcolor: '#fff',
                          color: '#f44336',
                          borderRadius: 1,
                          ':hover': { bgcolor: '#f44336', color: '#fff' },
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            width={{ md: '80%', sm: '100%' }}
            mx="auto"
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end">
            {/* add button  */}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              mt={2}>
              <Button
                fontSize="15px"
                type="button"
                disabled={shadeArray?.length == totalCount}
                variant="contained"
                onClick={() => {
                  append();
                  handelAppend();
                }}>
                <Typography fontSize="15px">Add</Typography>
              </Button>
            </Stack>
            <Stack
              direction="row"
              width="100%"
              spacing={3}
              justifyContent="flex-end"
              alignItems="center">
              <Typography fontSize={15} sx={{ fontWeight: '500' }}>
                Total Items: {totalCount + 1}
              </Typography>

              <Typography fontSize={15} sx={{ fontWeight: '500' }}>
                Total Quantity: {totalQuantity} KG
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </form>
      <Stack
        direction="row"
        width={{ md: '90%', sm: '100%' }}
        justifyContent="flex-end"
        alignItems="center"
        mt={2}>
        <Button
          variant="contained"
          disabled={shadeArray?.length == totalCount}
          onClick={() => {
            validationCheck();
          }}>
          <Typography fontSize="15px">Create</Typography>
        </Button>
      </Stack>

      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to add this order?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => handelConfrimationDecision()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>

      <CreateShadeColor
        toggleModal={setShowAddColorForm}
        show={showAddShadeColorForm}
        shadeIndex={shadeIndex}
        shadeArray={shadeArray}
        setShadeArray={setShadeArray}
        selectedClient={selectedClient}
      />
      {fabricList ? (
        <CreateFabricType
          toggleModal={setShowAddFabricTypeForm}
          show={showAddFabricTypeForm}
          setFabricList={setFabricList}
          fabricList={fabricList}
          hasFabricAdded={hasFabricAdded}
          setHasFabricAdded={setHasFabricAdded}
        />
      ) : (
        <></>
      )}
      <PageTitle title="Create Order" />
      <CreateProcessDescriptionForm
        toggleModal={setShowProcessDescriptionForm}
        show={showProcessDescriptionForm}
        shadeArray={shadeArray}
        setShadeArray={setShadeArray}
        shadeIndex={shadeIndex}
        processFormArray={processFormArray}
        setProecessFormArray={setProecessFormArray}
        additionalStepCharges={
          additionalStepCharges
        }></CreateProcessDescriptionForm>
    </Stack>
  );
};

export default CreateOrder;
