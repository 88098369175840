import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorBoundary from '../components/global/ErrorBoundary';
import ProtectedPage from '../components/layout/ProtectedPage';
import { routes } from '../constants/routes';
import { useSocket } from '../hooks/useSocket';
import PrivateLayout from '../layouts/PrivateLayout';
import PublicLayout from '../layouts/PublicLayout';
import PageNotFound from '../pages/global/PageNotFound';

const RootRoute = () => {
  const socket = useSocket();

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Routes>
          {routes.map((route) => {
            const { path, Element, isProtected, isIndexUrl } = route;
            if (isProtected) {
              return (
                <Route element={<PrivateLayout socket={socket} />} key={path}>
                  <Route
                    index={isIndexUrl}
                    path={path}
                    element={
                      <ProtectedPage>
                        <Element socket={socket} />
                      </ProtectedPage>
                    }
                  />
                </Route>
              );
            } else {
              return (
                <Route element={<PublicLayout />} key={path}>
                  <Route
                    index={isIndexUrl}
                    path={path}
                    element={<Element socket={socket} />}
                  />
                </Route>
              );
            }
          })}
          <Route element={<PublicLayout />}>
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default RootRoute;
