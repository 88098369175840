import {
  PlagiarismTwoTone as Plagiarism,
  ScienceTwoTone as Science,
} from '@mui/icons-material';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { RECIPE_MANAGEMENT } from '../../../../constants/routeUrls';
import Button from '../../../../widgets/Button';

const RecipeBody = ({ task, recipe, lotNumber, recipes }) => {
  const recipeStatusColor = (status) => {
    const colors = {
      'IN QUEUE': '#ffe0b2',
      PREPARED: '#fecd70',
      'PARTIAL DELIVERED': '#89cffd',
      DELIVERED: '#89cffd',
      USED: '#7fb77e',
      RETURNED: '#f96666',
    };

    return colors[status || 'IN QUEUE'];
  };

  if (!task) {
    return recipes?.length > 0 ? (
      <Stack
        width="100%"
        height="100%"
        px={1}
        py={1}
        spacing={2}
        overflow="auto">
        {recipes.map((item, index) => (
          <Stack
            key={item.taskUid}
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start">
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              alignItems="center">
              <Typography fontSize={16} fontWeight={700}>
                {index + 1}. {item.taskName}
              </Typography>
              <Stack direction="row" spacing={0.5}>
                {item.isSkipped && (
                  <Chip
                    label="Skipped"
                    size="small"
                    color="error"
                    sx={{ fontSize: '0.65rem', fontWeight: 700 }}
                  />
                )}
                <Chip
                  label={item.status}
                  size="small"
                  sx={{
                    fontSize: '0.65rem',
                    fontWeight: 700,
                    bgcolor: recipeStatusColor(item.status),
                  }}
                />
              </Stack>
            </Stack>
            <Box height={10} />
            <Stack spacing={1} width="100%">
              {item.recipe?.map((rcp) => (
                <Stack
                  width="100%"
                  px={1}
                  py={1}
                  bgcolor={rcp.isDelivered ? '#E1FFB1' : '#f7f7f7'}
                  borderRadius={2}
                  key={rcp.id}>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between">
                    <Typography fontSize={14}>
                      {rcp.chemicalName} X {rcp.quantity}
                    </Typography>
                    <Typography fontSize={14} textTransform="uppercase">
                      {rcp.total} ({rcp.unit === 'percent' ? '%' : rcp.unit})
                    </Typography>
                    {rcp.cost > 0 && (
                      <Typography fontSize={14}>
                        (Cost: {rcp.cost.toFixed(2)} BDT)
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    ) : (
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        px={3}
        py={3}
        spacing={2}
        overflow="auto">
        <Plagiarism sx={{ width: 100, height: 100, fill: '#303030' }} />
        <Typography variant="h5" fontWeight={700}>
          Recipe Infomation
        </Typography>
        <Typography textAlign="center">
          Please select a task to see more details
        </Typography>
      </Stack>
    );
  } else if (task.isRecipeRequired && !recipe) {
    return (
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        px={3}
        py={3}
        spacing={2}>
        <Plagiarism sx={{ width: 100, height: 100, fill: '#303030' }} />
        <Typography variant="h5" fontWeight={700}>
          Recipe Not Configured
        </Typography>
        <Typography textAlign="center">
          This task requires chemical recipe. Please configure required
          chemicals for this task.
        </Typography>
        <Button
          component={Link}
          to={`${RECIPE_MANAGEMENT}/${lotNumber}`}
          variant="contained">
          Configure Recipe
        </Button>
      </Stack>
    );
  } else if (!task.isRecipeRequired) {
    return (
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        px={3}
        py={3}
        spacing={2}>
        <Science sx={{ width: 100, height: 100, fill: '#303030' }} />
        <Typography variant="h5" fontWeight={700}>
          Recipe Not Required
        </Typography>
        <Typography textAlign="center">
          This task does not requires any chemicals to complete.
        </Typography>
      </Stack>
    );
  } else {
    return (
      <Stack
        width="100%"
        height="100%"
        px={1}
        py={1}
        spacing={2}
        overflow="auto">
        <Stack width="100%" alignItems="flex-start" justifyContent="flex-start">
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center">
            <Typography fontWeight={700}>{recipe.taskName}</Typography>
            <Stack direction="row" spacing={0.5}>
              {recipe.isSkipped && (
                <Chip
                  label="Skipped"
                  size="small"
                  color="error"
                  sx={{ fontSize: '0.65rem', fontWeight: 700 }}
                />
              )}
              <Chip
                label={recipe.status}
                size="small"
                sx={{
                  fontSize: '0.65rem',
                  fontWeight: 700,
                  bgcolor: recipeStatusColor(recipe.status),
                }}
              />
            </Stack>
          </Stack>
          <Box height={10} />
          <Stack spacing={1} width="100%">
            {recipe?.recipe?.map((rcp) => (
              <Stack
                width="100%"
                px={1}
                py={1}
                bgcolor={rcp.isDelivered ? '#E1FFB1' : '#f7f7f7'}
                borderRadius={2}
                key={rcp.id}>
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="space-between">
                  <Stack spacing={0.5}>
                    <Typography fontSize={15} fontWeight={700}>
                      {rcp.chemicalName} X {rcp.quantity}
                    </Typography>
                    {rcp.dyeGroupName && (
                      <Typography>Dye Group: {rcp.dyeGroupName}</Typography>
                    )}
                    {rcp.changeBy && (
                      <Typography fontSize={14}>
                        Change By: {rcp.changeBy || 0}
                      </Typography>
                    )}
                    {rcp.totalChnage && (
                      <Typography fontSize={14}>
                        Total Change: {rcp.totalChnage || 0}
                      </Typography>
                    )}
                    <Typography fontSize={14}>
                      Addition: {rcp.addition || 0}
                    </Typography>
                  </Stack>
                  <Stack alignItems="flex-end">
                    <Typography fontSize={14} textTransform="uppercase">
                      Total ({rcp.unit === 'percent' ? '%' : rcp.unit})
                    </Typography>
                    <Typography fontSize={20} fontWeight={800}>
                      {rcp.total}
                    </Typography>
                  </Stack>
                  {!recipe.isSkipped && rcp.cost > 0 ? (
                    <Stack alignItems="flex-end">
                      <Typography fontSize={14} textTransform="uppercase">
                        Total Cost (BDT)
                      </Typography>
                      <Typography fontSize={20} fontWeight={800}>
                        {rcp.cost}
                      </Typography>
                    </Stack>
                  ) : null}
                </Stack>
              </Stack>
            ))}
          </Stack>
          {recipe.totalCost > 0 ? (
            <Stack
              justifyContent="flex-end"
              direction="row"
              width="100%"
              mt={2}>
              <Typography fontSize={20} fontWeight={800}>
                Total Cost: {recipe.totalCost.toFixed(2)} BDT
              </Typography>
            </Stack>
          ) : (
            <Stack
              justifyContent="flex-end"
              direction="row"
              width="100%"
              mt={2}>
              <Typography fontSize={20} fontWeight={800}>
                Cost hasn&apos;t been calculated yet
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }
};

export default RecipeBody;
