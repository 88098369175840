import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Button from '../../widgets/Button';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import {
  AddCircleTwoTone as Add,
  BiotechTwoTone as Biotech,
  CheckCircleTwoTone as CheckCircle,
  CancelTwoTone as Close,
  DeleteTwoTone as Delete,
  FormatPaintTwoTone as FormatPaint,
  SaveTwoTone,
  WarningTwoTone as Warning,
} from '@mui/icons-material';
import ValidationFields from './ValidationFields';
import { yupResolver } from '@hookform/resolvers/yup';
import { TaskFormSchema } from '../../validators/task-form';
import { createNewTaskForm } from '../../api/task-form';
import { convertWordToCamelCase } from '../../utils/word';
import { successNoitif } from '../../utils/notification';

const AddNewForm = ({ toggleForm, open, update }) => {
  const resolver = yupResolver(TaskFormSchema);

  const [isColorTemplate, setIsColorTemplate] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      taskName: '',
      taskType: 'DYEING',
      formFields: [],
    },
    mode: 'onChange',
    resolver,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formFields',
  });
  const handleFormFieldAppend = () => {
    append({
      label: '',
      fieldType: 'TEXT',
    });
  };
  const handleClose = () => {
    reset({
      taskName: '',
      taskType: 'DYEING',
      formFields: [],
    });
    toggleForm(false);
  };

  const handleFormSubmit = async (data) => {
    const cleanedFormFields = data.formFields.map((field) => ({
      ...field,
      min: field.min || 0,
      max: field.max || 140,
      options: field.options || '',
      inputKeyName: convertWordToCamelCase(field.inputKeyName),
    }));

    const response = await createNewTaskForm({
      ...data,
      isColorTemplate,
      formFields: cleanedFormFields,
    });

    if (response) {
      setShowConfirmation(false);
      successNoitif('Process Template saved');
      reset({ taskName: '', taskType: 'DYEING', formFields: [] });
      toggleForm(false);
      update((forms) => [response, ...forms]);
    }
  };

  const formFields = useWatch({ control, name: 'formFields' });

  const getFieldType = (index) => {
    return formFields?.find((field, itemIndex) => itemIndex === index)
      ?.fieldType;
  };

  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handelProcessFormSubmission = () => {
    setShowConfirmation(true);
  };
  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogContent>
        <Stack
          width="80%"
          mx="auto"
          height="auto"
          spacing={2}
          px={2}
          py={2}
          borderRadius={1.5}
          border="2px solid rgba(0,0,0,0.2)"
          sx={{ my: 2 }}>
          <Typography variant="h5" fontWeight={800}>
            Create Process
          </Typography>
          <Stack
            width="100%"
            direction="row"
            flexWrap="wrap"
            sx={{ my: 2 }}
            spacing={2}>
            <TextField
              size="small"
              fullWidth
              label="Process Name"
              placeholder="Please enter name of the process"
              sx={{ flex: 0.75 }}
              {...register('taskName')}
              error={errors?.taskName}
              helperText={errors?.taskName?.message}
            />
            <FormControl sx={{ flex: 0.25 }} error={errors?.taskType}>
              <InputLabel>Process Type</InputLabel>
              <Select
                defaultValue="DYEING"
                label="Process Type"
                size="small"
                {...register('taskType')}>
                <MenuItem value="DYEING">
                  <Stack direction="row" spacing={1.5}>
                    <FormatPaint />
                    <Typography fontSize="0.8rem" fontWeight={700}>
                      Dyeing
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem value="FINISHING">
                  <Stack direction="row" spacing={1.5}>
                    <CheckCircle />
                    <Typography fontSize="0.8rem" fontWeight={700}>
                      Finishing
                    </Typography>
                  </Stack>
                </MenuItem>
                <MenuItem value="QC">
                  <Stack direction="row" spacing={1.5}>
                    <Biotech />
                    <Typography fontSize="0.8rem" fontWeight={700}>
                      Quality Check
                    </Typography>
                  </Stack>
                </MenuItem>
              </Select>
              {errors?.taskType && (
                <FormHelperText>{errors?.taskType?.message}</FormHelperText>
              )}
            </FormControl>
          </Stack>
          <Typography variant="h5" fontWeight={800}>
            Add Process Form Fields
          </Typography>

          {fields.map((field, index) => (
            <Stack
              width="100%"
              direction="column"
              flexWrap="wrap"
              key={field.id}
              spacing={1.5}
              alignItems="center"
              borderRadius={1.5}
              border={1}
              borderColor="grey.300"
              px={1}
              py={1}
              boxSizing="border-box">
              <Stack direction="row" width="100%" spacing={2}>
                <TextField
                  fullWidth
                  sx={{ flex: 1 }}
                  size="small"
                  label="Field Label"
                  placeholder="Enter label to show for this form"
                  {...register(`formFields.${index}.label`)}
                  error={errors?.formFields?.[index]?.label}
                  helperText={errors?.formFields?.[index]?.label?.message}
                />
                <TextField
                  fullWidth
                  sx={{ flex: 0.5 }}
                  size="small"
                  label="Field Identifier"
                  placeholder="Enter identifier for saving the input against"
                  {...register(`formFields.${index}.inputKeyName`)}
                  error={errors?.formFields?.[index]?.inputKeyName}
                  helperText={
                    errors?.formFields?.[index]?.inputKeyName?.message
                  }
                />
                <FormControl
                  size="small"
                  sx={{ flex: 0.5 }}
                  error={errors?.formFields?.[index]?.fieldType}>
                  <InputLabel>Field Type</InputLabel>
                  <Select
                    defaultValue={getFieldType(index)}
                    {...register(`formFields[${index}].fieldType`)}
                    label="Field Type">
                    <MenuItem value="TEXT">Text</MenuItem>
                    <MenuItem value="TEXTAREA">Large Text</MenuItem>
                    <MenuItem value="NUMBER">Number</MenuItem>
                    <MenuItem value="IMAGE">Image</MenuItem>
                    <MenuItem value="YES/NO">Yes/No</MenuItem>
                    {/* <MenuItem value="SELECT">Select</MenuItem>
                    <MenuItem value="LIST">List</MenuItem> */}
                  </Select>
                  {errors?.formFields?.[index]?.fieldType && (
                    <FormHelperText>
                      {' '}
                      {errors?.formFields?.[index]?.fieldType?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                spacing={2}
                alignItems="flex-start"
                justifyContent="space-between">
                <Stack direction="row" spacing={1.5}>
                  <ValidationFields
                    fieldType={getFieldType(index)}
                    register={register}
                    index={index}
                    errors={errors}
                  />
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>Required Field?</Typography>
                    <Switch
                      onChange={(event) =>
                        setValue(
                          `formFields.${index}.isRequired`,
                          event.target.checked,
                        )
                      }
                    />
                  </Stack>
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{
                      flex: 0.05,
                      height: 'max-content',
                      width: 'max-content',
                    }}>
                    <Delete />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          ))}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              variant="contained"
              endIcon={<Add />}
              sx={{ maxWidth: 'max-content' }}
              onClick={handleFormFieldAppend}>
              Add Field
            </Button>
            {errors?.formFields && (
              <Tooltip
                arrow
                placement="right"
                title="Every task must have atleast one form">
                <Warning color="warning" />
              </Tooltip>
            )}
          </Stack>
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}>
            <Typography fontSize="0.9rem" fontWeight={700}>
              Is Color Step?{' '}
            </Typography>
            <Switch
              checked={isColorTemplate}
              onChange={(event) => setIsColorTemplate(event.target.checked)}
            />
            <Button
              variant="contained"
              onClick={handleClose}
              color="error"
              endIcon={<Close />}>
              Close
            </Button>
            <Button
              variant="contained"
              color="info"
              endIcon={<SaveTwoTone />}
              onClick={handleSubmit(handelProcessFormSubmission)}>
              save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <Stack>
        <Stack>
          <Dialog
            onClose={onCloseCongfirmation}
            open={showConfirmation}
            maxWidth="sm"
            title="Are you sure"
            yesText="Yes"
            noHandler={onCloseCongfirmation}>
            <DialogTitle id="simple-dialog-title">
              Are you sure you want to add this process?
            </DialogTitle>
            <Stack
              direction="row"
              gap={1}
              justifyContent="flex-end"
              sx={{ paddingRight: 2, paddingBottom: 2 }}>
              <Button
                variant="outlined"
                sx={{ borderColor: 'red', color: 'red' }}
                onClick={() => {
                  setShowConfirmation(false);
                }}>
                No
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit(handleFormSubmit)}>
                Yes
              </Button>
            </Stack>
          </Dialog>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AddNewForm;
