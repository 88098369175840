export default (role) => {
  const roles = {
    ADMIN: 'Admin',
    SUPER_ADMIN: 'Super Admin',
    ACC: 'Accounts',
    SI: 'Store Supervisor',
    DPO: 'Dyeing Supervisor',
    FS: 'Finishing Supervisor',
    QCS: 'Quality Supervisor',
    BM: 'Batch Manager',
    SUPERVISOR: 'Supervisor',
    MANAGER: 'Manager',
    DI: 'Delivery Incharge',
  };
  return roles[role];
};
