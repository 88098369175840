import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  Chip,
  createFilterOptions,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import CheckBoxIcon from '@mui/icons-material/CheckBoxTwoTone';
import {
  CircleTwoTone as Circle,
  InfoTwoTone as Info,
  WarningTwoTone as Warning,
} from '@mui/icons-material';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '../../../widgets/Button';
import TextField from '../../../widgets/TextField';

import {
  fetchAllReRunBatchRefSuggestion,
  fetchBatchByOrderIds,
  fetchReRunBatchRefSuggestion,
} from '../../../api/batch';

import { Controller, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BATCH } from '../../../constants/routeUrls';
import RelatedBatchDrawer from './RelatedBatchDrawer';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BatchBasicInfoForm = ({
  next,
  formControl,
  setFormValue,
  register,
  errors,
  submit,
  isUpdating,
  isFormDirty,
}) => {
  const [showWarningForUnsavedChanges, setShowWarningForUnsavedChanges] =
    useState(false);
  const [showRelatedBatches, setShowRelatedBatches] = useState(false);

  const navigation = useNavigate();

  const reRunBatchRef = useWatch({
    control: formControl,
    name: 'reRunBatchRef',
  });
  const orders = useWatch({ control: formControl, name: 'orders' });
  const loadingTime = useWatch({ control: formControl, name: 'loadingTime' });
  const deliveryDate = useWatch({ control: formControl, name: 'deliveryDate' });
  const lotNumber = useWatch({ control: formControl, name: 'lotNumber' });
  const batchType = useWatch({ control: formControl, name: 'batchType' });
  const processType = useWatch({ control: formControl, name: 'processType' });
  const finishingType = useWatch({
    control: formControl,
    name: 'finishingType',
  });

  const priority = useWatch({ control: formControl, name: 'priority' });

  const [batchList, setBatchList] = React.useState([]);
  const [relatedBatches, setRelatedBatches] = React.useState([]);
  const [allBatchlist, setAllBatchList] = React.useState([]);
  const fetchRelatedBatches = async (orderIds) => {
    const response = await fetchBatchByOrderIds(orderIds);
    setRelatedBatches(response);
  };

  const fetchBatches = async (lotNumber) => {
    const batches = await fetchReRunBatchRefSuggestion(lotNumber);
    setBatchList(batches);
  };

  const fetchAllReprocessBatches = async () => {
    const batches = await fetchAllReRunBatchRefSuggestion();
    setAllBatchList(batches);
  };

  useEffect(() => {
    fetchAllReprocessBatches();
  }, []);
  const OPTIONS_LIMIT = 6;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  useEffect(() => {
    (async () => {
      const orderIds = orders?.map((order) => order.id);
      await fetchRelatedBatches(orderIds);
    })();
  }, [orders]);

  const showFullForm = Boolean(reRunBatchRef);

  const handleNext = async () => {
    next(1);
  };

  const handleGoBack = () => {
    if (isFormDirty) {
      setShowWarningForUnsavedChanges(true);
    } else {
      navigation(BATCH);
    }
  };

  const preLoadForm = (lotNumber) => {
    const batch = batchList?.find((item) => item.lotNumber === lotNumber);

    if (!batch) {
      return;
    }
    const orders = batch?.orderInfo?.map((order) => ({
      id: order.id,
      fcNumber: order.fcNumber,
      partyName: order.partyName,
    }));

    const fabrics = batch?.fabricInfo?.map((item) => {
      const order = orders?.find((order) => order.id === item.orderId);
      return {
        ...item,
        fcNumber: order?.fcNumber,
        partyName: order?.partyName,
      };
    });

    setFormValue('orders', orders || []);
    setFormValue('fabricInfo', fabrics || []);
    setFormValue('machine', batch?.machineInfo || null);
    setFormValue('loadingTime', batch?.loadingTime || null);
    setFormValue('deliveryDate', batch?.deliveryDate || null);
    setFormValue('tasks', batch?.taskInfo?.tasks || []);
    setFormValue('isWashRequired', batch?.taskInfo?.isWashRequired || false);
    setFormValue(
      'isHeatSetRequired',
      batch?.taskInfo?.isHeatSetRequired || false,
    );
  };

  useEffect(() => {
    if (batchList) {
      preLoadForm(reRunBatchRef);
    }
  }, [batchList]);

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center">
      {relatedBatches && (
        <RelatedBatchDrawer
          batches={relatedBatches}
          open={showRelatedBatches}
          toggle={setShowRelatedBatches}
        />
      )}
      <Dialog
        open={showWarningForUnsavedChanges}
        onClose={() => setShowWarningForUnsavedChanges(false)}>
        <DialogContent>
          <Stack spacing={2} alignItems="center">
            <DialogTitle>Warning</DialogTitle>
            <Warning color="warning" sx={{ width: 30, height: 30 }} />
            <Typography variant="body1">You have unsaved changes</Typography>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack
        width="80%"
        minHeight={450}
        mx="auto"
        bgcolor="#f7f7f7"
        borderRadius={1.5}
        px={2}
        py={2.5}
        spacing={2.5}
        boxShadow={2}>
        <Typography variant="h5" fontWeight={800}>
          Configure Batch
        </Typography>
        <Stack direction="row" spacing={2}>
          <Autocomplete
            filterOptions={filterOptions}
            fullWidth
            value={reRunBatchRef}
            onChange={(event, newValue) => {
              setFormValue('reRunBatchRef', newValue);
              fetchBatches(newValue).then(() => {
                preLoadForm(newValue);
              });
            }}
            options={allBatchlist.map((item) => item.lotNumber)}
            onInputChange={async (event, query) => {
              if (query.length > 2) {
                await fetchBatches(query);
              }
            }}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => `Lot ${option.split('-')[1]}`}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Chip
                    label={`Lot ${option?.split('-')[1]}`}
                    color="info"
                    size="small"
                  />
                  <Typography fontSize="0.9rem" fontWeight={700}>
                    {option?.split('-')[1]}
                  </Typography>
                </Stack>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Batch"
                placeholder="Select a batch"
                error={errors?.orders}
                helperText={errors?.orders?.message}
              />
            )}
          />
        </Stack>
        {showFullForm ? (
          <>
            <Stack direction="row" spacing={2}>
              <Autocomplete
                fullWidth
                multiple
                disabled
                disableCloseOnSelect
                value={orders}
                onChange={(event, newValue) => {
                  setFormValue('orders', newValue);
                  setFormValue('fabricInfo', []);
                }}
                options={orders.map((item) => ({
                  id: item.id,
                  fcNumber: item.fcNumber,
                  partyName: item.partyName,
                }))}
                isOptionEqualToValue={(option, value) =>
                  option.fcNumber === value.fcNumber
                }
                getOptionLabel={(option) =>
                  `FC ${option.fcNumber} - ${option.partyName}`
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Chip
                        label={`FC ${option.fcNumber}`}
                        color="info"
                        size="small"
                      />
                      <Typography fontSize="0.9rem" fontWeight={700}>
                        {option.partyName}
                      </Typography>
                    </Stack>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Orders"
                    placeholder="Select orders"
                    error={errors?.orders}
                    helperText={errors?.orders?.message}
                  />
                )}
              />
              <Button
                sx={{ width: 240, maxHeight: 54 }}
                endIcon={<Info />}
                variant="outlined"
                disabled={relatedBatches?.length <= 0}
                onClick={() => setShowRelatedBatches(true)}>
                {relatedBatches?.length || 0} Batches Found
              </Button>
            </Stack>
            <Stack direction="row" spacing={2}>
              {isUpdating ? (
                <TextField
                  disabled
                  value={lotNumber?.split('-')?.[1]}
                  fullWidth
                  label="Lot Number"
                  placeholder="Please provide batch lot number"
                  error={errors?.lotNumber}
                  helperText={errors?.lotNumber?.message}
                />
              ) : (
                <TextField
                  fullWidth
                  label="Lot Number"
                  placeholder="Please provide batch lot number"
                  {...register('lotNumber')}
                  error={errors?.lotNumber}
                  helperText={errors?.lotNumber?.message}
                />
              )}
              <FormControl fullWidth error={errors?.batchType}>
                <InputLabel>Batch Type</InputLabel>
                <Controller
                  control={formControl}
                  name="batchType"
                  defaultValue={batchType}
                  render={({ field }) => (
                    <Select
                      {...field}
                      {...register('batchType')}
                      defaultValue="Batch To Batch"
                      label="Batch Type"
                      placeholder="Please select a batch type">
                      <MenuItem value="New Shade">New Shade</MenuItem>
                      <MenuItem value="Batch To Batch">Batch To Batch</MenuItem>
                    </Select>
                  )}
                />
                {errors?.batchType && (
                  <FormHelperText>{errors?.batchType?.message}</FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth error={errors?.finishingType}>
                <InputLabel>Finishing Type</InputLabel>
                <Controller
                  control={formControl}
                  name="finishingType"
                  defaultValue={finishingType}
                  render={({ field }) => (
                    <Select
                      {...field}
                      defaultValue="TUBE"
                      {...register('finishingType')}
                      label="Finishing Type"
                      placeholder="Please select a finishing type">
                      <MenuItem value="TUBE">Tube</MenuItem>
                      <MenuItem value="OPEN">Open</MenuItem>
                    </Select>
                  )}
                />
                {errors?.finishingType && (
                  <FormHelperText>
                    {errors?.finishingType?.message}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth error={errors?.priority}>
                <InputLabel>Priority</InputLabel>
                <Controller
                  control={formControl}
                  name="priority"
                  defaultValue={priority}
                  render={({ field }) => (
                    <Select
                      {...field}
                      {...register('priority')}
                      defaultValue="LOW"
                      label="Priority"
                      placeholder="Please select batch priority">
                      <MenuItem value="HIGH">
                        <Stack direction="row" spacing={1} width="100%">
                          <Typography>High</Typography>
                          <Circle color="error" />
                        </Stack>
                      </MenuItem>
                      <MenuItem value="MEDIUM">
                        <Stack direction="row" spacing={1} width="100%">
                          <Typography>Medium</Typography>
                          <Circle color="warning" />
                        </Stack>
                      </MenuItem>
                      <MenuItem value="LOW">
                        <Stack direction="row" spacing={1} width="100%">
                          <Typography>Low</Typography>
                          <Circle color="info" />
                        </Stack>
                      </MenuItem>
                    </Select>
                  )}
                />
                {errors?.priority && (
                  <FormHelperText>{errors?.priority?.message}</FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl disabled fullWidth error={errors?.processType}>
                <InputLabel>Process Type</InputLabel>
                <Controller
                  control={formControl}
                  name="processType"
                  defaultValue={processType}
                  render={({ field }) => (
                    <Select
                      {...field}
                      {...register('processType')}
                      defaultValue="BULK"
                      label="Process Type"
                      placeholder="Please select a process type">
                      <MenuItem value="RE PROCESS">Re Process</MenuItem>
                    </Select>
                  )}
                />
                {errors?.processType && (
                  <FormHelperText>
                    {errors?.processType?.message}
                  </FormHelperText>
                )}
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  minDate={new Date()}
                  label="Loading Date & Time"
                  value={loadingTime}
                  onChange={(value) => setFormValue('loadingTime', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={errors?.loadingTime}
                      helperText={errors?.loadingTime?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  minDate={new Date()}
                  label="Delivery Date"
                  value={deliveryDate}
                  onChange={(value) => setFormValue('deliveryDate', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ maxWidth: { md: 'calc(50% - 5px)', xs: '100%' } }}
                      error={errors?.deliveryDate}
                      helperText={errors?.deliveryDate?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          </>
        ) : (
          <Stack
            flexGrow={1}
            direction="column"
            spacing={2}
            width="100%"
            alignItems="center"
            justifyContent="center">
            <Typography variant="h4" fontWeight={700}>
              Please Select A Batch
            </Typography>
            <Typography sx={{ maxWidth: 280, textAlign: 'center' }}>
              A re-process batch must have a batch referance. Please select a
              referance batch to continue.
            </Typography>
          </Stack>
        )}
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          spacing={2}>
          <Button onClick={handleGoBack} variant="outlined">
            Return To Menu
          </Button>
          <Button variant="contained" onClick={submit(handleNext)}>
            Next
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BatchBasicInfoForm;
