import Request from './request';

const axiosV2 = new Request(2);

export const fetchFabricsByMultipleFC = async (fcNumbers) => {
  const response = await axiosV2.Get('/fabrics/multiple-fc', { fcNumbers });
  if (!response) {
    return [];
  }
  return response.fabrics;
};
