import {
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { updateQuotationStatus } from '../../api/approval';
import { successNoitif } from '../../utils/notification';
import Button from '../../widgets/Button';

const QuotationApprovalModal = ({
  show,
  toggleModal,
  data,
  isUpdated,
  setIsUpdated,
}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const onClose = () => {
    handleCreationFormClose();
  };
  const handleCreationFormClose = () => {
    toggleModal(false);
  };

  const handleApproval = async () => {
    const response = await updateQuotationStatus(data.uid);

    if (response) {
      successNoitif('Quotation status has been approved');
      setIsUpdated(!isUpdated);
      toggleModal(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">
          Approve Quotation &apos;{data.uid}&apos;
        </DialogTitle>
        <Stack
          sx={{
            paddingLeft: 3,
            paddingRight: 2,
            paddingBottom: 1,
          }}>
          <Typography>
            Are you sure you want to approve this quotation?
          </Typography>
          <form>
            <Stack
              my={2}
              direction="row"
              alignItems="flex-start"
              justifyContent="center">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingRight: 0,
                      margin: '0px',
                      border: '0px',
                      borderRadius: '0px',
                    }}
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="hello"
                  />
                }
              />
              <Stack
                sx={{
                  paddingRight: 1,
                }}>
                <Typography>
                  By checking this box I here by declare that I have checked all
                  the information and confirm my action.I also confirm that my
                  information will be stored.
                </Typography>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Stack
          mr={3}
          direction="row"
          gap={1}
          justifyContent="flex-end"
          sx={{ paddingRight: 1, paddingBottom: 2 }}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleCreationFormClose}>
            Cancel
          </Button>
          <Button
            color="success"
            variant="contained"
            disabled={!checked}
            onClick={handleApproval}>
            Confirm
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default QuotationApprovalModal;
