import { Dialog, DialogTitle, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { updateTaskAdditionalRemarks } from '../../../../api/batch';
import { errorNoitif, successNoitif } from '../../../../utils/notification';
import Button from '../../../../widgets/Button';

const EditTaskAdditionalRemarksModal = ({
  toggleModal,
  show,
  task,
  socket,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { lotNumber } = useParams();

  const {
    formState: { errors },
    register,

    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      additionalRemarks: task?.additionalRemarks || 'N/A',
    },
  });

  const onCloseConfirmation = () => {
    handleCloseConfirmation();
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleCreationFormClose = () => {
    reset({
      additionalRemarks: task?.additionalRemarks || 'N/A',
    });
    toggleModal(false);
  };

  useEffect(() => {
    if (task) {
      reset({
        additionalRemarks: task?.additionalRemarks || 'N/A',
      });
    }
  }, [task]);

  const handleQuantityChangeSubmission = () => {
    setShowConfirmation(true);
    toggleModal(false);
  };

  const updateRemarksFormSubmit = async (data) => {
    const response = await updateTaskAdditionalRemarks({
      taskUid: task?.uid,
      additionalRemarks: data?.additionalRemarks,
    });

    if (response) {
      successNoitif('Additional Remarks Updated Successfully');
      socket.emit('batch details', { lotNumber });
      toggleModal(false);
    } else {
      errorNoitif('Additional Remarks Update Failed');
    }
    setShowConfirmation(false);
  };

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="simple-dialog-title">
          Update Additional Remarks for {task?.taskName}
        </DialogTitle>
        <form>
          <Stack
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 0,
              paddingBottom: 3,
            }}>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Additional Remarks"
                required
                multiline
                rows={5}
                placeholder="Enter Additional Remarks"
                {...register('additionalRemarks')}
                error={errors?.additionalRemarks}
                helperText={errors?.additionalRemarks?.message}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red' }}
              color="error"
              onClick={handleCreationFormClose}>
              Cancel
            </Button>

            <Stack>
              <Button
                variant="contained"
                disabled={!isDirty}
                onClick={handleSubmit(handleQuantityChangeSubmission)}>
                Update
              </Button>
            </Stack>
          </Stack>
        </form>
      </Dialog>
      <Stack>
        <Dialog
          onClose={onCloseConfirmation}
          open={showConfirmation}
          maxWidth="sm">
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to update additional remarks?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
                toggleModal(true);
              }}>
              No
            </Button>

            <Button
              variant="contained"
              onClick={handleSubmit(updateRemarksFormSubmit)}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
    </>
  );
};

export default EditTaskAdditionalRemarksModal;
