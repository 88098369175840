import React from 'react';
import { Stack, Typography } from '@mui/material';

const StatCard = ({ label, content, color }) => {
  return (
    <Stack
      px={1}
      py={1}
      bgcolor="#f2f2f2"
      boxSizing="border-box"
      borderLeft={8}
      borderColor={color}
      width={150}
      alignItems="center">
      <Typography fontSize="0.8rem" fontWeight={700}>
        {label}
      </Typography>
      <Typography fontSize="2.5rem" fontWeight={700}>
        {content}
      </Typography>
    </Stack>
  );
};

export default StatCard;
