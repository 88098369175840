export const getBatchStatus = (status) => {
  switch (status) {
    case 'DRAFT':
      return { label: 'Draft', color: 'default' };
    case 'IS PAUSED':
      return { label: 'Paused', color: 'warning' };
    case 'ON HOLD':
      return { label: 'Halted', color: 'error' };
    case 'IN QUEUE':
      return { label: 'In Queue', color: 'warning' };
    case 'STARTED':
      return { label: 'Started', color: 'success' };
    case 'WAITING FOR DELIVERY':
      return { label: 'Waiting For Delivery', color: 'default' };
    case 'READY FOR DELIVERY':
      return { label: 'Ready For Delivery', color: 'info' };
    case 'DELIVERED':
      return { label: 'Delivered', color: 'success' };
    case 'WAITING FOR APPROVAL':
      return { label: 'Waiting For Approval', color: 'info' };
    case 'PARTIAL DELIVERED':
      return {
        label: 'Partially Delivered',
        color: 'default',
      };
    case 'REJECTED':
      return {
        label: 'Rejected',
        color: 'error',
      };

    default:
      return null;
  }
};

export const getBatchProcessType = (processType) => {
  switch (processType) {
    case 'BULK':
      return 'Bulk';
    case 'SAMPLE':
      return 'Sample';
    case 'RE PROCESS':
      return 'Re Process';
    default:
      return null;
  }
};

export const getBatchFinishType = (finishType) => {
  switch (finishType) {
    case 'TUBE':
      return 'Tube';
    case 'OPEN':
      return 'Open';
    default:
      return null;
  }
};

export const calculateTotalDuration = (tasks) => {
  return tasks?.reduce((previous, task) => {
    const hour = task?.standardTimeHour ? parseInt(task.standardTimeHour) : 0;
    const minute = task?.standardTimeMinute
      ? parseInt(task.standardTimeMinute)
      : 0;

    const duration = hour * 60 + minute;
    const durationToMs = duration * 60 * 1000;

    return previous + durationToMs;
  }, 0);
};

export const modifyTaskListForDb = (tasks) => {
  const modifiedTasks = tasks?.map((task) => {
    // eslint-disable-next-line no-unused-vars
    const { processType, ...rest } = task;
    return {
      ...rest,
      standardTimeHour: task.standardTimeHour,
      standardTimeMinute: task.standardTimeMinute,
      taskName: task.processType.taskName,
      formFields: task.processType.formFields,
    };
  });

  return modifiedTasks || [];
};

export const modifyTaskListForDisplay = (tasks) => {
  const modifiedTasks = tasks?.map((task) => {
    return {
      ...task,
      standardTimeHour: task.standardTimeHour,
      standardTimeMinute: task.standardTimeMinute,
      processType: {
        taskName: task.taskName,
        formFields: task.formFields,
      },
    };
  });

  const filterItems = modifiedTasks?.filter(
    (task) =>
      task.taskName !== 'Fabric Loading' && task.taskName !== 'Heat Set',
  );
  return filterItems || [];
};

export const batchPhaseColor = (phase) => {
  switch (phase) {
    case 'DYEING':
      return 'secondary';
    case 'FINISHING':
      return 'info';
    case 'QC':
      return 'warning';
  }
};

export const isBatchEditAble = (status) => {
  const editableStatus = ['IN QUEUE', 'DRAFT', 'WAITING FOR APPROVAL'];

  if (editableStatus.includes(status || '')) {
    return true;
  }
  return false;
};
