import { createAsyncThunk } from '@reduxjs/toolkit';

export const signin = createAsyncThunk(
  'auth/signin',
  async (identififer, { extra: { authService } }) => {
    const { username, password } = identififer;
    const response = await authService.signInUser(username, password);
    return response;
  },
);

export const otpinput = createAsyncThunk(
  'auth/otp',
  async (data, { extra: { authService } }) => {
    const { userData, otpToken, otpType } = data;
    const response = await authService.verifyOtp(userData, otpToken, otpType);
    return response;
  },
);
