import {
  AddCircleTwoTone as Add,
  DeleteTwoTone as Delete,
  InfoTwoTone as Info,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  FormHelperText,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import {
  createInitialDraftBatch,
  getFilteredMachineList,
} from '../../../api/batch';
import { fetchFabricsByMultipleFC } from '../../../api/fabric';
import { errorNoitif, successNoitif } from '../../../utils/notification';
import Button from '../../../widgets/Button';
import TextField from '../../../widgets/TextField';
import BatchesAssociatedWithMachine from '../BatchesAssociatedWithMachine';
import FabricAddDialog from './FabricAddDialog';
import dayjs from 'dayjs';

const MachineOption = ({ machine, ...rest }) => {
  return (
    <li {...rest}>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        px={1}
        py={1}>
        <Typography variant="body2">{machine.name}</Typography>
        <Chip label={`Utilization ${machine.utilization} %`} size="small" />
        {machine.isBusy ? (
          <Tooltip
            title={`Next Available ${dayjs(machine.availableAt).format(
              'MM/DD/YYYY HH:mm',
            )}`}
            arrow>
            <Chip label="Unavailable" color="warning" size="small" />
          </Tooltip>
        ) : (
          <Chip label="Available" color="success" size="small" />
        )}
      </Stack>
    </li>
  );
};

const MachineFabricForm = ({
  next,
  control,
  setFormValue,
  register,
  errors,
  submit,
  isUpdating,
  setIsUpdating,
  setCreatedBatch,
  isFormDirty,
}) => {
  const [allFabrics, setAllFabrics] = useState([]);
  const [showAddFabricDialog, setShowAddFabricDialog] = useState(false);
  const [machineList, setMachineList] = useState([]);

  const orders = useWatch({ control, name: 'orders' });
  const fabricInfo = useWatch({ control, name: 'fabricInfo' });
  const selectedMachine = useWatch({ control, name: 'machine' });
  const processType = useWatch({ control, name: 'processType' });

  const { fields: fabrics, remove } = useFieldArray({
    control,
    name: 'fabricInfo',
  });

  // * Fetch fabrics by FC
  const fetchFabrics = async () => {
    const fcNumbers = orders.map((order) => order.fcNumber);
    const response = await fetchFabricsByMultipleFC(fcNumbers);

    setAllFabrics(response);
  };

  // * fetch machine list by weight
  const fetchMachineList = async (weight) => {
    const response = await getFilteredMachineList(weight || 0);
    setMachineList(response);
  };

  // * calculate total fabric weight
  const calculateTotalFabricQuantity = () => {
    const totalFabricQuantity = fabricInfo.reduce((acc, fabric) => {
      if (processType === 'RE PROCESS') {
        return parseFloat(acc) + parseFloat(fabric.reProcessQty || 0);
      }
      return parseFloat(acc) + parseFloat(fabric.usedQty || 0);
    }, 0);
    return totalFabricQuantity;
  };

  // * calculate total fabric weight memo value
  const totalFabricWeight = useMemo(
    () => calculateTotalFabricQuantity(),
    [fabricInfo],
  );

  useEffect(() => {
    (async () => await fetchFabrics())();
  }, [orders]);

  useEffect(() => {
    (async () => await fetchMachineList(totalFabricWeight))();
  }, [totalFabricWeight]);

  // * deselect machine when total fabric qty is greater than maxCapacity
  useEffect(() => {
    if (totalFabricWeight > selectedMachine?.maxCapacity) {
      setFormValue('machine', null);
    }
  }, [totalFabricWeight]);

  const handleSubmit = async (data) => {
    if (!isFormDirty) {
      next(2);
      return;
    }
    const {
      batchType,
      deliveryDate,
      fabricInfo,
      finishingType,
      loadingTime,
      lotNumber,
      machine,
      orders,
      priority,
      processType,
    } = data;

    const response = await createInitialDraftBatch({
      batchType,
      deliveryDate,
      fabricInfo,
      finishingType,
      loadingTime,
      lotNumber,
      machine,
      orders,
      priority,
      processType,
      totalFabricWeight,
      reRunBatchRef: data.reRunBatchRef || null,
      isUpdating,
    });

    if (!response) {
      errorNoitif('Failed to save data');
      next(1);
    } else {
      setIsUpdating(response.isUpdated);
      setCreatedBatch(response);
      successNoitif('Information saved successfully');
      next(2);
    }
  };

  const machineUtilization = useMemo(() => {
    const utilization = totalFabricWeight / selectedMachine?.maxCapacity;

    return Math.floor(utilization * 100);
  }, [selectedMachine, totalFabricWeight]);

  const checkUserQtyValid = (fabric, userQty) => {
    if (
      fabric.quantity <
      fabric.processed + fabric.reProcessed + parseFloat(userQty || 0)
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      py={2}>
      <FabricAddDialog
        show={showAddFabricDialog}
        onClose={() => setShowAddFabricDialog(false)}
        allFabrics={allFabrics}
        alreadyAddedFabrics={fabricInfo}
        update={setFormValue}
      />
      <Stack
        boxShadow={2}
        width="100%"
        mx="auto"
        bgcolor="#f7f7f7"
        borderRadius={1.5}
        px={2}
        py={2.5}
        spacing={2.5}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h5" fontWeight={800}>
            Select Fabric
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography fontSize="0.9rem" fontWeight={800}>
              Total Weight: {totalFabricWeight} KG
            </Typography>
            <IconButton>
              <Info />
            </IconButton>
          </Stack>
        </Stack>
        {fabricInfo?.length > 0 ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>FC #</TableCell>
                <TableCell>Fabric Type</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Shade (%)</TableCell>
                <TableCell>Weight (KG)</TableCell>
                <TableCell>Processed (KG)</TableCell>
                <TableCell>Re Processed (KG)</TableCell>
                {processType !== 'RE PROCESS' ? (
                  <>
                    <TableCell width={120}>Roll</TableCell>
                    <TableCell width={180}>Quantity (KG)</TableCell>{' '}
                  </>
                ) : (
                  <>
                    <TableCell width={180}>Assigned (KG)</TableCell>
                    <TableCell width={120}>Roll</TableCell>
                    <TableCell>Re Process (KG)</TableCell>
                  </>
                )}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fabrics.map((fabric, index) => (
                <TableRow key={fabric.id}>
                  <TableCell>{fabric.fcNumber}</TableCell>
                  <TableCell>
                    <Tooltip arrow followCursor title={fabric.fabricType}>
                      <Typography fontSize="0.85rem">
                        {fabric.fabricType?.substring(0, 15)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip arrow followCursor title={fabric.colour}>
                      <Typography fontSize="0.85rem">
                        {fabric.colour?.substring(0, 15)}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{fabric?.shade}</TableCell>
                  <TableCell>{fabric.quantity}</TableCell>
                  <TableCell>{fabric.processed}</TableCell>
                  <TableCell>{fabric.reProcessed}</TableCell>
                  {processType !== 'RE PROCESS' ? (
                    <>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          {...register(`fabricInfo[${index}].rolls`)}
                          error={errors?.fabricInfo?.[index]?.rolls}
                          helperText={
                            errors?.fabricInfo?.[index]?.rolls?.message
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          {...register(`fabricInfo[${index}].usedQty`)}
                          error={
                            errors?.fabricInfo?.[index]?.usedQty ||
                            checkUserQtyValid(
                              fabric,
                              fabricInfo?.[index]?.usedQty,
                            )
                          }
                          helperText={
                            errors?.fabricInfo?.[index]?.usedQty?.message ? (
                              errors?.fabricInfo?.[index]?.usedQty?.message
                            ) : (
                              <>
                                {checkUserQtyValid(
                                  fabric,
                                  fabricInfo?.[index]?.usedQty,
                                ) ? (
                                  <Typography fontSize="small" color="red">
                                    Invalid Quantity
                                  </Typography>
                                ) : (
                                  <></>
                                )}
                              </>
                            )
                          }
                        />
                      </TableCell>{' '}
                    </>
                  ) : (
                    <>
                      <TableCell>{fabric.usedQty}</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          {...register(`fabricInfo[${index}].rolls`)}
                          error={errors?.fabricInfo?.[index]?.rolls}
                          helperText={
                            errors?.fabricInfo?.[index]?.rolls?.message
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          size="small"
                          {...register(`fabricInfo[${index}].reProcessQty`)}
                          error={errors?.fabricInfo?.[index]?.reProcessQty}
                          helperText={
                            errors?.fabricInfo?.[index]?.reProcessQty?.message
                          }
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <IconButton onClick={() => remove(index)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Stack
            width="100%"
            height={350}
            alignItems="center"
            justifyContent="center"
            spacing={2}>
            <Typography variant="h4" fontWeight={800}>
              No fabrics added
            </Typography>
            <Typography
              variant="body1"
              sx={{ maxWidth: 350, textAlign: 'center' }}>
              Please add fabrics to your batch from the orders you have selected
              in the previous step.
            </Typography>
            {processType !== 'RE PROCESS' && (
              <Button
                disabled={allFabrics.length == 0 ? true : false}
                variant="contained"
                onClick={() => setShowAddFabricDialog(true)}>
                Add Fabrics
              </Button>
            )}
            {errors?.fabricInfo && (
              <FormHelperText sx={{ color: 'red' }}>
                {errors?.fabricInfo?.message}
              </FormHelperText>
            )}
            {allFabrics.length == 0 ? (
              <Typography sx={{ color: 'red' }}>
                No valid fabric found
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
        )}
        {fabricInfo?.length > 0 && processType !== 'RE PROCESS' && (
          <Stack>
            <Button
              sx={{ width: 'max-content' }}
              variant="contained"
              startIcon={<Add />}
              onClick={() => setShowAddFabricDialog(true)}>
              Add More
            </Button>
          </Stack>
        )}
        <Typography variant="h5" fontWeight={800}>
          Select Machine
        </Typography>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          width="100%"
          spacing={2}>
          <Stack width="40%">
            {totalFabricWeight > 0 ? (
              <Autocomplete
                disabled={totalFabricWeight <= 0}
                value={selectedMachine}
                options={machineList}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <MachineOption machine={option} {...props} />
                )}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    label="Select Machine"
                    placeholder="Select a Machine"
                    error={errors?.machine}
                    helperText={errors?.machine?.message}
                  />
                )}
                onChange={(event, value) =>
                  setFormValue('machine', value, {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            ) : (
              <Tooltip
                arrow
                placement="top"
                title="Please enter fabric quantities first">
                <Autocomplete
                  disabled={totalFabricWeight <= 0}
                  value={selectedMachine}
                  options={machineList}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <MachineOption machine={option} {...props} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label="Select Machine"
                      placeholder="Select a Machine"
                      error={errors?.machine}
                      helperText={errors?.machine?.message}
                    />
                  )}
                  onChange={(event, value) =>
                    setFormValue('machine', value, {
                      shouldTouch: true,
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                />
              </Tooltip>
            )}
            {selectedMachine && (
              <Box
                width="100%"
                px={1}
                py={1}
                borderRadius={1.5}
                bgcolor="#303030"
                mt={2}
                color="#fff">
                <Typography variant="h4" fontWeight={800}>
                  {selectedMachine.name}
                </Typography>
                <Stack direction="row" width="100%" flexWrap="wrap" mt={2}>
                  <Stack spacing={1} mr={2} mb={1}>
                    <Typography>Max Capacity</Typography>
                    <Typography fontSize="1.5rem" fontWeight={700}>
                      {selectedMachine.maxCapacity} KG
                    </Typography>
                  </Stack>
                  <Stack spacing={1} mr={2} mb={1}>
                    <Typography>Min Capacity</Typography>
                    <Typography fontSize="1.5rem" fontWeight={700}>
                      {selectedMachine.minCapacity} KG
                    </Typography>
                  </Stack>
                  <Stack spacing={1} mr={2} mb={1}>
                    <Typography>Utilization</Typography>
                    <Typography fontSize="1.5rem" fontWeight={700}>
                      {machineUtilization || 0} %
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            )}
          </Stack>
        </Stack>
        {selectedMachine && (
          <BatchesAssociatedWithMachine machine={selectedMachine} />
        )}
        <Stack
          width="100%"
          justifyContent="flex-end"
          spacing={2}
          direction="row">
          <Button variant="outlined" onClick={() => next(0)}>
            Go Back
          </Button>
          <Button variant="contained" onClick={submit(handleSubmit)}>
            Save & Continue
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MachineFabricForm;
