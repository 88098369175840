import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/SearchTwoTone';
import RestartAltIcon from '@mui/icons-material/RestartAltTwoTone';
import { DataGrid } from '@mui/x-data-grid';
import NoDataOverlay from '../global/NoDataOverlay';
import { AllAccountOrderlistColumns } from '../../constants/table-column/order';
import { fetchAllAccountOrder } from '../../api/order';

const OrderAccount = () => {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);
  const [uid, setUid] = useState('');
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [statusTextField, setStatusTextField] = useState(0);
  const [typeTextField, setTypeTextField] = useState(0);
  const textInput = React.useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchOrders(uid, status, page, pageSize);
    setUid(uid);
    setStatus(status);
  };

  const resetAll = () => {
    fetchOrders('', '', page, pageSize);
    setUid('');
    setStatus('');
    textInput.current.value = '';
    setStatusTextField(0);
    setTypeTextField(0);
  };

  const fetchOrders = async (uid, status, page, pageSize) => {
    setIsloading(true);

    const response = await fetchAllAccountOrder({
      uid: uid,
      status: status,
      page: page,
      pageSize: pageSize,
    });

    setOrderList(response.orders);
    setTotalEntries(response.total);
    setIsloading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchOrders();
    })();
  }, []);

  useEffect(() => {
    (async () => await fetchOrders(uid, status, page, pageSize))();
  }, [page, pageSize]);

  return (
    <Stack>
      <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1}>
        Orders
      </Typography>
      <Stack my={1} width="100%">
        <Grid mb={2} container width="100%">
          <Grid item xs={12} sm={7} md={8}>
            <form onSubmit={handleSubmit}>
              <Stack
                mt={2}
                direction="row"
                width="100%"
                spacing={2}
                alignItems="center"
                justifyContent="left">
                <TextField
                  size="small"
                  onChange={(event) => setUid(event.target.value)}
                  inputRef={textInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  flexWrap="wrap"
                  fullWidth
                  id="outlined-basic"
                  label="Search"
                  sx={{ maxWidth: 300 }}
                  variant="outlined"
                />

                {/* for machine status search  */}
                <FormControl fullWidth sx={{ maxWidth: 200 }}>
                  <InputLabel id="SearchStatus">Status</InputLabel>
                  <Select
                    size="small"
                    defaultValue={statusTextField}
                    value={typeTextField}
                    labelId="SearchStatus"
                    id="demo-simple-select"
                    label="Status"
                    placeholder="Status"
                    onChange={(event) => {
                      setStatus(event.target.value);

                      setTypeTextField(event.target.value);
                    }}>
                    <MenuItem value="UNPAID">Unpaid</MenuItem>
                    <MenuItem value="PAID">paid</MenuItem>
                    <MenuItem value="PARTIALLY PAID">Partially Paid</MenuItem>
                  </Select>
                </FormControl>

                {/* Search button  */}
                <IconButton
                  title="Edit"
                  aria-label="edit"
                  type="submit"
                  sx={{
                    bgcolor: '#303030',
                    color: 'white',
                    borderRadius: 1,
                    ':hover': { bgcolor: '#404040' },
                  }}>
                  <SearchIcon />
                </IconButton>

                {/* reset button  */}
                <IconButton
                  title="Edit"
                  aria-label="edit"
                  onClick={() => resetAll()}
                  sx={{
                    bgcolor: '#303030',
                    color: 'white',
                    borderRadius: 1,
                    ':hover': { bgcolor: '#404040' },
                  }}>
                  <RestartAltIcon />
                </IconButton>
              </Stack>
            </form>
          </Grid>
        </Grid>

        {/* Table section */}
        {!orderList ? (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Box
              width="100%"
              height={500}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={AllAccountOrderlistColumns}
                rows={orderList}
                rowCount={totalEntries}
                page={page}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                paginationMode="server"
                filterMode="server"
                onPageChange={(value) => setPage(value)}
                onPageSizeChange={(value) => setPageSize(value)}
                loading={isLoading}
                components={{
                  NoRowsOverlay: NoDataOverlay,
                }}
              />
            </Box>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default OrderAccount;
