import Request from './request';

const axiosV1 = new Request(1);

export const fetchAllUser = async (
  userName,
  status,
  role,
  shift,
  page,
  pageSize,
) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/user', {
    username: userName,
    status,
    role,
    shift,
    page,
    pageSize,
  });

  if (response) {
    return {
      users: response.users,
      total: response.totalEntries,
    };
  } else {
    return { users: [], total: 0 };
  }
};

export const createNewUser = async (data) => {
  const response = await axiosV1.Post('/user', {
    username: data.username,
    password: data.password,
    email: data.email,
    roles: data.roles,
    phone: data.phone,
    name: data.name,
    shiftId: data.shiftId,
  });

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const verifyOtp = async (
  adminUsername,
  newUserUsername,
  otp,
  phone,
  sessionId,
  activeShift,
) => {
  const response = await axiosV1.Post(
    `user/verify-user-creation/${adminUsername}/${phone}/${sessionId}`,
    {
      otpToken: otp,
      otpType: 'create-user-verify',
      username: newUserUsername,
    },
  );
  if (response) {
    const users = await fetchAllUser(activeShift);
    return users;
  }
};

export const getOtpForUserUpdate = async () => {
  const response = await axiosV1.Post('/user/get-otp/');
  if (response) {
    return response.privateKey;
  } else {
    return null;
  }
};

export const updateUser = async ({
  uid,
  data,
  username,
  sessionId,
  phone,
  otpToken,
}) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(
    `/user/update/${uid}/${username}/${phone}/${sessionId}`,
    {
      ...data,
      otpType: 'update-user-verify',
      otpToken,
    },
  );

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const banUsers = async (ids) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Delete('/user', { ids });
  if (response) {
    return response.users;
  } else {
    return null;
  }
};

export const fetchSpecificUser = async (uid) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/user/details/${uid}`);

  if (response) {
    return { user: response.user };
  } else {
    return { user: [] };
  }
};

export const fetchUserBatchLogs = async (uid) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/user/user-batch-log/${uid}`);

  if (response) {
    return { logs: response.logs };
  } else {
    return { logs: [] };
  }
};

export const fetchUserCompletedTask = async (uid) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/user/user-completed-task/${uid}`);

  if (response) {
    return { logs: response.logs };
  } else {
    return { logs: [] };
  }
};

export const fetchUserStats = async (uid) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/user/stats/${uid}`);
  if (response) {
    return {
      stats: response.stats,
    };
  } else {
    return { stats: [] };
  }
};

export const updateShiftId = async (shiftData) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch('/user/shift-update/', {
    uid: shiftData.uid,
    shiftId: shiftData.shiftId,
  });

  if (response) {
    return response;
  } else {
    return null;
  }
};
