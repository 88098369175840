import Request from './request';

export const fetchMedia = async (category, identifier) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get('/file', { category, identifier });

  if (response) {
    return response.medias;
  }

  return [];
};
