import {
  DeleteTwoTone as Delete,
  DragIndicatorTwoTone as DragIndicator,
  VisibilityOffTwoTone as Visibility,
} from '@mui/icons-material';
import {
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { fetchAllTaskForms } from '../../../api/task-form';
import { warningNoitif } from '../../../utils/notification';

const TemplateFields = ({
  index,
  remove,
  errors,
  control,
  register,
  setFormValue,
}) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [taskList, setTaskList] = useState([]);

  const taskType = useWatch({ control, name: `tasks[${index}].taskType` });
  const taskName = useWatch({ control, name: `tasks[${index}].taskName` });
  const isRecipeRequired = useWatch({
    control,
    name: `tasks[${index}].isRecipeRequired`,
  });

  const removeItem = () => {
    if (taskName !== 'Fabric Loading' || taskName !== 'Heat Set') {
      remove(index);
      return;
    }

    warningNoitif('Can not remove this task');
  };

  const selectedTask = useWatch({
    control,
    name: `tasks[${index}]`,
  });

  const clearProcessTypeSelectionOnTaskTypeChange = () => {
    setFormValue(`tasks[${index}].taskName`, '');
    setFormValue(`tasks[${index}].formField`, null);
  };

  useEffect(() => {
    if (!initialLoad) {
      clearProcessTypeSelectionOnTaskTypeChange();
    }
    setInitialLoad(false);
  }, [taskType]);

  const fetchTaskListByTaskType = async () => {
    const response = await fetchAllTaskForms(null, taskType || 'DYEING');
    setTaskList(response);
  };

  useEffect(() => {
    (async () => await fetchTaskListByTaskType())();
  }, [taskType]);

  const handletaskSelectionValueChange = (event, value) => {
    setFormValue(`tasks[${index}].taskName`, value.taskName || '', {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setFormValue(`tasks[${index}].formFields`, value.formFields || [], {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (!selectedTask?.taskName) {
      selectedTask.taskName = '';
      selectedTask.standardTimeHour = 0;
      selectedTask.standardTimeMinute = 0;
    }
  }, [selectedTask]);
  return (
    <Stack
      width="100%"
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      spacing={2}>
      <DragIndicator />
      <Chip color="warning" size="small" label={`Step ${index + 1}`} />
      <FormControl sx={{ width: 200 }} error={errors?.tasks?.[index]?.taskType}>
        <InputLabel>Task Type</InputLabel>
        <Controller
          control={control}
          name={`tasks[${index}].taskType`}
          defaultValue={taskType}
          render={({ field }) => {
            return (
              <Select
                size="small"
                label="Task Type"
                variant="standard"
                value={field.value}
                onChange={(event) =>
                  setFormValue(`tasks[${index}].taskType`, event.target.value)
                }>
                <MenuItem value="DYEING">Dyeing</MenuItem>
                <MenuItem value="FINISHING">Finishing</MenuItem>
                <MenuItem value="QC">QC</MenuItem>
              </Select>
            );
          }}
        />
        {errors?.tasks?.[index]?.taskType && (
          <FormHelperText>
            {errors?.tasks?.[index]?.taskType?.message}
          </FormHelperText>
        )}
      </FormControl>
      <Autocomplete
        defaultValue={selectedTask || ''}
        value={selectedTask || ''}
        options={taskList}
        getOptionLabel={(option) => option.taskName}
        renderOption={(props, option) => {
          return (
            <Stack
              {...props}
              px={1}
              py={1}
              direction="row"
              justifyContent="flex-start">
              <Typography>
                {typeof option === 'object' ? option.taskName : option}
              </Typography>
            </Stack>
          );
        }}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={handletaskSelectionValueChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            size="small"
            label="Select a task"
            sx={{ width: 250 }}
            error={
              errors?.tasks?.[index]?.taskName ||
              errors?.tasks?.[index]?.formFields
            }
            helperText={errors?.tasks?.[index]?.taskName?.message}
          />
        )}
      />
      <TextField
        label="Standard Time (Hour)"
        size="small"
        variant="standard"
        {...register(`tasks[${index}].standardTimeHour`)}
        error={errors?.tasks?.[index]?.standardTimeHour}
        helperText={errors?.tasks?.[index]?.standardTimeHour?.message}
      />
      <TextField
        label="Standard Time (Minute)"
        size="small"
        variant="standard"
        {...register(`tasks[${index}].standardTimeMinute`)}
        error={errors?.tasks?.[index]?.standardTimeMinute}
        helperText={errors?.tasks?.[index]?.standardTimeMinute?.message}
      />
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography>Requires Recipe</Typography>
        <Switch
          checked={isRecipeRequired}
          value={isRecipeRequired}
          onChange={(event) =>
            setFormValue(
              `tasks[${index}].isRecipeRequired`,
              event.target.checked,
            )
          }
        />
      </Stack>
      <IconButton>
        <Visibility />
      </IconButton>
      <IconButton onClick={removeItem}>
        <Delete />
      </IconButton>
    </Stack>
  );
};

export default TemplateFields;
