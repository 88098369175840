import { CircularProgress, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { submitCsvFile } from '../../api/order';
import { ORDER } from '../../constants/routeUrls';
import { errorNoitif } from '../../utils/notification';
import { samlpeData } from '../../utils/order';
import Button from '../../widgets/Button';
import CsvFileUploader from './CsvFileUploader';
import exportFromJSON from 'export-from-json';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';

const SelectOrderCsv = ({ next, setValidOrders, setErrorOrders }) => {
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();

  const handleGoBack = () => {
    navigation(ORDER);
  };

  const submitSelectedFile = async () => {
    if (!file) {
      errorNoitif('You did not add any file');
      return;
    }
    setIsLoading(true);
    const response = await submitCsvFile(
      file /**ANY EXTRA DATA YOU WANT TO PASS */,
    );

    if (response) {
      setValidOrders(response.validArray);
      setErrorOrders(response.errorArray);
      next(1);
    } else {
      errorNoitif('Please select valid files');
    }
    setIsLoading(false);
  };

  const handleExport = async () => {
    exportFromJSON({
      data: samlpeData,
      fileName: 'AREX-sample-Report',
      exportType: exportFromJSON.types.csv,
    });
  };

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center">
      <Stack
        width="80%"
        heigh="100%"
        mx="auto"
        borderRadius={1.5}
        px={2}
        py={2.5}
        spacing={2.5}
        sx={{
          border: '2px solid rgba(0,0,0,0.2)',
        }}>
        <Typography variant="h5" fontWeight={800}>
          Select CSV File
        </Typography>
        <Typography fontSize={15} fontWeight={300}>
          Please fill up the form according to the sample CSV file
        </Typography>
        <Stack
          width="100%"
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Stack width="17vw">
            <Button
              fullWidth
              onClick={() => handleExport()}
              variant="contained"
              endIcon={<DownloadTwoToneIcon />}>
              Download Sample CSV
            </Button>
          </Stack>
        </Stack>
        {isLoading ? (
          <Stack
            justifyContent="center"
            width="100%"
            height="20vh"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack
            heigh="100%"
            direction="column"
            justifyContent="space-between"
            alignItems="space-between">
            <Stack
              mt={2}
              width="100%"
              direction="row"
              justifyContent="center"
              alignItems="center">
              <CsvFileUploader file={file} setFile={setFile}></CsvFileUploader>
            </Stack>

            <Stack
              direction="row"
              width="100%"
              justifyContent="flex-end"
              spacing={2}
              mt={3}>
              <Button onClick={handleGoBack} variant="outlined">
                Return To Menu
              </Button>
              <Button
                variant="contained"
                disabled={file ? false : true}
                onClick={submitSelectedFile}>
                Next
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default SelectOrderCsv;
