import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import reducers from './reducers/rootReducer';
import { authService } from '../api/auth';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['feedback'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    thunk: {
      extraArgument: {
        authService,
      },
    },
  }),
});

export const persistor = persistStore(store);
