import Request from './request';

export const createChemicalAndLots = async (chemicalInfo, chemicalLots) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Post('/inventory', {
    chemicalInfo,
    chemicalLots,
  });

  if (!response) {
    return null;
  }

  return response;
};

export const fetchAllChemicals = async (type, query) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Get('/inventory', {
    type,
    page: query.page,
    pageSize: query.pageSize,
    searchKey: query?.searchKey,
    status: query?.status,
    sku: query?.sku,
    dyeGroup: query?.dyeGroup,
  });

  if (!response) {
    return {
      chemicals: [],
      total: 0,
    };
  }

  return {
    chemicals: response.chemicals,
    total: response.total,
  };
};

export const fetchStockDetails = async (sku) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/inventory/${sku}`);

  if (!response) {
    return null;
  }

  return response.stockDetail;
};

export const fetchChemicalStocks = async (sku, query) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/inventory/lots/${sku}`, query);

  if (!response) {
    return { stocks: [], total: 0 };
  }

  return { stocks: response.stocks, total: response.total };
};
export const fetchTransactions = async (sku, query) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/inventory/usage/${sku}`, query);

  if (!response) {
    return { transactions: [], total: 0 };
  }

  return { transactions: response.transactions, total: response.total };
};

export const fetchAllDyeGroup = async (name, shouldReturnAll) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/inventory/dye-groups', {
    name,
    shouldReturnAll,
  });

  if (!response) {
    return [];
  }

  return response.dyeGroups;
};

export const addDyeGroup = async (name) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Post('/inventory/dye-groups', { name });

  if (!response) {
    return null;
  }

  return response.dyeGroup;
};

export const fetchChemicalStats = async (type) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/inventory/stats', { type });

  if (!response) {
    return [];
  }

  return response.stats;
};

export const addStockToExsistingChemicalLot = async (sku, lots) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Post(`/inventory/lots/${sku}`, { lots });

  if (response) {
    return response;
  }

  return null;
};

export const checkChemicalValidation = async (sku) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get(`/inventory/check/${sku}`);

  if (response) {
    return response;
  }

  return null;
};

// deleteChemical
export const deleteChemical = async (sku) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(`/inventory/${sku}`);

  if (response) {
    return response;
  }

  return null;
};
