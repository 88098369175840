import {
  Stack,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@mui/material';

import React from 'react';

const FabricInfo = ({ fabrics }) => {
  return (
    <Stack flex={1}>
      <Stack
        width="100%"
        height="100%"
        border={1}
        borderRadius={2}
        borderColor="rgba(0,0,0,0.1)"
        px={1}
        py={1}
        spacing={1}>
        <Typography variant="h6" fontWeight={700}>
          Fabrics
        </Typography>
        <Box overflow="auto">
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Fabric Type</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Shade Group</TableCell>
                <TableCell>Shade (%)</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>DIA</TableCell>
                <TableCell>GSM</TableCell>
                <TableCell>Process</TableCell>
                <TableCell>Lab Dip</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fabrics?.map((fabric) => (
                <TableRow key={fabric.id}>
                  <TableCell>{fabric.fabricType}</TableCell>
                  <TableCell>{fabric.colour}</TableCell>
                  <TableCell>{fabric.shadeGroup}</TableCell>
                  <TableCell>{fabric.shade}</TableCell>
                  <TableCell>{fabric.usedQty}</TableCell>
                  <TableCell>{fabric.dia}</TableCell>
                  <TableCell>{fabric.gsm}</TableCell>
                  <TableCell>{fabric.processDescription}</TableCell>
                  <TableCell>{fabric.labDipRef}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FabricInfo;
