/* eslint-disable no-unused-vars */
import { CloseTwoTone as Close } from '@mui/icons-material';
import {
  Autocomplete,
  Chip,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFilteredMachineList, reAssignMachine } from '../../../api/batch';
import { successNoitif, warningNoitif } from '../../../utils/notification';
import Button from '../../../widgets/Button';
import ConcentModal from '../ConcentModal';

const MachineOption = ({ machine, ...rest }) => {
  return (
    <li {...rest} style={{ zIndex: 90000 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        px={1}
        py={1}>
        <Typography variant="body2">{machine.name}</Typography>
        <Chip label={`Utilization ${machine.utilization} %`} size="small" />
        {machine.isBusy ? (
          <Tooltip
            title={`Next Available ${dayjs(machine.availableAt).format(
              'MM/DD/YYYY HH:mm',
            )}`}
            arrow>
            <Chip label="Unavailable" color="warning" size="small" />
          </Tooltip>
        ) : (
          <Chip label="Available" color="success" size="small" />
        )}
      </Stack>
    </li>
  );
};

const MachineReAssignModel = ({
  show,
  onClose,
  machine,
  totalWeight,
  socket,
}) => {
  const [machineList, setMachineList] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [confirmMachineChange, setConfirmMachineChange] = useState(false);

  const { lotNumber } = useParams();

  const fetchMachineList = async () => {
    const response = await getFilteredMachineList(totalWeight || 0);
    setMachineList(response?.filter((item) => item.id !== machine?.id));
  };

  useEffect(() => {
    setSelectedMachine(null);
    setConfirmMachineChange(false);
  }, [show]);

  useEffect(() => {
    (async () => await fetchMachineList())();
  }, [lotNumber]);

  const handleMachineChange = async () => {
    if (!selectedMachine) {
      warningNoitif('Sorry no machine selected');
      return;
    }
    const response = await reAssignMachine(selectedMachine?.name, lotNumber);

    if (response) {
      successNoitif(response);
      setConfirmMachineChange(false);
      onClose();
      socket.emit('batch details', { lotNumber });

      return;
    }

    setConfirmMachineChange(false);
  };

  return (
    <Drawer open={show} onClose={onClose} anchor="right" sx={{ zIndex: 90000 }}>
      <ConcentModal
        show={confirmMachineChange}
        onClose={() => setConfirmMachineChange(false)}
        title="Change Machine"
        text="Are you sure you want to change the machine currently assigned"
        submissionHandler={handleMachineChange}
        sx={{ zIndex: 90000 }}
      />

      <Stack width={320} height="100vh" px={2} py={3}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Typography variant="h5" fontWeight={700}>
            Change Machine
          </Typography>
          <IconButton onClick={onClose} color="error">
            <Close />
          </IconButton>
        </Stack>
        <Stack
          mt={2.6}
          px={1}
          py={1}
          borderRadius={2}
          border={2}
          borderColor="rgba(0,0,0,0.1)"
          direction="row"
          width="100%"
          justifyContent="space-between">
          <Stack spacing={1}>
            <Typography fontSize="0.75rem" fontWeight={700}>
              Currently Assigned
            </Typography>
            <Typography variant="h6" fontWeight={700}>
              {machine?.name} (
              {((totalWeight / machine?.maxCapacity) * 100).toFixed()} %)
            </Typography>
          </Stack>
          <Stack spacing={1} alignItems="flex-end">
            <Typography fontSize="0.75rem" fontWeight={700}>
              Max Capacity
            </Typography>
            <Typography variant="h6" fontWeight={700}>
              {machine?.maxCapacity} Kg
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1} mt={2.5}>
          <Typography fontSize={18} fontWeight={800}>
            Assign Another Machine
          </Typography>
          <Autocomplete
            value={selectedMachine}
            onChange={(event, value) => setSelectedMachine(value)}
            options={machineList}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <MachineOption machine={option} {...props} />
            )}
            disablePortal
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Select Machine"
                placeholder="Select a Machine"
              />
            )}
          />
        </Stack>
        {selectedMachine && (
          <Stack
            mt={2.6}
            px={1}
            py={1}
            borderRadius={2}
            border={2}
            borderColor="rgba(0,0,0,0.1)"
            direction="row"
            width="100%"
            justifyContent="space-between">
            <Stack spacing={1}>
              <Typography fontSize="0.75rem" fontWeight={700}>
                Selected Machine
              </Typography>
              <Typography variant="h6" fontWeight={700}>
                {selectedMachine?.name} (
                {((totalWeight / selectedMachine?.maxCapacity) * 100).toFixed()}{' '}
                %)
              </Typography>
            </Stack>
            <Stack spacing={1} alignItems="flex-end">
              <Typography fontSize="0.75rem" fontWeight={700}>
                Max Capacity
              </Typography>
              <Typography variant="h6" fontWeight={700}>
                {selectedMachine?.maxCapacity} Kg
              </Typography>
            </Stack>
          </Stack>
        )}

        <Button
          variant="contained"
          sx={{ mt: 1 }}
          disabled={!selectedMachine}
          onClick={() => setConfirmMachineChange(true)}>
          Save Changes
        </Button>
      </Stack>
    </Drawer>
  );
};

export default MachineReAssignModel;
