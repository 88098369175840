import { Chip, Drawer, Stack, Typography } from '@mui/material';
import React from 'react';
import { getBatchProcessType, getBatchStatus } from '../../../utils/batch';

const RelatedBatchDrawer = ({ open, batches, toggle }) => {
  return (
    <Drawer
      open={open}
      onClose={() => toggle(false)}
      anchor="right"
      sx={{ zIndex: 3000 }}>
      <Stack
        px={2}
        py={3}
        width={350}
        alignItems="center"
        justifyContent="center"
        spacing={1.5}>
        <Typography
          variant="h6"
          textAlign="left"
          fontWeight={800}
          sx={{ width: '100%' }}>
          Related Batches
        </Typography>
        {batches.map((batch) => (
          <Stack
            px={1}
            py={1}
            borderRadius={1.2}
            border={1}
            width="100%"
            key={batch.id}
            spacing={0.8}>
            <Stack
              direction="row"
              spacing={1.2}
              alignItems="center"
              justifyContent="flex-start">
              <Typography variant="h6" fontWeight={700}>
                Batch # {batch.lotNumber.split('-')[1]}{' '}
              </Typography>
              <Chip
                label={getBatchStatus(batch.status).label}
                color={getBatchStatus(batch.status).color}
                size="small"
              />
            </Stack>
            <Typography fontWeight={600} fontSize="0.8rem">
              Weight: {batch.totalFabricWeight} Kg
            </Typography>
            <Typography fontWeight={600} fontSize="0.8rem">
              Process: {getBatchProcessType(batch.processType)}
            </Typography>
            <Typography fontWeight={600} fontSize="0.8rem">
              Machine: {batch.machineInfo.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Drawer>
  );
};

export default RelatedBatchDrawer;
