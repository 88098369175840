import colors from '../constants/colors';

export const setAccountStatusBackgroundColor = (status) => {
  switch (status) {
    case 'PAID':
      return colors.success;
    case 'UNPAID':
      return colors.error;
    case 'ADVANCED':
      return colors.secondary;
    case 'PARTIALLY PAID':
      return colors.warning;
    case 'PARTIALLY ADVANCED':
      return colors.info;
    default:
      return colors.info;
  }
};

export const setOrderBatchBackgroundColor = (status) => {
  switch (status) {
    case 'DRAFT':
      return colors.info;
    case 'IS PAUSED':
      return colors.warning;
    case 'ON HOLD':
      return colors.error;
    case 'IN QUEUE':
      return colors.warning;
    case 'STARTED':
      return colors.success;
    case 'WAITING FOR DELIVERY':
      return colors.info;
    case 'READY FOR DELIVERY':
      return colors.info;
    case 'DELIVERED':
      return colors.success;
    case 'WAITING FOR APPROVAL':
      return colors.info;
    case 'PARTIAL DELIVERED':
      return colors.info;
    case 'REJECTED':
      return colors.error;
    default:
      return colors.info;
  }
};

export const generateTransactionTypeColor = (type) => {
  switch (type) {
    case 'DUE ADJUSTMENT':
      return colors.info;
    case 'ADVANCE':
      return colors.success;
    case 'OTHER':
      return colors.warning;
    default:
      return colors.info;
  }
};

export const generatePaymentMethodColor = (type) => {
  switch (type) {
    case 'CASH':
      return colors.success;
    case 'CHEQUE':
      return colors.warning;
    default:
      return colors.info;
  }
};
