import { styled, Tab as MuiTab } from '@mui/material';

const Tab = styled(MuiTab)({
  fontSize: '0.9rem',
  letterSpacing: 0.8,
  fontWeight: 700,
  textTransform: 'capitalize',
  backgroundColor: '#eeeeee',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#4562f7',
    transition: '0.3s ease',
  },
});

export default Tab;
