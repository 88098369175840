import {
  Grid,
  Stack,
  Typography,
  useTheme,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
  styled,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import BatchBasicInfoForm from '../../components/batch/create-batch/BatchBasicInfoForm';
import BatchTemplating from '../../components/batch/create-batch/BatchTemplating';
import MachineFabricForm from '../../components/batch/create-batch/MachineFabricForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { BatchCreateSchema } from '../../validators/batch';
import { useForm } from 'react-hook-form';
import { motion, AnimatePresence } from 'framer-motion';
import PageTitle from '../../utils/PageTitle';

const Steper = styled(MuiStepper)({
  '& .Mui-completed': {
    fill: '#66bb6a',
  },
});
const Step = styled(MuiStep)({});
const StepLabel = styled(MuiStepLabel)({});

const AddNewBatch = () => {
  const [activeStep, setActiveStep] = useState(0);
  const resolver = yupResolver(BatchCreateSchema[activeStep]);

  const [createdBatch, setCreatedBatch] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm({
    resolver,
    mode: 'onChange',
    defaultValues: {
      orders: [],
      batchType: 'Batch To Batch',
      processType: 'BULK',
      finishingType: 'TUBE',
      priority: 'LOW',
      fabricInfo: [],
    },
  });

  const preloadDataForUpdatingState = () => {
    setValue('lotNumber', createdBatch.lotNumber);
    setValue('batchType', createdBatch.batchType);
    setValue('processType', createdBatch.processType);
    setValue('finishingType', createdBatch.finishingType);
    setValue('priority', createdBatch.priority);

    const orders = createdBatch?.orderInfo?.map((order) => ({
      id: order.id,
      fcNumber: order.fcNumber,
      partyName: order.partyName,
    }));
    setValue('orders', orders || []);
    setValue('loadingTime', createdBatch.loadingTime);
    setValue('deliveryDate', createdBatch.deliveryDate);
    setValue('fabricInfo', createdBatch?.fabricInfo || []);
    setValue('machine', createdBatch?.machineInfo || null);
    setValue('tasks', createdBatch?.taskInfo?.tasks || []);
    setValue('isWashRequired', createdBatch?.taskInfo?.isWashRequired || false);
    setValue(
      'isHeatSetRequired',
      createdBatch?.taskInfo?.isHeatSetRequired || false,
    );
  };
  useEffect(() => {
    if (isUpdating && createdBatch) {
      preloadDataForUpdatingState();
    }
  }, [isUpdating, createdBatch]);

  const steps = [
    {
      label: 'Configure Batch',
      description: 'Configure order and batch information',
      step: (
        <BatchBasicInfoForm
          isFormDirty={isDirty}
          next={setActiveStep}
          register={register}
          errors={errors}
          formControl={control}
          setFormValue={setValue}
          submit={handleSubmit}
          isUpdating={isUpdating}
          createdBatch={createdBatch}
        />
      ),
    },
    {
      label: 'Select Fabric & Machine',
      description: 'Configure fabric and machine information for this batch',
      step: (
        <MachineFabricForm
          isFormDirty={isDirty}
          next={setActiveStep}
          control={control}
          setFormValue={setValue}
          register={register}
          errors={errors}
          submit={handleSubmit}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          setCreatedBatch={setCreatedBatch}
        />
      ),
    },
    {
      label: 'Batch Planning',
      description:
        'Setup tasks for your batch, and select the templates to use for each task',
      step: (
        <BatchTemplating
          isFormDirty={isDirty}
          next={setActiveStep}
          setFormValue={setValue}
          control={control}
          formErrors={errors}
          register={register}
          submit={handleSubmit}
          setIsUpdating={setIsUpdating}
          setCreatedBatch={setCreatedBatch}
        />
      ),
    },
  ];

  const theme = useTheme();

  const currentStep = steps[activeStep];
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}>
        <Grid
          container
          width="100%"
          height="auto"
          minHeight="calc(100vh - 64px)">
          <PageTitle title="Create Batch" />
          <Grid
            item
            xs={12}
            display={{ xs: 'block', md: 'none' }}
            minHeight="25vh"
            height="auto">
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ width: '100%', px: 2, py: 3 }}>
              <Typography variant="h4" fontWeight={800} textAlign="center">
                {currentStep.label}
              </Typography>
              <Typography sx={{ my: 2 }} textAlign="center">
                {currentStep.description}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            display={{ xs: 'none', md: 'block' }}
            md={2}
            bgcolor={theme.palette.mode === 'light' ? '#f3f3f3' : '#303030'}>
            <Stack
              sx={{
                px: 2,
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}>
              <Steper activeStep={activeStep} orientation="vertical">
                {steps.map((item, index) => {
                  const isActive = index === activeStep;
                  if (isActive) {
                    return (
                      <Step key={item.label}>
                        <StepLabel></StepLabel>
                        <Typography variant="h5" fontWeight={800}>
                          {item.label}
                        </Typography>
                        <Typography sx={{ my: 2 }}>
                          {item.description}
                        </Typography>
                      </Step>
                    );
                  } else {
                    return (
                      <Step key={item.label}>
                        <StepLabel>{item.label}</StepLabel>
                      </Step>
                    );
                  }
                })}
              </Steper>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            height="auto"
            minHeight={{ xs: '75vh', md: 'calc(100vh - 64px)' }}>
            <Box
              px={5}
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center">
              {steps[activeStep].step}
            </Box>
          </Grid>
        </Grid>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddNewBatch;
