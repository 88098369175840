import * as yup from 'yup';

export const TaskFormSchema = yup.object().shape({
  taskName: yup.string().required('Must provide a task name'),
  taskType: yup
    .string()
    .oneOf(['DYEING', 'FINISHING', 'QC'])
    .required('Must select task type'),
  formFields: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required('Must provide field label'),
        fieldType: yup
          .string()
          .oneOf(
            ['TEXT', 'TEXTAREA', 'NUMBER', 'IMAGE', 'YES/NO', 'SELECT', 'LIST'],
            'Invalid option selected',
          )
          .required('Must select field type'),
        inputKeyName: yup
          .string()
          .required('Must add input key name')
          .matches(
            '^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$',
            'Invalid format for identifier',
          ),
      }),
    )
    .min(1, 'At least one field is required')
    .required('At least one field is required'),
});
