import colors from '../constants/colors';

export const setClientStatusColor = (status) => {
  switch (status) {
    case 'ACTIVE':
      return colors.success;
    case 'INACTIVE':
      return colors.error;
    default:
      return colors.info;
  }
};

export const setQuotationBackgroundColor = (status) => {
  switch (status) {
    case 'VALID':
      return colors.success;
    case 'WAITING FOR APPROVAL':
      return colors.warning;
    case 'EXPIRED':
      return colors.error;
    default:
      return colors.info;
  }
};
