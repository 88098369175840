import { ScienceTwoTone as Science } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { BATCH } from '../../../constants/routeUrls';
import Button from '../../../widgets/Button';

const NoChemicalRequired = ({ lotNumber }) => {
  return (
    <Stack
      width="100%"
      height="60vh"
      alignItems="center"
      justifyContent="center"
      spacing={2}>
      <Science sx={{ width: 60, height: 60 }} />
      <Typography variant="h4" fontWeight={700}>
        No Chemical Required
      </Typography>
      <Typography>
        No chemical configuration required for this batch #
        {lotNumber?.split('-')[1]}
      </Typography>
      <Button variant="contained" component={Link} to={BATCH}>
        Go Back
      </Button>
    </Stack>
  );
};

export default NoChemicalRequired;
