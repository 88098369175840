import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../widgets/PageHeader';
import BatchAccount from '../../components/account/BatchAccount';
import OrderAccount from '../../components/account/OrderAccount';
import { ACCOUNT } from '../../constants/routeUrls';
import Tabs from '../../widgets/Tabs';
import Tab from '../../widgets/Tab';
import PageTitle from '../../utils/PageTitle';

const Account = () => {
  //for tab section
  let navigate = useNavigate();
  const { tab: tabParams } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(parseInt(tabParams));
  }, [tabParams]);

  const tabs = [
    {
      Element: <OrderAccount />,
    },
    {
      Element: <BatchAccount />,
    },
  ];

  const currentTab = tabs[activeTab];
  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
    navigate(`${ACCOUNT}/${tab}`);
  };
  return (
    <Stack
      spacing={2}
      width="100%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <PageTitle title="Accounts" />
      {/* header section  */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        spacing={2}>
        <PageHeader>Accounts</PageHeader>
      </Stack>
      {/* tab section  */}
      <Stack mt={4}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ width: 'max-content' }}>
          <Tab label={'ORDER'} />
          <Tab label={'BATCH'} />
        </Tabs>
      </Stack>
      <Stack width="100%" height={450}>
        {currentTab.Element}
      </Stack>
    </Stack>
  );
};

export default Account;
