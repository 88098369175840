import permissionMapping from '../constants/permissionMapping';
import { store } from '../store';

export default (permission) => {
  const roles = store.getState().auth.user.roles;

  let hasPermission = false;
  roles.split(',').map((role) => {
    const permissionList = permissionMapping[role];

    if (permissionList) {
      if (permissionList.includes(permission)) {
        hasPermission = true;
      }
    }
  });

  return hasPermission;
};
