import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs';
import { formatTime } from '../../utils/time';
import { convertNumberToWords, formatNumber } from '../../utils/number';

const OrderAccountTranscript = ({ reciptData, open, setOpen, orderInfo }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: `AREX-${orderInfo?.fcNumber}-QR-${formatTime(new Date())}`,
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (open) {
      handlePrint();
      setOpen(false);
    }
  }, [open]);

  return (
    <div style={{ display: 'none' }}>
      <Box
        mx="auto"
        width="1080px"
        my={2}
        mt={3}
        ref={componentRef}
        boxSizing="border-box"
        padding="15px 30px"
        class="page">
        {/* header section  */}
        <Grid my={2} container spacing={2} alignItems="center">
          <Grid item xs={5}>
            {' '}
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}>
              <Box
                component="img"
                sx={{
                  width: 150,
                }}
                alt="The house from the offer."
                src="/assets/images/logo.svg"
              />
              <Stack>
                <Typography fontSize={18}>2/1,Gadda,Savar,Dhaka</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack width="100%">
              <Typography fontSize={25} fontWeight={700}>
                Money Receipt
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Divider />
        {/* body section  */}
        <Stack mt={2} mb={2}>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}>
              <Typography>No:</Typography>
              <Typography
                style={{
                  borderBottom: '1px dashed #000',
                  textDecoration: 'none',
                }}>
                {reciptData?.transactionId}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}>
              <Typography>Date:</Typography>
              <Typography
                style={{
                  borderBottom: '1px dashed #000',
                  textDecoration: 'none',
                }}>
                {dayjs(reciptData?.paymentDate).format('DD/MMM/YYYY ')}
              </Typography>
            </Stack>
          </Stack>

          <Stack my={2} width="100%" />
          {/* Received from  */}
          <Stack width="100%">
            <Grid container>
              <Grid item xs={2}>
                <Typography>Received from:</Typography>
              </Grid>
              <Grid
                item
                xs={10}
                style={{
                  borderBottom: '1px dashed #000',
                  textDecoration: 'none',
                }}>
                <Typography style={{ textAlign: 'center' }}>
                  {orderInfo?.partyName}
                </Typography>
              </Grid>
            </Grid>
          </Stack>

          <Stack my={2} width="100%" />

          {/* Amount in words  */}
          <Stack width="100%">
            <Grid container>
              <Grid item xs={2.5}>
                <Typography>Amount in words :</Typography>
              </Grid>
              <Grid
                item
                xs={9.5}
                style={{
                  borderBottom: '1px dashed #000',
                  textDecoration: 'none',
                }}>
                <Typography style={{ textAlign: 'center' }}>
                  {convertNumberToWords(reciptData?.paymentAmount)} Taka Only
                </Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* payment method  */}
          <Stack mt={2} width="100%">
            {reciptData?.paymentBy == 'CHEQUE' ? (
              <Grid container spacing={0.8}>
                <Grid item xs={4}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography>Payment method :</Typography>
                    <Typography
                      style={{
                        borderBottom: '1px dashed #000',
                        textDecoration: 'none',
                      }}>
                      {reciptData?.paymentBy}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>Cheque No :</Typography>
                    <Typography
                      style={{
                        borderBottom: '1px dashed #000',
                        textDecoration: 'none',
                      }}>
                      {reciptData?.chequeNo}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography>Cheque Date :</Typography>
                    <Typography
                      style={{
                        borderBottom: '1px dashed #000',
                        textDecoration: 'none',
                      }}>
                      {dayjs(reciptData?.chequeDate).format('DD/MMM/YYYY ')}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6.5}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>Bank Name :</Typography>
                    <Typography
                      style={{
                        borderBottom: '1px dashed #000',
                        textDecoration: 'none',
                      }}>
                      {reciptData?.bankName}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Typography>Payment for:</Typography>
                </Grid>
                <Grid
                  item
                  xs={3.5}
                  style={{
                    borderBottom: '1px dashed #000',
                    textDecoration: 'none',
                  }}>
                  <Typography style={{ textAlign: 'center' }}>
                    {reciptData?.transactionType}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={0.5}>
                <Grid item xs={5}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography>Payment method :</Typography>
                    <Typography
                      style={{
                        borderBottom: '1px dashed #000',
                        textDecoration: 'none',
                      }}>
                      {reciptData?.paymentBy}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={2}>
                  <Typography>Payment for:</Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    borderBottom: '1px dashed #000',
                    textDecoration: 'none',
                  }}>
                  <Typography style={{ textAlign: 'center' }}>
                    {reciptData?.transactionType}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Stack>

          <Stack mt={1} />
          {reciptData?.transactionType == 'OTHER' ? (
            <>
              <Stack my={1} width="100%">
                <Grid container>
                  <Grid item xs={1.5}>
                    <Typography>Reason:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={10.5}
                    style={{
                      borderBottom: '1px dashed #000',
                      textDecoration: 'none',
                    }}>
                    <Typography style={{ textAlign: 'center' }}>
                      {reciptData?.reason}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </>
          ) : (
            <></>
          )}

          <Stack my={1} width="100%">
            <Grid container>
              <Grid item xs={1.5}>
                <Typography>Delviery No:</Typography>
              </Grid>
              <Grid
                item
                xs={10.5}
                style={{
                  borderBottom: '1px dashed #000',
                  textDecoration: 'none',
                }}>
                <Typography style={{ textAlign: 'center' }}>
                  {reciptData?.deliveryInfo?.uid}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}>
          <Typography fontWeight={700}>TK.</Typography>
          <Stack>
            <Stack
              height={70}
              width="100%"
              border="2px solid black"
              justifyContent="center"
              alignItems="center"
              p={1}>
              <Typography fontWeight={700} fontSize="20px">
                ৳{formatNumber(reciptData?.paymentAmount)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {/* signature  */}
        <Grid className="singnature-class" container spacing={2} mt={8}>
          <Grid item xs={4}>
            <Divider width="80%"></Divider>
            <Stack width="90%" justifyContent="center" alignItems="center">
              <Stack my={0.5}>
                <Typography fontSize={15}>Authorized Signature</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Divider width="80%"></Divider>
            <Stack width="90%" justifyContent="center" alignItems="center">
              <Stack my={0.5}>
                <Typography fontSize={15}>Received By</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack
          mt={3}
          width="100%"
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          <Typography>
            ***This is an electronically generated receipt.
          </Typography>
        </Stack>
      </Box>
    </div>
  );
};

export default OrderAccountTranscript;
