import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchBatchProductionStats } from '../../../api/batch';
import { formatNumber, humanizeNumber } from '../../../utils/number';
import { milisecondsToTime } from '../../../utils/time';

const Stats = () => {
  const [stats, setStats] = useState([]);

  const fetchData = async () => {
    const response = await fetchBatchProductionStats();
    setStats(response);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  useEffect(() => {
    const interval = setInterval(
      async () => await fetchData(),
      15 * 60 * 10000,
    );

    return () => clearInterval(interval);
  }, []);

  const Stat = ({ label, content, bgcolor, ...rest }) => {
    return (
      <Stack
        height={140}
        width="100%"
        border="2px solid rgba(0,0,0,0.2)"
        borderRadius={2}
        justifyContent="center"
        alignItems="center"
        bgcolor={bgcolor}
        {...rest}
        p={1}>
        <Typography fontSize={12} fontWeight={700} textAlign="center">
          {label}
        </Typography>

        <Typography fontWeight={800} fontSize={26} component="div">
          {content}
        </Typography>
      </Stack>
    );
  };

  const numberChecker = (number) => {
    if (isNaN(number)) {
      return '-';
    }
    if (!isFinite(number)) {
      return '-';
    }
    if (number < 1) {
      return 0;
    }
    return formatNumber(number);
  };

  return (
    <Stack
      width="100%"
      height="100%"
      spacing={1}
      justifyContent="space-between">
      <Stack direction="row" width="100%" spacing={1}>
        <Stat label="Shift" content={stats?.shift?.name || '-'} />
        <Stat
          label="Production Target (Kg)"
          content={humanizeNumber(
            isNaN(stats.productionTarget) ? 0 : stats.productionTarget,
          )}
        />
        <Stat
          label="Dyeing Target (Kg)"
          content={humanizeNumber(
            isNaN(stats.dyeingTarget) ? 0 : stats.dyeingTarget,
          )}
        />
        <Stat
          label="Production Rate (kg/h)"
          content={numberChecker(stats.productionRate)}
        />
      </Stack>
      <Stack direction="row" width="100%" spacing={1}>
        <Stat
          label="Dyeing Rate (Kg/h)"
          content={numberChecker(stats.dyeingProductionRate)}
        />
        <Stat
          label="Finishing Rate (Kg/h)"
          content={numberChecker(stats.finishingProductionRate)}
        />
        <Stat
          label="Delay"
          content={milisecondsToTime(stats.delay)}
          bgcolor="#FA7070"
        />
        <Stat
          label="Time Saved"
          content={milisecondsToTime(stats.early)}
          bgcolor="#7FB77E"
        />
      </Stack>
    </Stack>
  );
};

export default Stats;
