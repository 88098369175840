import {
  Chip,
  LinearProgress,
  Stack,
  Tooltip,
  IconButton,
  Typography,
} from '@mui/material';
import {
  VisibilityTwoTone as Visibility,
  DoneOutlineTwoTone as Done,
  CloseTwoTone as NotDone,
  EditTwoTone as Edit,
  LocalShippingTwoTone as Delivery,
  LinkTwoTone as Chain,
  HourglassEmptyTwoTone as Wait,
  PauseCircleOutlineTwoTone as Pause,
  FactoryTwoTone as Factory,
  WhereToVoteTwoTone as Ready,
} from '@mui/icons-material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrintIcon from '@mui/icons-material/Print';

import dayjs from 'dayjs';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import {
  ACCOUNT_DELIVERY_DETAILS,
  ACCOUNT_ORDER_DETAILS,
  EDIT_BULK_ORDER,
  EDIT_ORDER,
  ORDER_DETAILS,
  UPDATE_DELIVERY,
} from '../routeUrls';
import PermissionWrapper from '../../pages/wrapper/PermissionWrapper';
import { formatNumber } from '../../utils/number';
import {
  setOrderDeliveryBillStatusColor,
  setOrderStatusColor,
} from '../../utils/order';
import {
  generatePaymentMethodColor,
  generateTransactionTypeColor,
  setAccountStatusBackgroundColor,
} from '../../utils/account';
import Button from '../../widgets/Button';

const isApproved = (value) => {
  if (value == true) {
    return (
      <IconButton size="small">
        <Done sx={{ width: 18, height: 18 }} />
      </IconButton>
    );
  } else {
    return (
      <IconButton size="small">
        <NotDone sx={{ width: 18, height: 18 }} />
      </IconButton>
    );
  }
};

export const AllOrderListColumns = [
  {
    field: 'ACTIONS',
    headerName: '',
    width: 70,
    type: 'date',
    renderCell: (params) => (
      <Stack direction="row" spacing={1}>
        {/* <Button>
          <VisibilityIcon />
        </Button> */}

        {params?.row?.isApproved == true ? (
          <>
            <IconButton
              size="small"
              component={Link}
              to={`${ORDER_DETAILS}/${params.row.fcNumber}/0`}>
              <Visibility sx={{ width: 18, height: 18 }} />
            </IconButton>
          </>
        ) : (
          <>
            {params?.row?.isBulkOrder == true ? (
              <IconButton
                size="small"
                component={Link}
                to={`${EDIT_BULK_ORDER}/${params.row.fcNumber}`}>
                <Edit sx={{ width: 18, height: 18 }} />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                component={Link}
                to={`${EDIT_ORDER}/${params.row.fcNumber}`}>
                <Edit sx={{ width: 18, height: 18 }} />
              </IconButton>
            )}
          </>
        )}
      </Stack>
    ),
  },
  {
    field: 'fcNumber',
    headerName: 'FC NUMBER',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'partyName',
    headerName: 'PARTY NAME',
    width: 150,
    renderCell: (params) => {
      return (
        <Stack>
          {params?.value?.length > 10 ? (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value.substr(0, 10)}...</Typography>
              </Stack>
            </Tooltip>
          ) : (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value}</Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'isApproved',
    headerName: 'IS APPROVED',
    width: 150,
    renderCell: (params) => {
      return <Stack>{isApproved(params?.value)}</Stack>;
    },
    filterable: false,
    sortable: false,
  },

  {
    field: 'status',
    headerName: 'STATUS',
    width: 150,
    renderCell: (params) => {
      const status = params?.value;

      return (
        <Chip
          size="small"
          label={status}
          sx={{ backgroundColor: setOrderStatusColor(status), color: '#ffff' }}
        />
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'finsihedStock',
    headerName: 'FINISHED STOCK(KG)',
    width: 180,

    filterable: false,
    sortable: false,
  },

  {
    field: 'totalQuantity',
    headerName: 'TOTAL QUANTITY(KG)',
    width: 180,

    filterable: false,
    sortable: false,
  },

  {
    field: 'PROGRESS',
    headerName: 'PROGRESS',
    width: 180,

    renderCell: (params) => {
      let progressValue = 0;
      progressValue =
        (params?.row?.finsihedStock / params?.row?.totalQuantity) * 100;

      return (
        <Box sx={{ width: '100%' }}>
          <Typography>{progressValue.toFixed(2)}%</Typography>
          <LinearProgress variant="determinate" value={progressValue} />
        </Box>
      );
    },
  },
  {
    field: 'salesPerson',
    headerName: 'SALES PERSON',
    width: 180,

    filterable: false,
    sortable: false,
  },
  {
    field: 'deliveryDate',
    headerName: 'DELIVERY DATE',
    width: 170,
    type: 'date',
    renderCell: (params) => {
      if (params.value) {
        return params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`;
      } else {
        return 'Invalid Date';
      }
    },
  },
  {
    field: 'createdAt',
    headerName: 'ISSUE DATE',
    width: 170,
    type: 'date',
    renderCell: (params) =>
      params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`,
  },
];

export const OrderBatchListColumn = [
  {
    field: 'fabricType',
    headerName: 'FABRIC TYPE',
    width: 130,
    filterable: false,
    sortable: false,
  },

  {
    field: 'batchFabricAmount',
    headerName: 'QTY(KG)',
    width: 100,
    filterable: false,
    sortable: false,
  },

  {
    field: 'batchFabricAmount',
    headerName: 'QTY(KG)',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'rate',
    headerName: 'RATE(BDT)',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'amount',
    headerName: 'AMOUNT(BDT)',
    width: 130,
    renderCell: (params) => {
      let amount = 0;
      amount = params?.row?.batchFabricAmount * params?.row?.rate;

      return <Typography fontSize="small">{amount.toFixed(2)}</Typography>;
    },
  },
];

export const OrderFabricsListColumns = [
  {
    field: 'fabricType',
    headerName: 'FABRIC TYPE',
    width: 130,
    filterable: false,
    sortable: false,
  },

  {
    field: 'batchFabricAmount',
    headerName: 'QTY(KG)',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'rate',
    headerName: 'RATE(BDT)',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'amount',
    headerName: 'AMOUNT(BDT)',
    width: 130,
    renderCell: (params) => {
      let amount = 0;
      amount = params?.row?.batchFabricAmount * params?.row?.rate;

      return <Typography fontSize="small">{amount.toFixed(2)}</Typography>;
    },
  },
];

const generateBatchTaskStatusColor = (status) => {
  switch (status) {
    case 'IN QUEUE':
      return '#2196f3';
    case 'PREPARED':
      return '#6fbf73';
    default:
      return '#2196F3';
  }
};

export const OrderBatchTaskListColumn = ({
  setChemicalList,
  setOpenChemicalModal,
}) => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 70,
      type: 'date',
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            onClick={() => {
              setChemicalList(params.row), setOpenChemicalModal(true);
            }}
            style={{
              paddingLeft: '0px !important',
              marginLeft: '0px !important',
              paddingRight: '0px !important',
              marginRight: '0px !important',
            }}>
            <Visibility sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'taskName',
      headerName: 'TASK NAME',
      width: 130,
      filterable: false,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 130,
      renderCell: (params) => {
        const status = params?.value;

        return (
          <Chip
            size="small"
            label={status}
            sx={{
              color: 'white',
              backgroundColor: generateBatchTaskStatusColor(status),
            }}
          />
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'totalCost',
      headerName: 'COST(BDT)',
      width: 130,
      renderCell: (params) => {
        if (params?.row?.status === 'PREPARED') {
          return (
            <Typography fontSize="small">
              {params?.row?.totalCost.toFixed(2)}
            </Typography>
          );
        } else {
          return <Typography fontSize="small">Not Calculated Yet</Typography>;
        }
      },
    },
  ];
};

export const OrderBatchTaskChemicalListColumn = [
  {
    field: 'chemicalName',
    headerName: 'CHEMICAL NAME',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'unit',
    headerName: 'UNIT',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'total',
    headerName: 'QUANTITY(KG)',
    width: 150,
    renderCell: (params) => {
      let amount = 0;

      amount = params?.row?.total * 0.001;

      return <Typography fontSize="small">{amount.toFixed(2)}</Typography>;
    },
  },
  {
    field: 'addition',
    headerName: 'ADDITIONAL(KG)',
    width: 150,
    renderCell: (params) => {
      let amount = 0;
      let addition = params?.row?.addition || 0;
      amount = addition * 0.001;

      return <Typography fontSize="small">{amount.toFixed(2)}</Typography>;
    },
  },
  {
    field: 'cost',
    headerName: 'COST(BDT)',
    width: 130,
    renderCell: (params) => {
      if (params?.row?.isPrepared) {
        return (
          <Typography fontSize="small">
            {params?.row?.cost.toFixed(2)}
          </Typography>
        );
      } else {
        return <Typography fontSize="small">Not Calculated Yet</Typography>;
      }
    },
  },
];

export const BatchTaskChemicalDetailsColumns = [
  {
    field: 'chemicalName',
    headerName: 'CHEMICAL NAME',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'unit',
    headerName: 'UNIT',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'total',
    headerName: 'QUANTITY(KG)',
    width: 150,
    renderCell: (params) => {
      let amount = 0;

      amount = params?.row?.total * 0.001;

      return <Typography fontSize="small">{amount.toFixed(2)}</Typography>;
    },
  },
  {
    field: 'addition',
    headerName: 'ADDITIONAL(KG)',
    width: 150,
    renderCell: (params) => {
      let amount = 0;
      let addition = params?.row?.addition || 0;
      amount = addition * 0.001;

      return <Typography fontSize="small">{amount.toFixed(2)}</Typography>;
    },
  },
  {
    field: 'cost',
    headerName: 'COST(BDT)',
    width: 130,
    renderCell: (params) => {
      if (params?.row?.isPrepared) {
        return (
          <Typography fontSize="small">
            {params?.row?.cost.toFixed(2)}
          </Typography>
        );
      } else {
        return <Typography fontSize="small">Not Calculated Yet</Typography>;
      }
    },
  },
];

export const paymentLogListColumn = ({
  setReciptData,
  setOpen,
  setPaymentEditData,
  setShowPaymentEditModal,
}) => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 100,
      type: 'date',
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            onClick={() => {
              setReciptData(params.row);
              setOpen(true);
            }}
            style={{
              paddingLeft: '0px !important',
              marginLeft: '0px !important',
              paddingRight: '0px !important',
              marginRight: '0px !important',
            }}>
            <PrintIcon sx={{ width: 18, height: 18 }} />
          </IconButton>
          <PermissionWrapper acceptedRoles="MANAGER,ADMIN">
            <IconButton
              size="small"
              onClick={() => {
                setPaymentEditData(params.row), setShowPaymentEditModal(true);
              }}
              style={{
                paddingLeft: '0px !important',
                marginLeft: '0px !important',
                paddingRight: '0px !important',
                marginRight: '0px !important',
              }}>
              <Edit sx={{ width: 18, height: 18 }} />
            </IconButton>
          </PermissionWrapper>
        </Stack>
      ),
    },
    {
      field: 'transactionId',
      headerName: 'TRANSACTION #',
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryId',
      headerName: 'DELIVERY #',
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.deliveryInfo?.uid}</Typography>;
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'transactionType',
      headerName: 'TYPE',
      width: 170,
      renderCell: (params) => {
        const status = params?.value;

        return (
          <Chip
            size="small"
            label={status}
            sx={{
              color: 'white',
              backgroundColor: generateTransactionTypeColor(status),
            }}
          />
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'reason',
      headerName: 'REASON',
      width: 200,
      renderCell: (params) => {
        const status = params?.value;

        return (
          <Stack>
            {status?.length > 20 ? (
              <Tooltip title={status}>
                <Stack>
                  <Typography>{status.substr(0, 20)}...</Typography>
                </Stack>
              </Tooltip>
            ) : (
              <Stack>
                {status?.length > 0 ? (
                  <Typography>{status}</Typography>
                ) : (
                  <Typography>N/A</Typography>
                )}
              </Stack>
            )}
          </Stack>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'paymentDate',
      headerName: 'TRANSACTION DATE',
      width: 170,
      type: 'date',
      renderCell: (params) =>
        params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`,
    },
    {
      field: 'paymentAmount',
      headerName: 'PAYMENT AMOUNT(BDT)',
      width: 200,
      renderCell: (params) => {
        return <Typography>{formatNumber(params?.value)}</Typography>;
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'paymentBy',
      headerName: 'PAYMENT METHOD',
      width: 170,
      renderCell: (params) => {
        const status = params?.value;

        return (
          <Chip
            size="small"
            label={status}
            sx={{
              color: 'white',
              backgroundColor: generatePaymentMethodColor(status),
            }}
          />
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'bankName',
      headerName: 'BANK NAME',
      width: 150,
      renderCell: (params) => {
        const bankName = params?.value;

        return (
          <Stack>
            {bankName?.length > 20 ? (
              <Tooltip title={bankName}>
                <Stack>
                  <Typography>{bankName.substr(0, 20)}...</Typography>
                </Stack>
              </Tooltip>
            ) : (
              <Stack>
                {bankName?.length > 0 ? (
                  <Typography>{bankName}</Typography>
                ) : (
                  <Typography>N/A</Typography>
                )}
              </Stack>
            )}
          </Stack>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'chequeNo',
      headerName: 'CHEQUE #',
      width: 150,
      renderCell: (params) => {
        const chequeNo = params?.value;

        return (
          <Stack>
            {chequeNo?.length > 0 ? (
              <Typography>{chequeNo}</Typography>
            ) : (
              <Typography>N/A</Typography>
            )}
          </Stack>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'chequeDate',
      headerName: 'CHEQUE DATE',
      width: 150,
      renderCell: (params) => {
        return (
          <Stack>
            {params?.row?.paymentBy == 'CHEQUE' ? (
              <>
                {params.value &&
                  `${dayjs(params.value).format('DD/MMM/YYYY ')}`}
              </>
            ) : (
              <Typography>N/A</Typography>
            )}
          </Stack>
        );
      },
      filterable: false,
      sortable: false,
    },
  ];
};

export const AllAccountOrderlistColumns = [
  {
    field: 'ACTIONS',
    headerName: '',
    width: 70,
    type: 'date',
    renderCell: (params) => (
      <IconButton
        size="small"
        component={Link}
        to={`${ACCOUNT_ORDER_DETAILS}/${params.row.uid}/0`}>
        <Visibility sx={{ width: 18, height: 18 }} />
      </IconButton>
    ),
  },
  {
    field: 'uid',
    headerName: 'UID',
    width: 130,
    filterable: false,
    sortable: false,
  },

  {
    field: 'partyName',
    headerName: 'PARTY NAME',
    width: 200,
    renderCell: (params) => {
      return (
        <Stack>
          {params?.row?.orderInfo?.partyName > 10 ? (
            <Tooltip title={params?.row?.orderInfo?.partyName}>
              <Stack>
                <Typography>
                  {params?.row?.orderInfo?.partyName.substr(0, 10)}...
                </Typography>
              </Stack>
            </Tooltip>
          ) : (
            <Tooltip title={params?.row?.orderInfo?.partyName}>
              <Stack>
                <Typography>{params?.row?.orderInfo?.partyName}</Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
      );
    },
    filterable: false,
    sortable: false,
  },

  {
    field: 'status',
    headerName: 'STATUS',
    width: 150,
    renderCell: (params) => {
      const status = params?.value;

      return (
        <Chip
          size="small"
          label={status}
          sx={{
            backgroundColor: setAccountStatusBackgroundColor(status),
            color: 'white',
          }}
        />
      );
    },
    filterable: false,
    sortable: false,
  },

  {
    field: 'finsihedStock',
    headerName: 'FINISHED STOCK(KG)',
    width: 180,

    filterable: false,
    sortable: false,
  },
  {
    field: 'totalQuantity',
    headerName: 'TOTAL QUANTITY(KG)',
    width: 180,

    filterable: false,
    sortable: false,
  },
  {
    field: 'PROGRESS',
    headerName: 'PROGRESS',
    width: 180,

    renderCell: (params) => {
      let progressValue = 0;
      progressValue =
        (params?.row?.finsihedStock / params?.row?.totalQuantity) * 100;

      return (
        <Box sx={{ width: '100%' }}>
          <Typography>{progressValue.toFixed(2)}%</Typography>
          <LinearProgress variant="determinate" value={progressValue} />
        </Box>
      );
    },
  },

  {
    field: 'totalAmount',
    headerName: 'TOTAL AMOUNT (BDT)',
    width: 180,
    renderCell: (params) => {
      return <Typography>{formatNumber(params?.value)}</Typography>;
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'totalPaid',
    headerName: 'AMOUNT PAID (BDT)',
    width: 180,
    renderCell: (params) => {
      return <Typography>{formatNumber(params?.value)}</Typography>;
    },
    filterable: false,
    sortable: false,
  },

  {
    field: 'deliveryDate',
    headerName: 'DELIVERY DATE',
    width: 170,
    type: 'date',
    renderCell: (params) => {
      if (params?.row?.orderInfo?.deliveryDate) {
        return (
          params?.row?.orderInfo?.deliveryDate &&
          `${dayjs(params?.row?.orderInfo?.deliveryDate).format(
            'DD/MMM/YYYY ',
          )}`
        );
      } else {
        return 'Invalid Date';
      }
    },
  },
];

export const AllCSVListColumns = [
  {
    field: 'fcNumber',
    headerName: 'FC NUMBER',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'partyName',
    headerName: 'CLIENT NAME',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'issueDate',
    headerName: 'ISSUE DATE',
    width: 170,
    type: 'date',
    renderCell: (params) =>
      params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`,
  },

  {
    field: 'salesPerson',
    headerName: 'SALES PERSON',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'deliveryAddress',
    headerName: 'DELIVERY ADDRESS',
    width: 130,
    renderCell: (params) => {
      return (
        <Stack>
          {params?.value?.length > 20 ? (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value.substr(0, 20)}...</Typography>
              </Stack>
            </Tooltip>
          ) : (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value}</Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'remarks',
    headerName: 'ORDER REMARKS',
    width: 130,
    filterable: false,
    sortable: false,
  },
];

export const AllValidListColumns = [
  {
    field: 'fcNumber',
    headerName: 'FC NUMBER',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'clientName',
    headerName: 'CLIENT NAME',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'issueDate',
    headerName: 'ISSUE DATE',
    width: 170,
    type: 'date',
    renderCell: (params) =>
      params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`,
  },

  {
    field: 'salesPerson',
    headerName: 'SALES PERSON',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'deliveryAddress',
    headerName: 'DELIVERY ADDRESS',
    width: 130,
    renderCell: (params) => {
      return (
        <Stack>
          {params?.value?.length > 20 ? (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value.substr(0, 20)}...</Typography>
              </Stack>
            </Tooltip>
          ) : (
            <Tooltip title={params?.value}>
              <Stack>
                <Typography>{params?.value}</Typography>
              </Stack>
            </Tooltip>
          )}
        </Stack>
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'remarks',
    headerName: 'ORDER REMARKS',
    width: 130,
    filterable: false,
    sortable: false,
  },
];

export const ErrorCSVListColumns = [
  {
    field: 'errorMsg',
    headerName: 'Invalidity Reason',
    width: 200,
    filterable: false,
    sortable: false,
    renderCell: (params) => {
      return (
        <Stack>
          <Tooltip title={params?.value}>
            <Typography color="red" fontSize="small">
              {params?.value}
            </Typography>
          </Tooltip>
        </Stack>
      );
    },
  },
  {
    field: 'fcNumber',
    headerName: 'FC NUMBER',
    width: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: 'clientName',
    headerName: 'CLIENT NAME',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'issueDate',
    headerName: 'ISSUE DATE',
    width: 170,
    type: 'date',
    renderCell: (params) =>
      params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`,
  },
  {
    field: 'deliveryAddress',
    headerName: 'DELIVERY ADDRESS',
    width: 200,
    filterable: false,
    sortable: false,
  },
  {
    field: 'remarks',
    headerName: 'ORDER REMARKS',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'salesPerson',
    headerName: 'SALES PERSON',
    width: 130,
    filterable: false,
    sortable: false,
  },
];

const calculateUnprocessed = (
  totalQuantity,
  finsihedStock,
  deliveredQuantity,
) => {
  let unprocessed = 0;
  unprocessed = totalQuantity - (finsihedStock + deliveredQuantity);
  return unprocessed;
};
export const OrderFabricInfo = [
  {
    field: 'shadeGroup',
    headerName: 'SHADE GROUP',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{params.row.fabricInfo.shadeGroup}</Typography>
      </Stack>
    ),
  },

  {
    field: 'color',
    headerName: 'COLOR',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{params.row.fabricInfo.colour}</Typography>
      </Stack>
    ),
  },
  {
    field: 'shade',
    headerName: 'SHADE %',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{params.row.fabricInfo.shadeRange}</Typography>
      </Stack>
    ),
  },
  {
    field: 'fabricType',
    headerName: 'FABRIC TYPE',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{params.row.fabricInfo.fabricType}</Typography>
      </Stack>
    ),
  },
  {
    field: 'quantity',
    headerName: 'QUANTITY (KG)',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{params.row.fabricInfo.quantity}</Typography>
      </Stack>
    ),
  },
  {
    field: 'processed',
    headerName: 'FINISHED STOCK(KG)',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{params.row.fabricInfo.finishedStock}</Typography>
      </Stack>
    ),
  },

  {
    field: 'deliveryQuantity',
    headerName: 'DELIVERED(KG)',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{params.row.fabricInfo.deliveryQuantity}</Typography>
      </Stack>
    ),
  },

  {
    field: 'Unprocessed',
    headerName: 'UNPROCESSED(KG)',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>
          {calculateUnprocessed(
            params.row.fabricInfo.quantity,
            params.row.fabricInfo.finishedStock,
            params.row.fabricInfo.deliveryQuantity,
          )}
        </Typography>
      </Stack>
    ),
  },

  {
    field: 'gsm',
    headerName: 'GSM',
    width: 80,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{params.row.fabricInfo.gsm}</Typography>
      </Stack>
    ),
  },
  {
    field: 'dia',
    headerName: 'DIA',
    width: 80,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{params.row.fabricInfo.dia}</Typography>
      </Stack>
    ),
  },
];

const reformateLotNumber = (lotNumber) => {
  const lotArray = lotNumber.split('-');
  return lotArray[1];
};

const batchStatusIconGenerate = (status) => {
  if (status == 'WAITING FOR DELIVERY') {
    return (
      <Tooltip title={status}>
        <IconButton>
          <Delivery sx={{ width: 18, height: 18 }} />
        </IconButton>
      </Tooltip>
    );
  } else if (status === 'READY FOR DELIVERY') {
    return (
      <Tooltip title={status}>
        <IconButton>
          <Ready sx={{ width: 18, height: 18 }} />
        </IconButton>
      </Tooltip>
    );
  } else if (status == 'DRAFT' || status == 'IN QUEUE') {
    return (
      <Tooltip title={status}>
        <IconButton>
          <Chain sx={{ width: 18, height: 18 }} />
        </IconButton>
      </Tooltip>
    );
  } else if (status == 'WAITING FOR APPROVAL') {
    return (
      <Tooltip title={status}>
        <IconButton>
          <Wait sx={{ width: 18, height: 18 }} />
        </IconButton>
      </Tooltip>
    );
  } else if (status == 'HALTED' || status == 'PAUSED') {
    return (
      <Tooltip title={status}>
        <IconButton>
          <Pause sx={{ width: 18, height: 18 }} />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={status}>
        <IconButton>
          <Factory sx={{ width: 18, height: 18 }} />
        </IconButton>
      </Tooltip>
    );
  }
};

export const OrderBatchInfo = () => {
  return [
    {
      field: 'lotNumber',
      headerName: 'LOT#',
      width: 100,

      renderCell: (params) => (
        <Stack direction="row" alignItems="flex-start">
          <Typography>{reformateLotNumber(params.row.lotNumber)}</Typography>
        </Stack>
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: 'processQuantity',
      headerName: 'QTY (KG)',
      width: 105,
      filterable: false,
      sortable: false,
    },
    {
      field: 'delivered',
      headerName: 'DELIVERED',
      width: 110,
      filterable: false,
      sortable: false,
    },
    {
      field: 'batchStatus',
      headerName: 'STATUS',
      width: 120,
      renderCell: (params) => (
        <Stack direction="row" alignItems="flex-start">
          {batchStatusIconGenerate(params.row.batchStatus)}
        </Stack>
      ),
      filterable: false,
      sortable: false,
    },
  ];
};

export const dummyUpdateDelivery = {
  orderId: 4002,
  fcNumber: 4002,
  deliveryInfo: [
    {
      fabricId: 26,
      fabricName: 'Lycra',
      subTotal: 150,
      batchInfo: [
        {
          lotNumber: '69696',
          processQuantity: 500,
          rolls: 5,
          reProcessQty: 0,
          fabricStatus: 'PROCESSING',
          batchStatus: 'IN QUEUE',
          batchDeliverable: 100,
          tempDeliverable: 100,
        },
        {
          lotNumber: '13213',
          processQuantity: 600,
          rolls: 4,
          reProcessQty: 0,
          fabricStatus: 'PROCESSING',
          batchStatus: 'IN QUEUE',
          batchDeliverable: 100,
          tempDeliverable: 50,
        },
      ],
    },
  ],
};

export const AllOrderApprovalListColumns = ({ handleEditModal }) => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 80,
      type: 'date',
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          {/* <Button>
          <VisibilityIcon />
        </Button> */}
          <IconButton
            size="small"
            component={Link}
            to={`${ORDER_DETAILS}/${params.row.fcNumber}/0`}
            state={{ approvalTab: 1 }}>
            <Visibility sx={{ width: 18, height: 18 }} />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleEditModal(params.row)}
            style={{
              paddingLeft: '0px !important',
              marginLeft: '0px !important',
              paddingRight: '0px !important',
              marginRight: '0px !important',
            }}>
            <CheckCircleIcon sx={{ width: 18, height: 18 }}></CheckCircleIcon>
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'fcNumber',
      headerName: 'FC NUMBER',
      width: 130,
      filterable: false,
      sortable: false,
    },
    {
      field: 'partyName',
      headerName: 'PARTY NAME',
      width: 150,
      renderCell: (params) => {
        return (
          <Stack>
            {params?.value?.length > 10 ? (
              <Tooltip title={params?.value}>
                <Stack>
                  <Typography>{params?.value.substr(0, 10)}...</Typography>
                </Stack>
              </Tooltip>
            ) : (
              <Tooltip title={params?.value}>
                <Stack>
                  <Typography>{params?.value}</Typography>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 150,
      renderCell: (params) => {
        const status = params?.value;

        return (
          <Chip
            size="small"
            label={status}
            sx={{
              backgroundColor: setOrderStatusColor(status),
              color: '#ffff',
            }}
          />
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'finsihedStock',
      headerName: 'FINISHED STOCK(KG)',
      width: 180,

      filterable: false,
      sortable: false,
    },

    {
      field: 'totalQuantity',
      headerName: 'TOTAL QUANTITY(KG)',
      width: 180,

      filterable: false,
      sortable: false,
    },

    {
      field: 'PROGRESS',
      headerName: 'PROGRESS',
      width: 180,

      renderCell: (params) => {
        let progressValue = 0;
        progressValue =
          (params?.row?.finsihedStock / params?.row?.totalQuantity) * 100;

        return (
          <Box sx={{ width: '100%' }}>
            <Typography>{progressValue.toFixed(2)}%</Typography>
            <LinearProgress variant="determinate" value={progressValue} />
          </Box>
        );
      },
    },
    {
      field: 'salesPerson',
      headerName: 'SALES PERSON',
      width: 180,

      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryDate',
      headerName: 'DELIVERY DATE',
      width: 170,
      type: 'date',
      renderCell: (params) => {
        if (params.value) {
          return (
            params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`
          );
        } else {
          return 'Invalid Date';
        }
      },
    },
    {
      field: 'createdAt',
      headerName: 'ISSUE DATE',
      width: 170,
      type: 'date',
      renderCell: (params) =>
        params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`,
    },
  ];
};

export const DeliveryApprovalListColumns = ({ handleEditModal }) => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 80,
      type: 'date',
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          {/* <Button>
          <VisibilityIcon />
        </Button> */}
          <IconButton
            size="small"
            component={Link}
            to={`${UPDATE_DELIVERY}/${params.row.uid}`}>
            <Visibility sx={{ width: 18, height: 18 }} />
          </IconButton>
          <IconButton
            size="small"
            // disabled={params.row.status != 'IN QUEUE' ? true : false}
            onClick={() => handleEditModal(params.row)}
            style={{
              paddingLeft: '0px !important',
              marginLeft: '0px !important',
              paddingRight: '0px !important',
              marginRight: '0px !important',
            }}>
            <CheckCircleIcon sx={{ width: 18, height: 18 }}></CheckCircleIcon>
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'uid',
      headerName: 'DELIVERY #',
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: 'partyName',
      headerName: 'PARTY NAME',
      width: 170,
      filterable: false,
      sortable: false,
    },
    {
      field: 'totalDeliveryQuantity',
      headerName: 'QUANTITY(KG)',
      width: 120,
      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryContatctNumber',
      headerName: 'CONTACT NUMBER',
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryContatct',
      headerName: 'CONTACT PERSON',
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryAddress',
      headerName: 'DELIVERY ADDRESS',
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryDate',
      headerName: 'DELIVERY DATE',
      width: 170,
      type: 'date',
      renderCell: (params) => {
        if (params.value) {
          return (
            params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`
          );
        } else {
          return 'Invalid Date';
        }
      },
    },
  ];
};

export const AllAccountDeliveryListColumns = (accountInformation) => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 80,
      type: 'date',
      renderCell: (params) => (
        <Stack direction="row" alignItems="flex-start">
          <Button
            size="small"
            component={Link}
            to={`${ACCOUNT_DELIVERY_DETAILS}/${params.row.uid}`}
            state={{
              delivery: params.row,
              accountInformation: accountInformation,
            }}>
            <Edit />
          </Button>
        </Stack>
      ),
    },
    {
      field: 'uid',
      headerName: 'UID#',
      width: 100,
      filterable: false,
      sortable: false,
    },
    {
      field: 'partyName',
      headerName: 'PARTY NAME',
      width: 170,
      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryContatct',
      headerName: 'CONTACT PERSON',
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryAddress',
      headerName: 'DELIVERY ADDRESS',
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: 'totalDeliveryQuantity',
      headerName: 'QUANTITY(KG)',
      width: 120,
      filterable: false,
      sortable: false,
    },
    {
      field: 'totalNetQuantity',
      headerName: 'NET QUANTITY(KG)',
      width: 120,
      filterable: false,
      sortable: false,
    },
    {
      field: 'billStatus',
      headerName: 'BILL STATUS',
      width: 150,
      renderCell: (params) => {
        const status = params?.value;

        return (
          <Chip
            size="small"
            label={status}
            sx={{
              backgroundColor: setOrderDeliveryBillStatusColor(status),
              color: '#ffff',
            }}
          />
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'deliveryDate',
      headerName: 'DELIVERY DATE',
      width: 170,
      type: 'date',
      renderCell: (params) => {
        if (params.value) {
          return (
            params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`
          );
        } else {
          return 'Invalid Date';
        }
      },
    },
  ];
};
