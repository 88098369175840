import { PlagiarismTwoTone as Plagiarism } from '@mui/icons-material';
import { Alert, Stack, Typography } from '@mui/material';
import React from 'react';
import RenderFormValues from './RenderFormValues';
import TaskInformations from './TaskInformations';

const TaskDetail = ({ task, batch, socket }) => {
  if (task) {
    return (
      <Stack spacing={1} height="100%" overflow="auto">
        <Stack boxSizing="border-box" px={2} py={2} flex={0.8}>
          <TaskInformations task={task} socket={socket} />
        </Stack>

        <Stack flex={1} px={2} py={2} spacing={2}>
          {task.status === 'DONE' ? (
            task?.formFields?.map((field) => (
              <Stack key={field?.label}>
                <RenderFormValues field={field} batch={batch} task={task} />
              </Stack>
            ))
          ) : (
            <Alert severity="warning">
              <Typography>
                This task has not completed yet. More details will be shown once
                the batch is complete.
              </Typography>
            </Alert>
          )}
        </Stack>
      </Stack>
    );
  } else {
    return (
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        spacing={2}>
        <Plagiarism sx={{ width: 100, height: 100 }} />
        <Typography variant="h5" fontWeight={700}>
          Nothing To Show
        </Typography>
        <Typography textAlign="center">
          Select a task from the timeline see details
        </Typography>
      </Stack>
    );
  }
};

export default TaskDetail;
