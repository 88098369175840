import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Rating,
  Stack,
  TextField,
  Typography,
  Tooltip as MuiTooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  factoryUtilityCharts,
  fetchUtilityDetails,
  updateUtility,
} from '../../api/factory-utility';
import {
  utilityScaleColorMapper,
  utilityScaleNumberToStringMapper,
} from '../../utils/factory-utility';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Button from '../../widgets/Button';
import {
  ArrowBackIosTwoTone,
  Brightness1,
  Brightness1TwoTone,
  DownloadTwoTone,
  HistoryTwoTone,
  UpdateTwoTone,
} from '@mui/icons-material';
import { SETTINGS } from '../../constants/routeUrls';
import { successNoitif, warningNoitif } from '../../utils/notification';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

const options = {
  responsive: true,
  maintainAspectRation: false,
  bezierCurve: true,
  scales: {
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Utility Chart',
    },
  },
};

const UtilityDetails = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [utility, setUtility] = useState(null);
  const [logs, setLogs] = useState([]);
  const [chartConfig, setChartConfig] = useState(null);
  const [utilityLevelValue, setUtilityLevelValue] = useState();
  const [showUtilityUpdateModal, setShowUtilityUpdateModal] = useState(false);
  const [utilityAdditionalInfo, setUtilityAdditionalInfo] = useState('');

  const fetchData = async () => {
    const data = await fetchUtilityDetails(uid);
    const chartData = await factoryUtilityCharts(uid);

    setChartConfig(chartData);
    setUtility(data.utility);
    setUtilityLevelValue(data.utility.scale);
    setLogs(data.logs);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, [uid]);

  useEffect(() => {
    const interval = setInterval(async () => await fetchData(), 30 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const handleUtilityUpdate = async () => {
    if (utilityLevelValue === utility.scale) {
      warningNoitif('Utiity level not changed');
      return;
    }
    const patchData = {
      scale: utilityLevelValue,
      value: utilityAdditionalInfo,
    };

    const response = await updateUtility({ uid, formData: patchData });

    if (response) {
      await fetchData();
      setUtility((util) => ({
        ...util,
        scale: patchData.scale,
        value: patchData.value,
      }));
      successNoitif('Utility updated');
    }

    setShowUtilityUpdateModal(false);
  };
  return (
    <Stack width="100%" height="100%" px={2} py={3}>
      <Stack
        width="100%"
        direction="row"
        justifyContent="flex-start"
        mt={1}
        mb={2}>
        <Button
          onClick={() => navigate(SETTINGS + `/${2}`)}
          variant="contained"
          startIcon={<ArrowBackIosTwoTone />}>
          Back
        </Button>
      </Stack>
      <Box mb={2}>
        <Divider></Divider>
      </Box>
      {utility ? (
        <>
          <Stack direction="row" spacing={2}>
            <Dialog
              open={showUtilityUpdateModal}
              onClose={() => setShowUtilityUpdateModal(false)}>
              <DialogContent>
                <Stack my={2} spacing={2}>
                  <Typography fontSize={22} fontWeight={700}>
                    Update Utility
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Typography fontWeight={800}>Utility Level</Typography>
                    <Chip
                      label={utilityScaleNumberToStringMapper(
                        utilityLevelValue,
                      )}
                      color={utilityScaleColorMapper(utilityLevelValue)}
                    />
                  </Stack>
                  <Rating
                    emptyIcon={<Brightness1TwoTone />}
                    icon={<Brightness1 />}
                    max={10}
                    defaultValue={utilityLevelValue}
                    value={utilityLevelValue}
                    onChange={(event, val) => setUtilityLevelValue(val)}
                  />
                  {utility?.shouldCaptureValue && (
                    <TextField
                      size="small"
                      label="Additional information (optional)"
                      value={utilityAdditionalInfo}
                      onChange={(event) =>
                        setUtilityAdditionalInfo(event.target.value)
                      }
                    />
                  )}
                  <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button
                      color="error"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setUtilityLevelValue(utility.scale);
                        setShowUtilityUpdateModal(false);
                      }}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleUtilityUpdate}>
                      Save Changes
                    </Button>
                  </Stack>
                </Stack>
              </DialogContent>
            </Dialog>
            <Stack
              minWidth={350}
              minHeight={300}
              px={2}
              py={2}
              borderRadius={2}
              border="2px solid rgba(0,0,0,0.2)"
              spacing={1}
              justifyContent="space-between">
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6" fontWeight={700}>
                  {utility?.name}
                </Typography>
                <Chip
                  label={utilityScaleNumberToStringMapper(utility?.scale)}
                  color={utilityScaleColorMapper(utility?.scale)}
                />
              </Stack>
              <Stack flexGrow={1} direction="row" spacing={1}>
                <MuiTooltip title="Download utility trend report">
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ height: 'max-content' }}
                      endIcon={<DownloadTwoTone />}
                      disabled>
                      Download
                    </Button>
                  </Box>
                </MuiTooltip>
                <MuiTooltip title="Update information">
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ height: 'max-content' }}
                      endIcon={<UpdateTwoTone />}
                      onClick={() => setShowUtilityUpdateModal(true)}>
                      Update
                    </Button>
                  </Box>
                </MuiTooltip>
              </Stack>
              <Stack spacing={1} direction="row">
                <Chip
                  label={`Updated By: ${utility?.updatedByInfo?.name}`}
                  color="info"
                  size="small"
                  sx={{ width: 'max-content' }}
                />
                <Typography fontSize={11} fontWeight={300}>
                  Updated At:{' '}
                  {dayjs(utility?.updatedAt).format('hh:mm A - DD, MMM')}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              width="100%"
              px={2}
              py={2}
              borderRadius={2}
              border="2px solid rgba(0,0,0,0.2)"
              spacing={2}
              alignItems="center"
              justifyContent="center">
              {chartConfig ? (
                <Line options={options} data={chartConfig} height={60} />
              ) : (
                <>
                  <Typography variant="h5" fontWeight={700}>
                    Something Went Wrong
                  </Typography>
                  <Typography>
                    Sorry something went wrong. The chart could not be loaded at
                    this moment
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
          <Stack spacing={2} mt={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <HistoryTwoTone />
              <Typography fontSize={18} fontWeight={700}>
                History ({dayjs().format('DD, MMM ')})
              </Typography>
            </Stack>
            <Stack maxHeight={580} overflow="auto" spacing={2}>
              {logs?.map((item) => (
                <Stack
                  key={item}
                  direction="row"
                  px={1}
                  py={1}
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius={1}
                  bgcolor="#f2f2f2">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography fontSize={18} fontWeight={700}>
                      {item.title}
                    </Typography>
                    <Chip
                      label={utilityScaleNumberToStringMapper(item.scale)}
                      color={utilityScaleColorMapper(item.scale)}
                      size="small"
                    />
                  </Stack>
                  <Typography fontSize={14} fontWeight={300}>
                    {item.message}
                  </Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography fontSize={14} fontWeight={800}>
                      {item.value || 0}
                    </Typography>
                    <Chip
                      label={`Updated By: ${item.updatedByInfo.name}`}
                      color="info"
                      size="small"
                    />
                    <Typography fontSize={12} fontWeight={400}>
                      Last Updated:{' '}
                      {dayjs(item.updatedAt).format('hh:mm A - DD, MMM')}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack
          width="100%"
          height="50vh"
          alignItems="center"
          justifyContent="center"
          my="auto"
          spacing={2}>
          <Typography variant="h4" fontWeight={700}>
            Not Found
          </Typography>
          <Typography variant="body1" fontWeight={400}>
            Sorry no information could be loaded.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default UtilityDetails;
