import * as yup from 'yup';

export const CreateChemicalSchema = yup.object().shape({
  name: yup.string().required('Must provide chemical name'),
  unit: yup.string().required('Must provide chemical unit'),
  chemicalType: yup
    .string()
    .oneOf(['Chemical', 'Dye'])
    .required('Must provide chemical chemicalType'),
  dyeGroupId: yup.string('Must select dye group').when('chemicalType', {
    is: 'Dye',
    then: yup.string().required('Must select dye group'),
  }),
  lots: yup
    .array()
    .of(
      yup.object().shape({
        brand: yup.string(),
        quantity: yup
          .number()
          .positive('Must be greater than 0')
          .typeError('Must be greater than 0')
          .required('Must provide total stock quantity'),
        cost: yup
          .number()
          .positive('Must be greater than 0')
          .typeError('Must be greater than 0')
          .required('Must provide total cost'),
      }),
    )
    .min(1, 'Must add atleast one lot')
    .required('Must add atleast one lot'),
});

export const AddStockSchema = yup.object().shape({
  lots: yup
    .array()
    .of(
      yup.object().shape({
        brand: yup.string(),
        quantity: yup
          .number()
          .positive('Must be greater than 0')
          .typeError('Must be greater than 0')
          .required('Must provide total stock quantity'),
        cost: yup
          .number()
          .positive('Must be greater than 0')
          .typeError('Must be greater than 0')
          .required('Must provide total cost'),
      }),
    )
    .min(1, 'Must add atleast one lot')
    .required('Must add atleast one lot'),
});

export const RecipeCreateSchema = yup.object().shape({
  recipe: yup
    .array()
    .of(
      yup.object().shape({
        chemical: yup
          .mixed()
          .transform((v) => (!v ? undefined : v))
          .required('Must select a chemical'),
        quantity: yup
          .number()
          .typeError('Must be greater than 0')
          .min(0)
          .required('Must provide quantity'),
      }),
    )
    .min(1, 'Must add atleast one chemical')
    .required('must add atleast one chemical'),
});
