import React, { useEffect } from 'react';
import {
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CloseTwoTone as Close } from '@mui/icons-material';
import Button from '../../../widgets/Button';
import { addBatchRemarks } from '../../../api/batch';
import { successNoitif } from '../../../utils/notification';

const BatchRemarksDrawer = ({ show, onClose, batch, socket }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      dyeingRemark: '',
      finishingRemark: '',
      qcRemark: '',
      batchRejectionRemark: '',
      deliveryRemark: '',
    },
  });

  const preloadData = () => {
    setValue('dyeingRemark', batch.dyeingRemark, {
      shouldDirty: false,
      shouldTouch: false,
    });
    setValue('finishingRemark', batch.finishingRemark, {
      shouldDirty: false,
      shouldTouch: false,
    });
    setValue('qcRemark', batch.qcRemark, {
      shouldDirty: false,
      shouldTouch: false,
    });
    setValue('deliveryRemark', batch.deliveryRemark, {
      shouldDirty: false,
      shouldTouch: false,
    });
    setValue('batchRejectionRemark', batch.batchRejectionRemark, {
      shouldDirty: false,
      shouldTouch: false,
    });
  };

  useEffect(() => preloadData(), [batch]);

  const handleSave = async (data) => {
    const response = await addBatchRemarks(batch.lotNumber, data);

    if (response) {
      socket.emit('batch details', { lotNumber: batch.lotNumber });
      successNoitif(response);
    }
  };

  return (
    <Drawer onClose={onClose} open={show} anchor="right" sx={{ zIndex: 90000 }}>
      <Stack width={320} height="100vh" spacing={1} px={2} py={3}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Typography variant="h5" fontWeight={700}>
            Remarks
          </Typography>
          <IconButton onClick={onClose} color="error">
            <Close />
          </IconButton>
        </Stack>
        <TextField
          {...register('dyeingRemark')}
          label="Dyeing Remarks"
          multiline
          minRows={3}
          maxRows={5}
        />
        <TextField
          {...register('finishingRemark')}
          label="Finishing Remarks"
          multiline
          minRows={3}
          maxRows={5}
        />
        <TextField
          {...register('qcRemark')}
          label="QC Remarks"
          multiline
          minRows={3}
          maxRows={5}
        />
        <TextField
          {...register('deliveryRemark')}
          label="Delivery Remarks"
          multiline
          minRows={3}
          maxRows={5}
        />
        <TextField
          {...register('batchRejectionRemark')}
          label="Batch Rejection Remarks"
          multiline
          minRows={3}
          maxRows={5}
        />
        <Button
          disabled={!isDirty}
          fullWidth
          variant="contained"
          onClick={handleSubmit(handleSave)}>
          Save Changes
        </Button>
      </Stack>
    </Drawer>
  );
};

export default BatchRemarksDrawer;
