import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
  batchPhaseColor,
  getBatchFinishType,
  getBatchProcessType,
  getBatchStatus,
} from '../../utils/batch';

import {
  VisibilityTwoTone as Visibility,
  EditTwoTone as Edit,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ACCOUNT_BATCH_DETAILS, BATCH_DETAILS, BATCH_EDIT } from '../routeUrls';

import { formatTime } from '../../utils/time';
import dayjs from 'dayjs';
import { setOrderBatchBackgroundColor } from '../../utils/account';

const checkStatus = (status) => {
  if (status === 'DRAFT') {
    return false;
  } else if (status === 'WAITING FOR APPROVAL') {
    return false;
  }
  return true;
};

export const AllBatchListColumns = [
  {
    field: 'actions',
    headerName: '#',
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            component={Link}
            to={`${BATCH_DETAILS}/${params.row.lotNumber}`}>
            <Visibility sx={{ width: 18, height: 18 }} />
          </IconButton>
          <IconButton
            size="small"
            // 181238
            disabled={
              checkStatus(params.row.status.toUpperCase()) ? true : false
            }
            component={Link}
            to={`${BATCH_EDIT}/${params.row.lotNumber}`}>
            <Edit sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Stack>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status'.toUpperCase(),
    type: 'singleSelect',
    valueOptions: [
      'IS PAUSED',
      'ON HOLD',
      'IN QUEUE',
      'STARTED',
      'READY FOR DELIVERY',
      'DRAFT',
      'WAITING FOR APPROVAL',
      'DELIVERED',
      'WAITING FOR DELIVERY',
      'PARTIAL DELIVERED',
      'REJECTED',
    ],
    width: 150,
    renderCell: (params) => {
      const label = getBatchStatus(params?.value);
      if (!label) {
        return '';
      }
      return <Chip size="small" label={label.label} color={label.color} />;
    },
  },
  {
    field: 'lotNumber',
    headerName: 'Lot Number'.toUpperCase(),
    width: 100,
    renderCell: (params) => `${params.value?.split('-')[1]}`,
  },

  {
    field: 'taskInfo',
    headerName: 'Phase'.toUpperCase(),
    width: 150,
    renderCell: (params) => (
      <Chip
        label={`${
          params?.value?.batchPhase ? params?.value?.batchPhase : 'N/A'
        }`}
        color={batchPhaseColor(params?.value?.batchPhase)}
        size="small"
        sx={{ mr: 0.5 }}
      />
    ),

    filterable: false,
    sortable: false,
  },
  {
    field: 'orderInfo',
    headerName: 'Orders'.toUpperCase(),
    minWidth: 200,
    renderCell: (params) =>
      params?.value?.map((order) => (
        <Chip
          key={order.fcNumber}
          label={`${order.fcNumber}`}
          size="small"
          sx={{ mr: 0.5 }}
        />
      )),
    filterable: false,
    sortable: false,
  },
  {
    field: 'totalFabricWeight',
    headerName: 'WEIGHT (KG)',
    width: 120,
  },
  {
    field: 'fabricInfo',
    headerName: 'Color (%)'.toUpperCase(),
    flex: 1,
    minWidth: 200,
    renderCell: (params) =>
      params?.value?.map((fabric, index) => {
        if (index !== params?.value?.length - 1) {
          return (
            <Tooltip title={` ${fabric.colour} (${fabric.shade} %),{' '}`}>
              <Typography
                key={fabric.id}
                fontWeight={400}
                sx={{ mr: 1, fontSize: '0.8rem' }}>
                {fabric.colour} ({fabric.shade} %),{' '}
              </Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={` ${fabric.colour} (${fabric.shade} %),{' '}`}>
              <Typography
                key={fabric.id}
                fontWeight={400}
                sx={{ mr: 1, fontSize: '0.8rem' }}>
                {fabric.colour} ({fabric.shade} %){' '}
              </Typography>
            </Tooltip>
          );
        }
      }),
    filterable: false,
    sortable: false,
  },

  {
    field: 'machineInfo',
    headerName: 'Machine'.toUpperCase(),
    width: 150,
    renderCell: (params) => `${params?.value?.name}`,
    filterable: false,
    sortable: false,
  },

  {
    field: 'loadingTime',
    headerName: 'Loading Time'.toUpperCase(),
    width: 170,
    type: 'date',
    renderCell: (params) => params.value && `${formatTime(params.value)}`,
  },
  {
    field: 'actualStartTime',
    headerName: 'Loaded At'.toUpperCase(),
    width: 170,
    type: 'date',
    renderCell: (params) => params.value && `${formatTime(params.value)}`,
  },
  {
    field: 'estEndTime',
    headerName: 'Est End Time'.toUpperCase(),
    width: 170,
    type: 'date',
    renderCell: (params) => params.value && `${formatTime(params.value)}`,
  },
  {
    field: 'actualEndTime',
    headerName: 'Ended At'.toUpperCase(),
    width: 170,
    type: 'date',
    renderCell: (params) => params.value && `${formatTime(params.value)}`,
  },
  {
    field: 'finishingType',
    headerName: 'Finishing'.toUpperCase(),
    width: 150,
    type: 'singleSelect',
    valueOptions: ['TUBE', 'OPEN'],
    renderCell: (params) => (
      <Typography fontWeight={400}>
        {getBatchFinishType(params.value)}
      </Typography>
    ),
  },
  {
    field: 'processType',
    headerName: 'Process'.toUpperCase(),
    type: 'singleSelect',
    valueOptions: ['BULK', 'SAMPLE', 'RE PROCESS'],
    width: 150,
    renderCell: (params) => (
      <Typography fontWeight={400}>
        {getBatchProcessType(params.value)}
      </Typography>
    ),
  },
  {
    field: 'shiftInfo',
    headerName: 'Shift'.toUpperCase(),
    width: 100,
    renderCell: (params) => params?.value?.name,
    filterable: false,
    sortable: false,
  },
  {
    field: 'batchType',
    headerName: 'Batch Type'.toUpperCase(),
    type: 'singleSelect',
    valueOptions: ['Batch To Batch', 'New Shade'],
    width: 150,
  },
];

export const OrdersAssignedToBatch = [
  {
    field: 'fcNumber',
    headerName: 'FC Number'.toUpperCase(),
    width: 100,
  },
  {
    field: 'partyName',
    headerName: 'Party Name',
    flex: 1,
  },
];

export const FabricsAssignedToBatch = [
  {
    field: 'fabricType',
    headerName: 'Fabric Type'.toUpperCase(),
    width: 120,
  },
  {
    field: 'fcNumber',
    headerName: 'FC #',
    width: 80,
    renderCell: (params) => (
      <Tooltip title={params.row.partyName}>
        <Typography>{params.value}</Typography>
      </Tooltip>
    ),
  },
  {
    field: 'colour',
    headerName: 'Color'.toUpperCase(),
    width: 140,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <Typography>{params.value?.substring(0, 15)}</Typography>
      </Tooltip>
    ),
  },
  {
    field: 'shadeGroup',
    headerName: 'Shade Group'.toUpperCase(),
    width: 150,
    renderCell: (params) => `${params.value} (${params.row.shade}%)`,
  },
  {
    field: 'quantity',
    headerName: 'QTY (Kg)'.toUpperCase(),
    width: 80,
    renderCell: (params) =>
      `${
        params.row.reProcessQty > 0
          ? params.row.reProcessQty
          : params.row.usedQty
      }`,
  },
  {
    field: 'dia',
    headerName: 'DIA'.toUpperCase(),
    width: 90,
  },
  {
    field: 'gsm',
    headerName: 'GSM'.toUpperCase(),
    width: 90,
  },
];

export const BatchApprovalListColumns = () => {
  return [
    {
      field: 'ACTIONS',
      headerName: '',
      width: 60,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}>
          <IconButton
            title="View & Approve"
            size="small"
            component={Link}
            to={`${BATCH_DETAILS}/${params.row.lotNumber}`}>
            <Visibility sx={{ width: 18, height: 18 }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'lotNumber',
      headerName: 'Lot Number',
      width: 100,
      renderCell: (params) => `${params.value?.split('-')[1]}`,
    },
    {
      field: 'orderInfo',
      headerName: 'Orders',
      flex: 1,
      minWidth: 300,
      renderCell: (params) =>
        params?.value?.map((order) => (
          <Chip
            key={order.fcNumber}
            label={`${order.fcNumber} ${order.partyName}`}
            size="small"
          />
        )),
      filterable: false,
      sortable: false,
    },
    {
      field: 'processType',
      headerName: 'Process',
      type: 'singleSelect',
      valueOptions: ['BULK', 'SAMPLE', 'RE PROCESS'],
      width: 150,
      renderCell: (params) => (
        <Typography fontWeight={400}>
          {getBatchProcessType(params.value)}
        </Typography>
      ),
    },
    {
      field: 'shiftInfo',
      headerName: 'Shift',
      width: 100,
      renderCell: (params) => params?.value?.name,
      filterable: false,
      sortable: false,
    },
    {
      field: 'machineInfo',
      headerName: 'Machine',
      width: 200,
      renderCell: (params) => `${params?.value?.name}`,
      filterable: false,
      sortable: false,
    },
    {
      field: 'totalFabricWeight',
      headerName: 'Weight (Kg)',
      width: 200,
    },
    {
      field: 'fabricInfo',
      headerName: 'Color (%)',
      flex: 1,
      minWidth: 300,
      renderCell: (params) =>
        params?.value?.map((fabric, index) => {
          if (index !== params?.value?.length - 1) {
            return (
              <Typography
                key={fabric.id}
                fontWeight={400}
                sx={{ mr: 1, fontSize: '0.8rem' }}>
                {fabric.colour} ({fabric.shade} %),{' '}
              </Typography>
            );
          } else {
            return (
              <Typography
                key={fabric.id}
                fontWeight={400}
                sx={{ mr: 1, fontSize: '0.8rem' }}>
                {fabric.colour} ({fabric.shade} %){' '}
              </Typography>
            );
          }
        }),
      filterable: false,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: [
        'IS PAUSED',
        'ON HOLD',
        'IN QUEUE',
        'STARTED',
        'READY FOR DELIVERY',
        'DRAFT',
        'WAITING FOR APPROVAL',
        'DELIVERED',
        'WAITING FOR DELIVERY',
        'PARTIAL DELIVERED',
        'REJECTED',
      ],
      width: 120,
      renderCell: (params) => {
        const label = getBatchStatus(params?.value);
        if (!label) {
          return '';
        }
        return (
          <Chip
            size="small"
            label={label.label}
            sx={{
              color: '#ffff',
              background: setOrderBatchBackgroundColor(params?.value),
            }}
          />
        );
      },
    },
    {
      field: 'batchType',
      headerName: 'Batch Type',
      type: 'singleSelect',
      valueOptions: ['Batch To Batch', 'New Shade'],
      width: 150,
    },

    {
      field: 'finishingType',
      headerName: 'Finishing',
      width: 150,
      type: 'singleSelect',
      valueOptions: ['TUBE', 'OPEN'],
      renderCell: (params) => (
        <Typography fontWeight={400}>
          {getBatchFinishType(params.value)}
        </Typography>
      ),
    },
    {
      field: 'loadingTime',
      headerName: 'Loading Time',
      width: 170,
      type: 'date',
      renderCell: (params) => params.value && `${formatTime(params.value)}`,
    },
    {
      field: 'actualStartTime',
      headerName: 'Loaded At',
      width: 170,
      type: 'date',
      renderCell: (params) => params.value && `${formatTime(params.value)}`,
    },
    {
      field: 'estEndTime',
      headerName: 'Est End Time',
      width: 170,
      type: 'date',
      renderCell: (params) => params.value && `${formatTime(params.value)}`,
    },
    {
      field: 'actualEndTime',
      headerName: 'Ended At',
      width: 170,
      type: 'date',
      renderCell: (params) => params.value && `${formatTime(params.value)}`,
    },
  ];
};

const generateBatchStatusColor = (status) => {
  switch (status) {
    case 'IS PAUSED':
      return '#FFC107';
    case 'ON HOLD':
      return '#FFC107';
    case 'IN QUEUE':
      return '#FFC107';
    case 'STARTED':
      return '#FFC107';
    case 'READY FOR DELIVERY':
      return '#FFC107';
    case 'DRAFT':
      return '#FFC107';
    case 'WAITING FOR APPROVAL':
      return '#FFC107';
    case 'DELIVERED':
      return '#4CAF50';
    case 'WAITING FOR DELIVERY':
      return '#2196f3';
    case 'PARTIAL DELIVERED':
      return '#FFC107';
    case 'REJECTED':
      return '#F44336';
    default:
      return '#FFC107';
  }
};

const calcaulateBatchAmount = (fabricList) => {
  let amount = 0;
  fabricList.forEach((fabric) => {
    amount += fabric.batchFabricAmount * fabric.rate;
  });
  return amount;
};

const calcaulateTaskCostAmount = (taskList) => {
  let amount = 0;
  taskList.forEach((task) => {
    amount += task.totalCost;
  });
  return amount;
};

export const AllAccountBatchlistColumns = [
  {
    field: 'ACTIONS',
    headerName: '',
    width: 70,
    type: 'date',
    renderCell: (params) => (
      <IconButton
        size="small"
        component={Link}
        to={`${ACCOUNT_BATCH_DETAILS}/${params.row.lotNumber}`}>
        <Visibility sx={{ width: 18, height: 18 }} />
      </IconButton>
    ),
  },
  {
    field: 'lotNumber',
    headerName: 'LOT#',
    width: 130,
    filterable: false,
    sortable: false,
  },
  {
    field: 'totalFabricWeight',
    headerName: 'WEIGHT (Kg)',
    width: 130,
  },
  {
    field: 'status',
    headerName: 'STATUS',
    width: 170,
    renderCell: (params) => {
      const status = params?.value;

      return (
        <Chip
          size="small"
          label={status}
          sx={{
            color: 'white',
            backgroundColor: generateBatchStatusColor(status),
          }}
        />
      );
    },
    filterable: false,
    sortable: false,
  },
  {
    field: 'deliveryDate',
    headerName: 'DELIVERY DATE',
    width: 170,
    type: 'date',
    renderCell: (params) => {
      if (params.value) {
        return params.value && `${dayjs(params.value).format('DD/MMM/YYYY ')}`;
      } else {
        return 'Invalid Date';
      }
    },
  },
  {
    field: 'AMOUNT',
    headerName: 'DYEING COST(BDT)',
    width: 160,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>
          {calcaulateBatchAmount(params.row.fabricList).toFixed(2)}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'COST',
    headerName: 'CHEMICAL COST(BDT)',
    width: 180,
    filterable: false,
    sortable: false,
    renderCell: (params) => (
      <Stack direction="row" alignItems="flex-start">
        <Typography>{calcaulateTaskCostAmount(params.row.taskInfo)}</Typography>
      </Stack>
    ),
  },
];
