import { Pageview } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import OrderBatchDetails from './OrderBatchDetails';

const OrderAccountBatchTab = ({ batchList }) => {
  const checkBatchStatus = (batch) => {
    switch (batch?.batchInfo?.status) {
      case 'WAITING FOR DELIVERY':
        return true;
      case 'READY FOR DELIVERY':
        return true;
      case 'DELIVERED':
        return true;
      case 'PARTIAL DELIVERED':
        return true;
      default:
        return false;
    }
  };
  return (
    <Stack>
      <Stack mt={3}>
        {batchList?.length < 1 ? (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <Stack
              direction="Column"
              justifyContent="center"
              alignItems="center">
              <Pageview sx={{ width: 120, height: 120 }} />
              <Typography fontSize={18} fontWeight={800}>
                No Batches
              </Typography>
              <Typography maxWidth={450}>No batches created yet!</Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems="left" justifyContent="flex-start" width="100%">
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
              Batch List:
            </Typography>
            <Stack alignItems="left" justifyContent="flex-start" width="100%">
              <Stack
                flexWrap="wrap"
                direction="row"
                alignItems="center"
                justifyContent="flex-start">
                {batchList.map((batch) =>
                  checkBatchStatus(batch) ? (
                    <OrderBatchDetails key={batch?.id} batch={batch} />
                  ) : (
                    <></>
                  ),
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default OrderAccountBatchTab;
