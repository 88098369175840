import { useState } from 'react';
import { successNoitif } from '../../utils/notification';
import {
  Stack,
  Dialog,
  DialogTitle,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Button from '../../widgets/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { MachineCreationValidator } from '../../validators/machine';
import { createNewMachine } from '../../api/machine';
export const AddNewMachine = ({
  show,
  toggleModal,
  update,
  isAdded,
  setIsAdded,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [machineData, setMachineData] = useState({});
  const resolver = yupResolver(MachineCreationValidator);
  //handle form submission
  const handelMachineFormSubmission = (data) => {
    setMachineData(data);
    setShowConfirmation(true);
    toggleModal(false);
  };

  //initail values of form
  const {
    register,
    formState: { errors },

    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      capacity: '',
      maxCapacity: '',
      minCapacity: '',
      machineType: '',
    },
    resolver,
  });

  //reset the values on close of add machien form
  const onClose = () => {
    handleCreationFormClose();
  };

  //colse confrimation dialog
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const handleCreationFormClose = () => {
    reset({
      name: '',
      capacity: '',
      maxCapacity: '',
      minCapacity: '',
      machineType: '',
    });
    toggleModal(false);
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  //handel confrimationDesision
  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    createMachineFormSubmit();
    reset(onClose());
  };

  //async calling api
  const createMachineFormSubmit = async () => {
    const response = await createNewMachine(machineData);

    if (response) {
      successNoitif('Machine has been Added');
      update((machines) => [response, ...machines]);
      setIsAdded(!isAdded);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">Add New Machine</DialogTitle>
        <form>
          <Stack
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 3,
              paddingBottom: 3,
            }}>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Name"
                required
                placeholder="Machine Name"
                {...register('name')}
                error={errors?.name}
                helperText={errors?.name?.message}
              />
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Capacity"
                required
                placeholder="Machine Capacity"
                {...register('capacity')}
                error={errors?.capacity}
                helperText={errors?.capacity?.message}
              />
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Max Capacity"
                required
                placeholder="Machine Max Capacity"
                {...register('maxCapacity')}
                error={errors?.maxCapacity}
                helperText={errors?.maxCapacity?.message}
              />
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Minimum Capacity"
                required
                placeholder="Machine Minimum Capacity"
                {...register('minCapacity')}
                error={errors?.minCapacity}
                helperText={errors?.minCapacity?.message}
              />
            </Stack>

            {/* machine type */}
            <Stack spacing={2} sx={{ mt: 2 }}>
              <FormControl fullWidth error={errors?.machineType}>
                <InputLabel id="type123">Machine Type</InputLabel>
                <Select
                  labelId="type123"
                  id="demo-simple-select"
                  label="Machine Type"
                  {...register('machineType')}
                  placeholder="Select Machine Type">
                  <MenuItem value="DYEING">Dyeing Machine</MenuItem>
                  <MenuItem value="FINISHING">Finishing Machine</MenuItem>
                </Select>
                {errors?.machineType && (
                  <FormHelperText>
                    {errors?.machineType?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
          </Stack>
        </form>

        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button
            variant="outlined"
            sx={{ borderColor: 'red' }}
            color="error"
            onClick={handleCreationFormClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(handelMachineFormSubmission)}>
            Save
          </Button>
        </Stack>
      </Dialog>
      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to add this machine?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
                toggleModal(true);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => handelConfrimationDecision()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
    </>
  );
};
