import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import colors from '../../constants/colors';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.logInfo = null;
    this.error = null;
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, logInfo: info, error: error });
    // You can also log the error to an error reporting service
    console.log({ error, info });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack
          width="100%"
          height="100vh"
          bgcolor={colors.error}
          color="#171820"
          alignItems="center"
          justifyContent="center"
          spacing={2}>
          <Typography fontWeight={700} fontSize={36}>
            Something went wrong. Please refresh the page.
          </Typography>
          <Box>
            <Divider orientation="horizontal">Or</Divider>
          </Box>
          <Typography fontWeight={800} fontSize={14}>
            Contact the developer at Manush Technologies LTD.{' '}
            <b>abir@manush.tech</b>
          </Typography>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
