import Request from './request';

const axiosV2 = new Request(2);

export const createNewClinet = async (data) => {
  const response = await axiosV2.Post('/clients', {
    clientName: data.name,
    shortCode: data.shortCode,
    status: data.status,
    contactPerson: data.contactPerson,
    contactNumber: data.contactNumber,
  });

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const createQuotation = async (data) => {
  const response = await axiosV2.Post('/clients/quotation', data);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const updateQuotation = async (data) => {
  const response = await axiosV2.Patch(
    `/clients/quotation/single/${data.quotationUid}`,
    data,
  );

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const fetchAllClients = async ({
  searchKey,
  status,
  page,
  pageSize = 10,
  pageless,
}) => {
  const response = await axiosV2.Get('/clients', {
    searchKey,
    status,
    page,
    pageSize,
    pageless,
  });
  if (response) {
    return { clients: response.clients, total: response.totalEntries };
  } else {
    return { clients: [], total: 0 };
  }
};

export const getClient = async (uid) => {
  const response = await axiosV2.Get(`/clients/${uid}`);
  if (response) {
    return { client: response.client };
  } else {
    return { client: {} };
  }
};
export const fetchClientOrder = async ({
  uid,
  fcNumber,
  status,
  page,
  pageSize,
  orderPageless,
}) => {
  const response = await axiosV2.Get(`/orders/client/${uid}`, {
    fcNumber,
    status,
    page,
    pageSize,
    orderPageless,
  });
  if (response) {
    return { orders: response.orders, totalEntries: response.totalEntries };
  } else {
    return { orders: {}, totalEntries: 0 };
  }
};

export const fetchAllQuotation = async ({
  uid,
  searchKey,
  status,
  page,
  pageSize,
  quotationPageless,
}) => {
  const response = await axiosV2.Get(`/clients/quotation/${uid}`, {
    uid,
    searchKey,
    status,
    page,
    pageSize,
    quotationPageless,
  });

  if (response) {
    return { quotations: response.quotations, total: response.totalEntries };
  } else {
    return { quotations: [], total: 0 };
  }
};

export const fetchAllQuotations = async ({ searchKey, page, pageSize }) => {
  const response = await axiosV2.Get('/clients/quotations', {
    searchKey,
    status: 'WAITING FOR APPROVAL',
    page,
    pageSize,
  });

  if (response) {
    return { quotations: response.quotations, total: response.totalEntries };
  } else {
    return { quotations: [], total: 0 };
  }
};

export const fetchAllProcess = async () => {
  const response = await axiosV2.Get('/utility/process-services');
  if (response) {
    return { processes: response.processes };
  } else {
    return { processes: [] };
  }
};

export const fetchSpecificQuotation = async ({ uid }) => {
  const response = await axiosV2.Get(`/clients/quotation/single/${uid}`);

  if (response) {
    return { quotations: response.quotation };
  } else {
    return { quotations: [] };
  }
};

export const updateClient = async (clinetData) => {
  const response = await axiosV2.Patch(`/clients/${clinetData.uid}`, {
    clientName: clinetData.name,
    shortCode: clinetData.shortCode,
    contactPerson: clinetData.contactPerson,
    contactNumber: clinetData.contactNumber,
    status: clinetData.status,
  });

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const deleteClient = async ({ uid }) => {
  const response = await axiosV2.Delete(`/clients/${uid}`);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const createProcess = async ({ data }) => {
  const response = await axiosV2.Post('/utility/process-services', {
    processName: data.processName,
    shortName: data.shortName,
  });

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const fetchClientChartData = async () => {
  const response = await axiosV2.Get('/clients/reports');
  if (response) {
    return response;
  } else {
    return null;
  }
};
