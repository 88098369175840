import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField,
  Box,
  InputAdornment,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { fetchPaymentLogList } from '../../api/order';

import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import OrderAccountTranscript from './OrderAccountTranscript';

import OrderTransactionUpdateModal from './OrderTransactionUpdateModal';
import { DataGrid } from '@mui/x-data-grid';
import NoDataOverlay from '../global/NoDataOverlay';
import { paymentLogListColumn } from '../../constants/table-column/order';

import OrderAccountFileExporter from './OrderAccountFileExporter';

const OrderAccountLogsTab = ({
  uid,
  transactionUpdate,
  orderInfo,
  accountInfo,
  setTransactionUpdate,
  deliveryBillId,
}) => {
  const [paymentLogList, setPaymentLogList] = useState([]);
  const [reciptData, setReciptData] = useState({});
  const [open, setOpen] = useState(false);

  //payment edit
  const [showPaymentEditModal, setShowPaymentEditModal] = useState(false);
  const [paymentEditData, setPaymentEditData] = useState({});

  //log search
  const [paymentStatusTextField, setPaymentStatusTextField] = useState(0);
  const [paymentTypeTextField, setPaymentTypeTextField] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [startdate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [dateValid, setDateValid] = useState(true);

  const [transactionId, setTransactionId] = useState('');
  const transactionInput = React.useRef(null);

  const resetMachineLogSearch = () => {
    fetchPaymentLogs('', '', '', '');
    setPaymentStatus('');
    setPaymentStatusTextField(0);
    setPaymentTypeTextField(0);
    setStartDate(null);
    setEndDate(null);
    transactionInput.current.value = '';
  };

  useEffect(() => {
    if (endDate) {
      if (startdate > endDate) {
        setDateValid(false);
      } else {
        setDateValid(true);
      }
    } else {
      setDateValid(true);
    }
  }, [startdate, endDate]);

  const handleLogSearchSubmit = (event) => {
    event.preventDefault();
    setTransactionId(transactionId);
    fetchPaymentLogs(transactionId, paymentStatus, startdate, endDate);
  };

  const fetchPaymentLogs = async (
    transactionId,
    paymentStatus,
    startdate,
    endDate,
  ) => {
    const response = await fetchPaymentLogList({
      uid,
      transactionId,
      paymentStatus,
      startdate,
      endDate,
      deliveryBillId,
    });
    setPaymentLogList(response.logs);
  };

  const fetchPaymentLogsPrint = async () => {
    const response = await fetchPaymentLogList({
      uid,
      transactionId,
      paymentStatus,
      startdate,
      endDate,
      deliveryBillId,
    });
    return response.logs;
  };

  useEffect(() => {
    (async () =>
      await fetchPaymentLogs(
        transactionId,
        paymentStatus,
        startdate,
        endDate,
      ))();
  }, [transactionUpdate]);

  return (
    <Stack mt={3}>
      <Stack alignItems="left" justifyContent="flex-start" width="100%">
        <Stack pt={0.2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
            Payment Logs:
          </Typography>
          <Stack my={1}>
            <form onSubmit={handleLogSearchSubmit}>
              <Stack
                mt={2}
                direction={{ md: 'row', xs: 'column', sm: 'row' }}
                width="100%"
                spacing={2}
                alignItems="left"
                justifyContent="left">
                <TextField
                  size="small"
                  placeholder="Transaction ID"
                  onChange={(event) => setTransactionId(event.target.value)}
                  inputRef={transactionInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  flexWrap="wrap"
                  fullWidth
                  id="outlined-basic"
                  label="Search"
                  sx={{ maxWidth: 250 }}
                  variant="outlined"
                />
                <Stack width={{ xs: '23%', md: '12%' }}>
                  <FormControl fullWidth sx={{ maxWidth: 200 }}>
                    <InputLabel id="SearchStatus">Status</InputLabel>
                    <Select
                      size="small"
                      defaultValue={paymentStatusTextField}
                      value={paymentTypeTextField}
                      labelId="SearchStatus"
                      id="demo-simple-select"
                      label="Status"
                      placeholder="Status"
                      onChange={(event) => {
                        setPaymentStatus(event.target.value);
                        setPaymentTypeTextField(event.target.value);
                      }}>
                      <MenuItem value="DUE ADJUSTMENT">Due Adjustment</MenuItem>
                      <MenuItem value="ADVANCE">Advance</MenuItem>
                      <MenuItem value="OTHER">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                {/* start date  */}
                <Stack width={{ xs: '23%', md: '15%' }}>
                  {/* issue date picker  */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Start Date"
                      value={startdate}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>

                {/* end date  */}
                <Stack width={{ xs: '23%', md: '15%' }}>
                  {/* issue date picker  */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>

                <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
                  <IconButton
                    disabled={!dateValid ? true : false}
                    title="Edit"
                    aria-label="edit"
                    type="submit"
                    sx={{
                      bgcolor: '#171820',
                      color: 'white',
                      borderRadius: 1,
                      ':hover': { bgcolor: '#303030' },
                    }}>
                    <SearchIcon />
                  </IconButton>

                  {/* reset button  */}
                  <IconButton
                    title="Edit"
                    aria-label="edit"
                    onClick={() => resetMachineLogSearch()}
                    sx={{
                      bgcolor: '#171820',
                      color: 'white',
                      borderRadius: 1,
                      ':hover': { bgcolor: '#303030' },
                    }}>
                    <RestartAltIcon />
                  </IconButton>
                  {/* downloadExcelSection  */}
                  <OrderAccountFileExporter
                    apiHandle={fetchPaymentLogsPrint}
                    title={'Payment Summary'}
                    isUpdated={transactionUpdate}
                    mapKey={'transactionId'}
                    orderInfo={orderInfo}
                    accountInfo={accountInfo}
                  />
                </Stack>
              </Stack>
            </form>
          </Stack>

          {/* table view formate datagrid  */}

          <Box
            width="100%"
            height={450}
            sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <DataGrid
              autoPageSize
              columns={paymentLogListColumn({
                setReciptData,
                setOpen,
                setPaymentEditData,
                setShowPaymentEditModal,
              })}
              rows={paymentLogList}
              getRowId={(row) => row.id}
              components={{
                NoRowsOverlay: NoDataOverlay,
              }}
            />
          </Box>
        </Stack>
      </Stack>

      {/* money recipt  */}
      <OrderAccountTranscript
        reciptData={reciptData}
        open={open}
        setOpen={setOpen}
        orderInfo={orderInfo}
      />

      {/* edit payment modal  */}
      <OrderTransactionUpdateModal
        show={showPaymentEditModal}
        toggleModal={setShowPaymentEditModal}
        paymentEditData={paymentEditData}
        accountInfo={accountInfo}
        transactionUpdate={transactionUpdate}
        setTransactionUpdate={setTransactionUpdate}
      />
    </Stack>
  );
};

export default OrderAccountLogsTab;
