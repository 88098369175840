import Request from './request';

export const updateQuotationStatus = async (uid) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(`/clients/quotation/status/${uid}`);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const approveOrder = async (fcNumber) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(`/orders/is-approved/${fcNumber}`);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const approveDelivery = async ({ uid }) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Patch(`/orders/delivery/approve/${uid}`);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const approveNetQauntity = async ({ uid }) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Patch(
    `/orders/delivery/approve/net-quantity/${uid}`,
  );

  if (response) {
    return response;
  } else {
    return null;
  }
};
