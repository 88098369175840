import { CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchUserStats } from '../../api/users';
import { PageviewTwoTone as Pageview } from '@mui/icons-material';
import { setUserStatsColor } from '../../constants/table-column/user';
const UserStats = ({ uid }) => {
  const [statsList, setStatsList] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const fetchUserStatsList = async () => {
    const response = await fetchUserStats(uid);

    setStatsList(response.stats);
  };

  const getCurrentMonth = () => {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const d = new Date();
    let monthName = month[d.getMonth()];
    return monthName;
  };

  const handelLoading = () => {
    setTimeout(() => {
      if (statsList.length == 0) {
        setNotFound(true);
      }
    }, 4000);
  };

  useEffect(() => {
    (async () => await fetchUserStatsList())();
  }, []);

  return (
    <>
      <Stack>
        <Typography fontSize={18} fontWeight={800}>
          Task Completion ({getCurrentMonth()})
        </Typography>

        {statsList.length > 0 ? (
          <>
            <Stack
              mt={2}
              height="100%"
              width="100%"
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              alignItems="center">
              {statsList?.map((stat) => (
                <Stack
                  px={1}
                  py={1}
                  bgcolor="#f2f2f2"
                  boxSizing="border-box"
                  borderRadius={1}
                  sx={{
                    color: 'white',
                    borderColor: setUserStatsColor(stat.name),
                  }}
                  width={120}
                  alignItems="center"
                  key={stat.id}>
                  <Typography fontSize="0.8rem" fontWeight={700}>
                    {stat.name}
                  </Typography>
                  <Typography fontSize="2.5rem" fontWeight={700}>
                    {stat.count}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </>
        ) : (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            {notFound ? (
              <Stack
                direction="Column"
                justifyContent="center"
                alignItems="center">
                <Pageview sx={{ width: 120, height: 120 }} />
                <Typography fontSize={18} fontWeight={800}>
                  No User Stats
                </Typography>
                <Typography maxWidth={450}>No user stats to show</Typography>
              </Stack>
            ) : (
              <Stack>
                {handelLoading()}
                <CircularProgress />
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default UserStats;
