import { Pageview } from '@mui/icons-material';
import {
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import TaskCard from './TaskCard';
import { FullscreenTwoTone as Fullscreen } from '@mui/icons-material';
import ViewportList from 'react-viewport-list';
import Button from '../../../widgets/Button';
import { RefreshTwoTone } from '@mui/icons-material';
import StatCard from '../live-tracking/StatCard';
import Clock from '../Clock';

const UpcomingTasksIndex = ({ socket }) => {
  const [dyeingBatches, setDyeingBatches] = useState([]);
  const [finishingBatches, setFinishingBatches] = useState([]);
  const [qcBatches, setQcBatches] = useState([]);
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(socket.connected);
    });
    socket.emit('batch-upcoming-tasks-list');
    return () => {
      socket.off('connect');
    };
  }, [socket]);

  useEffect(() => {
    socket.on('batch-upcoming-tasks-list-data', (data) => {
      setDyeingBatches(data.dyeingBatches);
      setFinishingBatches(data.finishingBatches);
      setQcBatches(data.qcBatches);
    });
    return () => {
      socket.off('batch-upcoming-tasks-list-data');
    };
  }, [socket]);

  const toggleFullScreen = () => {
    const trackerDiv = document.querySelector('#batch-timeline-tracker');
    if (!isFullScreenMode) {
      trackerDiv.requestFullscreen();
      setIsFullScreenMode(true);
    } else {
      document.exitFullscreen();
      setIsFullScreenMode(false);
    }
  };

  const dyeingTaskListRef = useRef(null);
  const finishingTaskListRef = useRef(null);
  const qcTaskListRef = useRef(null);

  return (
    <Stack id="batch-timeline-tracker" sx={{ background: 'white' }}>
      {isConnected ? (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={isFullScreenMode ? 2 : 0}
            mr={2}
            ml={isFullScreenMode ? 4 : 0}>
            <Stack direction="row" spacing={1}>
              <Clock label={'Upcoming Tasks'} />
              <StatCard
                label="Dyeing Tasks"
                count={dyeingBatches?.length || 0}
                borderColor="#C6EBC5"
              />
              <StatCard
                label="Finishing Tasks"
                count={finishingBatches?.length || 0}
                borderColor="#FECD70"
              />
              <StatCard
                label="QC Tasks"
                count={qcBatches?.length || 0}
                borderColor="#FA7070"
              />
            </Stack>

            <IconButton
              title="Fullscreen mode"
              onClick={toggleFullScreen}
              sx={{
                bgcolor: '#303030',
                color: '#fff',
                borderRadius: 1,
                ':hover': {
                  bgcolor: '#171820',
                },
              }}>
              <Fullscreen />
            </IconButton>
          </Stack>
          <Grid
            mt={0.5}
            container
            spacing={2}
            width={isFullScreenMode ? '98%' : '100%'}
            mx={isFullScreenMode ? 'auto' : 'none'}>
            <Grid item md={4} sm={12} xs={12}>
              <Stack
                sx={{
                  height: isFullScreenMode
                    ? 'calc(100vh - 15vh)'
                    : 'calc(100vh - 30vh)',
                  width: '100%',
                  mx: 'auto',
                  border: '2px solid rgba(0,0,0,0.2)',
                  p: 2,

                  borderRadius: 1.5,
                }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  Dyeing
                </Typography>
                {dyeingBatches.length > 0 ? (
                  <Stack spacing={1} ref={dyeingTaskListRef} overflow="auto">
                    <ViewportList
                      viewportRef={dyeingTaskListRef}
                      items={dyeingBatches}
                      overscan={3}>
                      {(item) => <TaskCard key={item?.id} batch={item} />}
                    </ViewportList>
                  </Stack>
                ) : (
                  <Stack
                    justifyContent="center"
                    pt={10}
                    width="100%"
                    height="100%"
                    alignItems="center">
                    <Stack
                      direction="Column"
                      justifyContent="center"
                      alignItems="center">
                      <Pageview sx={{ width: 120, height: 120 }} />
                      <Typography fontSize={18} fontWeight={800}>
                        No Batches
                      </Typography>
                      <Typography maxWidth={450}>
                        No Batch Tasks to show
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Stack
                sx={{
                  height: isFullScreenMode
                    ? 'calc(100vh - 15vh)'
                    : 'calc(100vh - 30vh)',
                  width: '100%',
                  mx: 'auto',
                  border: '2px solid rgba(0,0,0,0.2)',
                  p: 2,
                  borderRadius: 1.5,
                }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  Finishing
                </Typography>
                {finishingBatches.length > 0 ? (
                  <Stack spacing={1} ref={finishingTaskListRef} overflow="auto">
                    <ViewportList
                      viewportRef={finishingTaskListRef}
                      items={finishingBatches}
                      overscan={3}>
                      {(item) => <TaskCard key={item?.id} batch={item} />}
                    </ViewportList>
                  </Stack>
                ) : (
                  <Stack
                    justifyContent="center"
                    pt={10}
                    width="100%"
                    height="100%"
                    alignItems="center">
                    <Stack
                      direction="Column"
                      justifyContent="center"
                      alignItems="center">
                      <Pageview sx={{ width: 120, height: 120 }} />
                      <Typography fontSize={18} fontWeight={800}>
                        No Batches
                      </Typography>
                      <Typography maxWidth={450}>
                        No Batch Tasks to show
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Stack
                sx={{
                  height: isFullScreenMode
                    ? 'calc(100vh - 15vh)'
                    : 'calc(100vh - 30vh)',
                  width: '100%',
                  mx: 'auto',
                  border: '2px solid rgba(0,0,0,0.2)',
                  p: 2,

                  borderRadius: 1.5,
                }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  QC
                </Typography>
                {qcBatches.length > 0 ? (
                  <Stack spacing={1} ref={qcTaskListRef} overflow="auto">
                    <ViewportList
                      viewportRef={qcTaskListRef}
                      items={qcBatches}
                      overscan={3}>
                      {(item) => <TaskCard key={item?.id} batch={item} />}
                    </ViewportList>
                  </Stack>
                ) : (
                  <Stack
                    justifyContent="center"
                    pt={10}
                    width="100%"
                    height="100%"
                    alignItems="center">
                    <Stack
                      direction="Column"
                      justifyContent="center"
                      alignItems="center">
                      <Pageview sx={{ width: 120, height: 120 }} />
                      <Typography fontSize={18} fontWeight={800}>
                        No Batches
                      </Typography>
                      <Typography maxWidth={450}>
                        No Batch Tasks to show
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="auto"
          minHeight="100vh"
          spacing={2}>
          <CircularProgress />
          <Typography
            fontSize={18}
            fontWeight={600}
            sx={{ maxWidth: 450, textAlign: 'center' }}>
            Trying to load information .Try refreshing the page.
          </Typography>
          <Button
            variant="contained"
            color="info"
            endIcon={<RefreshTwoTone />}
            onClick={() => {
              location.reload();
            }}>
            Refresh
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default UpcomingTasksIndex;
