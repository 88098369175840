import { Button, Divider, Stack, Table, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import '../../styles/common-table-print.css';

const TablePdfPreview = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const { data, title, mapKey } = location.state;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Stack mx="auto" width="1080px" my={3} px={2}>
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}>
          <Stack>
            <Button onClick={() => navigate(-1)} variant="contained">
              <ArrowBackIosIcon fontSize="15px" />
              <Typography fontSize="15px">Back</Typography>
            </Button>
          </Stack>
          <Button onClick={handlePrint} variant="contained">
            <Stack direction="row" spacing={0.5}>
              <Typography fontSize="15px">Print</Typography>
              <PrintIcon />
            </Stack>
          </Button>
        </Stack>
        <Divider />
        <Box
          mx="auto"
          width="1080px"
          my={2}
          mt={3}
          ref={componentRef}
          boxSizing="border-box"
          padding="15px 30px"
          class="page">
          {/* header section  */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            mb={2}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}>
              <Box
                component="img"
                sx={{
                  width: 150,
                }}
                alt="The house from the offer."
                src="/assets/images/logo.svg"
              />
              <Stack>
                <Typography fontSize={18}>2/1,Gadda,Savar,Dhaka</Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography fontSize={20}>{title} Table</Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
              spacing={1}>
              <Typography fontSize={20}>
                {dayjs(new Date()).format('DD, MMM YYYY ')}
              </Typography>
              <Typography fontSize={14}>
                {dayjs(new Date()).format('hh:mm A')}
              </Typography>
            </Stack>
          </Stack>
          {/* table section  */}
          <Stack mt={3}>
            <Table className="common-table">
              <thead className="common-table-thead">
                <tr className="common-table-tr">
                  {Object.keys(data[0]).map((row, index) => {
                    return (
                      <th
                        key={index}
                        style={{ fontSize: '10px' }}
                        className="common-table-th">
                        {row}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((row) => {
                  return (
                    <tr key={row[mapKey]} className="common-table-tr">
                      {Object.entries(row).map((value, index) => {
                        return (
                          <td
                            className="common-table-td"
                            style={{ fontSize: '10px' }}
                            key={index}>
                            {value[1]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default TablePdfPreview;
