import { Chip, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

import { OrderBatchInfo } from '../../constants/table-column/order';
import EditOrderDetails from './EditOrderDetails';
import { EditTwoTone } from '@mui/icons-material';
import NoDataOverlay from '../global/NoDataOverlay';
import { setFabricStatusColor } from '../../utils/order';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const FabricInformationDetails = ({
  fabric,
  isQuantityUpdated,
  setIsQuantityUpdated,
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  const calculateProgress = (procced, quantity) => {
    let progress = 0;
    progress = (procced / quantity) * 100;

    return progress;
  };

  const calculateRemaining = (procced, quantity) => {
    let remaining = 0;
    remaining = quantity - procced;
    return remaining;
  };

  const removeRejected = (fabricBathes) => {
    const rows = (fabricBathes || []).filter(
      (row) => row.batchStatus !== 'REJECTED',
    );

    return rows;
  };

  useEffect(() => {}, [isQuantityUpdated]);

  return (
    <>
      <Stack
        direction="column"
        sx={{
          height: 'auto',
          minHeight: 250,
          width: '100%',
          maxWidth: { md: 472, xs: '100%' },
          border: '2px solid rgba(0,0,0,0.2)',
          mr: 2,
          mb: 2,
          p: 2,
          borderRadius: 1.5,
        }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          {/* upper information   */}
          <Stack direction="column" justifyContent="flex-start">
            <Stack direction="row" spacing={1} pb={1}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {fabric.fabricInfo.fabricType}
              </Typography>
              {/* Showing fabric status  */}
              <Stack pt={0.7}>
                <Chip
                  label={fabric.fabricInfo.fabricGlobalStatus}
                  size="small"
                  sx={{
                    backgroundColor: setFabricStatusColor(
                      fabric.fabricInfo.fabricGlobalStatus,
                    ),
                    color: '#fff',
                  }}
                />
              </Stack>
            </Stack>
            <Typography sx={{ fontSize: '0.9rem' }}>
              {fabric?.fabricInfo?.colour} ({fabric?.fabricInfo?.shadeRange})
            </Typography>
            <Typography sx={{ fontSize: '0.9rem' }}>
              Shade Group: {fabric?.fabricInfo?.shadeGroup}
            </Typography>
            <Typography sx={{ fontSize: '0.9rem' }}>
              GSM: {fabric?.fabricInfo?.gsm}
            </Typography>
            <Typography sx={{ fontSize: '0.9rem' }}>
              DIA: {fabric?.fabricInfo?.dia}
            </Typography>
          </Stack>
          {/* progress bar  */}
          <Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-end">
              <CircularProgressWithLabel
                value={calculateProgress(
                  fabric?.fabricInfo?.processed,
                  fabric?.fabricInfo?.quantity,
                )}
              />
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-end"
              pt={1}>
              <Typography
                textAlign="right"
                variant="p"
                sx={{ fontWeight: 'bold' }}>
                Finished Stock:
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }}>
                {fabric?.fabricInfo?.finishedStock} KG
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" py={2} justifyContent="space-between">
          <Stack>
            <Typography sx={{ fontWeight: 'bold' }}>Total Quantity</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {fabric?.fabricInfo?.quantity} KG
            </Typography>
          </Stack>

          <Stack>
            <Typography sx={{ fontWeight: 'bold' }}>Unprocessed</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {calculateRemaining(
                fabric?.fabricInfo?.finishedStock,
                fabric?.fabricInfo?.quantity,
              )}{' '}
              KG
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ fontWeight: 'bold' }}>Delivered</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {fabric?.fabricInfo?.deliveryQuantity} KG
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Stack>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
              Batch Summary
            </Typography>
          </Stack>
          <Stack>
            <IconButton
              size="small"
              title="Edit"
              aria-label="edit"
              onClick={() => setEditModalOpen(true)}
              sx={{
                bgcolor: '#fff',
                borderRadius: 1,
                ':hover': { bgcolor: '#f7f7f7' },
              }}>
              <EditTwoTone />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="center">
          <Stack
            width="100%"
            justifyContent="flex-start"
            sx={{
              height: 'auto',
              minHeight: 250,

              borderRadius: 1.5,
            }}>
            <Box
              width="100%"
              height={300}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                autoPageSize
                density="compact"
                columns={OrderBatchInfo({})}
                rows={removeRejected(fabric.fabricBatches)}
                getRowId={(row) => row.lotNumber}
                components={{
                  NoRowsOverlay: NoDataOverlay,
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <EditOrderDetails
          toggleModal={setEditModalOpen}
          show={editModalOpen}
          fabric={fabric}
          isQuantityUpdated={isQuantityUpdated}
          setIsQuantityUpdated={setIsQuantityUpdated}
        />
      </Stack>
    </>
  );
};

export default FabricInformationDetails;
