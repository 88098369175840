import { Box as MuiBox, styled } from '@mui/material';

const Page = styled(MuiBox)({
  width: '100%',
  height: 'auto',
  minHeight: '100vh',
  backgroundColor: '#f1f1f1',
});

export default Page;
