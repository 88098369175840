import { Dialog, DialogTitle, Stack, TextField } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { createFabricType } from '../../api/order';
import { successNoitif } from '../../utils/notification';
import Button from '../../widgets/Button';

const CreateFabricType = ({
  show,
  toggleModal,
  setFabricList,

  hasFabricAdded,
  setHasFabricAdded,
}) => {
  const handleCreationFormClose = () => {
    toggleModal(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      newFabric: '',
    },
  });

  const handelFabricTypeFormSubmission = async (data) => {
    const response = await createFabricType({
      data,
    });
    if (response) {
      const modifiedData = {
        fabricId: response.fabricType.fabricTypeId,
        fabricName: data.newFabric,
      };

      setFabricList((oldArray) => [...oldArray, modifiedData]);
      toggleModal(false);
      setHasFabricAdded(!hasFabricAdded);
      reset({ newFabric: '' });
      successNoitif('FabricType Has be added');
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="xs">
        <DialogTitle id="simple-dialog-title">Add New Fabric type</DialogTitle>
        <form>
          <Stack
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 3,
              paddingBottom: 3,
            }}>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Add New Fabric Type"
                required
                placeholder="Add New Fabric Type"
                {...register('newFabric', { required: true, minLength: 3 })}
                error={errors?.newFabric}
                helperText={errors?.newFabric && 'Must enter fabric type'}
              />
            </Stack>
          </Stack>
        </form>
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button
            variant="outlined"
            sx={{ borderColor: 'red' }}
            color="error"
            onClick={handleCreationFormClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(handelFabricTypeFormSubmission)}>
            Add
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default CreateFabricType;
