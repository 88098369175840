import { Stack } from '@mui/material';

import React from 'react';

import ClientReportChart from './ClientReportChart';

const ClientReport = () => {
  return (
    <Stack>
      <Stack direction={{ lg: 'row', md: 'column' }} spacing={2}>
        <Stack width="80%" mx="auto">
          <ClientReportChart />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ClientReport;
