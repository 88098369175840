import {
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../widgets/PageHeader';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DeleteTwoTone } from '@mui/icons-material';
import {
  checkDeliveryValidity,
  deleteOrderDelivery,
  fetchOrderDelivery,
  fetchOrderDetails,
  updateOrderDelivery,
} from '../../api/order';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { OrderDeliveryValidator } from '../../validators/order';
import dayjs from 'dayjs';
import DoneIcon from '@mui/icons-material/Done';
import UpdateOrderFabricDetails from '../../components/order/UpdateOrderFabricDetails';
import PrintIcon from '@mui/icons-material/Print';
import OrderSummary from '../../components/order/OrderSummary';
import { PRINT_DELIVERY } from '../../constants/routeUrls';
import { successNoitif } from '../../utils/notification';
import DeliveryApprovalModal from '../../components/approval/DeliveryApprovalModal';
import PermissionWrapper from '../wrapper/PermissionWrapper';
import PageTitle from '../../utils/PageTitle';
import Button from '../../widgets/Button';

const UpdateDelivery = () => {
  const { deliveryId } = useParams();
  const [orderInfo, setOrderInfo] = useState({});
  const [clientInfo, setClientInfo] = useState({});
  const [initialDelivery, setInitialDelivery] = useState({});
  const [delivery, setDelivery] = useState('');
  const [newDelivery, setNewDelivery] = useState([]);
  const [parentCheck, SetParentCheck] = useState(0);
  const [perticularFabric, setPerticularFabric] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fullFormData, setFullFormData] = useState({});
  const [validity, setValidity] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [showDeliveryApprovalModal, setShowDeliveryApprovalModal] =
    useState(false);
  let navigate = useNavigate();

  const handleEditModal = async () => {
    const response = await checkDeliveryValidity({ uid: deliveryId });
    setValidity(response.check);
    setShowDeliveryApprovalModal(true);
  };

  const resolver = yupResolver(OrderDeliveryValidator);

  const {
    register,
    formState: { errors },

    handleSubmit,
    setValue,

    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      partyName: '',
      vehicleNumber: '',
      deliveryContatct: '',
      deliveryContatctNumber: '',
      deliveryAddress: '',
    },
    resolver,
  });
  const deliveryDate = useWatch({ control, name: 'deliveryDate' });
  const partyName = useWatch({ control, name: 'partyName' });
  const vehicleNumber = useWatch({ control, name: 'vehicleNumber' });
  const deliveryAddress = useWatch({ control, name: 'deliveryAddress' });
  const deliveryContatct = useWatch({ control, name: 'deliveryContatct' });
  const deliveryContatctNumber = useWatch({
    control,
    name: 'deliveryContatctNumber',
  });

  const calculateFabricTotalQuantity = (fabric) => {
    const total = fabric?.batchInfo.reduce((sum, batch) => {
      return sum + batch.tempDeliverable;
    }, 0);

    return total;
  };

  const handelFormSubmit = (data) => {
    let modifiedFabricList = [];
    newDelivery.map((fabric) => {
      let modifiedFabric = {
        fabricId: fabric?.fabricId,
        fabricName: fabric?.fabricName,
        fabricRate: fabric?.fabricRate,
        fabricColor: fabric?.fabricColor || '',
        fabricDeliveryQuantity: calculateFabricTotalQuantity(fabric),
        batchInfo: fabric?.batchInfo,
      };
      modifiedFabricList.push(modifiedFabric);
    });
    const modifiedData = {
      partyName: data?.partyName,
      vehicleNumber: data?.vehicleNumber,
      deliveryContatct: data?.deliveryContatct,
      deliveryContatctNumber: data?.deliveryContatctNumber,
      deliveryAddress: data?.deliveryAddress,
      deliveryDate: data?.deliveryDate,
      deliveryId: deliveryId,
      totalDeliveryQuantity: calculateTotalQuantity(newDelivery),
      clientId: clientInfo?.clientId,
      orderId: orderInfo?.orderId,
      fabricList: modifiedFabricList,
    };

    setFullFormData(modifiedData);
    setShowConfirmation(true);
  };

  const fetchDeliveryInformation = async () => {
    const result = await fetchOrderDelivery({ uid: deliveryId });
    setInitialDelivery(result);
    fetchDetails(result.fcNumber);
  };

  const fetchDetails = async (fcNumber) => {
    const response = await fetchOrderDetails(fcNumber);
    if (response) {
      const { order, client, fabrics } = response;
      setDelivery(fabrics);
      setOrderInfo(order);
      setClientInfo(client);
    }
  };

  const calculateTotalQuantity = (newDelivery) => {
    let total = 0;
    for (const fabric of newDelivery) {
      for (const batch of fabric.batchInfo) {
        total = total + batch.tempDeliverable;
      }
    }
    return total;
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  //colse confrimation dialog
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const onDeleteConfirmation = () => {
    handleDeleteCloseConfirmation();
  };

  const handleDeleteCloseConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    orderDeliveryFormSubmit();
  };

  const handelDeleteDelivery = async () => {
    const response = await deleteOrderDelivery({ uid: deliveryId });
    if (response) {
      successNoitif('Delivery has been deleted Successfully');
      setShowDeleteConfirmation(false);
      navigate(-1);
    }
  };
  //async calling api to post form data
  const orderDeliveryFormSubmit = async () => {
    const response = await updateOrderDelivery(fullFormData);

    if (response) {
      successNoitif('Order delivery has been updated');
      navigate(-1);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchDeliveryInformation();
    })();
  }, []);

  useEffect(() => {
    if (isUpdated) {
      navigate(-1);
    }
  }, [isUpdated]);

  useEffect(() => {
    if (initialDelivery) {
      setValue('partyName', initialDelivery?.partyName);
      setValue('deliveryContatct', initialDelivery?.deliveryContatct);
      setValue(
        'deliveryContatctNumber',
        initialDelivery?.deliveryContatctNumber,
      );
      setValue('deliveryAddress', initialDelivery?.deliveryAddress);
      setValue('deliveryDate', initialDelivery?.deliveryDate);
      setValue('vehicleNumber', initialDelivery?.vehicleNumber);
    }
  }, [initialDelivery]);

  return (
    <Stack
      spacing={2}
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <Stack width={{ md: '80%', sm: '100%' }} mx="auto">
        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-start"
          mt={1}
          mb={2}>
          <Button onClick={() => navigate(-1)} variant="contained">
            <ArrowBackIosIcon fontSize="15px" />
            <Typography fontSize="15px">Back</Typography>
          </Button>
        </Stack>
        <Divider></Divider>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
            <PageHeader>Edit Delivery #{deliveryId} </PageHeader>
            {initialDelivery?.status == 'WAITING FOR APPROVAL' ? (
              <PermissionWrapper acceptedRoles="MANAGER,ADMIN">
                <IconButton
                  aria-label="log"
                  disabled={
                    initialDelivery.status != 'WAITING FOR APPROVAL'
                      ? true
                      : false
                  }
                  onClick={() => {
                    setShowDeleteConfirmation(true);
                  }}
                  sx={{
                    fontSize: 'large',
                    bgcolor: '#ff5722',
                    color: 'white',
                    borderRadius: 1,
                    ':hover': { bgcolor: '#ff5722' },
                  }}>
                  <DeleteTwoTone />
                </IconButton>
              </PermissionWrapper>
            ) : (
              <Chip
                label={initialDelivery?.status}
                sx={{
                  maxWidth: 'max-content',
                  background: '#4caf50',
                  color: 'white',
                }}
              />
            )}
          </Stack>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            {initialDelivery.status == 'WAITING FOR APPROVAL' ? (
              <>
                <PermissionWrapper acceptedRoles="MANAGER,ADMIN">
                  <Button
                    sx={{
                      backgroundColor: 'green',
                      ':hover': {
                        bgcolor: 'green',
                        color: '#fff',
                      },
                    }}
                    onClick={() => handleEditModal()}
                    variant="contained">
                    <Stack direction="row" spacing={0.5}>
                      <Typography>Approve</Typography>
                      <DoneIcon />
                    </Stack>
                  </Button>
                </PermissionWrapper>
              </>
            ) : (
              <Button
                sx={{
                  backgroundColor: '#4caf50',
                  pointerEvents: 'none',
                }}
                variant="contained">
                <Stack direction="row" spacing={0.5}>
                  <DoneIcon />
                  <Typography>Approved</Typography>
                </Stack>
              </Button>
            )}

            <Button
              variant="contained"
              component={Link}
              to={`${PRINT_DELIVERY}/${deliveryId}`}>
              <Stack direction="row" spacing={0.5}>
                <Typography>Preview</Typography>
                <PrintIcon />
              </Stack>
            </Button>
          </Stack>
        </Stack>

        {!initialDelivery ? (
          <>
            <Stack
              justifyContent="center"
              width="100%"
              height="100%"
              alignItems="center">
              <CircularProgress />
            </Stack>
          </>
        ) : (
          <Stack
            sx={{
              height: 'auto',
              minHeight: 100,
              width: { xs: '100%', md: '97%' },
              p: 2,
              paddingBottom: 0,
              m: 2,
              marginLeft: { xs: 0, md: 5 },
            }}>
            <form>
              <Stack
                width={{ md: '80%', sm: '100%' }}
                direction="row"
                spacing={4}
                justifyContent="space-between"
                alignItems="flex-start">
                <Stack
                  sx={{
                    height: 'auto',
                    minHeight: 200,
                    width: { xs: '100%', md: '40%', sm: '60%' },
                  }}>
                  <Typography
                    fontSize={20}
                    sx={{ fontWeight: '500' }}
                    my={1}
                    mt={1}>
                    Delivery Information
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    py={2}>
                    <Grid item xs={6} md={6}>
                      <Stack>
                        <Typography fontSize={15}>Delivery Date: </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          minDate={new Date()}
                          value={deliveryDate}
                          onChange={(value) => setValue('deliveryDate', value)}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              type="date"
                              {...params}
                              sx={{
                                maxWidth: {
                                  md: '100%',
                                  sm: '100%',
                                  xs: '100%',
                                },
                              }}
                              error={errors?.deliveryDate}
                              helperText={errors?.deliveryDate?.message}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Stack>
                        <Typography fontSize={15}>Vehicle number: </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        size="small"
                        sx={{
                          width: '100%',
                        }}
                        placeholder="Vehicle Number"
                        {...register('vehicleNumber')}
                        error={errors?.vehicleNumber}
                        helperText={errors?.vehicleNumber?.message}
                      />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Stack>
                        <Typography fontSize={15}>
                          Delivery Address:{' '}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      {/* delivery Address  */}
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        <TextField
                          size="small"
                          sx={{
                            width: '300px',
                          }}
                          {...register('deliveryAddress')}
                          error={errors?.deliveryAddress}
                          helperText={errors?.deliveryAddress?.message}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Stack
                  sx={{
                    height: 'auto',
                    minHeight: 200,
                    width: { xs: '100%', md: '40%', sm: '60%' },
                  }}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    pt={7}>
                    <Grid item xs={6} md={6}>
                      <Stack>
                        <Typography fontSize={15}>
                          Delivery Contact Number:{' '}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        size="small"
                        {...register('deliveryContatctNumber')}
                        error={errors?.deliveryContatctNumber}
                        helperText={errors?.deliveryContatctNumber?.message}
                      />
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Stack>
                        <Typography fontSize={15}>
                          Delivery Contact Person:{' '}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        size="small"
                        {...register('deliveryContatct')}
                        error={errors?.deliveryContatct}
                        helperText={errors?.deliveryContatct?.message}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </form>
          </Stack>
        )}

        {/* edit batch wise fabric section  */}
        {!delivery ? (
          <>
            <Stack
              justifyContent="center"
              width="100%"
              height="100%"
              alignItems="center">
              <CircularProgress />
            </Stack>
          </>
        ) : (
          <>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <Stack
                direction="column"
                flexWrap="wrap"
                alignItems="center"
                width={{ xs: '100%', md: '50%' }}
                justifyContent="flex-start">
                {delivery?.map((fabric, index) => {
                  if (fabric?.fabricInfo?.processed > 0) {
                    return (
                      <UpdateOrderFabricDetails
                        key={fabric?.fabricInfo?.fabricId}
                        fabric={fabric}
                        index={index}
                        parentCheck={parentCheck}
                        SetParentCheck={SetParentCheck}
                        perticularFabric={perticularFabric}
                        setPerticularFabric={setPerticularFabric}
                        newDelivery={newDelivery}
                        setNewDelivery={setNewDelivery}
                        specificFabricId={fabric?.fabricInfo?.fabricId}
                        fabricList={
                          initialDelivery.fabricList
                        }></UpdateOrderFabricDetails>
                    );
                  } else {
                    <></>;
                  }
                })}
              </Stack>

              <Stack
                sx={{
                  height: '98%',
                  minHeight: 90,
                  width: { xs: '100%', md: '50%' },
                  border: '2px solid rgba(0,0,0,0.2)',
                  p: 2,
                  paddingBottom: 0,
                  // m: 2,

                  borderRadius: 1.5,
                }}>
                <Stack flex={1}>
                  <Stack
                    width="100%"
                    justifyContent="center"
                    alignItems="center">
                    <Typography
                      fontSize={{ xs: 18, md: 20 }}
                      sx={{ fontWeight: 'bold' }}>
                      Order Delivery Summary
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      pt={1}
                      sx={{ fontWeight: 'bold' }}
                      fontSize={{ xs: 15, md: 18 }}>
                      Order #{deliveryId}
                    </Typography>
                  </Stack>
                  <Grid container spacing={{ xs: 0, md: 2 }}>
                    <Grid item xs={12} md={6} sm={6}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="p"
                          fontSize={15}
                          sx={{ fontWeight: 'bold' }}>
                          Party Name:
                        </Typography>
                        <Typography variant="p" fontSize={15}>
                          {partyName}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="p"
                          fontSize={15}
                          sx={{ fontWeight: 'bold' }}>
                          Vehicle No:
                        </Typography>
                        {vehicleNumber ? (
                          <Typography variant="p" fontSize={15}>
                            {vehicleNumber}
                          </Typography>
                        ) : (
                          <Typography variant="p" fontSize={15}>
                            Not provided
                          </Typography>
                        )}
                      </Stack>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="p"
                          fontSize={15}
                          sx={{ fontWeight: 'bold' }}>
                          Delivery Address:
                        </Typography>
                        <Typography variant="p" fontSize={15}>
                          {deliveryAddress}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="p"
                          fontSize={15}
                          sx={{ fontWeight: 'bold' }}>
                          Delivery Contatct:
                        </Typography>
                        <Typography variant="p" fontSize={15}>
                          {deliveryContatct}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="p"
                          fontSize={15}
                          sx={{ fontWeight: 'bold' }}>
                          Delivery Number:
                        </Typography>
                        <Typography variant="p" fontSize={15}>
                          {deliveryContatctNumber}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={0.5}>
                        <Typography
                          variant="p"
                          fontSize={15}
                          sx={{ fontWeight: 'bold' }}>
                          Delivery Date:{' '}
                        </Typography>
                        <Typography variant="p" fontSize={15}>
                          {dayjs(deliveryDate).format('DD/MMM/YYYY ')}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Stack>
                    {!newDelivery ? (
                      <></>
                    ) : (
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        width="100%">
                        <Stack>
                          {newDelivery?.map((fabric) => (
                            <OrderSummary
                              key={fabric.fabricId}
                              fabric={fabric}></OrderSummary>
                          ))}
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent="flex-end" py={2}>
                  <Typography variant="h6" fontSize={{ xs: 15, md: 18 }}>
                    Total Delivery Quantity:{' '}
                    {calculateTotalQuantity(newDelivery)} KG
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </>
        )}

        <Stack
          width="100%"
          justifyContent="center"
          alignItems="flex-end"
          mt={2}>
          <Button
            disabled={
              initialDelivery.status != 'WAITING FOR APPROVAL' ? true : false
            }
            variant="contained"
            onClick={handleSubmit(handelFormSubmit)}>
            Update
          </Button>
        </Stack>
      </Stack>

      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to update this delivery order?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => handelConfrimationDecision()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>

      <Stack>
        <Dialog
          onClose={onDeleteConfirmation}
          open={showDeleteConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to delete this delivery order?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowDeleteConfirmation(false);
              }}>
              No
            </Button>
            <Button variant="contained" onClick={() => handelDeleteDelivery()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
      <PageTitle title="Update Order Delivery" />

      <DeliveryApprovalModal
        show={showDeliveryApprovalModal}
        toggleModal={setShowDeliveryApprovalModal}
        data={initialDelivery}
        validity={validity}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}></DeliveryApprovalModal>
    </Stack>
  );
};

export default UpdateDelivery;
