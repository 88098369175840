import Request from './request';

const axiosV2 = new Request(2);

export const fetchInQueueOrders = async (query) => {
  const response = await axiosV2.Get('/orders/in-queue', {
    searchKey: query,
  });

  if (!response) {
    return [];
  }
  return response.orders;
};

export const fetchAllOrder = async ({
  fcNumber,
  status,
  page,
  pageSize,
  isApproved,
  pageless,
}) => {
  const response = await axiosV2.Get('/orders', {
    fcNumber,
    status,
    page,
    pageSize,
    isApproved,
    pageless,
  });

  if (response) {
    return {
      orders: response.orders,
      total: response.totalEntries,
    };
  } else {
    return { orders: [], total: 0 };
  }
};

export const fetchAllAccountOrder = async ({ uid, status, page, pageSize }) => {
  const response = await axiosV2.Get('/orders/accounts', {
    uid,
    status,
    page,
    pageSize,
  });

  if (response) {
    return {
      orders: response.accounts,
      total: response.totalEntries,
    };
  } else {
    return { orders: [], total: 0 };
  }
};

export const fetchNonApprovedOrder = async ({
  fcNumber,
  status,
  page,
  pageSize,
}) => {
  const response = await axiosV2.Get('/orders/not-approved', {
    fcNumber,
    status,
    page,
    pageSize,
  });
  if (response) {
    return {
      orders: response.orders,
      total: response.totalEntries,
    };
  } else {
    return { orders: [], total: 0 };
  }
};

export const fetchAllOrderStats = async () => {
  const response = await axiosV2.Get('/orders/stats');

  if (response) {
    return response.stats;
  } else {
    return { stats: [] };
  }
};

export const fetchOrderClients = async ({ searchKey, searchType }) => {
  const response = await axiosV2.Get('/clients', {
    searchKey,
    searchType,
  });
  if (response) {
    return { clients: response.clients, total: response.totalEntries };
  } else {
    return { clients: [], total: 0 };
  }
};

export const fetchOrderQuotation = async ({ uid, pageless }) => {
  const response = await axiosV2.Get(`/clients/quotation/${uid}`, {
    uid,
    pageless,
  });

  if (response) {
    return { quotations: response.quotations, total: response.totalEntries };
  } else {
    return { quotations: [], total: 0 };
  }
};

export const fetchOrderFabricInformation = async () => {
  const response = await axiosV2.Get('/utility/fabrics');

  if (response) {
    return {
      fabricInformation: response.fabricTypes,
    };
  } else {
    return { fabricInformation: {} };
  }
};

export const createOrderShadeColor = async ({ uid, shadeId, colorName }) => {
  const response = await axiosV2.Post('/clients/colour', {
    clientUid: uid,
    shadeId: shadeId,
    colourName: colorName,
  });

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const fetchOrderDetails = async (fcNumber) => {
  const response = await axiosV2.Get(`/orders/${fcNumber}`, {
    advance: true,
  });

  if (response.status === 200) {
    return {
      order: response.order,
      client: response.client,
      fabrics: response.fabrics,
      stats: response.stats,
    };
  } else {
    return null;
  }
};

export const fetchOrderAccountDetails = async (uid) => {
  const response = await axiosV2.Get(`/orders/accounts/${uid}`);
  if (response.status === 200) {
    return {
      order: response.order,
      batches: response.orderBatches,
      account: response.account,
    };
  } else {
    return null;
  }
};

export const fetchOrderDelivery = async ({ uid }) => {
  const response = await axiosV2.Get(`/orders/update-delivery/${uid}`);
  if (response.status === 200) {
    return response.orderDelivery;
  } else {
    return null;
  }
};

export const deleteOrderDelivery = async ({ uid }) => {
  const response = await axiosV2.Delete(`/orders/delivery/delete/${uid}`);
  if (response) {
    return response;
  } else {
    return null;
  }
};

export const checkDeliveryValidity = async ({ uid }) => {
  const response = await axiosV2.Get(`/orders/delivery/check/${uid}`);
  return response;
};

export const fetchSpecificShadeColor = async ({ shadeId, clientId }) => {
  const response = await axiosV2.Get(`/orders/shade-colors/${shadeId}`, {
    clientUid: clientId,
  });

  if (response.status === 200) {
    return {
      colours: response.colourList,
    };
  } else {
    return { colours: [] };
  }
};

export const createOrder = async (data) => {
  const response = await axiosV2.Post('/orders', data);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const createTransaction = async (data) => {
  const response = await axiosV2.Post(`/orders/accounts/${data.uid}`, data);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const fetchPaymentLogList = async ({
  uid,
  transactionId,
  paymentStatus,
  startdate,
  endDate,
  deliveryBillId,
}) => {
  const response = await axiosV2.Get(`/orders/accounts/logs/${uid}`, {
    transactionId,
    status: paymentStatus,
    startDate: startdate,
    endDate,
    deliveryBillId,
  });

  if (response) {
    return { logs: response.accountLogs };
  } else {
    return { logs: [] };
  }
};

export const fetchAccountDeliveryList = async ({
  uid,
  searchKey,
  status,
  page,
  pageSize,
}) => {
  const response = await axiosV2.Get(`/orders/accounts/delivery/${uid}`, {
    searchKey,
    status,
    page,
    pageSize,
  });
  if (response) {
    return response;
  }
  return null;
};

export const createOrderDelivery = async (data) => {
  const response = await axiosV2.Post('/orders/delivery', data);
  if (response) {
    return response;
  } else {
    return null;
  }
};

export const updateOrderDelivery = async (data) => {
  const response = await axiosV2.Patch('/orders/delivery', data);
  if (response) {
    return response;
  } else {
    return null;
  }
};

export const updateOrderDeliveryRate = async (data) => {
  const response = await axiosV2.Patch(
    '/orders/accounts/delivery/rate-update',
    { rateInfo: data },
  );
  if (response) {
    return response;
  } else {
    return null;
  }
};

export const updateOrderDeliveryNetQuantity = async (deliveryId, data) => {
  const response = await axiosV2.Patch(
    `/orders/delivery/net-quantity-update/${deliveryId}`,
    {
      deliveryInfo: data,
    },
  );
  if (response) {
    return response;
  } else {
    return null;
  }
};

export const updateOrderTransactionAmount = async ({
  transactionId,
  formData,
}) => {
  const response = await axiosV2.Patch(
    `/orders/accounts/update/${transactionId}`,
    formData,
  );
  if (response) {
    return response;
  } else {
    return null;
  }
};
export const createValidOrders = async (data) => {
  const response = await axiosV2.Post('/orders/bulk', {
    validOrders: data,
  });

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const submitCsvFile = async (file, data) => {
  const formData = new FormData();
  formData.append('data', data);
  formData.append('file', file, file?.name);
  const response = await axiosV2.PostWithFileUpload(
    '/orders/check-csv',
    formData,
  );

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const createFabricType = async ({ data }) => {
  const response = await axiosV2.Post('/utility/fabrics', {
    fabricName: data.newFabric,
  });

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const updateOrder = async (fcNumber, data) => {
  const response = await axiosV2.Patch(`/orders/${fcNumber}`, data);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const updateInQueueOrder = async (fcNumber, data) => {
  const response = await axiosV2.Patch(`/orders/in-queue/${fcNumber}`, data);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const updateBulkOrder = async (fcNumber, data) => {
  const response = await axiosV2.Patch(`/orders/bulk-order/${fcNumber}`, data);

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const fetchFabricById = async (orderId) => {
  const response = await axiosV2.Get(`/fabrics/v2/${orderId}`);
  if (response.status === 200) {
    return {
      fabrics: response.fabrics,
    };
  } else {
    return null;
  }
};

export const fetchSpecificOrder = async ({ fcNumber }) => {
  const response = await axiosV2.Get(`/orders/${fcNumber}`);

  if (response) {
    return { order: response.order };
  } else {
    return { order: {} };
  }
};

export const fetchSpecificBulkOrder = async ({ fcNumber }) => {
  const response = await axiosV2.Get(`/orders/bulk/${fcNumber}`);

  if (response) {
    return { order: response.order };
  } else {
    return { order: {} };
  }
};

export const updateBatchOrderQuantity = async ({ fabricId, data }) => {
  const response = await axiosV2.Patch('/orders/fabric-quantity', {
    fabricId: fabricId,
    quantity: data.totalQuantity,
  });

  if (response) {
    return response;
  } else {
    return null;
  }
};

export const fetchSingleOrderDeliveries = async ({
  fcNumber,
  searchKey,
  status,
  page,
  pageSize,
}) => {
  const response = await axiosV2.Get(`/orders/delivery/${fcNumber}`, {
    searchKey,
    status,
    page,
    pageSize,
  });

  if (response) {
    return { deliveries: response.deliveries, total: response.totalEntries };
  } else {
    return { deliveries: [], total: 0 };
  }
};

export const fetchDeliveryList = async (query) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Get('/orders/deliveries', query);

  if (!response) {
    return {
      deliveries: [],
      total: 0,
    };
  }
  return {
    deliveries: response.deliveries,
    total: response.total,
  };
};
