import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import TemplateFields from './TemplateFields';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '../../../widgets/Button';
import { useState } from 'react';
import { batchPhaseColor } from '../../../utils/batch';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightyellow' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#fff',
  borderRadius: '5px',
  padding: grid,
});

const TaskManagementForm = ({
  onDropEnd,
  remove,
  tasks,
  append,
  errors,
  formControl,
  register,
  setFormValue,
}) => {
  const [showTaskFormDetailModal, setShowTaskFormDetailModal] = useState(false);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleTaskSelection = (task) => {
    setSelectedTaskIndex(task);
    setShowTaskFormDetailModal(true);
  };

  const handleClose = () => {
    setShowTaskFormDetailModal(false);
    setSelectedTaskIndex(null);
    setSelectedTask(null);
  };

  const findSelectedTask = () => {
    const task = tasks?.find((item, index) => index === selectedTaskIndex);
    setSelectedTask(task);
  };

  useEffect(() => findSelectedTask(), [selectedTask, selectedTaskIndex]);

  return (
    <Box width="100%" height="100%">
      <Dialog open={showTaskFormDetailModal} onClose={handleClose}>
        <DialogContent>
          <Stack spacing={1.5} py={1} minWidth={300}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between">
              <Typography fontSize={20} fontWeight={700}>
                {selectedTask?.taskName}
              </Typography>
              <Chip
                size="small"
                label={selectedTask?.taskType}
                color={batchPhaseColor(selectedTask?.taskType)}
              />
            </Stack>
            {selectedTask?.formFields?.map((item, index) => (
              <Stack
                key={JSON.stringify(item)}
                direction="row"
                spacing={1}
                justifyContent="space-evenly">
                <Typography
                  textAlign="left"
                  flex={1}
                  fontWeight={700}
                  fontSize={14}>
                  {index + 1}. {item?.label}
                </Typography>
                <Chip label={item?.fieldType} size="small" color="secondary" />
                <Chip
                  label={item?.isRequired ? 'REQUIRED' : 'OPTIONAL'}
                  size="small"
                  color={item?.isRequired ? 'error' : 'info'}
                />
              </Stack>
            ))}
          </Stack>
        </DialogContent>
      </Dialog>

      {tasks?.length > 0 ? (
        <DragDropContext onDragEnd={onDropEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Box
                width="100%"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {tasks.map((task, index) => (
                  <Draggable
                    key={`${task.taskName}-${index}`}
                    draggableId={`${task.taskName}-${index}`}
                    index={index}>
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}>
                        <TemplateFields
                          index={index}
                          remove={remove}
                          errors={errors}
                          control={formControl}
                          register={register}
                          setFormValue={setFormValue}
                          showTaskDetailView={handleTaskSelection}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Stack
          width="100%"
          height="30vh"
          alignItems="center"
          justifyContent="center"
          spacing={2}>
          <Typography variant="h5" fontWeight={800} sx={{ flexGrow: 0 }}>
            No task added
          </Typography>
          <Typography>
            No task has been added yet. Please add your first task to get
            started.
          </Typography>
          <Button variant="contained" onClick={append}>
            Add Task
          </Button>
          {errors.tasks && (
            <FormHelperText sx={{ color: 'red' }}>
              {errors?.tasks?.message}
            </FormHelperText>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default TaskManagementForm;
