import {
  Divider,
  Typography,
  CircularProgress,
  Stack,
  Grid,
  IconButton,
  Chip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import Button from '../../widgets/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from 'react-router-dom';

import { EditTwoTone } from '@mui/icons-material';
import dayjs from 'dayjs';

import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import MachineTimeLine from '../../components/machine/MachineTimeLine';
import {
  fetchSingleMachineLogs,
  generateMachineQrCode,
  getMachine,
} from '../../api/machine';
import { EditMachine } from '../../components/machine/EditMachine';
import { PageviewTwoTone as Pageview } from '@mui/icons-material';
import { RefreshTwoTone as Refresh } from '@mui/icons-material';
import ViewportList from 'react-viewport-list';
import { setMachineStatusColor } from '../../constants/table-column/machine';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import DownloadIcon from '@mui/icons-material/Download';
import { formatTime } from '../../utils/time';
import MachineStats from '../../components/machine/MachineStats';
import PermissionWrapper from '../wrapper/PermissionWrapper';
import PageTitle from '../../utils/PageTitle';

const MachineDetails = () => {
  let navigate = useNavigate();
  const { name } = useParams();
  const [showEditMachineForm, setShowEditMachineForm] = useState(false);
  const [machineInfo, setMachineInfo] = useState({});
  const [machineLogs, setMachineLogs] = useState([]);
  const [machineStatusTextField, setMachineStatusTextField] = useState(0);
  const [machineTypeTextField, setMachineTypeTextField] = useState(0);
  const [machineStatus, setMachineStatus] = useState('');
  const [startdate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [dateValid, setDateValid] = useState(true);
  const textInput = React.useRef(null);
  const machineLogsListRef = useRef(null);
  const [showQrCodeReGenerationDialog, setShowQrCodeReGEnerationDIalog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const fetchMachineInfo = async () => {
    const response = await getMachine(name);
    setMachineInfo(response.machine);
  };

  const fecthMachineLogs = async (machineStatus, startdate, endDate) => {
    const response = await fetchSingleMachineLogs({
      name: name,
      status: machineStatus,
      startDate: startdate,
      endDate: endDate,
    });

    if (response) {
      setMachineLogs(response.machineLogs.reverse());
    }
  };

  const handleLogSearchSubmit = (event) => {
    event.preventDefault();
    fecthMachineLogs(machineStatus, startdate, endDate);
  };

  const resetMachineLogSearch = () => {
    fecthMachineLogs('', '', '');
    setMachineStatus('');
    setMachineStatusTextField(0);
    setMachineTypeTextField(0);
    setStartDate(null);
    setEndDate(null);
    textInput.current.value = '';
  };

  useEffect(() => {
    async function loader() {
      await Promise.all([fetchMachineInfo(), fecthMachineLogs()]);
    }

    loader();
  }, [isUpdated]);

  useEffect(() => {
    if (endDate) {
      if (startdate > endDate) {
        setDateValid(false);
      } else {
        setDateValid(true);
      }
    } else {
      setDateValid(true);
    }
  }, [startdate, endDate]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: `AREX-${machineInfo?.name}-QR-${formatTime(new Date())}`,
    content: () => componentRef.current,
  });

  const handleQrCodeGeneration = async () => {
    setIsLoading(true);
    const response = await generateMachineQrCode(machineInfo?.name);

    if (response) {
      setShowQrCodeReGEnerationDIalog(false);
      setIsLoading(false);
      setIsUpdated(!isUpdated);
    }
  };

  return (
    <Stack
      width="80%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      mx="auto"
      pt={3}>
      <PageTitle title="Machine Details" />
      <Dialog
        open={showQrCodeReGenerationDialog}
        onClose={() => setShowQrCodeReGEnerationDIalog(false)}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to re generate QR code for &apos;
            {machineInfo.name}&apos;. If so the QR code currently being used
            will be invalid
          </Typography>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => setShowQrCodeReGEnerationDIalog(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              loading={isLoading}
              onClick={handleQrCodeGeneration}>
              Yes
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack
        width="100%"
        direction="row"
        justifyContent="flex-start"
        mt={1}
        mb={2}>
        <Button onClick={() => navigate(-1)} variant="contained">
          <ArrowBackIosIcon fontSize="15px" />
          <Typography fontSize="15px">Back</Typography>
        </Button>
      </Stack>
      <Divider></Divider>
      {!machineInfo.qrId ? (
        <Stack
          justifyContent="center"
          width="100%"
          height="100%"
          alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Grid mt={2} container spacing={2} width="100%">
          <Grid item md={5} sm={12}>
            <Stack
              sx={{
                height: '100%',
                mx: 'auto',
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}>
                <Stack
                  sx={{
                    position: 'relative',
                  }}>
                  {machineInfo?.qrId ? (
                    <QRCode size={80} value={machineInfo?.qrId} />
                  ) : (
                    <CircularProgress />
                  )}
                  <Stack display="none" width="100%" heigh="100%">
                    <Stack
                      ref={componentRef}
                      spacing={1}
                      width="100%"
                      heigh="100vh"
                      marginTop="32vh"
                      direction="column"
                      alignItems="center"
                      justifyContent="center">
                      <QRCode size={350} value={machineInfo?.qrId} />
                      <Typography fontSize="30px">
                        {machineInfo?.name}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    position="absolute"
                    width={80}
                    height={80}
                    bgcolor="rgba(0,0,0,0.5)"
                  />
                  <Stack
                    sx={{
                      position: 'absolute',
                      background: '#4562f7',
                      top: 0,
                      left: 0,

                      '&:hover': {
                        boxShadow: 3,
                        transition: '300ms ease',
                      },
                    }}>
                    <IconButton
                      sx={{
                        px: 1,
                        py: 1,
                        color: 'white',
                        opacity: 1,
                        ':hover': {
                          boxShadow: 2,
                        },
                      }}
                      onClick={handlePrint}>
                      <DownloadIcon />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack width="100%" direction="column">
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}>
                    <Stack>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        justifyContent="flex-start"
                        alignItems="center">
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                          {machineInfo?.name}
                        </Typography>
                        <PermissionWrapper acceptedRoles="MANAGER,ADMIN">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.2}>
                            <IconButton
                              size="small"
                              title="Edit"
                              aria-label="edit"
                              onClick={() => setShowEditMachineForm(true)}
                              sx={{
                                borderRadius: 1,
                                ':hover': { bgcolor: '#f7f7f7' },
                              }}>
                              <EditTwoTone />
                            </IconButton>
                            <IconButton
                              size="small"
                              title="Re generate QR code"
                              aria-label="edit"
                              onClick={() =>
                                setShowQrCodeReGEnerationDIalog(true)
                              }
                              sx={{
                                bgcolor: '#fff',
                                borderRadius: 1,
                                ':hover': { bgcolor: '#f7f7f7' },
                              }}>
                              <Refresh />
                            </IconButton>
                          </Stack>
                        </PermissionWrapper>
                      </Stack>

                      <Typography pt={0.5} fontSize={15}>
                        {machineInfo?.machineType} Machine
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Stack
                        width="100%"
                        direction="row"
                        justifyContent="flex-end">
                        <Chip
                          label={machineInfo?.status}
                          size="small"
                          sx={{
                            backgroundColor: setMachineStatusColor(
                              machineInfo?.status,
                            ),
                            color: '#ffff',
                          }}
                        />
                      </Stack>

                      <Stack>
                        <Typography>
                          Capacity:{machineInfo?.capacity} KG
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Typography pt={0.5} fontSize={15}>
                    {dayjs(machineInfo?.issueDate).format('DD/MMM/YYYY ')}
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                width="100%"
                pt={2}
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center">
                <Stack direction="column" spacing={0.5} alignItems="center">
                  <Stack>
                    <Typography>Max Capacity</Typography>
                  </Stack>
                  <Stack>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '25px' }}>
                      {machineInfo?.maxCapacity} KG
                    </Typography>
                  </Stack>
                </Stack>
                <Divider
                  orientation="vertical"
                  style={{ background: 'black', fontSize: '3px' }}
                  flexItem
                />
                <Stack direction="column" spacing={0.5} alignItems="center">
                  <Stack>
                    <Typography>Min Capacity</Typography>
                  </Stack>
                  <Stack>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '25px' }}>
                      {machineInfo?.minCapacity} KG
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={5} sm={12}>
            <Stack
              sx={{
                height: '100%',
                width: '100%',
              }}>
              <MachineStats
                sx={{
                  height: '100%',
                }}
                name={machineInfo.name}
              />
            </Stack>
          </Grid>
        </Grid>
      )}

      {/* timeline  */}
      <Stack mt={2}>
        <Stack
          width={{ md: '100%', xs: '98%' }}
          justifyContent="flex-start"
          sx={{
            height: 'auto',
            minHeight: 250,
            border: '2px solid rgba(0,0,0,0.2)',
            p: 2,
            borderRadius: 1.5,
          }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Timeline
          </Typography>
          <Stack my={1}>
            {machineInfo?.id ? (
              <MachineTimeLine
                machineName={name}
                uid={machineInfo?.id}></MachineTimeLine>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Stack>

      {/* new section  */}
      <Stack mt={4}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Logs
        </Typography>
        <Stack my={1}>
          <form onSubmit={handleLogSearchSubmit}>
            <Stack
              mt={2}
              direction={{ md: 'row', xs: 'column', sm: 'row' }}
              width="100%"
              spacing={2}
              alignItems="left"
              justifyContent="left">
              <Stack width={{ xs: '23%', md: '12%' }}>
                <FormControl fullWidth sx={{ maxWidth: 200 }}>
                  <InputLabel id="SearchStatus">Status</InputLabel>
                  <Select
                    size="small"
                    defaultValue={machineStatusTextField}
                    value={machineTypeTextField}
                    labelId="SearchStatus"
                    id="demo-simple-select"
                    label="Status"
                    placeholder="Status"
                    onChange={(event) => {
                      setMachineStatus(event.target.value);
                      setMachineTypeTextField(event.target.value);
                    }}>
                    <MenuItem value="IDLE">IDLE</MenuItem>
                    <MenuItem value="MAINTANENCE">MAINTANENCE</MenuItem>

                    <MenuItem value="OUT OF ORDER">OUT OF ORDER</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              {/* start date  */}
              <Stack width={{ xs: '23%', md: '15%' }}>
                {/* issue date picker  */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Start Date"
                    value={startdate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Stack>

              {/* end date  */}
              <Stack width={{ xs: '23%', md: '15%' }}>
                {/* issue date picker  */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Stack>

              <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
                <IconButton
                  disabled={!dateValid ? true : false}
                  title="Edit"
                  aria-label="edit"
                  type="submit"
                  sx={{
                    bgcolor: '#171820',
                    color: 'white',
                    borderRadius: 1,
                    ':hover': { bgcolor: '#303030' },
                  }}>
                  <SearchIcon />
                </IconButton>

                {/* reset button  */}
                <IconButton
                  title="Edit"
                  aria-label="edit"
                  onClick={() => resetMachineLogSearch()}
                  sx={{
                    bgcolor: '#171820',
                    color: 'white',
                    borderRadius: 1,
                    ':hover': { bgcolor: '#303030' },
                  }}>
                  <RestartAltIcon />
                </IconButton>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Stack>
      {machineLogs.length > 0 ? (
        <>
          {/* logs  */}
          <Stack>
            <Stack>
              <Stack
                ref={machineLogsListRef}
                spacing={0.5}
                height="30vh"
                style={{
                  overflow: 'auto',
                  overflowX: 'hidden',
                }}>
                <ViewportList
                  viewportRef={machineLogsListRef}
                  items={machineLogs}
                  overscan={5}>
                  {(item) => (
                    <>
                      <Grid
                        container
                        spacing={1}
                        px={1}
                        py={1}
                        borderRadius={1}
                        bgcolor="#f2f2f2">
                        <Grid item xs={2}>
                          <Typography
                            fontSize={{ xs: 10, md: 18 }}
                            fontWeight={800}>
                            {dayjs(item.updatedAt).format('hh:mm A - DD, MMM')}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Chip
                            label={item.status}
                            sx={{
                              backgroundColor: setMachineStatusColor(
                                item.status,
                              ),
                              color: '#ffff',
                            }}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            fontSize={{ xs: 10, md: 18 }}
                            fontWeight={800}>
                            {item?.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            fontSize={{ xs: 10, md: 14 }}
                            fontWeight={800}>
                            {item.message}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </ViewportList>
              </Stack>
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <Stack
            width="100%"
            alignItems="center"
            height="30vh"
            justifyContent="center">
            <Pageview sx={{ width: 120, height: 120 }} />
            <Typography fontSize={18} fontWeight={800}>
              No logs
            </Typography>
            <Typography maxWidth={450}>No logs to show</Typography>
          </Stack>
        </>
      )}

      <EditMachine
        toggleModal={setShowEditMachineForm}
        show={showEditMachineForm}
        machine={machineInfo}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}
      />
    </Stack>
  );
};

export default MachineDetails;
