import {
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { fetchAllQuotations } from '../../api/client';
import { AllQuotationApprovalListColumns } from '../../constants/table-column/client';
import QuotationApprovalModal from './QuotationApprovalModal';
import NoDataOverlay from '../global/NoDataOverlay';

const QuotationApproval = ({ isUpdated, setIsUpdated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [quotationList, setQuotationList] = useState([]);
  const [quotationPage, setQuotationPage] = useState(0);
  const [quotationPageSize, setQuotationPageSize] = useState(10);

  const [quotationTotalEntries, setQuotationTotalEntires] = useState(0);

  const [quotationId, setQuotationId] = useState('');
  const quotationTextInput = React.useRef(null);
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [requestData, setRequestData] = useState({});

  const fetchQuotation = async (
    quotationId,
    quotationPage,
    quotationPageSize,
  ) => {
    setIsLoading(true);

    const response = await fetchAllQuotations({
      searchKey: quotationId,
      page: quotationPage,
      pageSize: quotationPageSize,
    });
    setQuotationList(response.quotations);
    setQuotationTotalEntires(response.total);
    setIsLoading(false);
  };

  const resetQuotation = () => {
    fetchQuotation('', quotationPage, quotationPageSize);
    quotationTextInput.current.value = '';
    setQuotationId('');
  };

  //quation search submit
  const handleQuotationSubmit = (event) => {
    event.preventDefault();
    setQuotationId(quotationId);
    fetchQuotation(
      quotationId,

      quotationPage,
      quotationPageSize,
    );
  };

  const handleEditModal = (value) => {
    setRequestData(value);
    setShowQuotationModal(true);
  };

  useEffect(() => {
    (async () =>
      await fetchQuotation(quotationId, quotationPage, quotationPageSize))();
  }, [isUpdated, quotationPage, quotationPageSize]);

  useEffect(() => {
    (async () => {
      await fetchQuotation();
    })();
  }, []);
  return (
    <Stack>
      <Stack>
        <Typography fontSize={25} sx={{ fontWeight: '500' }} my={1}>
          Quotations
        </Typography>
        {/* Quotation search section  */}
        <Stack my={1} width="100%">
          <Grid container width="100%">
            <Grid item xs={12} sm={7} md={8}>
              <form onSubmit={handleQuotationSubmit}>
                <Stack
                  direction={{ md: 'row', xs: 'column', sm: 'row' }}
                  width="100%"
                  spacing={2}
                  alignItems="left"
                  justifyContent="left">
                  <TextField
                    size="small"
                    placeholder="Quotation no."
                    onChange={(event) => setQuotationId(event.target.value)}
                    inputRef={quotationTextInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    flexWrap="wrap"
                    fullWidth
                    id="outlined-basic"
                    label="Search"
                    sx={{ maxWidth: 250 }}
                    variant="outlined"
                  />

                  {/* Search button  */}
                  <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
                    <IconButton
                      title="Edit"
                      aria-label="edit"
                      type="submit"
                      sx={{
                        bgcolor: '#171820',
                        color: 'white',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#303030' },
                      }}>
                      <SearchIcon />
                    </IconButton>
                    {/* reset button  */}
                    <IconButton
                      title="Edit"
                      aria-label="edit"
                      onClick={() => resetQuotation()}
                      sx={{
                        bgcolor: '#171820',
                        color: 'white',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#303030' },
                      }}>
                      <RestartAltIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </form>
            </Grid>
            <Grid item xs={12} sm={5} md={4}></Grid>
          </Grid>
        </Stack>
        {/* Table section */}
        {!quotationList ? (
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Box
              pt={1}
              width="100%"
              height={450}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={AllQuotationApprovalListColumns({ handleEditModal })}
                rows={quotationList}
                rowCount={quotationTotalEntries}
                getRowId={(row) => row.uid}
                page={quotationPage}
                pageSize={quotationPageSize}
                rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                paginationMode="server"
                filterMode="server"
                onPageChange={(value) => setQuotationPage(value)}
                onPageSizeChange={(value) => setQuotationPageSize(value)}
                loading={isLoading}
                components={{
                  NoRowsOverlay: NoDataOverlay,
                }}
              />
            </Box>
          </>
        )}
      </Stack>
      <QuotationApprovalModal
        show={showQuotationModal}
        toggleModal={setShowQuotationModal}
        data={requestData}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}></QuotationApprovalModal>
    </Stack>
  );
};

export default QuotationApproval;
