import { Box, Grid, Stack, useTheme, Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';
import PageHeader from '../../widgets/PageHeader';
import ShiftManagement from '../../components/settings/ShiftManagement';
import ProcessManagement from '../../components/settings/ProcessManagement';
import UtilityManagement from '../../components/settings/UtilityManagement';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { SETTINGS } from '../../constants/routeUrls';
import NoticeManagement from '../../components/settings/NoticeManagement';
import {
  AddRoadTwoTone,
  AlarmTwoTone,
  DynamicFormTwoTone,
  SpatialAudioOffTwoTone,
} from '@mui/icons-material';

const Settings = ({ socket }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { tab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (tab) {
      setActiveTab(parseInt(tab));
    } else {
      setActiveTab(0);
    }
  }, [tab]);

  const tabs = [
    {
      Element: <ShiftManagement />,
    },
    {
      Element: <ProcessManagement />,
    },
    {
      Element: <UtilityManagement />,
    },
    {
      Element: <NoticeManagement socket={socket} />,
    },
  ];
  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
    navigate(SETTINGS + `/${tab}`);
  };

  const theme = useTheme();
  return (
    <Grid container width="100%" height="auto" minHeight="calc(100vh - 64px)">
      <Grid
        item
        xs={12}
        display={{ xs: 'block', md: 'none' }}
        minHeight="25vh"
        height="auto">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100%', px: 2, py: 3 }}>
          <PageHeader textTransform="capitalize" textAlign="center">
            Settings
          </PageHeader>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            orientation="horizontal">
            <Tab label="Shift" />
            <Tab label="Process" />
            <Tab label="Utility" />
            <Tab label="Notice" />
          </Tabs>
        </Stack>
      </Grid>
      <Grid
        item
        display={{ xs: 'none', md: 'block' }}
        md={1.7}
        bgcolor={theme.palette.mode === 'light' ? '#f3f3f3' : '#303030'}>
        <Stack
          sx={{
            px: 2,
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '100%',
          }}>
          <PageHeader
            textTransform="capitalize"
            textAlign="center"
            sx={{ mt: 2 }}>
            Settings
          </PageHeader>
          <Tabs
            sx={{ width: '100%' }}
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            orientation="vertical">
            <Tab
              label="Shift"
              icon={<AlarmTwoTone />}
              iconPosition="start"
              sx={{
                width: '100%',
                textTransform: 'capitalize',
                fontSize: 14,
                fontWeight: 700,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#4562f7',
                  transition: 'ease-in-out 500ms',
                },
              }}
            />
            <Tab
              label="Process"
              icon={<DynamicFormTwoTone />}
              iconPosition="start"
              sx={{
                width: '100%',
                textTransform: 'capitalize',
                fontSize: 14,
                fontWeight: 700,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#4562f7',
                  transition: 'ease-in-out 500ms',
                },
              }}
            />
            <Tab
              label="Utility"
              icon={<AddRoadTwoTone />}
              iconPosition="start"
              sx={{
                width: '100%',
                textTransform: 'capitalize',
                fontSize: 14,
                fontWeight: 700,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#4562f7',
                  transition: 'ease-in-out 500ms',
                },
              }}
            />
            <Tab
              label="Notice"
              icon={<SpatialAudioOffTwoTone />}
              iconPosition="start"
              sx={{
                width: '100%',
                textTransform: 'capitalize',
                fontSize: 14,
                fontWeight: 700,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                '&.Mui-selected': {
                  color: '#fff',
                  backgroundColor: '#4562f7',
                  transition: 'ease-in-out 500ms',
                },
              }}
            />
          </Tabs>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={10.3}
        height="auto"
        minHeight={{ xs: '75vh', md: 'calc(100vh - 64px)' }}>
        <Box
          px={5}
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          py={4}>
          {tabs[activeTab].Element}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Settings;
