import { DeleteTwoTone as Delete } from '@mui/icons-material';
import {
  Autocomplete,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';

const RegularChemcialForm = ({
  index,
  control,
  remove,
  recipes,
  register,
  options,
  setFormValue,
  liquorRatio,
  totalFabricWeight,
  errors,
}) => {
  const selectedChemical = useWatch({
    control,
    name: `recipe.${index}.chemical`,
  });

  const unit = useWatch({ control, name: `recipe.${index}.unit` });

  const [filterdOptions, setFilterdOptions] = useState(options);

  const calculateAndSetTotal = () => {
    if (unit === 'g/l') {
      const total =
        parseFloat(recipes?.[index]?.quantity) *
        liquorRatio *
        totalFabricWeight;

      setFormValue(`recipe.${index}.total`, total.toFixed(3), {
        shouldDirty: true,
        shouldTouch: true,
      });
    } else if (unit === 'percent') {
      const total =
        totalFabricWeight * parseFloat(recipes?.[index]?.totalChange) * 10;
      setFormValue(`recipe.${index}.total`, total.toFixed(3), {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const calculateAndSetTotalChange = () => {
    if (unit === 'percent') {
      setFormValue(
        `recipe.${index}.totalChange`,
        parseFloat(recipes?.[index]?.quantity) +
          (parseFloat(recipes?.[index]?.changeBy) || 0) * 0.01,
        { shouldDirty: true, shouldTouch: true },
      );
    }
  };

  useEffect(
    () => calculateAndSetTotalChange(),
    [unit, recipes?.[index]?.quantity, recipes?.[index]?.changeBy],
  );

  useEffect(
    () => calculateAndSetTotal(),
    [
      recipes?.[index]?.quantity,
      recipes?.[index]?.totalChnage,
      liquorRatio,
      unit,
    ],
  );

  useEffect(() => {
    //fileter out options which are not in recipes
    const filteredRecipeOptions = options.filter((option) => {
      const isOptionInRecipes = recipes?.find(
        (recipe) => recipe.chemical.id === option.id,
      );
      return !isOptionInRecipes;
    });
    setFilterdOptions(filteredRecipeOptions);
  }, []);

  const checkQuantityError = () => {
    const convertedTotal = recipes?.[index]?.total * 0.001;
    if (convertedTotal > selectedChemical?.totalQuantity) {
      return true;
    }
    return false;
  };

  return (
    <TableRow>
      <TableCell>
        <Autocomplete
          defaultValue={selectedChemical}
          options={filterdOptions}
          isOptionEqualToValue={(filterdOptions, value) =>
            filterdOptions.id === value.id
          }
          getOptionLabel={(filterdOptions) =>
            filterdOptions ? `${filterdOptions?.name}` : ''
          }
          onChange={(event, value) => {
            setFormValue(`recipe.${index}.chemical`, value, {
              shouldDirty: true,
              shouldTouch: true,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={recipes[index]?.isPrepared ? true : false}
              size="small"
              sx={{ width: 220 }}
              error={!!errors?.recipe?.[index]?.chemical}
              helperText={errors?.recipe?.[index]?.chemical?.message}
            />
          )}
        />
      </TableCell>
      <TableCell>{selectedChemical?.totalQuantity}</TableCell>
      {recipes?.[index]?.unit === 'g/l' ? (
        <TableCell>
          <TextField
            disabled={recipes[index]?.isPrepared ? true : false}
            size="small"
            type="number"
            sx={{ width: 100 }}
            {...register(`recipe.${index}.quantity`)}
            error={!!errors?.recipe?.[index]?.quantity || checkQuantityError()}
            helperText={
              errors?.recipe?.[index]?.quantity?.message || checkQuantityError()
                ? 'Quantity is greater than total quantity'
                : ''
            }
          />
        </TableCell>
      ) : (
        <TableCell>
          <Stack spacing={0.5}>
            <TextField
              size="small"
              type="number"
              placeholder="Quantity"
              {...register(`recipe.${index}.quantity`)}
              sx={{ width: 100 }}
              error={!!errors?.recipe?.[index]?.quantity}
              helperText={errors?.recipe?.[index]?.quantity?.message}
            />
            <TextField
              size="small"
              type="number"
              placeholder="Change by"
              {...register(`recipe.${index}.changeBy`)}
              sx={{ width: 100 }}
            />
            <TextField
              size="small"
              type="number"
              placeholder="Total change"
              {...register(`recipe.${index}.totalChange`)}
              disabled
              sx={{ width: 100 }}
            />
          </Stack>
        </TableCell>
      )}
      <TableCell>
        <Controller
          control={control}
          name={`recipe.${index}.unit`}
          defaultValue={recipes?.[index]?.unit}
          render={({ field }) => {
            return (
              <Select
                {...field}
                value={field.value}
                disabled={recipes[index]?.isPrepared ? true : false}
                size="small"
                sx={{ width: 100 }}>
                <MenuItem value="g/l">g/l</MenuItem>
                <MenuItem value="percent">%</MenuItem>
              </Select>
            );
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          size="small"
          disabled
          sx={{ width: 100 }}
          fullWidth
          value={recipes?.[index]?.total}
          onChange={calculateAndSetTotal}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          disabled={recipes[index]?.isPrepared ? true : false}
          size="small"
          fullWidth
          sx={{ width: 100 }}
          {...register(`recipe.${index}.addition`)}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => remove(index)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default RegularChemcialForm;
