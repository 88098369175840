import {
  Autocomplete,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../widgets/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PageHeader from '../../widgets/PageHeader';
import {
  fetchOrderFabricInformation,
  fetchSpecificBulkOrder,
  fetchSpecificShadeColor,
  updateBulkOrder,
  updateInQueueOrder,
} from '../../api/order';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrderUpdateValidator } from '../../validators/order';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CreateShadeColor from '../../components/order/CreateShadeColor';
import CreateFabricType from '../../components/order/CreateFabricType';
import { successNoitif } from '../../utils/notification';
import PageTitle from '../../utils/PageTitle';

const UpdateBulkOrder = () => {
  let navigate = useNavigate();

  const { id } = useParams();

  const [showAddShadeColorForm, setShowAddColorForm] = useState(false);
  const [showAddFabricTypeForm, setShowAddFabricTypeForm] = useState(false);

  const [shadeArray, setShadeArray] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [fabricList, setFabricList] = useState([]);
  const [shadeIndex, setShadeIndex] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fullFormData, setFullFormData] = useState({});
  const [initialOrderData, setInitialOrderData] = useState({});
  const [initialArrayLength, setInitialArrayLength] = useState(0);
  const [hasFabricAdded, setHasFabricAdded] = useState(false);

  const resolver = yupResolver(OrderUpdateValidator);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      salesPerson: initialOrderData.salesPerson,
      issueDate: initialOrderData.issueDate,
    },
    resolver,
  });

  //useArrayField
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'fabricInformation', // unique name for your Field Array
  });

  const deliveryDate = useWatch({ control, name: 'deliveryDate' });
  const issueDate = useWatch({ control, name: 'issueDate' });
  const clientContactPerson = useWatch({
    control,
    name: 'clientContactPerson',
  });
  const clientContactNumber = useWatch({
    control,
    name: 'clientContactNumber',
  });
  const clientReference = useWatch({
    control,
    name: 'clientReference',
  });
  const deliveryAddress = useWatch({
    control,
    name: 'deliveryAddress',
  });
  const remarks = useWatch({
    control,
    name: 'remarks',
  });
  const fabricInformation = useWatch({ control, name: 'fabricInformation' });

  const salesPerson = useWatch({ control, name: 'salesPerson' });

  const fetchInitialOrder = async () => {
    const response = await fetchSpecificBulkOrder({ fcNumber: id });

    setInitialOrderData(response.order);

    if (response.order) {
      //filling intial shadeArray values
      const tempArray = response?.order?.fabricInfo;
      let ArrayIndex = 0;
      setInitialArrayLength(tempArray.length);
      setTotalCount(tempArray.length - 1);
      setInitialArrayLength(tempArray.length);
      for (const element of tempArray) {
        const colourList = await fetchSpecificShadeColor({
          shadeId: element?.shadeGroupId,
          clientId: response?.order?.clientUid,
        });
        const tempObject = {
          shadeName: element?.shadeGroupName,
          shadeRange: element?.shadeRange,
          colours: colourList.colours,
          selectedColor: {
            colourId: element?.colourId,
            colourName: element?.colourName,
          },
          processInfo: {
            rate: element?.rate,
          },
          quantity: element?.quantity,
          shadeGroupId: element?.shadeGroupId,
        };
        setShadeArray((oldArray) => [...oldArray, tempObject]);
        ArrayIndex = ArrayIndex + 1;
      }
    }
  };

  const fetchfabricList = async () => {
    const res = await fetchOrderFabricInformation();

    setFabricList(res.fabricInformation);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  //colse confrimation dialog
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    orderFormSubmit();
  };

  //async calling api to post form data
  const orderFormSubmit = async () => {
    let response = null;
    if (initialOrderData?.status == 'IN QUEUE') {
      response = await updateInQueueOrder(id, fullFormData);
    } else {
      response = await updateBulkOrder(id, fullFormData);
    }

    if (response) {
      successNoitif('Order has been Updated');
      window.location.reload();
    }
  };

  const validationCheck = () => {
    setIsSubmitted(true);
    let count = 1;

    shadeArray.map((element) => {
      if (
        element.shadeName &&
        element.quantity &&
        (element.selectedColor || element.shadeColor)
      ) {
        return;
      } else {
        count = 0;
      }
    });

    if (count == 1) {
      handleSubmit(handelFormSubmit)();
    }
  };

  const handelShadeName = (index, value) => {
    if (shadeArray.length <= index) {
      setShadeArray((oldArray) => [...oldArray, { shadeName: value }]);
    } else {
      let tempArray = JSON.parse(JSON.stringify(shadeArray));
      tempArray[index].shadeName = value;
      setShadeArray(tempArray);
    }
  };

  const handelShadeColor = async (index, value) => {
    if (shadeArray.length <= index) {
      setShadeArray((oldArray) => [...oldArray, { shadeColor: value }]);
    } else {
      let tempArray = JSON.parse(JSON.stringify(shadeArray));
      tempArray[index].shadeColor = value;
      setShadeArray(tempArray);
    }
  };

  const handelShadeColorSelection = (index, value) => {
    let tempArray = JSON.parse(JSON.stringify(shadeArray));
    tempArray[index].selectedColor = value;

    setShadeArray(tempArray);
  };

  const handelShadeRange = (index, newValue) => {
    let tempArray = JSON.parse(JSON.stringify(shadeArray));
    tempArray[index].shadeRange = newValue;
    setShadeArray(tempArray);
  };

  const handelQuantityChange = (index, newValue) => {
    const updateItem = shadeArray.map((item, i) => {
      if (i == index) {
        item.quantity = newValue;
      }
      return item;
    });
    setShadeArray(updateItem);
    if (shadeArray.length <= index) {
      let newShade = { quantity: 0 };
      newShade.quantity = newValue;
      setShadeArray((oldArray) => [...oldArray, newShade]);
    }
  };

  const handelRemove = (index) => {
    //decrement 1 from initialArrayLength

    setShadeArray([
      ...shadeArray.slice(0, index),
      ...shadeArray.slice(index + 1),
    ]);

    setTotalCount(totalCount - 1);
  };

  const handelAppend = () => {
    setTotalCount(totalCount + 1);
  };

  const handelFormSubmit = (data) => {
    let tempArray = [];

    for (let index = 0; index < shadeArray.length; index++) {
      let seletecFabricIndex = 0;
      for (let fabIndex = 0; fabIndex < fabricList.length; fabIndex++) {
        if (
          fabricList[fabIndex].fabricName ==
          data.fabricInformation[index].fabricTypeName
        ) {
          seletecFabricIndex = fabricList[fabIndex].fabricTypeId;
        }
      }

      let tempObject = {
        shadeGroupName: shadeArray[index].shadeName,
        shadeGroupId: shadeArray[index]?.shadeGroupId || '',
        colourName:
          initialArrayLength > index
            ? shadeArray[index]?.selectedColor?.colourName
            : shadeArray[index]?.shadeColor,
        colourId: shadeArray[index]?.selectedColor?.colourId || '',
        shadeRange: shadeArray[index].shadeRange,
        fabricTypeName: data.fabricInformation[index].fabricTypeName,
        fabricTypeId: seletecFabricIndex,
        processDescription: data.fabricInformation[index].processDescription,
        gsm: data.fabricInformation[index].gsm,
        dia: data.fabricInformation[index].dia,
        rate: data.fabricInformation[index].rate,
        quantity: shadeArray[index].quantity,
        labDipRef: data.fabricInformation[index].labDipRef,
        id: data.fabricInformation[index].id,
      };
      tempArray.push(tempObject);
    }

    const modifiedData = {
      clientUid: initialOrderData?.clientUid,
      fcNumber: initialOrderData.fcNumber,
      issueDate: data.issueDate,
      deliveryDate: data.deliveryDate,
      deliveryAddress: data?.deliveryAddress,
      clientRefNo: data?.clientReference,
      clientContactPerson: data?.clientContactPerson,
      clientContactPhone: data?.clientContactNumber,
      salesPerson: data?.salesPerson,
      remarks: data?.remarks,
      fabricInfo: tempArray,
      initialLength: initialArrayLength,
    };

    setFullFormData(modifiedData);
    setShowConfirmation(true);
  };

  useEffect(() => {
    (async () => {
      await fetchInitialOrder();
      await fetchfabricList();
    })();
  }, []);
  useEffect(() => {
    (async () => {
      await fetchfabricList();
    })();
  }, [hasFabricAdded]);

  //for prefilled data
  useEffect(() => {
    setValue('salesPerson', initialOrderData?.salesPerson);
    setValue('clientContactPerson', initialOrderData?.clientContactPerson);
    setValue('clientContactNumber', initialOrderData?.clientContactPhone);
    setValue('deliveryAddress', initialOrderData?.deliveryAddress);
    setValue('remarks', initialOrderData?.remarks);
    setValue('clientReference', initialOrderData?.clientRefNo);
    setValue('deliveryDate', initialOrderData?.deliveryDate);
    setValue('issueDate', initialOrderData?.issueDate);
    setValue('fabricInformation', initialOrderData?.fabricInfo);
  }, [initialOrderData]);

  useEffect(() => {
    let calTotalQuantity = 0;
    for (const element of shadeArray) {
      calTotalQuantity = calTotalQuantity + parseFloat(element?.quantity || 0);
    }
    setTotalQuantity(calTotalQuantity);
  }, [shadeArray]);

  const checkDisablity = (index) => {
    if (initialOrderData?.status == 'IN QUEUE' && initialArrayLength > index) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Stack
      spacing={2}
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <Stack width={{ md: '80%', sm: '100%' }} mx="auto">
        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-start"
          mt={1}
          mb={2}>
          <Button onClick={() => navigate(-1)} variant="contained">
            <ArrowBackIosIcon fontSize="15px" />
            <Typography fontSize="15px">Back</Typography>
          </Button>
        </Stack>
        <Divider></Divider>
        <PageHeader>Edit Order No. {id} </PageHeader>
      </Stack>
      <form>
        <Stack
          width={{ md: '80%', sm: '100%' }}
          mx="auto"
          direction="row"
          spacing={4}
          justifyContent="space-between"
          alignItems="flex-start">
          <Stack
            sx={{
              height: 'auto',
              minHeight: 200,
              width: { xs: '100%', md: '40%', sm: '60%' },
            }}>
            <Typography fontSize={20} sx={{ fontWeight: '500' }} my={1} mt={1}>
              Basic Order Information
            </Typography>
            {/* client Name  */}
            <Grid container spacing={2} direction="row" alignItems="center">
              {/* Client Name */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography fontSize={15}>
                    Client Name <sup style={{ color: 'red' }}>*</sup> :{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  disabled
                  sx={{ fontSize: '15px' }}
                  size="small"
                  value={initialOrderData?.clientName}
                />
              </Grid>
              {/* Fc Number */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    FC number <sup style={{ color: 'red' }}>*</sup> :{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  disabled
                  sx={{ fontSize: '15px' }}
                  size="small"
                  value={id}
                  {...register('fcNumber')}
                  error={errors?.fcNumber}
                  helperText={errors?.fcNumber?.message}
                />
              </Grid>
              {/* Sales Person Name */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Sales Person Name:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  sx={{ fontSize: '15px' }}
                  size="small"
                  value={salesPerson || initialOrderData.salesPerson}
                  onChange={(value) => setValue('salesPerson', value)}
                  {...register('salesPerson')}
                  error={errors?.salesPerson}
                  helperText={errors?.salesPerson?.message}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Issue Date <sup style={{ color: 'red' }}>*</sup> :{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    minDate={new Date()}
                    value={issueDate || initialOrderData.issueDate}
                    onChange={(value) => setValue('issueDate', value)}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        type="date"
                        {...params}
                        style={{ width: '100%' }}
                        error={errors?.issueDate}
                        helperText={errors?.issueDate?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              {/* Delivery Date */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Delivery Date <sup style={{ color: 'red' }}>*</sup> :{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    minDate={new Date()}
                    value={deliveryDate || initialOrderData.deliveryDate}
                    onChange={(value) => setValue('deliveryDate', value)}
                    renderInput={(params) => (
                      <TextField
                        sx={{ fontSize: '15px' }}
                        size="small"
                        type="date"
                        {...params}
                        style={{ width: '100%' }}
                        error={errors?.deliveryDate}
                        helperText={errors?.deliveryDate?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Stack>
          <Stack
            sx={{
              height: 'auto',
              minHeight: 200,
              width: { xs: '100%', md: '40%', sm: '60%' },
            }}>
            <Typography fontSize={20} sx={{ fontWeight: '500' }} my={1} mt={1}>
              Client Information
            </Typography>
            <Grid container spacing={2} direction="row" alignItems="center">
              {/* Client Reference */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Client Contact Person:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  sx={{ fontSize: '15px' }}
                  size="small"
                  value={
                    clientContactPerson || initialOrderData.clientContactPerson
                  }
                  onChange={(value) => setValue('clientContactPerson', value)}
                  {...register('clientContactPerson')}
                  error={errors?.clientContactPerson}
                  helperText={errors?.clientContactPerson?.message}
                />
              </Grid>
              {/* Client Reference */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Client Contact No:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  sx={{ fontSize: '15px' }}
                  size="small"
                  value={
                    clientContactNumber || initialOrderData.clientContactPhone
                  }
                  onChange={(value) => setValue('clientContactNumber', value)}
                  {...register('clientContactNumber')}
                  error={errors?.clientContactNumber}
                  helperText={errors?.clientContactNumber?.message}
                />
              </Grid>
              {/* Client Reference:*/}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Client Reference:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  sx={{ fontSize: '15px' }}
                  size="small"
                  value={clientReference || initialOrderData.clientRefNo}
                  onChange={(value) => setValue('clientReference', value)}
                  {...register('clientReference')}
                  error={errors?.clientReference}
                  helperText={errors?.clientReference?.message}
                />
              </Grid>
              {/* Delivery Address */}
              <Grid item xs={6} md={6}>
                <Stack>
                  <Typography sx={{ fontSize: '15px' }}>
                    Delivery Address:{' '}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  size="small"
                  sx={{ fontSize: '15px' }}
                  value={deliveryAddress || initialOrderData.deliveryAddress}
                  onChange={(value) => setValue('deliveryAddress', value)}
                  {...register('deliveryAddress')}
                  error={errors?.deliveryAddress}
                  helperText={errors?.deliveryAddress?.message}
                />
              </Grid>
            </Grid>
            {/* remarks  */}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              pb={3}
              mt={2}>
              <TextField
                style={{ width: '100%' }}
                multiline
                rows={5}
                placeholder="Remarks"
                value={remarks || initialOrderData.remarks}
                onChange={(value) => setValue('remarks', value)}
                {...register('remarks')}
                error={errors?.remarks}
                helperText={errors?.remarks?.message}
              />
            </Stack>
          </Stack>
        </Stack>
        {/* Fabric Information  */}
        <Stack>
          <Stack width={{ md: '80%', sm: '100%' }} mx="auto">
            <Typography fontSize={20} sx={{ fontWeight: '500' }} my={1}>
              Fabric Information
            </Typography>
          </Stack>
          <Divider></Divider>
          <TableContainer
            sx={{
              'td, th': { border: 0, padding: 1, margin: 1 },
            }}>
            <Table
              sx={{ minWidth: 650, 'td, th': { border: 0 }, border: 0 }}
              aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" sx={{ fontSize: '12px' }}>
                    #
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    SHADE GROUP <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    COLOR <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    SHADE % <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    FABRIC TYPE <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    GSM <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    DIA <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    PROCESS <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    RATE <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>

                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    QUANTITY (KG) <sup style={{ color: 'red' }}>*</sup>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: '12px' }}>
                    LAB DIP
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: '12px' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  tr: { borderTop: 0.5, borderBottom: 0.5 },
                  td: { borderTop: 0.5, borderBottom: 0.5 },
                }}>
                {fields.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell align="right" component="th" scope="row">
                      <Typography>{index + 1}</Typography>
                    </TableCell>

                    <TableCell align="center" width="130px">
                      <TextField
                        disabled={initialArrayLength > index ? true : false}
                        sx={{ fontSize: '15px' }}
                        size="small"
                        value={shadeArray[index]?.shadeName || ''}
                        onChange={(event) => {
                          handelShadeName(index, event.target.value);
                        }}
                      />
                    </TableCell>

                    {initialArrayLength > index ? (
                      <TableCell align="center" width="130px">
                        <Stack direction="row" spacing={1}>
                          <Autocomplete
                            disablePortal
                            disableClearable
                            disabled={checkDisablity(index)}
                            noOptionsText={
                              <Typography fontSize={12}>No Options</Typography>
                            }
                            id="combo-box-demo"
                            sx={{
                              '& .MuiAutocomplete-input, & .MuiInputLabel-root':
                                {
                                  fontSize: 12,
                                },
                            }}
                            value={{
                              colourName:
                                shadeArray[index]?.selectedColor?.colourName ||
                                '',
                            }}
                            options={shadeArray[index]?.colours || []}
                            onChange={(event, value) => {
                              handelShadeColorSelection(index, value);
                            }}
                            getOptionLabel={(option) => option.colourName || ''}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ fontSize: '12px', textAlign: 'left' }}
                                {...props}>
                                {option.colourName}
                              </Box>
                            )}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                sx={{ width: '120px' }}
                                {...params}
                                error={
                                  (shadeArray[index]?.selectedColor
                                    ?.colourName == '' ||
                                    typeof shadeArray[index]?.selectedColor
                                      ?.colourName == 'undefined') &&
                                  isSubmitted
                                }
                                helperText={
                                  (shadeArray[index]?.selectedColor
                                    ?.colourName == '' ||
                                    typeof shadeArray[index]?.selectedColor
                                      ?.colourName == 'undefined') &&
                                  isSubmitted
                                    ? 'invalid Color'
                                    : ''
                                }
                              />
                            )}
                          />
                          <IconButton
                            size="small"
                            title="Add"
                            aria-label="Add"
                            disabled={
                              checkDisablity(index) || !shadeArray[index]
                                ? true
                                : false
                            }
                            onClick={() => {
                              setShowAddColorForm(true);
                              setShadeIndex(index);
                            }}
                            sx={{
                              bgcolor: '#fff',
                              color: '#f44336',
                              borderRadius: 1,
                              ':hover': { bgcolor: '#f44336', color: '#fff' },
                            }}>
                            <AddIcon />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    ) : (
                      <TableCell align="left" width="130px">
                        <TextField
                          sx={{
                            fontSize: '15px',
                            width: '120px',
                            textAlign: 'left',
                            alignItems: 'left',
                          }}
                          size="small"
                          value={shadeArray[index]?.shadeColor || ''}
                          onChange={(event) => {
                            handelShadeColor(index, event.target.value);
                          }}
                          error={
                            (shadeArray[index]?.shadeColor == '' ||
                              typeof shadeArray[index]?.shadeColor ==
                                'undefined') &&
                            isSubmitted
                          }
                          helperText={
                            (shadeArray[index]?.shadeColor == '' ||
                              typeof shadeArray[index]?.shadeColor ==
                                'undefined') &&
                            isSubmitted
                              ? 'invalid Color'
                              : ''
                          }
                        />
                      </TableCell>
                    )}

                    <TableCell align="center" width="150px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        disabled={checkDisablity(index)}
                        value={shadeArray[index]?.shadeRange || ''}
                        onChange={(event) => {
                          handelShadeRange(index, event.target.value);
                        }}
                        error={errors?.fabricInformation?.[index]?.shadeRange}
                        helperText={
                          errors?.fabricInformation?.[index]?.shadeRange
                            ?.message
                        }
                      />
                    </TableCell>

                    <TableCell align="center" width="130px">
                      {fabricList ? (
                        <Stack direction="row" spacing={1} width="100%">
                          <Autocomplete
                            disablePortal
                            disableClearable
                            disabled={checkDisablity(index)}
                            noOptionsText={
                              <Typography fontSize={12}>No Options</Typography>
                            }
                            id="combo-box-demo"
                            sx={{
                              '& .MuiAutocomplete-input, & .MuiInputLabel-root':
                                {
                                  fontSize: 12,
                                },
                            }}
                            defaultValue={{
                              fabricName:
                                fabricInformation[index]?.fabricTypeName || '',
                            }}
                            options={fabricList}
                            getOptionLabel={(option) => option?.fabricName}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ fontSize: '12px', textAlign: 'left' }}
                                {...props}>
                                {option.fabricName}
                              </Box>
                            )}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                sx={{ width: '120px' }}
                                {...params}
                                {...register(
                                  `fabricInformation.${index}.fabricTypeName`,
                                )}
                              />
                            )}
                          />
                          <IconButton
                            size="small"
                            title="Add"
                            aria-label="Add"
                            disabled={checkDisablity(index)}
                            onClick={() => {
                              setShowAddFabricTypeForm(true);
                              setShadeIndex(index);
                            }}
                            sx={{
                              bgcolor: '#fff',
                              color: '#f44336',
                              borderRadius: 1,
                              ':hover': { bgcolor: '#f44336', color: '#fff' },
                            }}>
                            <AddIcon />
                          </IconButton>
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell align="center" width="110px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        disabled={checkDisablity(index)}
                        {...register(`fabricInformation.${index}.gsm`)}
                        error={errors?.fabricInformation?.[index]?.gsm}
                        helperText={
                          errors?.fabricInformation?.[index]?.gsm?.message
                        }
                      />
                    </TableCell>
                    <TableCell align="center" width="110px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        disabled={checkDisablity(index)}
                        {...register(`fabricInformation.${index}.dia`)}
                        error={errors?.fabricInformation?.[index]?.dia}
                        helperText={
                          errors?.fabricInformation?.[index]?.dia?.message
                        }
                      />
                    </TableCell>
                    <TableCell align="center" width="110px">
                      <TextField
                        // disabled
                        size="small"
                        disabled={checkDisablity(index)}
                        inputProps={{ style: { fontSize: 12 } }}
                        {...register(
                          `fabricInformation.${index}.processDescription`,
                        )}
                        error={
                          errors?.fabricInformation?.[index]?.processDescription
                        }
                        helperText={
                          errors?.fabricInformation?.[index]?.processDescription
                            ?.message
                        }
                      />
                    </TableCell>

                    <TableCell align="center" width="110px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        {...register(`fabricInformation.${index}.rate`)}
                        error={errors?.fabricInformation?.[index]?.rate}
                        helperText={
                          errors?.fabricInformation?.[index]?.rate?.message
                        }
                      />
                    </TableCell>

                    <TableCell align="center" width="150px">
                      <TextField
                        size="small"
                        value={shadeArray[index]?.quantity || 0}
                        inputProps={{ style: { fontSize: 12 } }}
                        onChange={(event) => {
                          handelQuantityChange(index, event.target.value);
                        }}
                        error={
                          (shadeArray[index]?.quantity &&
                            (isNaN(shadeArray[index]?.quantity) ||
                              parseInt(shadeArray[index]?.quantity) < 0)) ||
                          (isSubmitted &&
                            (isNaN(shadeArray[index]?.quantity) ||
                              parseInt(shadeArray[index]?.quantity) < 0 ||
                              shadeArray[index]?.quantity == ''))
                        }
                        helperText={
                          (shadeArray[index]?.quantity &&
                            (isNaN(shadeArray[index]?.quantity) ||
                              parseInt(shadeArray[index]?.quantity) < 0)) ||
                          (isSubmitted &&
                            (isNaN(shadeArray[index]?.quantity) ||
                              parseInt(shadeArray[index]?.quantity) < 0 ||
                              shadeArray[index]?.quantity == ''))
                            ? 'invalid quantity'
                            : ''
                        }
                      />
                    </TableCell>

                    <TableCell align="center" width="130px">
                      <TextField
                        size="small"
                        inputProps={{ style: { fontSize: 12 } }}
                        disabled={checkDisablity(index)}
                        {...register(`fabricInformation.${index}.labDipRef`)}
                        error={errors?.fabricInformation?.[index]?.labDipRef}
                        helperText={
                          errors?.fabricInformation?.[index]?.labDipRef?.message
                        }
                      />
                    </TableCell>

                    <TableCell align="left">
                      <IconButton
                        size="small"
                        title="Delete"
                        disabled={initialArrayLength > index ? true : false}
                        aria-label="Delete"
                        onClick={() => {
                          remove(index);
                          handelRemove(index);
                        }}
                        sx={{
                          bgcolor: '#fff',
                          color: '#f44336',
                          borderRadius: 1,
                          ':hover': { bgcolor: '#f44336', color: '#fff' },
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            width={{ md: '80%', sm: '100%' }}
            mx="auto"
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end">
            {/* add button  */}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              mt={2}>
              <Button
                fontSize="15px"
                type="button"
                variant="contained"
                disabled={shadeArray?.length == totalCount}
                onClick={() => {
                  append();
                  handelAppend();
                }}>
                <Typography fontSize="15px">Add</Typography>
              </Button>
            </Stack>
            <Stack
              direction="row"
              width="100%"
              spacing={3}
              justifyContent="flex-end"
              alignItems="center">
              <Typography fontSize={15} sx={{ fontWeight: '500' }}>
                Total Items: {totalCount + 1}
              </Typography>

              <Typography fontSize={15} sx={{ fontWeight: '500' }}>
                Total Quantity: {totalQuantity} KG
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </form>
      <Stack
        direction="row"
        width={{ md: '90%', sm: '100%' }}
        justifyContent="flex-end"
        alignItems="center"
        mt={2}>
        <Button
          variant="contained"
          disabled={shadeArray?.length == totalCount}
          onClick={() => {
            validationCheck();
          }}>
          <Typography fontSize="15px">Update</Typography>
        </Button>
      </Stack>
      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to update this order?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => handelConfrimationDecision()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
      <PageTitle title="Update Order" />
      <CreateShadeColor
        toggleModal={setShowAddColorForm}
        show={showAddShadeColorForm}
        shadeIndex={shadeIndex}
        shadeArray={shadeArray}
        setShadeArray={setShadeArray}
        selectedClient={{ uid: initialOrderData.clientUid }}></CreateShadeColor>
      {fabricList ? (
        <CreateFabricType
          toggleModal={setShowAddFabricTypeForm}
          show={showAddFabricTypeForm}
          setFabricList={setFabricList}
          fabricList={fabricList}
          hasFabricAdded={hasFabricAdded}
          setHasFabricAdded={setHasFabricAdded}></CreateFabricType>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default UpdateBulkOrder;
