import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { fetchClientChartData } from '../../api/client';
import { FiberManualRecordTwoTone } from '@mui/icons-material';

const ClientReportChart = () => {
  const [clientChartData, setClientChartData] = useState([]);
  const [stats, setStats] = useState({});

  const fetchData = async () => {
    const response = await fetchClientChartData();

    setStats(response.stats);
    setClientChartData(response.clientsReport);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const Stat = ({ label, content, bgcolor, dotColor, ...rest }) => {
    return (
      <Stack
        height="max-content"
        width="100%"
        maxWidth={200}
        border="2px solid rgba(0,0,0,0.2)"
        borderRadius={2}
        justifyContent="flex-start"
        alignItems="flex-start"
        bgcolor={bgcolor}
        {...rest}
        p={1}>
        <Stack direction="row" spacing={1}>
          <Typography fontSize={14} fontWeight={700}>
            {label}
          </Typography>
          <FiberManualRecordTwoTone sx={{ fill: dotColor || '#4562f7' }} />
        </Stack>
        <Typography fontWeight={800} fontSize={26} component="div">
          {content}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      width="100%"
      px={2}
      py={2}
      borderRadius={2}
      border="2px solid rgba(0,0,0,0.2)"
      height={370}
      spacing={1}>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Typography variant="h6" fontWeight={800}>
          Client Report
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Stat
          label="Total Clients"
          content={clientChartData.length}
          dotColor="#7FB77E"
        />
        <Stat
          label="Total Quotations"
          content={stats?.totalQuotations ? stats.totalQuotations : 0}
          dotColor="#7FB77E"
        />
        <Stat
          label="Total Orders"
          content={stats?.totalOrders ? stats.totalOrders : 0}
          dotColor="#7FB77E"
        />
      </Stack>
      <Box width="100%" height={200} mt={1}>
        {clientChartData.length > 0 ? (
          <ResponsiveBar
            data={clientChartData}
            keys={['quotationNo', 'orderNo']}
            indexBy="clientName"
            margin={{ top: 30, right: 150, bottom: 80, left: 10 }}
            padding={0.4}
            innerPadding={2}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#4562f7', '#7FB77E', '#F1A661', '#FA7070']}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 90,
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={null}
            enableGridY={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={function (e) {
              return (
                e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
              );
            }}
          />
        ) : (
          <Stack>
            <Typography>Ops! Something Went Wrong</Typography>
            <Typography maxWidth={350}>
              Sorry the production chart could not be loaded
            </Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default ClientReportChart;
