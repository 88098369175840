import { Checkbox, Divider, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '../../widgets/Button';
import EditOrderDelivery from './EditOrderDelivery';
import OrderDeliveryDetails from './OrderDeliveryDetails';

const OrderFabicDetails = ({
  fabric,
  setDeliveryOrder,
  parentCheck,
  SetParentCheck,
  index,
  setPerticularFabric,
  setNewDelivery,
}) => {
  const [inputQuantity, setInputQuantity] = useState(
    fabric?.fabricInfo?.finishedStock,
  );

  const [fabricInormation, setFabricInformation] = useState(fabric);
  const [batchInformation, setBatchInformation] = useState([]);
  const [change, setChange] = useState(0);
  const [editDelivery, setEditDelivery] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    let remaining = inputQuantity;
    let fabricInfo = [];
    let fabricBatches = JSON.parse(JSON.stringify(fabric.fabricBatches));
    fabricBatches.map((item) => {
      if (item.batchStatus === 'READY FOR DELIVERY') {
        fabricInfo.push(item);
      }
    });

    fabricInfo.map((fabric) => {
      fabric['batchDeliverable'] =
        fabric.processQuantity - fabric.deliveredQuantity;
    });

    fabricInfo.sort((a, b) => b.processQuantity - a.processQuantity);
    setChange(inputQuantity);

    fabricInfo.map((element) => {
      if (remaining != 0) {
        if (element.batchDeliverable >= remaining) {
          element.tempDeliverable = remaining;
          remaining = 0;
        } else if (element.batchDeliverable <= remaining) {
          remaining = remaining - element.batchDeliverable;
          element.tempDeliverable = element.batchDeliverable;
        }
      } else {
        element.tempDeliverable = 0;
      }
    });
    setBatchInformation(fabricInfo);
    updateNewDelivery(fabricInfo);
  };

  useEffect(() => {
    setFabricInformation((currentValue) => {
      return { ...currentValue, batchInfo: batchInformation };
    });
    updateNewDelivery(batchInformation);
  }, [batchInformation]);

  useEffect(() => {
    setPerticularFabric(fabricInormation);
  }, [fabricInormation]);

  const updateNewDelivery = (fabricInfo) => {
    if (fabricInfo.length != 0) {
      let modifiedOutput = {
        fabricId: fabricInormation?.fabricInfo?.fabricId,
        fabricName: fabricInormation?.fabricInfo?.fabricType,
        fabricRate: fabricInormation?.fabricInfo?.rate,
        batchInfo: fabricInfo,
      };
      setNewDelivery((prev) =>
        prev.filter(
          (fruit) => fruit.fabricId !== fabricInormation?.fabricInfo?.fabricId,
        ),
      );
      setNewDelivery((oldArray) => [...oldArray, modifiedOutput]);
    } else {
      setNewDelivery((prev) =>
        prev.filter(
          (fruit) => fruit.fabricId !== fabricInormation?.fabricInfo?.fabricId,
        ),
      );
    }
  };

  //for checkbox
  const handleCheckChange = () => {
    if (isChecked == true) {
      if (parentCheck - 1 < 1) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
        updateNewDelivery([]);
        SetParentCheck((currentValue) => {
          return currentValue - 1;
        });
      }
    } else {
      setIsChecked(true);
      updateNewDelivery(batchInformation);
      SetParentCheck((currentValue) => {
        return currentValue + 1;
      });
    }
  };

  const checking = () => {
    setIsChecked(true);
    SetParentCheck((currentValue) => {
      return currentValue + 1;
    });
  };

  useEffect(() => {
    if (index == 0) {
      checking();
    }
    setPerticularFabric(fabric);
  }, []);

  return (
    <Stack direction="row" sx={{ width: '100%' }}>
      <Stack pt={1.5} justifyContent="flex-start">
        <Checkbox
          checked={isChecked}
          onClick={handleCheckChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Stack>

      <Stack
        direction="column"
        key={fabric.fabricId}
        sx={{
          opacity: isChecked ? 1 : 0.5,
          pointerEvents: isChecked ? 'auto' : 'none',
          height: 'auto',
          minHeight: 220,
          width: '100%',
          maxWidth: { md: '100%', xs: '100%' },

          mb: 2,
          p: 2,
          border: '2px solid rgba(0,0,0,0.2)',
          borderRadius: 1.5,
        }}>
        <Stack direction="column">
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2 }}
            justifyContent="space-between">
            {/* fabric details  */}
            <Stack>
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold' }}
                fontSize={{ xs: 15, md: 18 }}>
                {fabric?.fabricInfo?.fabricType}
              </Typography>
              <Typography fontSize={15}>
                {fabric?.fabricInfo?.colour}
              </Typography>
              <Typography fontSize={15}>
                Shade Group: {fabric?.fabricInfo?.shadeGroup}
              </Typography>
              <Typography fontSize={15}>
                GSM: {fabric?.fabricInfo?.gsm}
              </Typography>
              <Typography fontSize={15}>
                DIA: {fabric?.fabricInfo?.dia}
              </Typography>
              <Typography fontSize={15}>
                Total Fabric Quantity: {fabric?.fabricInfo?.quantity} KG
              </Typography>
            </Stack>
            {/* delivery input  */}
            <Stack
              direction="column"
              alignItems={{ xs: 'flex-start', md: 'flex-end', sm: 'flex-end' }}>
              <Typography
                pb={0.3}
                fontSize={{ xs: 13, md: 16 }}
                sx={{ fontWeight: 'bold' }}>
                Finished Stock: {fabric?.fabricInfo?.finishedStock} KG
              </Typography>
              <form onSubmit={handleSubmit}>
                <Stack direction="row" spacing={1}>
                  <TextField
                    size="small"
                    defaultValue={fabric?.fabricInfo?.finishedStock}
                    onChange={(event) =>
                      setInputQuantity(parseInt(event.target.value))
                    }
                    flexWrap="wrap"
                    fullWidth
                    id="outlined-basic"
                    sx={{ maxWidth: 170 }}
                    variant="outlined"
                  />
                  <Button
                    disabled={
                      inputQuantity > fabric?.fabricInfo?.finishedStock ||
                      fabric?.fabricBatches?.length == 0
                        ? true
                        : false
                    }
                    variant="contained"
                    size="small"
                    type="submit">
                    <Typography
                      fontSize={{ xs: 'small', md: 12 }}
                      px={{ xs: 2 }}>
                      Generate
                    </Typography>
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Stack>
        </Stack>

        {fabric?.fabricBatches?.length == 0 ? (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Typography>No Batch Assigned yet</Typography>
          </Stack>
        ) : (
          <></>
        )}

        {/* batch wise quantity  */}
        {!batchInformation.length ? (
          <></>
        ) : (
          <Stack width="100%" justifyContent="center" py={1}>
            <Divider textAlign="left"></Divider>
            <Stack
              width="100%"
              justifyContent="center"
              alignItems="flex-end"
              pt={2}>
              <Button
                size="small"
                variant="contained"
                onClick={() => setEditDelivery(true)}>
                <Typography fontSize={{ xs: 'small', md: 12 }} px={{ xs: 2 }}>
                  {' '}
                  edit
                </Typography>
              </Button>

              <EditOrderDelivery
                toggleModal={setEditDelivery}
                show={editDelivery}
                batchInformation={batchInformation}
                setBatchInformation={setBatchInformation}
                change={change}
                inputQuantity={inputQuantity}
              />
            </Stack>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              pt={1}>
              <Stack width="100%">
                <Typography
                  align="left"
                  fontSize={{ md: '20', sm: '5' }}
                  sx={{ fontWeight: 'bold' }}>
                  Batch #
                </Typography>
              </Stack>
              <Stack width="100%">
                <Typography
                  align="left"
                  fontSize={{ md: '20', sm: '5' }}
                  sx={{ fontWeight: 'bold' }}>
                  Finished Stock (KG)
                </Typography>
              </Stack>

              <Stack
                justifyContent="flex-end"
                width="100%"
                alignItems="flex-end">
                <Typography
                  align="right"
                  fontSize={{ md: '20', sm: '5' }}
                  sx={{ fontWeight: 'bold' }}>
                  Deliver Quanity (KG)
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
        <Stack>
          {batchInformation?.map((batch, index) => (
            <Stack key={batch.batchId}>
              <Stack>{index == 0 ? <></> : <></>}</Stack>
              <Stack
                tack
                flexWrap="wrap"
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start">
                <OrderDeliveryDetails
                  batch={batch}
                  setDeliveryOrder={setDeliveryOrder}
                  inputQuantity={inputQuantity}
                  change={change}></OrderDeliveryDetails>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrderFabicDetails;
