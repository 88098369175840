import {
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import Tabs from '../../widgets/Tabs';
import Tab from '../../widgets/Tab';
import dayjs from 'dayjs';
import Button from '../../widgets/Button';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchOrderDetails } from '../../api/order';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  APPROVAL,
  CLIENT_DETAILS,
  EDIT_BULK_ORDER,
  EDIT_ORDER,
  ORDER,
  ORDER_DETAILS,
  // ORDER_DETAILS,
} from '../../constants/routeUrls';
import DeliveryTable from '../../components/order/DeliveryTable';
import { EditTwoTone } from '@mui/icons-material';
import FabricTab from '../../components/order/FabricTab';
import colors from '../../constants/colors';
import PageTitle from '../../utils/PageTitle';

const OrderDetails = () => {
  let navigate = useNavigate();
  const [orderInfo, setOrderInfo] = useState({});

  const [isQuantityUpdated, setIsQuantityUpdated] = useState(false);
  const [fabricsInfo, setFabricsInfo] = useState([]);
  const [clientInfo, setClientInfo] = useState({});
  const [statsInfo, setStatsInfo] = useState({});

  //taking the parameter value
  const { fcNumber } = useParams();

  const fetchDetails = async () => {
    const response = await fetchOrderDetails(fcNumber);
    if (response) {
      setOrderInfo(response.order);
      setFabricsInfo(response.fabrics);
      setClientInfo(response.client);
      setStatsInfo(response.stats);
    }
  };

  useEffect(() => {
    (async () => await fetchDetails())();
  }, [fcNumber, isQuantityUpdated]);

  const TotalFabricProcced = (array) => {
    let procced = 0;
    let totalQuantity = 0;
    array.map((item) => {
      totalQuantity = totalQuantity + item.fabricInfo.quantity;
      procced = procced + item.fabricInfo.processed;
    });
    const percentage = ((procced / totalQuantity) * 100).toFixed(2);
    return percentage;
  };

  const setBackgroundColor = (status) => {
    const statusColors = {
      'IN QUEUE': 'warning',
      'READY FOR DELIVERY': 'info',
      DELIVERED: 'success',
    };

    return statusColors[status] || 'default';
  };

  const Stat = ({ label, content, bgcolor, ...rest }) => {
    return (
      <Stack
        height={100}
        width="100%"
        border="2px solid rgba(0,0,0,0.2)"
        borderRadius={1}
        justifyContent="center"
        alignItems="center"
        bgcolor={bgcolor}
        {...rest}
        p={1}>
        <Typography
          color="#ffff"
          fontSize={12}
          fontWeight={700}
          textAlign="center">
          {label}
        </Typography>
        <Typography
          color="#ffff"
          fontWeight={800}
          fontSize={26}
          component="div">
          {content}
        </Typography>
      </Stack>
    );
  };

  const calcuateUnprocessed = () => {
    let unprocessed = 0;
    unprocessed =
      statsInfo.totalQuantity -
      (statsInfo.finsihedStock + statsInfo.deliveredQuantity);
    return unprocessed;
  };

  //for tab section
  const { tab: tabParams } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    setActiveTab(parseInt(tabParams));
  }, [tabParams]);
  const tabs = [
    {
      Element: (
        <FabricTab
          fabricsInfo={fabricsInfo}
          isQuantityUpdated={isQuantityUpdated}
          setIsQuantityUpdated={setIsQuantityUpdated}
        />
      ),
    },
    {
      Element: <DeliveryTable orderInfo={orderInfo} />,
    },
  ];

  const currentTab = tabs[activeTab];

  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
    navigate(`${ORDER_DETAILS}/${fcNumber}/${tab}`);
  };

  const location = useLocation();
  const approvalTab = location.state?.approvalTab;
  const clientUid = location.state?.clientUid;

  return (
    <Stack
      width="80%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      mx="auto"
      pt={3}>
      <PageTitle title="Order Details" />
      <Stack
        width="100%"
        direction="row"
        justifyContent="flex-start"
        mt={1}
        mb={2}>
        {approvalTab ? (
          <>
            <Button
              component={Link}
              to={`${APPROVAL}/${approvalTab}`}
              variant="contained">
              <ArrowBackIosIcon fontSize="15px" />
              <Typography fontSize="15px">Back</Typography>
            </Button>
          </>
        ) : (
          <>
            {clientUid ? (
              <Button
                component={Link}
                to={`${CLIENT_DETAILS}/${clientUid}`}
                variant="contained">
                <ArrowBackIosIcon fontSize="15px" />
                <Typography fontSize="15px">Back</Typography>
              </Button>
            ) : (
              <Button component={Link} to={`${ORDER}`} variant="contained">
                <ArrowBackIosIcon fontSize="15px" />
                <Typography fontSize="15px">Back</Typography>
              </Button>
            )}
          </>
        )}
      </Stack>
      <Divider></Divider>
      {!orderInfo?.fcNumber ? (
        <Stack
          justifyContent="center"
          width="100%"
          height="100%"
          alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Grid
          mt={2}
          container
          spacing={2}
          width="100%"
          justifyContent="flex-start">
          <Grid item xs={12} md={4.5}>
            <Stack
              width="100%"
              direction="column"
              justifyContent="space-between"
              alignItems="space-between"
              sx={{
                height: '100%',
                width: '100%',
                mx: 'auto',
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              <Stack direction="column">
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    justifyContent="flex-start"
                    alignItems="center">
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      FC # {orderInfo?.fcNumber}
                    </Typography>
                    {orderInfo?.isBulkOrder == true ? (
                      <IconButton
                        size="small"
                        title="Edit"
                        aria-label="edit"
                        disabled={
                          orderInfo?.status == 'NOT ASSIGNED' ||
                          orderInfo?.status == 'IN QUEUE'
                            ? false
                            : true
                        }
                        component={Link}
                        to={`${EDIT_BULK_ORDER}/${fcNumber}`}
                        sx={{
                          borderRadius: 1,
                          ':hover': { bgcolor: '#f7f7f7' },
                        }}>
                        <EditTwoTone />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        title="Edit"
                        aria-label="edit"
                        disabled={
                          orderInfo?.status == 'NOT ASSIGNED' ||
                          orderInfo?.status == 'IN QUEUE'
                            ? false
                            : true
                        }
                        component={Link}
                        to={`${EDIT_ORDER}/${fcNumber}`}
                        sx={{
                          borderRadius: 1,
                          ':hover': { bgcolor: '#f7f7f7' },
                        }}>
                        <EditTwoTone />
                      </IconButton>
                    )}
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <Chip
                      label={orderInfo?.status}
                      size="small"
                      sx={{ color: 'white' }}
                      color={setBackgroundColor(orderInfo?.status)}
                    />
                  </Stack>
                </Stack>

                {/* Showing order status  */}

                <Typography pt={0.5} fontSize={15}>
                  {dayjs(orderInfo?.issueDate).format('DD/MMM/YYYY ')}
                </Typography>

                <Typography noWrap>
                  {clientInfo?.partyName} ({clientInfo?.clientRefNo})
                </Typography>
                <Typography>{clientInfo?.clientContactPhone}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7.5}>
            <Stack
              sx={{
                height: '100%',
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              {/* showing party name  */}

              {/* CLIENT REF info  */}
              <Grid container spacing={0.5} direction="row" alignItems="center">
                <Grid item xs={6} md={4.5}>
                  <Stack>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Sales Person:
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={5}>
                  <Typography>{orderInfo?.salesPerson}</Typography>
                </Grid>
                <Grid item xs={6} md={4.5}>
                  <Stack>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Delivery Date:{' '}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={5}>
                  {orderInfo?.deliveryDate ? (
                    <Typography>
                      {dayjs(orderInfo?.deliveryDate).format('DD/MMM/YYYY ')}
                    </Typography>
                  ) : (
                    <Typography>Delivery Date: Invalid Date</Typography>
                  )}
                </Grid>
                <Grid item xs={6} md={4.5}>
                  <Stack>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Delivery Address:
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={5}>
                  {clientInfo?.deliveryAddress?.length > 20 ? (
                    <Tooltip title={clientInfo?.deliveryAddress}>
                      <Typography>
                        {clientInfo?.deliveryAddress.substr(0, 20)}...
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography>{clientInfo?.deliveryAddress}</Typography>
                  )}
                </Grid>
              </Grid>

              {/* CLIENT ADDRESS info  */}
            </Stack>
          </Grid>
          <Grid width="100%" item xs={12} md={12}>
            {orderInfo?.remarks?.length > 1 ? (
              <Stack
                sx={{
                  height: '100%',
                  border: '2px solid rgba(0,0,0,0.2)',
                  p: 2,
                  borderRadius: 1.5,
                  overflow: scroll,
                }}>
                <Grid
                  container
                  spacing={0.5}
                  direction="row"
                  alignItems="center">
                  <Grid item xs={12} md={12}>
                    <Stack>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Remarks:
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextareaAutosize
                      maxRows={4}
                      disabled
                      aria-label="maximum height"
                      placeholder="Maximum 4 rows"
                      defaultValue={orderInfo?.remarks}
                      style={{
                        resize: 'none',
                        width: '100%',
                        border: 0,
                        color: 'black',
                        background: 'white',
                        fontSize: '15px',
                        fontFamily: 'sans-serif',
                      }}
                    />
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Stat
                  label="Total Quantity"
                  content={statsInfo?.totalQuantity}
                  bgcolor={colors.secondary}
                />
              </Grid>
              <Grid item xs={2}>
                <Stat
                  label="Finished Stock"
                  content={statsInfo?.finsihedStock}
                  bgcolor={colors.warning}
                />
              </Grid>
              <Grid item xs={2}>
                <Stat
                  label="Deliverd Quantity"
                  content={statsInfo?.deliveredQuantity}
                  bgcolor={colors.info}
                />
              </Grid>
              <Grid item xs={2}>
                <Stat
                  label="Unprocessed"
                  content={calcuateUnprocessed()}
                  bgcolor={colors.error}
                />
              </Grid>
              <Grid item xs={2}>
                <Stat
                  label="Completion"
                  content={`${TotalFabricProcced(fabricsInfo)}%`}
                  bgcolor={colors.success}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* fabric table data  */}
      <Stack mt={4}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ width: 'max-content' }}>
          <Tab label="Fabrics" />
          <Tab
            label="Deliveries"
            disabled={orderInfo.isApproved ? false : true}
          />
        </Tabs>
      </Stack>
      <Stack width="100%" height={450}>
        {currentTab.Element}
      </Stack>
    </Stack>
  );
};

export default OrderDetails;
