import { Filter1TwoTone as FilterAlt } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import Button from '../../../widgets/Button';

const LiveTrackingFilterModule = ({
  show,
  onClose,
  filter,
  saveFilters,
  clearFilter,
  handleFilter,
  handleChange,
}) => {
  const handleOnChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const handleSaveAndFilter = () => {
    saveFilters();
    handleFilter();
    onClose();
  };

  const handleClearFiter = () => {
    clearFilter();
    handleFilter(true);
    onClose();
  };

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogContent>
        <Stack mt={2} spacing={2} width={320}>
          <Stack direction="row" spacing={2} alignItems="center">
            <FilterAlt />
            <Typography fontSize={18} fontWeight={700}>
              Filter{' '}
            </Typography>
          </Stack>
          <TextField
            onChange={handleOnChange}
            name="lotNumber"
            label="Lot number"
            value={filter?.['lotNumber']}
            placeholder="Search by lotn number"
            size="small"
          />
          <TextField
            onChange={handleOnChange}
            name="machineName"
            label="Machine Name"
            value={filter?.['machineName']}
            placeholder="Search by machine name"
            size="small"
          />
          <FormControl size="small">
            <InputLabel>Batch Phase</InputLabel>
            <Select
              defaultValue={filter?.['batchPhase']}
              onChange={handleOnChange}
              name="batchPhase"
              label="Batch Phase">
              <MenuItem value="DYEING">Dyeing</MenuItem>
              <MenuItem value="FINISHING">Finishing</MenuItem>
              <MenuItem value="QC">QC</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small">
            <InputLabel>Status</InputLabel>
            <Select
              defaultValue={filter?.['status']}
              onChange={handleOnChange}
              name="status"
              label="Status">
              <MenuItem value="ON HOLD">Halted</MenuItem>
              <MenuItem value="IS PAUSED">Paused</MenuItem>
              <MenuItem value="IN QUEUE">In Queue</MenuItem>
              <MenuItem value="STARTED">Started</MenuItem>
            </Select>
          </FormControl>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              onClick={handleClearFiter}
              variant="contained"
              size="small"
              color="error">
              Clear
            </Button>
            <Button
              onClick={handleSaveAndFilter}
              variant="contained"
              size="small">
              Save & Filter
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default LiveTrackingFilterModule;
