import {
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  Checkbox,
  DialogContent,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Button from '../../widgets/Button';
import TextField from '../../widgets/TextField';

const ConcentModal = ({
  show,
  onClose,
  submissionHandler,
  title,
  text,
  showReasonCaptureField,
  ...rest
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState('');

  const submit = async () => {
    setIsLoading(true);
    await submissionHandler(reason);
    setReason('');
    setConfirmed(false);
    onClose();
    setIsLoading(false);
  };

  useEffect(() => setConfirmed(false), []);

  const shouldDisableSubmission = () => {
    if (showReasonCaptureField && !reason) return true;
    if (!confirmed) return true;
    return false;
  };
  return (
    <Dialog open={show} onClose={onClose} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
        <Box height={20} />
        {showReasonCaptureField && (
          <TextField
            multiline
            fullWidth
            maxRows={4}
            value={reason}
            onChange={(event) => setReason(event.target.value)}
            label="Additional Remarks"
            error={!reason}
            helperText={reason ? '' : 'Must provide additional remarks'}
          />
        )}
        <Box height={20} />
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <Checkbox
            value={confirmed}
            onChange={(event) => setConfirmed(event.target.checked)}
            sx={{ px: 0, py: 0 }}
          />
          <Typography>
            By checking this box I here by declare that I have checked all the
            information and confirm my action. I also confirm that my
            information will be stored.
          </Typography>
        </Stack>
        <Stack
          mt={2}
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          width="100%">
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            disabled={shouldDisableSubmission()}
            loading={isLoading}
            onClick={submit}>
            Yes
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ConcentModal;
