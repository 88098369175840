import { AlarmTwoTone as Alarm } from '@mui/icons-material';
import { Box, Chip, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchTransactions } from '../../api/inventory';
import Button from '../../widgets/Button';

const TransactionList = () => {
  const { sku } = useParams();
  const [page, setPage] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchDetails = async () => {
    const response = await fetchTransactions(sku);
    setTransactions(response.transactions);
    setTotal(response.total);
  };

  const fetchDetailsWithPagination = async () => {
    const response = await fetchTransactions(sku, { page: page + 1 });
    setTransactions([...transactions, ...response.transactions]);
    setTotal(response.total);
  };

  useEffect(() => {
    (async () => await fetchDetails())();
  }, [sku]);

  const handlePageIncrease = async () => {
    if (transactions?.length <= total) {
      setPage((value) => value + 1);
      await fetchDetailsWithPagination();
    } else {
      setPage(page);
    }
  };
  return (
    <Box
      minWidth={400}
      mr={1}
      mb={2}
      px={2}
      py={2}
      borderRadius={2}
      border={1}
      borderColor="rgba(0,0,0,0.1)">
      <Stack spacing={1} height="43vh" overflow="auto">
        {transactions.map((transaction, index) => (
          <Stack key={transaction.id}>
            <Stack direction="row" spacing={2}>
              <Stack alignItems="center" spacing={0.5}>
                <Stack
                  px={0.5}
                  py={0.5}
                  width={30}
                  height={30}
                  borderRadius={200}
                  alignItems="center"
                  justifyContent="center">
                  <Alarm />
                </Stack>
                {index !== transaction?.length - 1 && (
                  <Box
                    width={4}
                    height={20}
                    bgcolor="#171820"
                    borderRadius={2000}
                  />
                )}
              </Stack>
              <Stack alignItems="flex-start" spacing={1} width="100%">
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  width="100%">
                  <Typography fontSize="0.85rem" fontWeight={700}>
                    {dayjs(transaction.createdAt).format(
                      'hh:mm A, DD, MMMM YYYY',
                    )}
                  </Typography>
                  <Chip label={transaction.type} size="small" />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Chip
                    label={`Amount: ${transaction.quantity}`}
                    size="small"
                  />
                  <Chip label={`Cost: ${transaction.cost}`} size="small" />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Stack alignItems="center">
        <Button
          size="small"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={total <= transactions?.length}
          onClick={handlePageIncrease}>
          Load More
        </Button>
      </Stack>
    </Box>
  );
};

export default TransactionList;
