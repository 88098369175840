import { Container, Divider, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BasicInfo from '../../components/batch/recipe/BasicInfo';
import FabricInfo from '../../components/batch/recipe/FabricInfo';
import NoChemicalRequired from '../../components/batch/recipe/NoChemicalRequired';
import { fetchBatchDetail } from '../../api/batch';
import { errorNoitif } from '../../utils/notification';
import { Link, useParams } from 'react-router-dom';
import TemplatingForm from '../../components/batch/recipe/TemplatingForm';
import { BATCH_DETAILS } from '../../constants/routeUrls';
import { ChevronLeftTwoTone as ChevronLeft } from '@mui/icons-material';
import Button from '../../widgets/Button';

const RecipeTemplating = () => {
  const { lotNumber } = useParams();

  const [batchDetail, setBatchDetail] = useState(null);
  const [chemicalsRequiredTasks, setChemicalRequiredTasks] = useState([]);

  const [liquorRatio, setLiquorRatio] = useState(7);

  // const [batchRecipeTemplates, setBatchRecipeTemplates] = useState([]);
  const [reloadRecipeTemplates, setReloadRecipeTemplates] = useState(false);

  const fetchBtach = async () => {
    const response = await fetchBatchDetail(lotNumber);
    if (response) {
      setBatchDetail(response);
      setLiquorRatio(response.liquorRatio || 7);
      return;
    }
    errorNoitif('Sorry could not load required information');
  };

  useEffect(() => {
    if (lotNumber) {
      (async () => await fetchBtach())();
    }
  }, [lotNumber, reloadRecipeTemplates]);

  const cleanRecipeDataForPreload = (taskUid) => {
    const recipeInfo = batchDetail?.recipeInfo?.find(
      (rcp) => rcp.taskUid === taskUid,
    );

    if (!recipeInfo) {
      return [];
    }

    return recipeInfo?.recipe?.map((item) => {
      // eslint-disable-next-line no-unused-vars
      const { id, ...rest } = item;
      return {
        ...rest,
        addition: parseFloat(item.addition),
        changeBy: parseFloat(item.changeBy),
        quantity: parseFloat(item.quantity),
        chemical: {
          id: item.chemicalId,
          name: item.chemicalName,
          type: item.chemicalType,
          totalQuantity: item?.totalQuantity,
        },
        dyeGroup: {
          id: item.dyeGroupId,
          dyeGroupName: item.dyeGroupName,
        },
        total: parseFloat(item.total),
        totalChange: parseFloat(item.totalChange),
        unit: item.unit,
      };
    });
  };

  const checkPrepartion = (taskUid) => {
    const recipeInfo = batchDetail?.recipeInfo?.find(
      (rcp) => rcp.taskUid === taskUid,
    );
    if (!recipeInfo) {
      return false;
    }
    return recipeInfo?.status;
  };

  const cleanDataForPreload = () => {
    let extractedData = [];
    batchDetail?.taskInfo?.tasks?.map((item) => {
      if (item.isRecipeRequired) {
        extractedData.push({
          taskUid: item.uid,
          taskName: item.taskName,
          batchId: batchDetail?.id,
          isColorTemplate: item.isColorTemplate,
          recipe: cleanRecipeDataForPreload(item.uid),
          isPrepare: checkPrepartion(item.uid),
        });
      }
    });
    setChemicalRequiredTasks(extractedData);
  };

  const preLoadData = () => {
    if (batchDetail) {
      if (batchDetail?.recipeInfo?.length > 0) {
        cleanDataForPreload();
      } else {
        let extractedData = [];
        batchDetail?.taskInfo?.tasks?.map((item) => {
          if (item.isRecipeRequired) {
            extractedData.push({
              taskUid: item.uid,
              taskName: item.taskName,
              batchId: batchDetail?.id,
              isColorTemplate: item.isColorTemplate,
              isPrepare: checkPrepartion(item.uid),
              recipe: [],
            });
          }
        });
        setChemicalRequiredTasks(extractedData);
      }
    }
  };

  useEffect(() => preLoadData(), [batchDetail]);

  return (
    <Container maxWidth="xxl">
      <Stack spacing={1} mt={2}>
        <Button
          variant="contained"
          startIcon={<ChevronLeft />}
          component={Link}
          to={`${BATCH_DETAILS}/${lotNumber}`}
          sx={{ width: 'max-content' }}>
          Back
        </Button>
        <Divider />
      </Stack>
      <Stack
        width="100%"
        height="auto"
        minHeight="calc(100vh - 120px)"
        py={3}
        spacing={2}>
        <Stack spacing={1} width="100%">
          <Stack direction="row" spacing={2} width="100%" height="25vh">
            <BasicInfo
              lotNumber={lotNumber}
              liquorRatio={liquorRatio}
              setLiquorRatio={setLiquorRatio}
              totalWeight={batchDetail?.totalFabricWeight}
            />
            <FabricInfo fabrics={batchDetail?.fabricInfo} />
          </Stack>
          {chemicalsRequiredTasks?.length > 0 ? (
            <TemplatingForm
              chemicalsRequiredTasks={chemicalsRequiredTasks}
              setChemicalRequiredTasks={setChemicalRequiredTasks}
              batchDetail={batchDetail}
              fetchBtach={fetchBtach}
              liquorRatio={liquorRatio}
              lotNumber={lotNumber}
              reloadRecipeTemplates={reloadRecipeTemplates}
              setReloadRecipeTemplates={setReloadRecipeTemplates}
            />
          ) : (
            <NoChemicalRequired lotNumber={lotNumber} />
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default RecipeTemplating;
