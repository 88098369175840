export default {
  primary: {
    600: '#fff',
    900: '#f2f2f2',
  },
  grey: { 300: '#d1d1d1' },
  white: '#fff',
  green: {
    300: '#2C7A7B',
  },
  loader: {
    teal: '#5DDADB',
    purple: '#3437A2',
    pink: '#F78EB6',
    bg: '#171923db',
  },
  scrollbar: {
    border: '#ffffff',
    thumb: {
      bg: '#e1e1e1',
    },
  },
  gradient: {
    blue: {
      300: '#4562f7',
      600: '#4562f7',
      900: '#4562f7',
    },
  },
  text: {
    grey: {
      300: '#303330',
      500: '#171820',
    },
  },
  timeline: {
    divider: {
      bg: '#718096',
    },
  },
};
