import { Divider, Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { fetchBatchBasicInfoStats } from '../../../api/batch';
import { fetchUtilities } from '../../../api/factory-utility';
import {
  utilityScaleIconMapper,
  utilityScaleNumberToStringMapper,
} from '../../../utils/factory-utility';
const BasicInfo = () => {
  const [time, setTime] = useState();
  const [stats, setStats] = useState(null);
  const [utilities, setUtilities] = useState([]);

  const fetchData = async () => {
    const response = await fetchBatchBasicInfoStats();
    return response;
  };

  const fetchUtilData = async () => {
    const utilityData = await fetchUtilities();
    setUtilities(utilityData);
  };

  useEffect(() => {
    (async () => {
      const response = await fetchData();
      await fetchUtilData();
      setStats(response);
    })();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await fetchData();
      await fetchUtilData();
      setStats((data) => ({ ...data, response }));
    }, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 45 * 1000);
    return () => clearInterval(interval);
  }, []);

  const SmallStat = ({ label, count }) => {
    return (
      <Stack sx={{ mr: 1, mb: 1, px: 2, textAlign: 'center' }}>
        <Typography fontSize={14} fontWeight={700}>
          {label}
        </Typography>
        <Typography fontSize={20} fontWeight={800}>
          {count}
        </Typography>
      </Stack>
    );
  };

  const UtilStat = ({ util }) => {
    return (
      <Tooltip title={utilityScaleNumberToStringMapper(util.scale)}>
        <Stack
          spacing={1}
          sx={{
            mr: 1,
            mb: 1,
            px: 2,
            textAlign: 'center',
            alignItems: 'center',
          }}>
          <Typography fontSize={12} fontWeight={700}>
            {util.name}
          </Typography>
          {utilityScaleIconMapper(util.scale)}
        </Stack>
      </Tooltip>
    );
  };
  return (
    <Stack
      width="100%"
      px={2}
      py={2}
      borderRadius={2}
      border="2px solid rgba(0,0,0,0.2)"
      maxHeight={300}
      height="100%"
      spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h5" fontWeight={800}>
            {dayjs(time).format('DD, MMM YYYY')}
          </Typography>
          <Typography fontSize={18} fontWeight={600}>
            {dayjs(time).format('hh:mm A')}
          </Typography>
        </Stack>
      </Stack>
      <Divider orientation="horizontal" />
      <Stack direction="row" flexWrap="wrap" justifyContent="center">
        <SmallStat label="Users" count={stats?.activeUserCount || 0} />
        <Box>
          <Divider orientation="vertical" />
        </Box>
        <SmallStat
          label="Mahines"
          count={`${stats?.machineCount?.inUseMachine || 0}/${
            stats?.machineCount?.allMachineCount || 0
          }`}
        />
        <Box>
          <Divider orientation="vertical" />
        </Box>
        <SmallStat label="Batches" count={`${stats?.batchCount || 0}`} />
      </Stack>
      <Divider orientation="horizontal" />
      <Stack direction="row" flexWrap="wrap" justifyContent="center">
        {utilities?.map((item, index) => (
          <>
            <UtilStat key={item.uid} util={item} />
            {index !== utilities?.length - 1 && (
              <Box key={item.uid + 1}>
                <Divider orientation="vertical" />
              </Box>
            )}
          </>
        ))}
      </Stack>
      <Divider orientation="horizontal" />
    </Stack>
  );
};

export default BasicInfo;
