import Request from './request';

export const createOrUpdateRecipe = async ({ recipeData, chemicalData }) => {
  const axiosv2 = new Request(2);

  const response = await axiosv2.Post('/recipes', { recipeData, chemicalData });
  if (response) {
    return response.msg;
  } else {
    return null;
  }
};
