import { CircularProgress, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';

import { createValidOrders } from '../../api/order';
import {
  AllValidListColumns,
  ErrorCSVListColumns,
} from '../../constants/table-column/order';
import { successNoitif } from '../../utils/notification';
import Button from '../../widgets/Button';
import NoDataOverlay from '../global/NoDataOverlay';

const CheckCsvOrders = ({
  validOrders,
  errorOrders,
  next,
  setUploadedOrders,
}) => {
  const handleGoBack = () => {
    next(0);
  };
  const [isLoading, setIsLoading] = useState(false);
  const submitValidOrders = async () => {
    setIsLoading(true);
    const response = await createValidOrders(validOrders);

    if (response) {
      successNoitif('Orders has been Created');
      setUploadedOrders(response.orders);
      next(2);
      setIsLoading(false);
    }
  };
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center">
      <Stack
        width="80%"
        heigh="100%"
        mx="auto"
        borderRadius={1.5}
        px={2}
        py={2.5}
        spacing={2.5}
        sx={{
          border: '2px solid rgba(0,0,0,0.2)',
        }}>
        <Typography variant="h5" fontWeight={800}>
          Check CSV File
        </Typography>
        <Stack
          mt={1}
          width="100%"
          direction="row"
          justifyContent="center"
          alignItems="center">
          {validOrders.length > 0 ? (
            <Stack width="100%" mt={1}>
              <Typography mb={1} fontWeight={800}>
                Valid Orders:
              </Typography>
              <Box width="100%" height={250}>
                <DataGrid
                  columns={AllValidListColumns}
                  rows={validOrders}
                  rowCount={validOrders.length}
                  getRowId={(row) => row.fcNumber}
                  components={{
                    NoRowsOverlay: NoDataOverlay,
                  }}
                />
              </Box>
            </Stack>
          ) : (
            <Stack
              my={3}
              width="100%"
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Typography color="red">The file has no valid orders</Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          mt={1}
          width="100%"
          direction="row"
          justifyContent="center"
          alignItems="center">
          {errorOrders.length > 0 ? (
            <Stack width="100%" mt={1}>
              <Typography mb={1} fontWeight={800}>
                Invalid Orders:
              </Typography>
              <Box width="100%" height={250}>
                <DataGrid
                  columns={ErrorCSVListColumns}
                  rows={errorOrders}
                  rowCount={errorOrders.length}
                  getRowId={(row) => row.fcNumber}
                  components={{
                    NoRowsOverlay: NoDataOverlay,
                  }}
                />
              </Box>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
        {isLoading ? (
          <Stack
            justifyContent="center"
            width="100%"
            height="20vh"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack
            direction="row"
            width="100%"
            justifyContent="flex-end"
            spacing={2}
            mt={3}>
            <Button onClick={handleGoBack} variant="outlined">
              Change or Update File
            </Button>
            <Button
              variant="contained"
              disabled={validOrders.length > 0 ? false : true}
              onClick={submitValidOrders}>
              confirm Upload
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default CheckCsvOrders;
