import colors from '../constants/colors';
export const setColor = (StateName) => {
  const Stats = {
    'Total Orders': colors.secondary,
    'In Queue': colors.warning,
    'Ready For Delivery': colors.info,
    Delivered: colors.success,
  };
  return Stats[StateName || 'Total Orders'];
};

export const reformateLotNumber = (lotNumber) => {
  const lotArray = lotNumber?.split('-');
  if (lotArray?.length > 1) {
    return lotNumber;
  }
  return lotArray[1];
};

export const setOrderStatusColor = (status) => {
  switch (status) {
    case 'IN QUEUE':
      return colors.warning;
    case 'READY FOR DELIVERY':
      return colors.info;
    case 'DELIVERED':
      return colors.success;
    case 'CANCELLED':
      return colors.error;
    default:
      return colors.info;
  }
};

export const setOrderDeliveryBillStatusColor = (status) => {
  switch (status) {
    case 'UNPAID':
      return colors.error;
    case 'PARTIALLY PAID':
      return colors.warning;
    case 'PAID':
      return colors.success;
    default:
      return colors.info;
  }
};

export const setFabricStatusColor = (status) => {
  switch (status) {
    case 'IN QUEUE':
      return colors.secondary;
    case 'ASSIGNED':
      return colors.warning;
    case 'DELIVERED':
      return colors.success;
    case 'PROCESSED':
      return colors.info;
  }
};

export const setDeliveryBackgroundColor = (status) => {
  switch (status) {
    case 'WAITING FOR APPROVAL':
      return colors.secondary;
    case 'IN QUEUE':
      return colors.warning;
    case 'DELIVERED':
      return colors.success;
    case 'REJECTED':
      return colors.error;
    default:
      return colors.info;
  }
};

export const samlpeData = [
  {
    OrderNo: '',
    WorkOrderNo: '',
    ClientName: '',
    ContactPerson: '',
    SalesTerm: '',
    WoItemSl: '',
    OrderDate: '',
    ClentsRef: '',
    CurrencyName: '',
    ConRate: '',
    FinishedGSM: '',
    FinishedDia: '',
    Shade: '',
    FabricType: '',
    DeliveryPlace: '',
    ShadeGroupName: '',
    OrderRemarks: '',
    LabdipNo: '',
    SalesPerson: '',
    CCSize: '',
    ProcessDescription: '',
    Quantity: '',
    Unit: '',
    SQTY: '',
    SUnit: '',
    Rate: '',
    Total: '',
  },
];
