import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { approveBatch } from '../../../api/batch';
import { successNoitif } from '../../../utils/notification';
import Button from '../../../widgets/Button';

const BatchApproveModal = ({
  show,
  onClose,
  validationFlags,
  update,
  socket,
}) => {
  const [isApprovalConfirmed, setIsApprovalConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { lotNumber } = useParams();

  const handleBatchApprove = async () => {
    setIsLoading(true);
    const response = await approveBatch(lotNumber);
    setIsLoading(false);
    if (!response) {
      return;
    }
    socket.emit('batch details', { lotNumber });
    update((batch) => ({ ...batch, status: 'IN QUEUE' }));
    onClose();
    successNoitif(response);
  };

  return (
    <>
      <Dialog open={show} onClose={onClose}>
        <DialogTitle>Approve Batch</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to approve this batch?</Typography>
          <Stack my={2} spacing={1}>
            <Stack
              px={2}
              py={2}
              direction="row"
              bgcolor="#e7e7e7"
              borderLeft={5}
              borderLeftColor={
                validationFlags?.isBatchTemplateCreated ? '#59CE8F' : '#FF4A4A'
              }>
              {validationFlags?.isBatchTemplateCreated
                ? 'Batch template added'
                : 'You have not configured batch template'}
            </Stack>
            <Stack
              px={2}
              py={2}
              direction="row"
              bgcolor="#e7e7e7"
              borderLeft={5}
              borderLeftColor={
                validationFlags?.isRecipeTemplateCreated ? '#59CE8F' : '#FF4A4A'
              }>
              {validationFlags?.isRecipeTemplateCreated
                ? 'Chemical recipe and requirements added'
                : 'You have not configured chemical recipe and requirements'}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Checkbox
              value={isApprovalConfirmed}
              onChange={(event) => setIsApprovalConfirmed(event.target.checked)}
            />
            <Typography>
              By checking this box I here by declare that I have checked all the
              information and approve this batch for production. I also confirm
              that my information will be stored as approver for this batch.
            </Typography>
          </Stack>
          <Stack
            mt={2}
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
            width="100%">
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              size="small"
              disabled={
                !isApprovalConfirmed ||
                !validationFlags?.isRecipeTemplateCreated
              }
              loading={isLoading}
              onClick={handleBatchApprove}>
              Yes
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BatchApproveModal;
