import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { reformateLotNumber } from '../../utils/order';
import { DeliveryUpdateValidator } from '../../validators/order';
import Button from '../../widgets/Button';

const EditOrderDelivery = ({
  show,
  toggleModal,
  batchInformation,
  change,
  setBatchInformation,
  inputQuantity,
}) => {
  const [editedBatchInformation, setEditedBatchInformation] =
    useState(batchInformation);
  const [tempValue, setTempValue] = useState([]);
  const [isMatched, setIsMatched] = useState(true);
  const resolver = yupResolver(DeliveryUpdateValidator);
  const onClose = () => {
    handleEditFormClose();
    setEditedBatchInformation(editedBatchInformation);
  };
  const handleEditFormClose = () => {
    toggleModal(false);
  };

  //react hook form and field array
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      tempDeliverable: 0,
    },
    resolver,
  });

  //field array
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'editedBatchInformation', // unique name for your Field Array
  });
  useEffect(() => {
    setValue('editedBatchInformation', batchInformation);
  }, [change, batchInformation]);

  const test = useWatch({ control, name: 'editedBatchInformation' });

  useEffect(() => {
    setTempValue(test);
    if (tempValue) {
      checkValidation(tempValue);
    }
  }, [test]);

  //for checking validation
  const checkValidation = (tempValue) => {
    let total = 0;
    for (const element of tempValue) {
      total = total + parseInt(element.tempDeliverable);
    }

    if (total == inputQuantity || tempValue.length == 0) {
      setIsMatched(true);
    } else {
      setIsMatched(false);
    }
  };

  //handel submission
  const handelConfrimationDecision = (data) => {
    toggleModal(false);
    setValue('editedBatchInformation', batchInformation);
    setBatchInformation(data.editedBatchInformation);
  };

  return (
    <>
      <Dialog
        onClose={handleEditFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">
          Edit Batch Delivery Quantity
        </DialogTitle>
        <Stack p={2}>
          {' '}
          {fields.map((field, index) => (
            <Stack key={field.id} py={2}>
              <Stack
                direction="row"
                spacing={3}
                justifyContent="space-between"
                alignItems="center"
                pb={1}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Batch #
                  {editedBatchInformation[index]?.lotNumber
                    ? reformateLotNumber(
                        editedBatchInformation[index]?.lotNumber,
                      )
                    : ''}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Finished Stock:{' '}
                  {editedBatchInformation[index]?.processQuantity -
                    editedBatchInformation[index]?.deliveredQuantity}{' '}
                  KG
                </Typography>
              </Stack>

              <Stack
                direction="row"
                spacing={3}
                justifyContent="space-between"
                alignItems="center"
                pb={1}>
                <Typography>Deliver Quantity(KG): </Typography>

                <Stack style={{ width: '35%' }}>
                  <TextField
                    InputProps={{
                      sx: {
                        '& input': {
                          textAlign: 'right',
                        },
                      },
                    }}
                    style={{ flex: 1 }}
                    size="small"
                    placeholder="Enter label to show for this form"
                    {...register(
                      `editedBatchInformation.${index}.tempDeliverable`,
                    )}
                    error={
                      errors?.editedBatchInformation?.[index]?.tempDeliverable
                    }
                    helperText={
                      errors?.editedBatchInformation?.[index]?.tempDeliverable
                        ?.message
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>

        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}>
          <Stack>
            <Typography color="red">
              {isMatched
                ? ''
                : `*Deliver quantity should match ${inputQuantity} KG`}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red' }}
              color="error"
              onClick={handleEditFormClose}>
              Cancel
            </Button>
            <Tooltip
              title={
                isMatched
                  ? ''
                  : `Deliver quantity should match ${inputQuantity} KG`
              }>
              <Stack>
                <Button
                  variant="contained"
                  disabled={!isMatched ? true : false}
                  onClick={handleSubmit(handelConfrimationDecision)}>
                  Save
                </Button>
              </Stack>
            </Tooltip>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default EditOrderDelivery;
