import { Autocomplete, Box, Checkbox, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { roles } from '../../constants/constants';
import Dialog from '../../widgets/Dialog';
import TextField from '../../widgets/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBoxTwoTone';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserCreationSchema } from '../../validators/user';
import { createNewUser, verifyOtp } from '../../api/users';
import checkPermission from '../../utils/checkPermission';
import { ADD_USER } from '../../constants/permissionMapping';
import {
  errorNoitif,
  successNoitif,
  warningNoitif,
} from '../../utils/notification';
import ReactPinField from 'react-pin-field';
import Countdown from 'react-countdown';
import Button from '../../widgets/Button';
import { useSelector } from 'react-redux';

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const AddNewUser = ({
  shifts,
  show,
  handleClose,
  activeShift,
  isUpdated,
  setIsUpdated,
}) => {
  const resolver = yupResolver(UserCreationSchema);

  const [showOtpForm, setShowOtpForm] = useState(false);
  const [tempInfo, setTempInfo] = useState(null);
  const [postData, setPostData] = useState(null);
  const [otpValid, setOtpValid] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    control,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      username: '',
      phone: '',
      password: '',
      roles: [],
      shift: '',
    },
    resolver,
  });

  const ResetForm = () => {
    reset({
      name: '',
      email: '',
      username: '',
      phone: '',
      password: '',
      roles: [],
      shift: '',
    });
  };

  const selectedRoles = useWatch({ control, name: 'roles' });

  const onClose = () => {
    reset({
      name: '',
      email: '',
      username: '',
      phone: '',
      password: '',
      roles: [],
      shift: '',
    });
    handleClose();
  };

  const checkifEmailRequired = () => {
    let emailRequired = false;
    const requiredEmialRoles = ['ADMIN', 'BM', 'MANAGER', 'SUPERVISOR'];

    selectedRoles.map((item) => {
      if (requiredEmialRoles.includes(item.value)) {
        emailRequired = true;
      }
    });

    return emailRequired;
  };

  const handleSave = (data) => {
    const {
      roles: selectedRoles,
      name,
      email,
      username,
      phone,
      password,
      shift,
    } = data;

    const emailRequired = checkifEmailRequired();
    const isPermitted = checkPermission(ADD_USER);

    if (!isPermitted) {
      warningNoitif('Your are not allowed to add new user');
    }
    if (emailRequired && !email) {
      setError('email', {
        type: 'required',
        message:
          'Email is required for admin, batch manager, manager & supervisor',
      });
    } else {
      const formatedRoles = selectedRoles.reduce((previous, current) => {
        if (previous?.length > 0) {
          return previous + ',' + current.value;
        } else {
          return current.value;
        }
      }, '');

      const insertData = {
        name,
        email,
        password,
        phone,
        username,
        shiftId: shift.id,
        roles: formatedRoles,
      };
      setPostData(insertData);
    }
  };

  const formSubmit = async () => {
    const response = await createNewUser(postData);

    if (response) {
      setTempInfo(response);
      handleClose();
      setPostData(null);
      setShowOtpForm(true);
      ResetForm();
      successNoitif('User has been created, please verify with otp');
    } else {
      setTempInfo(null);
      ResetForm();
      handleClose();
      setPostData(null);
      errorNoitif('Failed to create user');
    }
  };

  const handleOtpSubmission = async (otp) => {
    const response = await verifyOtp(
      user.username,
      tempInfo.created,
      otp,
      user.phone,
      tempInfo.sessionId,
      activeShift,
    );
    if (response) {
      // update(response);
      setIsUpdated(!isUpdated);
      setTempInfo(null);
      setShowOtpForm(false);
      successNoitif('User creation verified');
    } else {
      errorNoitif('Failed to verify user');
    }
  };

  return (
    <>
      <Dialog
        open={postData}
        onClose={() => setPostData(null)}
        title="Are you sure?"
        noHandler={() => setPostData(null)}
        yesHandler={formSubmit}>
        <Typography sx={{ mt: 2 }}>
          Are you sure you want to add this user?
        </Typography>
      </Dialog>
      <Dialog
        onClose={onClose}
        open={show}
        fullWidth
        maxWidth="sm"
        title="Add New User"
        yesText="Save Information"
        yesHandler={handleSubmit(handleSave)}
        noHandler={onClose}>
        <form>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              label="Name"
              required
              placeholder="Name of the user"
              {...register('name')}
              error={errors?.name}
              helperText={errors?.name?.message}
            />
            <TextField
              label="Username"
              required
              placeholder="Username - ie - name"
              {...register('username')}
              error={errors?.username}
              helperText={errors?.username?.message}
            />
            <TextField
              label="Email"
              placeholder="User email (optional)"
              {...register('email')}
              error={errors?.email}
              helperText={errors?.email?.message}
            />
            <TextField
              label="Phone"
              required
              placeholder="Phone number  - ie - 017***"
              {...register('phone')}
              error={errors?.phone}
              helperText={errors?.phone?.message}
            />
            <TextField
              label="Password"
              required
              placeholder="Password of the user"
              {...register('password')}
              error={errors?.password}
              helperText={errors?.password?.message}
            />
            <Autocomplete
              options={roles}
              multiple
              disableCloseOnSelect
              onChange={(event, value) =>
                setValue('roles', value, { shouldDirty: true })
              }
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Roles"
                  placeholder="Please select roles for this user"
                  error={errors?.roles}
                  helperText={errors?.roles?.message}
                />
              )}
            />
            <Autocomplete
              options={shifts}
              onChange={(event, value) => setValue('shift', value)}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Shift"
                  placeholder="Please select shift for this user"
                  error={errors?.shift}
                  helperText={errors?.shift?.message}
                />
              )}
            />
          </Stack>
        </form>
      </Dialog>
      <Dialog
        hideButtons={true}
        onClose={() => setShowOtpForm(false)}
        open={showOtpForm}
        title={
          <Typography variant="h6" fontWeight={700} textAlign="center">
            Please enter OTP sent to your verified <br /> Email to complete user
            creation
          </Typography>
        }>
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          spacing={2}>
          <Box>
            <ReactPinField
              className="pin-field"
              length={6}
              onComplete={handleOtpSubmission}
            />
          </Box>
          {otpValid ? (
            <Countdown
              zeroPadTime={2}
              date={Date.now() + 240000}
              onComplete={() => {
                setOtpValid(false);
                warningNoitif('OTP has expired');
              }}
              renderer={({ minutes, seconds }) => (
                <Stack spacing={2} direction="row">
                  <Typography mb={15} fontSize={16} fontWeight="bold">
                    Valid till
                  </Typography>
                  <Typography fontWeight="bold" ml={15} color="red">
                    {minutes}:{seconds === 0 ? '00' : seconds}
                  </Typography>
                </Stack>
              )}
            />
          ) : (
            <Button
              color="error"
              variant="contained"
              fullWidth
              onClick={() => {
                setShowOtpForm(false);
                setTempInfo(null);
              }}>
              Try Again
            </Button>
          )}
        </Stack>
      </Dialog>
    </>
  );
};

export default AddNewUser;
