import React, { useEffect } from 'react';
import MuiTextField from '../../widgets/TextField';
import Button from '../../widgets/Button';
import MachineItem from '../../components/machine/MachineItem';
import {
  Box,
  Stack,
  InputAdornment,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/SearchTwoTone';
import { useState } from 'react';
import { IconButton, Chip, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackTwoTone';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardTwoTone';
import SearchOffIcon from '@mui/icons-material/SearchOffTwoTone';

import { AddNewMachine } from '../../components/machine/AddNewMachine';
import { fetchAllMachine } from '../../api/machine';
import QrCodeTwoToneIcon from '@mui/icons-material/QrCodeTwoTone';
import RestartAltIcon from '@mui/icons-material/RestartAltTwoTone';
import { Link } from 'react-router-dom';
import { PRINT_MACHINEQR } from '../../constants/routeUrls';
import PermissionWrapper from '../wrapper/PermissionWrapper';
import PageTitle from '../../utils/PageTitle';
const Machine = () => {
  const [showAddMachineForm, setShowAddMachineForm] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [machineList, setMachineList] = useState([]);
  const [total, setToatl] = useState(0);
  const [page, setPage] = useState(1);
  const [machineName, setMachineName] = useState('');
  const [searchmachineType, setSearchMachineType] = useState('');
  const [searchMachineStatus, setSearchStatusMachine] = useState('');
  const textInput = React.useRef(null);
  const [isAdded, setIsAdded] = useState({});
  const [statusTextField, setStatusTextField] = useState(0);
  const [typeTextField, setTypeTextField] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [pagelessMachineList, setPagelessMachineList] = useState([]);
  //page count for pagination
  const getTotalPage = () => {
    if (total === 0) {
      return 1;
    } else {
      return Math.ceil(total / 10);
    }
  };

  //page changing
  const goPrevious = () =>
    setPage((currentValue) => {
      if (currentValue <= 0) {
        return 0;
      }
      return currentValue - 1;
    });

  const goNext = () =>
    setPage((currentValue) => {
      if (currentValue >= getTotalPage()) {
        return getTotalPage() - 1;
      }
      return currentValue + 1;
    });

  const fetchMachines = async (
    name,
    searchmachineType,
    searchMachineStatus,
  ) => {
    setIsloading(true);
    const response = await fetchAllMachine({
      searchKey: name,
      page,
      pageSize: 10,
      machineType: searchmachineType,
      status: searchMachineStatus,
      pageless: false,
    });
    setMachineList(response.machines);
    setToatl(response.total);
    setIsloading(false);
    if (response.machines.length == 0) {
      setIsNotFound(true);
    } else {
      setIsNotFound(false);
    }
  };

  const fectchPagelessMachines = async () => {
    const response = await fetchAllMachine({
      searchKey: machineName,
      machineType: searchmachineType,
      status: searchMachineStatus,
      pageless: true,
    });
    setPagelessMachineList(response.machines);
  };

  const resetAll = () => {
    setMachineName('');
    setSearchMachineType('');
    setSearchStatusMachine('');
    textInput.current.value = '';
    setStatusTextField(0);
    setTypeTextField(0);
  };
  useEffect(() => {
    (async () => await fetchMachines())();
  }, [page, isUpdated]);

  useEffect(() => {
    (async () => await fectchPagelessMachines())();
  }, [isAdded, isUpdated, machineName, searchmachineType, searchMachineStatus]);

  useEffect(() => {
    if (machineName?.length > 2 || machineName.length <= 0) {
      (async () =>
        await fetchMachines(
          machineName,
          searchmachineType,
          searchMachineStatus,
        ))();
    }

    setPage(1);
  }, [machineName, searchmachineType, searchMachineStatus]);

  return (
    <Box width="100%" px={2} py={3}>
      <PageTitle title="Machines" />
      <Stack
        direction="row"
        spacing={1}
        width="100%"
        sx={{ margin: 'auto' }}
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Machines
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button
            component={Link}
            to={`${PRINT_MACHINEQR}`}
            disabled={pagelessMachineList.length === 0}
            state={{ data: pagelessMachineList, title: 'Machines QR' }}
            variant="contained">
            <Stack direction="row" spacing={0.5}>
              <Typography fontSize="15px">Preview QR Codes</Typography>
              <QrCodeTwoToneIcon />
            </Stack>
          </Button>
          <PermissionWrapper acceptedRoles="MANAGER,ADMIN">
            <Button
              variant="contained"
              onClick={() => setShowAddMachineForm(true)}>
              Add
            </Button>
          </PermissionWrapper>
        </Stack>
      </Stack>
      <AddNewMachine
        toggleModal={setShowAddMachineForm}
        show={showAddMachineForm}
        update={setMachineList}
        isAdded={isAdded}
        setIsAdded={setIsAdded}
      />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        width="100%"
        sx={{ margin: 'auto', paddingTop: 2 }}
        justifyContent="space-between"
        alignItems="center">
        <Stack
          direction="row"
          width="100%"
          spacing={2}
          alignItems="center"
          justifyContent="left">
          <MuiTextField
            size="small"
            inputRef={textInput}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(event) => setMachineName(event.target.value)}
            flexWrap="wrap"
            fullWidth
            id="outlined-basic"
            label="Search"
            sx={{ maxWidth: 300 }}
            variant="outlined"
          />

          {/* for machine type search  */}

          <FormControl fullWidth sx={{ maxWidth: 200 }}>
            <InputLabel id="MachineType">Search Type</InputLabel>
            <Select
              size="small"
              defaultValue={statusTextField}
              value={typeTextField}
              onChange={(event) => {
                setSearchMachineType(event.target.value);
                setTypeTextField(event.target.value);
              }}
              labelId="MachineType"
              id="demo-simple-select"
              label="Search Type"
              placeholder="Search Type">
              <MenuItem value="DYEING">Dyeing </MenuItem>
              <MenuItem value="FINISHING">Finishing </MenuItem>
            </Select>
          </FormControl>

          {/* for machine status search  */}
          <FormControl fullWidth sx={{ maxWidth: 200 }}>
            <InputLabel id="MachineStatus">Search Status</InputLabel>
            <Select
              value={statusTextField}
              size="small"
              onChange={(event) => {
                setSearchStatusMachine(event.target.value);
                setStatusTextField(event.target.value);
              }}
              labelId="MachineStatus"
              id="demo-simple-select"
              label="Search Status"
              placeholder="Search Status">
              <MenuItem value="IDLE">Idle</MenuItem>
              <MenuItem value="MAINTANENCE">Maintanence</MenuItem>
              <MenuItem value="IN USE">In Use</MenuItem>
              <MenuItem value="OUT OF ORDER">Out of Order</MenuItem>
            </Select>
          </FormControl>

          {/* reset button  */}
          <IconButton
            title="Edit"
            aria-label="edit"
            onClick={() => resetAll()}
            sx={{
              bgcolor: 'black',
              color: 'white',
              borderRadius: 1,
              ':hover': { bgcolor: 'black' },
            }}>
            <RestartAltIcon />
          </IconButton>
        </Stack>
        {/* for pagination */}
        <Stack
          direction="row"
          spacing={0.2}
          alignItems="center"
          justifyContent="right"
          sx={{ paddingTop: 2 }}>
          <IconButton onClick={goPrevious} disabled={page <= 1}>
            <ArrowBackIcon />
          </IconButton>
          <Chip size="small" label={`Page ${page} / ${getTotalPage()}`} />
          <IconButton onClick={goNext} disabled={page >= getTotalPage()}>
            <ArrowForwardIcon />
          </IconButton>
        </Stack>
      </Stack>

      {isLoading ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="auto"
          minHeight="60vh"
          spacing={2}>
          <CircularProgress />
          <Typography
            fontSize={18}
            fontWeight={600}
            sx={{ maxWidth: 450, textAlign: 'center' }}>
            Please wait while we get machine information
          </Typography>
        </Stack>
      ) : (
        <>
          <Stack alignItems="left" justifyContent="center" mt={2} width="100%">
            <Stack
              flexWrap="wrap"
              direction="row"
              alignItems="center"
              justifyContent="flex-start">
              {machineList.map((machine) => (
                <MachineItem
                  key={machine.id}
                  machine={machine}
                  update={setMachineList}
                  isUpdated={isUpdated}
                  setIsUpdated={setIsUpdated}
                />
              ))}
            </Stack>
          </Stack>
        </>
      )}
      {isNotFound ? (
        <Stack
          justifyContent="center"
          width="100%"
          height="100%"
          alignItems="center"
          pt={3}>
          <SearchOffIcon size="large" sx={{ fontSize: 200 }}></SearchOffIcon>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Machine not found
          </Typography>
        </Stack>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Machine;
