import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchBatchesAssignedToMachine } from '../../api/batch';
import dayjs from 'dayjs';

const BatchesAssociatedWithMachine = ({ machine }) => {
  const [associateBatchList, setAssociateBatchList] = useState([]);
  // * fetch batches assigned to machine
  const fetchAssociateBatches = async (machineId) => {
    const response = await fetchBatchesAssignedToMachine(machineId);
    setAssociateBatchList(response);
  };

  useEffect(() => {
    (async () => await fetchAssociateBatches(machine.id))();
  }, [machine]);

  return (
    <Stack width="100%" spacing={1.5}>
      {associateBatchList?.length > 0 && (
        <Typography variant="h5" fontWeight={700}>
          {associateBatchList?.length} batches assigned to &quot;{machine.name}
          &quot;
        </Typography>
      )}
      <Stack width="100%" direction="row" flexWrap="wrap">
        {associateBatchList?.length > 0 ? (
          associateBatchList?.map((batch) => (
            <>
              <Stack
                key={batch.lotNumber}
                px={1}
                py={1}
                bgcolor="#f7f7f7"
                borderRadius={1}
                border={1}
                spacing={1.2}
                mr={1}
                mb={1}>
                <Typography variant="h6" fontWeight={700}>
                  Batch # {batch.lotNumber.split('-')[1]}
                </Typography>
                <Typography fontWeight={600} fontSize="0.8rem">
                  Party: {batch.partyName}
                </Typography>
                <Typography fontWeight={600} fontSize="0.8rem">
                  Weight: {batch.totalFabricWeight} Kg
                </Typography>
                <Typography fontWeight={600} fontSize="0.8rem">
                  Scheduled At:{' '}
                  {dayjs(batch.loadingTime).format('DD/MMM/YYYY hh:mm A')}
                </Typography>
                <Typography fontWeight={600} fontSize="0.8rem">
                  Loaded At:{' '}
                  {batch.actualStartTime
                    ? dayjs(batch.actualStartTime).format('DD/MMM/YYYY hh:mm A')
                    : 'Not Loaded'}
                </Typography>
              </Stack>
            </>
          ))
        ) : (
          <Stack>
            <Typography variant="h5" fontWeight={300}>
              No batches assigned to <br /> <b> &quot;{machine.name}&quot; </b>
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default BatchesAssociatedWithMachine;
