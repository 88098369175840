import * as yup from 'yup';
export const OrderSearchValidator = yup.object().shape({
  //   fc_number: yup.string().min(3),
  //   status: yup.string().min(3),
});

export const OrderColorCreationValidator = yup.object().shape({
  shadeColor: yup.string().required('Must enter color name'),
});

export const OrderCreationValidator = yup.object().shape({
  fcNumber: yup
    .number()
    .typeError('Must be a number')
    .test(
      'maxDigits',
      'Fc Number must be greater than 4 digits',
      (number) => String(number).length >= 4,
    )
    .max(999999, 'Fc Number must be less than 6 digits')
    .required('Must provide FC number'),
  ClientName: yup
    .string()
    .min(3, 'Must Select Clinet')
    .required('Must Select Clinet'),
  quotationName: yup
    .string()
    .min(3, 'Must Select Quotation')
    .required('Must Select Quotation'),
  issueDate: yup
    .date()
    .required('Must select Issue Date')
    .typeError('Must select Issue Date')
    .max(yup.ref('deliveryDate'), 'Issue Date can not exceed Delivery Date'),
  deliveryDate: yup
    .date()
    .required('Must select Delivery Date')
    .typeError('Must select Delivery Date'),

  fabricInformation: yup.array().of(
    yup.object().shape({
      gsm: yup
        .number()
        .typeError('Must be number')
        .min(0, 'GSM Must be Positive')
        .required('Must be greater than 0'),
      dia: yup
        .number()
        .typeError('Must be number')
        .min(0, 'DIA Must be Positive')
        .required('Must be greater than 0'),

      labDip: yup.string(),

      fabricType: yup
        .string()
        .min(3, 'Must Select Fabric Type')
        .required('Must Select Fabric Type'),
    }),
  ),
});

export const OrderUpdateValidator = yup.object().shape({
  issueDate: yup
    .date()
    .required('Must select Issue Date')
    .typeError('Must select Issue Date')
    .max(yup.ref('deliveryDate'), 'Issue Date can not exceed Delivery Date'),
  deliveryDate: yup
    .date()
    .required('Must select Delivery Date')
    .typeError('Must select Delivery Date'),
  fabricInformation: yup.array().of(
    yup.object().shape({
      gsm: yup
        .number()
        .typeError('Must be number')
        .min(0, 'GSM Must be Positive')
        .required('Must be greater than 0'),
      dia: yup
        .number()
        .typeError('Must be number')
        .min(0, 'DIA Must be Positive')
        .required('Must be greater than 0'),

      labDipRef: yup.string(),
    }),
  ),
});

export const OrderDeliveryValidator = yup.object().shape({
  partyName: yup.string().min(3).required('Must enter Party name'),
  deliveryContatct: yup.string().min(3).required('Must enter Delivery Contact'),
  deliveryContatctNumber: yup
    .string()
    .min(3)
    .required('Must enter Delivery Contact Number'),
  deliveryAddress: yup.string().min(3).required('Must enter Delivery Contact'),
  deliveryDate: yup.mixed().required('Must select Date for Delivery'),
});

export const DeliveryUpdateValidator = yup.object().shape({
  editedBatchInformation: yup.array().of(
    yup.object().shape({
      tempDeliverable: yup
        .number()
        .typeError('Must provide a number')
        .min(0, 'Quantity Must be Positive')
        .max(
          yup.ref('batchDeliverable'),
          'Can not exceed total Deliverable Quantity',
        )
        .required('Must be greater than 0'),
    }),
  ),
});

export const OrderTotalQuanityValidator = yup.object().shape({
  totalQuantity: yup
    .number()
    .typeError('Must provide a number')
    .min(0, 'Quantity Must be Positive'),
});

export const OrderTranscationAmountValidator = yup.object().shape({
  transactionAmount: yup
    .number()
    .typeError('Must provide numaric value')
    .min(1, 'Amount Must be Positive and greater than 0'),
  transactionStatus: yup
    .string()
    .min(3)
    .required('Must select transaction status'),
  transactionDate: yup
    .date()
    .required('Must select transaction date')
    .typeError('Must select transaction date'),

  chequeNo: yup.string().when('paymentBy', {
    is: 'CHEQUE',
    then: yup.string().required('Must enter cheque number'),
  }),

  bankName: yup.string().when('paymentBy', {
    is: 'CHEQUE',
    then: yup.string().required('Must enter bank name'),
  }),
  chequeDate: yup.date().when('paymentBy', {
    is: 'CHEQUE',
    then: yup
      .date()
      .required('Must select transaction date')
      .typeError('Must select transaction date'),
  }),
});

export const OrderTranscationEditAmountValidator = yup.object().shape({
  transactionAmount: yup
    .number()
    .typeError('Must provide numaric value')
    .min(0, 'Amount Must be Positive'),
});
