import { TextField } from '@mui/material';
import React from 'react';

const ValidationFields = ({ fieldType, register, index, errors }) => {
  switch (fieldType) {
    case 'TEXT':
      return (
        <>
          <TextField
            {...register(`formFields.${index}.min`)}
            label="Min"
            placeholder="Minimum length of the value (default: 0)"
            size="small"
            type="number"
            error={errors?.formFields?.[index]?.min}
            helperText={errors?.formFields?.[index]?.min?.message}
          />
          <TextField
            {...register(`formFields.${index}.max`)}
            label="Max"
            placeholder="Maximum length of the value (default: 80)"
            size="small"
            type="number"
            error={errors?.formFields?.[index]?.max}
            helperText={errors?.formFields?.[index]?.max?.message}
          />
        </>
      );
    case 'TEXTAREA':
      return (
        <>
          <TextField
            {...register(`formFields.${index}.min`)}
            label="Min"
            placeholder="Minimum length of the value (default: 0)"
            size="small"
            type="number"
            error={errors?.formFields?.[index]?.min}
            helperText={errors?.formFields?.[index]?.min?.message}
          />
          <TextField
            {...register(`formFields.${index}.max`)}
            label="Max"
            placeholder="Minimum length of the value (default: 140)"
            size="small"
            type="number"
            error={errors?.formFields?.[index]?.max}
            helperText={errors?.formFields?.[index]?.max?.message}
          />
        </>
      );
    case 'NUMBER':
      return (
        <>
          <TextField
            {...register(`formFields.${index}.min`)}
            label="Min"
            placeholder="Minimum value (default: 0)"
            size="small"
            type="number"
            error={errors?.formFields?.[index]?.min}
            helperText={errors?.formFields?.[index]?.min?.message}
          />
          <TextField
            {...register(`formFields.${index}.max`)}
            label="Max"
            placeholder="Maximum value"
            size="small"
            type="number"
            error={errors?.formFields?.[index]?.max}
            helperText={errors?.formFields?.[index]?.max?.message}
          />
        </>
      );
    case 'IMAGE':
      return null;
    case 'YES/NO':
      return null;
    case 'SELECT':
      return (
        <TextField
          sx={{ minWidth: 300 }}
          multiline
          {...register(`formFields.${index}.options`)}
          label="Options"
          placeholder="Enter options seperated by comma"
          size="small"
          error={errors?.formFields?.[index]?.options}
          helperText={errors?.formFields?.[index]?.options?.message}
        />
      );
    case 'LIST':
      return (
        <TextField
          sx={{ minWidth: 300 }}
          multiline
          {...register(`formFields.${index}.options`)}
          label="Options"
          placeholder="Enter options seperated by comma"
          size="small"
          error={errors?.formFields?.[index]?.options}
          helperText={errors?.formFields?.[index]?.options?.message}
        />
      );

    default:
      return null;
  }
};

export default ValidationFields;
