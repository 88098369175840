import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AddCircleTwoTone as Add,
  BiotechTwoTone as Biotech,
  CheckCircleTwoTone as CheckCircle,
  CloseTwoTone as Close,
  DeleteTwoTone as Delete,
  FormatPaintTwoTone as FormatPaint,
  WarningTwoTone as Warning,
  SaveTwoTone as Save,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Button from '../../widgets/Button';
import { useFieldArray, useForm, useWatch, Controller } from 'react-hook-form';
import ValidationFields from './ValidationFields';
import { warningNoitif } from '../../utils/notification';
import {
  convertCamelCaseToWord,
  convertWordToCamelCase,
} from '../../utils/word';
import { updateTaskForm } from '../../api/task-form';
import { successNoitif } from '../../utils/notification';
const UpdateForm = ({ toggleForm, formValue, open, update }) => {
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      taskName: '',
      taskType: 'DYEING',
      formFields: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formFields',
  });

  useEffect(() => {
    if (formValue && isInitialLoad) {
      const cleanedFormFields = formValue.formFields.map((field) => ({
        ...field,
        min: field.min || 0,
        max: field.max || 140,
        options: field.options || '',
        inputKeyName: convertCamelCaseToWord(field.inputKeyName),
      }));

      setValue('uid', formValue.uid);
      setValue('taskName', formValue.taskName);
      setValue('taskType', formValue.taskType);
      setValue('isColorTemplate', formValue.isColorTemplate);
      setValue('formFields', cleanedFormFields);
      setIsInitialLoad(false);
    }
  }, [formValue]);

  const handleFormFieldAppend = () => {
    append({
      label: '',
      fieldType: 'TEXT',
    });
  };
  const handleClose = () => {
    toggleForm(null);
  };

  const handleFormSubmit = async (data) => {
    if (!isDirty) {
      setShowConfirmation(false);
      warningNoitif('Nothing changed');
      return;
    }

    const cleanedFormFields = data.formFields.map((field) => ({
      ...field,
      min: field.min || 0,
      max: field.max || 140,
      options: field.options || '',
      inputKeyName: convertWordToCamelCase(field.inputKeyName),
    }));

    const response = await updateTaskForm({
      ...data,
      formFields: cleanedFormFields,
    });
    if (response) {
      successNoitif('Changes saved');
      reset({ taskName: '', taskType: 'DYEING', formFields: [] });
      update((forms) => {
        return forms.map((item) => (item.uid === data.uid ? response : item));
      });
      toggleForm(null);
    }
  };

  const formFields = useWatch({ control, name: 'formFields' });
  const watch = useWatch({ control });

  const getFieldType = (index) => {
    return formFields?.find((field, itemIndex) => itemIndex === index)
      ?.fieldType;
  };

  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handelProcessFormSubmission = () => {
    setShowConfirmation(true);
  };
  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogContent>
        <Stack
          width="80%"
          mx="auto"
          height="auto"
          spacing={2}
          bgcolor="#f7f7f7"
          px={2}
          py={2}
          borderRadius={1.5}
          boxShadow={1}
          sx={{ my: 2 }}>
          <Typography variant="h5" fontWeight={800}>
            Update Process
          </Typography>
          <Stack
            width="100%"
            direction="row"
            flexWrap="wrap"
            sx={{ my: 2 }}
            spacing={2}>
            <TextField
              size="small"
              fullWidth
              label="Process Name"
              placeholder="Please enter name of the process"
              sx={{ flex: 0.75 }}
              {...register('taskName')}
              error={errors?.taskName}
              helperText={errors?.taskName?.message}
            />
            <Controller
              control={control}
              name="taskType"
              defaultValue={formValue?.taskType}
              render={({ field }) => (
                <FormControl sx={{ flex: 0.25 }} error={errors?.taskType}>
                  <InputLabel>Process Type</InputLabel>
                  <Select
                    {...field}
                    defaultValue="DYEING"
                    label="Process Type"
                    size="small"
                    {...register('taskType')}>
                    <MenuItem value="DYEING">
                      <Stack direction="row" spacing={1.5}>
                        <FormatPaint />
                        <Typography fontSize="0.8rem" fontWeight={700}>
                          Dyeing
                        </Typography>
                      </Stack>
                    </MenuItem>
                    <MenuItem value="FINISHING">
                      <Stack direction="row" spacing={1.5}>
                        <CheckCircle />
                        <Typography fontSize="0.8rem" fontWeight={700}>
                          Finishing
                        </Typography>
                      </Stack>
                    </MenuItem>
                    <MenuItem value="QC">
                      <Stack direction="row" spacing={1.5}>
                        <Biotech />
                        <Typography fontSize="0.8rem" fontWeight={700}>
                          Quality Check
                        </Typography>
                      </Stack>
                    </MenuItem>
                  </Select>
                  {errors?.taskType && (
                    <FormHelperText>{errors?.taskType?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Stack>
          <Typography variant="h5" fontWeight={800}>
            Update Process Form Fields
          </Typography>

          {fields.map((field, index) => (
            <Stack
              width="100%"
              direction="column"
              flexWrap="wrap"
              key={field.id}
              spacing={1.5}
              alignItems="center"
              borderRadius={1.5}
              border={1}
              borderColor="grey.300"
              px={1}
              py={1}
              boxSizing="border-box">
              <Stack direction="row" width="100%" spacing={2}>
                <TextField
                  fullWidth
                  sx={{ flex: 1 }}
                  size="small"
                  label="Field Label"
                  placeholder="Enter label to show for this form"
                  {...register(`formFields.${index}.label`)}
                  error={errors?.formFields?.[index]?.label}
                  helperText={errors?.formFields?.[index]?.label?.message}
                />
                <TextField
                  fullWidth
                  sx={{ flex: 0.5 }}
                  size="small"
                  label="Field Identifier"
                  placeholder="Enter identifier for saving the input against"
                  {...register(`formFields.${index}.inputKeyName`)}
                  error={errors?.formFields?.[index]?.inputKeyName}
                  helperText={
                    errors?.formFields?.[index]?.inputKeyName?.message
                  }
                />
                <Controller
                  control={control}
                  name={`formFields.${index}.fieldType`}
                  render={({ field }) => (
                    <FormControl
                      sx={{ flex: 0.45 }}
                      error={errors?.formFields?.[index]?.fieldType}>
                      <InputLabel>Field Type</InputLabel>
                      <Select
                        {...field}
                        {...register(`formFields.${index}.fieldType`)}
                        label="Field Type"
                        size="small">
                        <MenuItem value="TEXT">Text</MenuItem>
                        <MenuItem value="TEXTAREA">Large Text</MenuItem>
                        <MenuItem value="NUMBER">Number</MenuItem>
                        <MenuItem value="IMAGE">Image</MenuItem>
                        <MenuItem value="YES/NO">Yes/No</MenuItem>
                        {/* <MenuItem value="SELECT">Select</MenuItem>
                        <MenuItem value="LIST">List</MenuItem> */}
                      </Select>
                      {errors?.formFields?.[index]?.fieldType && (
                        <FormHelperText>
                          {' '}
                          {errors?.formFields?.[index]?.fieldType?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                width="100%"
                spacing={2}
                alignItems="flex-start"
                justifyContent="space-between">
                <Stack direction="row" spacing={1.5}>
                  <ValidationFields
                    fieldType={getFieldType(index)}
                    register={register}
                    index={index}
                    errors={errors}
                  />
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>Required Field?</Typography>
                    <Switch
                      checked={formFields?.[index]?.isRequired}
                      value={formFields?.[index]?.isRequired}
                      onChange={(event) =>
                        setValue(
                          `formFields.${index}.isRequired`,
                          event.target.checked,
                          { shouldValidate: true, shouldDirty: true },
                        )
                      }
                    />
                  </Stack>
                  <IconButton
                    onClick={() => remove(index)}
                    sx={{
                      flex: 0.05,
                      height: 'max-content',
                      width: 'max-content',
                    }}>
                    <Delete />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          ))}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              variant="contained"
              endIcon={<Add />}
              sx={{ maxWidth: 'max-content' }}
              onClick={handleFormFieldAppend}>
              Add Field
            </Button>
            {errors?.formFields && (
              <Tooltip
                arrow
                placement="right"
                title="Every task must have atleast one form">
                <Warning color="warning" />
              </Tooltip>
            )}
          </Stack>
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}>
            <Typography fontSize="0.9rem" fontWeight={700}>
              Is Color Step?{' '}
            </Typography>
            <Switch
              value={watch.isColorTemplate}
              checked={watch.isColorTemplate}
              onChange={(event) =>
                setValue('isColorTemplate', event.target.checked, {
                  shouldDirty: true,
                  shouldTouch: true,
                })
              }
            />
            <Button
              variant="contained"
              onClick={handleClose}
              color="error"
              endIcon={<Close />}>
              Close
            </Button>
            <Button
              variant="contained"
              color="info"
              endIcon={<Save />}
              onClick={handleSubmit(handelProcessFormSubmission)}>
              Update & Save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <Stack>
        <Stack>
          <Dialog
            onClose={onCloseCongfirmation}
            open={showConfirmation}
            maxWidth="sm"
            title="Are you sure"
            yesText="Yes"
            noHandler={onCloseCongfirmation}>
            <DialogTitle id="simple-dialog-title">
              Are you sure you want to update this process?
            </DialogTitle>
            <Stack
              direction="row"
              gap={1}
              justifyContent="flex-end"
              sx={{ paddingRight: 2, paddingBottom: 2 }}>
              <Button
                variant="outlined"
                sx={{ borderColor: 'red', color: 'red' }}
                onClick={() => {
                  setShowConfirmation(false);
                }}>
                No
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit(handleFormSubmit)}>
                Yes
              </Button>
            </Stack>
          </Dialog>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default UpdateForm;
