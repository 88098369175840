import { Button, Grid, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef } from 'react';
import QRCode from 'react-qr-code';

import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import '../../styles/user-qr-print.css';
import { useSelector } from 'react-redux';
import { USERS } from '../../constants/routeUrls';

import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { userActions } from '../../store/reducers/userReducer';
import PageTitle from '../../utils/PageTitle';
const UserQRPrint = () => {
  let navigate = useNavigate();
  const userList = useSelector((state) => state.user.userData);

  useEffect(() => {
    return () => {
      dispatch(userActions.clearUserData());
    };
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const dispatch = useDispatch();
  const handelBack = () => {
    navigate(`${USERS}`);
  };
  useEffect(() => {
    if (!userList) {
      navigate(`${USERS}`);
    }
  }, []);

  return (
    <>
      <PageTitle title="User QR's" />
      <Stack mx="auto" width="1080px" my={3} px={2}>
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}>
          <Stack>
            <Button onClick={() => handelBack()} variant="contained">
              <ArrowBackIosIcon fontSize="15px" />
              <Typography fontSize="15px">Back</Typography>
            </Button>
          </Stack>
          <Button onClick={handlePrint} variant="contained">
            <Stack direction="row" spacing={0.5}>
              <Typography fontSize="15px">Print</Typography>
              <PrintIcon />
            </Stack>
          </Button>
        </Stack>
        <Divider></Divider>
        <Stack width="100%" direction="row" justifyContent="Center" my={2}>
          <Typography fontSize={25} sx={{ fontWeight: '500' }}>
            QR Code:
          </Typography>
        </Stack>
        <Box
          mx="auto"
          width="1080px"
          my={2}
          mt={3}
          ref={componentRef}
          boxSizing="border-box"
          padding="15px 30px"
          class="page">
          <Grid
            mt={3}
            className="qr-class"
            direction="row"
            container
            spacing={2}>
            {userList?.map((user) => (
              <Grid key={user.uid} item md={3}>
                <Stack
                  direction="column"
                  width="100%"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center">
                  <QRCode size={200} value={user.username} />
                  <Typography>{user.name}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </>
  );
};

export default UserQRPrint;
