import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  createBatchTaskTemplate,
  fetchBatchRecipeTemplate,
} from '../../../api/batch';
import { fetchAllChemicals, fetchAllDyeGroup } from '../../../api/inventory';
import { createOrUpdateRecipe } from '../../../api/recipes';
import { BATCH_DETAILS } from '../../../constants/routeUrls';
import { errorNoitif, successNoitif } from '../../../utils/notification';
import { RecipeCreateSchema } from '../../../validators/inventory';
import Button from '../../../widgets/Button';
import ColorChemicalForm from './ColorChemicalForm';
import NoChemicalAdded from './NoChemicalAdded';
import RegularChemcialForm from './RegularChemcialForm';

const TemplatingForm = ({
  chemicalsRequiredTasks,
  setChemicalRequiredTasks,
  batchDetail,
  fetchBtach,
  liquorRatio,
  lotNumber,
  reloadRecipeTemplates,
  setReloadRecipeTemplates,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [chemicalList, setChemicalList] = useState([]);
  const [dyeList, setDyeList] = useState([]);
  const [dyeGroupList, setDyeGroupList] = useState([]);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(0);
  const [saveTemplate, setSaveTemplate] = useState(false);
  const [filterdRecipeTemplates, setFilterdRecipeTemplates] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);

  const [showTemplatePreloadingModal, setShowTemplatePreloadingModal] =
    useState(false);

  const [templateInfo, setTemplateInfo] = useState({
    name: '',
  });

  const navigate = useNavigate();

  const toggleTemplateSave = () => {
    setSaveTemplate(!saveTemplate);
    setTemplateInfo({ name: '' });
  };

  const fetchTemplates = async () => {
    const response = await fetchBatchRecipeTemplate(
      chemicalsRequiredTasks[selectedTaskIndex].taskName,
    );
    if (response?.recipeTemplates) {
      setFilterdRecipeTemplates(response?.recipeTemplates);
    }
  };

  const handleTemplatePreloading = () => {
    setValue('recipe', selectedOption?.template);
    setShowTemplatePreloadingModal(false);
  };

  useEffect(() => {
    (async () => await fetchTemplates())();
  }, [chemicalsRequiredTasks[selectedTaskIndex].taskName]);

  const resolver = yupResolver(RecipeCreateSchema);

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver,
    defaultValues: {
      recipe: [],
    },
  });

  const {
    fields: recipeFields,
    append: recipeAppend,
    remove: recipeRemove,
  } = useFieldArray({ control, name: 'recipe' });

  const resetIndexedForm = () => {
    if (
      chemicalsRequiredTasks?.[selectedTaskIndex]?.recipe !== null ||
      chemicalsRequiredTasks?.[selectedTaskIndex]?.recipe !== undefined
    ) {
      setIsLoading(false);
      setValue('recipe', chemicalsRequiredTasks?.[selectedTaskIndex]?.recipe);
    } else {
      setIsLoading(true);
    }
  };
  useEffect(() => resetIndexedForm(), [selectedTaskIndex]);

  const recipes = useWatch({ control, name: 'recipe' });
  const fetchRecipeOptions = async () => {
    const chemicals = await fetchAllChemicals('Chemical', {
      pageSize: 150000000,
    });

    const dyes = await fetchAllChemicals('Dye', { pageSize: 150000000 });
    const dyeGroups = await fetchAllDyeGroup(null, true);

    setChemicalList(chemicals.chemicals);
    setDyeList(dyes.chemicals);

    setDyeGroupList(dyeGroups);
  };

  useEffect(() => {
    (async () => await fetchRecipeOptions())();
  }, []);

  // * Force loading
  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timeout);
  }, [selectedTaskIndex]);

  // * restructure data before form submission
  const cleanSubmissionData = (data) => {
    const batchTaskRecipeData = {
      taskUid: chemicalsRequiredTasks[selectedTaskIndex].taskUid,
      taskName: chemicalsRequiredTasks[selectedTaskIndex].taskName,
      lotNumber: batchDetail?.lotNumber,
      batchId: batchDetail?.id,
      liquorRatio,
    };

    const recipeInfoData = data?.recipe.map((item) => {
      // eslint-disable-next-line no-unused-vars
      const { id, ...rest } = item;
      return {
        ...rest,
        addition: parseFloat(item.addition),
        changeBy: parseFloat(item.changeBy),
        quantity: parseFloat(item.quantity),
        chemicalId: item.chemical?.id,
        chemicalName: item.chemical?.name,
        chemicalType: item.chemical.type,
        dyeGroupId: item.dyeGroup?.id,
        dyeGroupName: item.dyeGroup?.name,
        total: parseFloat(item.total),
        totalChange: parseFloat(item.totalChange),
        unit: item.unit,
      };
    });

    return {
      recipeData: batchTaskRecipeData,
      chemicalData: recipeInfoData,
    };
  };

  const handleNext = async (data) => {
    setValue('recipe', chemicalsRequiredTasks?.[selectedTaskIndex]?.recipe);
    setSelectedOption(null);
    if (chemicalsRequiredTasks[selectedTaskIndex]?.isPrepare == 'PREPARED') {
      if (selectedTaskIndex < chemicalsRequiredTasks?.length - 1) {
        setSelectedTaskIndex((index) => index + 1);
      } else {
        navigate(BATCH_DETAILS + `/${lotNumber}`);
      }
    } else {
      if (isDirty) {
        const { chemicalData, recipeData } = cleanSubmissionData(data);
        setIsLoading(true);
        const response = await createOrUpdateRecipe({
          recipeData,
          chemicalData,
        });
        setIsLoading(false);

        if (response) {
          setIsLoading(true);
          await fetchBtach();

          setChemicalRequiredTasks((infos) =>
            infos.map((item, index) =>
              index === selectedTaskIndex
                ? { ...item, recipe: data.recipe }
                : item,
            ),
          );
          if (saveTemplate) {
            toggleTemplateSave();
            const templateData = {
              name: templateInfo.name,
              template: data.recipe,
              taskName: chemicalsRequiredTasks[selectedTaskIndex].taskName,
            };
            const response = await createBatchTaskTemplate(templateData);
            if (response.status == '200') {
              successNoitif('Template saved successfully');
              setReloadRecipeTemplates(!reloadRecipeTemplates);
            } else {
              errorNoitif('Template  cannot be saved');
            }
          }
          if (selectedTaskIndex < chemicalsRequiredTasks?.length - 1) {
            setSelectedTaskIndex((index) => index + 1);
          } else {
            navigate(BATCH_DETAILS + `/${lotNumber}`);
          }
          successNoitif(response);
        }
      } else {
        setIsLoading(true);
        setChemicalRequiredTasks((infos) =>
          infos.map((item, index) =>
            index === selectedTaskIndex
              ? { ...item, recipe: data.recipe }
              : item,
          ),
        );

        if (selectedTaskIndex < chemicalsRequiredTasks?.length - 1) {
          setSelectedTaskIndex((index) => index + 1);
        } else {
          navigate(BATCH_DETAILS + `/${lotNumber}`);
        }
      }
    }
  };

  const handlePrevious = async (data) => {
    setValue('recipe', chemicalsRequiredTasks?.[selectedTaskIndex]?.recipe);
    setSelectedOption(null);
    setSaveTemplate(false);
    setTemplateInfo({ name: '' });
    if (isDirty) {
      const { chemicalData, recipeData } = cleanSubmissionData(data);
      setIsLoading(true);
      const response = await createOrUpdateRecipe({ recipeData, chemicalData });
      setIsLoading(false);

      if (response) {
        setIsLoading(true);
        await fetchBtach();
        setChemicalRequiredTasks((infos) =>
          infos.map((item, index) =>
            index === selectedTaskIndex
              ? { ...item, recipe: data.recipe }
              : item,
          ),
        );
        if (selectedTaskIndex > 0) {
          setSelectedTaskIndex((index) => index - 1);
        }
      }
    } else {
      setIsLoading(true);
      setChemicalRequiredTasks((infos) =>
        infos.map((item, index) =>
          index === selectedTaskIndex ? { ...item, recipe: data.recipe } : item,
        ),
      );
      if (selectedTaskIndex > 0) {
        setSelectedTaskIndex((index) => index - 1);
      }
    }
  };

  return (
    <Stack
      width="100%"
      border={1}
      borderRadius={2}
      borderColor="rgba(0,0,0,0.1)"
      px={2}
      py={2}
      spacing={1}>
      <Stack
        direction="row"
        width="100%"
        height="48vh"
        overflow="auto"
        spacing={1}>
        <Stack width={200} height="100%" borderRadius={2} spacing={1}>
          {chemicalsRequiredTasks.map((task, index) => (
            <Box
              key={task?.id}
              bgcolor={index === selectedTaskIndex ? '#4562f7' : '#f7f7f7'}
              color={index === selectedTaskIndex ? '#fff' : '#171820'}
              px={1}
              py={1}
              width="100%"
              borderRadius={1}>
              <Typography fontSize={18} fontWeight={700}>
                {task?.taskName}
              </Typography>
            </Box>
          ))}
        </Stack>
        <Divider orientation="vertical" />
        <Stack
          width="calc(100% - 260px)"
          maxWidth="calc(100% - 260px)"
          height="100%"
          borderRadius={2}
          spacing={1}
          overflow="auto">
          {isLoading ? (
            <Stack
              width="100%"
              height="50vh"
              alignItems="center"
              justifyContent="center">
              <CircularProgress />
            </Stack>
          ) : recipeFields?.length > 0 ? (
            <>
              <Stack
                my={1}
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="flex-end">
                <Box width="100%" maxWidth={300}>
                  <Autocomplete
                    options={filterdRecipeTemplates}
                    value={selectedOption}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) =>
                      `${option.name} 
                    `
                    }
                    onInputChange={async (event, query) => {
                      if (query.length > 2 || query.length <= 0) {
                        await fetchTemplates({ name: query });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label="Load from template"
                        size="small"
                        placeholder="You can load task information from template"
                      />
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        setSelectedOption(value);
                        setShowTemplatePreloadingModal(true);
                      }
                    }}
                  />
                </Box>
              </Stack>

              <Dialog
                open={showTemplatePreloadingModal}
                onClose={() => setShowTemplatePreloadingModal(false)}>
                <DialogContent>
                  <Stack px={1} py={2} spacing={2}>
                    <Typography fontSize={20} fontWeight={800}>
                      Are you sure?
                    </Typography>
                    <Typography>
                      Are your sure you want to load this template?
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-end">
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setSelectedOption(null);
                          setShowTemplatePreloadingModal(false);
                        }}>
                        No
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={handleTemplatePreloading}>
                        Yes
                      </Button>
                    </Stack>
                  </Stack>
                </DialogContent>
              </Dialog>

              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    {chemicalsRequiredTasks?.[selectedTaskIndex]
                      ?.isColorTemplate && <TableCell>Dye Group</TableCell>}
                    <TableCell>Chemical</TableCell>
                    <TableCell>Chemical Stock(KG)</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Addition</TableCell>
                    <TableCell>#</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recipeFields?.map((recipe, index) =>
                    chemicalsRequiredTasks?.[selectedTaskIndex]
                      ?.isColorTemplate ? (
                      <ColorChemicalForm
                        recipes={recipes}
                        control={control}
                        register={register}
                        index={index}
                        remove={recipeRemove}
                        key={recipe.id}
                        options={dyeList}
                        dyeGroupOptions={dyeGroupList}
                        setFormValue={setValue}
                        liquorRatio={liquorRatio}
                        totalFabricWeight={batchDetail.totalFabricWeight}
                        errors={errors}
                      />
                    ) : (
                      <RegularChemcialForm
                        recipes={recipes}
                        control={control}
                        register={register}
                        index={index}
                        remove={recipeRemove}
                        key={recipe.id}
                        options={chemicalList}
                        setFormValue={setValue}
                        liquorRatio={liquorRatio}
                        totalFabricWeight={batchDetail.totalFabricWeight}
                        errors={errors}
                      />
                    ),
                  )}
                </TableBody>
              </Table>

              <Button
                variant="contained"
                sx={{ width: 'max-content' }}
                onClick={() => recipeAppend({ chemical: '', unit: 'g/l' })}>
                Add Another
              </Button>
            </>
          ) : (
            <>
              <NoChemicalAdded
                taskName={chemicalsRequiredTasks?.[selectedTaskIndex]?.taskName}
                append={() => {
                  if (
                    chemicalsRequiredTasks?.[selectedTaskIndex]?.isColorTemplate
                  ) {
                    recipeAppend({ chemical: '', unit: 'percent' });
                  } else {
                    recipeAppend({ chemical: '', unit: 'g/l' });
                  }
                }}
                errors={errors}
              />
            </>
          )}
          <Typography fontSize="0.8rem" fontWeight={400} color="#FF4A4A">
            Calculation (GL) = Total Fabric weight x Liqure Ratio x Quantity
          </Typography>
          <Typography fontSize="0.8rem" fontWeight={400} color="#FF4A4A">
            Calculation (%) = Total Fabric weight x Quantity x (Change x 0.01) x
            10
          </Typography>

          <Stack
            mb={2}
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-start"
            height="100%"
            spacing={1}>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography sx={{ whiteSpace: 'nowrap' }}>
                  Save template
                </Typography>
                <Switch checked={saveTemplate} onChange={toggleTemplateSave} />
              </Stack>
              <Stack>
                {saveTemplate && (
                  <TextField
                    size="small"
                    fullWidth
                    label="Template Name"
                    placeholder="Please provide a name for your template"
                    value={templateInfo.name}
                    error={templateInfo?.name?.length <= 0}
                    helperText={
                      templateInfo?.name?.length <= 0 && 'Name is required'
                    }
                    onChange={(event) =>
                      setTemplateInfo({
                        ...templateInfo,
                        name: event.target.value,
                      })
                    }
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}>
        <Button
          variant="contained"
          onClick={handleSubmit(handlePrevious)}
          disabled={selectedTaskIndex === 0}>
          Previous
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(handleNext)}
          disabled={saveTemplate ? templateInfo?.name?.length <= 0 : false}
          color={
            selectedTaskIndex < chemicalsRequiredTasks?.length - 1
              ? 'primary'
              : 'success'
          }>
          {selectedTaskIndex < chemicalsRequiredTasks?.length - 1
            ? 'Next'
            : 'Save Configaration'}
        </Button>
      </Stack>
    </Stack>
  );
};

export default TemplatingForm;
