import axios from './axios';

class Request {
  constructor(version) {
    if (version === 1) {
      this.axios = axios(process.env.REACT_APP_API_BASEURL);
    } else {
      this.axios = axios(process.env.REACT_APP_API_BASEURL_V2);
    }
  }

  async Get(url, query) {
    try {
      const response = await this.axios.get(url, { params: query });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async Post(url, body) {
    try {
      const response = await this.axios.post(url, { ...body });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async Patch(url, body) {
    try {
      const response = await this.axios.patch(url, { ...body });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async Delete(url, query) {
    try {
      const response = await this.axios.delete(url, { params: query });
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async PostWithFileUpload(url, formData, uploadEvent) {
    try {
      const response = await this.axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: uploadEvent,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }
}

export default Request;
