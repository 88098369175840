import Request from './request';

const signInUser = async (username, password) => {
  const axiosV2 = new Request(2);
  const response = await axiosV2.Post('/auth/sign-in', {
    username,
    password,
    isFromApp: false,
  });
  if (response) {
    return {
      ...response,
    };
  } else {
    return null;
  }
};

const verifyOtp = async (userData, otpToken, otpType) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Post(
    `/auth/login-verify/${userData.username}/${userData.phone}/${userData.sessionId}`,
    { otpToken, otpType },
  );
  if (response) {
    return {
      ...response,
    };
  } else {
    return null;
  }
};

const logoutUser = async (username) => {
  const axiosV2 = new Request(2);

  const response = await axiosV2.Post(`/auth/logout/${username}`);
  if (response) {
    return {
      ...response,
    };
  } else {
    return null;
  }
};

export const authService = {
  signInUser,
  verifyOtp,
  logoutUser,
};
