import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { fetchBatchProductionDetails } from '../../api/batch';
import NoData from '../global/NoData';
import dayjs from 'dayjs';
import { calculateMiliseconds, formatTime } from '../../utils/time';
import Button from '../../widgets/Button';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import { utils, writeFile } from 'xlsx';
import GridOnTwoToneIcon from '@mui/icons-material/GridOnTwoTone';
import '../../styles/production-print.css';

const ProductionReportDetails = () => {
  const [startdate, setStartDate] = useState(new Date());
  const [productionDetails, setProductionDetails] = useState([]);

  const [dateWiseProduction, setDateWiseProduction] = useState({});
  const [monthWiseProduction, setMonthWiseProduction] = useState({});
  const [print, setPrint] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async (startdate) => {
    setLoading(true);
    const response = await fetchBatchProductionDetails({
      startDate: startdate,
    });
    setMonthWiseProduction(response.monthProductionReport);
    setDateWiseProduction(response.productionReport);
    setProductionDetails(response.shiftDetailsArray);
    setLoading(false);
  };

  const resetProductionSearch = () => {
    fetchData(new Date());
    setStartDate(new Date());
  };

  const handleProductionSearchSubmit = (event) => {
    event.preventDefault();
    fetchData(startdate);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: `AREX-Production-Report-for-${dayjs(startdate).format(
      'DD/MM/YYYY',
    )}-${formatTime(new Date())}`,
    content: () => componentRef.current,
  });

  useEffect(() => {
    (async () => await fetchData(new Date()))();
  }, []);

  useEffect(() => {
    if (print) {
      handlePrint();
      setPrint(false);
    }
  }, [print]);

  const calculateGrandTotal = () => {
    let total = 0;

    total +=
      dateWiseProduction?.['Processed (Kg)'] +
      dateWiseProduction?.['Processing (Kg)'] +
      dateWiseProduction?.['Rejected (Kg)'] +
      dateWiseProduction?.['Reprocessed (Kg)'];

    return total.toFixed(2);
  };

  const calculateMonthlyGrandTotal = () => {
    let total = 0;
    total +=
      monthWiseProduction?.['Processed (Kg)'] +
      monthWiseProduction?.['Processing (Kg)'] +
      monthWiseProduction?.['Rejected (Kg)'] +
      monthWiseProduction?.['Reprocessed (Kg)'];
    return total.toFixed(2);
  };

  const handleExport = async () => {
    const wb = utils.book_new();

    const monthWiseProductionSummary = [];
    monthWiseProductionSummary.push(
      [
        'Month',
        'Total Processed',
        'Total Processing',
        'Total Rejected',
        'Total Reprocessed',
        'Grand Total',
      ],
      [
        dayjs(new Date()).format('MMMM YYYY'),
        monthWiseProduction?.['Processed (Kg)'],
        monthWiseProduction?.['Processing (Kg)'],
        monthWiseProduction?.['Rejected (Kg)'],
        monthWiseProduction?.['Reprocessed (Kg)'],
        calculateMonthlyGrandTotal(),
      ],
    );
    const mWs = utils.aoa_to_sheet(monthWiseProductionSummary);
    utils.book_append_sheet(wb, mWs, 'Current Month Summary');

    const dateWiseProductionSummary = [];
    dateWiseProductionSummary.push(
      [
        'Date',
        'Total Processed',
        'Total Processing',
        'Total Rejected',
        'Total Reprocessed',
        'Grand Total',
      ],
      [
        dayjs(startdate).format('DD/MM/YYYY'),
        dateWiseProduction?.['Processed (Kg)'],
        dateWiseProduction?.['Processing (Kg)'],
        dateWiseProduction?.['Rejected (Kg)'],
        dateWiseProduction?.['Reprocessed (Kg)'],
        calculateMonthlyGrandTotal(),
      ],
    );

    const ws = utils.aoa_to_sheet(dateWiseProductionSummary);
    utils.book_append_sheet(wb, ws, 'Date Wise Summary');

    productionDetails.map((shift) => {
      const dataX = [];
      dataX.push([
        'Machine',
        'FC No.',
        'Lot No.',
        'Party',
        'Colour',
        'Shade %',
        'Fabric Type',
        'Fabric Weight',
        'Loading',
        'Unloading',
        'Total Time',
        'Std. Time',
        'Extra',
        'Process',
        'Remarks',
        'Open/Tube',
        'Recipe',
      ]);
      shift.batchesInfoList.map((batch) => {
        dataX.push([
          batch?.machineName,
          batch?.fcNumber,
          batch?.lotNumber.split('-')[1],
          batch?.clientname,
          batch?.colour,
          batch?.shade,
          batch?.fabricType,
          batch?.fabricBatchQuantity,
          dayjs(batch?.loadingTime).format('hh:mm A'),
          dayjs(batch?.unloadingTime).format('hh:mm A'),
          calculateMiliseconds(batch?.totalTime) || 0,
          calculateMiliseconds(batch?.standardTime) || 0,
          `${batch?.extraTime < 0 ? '-' : ''} ${
            calculateMiliseconds(Math.abs(batch?.extraTime)) || 0
          }`,
          batch?.processType,
          batch?.dyeingRemark || 'N/A',
          batch?.finishingType,
          batch?.isRecipeRequired ? 'Yes' : 'No',
        ]);
      });

      const wsX = utils.aoa_to_sheet(dataX);
      let shitName = `${shift.shiftName} Shift`;
      utils.book_append_sheet(wb, wsX, shitName);
    });

    writeFile(
      wb,
      `arex-production-report-for-${dayjs(startdate).format(
        'DD/MM/YYYY',
      )}-${formatTime(new Date())}.xlsx`,
    );
  };

  return (
    <>
      <Stack width="100%" my={2}>
        <form onSubmit={handleProductionSearchSubmit}>
          <Stack
            mt={2}
            direction={{ md: 'row', xs: 'column', sm: 'row' }}
            width="100%"
            spacing={2}
            alignItems="center"
            justifyContent="right">
            <Stack>
              <Button
                variant="contained"
                onClick={() => {
                  setPrint(true);
                }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography>Print</Typography>
                  <PrintIcon />
                </Stack>
              </Button>
            </Stack>
            <Stack>
              <Button
                variant="contained"
                onClick={() => {
                  handleExport();
                }}>
                <Stack direction="row" spacing={0.5}>
                  <Typography>Export Excel</Typography>
                  <GridOnTwoToneIcon />
                </Stack>
              </Button>
            </Stack>
            <Stack width={{ xs: '23%', md: '15%' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date"
                  value={startdate ? startdate : new Date()}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
              <IconButton
                title="Edit"
                aria-label="edit"
                type="submit"
                sx={{
                  bgcolor: '#171820',
                  color: 'white',
                  borderRadius: 1,
                  ':hover': { bgcolor: '#303030' },
                }}>
                <SearchIcon />
              </IconButton>

              {/* reset button  */}
              <IconButton
                title="Edit"
                aria-label="edit"
                onClick={() => resetProductionSearch()}
                sx={{
                  bgcolor: '#171820',
                  color: 'white',
                  borderRadius: 1,
                  ':hover': { bgcolor: '#303030' },
                }}>
                <RestartAltIcon />
              </IconButton>
            </Stack>
          </Stack>
        </form>
      </Stack>

      {!loading ? (
        <Stack>
          <Box
            mx="auto"
            width="1080px"
            my={2}
            mt={3}
            ref={componentRef}
            boxSizing="border-box"
            padding="15px 30px"
            class="page">
            <Stack
              width="100%"
              sx={{
                width: '100%',
                mx: 'auto',
                border: '2px solid rgba(0,0,0,0.2)',
                p: 2,
                borderRadius: 1.5,
              }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}>
                    <Box
                      component="img"
                      sx={{
                        width: 150,
                      }}
                      alt="The house from the offer."
                      src="/assets/images/logo.svg"
                    />
                    <Stack>
                      <Typography fontSize={18}>
                        2/1,Gadda,Savar,Dhaka
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={7}>
                  <Stack width="100%">
                    <Typography textAlign="end" fontSize={22} fontWeight={700}>
                      Production Report
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Divider />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}>
                <Stack direction="column" spacing={1} mt={2}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start"> Date:</Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {dayjs(startdate).format('DD/MMM/YYYY ')}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">
                        {' '}
                        Total Processed :
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {dateWiseProduction?.['Processed (Kg)']} KG
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">
                        {' '}
                        Total Processing :
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {dateWiseProduction?.['Processing (Kg)']} KG
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">
                        Total Rejected :
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {dateWiseProduction?.['Rejected (Kg)']} KG
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">
                        Total Reprocessed :
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {dateWiseProduction?.['Reprocessed (Kg)']} KG
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">Grand Total :</Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {calculateGrandTotal()} KG
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={1} mt={2}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start"> Current Month:</Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {dayjs(new Date()).format('MMMM YYYY')}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">
                        {' '}
                        Total Processed :
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {monthWiseProduction['Processed (Kg)']} KG
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">
                        {' '}
                        Total Processing :
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {monthWiseProduction['Processing (Kg)']} KG
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">
                        Total Rejected :
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {monthWiseProduction['Rejected (Kg)']} KG
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">
                        Total Reprocessed :
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {monthWiseProduction['Reprocessed (Kg)']} KG
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Stack width="180px">
                      <Typography textAlign="start">Grand Total :</Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        textAlign="start"
                        fontWeight={700}
                        fontSize="1rem">
                        {calculateMonthlyGrandTotal()} KG
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            {productionDetails.length > 0 ? (
              productionDetails.map((shiftInfo, index) => {
                return (
                  <Stack width="100%" my={1} key={index}>
                    <Typography my={2} fontWeight={700} fontSize="1.2rem">
                      {shiftInfo?.shiftName} :
                    </Typography>
                    {shiftInfo?.batchesInfoList?.length > 0 ? (
                      <Stack>
                        <Box
                          mx="auto"
                          width="1080px"
                          my={2}
                          mb={3}
                          boxSizing="border-box"
                          padding="15px 30px"
                          class="page">
                          <Stack className="table-section">
                            <Table className="production-table">
                              <thead className="production-table-thead">
                                <tr className="production-table-tr">
                                  <th className="production-table-th">
                                    Machine
                                  </th>
                                  <th className="production-table-th">
                                    FC No.
                                  </th>
                                  <th className="production-table-th">
                                    Lot No.
                                  </th>
                                  <th className="production-table-th">Party</th>
                                  <th className="production-table-th">
                                    Colour
                                  </th>
                                  <th className="production-table-th">
                                    Shade %
                                  </th>
                                  <th className="production-table-th">
                                    Fabric Type
                                  </th>
                                  <th className="production-table-th">
                                    Fabric Weight
                                  </th>
                                  <th className="production-table-th">
                                    Loading
                                  </th>
                                  <th className="production-table-th">
                                    Unloading
                                  </th>
                                  <th className="production-table-th">
                                    Total Time
                                  </th>
                                  <th className="production-table-th">
                                    Std. Time
                                  </th>
                                  <th className="production-table-th">Extra</th>
                                  <th className="production-table-th">
                                    Process
                                  </th>
                                  <th className="production-table-th">
                                    Remarks
                                  </th>
                                  <th className="production-table-th">
                                    Open/Tube
                                  </th>
                                  <th className="production-table-th">
                                    Recipe
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {shiftInfo?.batchesInfoList?.map(
                                  (batch, index) => {
                                    return (
                                      <tr
                                        key={index}
                                        className="production-table-tr">
                                        <td className="production-table-td">
                                          {batch?.machineName}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.fcNumber}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.lotNumber.split('-')[1]}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.clientname}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.colour}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.shade}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.fabricType}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.fabricBatchQuantity}
                                        </td>
                                        <td className="production-table-td">
                                          {dayjs(batch?.loadingTime).format(
                                            'hh:mm A',
                                          )}
                                        </td>
                                        <td className="production-table-td">
                                          {dayjs(batch?.unloadingTime).format(
                                            'hh:mm A',
                                          )}
                                        </td>
                                        <td className="production-table-td">
                                          {calculateMiliseconds(
                                            batch?.totalTime,
                                          ) || 0}
                                        </td>
                                        <td className="production-table-td">
                                          {calculateMiliseconds(
                                            batch?.standardTime,
                                          ) || 0}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.extraTime < 0 ? '-' : ''}
                                          {calculateMiliseconds(
                                            Math.abs(batch?.extraTime),
                                          ) || 0}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.processType}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.dyeingRemark || 'N/A'}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.finishingType}
                                        </td>
                                        <td className="production-table-td">
                                          {batch?.isRecipeRequired
                                            ? 'Yes'
                                            : 'No'}
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </tbody>
                            </Table>
                          </Stack>
                        </Box>
                      </Stack>
                    ) : (
                      <Stack
                        width="100%"
                        height="20vh"
                        direction="row"
                        alignItems="center"
                        justifyContent="center">
                        <Typography fontWeight={700} fontSize="1rem">
                          No Batch Found for {shiftInfo?.shiftName} Shift
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                );
              })
            ) : (
              <Stack
                justifyContent="center"
                pt={10}
                width="100%"
                height="100%"
                alignItems="center">
                <NoData
                  title="No Production Information"
                  description="No production information found for the selected date."
                />
              </Stack>
            )}
          </Box>
        </Stack>
      ) : (
        <Stack
          width="100%"
          height="calc(100vh - 65px)"
          alignItems="center"
          justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
    </>
  );
};

export default ProductionReportDetails;
