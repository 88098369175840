import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Table,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSpecificQuotation } from '../../api/client';
import PrintIcon from '@mui/icons-material/Print';
import Button from '../../widgets/Button';
import { useReactToPrint } from 'react-to-print';
import '../../styles/quotation-print.css';
import { useNavigate } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PageTitle from '../../utils/PageTitle';

const PrintQuotation = () => {
  const { id } = useParams();
  const [quotation, setQuotation] = useState([]);
  let navigate = useNavigate();

  //fetching quotation information
  const fetchQuotation = async () => {
    const response = await fetchSpecificQuotation({ uid: id });
    setQuotation(response.quotations);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    (async () => {
      await fetchQuotation();
    })();
  }, []);

  return (
    <>
      <PageTitle title="Print Quotation" />
      {quotation.client ? (
        <Stack mx="auto" width="1080px" my={3} px={2}>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}>
            <Stack>
              <Button onClick={() => navigate(-1)} variant="contained">
                <ArrowBackIosIcon fontSize="15px" />
                <Typography fontSize="15px">Back</Typography>
              </Button>
            </Stack>
            <Button onClick={handlePrint} variant="contained">
              <Stack direction="row" spacing={0.5}>
                <Typography fontSize="15px">Print</Typography>
                <PrintIcon />
              </Stack>
            </Button>
          </Stack>
          <Divider></Divider>
          <Box
            mx="auto"
            width="1080px"
            my={2}
            mt={3}
            ref={componentRef}
            boxSizing="border-box"
            padding="15px 30px"
            class="page">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              mb={2}>
              <Stack>
                <Box
                  component="img"
                  sx={{
                    width: 150,
                  }}
                  alt="The house from the offer."
                  src="/assets/images/logo.svg"
                />
              </Stack>
              <Stack>
                <Typography fontSize={18}>2/1,Gadda,Savar,Dhaka</Typography>
              </Stack>
            </Stack>
            <Divider></Divider>
            <Stack width="100%" direction="row" justifyContent="Center" my={2}>
              <Typography fontSize={25} sx={{ fontWeight: '500' }}>
                Price Quotation
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start">
              <Stack direction="column" justifyContent="flex-start">
                <Typography fontSize={15}>To</Typography>
                <Typography fontSize={15} sx={{ fontWeight: '700' }}>
                  Managing Director,
                </Typography>
                <Typography fontSize={15} sx={{ fontWeight: '700' }}>
                  {quotation?.client?.name}
                </Typography>
              </Stack>

              {/* issue Dates  */}
              <Stack direction="column" alignItems="flex-end">
                <Typography fontSize={15}>
                  Issue Date:{' '}
                  {dayjs(quotation.issueDate).format('DD/MMM/YYYY ')}
                </Typography>
                <Typography fontSize={15}>
                  Valid Till:{' '}
                  {dayjs(quotation.expiryDate).format('DD/MMM/YYYY ')}
                </Typography>
              </Stack>
            </Stack>
            <Stack my={2}>
              <Typography fontSize={15} sx={{ fontWeight: '600' }}>
                Subject: Quotation for {quotation?.process?.name} (
                {quotation?.process?.shortName})
              </Typography>
            </Stack>

            <Stack>
              <Table className="quotation-table">
                <thead className="quotation-table-head">
                  <tr className="quotation-table-tr">
                    <th className="quotation-table-th">SL</th>
                    <th className="quotation-table-th">Shade Name</th>
                    <th className="quotation-table-th">Shade Range</th>
                    <th className="quotation-table-th">Open</th>
                    <th className="quotation-table-th">Tube</th>
                    <th className="quotation-table-th"> Lycra Open</th>
                    <th className="quotation-table-th">Double Part Open</th>
                    <th className="quotation-table-th">Double Part Tube</th>
                    <th className="quotation-table-th">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {quotation?.clientShades.map((row, index) => {
                    return (
                      <tr className="quotation-table-tr" key={row.id}>
                        <td className="quotation-table-td">{index + 1}</td>
                        <td className="quotation-table-td">{row.shadeName}</td>
                        <td className="quotation-table-td">{row.shadeRange}</td>
                        <td className="quotation-table-td">{row.open}</td>
                        <td className="quotation-table-td"> {row.tube}</td>
                        <td className="quotation-table-td">{row.lycraOpen}</td>
                        <td className="quotation-table-td">
                          {row.doublePartOpen}
                        </td>
                        <td className="quotation-table-td">
                          {row.doublePartTube}
                        </td>
                        <td className="quotation-table-td"> {row.remarks}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Stack>
            {/* additional charges part  */}
            <Stack className="notes">
              <Typography fontSize={15} sx={{ fontWeight: '500' }} mt={1}>
                Notes:
              </Typography>

              <ul className="notes">
                <li>
                  <Typography fontSize={15}>
                    Heat Seat Charge:{' '}
                    {quotation?.additionalStepCharges?.heatSetCharge}
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={15}>
                    Silicon Charge:{' '}
                    {quotation?.additionalStepCharges?.siliconCharge}
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={15}>
                    Stenter Charge:{' '}
                    {quotation?.additionalStepCharges?.stenterCharge}
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={15}>
                    Compacting Charge:{' '}
                    {quotation?.additionalStepCharges?.compactingCharge}
                  </Typography>
                </li>
                <li>
                  <Typography fontSize={15}>
                    Currency: {quotation?.currency} (Rate:{' '}
                    {quotation?.currencyExchangeRate} BDT)
                  </Typography>
                </li>
              </ul>
            </Stack>
            {/* terms and condition part  */}
            <Stack className="termsPart">
              {quotation.termsAndConditions.length ? (
                <Typography fontSize={15} sx={{ fontWeight: '500' }}>
                  Terms and Conditions:
                </Typography>
              ) : (
                <></>
              )}

              <ul className="termsPart">
                {quotation.termsAndConditions.map((terms) => {
                  return (
                    <li key={terms.id}>
                      <Typography fontSize={15}>{terms.term}</Typography>
                    </li>
                  );
                })}
              </ul>
            </Stack>
            {/* signature  */}
            <Grid className="singnature-class" container spacing={2} mt={8}>
              <Grid item xs={4}>
                <Divider width="80%"></Divider>
                <Stack width="90%" justifyContent="center" alignItems="center">
                  <Stack my={0.5}>
                    <Typography fontSize={15}>Authorized Signature</Typography>
                    <Typography fontSize={15}>Kishor Kumer Kejriwal</Typography>
                    <Typography fontSize={15}>Executive Director</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Divider width="80%"></Divider>
                <Stack width="90%" justifyContent="center" alignItems="center">
                  <Stack my={0.5}>
                    <Typography fontSize={15}>Authorized Signature</Typography>
                    <Typography fontSize={15}>Kanouj Chakma Ziko</Typography>
                    <Typography fontSize={15}>
                      GM (Dyeing & Finishing)
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Divider width="80%"></Divider>
                <Stack width="90%" justifyContent="center" alignItems="center">
                  <Stack my={0.5}>
                    <Typography fontSize={15}>Authorized Signature</Typography>
                    <Typography fontSize={15}>Md. Ekhlasur Rahman</Typography>
                    <Typography fontSize={15}>Technical Director</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      ) : (
        <>
          <Stack
            justifyContent="center"
            pt={10}
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        </>
      )}
    </>
  );
};

export default PrintQuotation;
