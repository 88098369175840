import { Stack, Typography } from '@mui/material';
import React from 'react';
import { formatNumber } from '../../../utils/number';

const StatCard = ({ label, count, borderColor }) => {
  return (
    <Stack
      minWidth={120}
      px={2}
      py={1}
      borderLeft={5}
      borderColor={borderColor}
      bgcolor="#F2F2F2"
      justifyContent="space-between"
      borderRadius="0 5px 5px 0">
      <Typography fontSize={14} fontWeight={800}>
        {label}
      </Typography>
      <Typography variant="h4" fontWeight={800}>
        {formatNumber(count)}
      </Typography>
    </Stack>
  );
};

export default StatCard;
