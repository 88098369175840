import { Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PrintIcon from '@mui/icons-material/Print';
import Button from '../../widgets/Button';
import { useReactToPrint } from 'react-to-print';
import { Box } from '@mui/system';
import QRCode from 'react-qr-code';
import '../../styles/machines-qr-print.css';
import { formatTime } from '../../utils/time';
import PageTitle from '../../utils/PageTitle';

export const MachinesQrPrint = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const { data, title } = location.state;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: `AREX-${title}-${formatTime(new Date())}`,
    content: () => componentRef.current,
  });

  return (
    <>
      <PageTitle title="Machine QRs" />
      <Stack mx="auto" width="1080px" my={3} px={2}>
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}>
          <Stack>
            <Button onClick={() => navigate(-1)} variant="contained">
              <ArrowBackIosIcon fontSize="15px" />
              <Typography fontSize="15px">Back</Typography>
            </Button>
          </Stack>
          <Stack>
            <Typography fontSize={25} sx={{ fontWeight: '500' }}>
              {title}
            </Typography>
          </Stack>
          <Button onClick={handlePrint} variant="contained">
            <Stack direction="row" spacing={0.5}>
              <Typography fontSize="15px">Print</Typography>
              <PrintIcon />
            </Stack>
          </Button>
        </Stack>
        <Divider></Divider>
        <Stack mb={5}></Stack>
        <Stack width="100%">
          <Grid
            mt={0.01}
            direction="row"
            className="qr-class"
            container
            spacing={3}>
            {data.map((machine) => (
              <Grid
                key={machine?.qrId}
                item
                md={4}
                sm={4}
                xs={4}
                spacing={3}
                className="machine-qr-item">
                <Stack
                  direction="column"
                  width="100%"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                  className="machine-qr-code">
                  <QRCode size={200} value={`${machine.qrId}`} />
                  <Typography fontSize="15px">{machine?.name}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Stack display="none" width="100%" heigh="100%">
          <Box
            mx="auto"
            width="1080px"
            my={2}
            mt={3}
            ref={componentRef}
            boxSizing="border-box"
            padding="15px 30px"
            class="page">
            <Grid
              mt={0.01}
              direction="row"
              className="qr-class"
              container
              spacing={3}>
              {data.map((machine) => (
                <Grid
                  key={machine?.qrId}
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  className="machine-qr-item">
                  <Stack
                    direction="column"
                    width="100%"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    className="machine-qr-code">
                    <QRCode size={230} value={`${machine.qrId}`} />
                    <Typography fontSize="18px">{machine?.name}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
