import { yupResolver } from '@hookform/resolvers/yup';
import { successNoitif } from '../../utils/notification';
import { Dialog, DialogTitle, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ClientCreationValidator } from '../../validators/client';
import Button from '../../widgets/Button';
import { createNewClinet } from '../../api/client';

export const AddNewClient = ({ show, toggleModal, setIsAdded }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [clinetData, setClientData] = useState({});
  const resolver = yupResolver(ClientCreationValidator);
  //initail values of form
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      shortCode: '',
      status: 'ACTIVE',
      contactPerson: '',
      contactNumber: '',
    },
    resolver,
  });
  //reset the values on close of add machien form
  const onClose = () => {
    handleCreationFormClose();
  };

  const handleCreationFormClose = () => {
    reset({
      name: '',
      shortCode: '',
      status: 'ACTIVE',
      contactPerson: '',
      contactNumber: '',
    });
    toggleModal(false);
  };

  //colse confrimation dialog
  const onCloseCongfirmation = () => {
    handleCloseConfirmation();
  };

  const handelClientFormSubmission = (data) => {
    setClientData(data);
    setShowConfirmation(true);
    toggleModal(false);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  //handel confrimationDesision
  const handelConfrimationDecision = () => {
    setShowConfirmation(false);
    createMachineFormSubmit();
    reset(onClose());
  };

  //async calling api
  const createMachineFormSubmit = async () => {
    const response = await createNewClinet(clinetData);

    if (response) {
      successNoitif('Clint has been Added');
      setIsAdded(true);
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">Add New Client</DialogTitle>
        <form>
          <Stack
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 3,
              paddingBottom: 3,
            }}>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Client Name"
                required
                placeholder="Client Name"
                {...register('name')}
                error={errors?.name}
                helperText={errors?.name?.message}
              />
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Short Code"
                required
                placeholder="Short Code"
                {...register('shortCode')}
                error={errors?.shortCode}
                helperText={errors?.shortCode?.message}
              />
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Contact Person"
                required
                placeholder="Contact Person"
                {...register('contactPerson')}
                error={errors?.contactPerson}
                helperText={errors?.contactPerson?.message}
              />
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Contact Number"
                required
                placeholder="Contact Number"
                {...register('contactNumber')}
                error={errors?.contactNumber}
                helperText={errors?.contactNumber?.message}
              />
            </Stack>
            {/* Status */}
            {/* <Stack spacing={2} sx={{ mt: 2 }}>
              <FormControl fullWidth error={errors?.status}>
                <InputLabel id="type123">Status</InputLabel>
                <Select
                  labelId="type123"
                  id="demo-simple-select"
                  label="Status"
                  {...register('status')}
                  placeholder="Status">
                  <MenuItem value="ACTIVE">Active</MenuItem>
                  <MenuItem value="INACTIVE">In Active</MenuItem>
                </Select>
                {errors?.status && (
                  <FormHelperText>{errors?.status?.message}</FormHelperText>
                )}
              </FormControl>
            </Stack> */}
          </Stack>
        </form>

        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button
            variant="outlined"
            sx={{ borderColor: 'red' }}
            color="error"
            onClick={handleCreationFormClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(handelClientFormSubmission)}>
            Add
          </Button>
        </Stack>
      </Dialog>
      <Stack>
        <Dialog
          onClose={onCloseCongfirmation}
          open={showConfirmation}
          maxWidth="sm"
          title="Are you sure"
          yesText="Yes"
          noHandler={onCloseCongfirmation}>
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to add this Client?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
                toggleModal(true);
              }}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => handelConfrimationDecision()}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
    </>
  );
};
