import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material';

import PageHeader from '../../widgets/PageHeader';
import Button from '../../widgets/Button';
import AddIcon from '@mui/icons-material/AddCircleTwoTone';
import FileUploadIcon from '@mui/icons-material/FileUploadTwoTone';

import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { AllOrderListColumns } from '../../constants/table-column/order';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/SearchTwoTone';
import TextField from '../../widgets/TextField';
import RestartAltIcon from '@mui/icons-material/RestartAltTwoTone';
import { fetchAllOrder, fetchAllOrderStats } from '../../api/order';
import { setColor } from '../../utils/order';
import { Link } from 'react-router-dom';
import { CREATE_ORDER, UPLOAD_ORDER } from '../../constants/routeUrls';

import NoDataOverlay from '../../components/global/NoDataOverlay';

import FileExporter from '../../components/global/FileExporter';
import PageTitle from '../../utils/PageTitle';

const Order = () => {
  const [orderList, setOrderList] = useState([]);
  const [orderStats, setOrderStats] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);

  const [page, setPage] = useState(0);
  const [fcNumber, setFcNumber] = useState('');
  const [status, setStatus] = useState('');
  const [pageSize, setPageSize] = useState(10);

  const [isLoading, setIsloading] = useState(false);

  const [statusTextField, setStatusTextField] = useState(0);
  const [typeTextField, setTypeTextField] = useState(0);
  const textInput = React.useRef(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchOrders(fcNumber, status, page, pageSize);
    setFcNumber(fcNumber);
    setStatus(status);
  };
  const resetAll = () => {
    fetchOrders('', '', page, pageSize);
    setFcNumber('');
    setStatus('');
    textInput.current.value = '';
    setStatusTextField(0);
    setTypeTextField(0);
  };

  const fetchOrders = async (fcNumber, status, page, pageSize) => {
    setIsloading(true);

    const response = await fetchAllOrder({
      fcNumber: fcNumber,
      status: status,
      page: page,
      pageSize: pageSize,
      pageless: false,
    });

    setOrderList(response.orders);
    setTotalEntries(response.total);
    setIsloading(false);
    setIsUpdated(!isUpdated);
  };

  const fetchOrderStatus = async () => {
    setIsloading(true);
    const response = await fetchAllOrderStats();
    setOrderStats(response);
    setIsloading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchOrders();
      await fetchOrderStatus();
    })();
  }, []);

  useEffect(() => {
    (async () => await fetchOrders(fcNumber, status, page, pageSize))();
  }, [page, pageSize]);

  const statsLabel = (label) => {
    if (label == 'Ready For Delivery') {
      return 'Ready';
    } else {
      return label;
    }
  };

  const fetchPagelessData = async () => {
    const response = await fetchAllOrder({
      fcNumber: fcNumber,
      status: status,
      pageless: true,
    });

    return response.orders;
  };

  return (
    <Stack
      spacing={2}
      width="100%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      px={2}
      pt={3}>
      <PageTitle title="Orders" />
      {/* header section  */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        spacing={2}>
        <PageHeader>Orders</PageHeader>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            component={Link}
            to={`${CREATE_ORDER}`}
            endIcon={<AddIcon />}>
            Add Order
          </Button>

          <Button
            variant="contained"
            component={Link}
            to={UPLOAD_ORDER}
            endIcon={<FileUploadIcon />}>
            Upload CSV
          </Button>
        </Stack>
      </Stack>
      {!orderStats[0] ? (
        <>
          <Stack
            justifyContent="center"
            width="100%"
            height="40%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        </>
      ) : (
        <>
          {/* stats section  */}
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            width="100%">
            {orderStats?.map((stat) => (
              <Stack
                px={1}
                py={1}
                bgcolor="#f2f2f2"
                boxSizing="border-box"
                borderLeft={8}
                borderColor={setColor(stat.label)}
                width={120}
                alignItems="center"
                key={stat.label}>
                <Typography fontSize="0.8rem" fontWeight={700}>
                  {statsLabel(stat.label)}
                </Typography>
                <Typography fontSize="2.5rem" fontWeight={700}>
                  {stat.count}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </>
      )}
      {/* search section  */}
      <form onSubmit={handleSubmit}>
        <Stack
          mt={2}
          direction="row"
          width="100%"
          spacing={2}
          alignItems="center"
          justifyContent="left">
          <TextField
            size="small"
            onChange={(event) => setFcNumber(event.target.value)}
            inputRef={textInput}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            flexWrap="wrap"
            fullWidth
            id="outlined-basic"
            label="Search"
            sx={{ maxWidth: 300 }}
            variant="outlined"
          />

          {/* for machine status search  */}
          <FormControl fullWidth sx={{ maxWidth: 200 }}>
            <InputLabel id="SearchStatus">Status</InputLabel>
            <Select
              size="small"
              defaultValue={statusTextField}
              value={typeTextField}
              labelId="SearchStatus"
              id="demo-simple-select"
              label="Status"
              placeholder="Status"
              onChange={(event) => {
                setStatus(event.target.value);

                setTypeTextField(event.target.value);
              }}>
              <MenuItem value="NOT ASSIGNED">Not Assigned</MenuItem>
              <MenuItem value="IN QUEUE">In Queue</MenuItem>
              <MenuItem value="READY FOR DELIVERY">Ready For Delivery</MenuItem>
              <MenuItem value="DELIVERED">Delivered</MenuItem>
            </Select>
          </FormControl>

          {/* Search button  */}
          <IconButton
            title="Edit"
            aria-label="edit"
            type="submit"
            sx={{
              bgcolor: '#303030',
              color: 'white',
              borderRadius: 1,
              ':hover': { bgcolor: '#404040' },
            }}>
            <SearchIcon />
          </IconButton>

          {/* reset button  */}
          <IconButton
            title="Edit"
            aria-label="edit"
            onClick={() => resetAll()}
            sx={{
              bgcolor: '#303030',
              color: 'white',
              borderRadius: 1,
              ':hover': { bgcolor: '#404040' },
            }}>
            <RestartAltIcon />
          </IconButton>

          {/* downloadExcelSection  */}
          <FileExporter
            apiHandle={fetchPagelessData}
            title={'Order'}
            isUpdated={isUpdated}
            mapKey={'FC NUMBER'}
          />
        </Stack>
      </form>

      {/* Table section */}

      <Box
        width="100%"
        height={500}
        sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <DataGrid
          columns={AllOrderListColumns}
          rows={orderList}
          rowCount={totalEntries}
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
          paginationMode="server"
          filterMode="server"
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={isLoading}
          components={{
            NoRowsOverlay: NoDataOverlay,
          }}
        />
      </Box>
    </Stack>
  );
};

export default Order;
