import {
  Chip,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchStockDetails } from '../../api/inventory';
import Barcode from 'react-barcode';
import dayjs from 'dayjs';
import { formatCurrency, formatNumber } from '../../utils/number';
import LotList from '../../components/stock-management/LotList';
import TransactionList from '../../components/stock-management/TransactionList';
import Button from '../../widgets/Button';

import {
  AddCircleTwoTone as Add,
  ChevronLeftTwoTone as ChevronLeft,
  DeleteTwoTone as Delete,
} from '@mui/icons-material';
import {
  ADD_CHEMICAL_STOCK,
  STORE_MANAGEMENT,
} from '../../constants/routeUrls';
import ChemicalDeleteModal from './ChemicalDeleteModal';

const StockDetailsView = () => {
  const { sku } = useParams();
  const location = useLocation();
  const { tab } = location.state;
  const [stockDetails, setStockDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const fetchDetails = async () => {
    setIsLoading(true);
    const response = await fetchStockDetails(sku);
    setStockDetails(response);
    setIsLoading(false);
  };
  let navigate = useNavigate();
  useEffect(() => {
    (async () => await fetchDetails())();
  }, [sku]);

  useEffect(() => {
    if (isDeleted) {
      navigate(-1);
    }
  }, [isDeleted]);

  const handleDeleteModal = () => {
    setShowDeleteModal(true);
  };

  return (
    <Container maxWidth="xl">
      <Stack
        width="100%"
        height="auto"
        minHeight="calc(100vh - 65px)"
        py={3}
        spacing={2}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Button
            variant="contained"
            startIcon={<ChevronLeft />}
            sx={{ width: 'max-content' }}
            component={Link}
            to={`${STORE_MANAGEMENT}/${tab}`}>
            Back
          </Button>

          {stockDetails?.isVisible ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                variant="contained"
                startIcon={<Add />}
                component={Link}
                to={`${ADD_CHEMICAL_STOCK}/${sku}`}
                sx={{ width: 'max-content' }}
                state={{ tab: tab }}>
                Add Stock
              </Button>
              <Button
                onClick={() => handleDeleteModal()}
                variant="contained"
                startIcon={<Delete />}>
                delete Chemical
              </Button>
            </Stack>
          ) : (
            <>
              <Typography color="red">
                **This chemical is already deleted
              </Typography>
            </>
          )}
        </Stack>
        <ChemicalDeleteModal
          show={showDeleteModal}
          toggleModal={setShowDeleteModal}
          data={stockDetails}
          setIsDeleted={setIsDeleted}
        />

        <Divider />
        {stockDetails !== null ? (
          !isLoading ? (
            <Stack direction="row" flexWrap="wrap" width="100%">
              <Stack
                spacing={1}
                alignItems="flex-start"
                width="max-content"
                sx={{ mr: 1, mb: 2 }}
                px={2}
                py={2}
                borderRadius={2}
                border={2}
                borderColor="rgba(0,0,0,0.2)">
                <Barcode
                  value="barcode-example"
                  displayValue={false}
                  height={80}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  maxWidth={410}
                  width="100%">
                  <Typography variant="h5" fontWeight={700}>
                    {stockDetails.sku}
                  </Typography>
                  <Typography variant="h5" fontWeight={700}>
                    {stockDetails.name}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Chip label={stockDetails.type} size="small" />
                  {stockDetails?.dyeGroup && (
                    <Chip label={stockDetails?.dyeGroup?.name} size="small" />
                  )}
                  <Chip
                    label={stockDetails.status}
                    size="small"
                    color={
                      stockDetails.status === 'IN STOCK' ? 'success' : 'error'
                    }
                  />
                  {stockDetails.totalQuantity <= 100 && (
                    <Chip label="Low In Stock" size="small" color="warning" />
                  )}
                </Stack>
                <Typography fontSize="0.85rem" fontWeight={400}>
                  Last updated:{' '}
                  {dayjs(stockDetails.updatedAt).format('DD, MMM YYYY hh:mm A')}
                </Typography>
              </Stack>
              <Stack
                px={2}
                py={2}
                sx={{ mr: 1, mb: 2 }}
                borderRadius={2}
                border={2}
                borderColor="rgba(0,0,0,0.2)"
                spacing={0.5}>
                <Typography fontSize="0.8rem" fontWeight={400}>
                  Available Quantity
                </Typography>
                <Typography fontSize="1.2rem" fontWeight={700}>
                  {formatNumber(stockDetails.totalQuantity)} {stockDetails.unit}
                </Typography>
                <Divider />
                <Typography fontSize="0.8rem" fontWeight={400}>
                  Avg Cost
                </Typography>
                <Typography fontSize="1.2rem" fontWeight={700}>
                  BDT {stockDetails.avgCostPerUnit.toFixed(2)} /{' '}
                  {stockDetails.unit}
                </Typography>
                <Divider />
                <Typography fontSize="0.8rem" fontWeight={400}>
                  Total Purchase (this month)
                </Typography>
                <Typography fontSize="1.2rem" fontWeight={700}>
                  {formatCurrency(stockDetails.purchaseThisMonth)}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack
              width="100%"
              height="calc(100vh - 65px)"
              alignItems="center"
              justifyContent="center">
              <CircularProgress />
            </Stack>
          )
        ) : (
          <Stack
            width="100%"
            height="calc(100vh - 65px)"
            alignItems="center"
            justifyContent="center">
            <Typography variant="h4" fontWeight={700}>
              OPS!!
            </Typography>
            <Typography fontSize="18px" maxWidth={310} textAlign="center">
              Something went wrong. Could not load information for SKU: &quot;
              {sku}&quot;
            </Typography>
          </Stack>
        )}
        <Stack direction="row" flexWrap="warp">
          <LotList />
          <TransactionList />
        </Stack>
      </Stack>
    </Container>
  );
};

export default StockDetailsView;
