import React, { useEffect, useState } from 'react';

import exportFromJSON from 'export-from-json';

import { IconButton, Stack } from '@mui/material';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone';
import GridOnTwoToneIcon from '@mui/icons-material/GridOnTwoTone';
import { Link } from 'react-router-dom';
import { PRINT_ACCOUNT_TABLE } from '../../constants/routeUrls';
import { paymentTableDataReformat } from '../../utils/table';
import { formatNumber } from '../../utils/number';

const OrderAccountFileExporter = ({
  apiHandle,
  title,
  isUpdated,
  vertical,
  mapKey,
  orderInfo,
  accountInfo,
}) => {
  const [dataList, setDataList] = useState([]);
  const fetchData = async () => {
    const response = await apiHandle();
    let tempData = JSON.parse(JSON.stringify(response));
    let reformatedData = [];
    reformatedData = paymentTableDataReformat(tempData);
    const modifiedData = reformatedData.map((item) => {
      return {
        'PAYMENT DATE': item['PAYMENT DATE'],
        'TRANSACTION ID': item['TRANSACTION ID'],
        'TRANSACTION TYPE': item['TRANSACTION TYPE'],
        REASON: item['REASON'],
        'PAYMENT BY': item['PAYMENT BY'],
        'BANK NAME': item['BANK NAME'],
        'CHEQUE NO': item['CHEQUE NO'],
        'CHEQUE DATE': item['CHEQUE DATE'],
        'PAYMENT AMOUNT': formatNumber(item['PAYMENT AMOUNT']),
      };
    });
    setDataList(modifiedData);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [isUpdated]);

  const handleExport = async () => {
    exportFromJSON({
      data: dataList,
      fileName: `AREX-${title}-Report`,
      exportType: exportFromJSON.types.xls,
    });
  };

  return (
    <>
      {vertical ? (
        <Stack
          direction="column"
          px={0.5}
          spacing={2}
          bgcolor="#f2f2f2"
          borderRadius={2}>
          <IconButton
            title="Preview PDF"
            component={Link}
            to={PRINT_ACCOUNT_TABLE}
            disabled={dataList?.length < 1 ? true : false}
            state={{ data: dataList, title: title, mapKey: mapKey }}>
            <PictureAsPdfTwoToneIcon />
          </IconButton>
          <IconButton
            title="Download Excel"
            disabled={dataList?.length < 1 ? true : false}
            onClick={() => handleExport()}>
            <GridOnTwoToneIcon />
          </IconButton>
        </Stack>
      ) : (
        <Stack
          direction="row"
          px={0.5}
          spacing={2}
          bgcolor="#f2f2f2"
          borderRadius={2}>
          <IconButton
            title="Preview PDF"
            component={Link}
            to={PRINT_ACCOUNT_TABLE}
            disabled={dataList?.length < 1 ? true : false}
            state={{
              data: dataList,
              title: title,
              mapKey: mapKey,
              orderInfo: orderInfo,
              accountInfo: accountInfo,
            }}>
            <PictureAsPdfTwoToneIcon />
          </IconButton>
          <IconButton
            title="Download Excel"
            disabled={dataList?.length < 1 ? true : false}
            onClick={() => handleExport()}>
            <GridOnTwoToneIcon />
          </IconButton>
        </Stack>
      )}
    </>
  );
};

export default OrderAccountFileExporter;
