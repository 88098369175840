import {
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../widgets/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { fetchBatchDetail } from '../../api/batch';

import { formatTime, milisecondsToTime } from '../../utils/time';
import { formatNumber } from '../../utils/number';
import { getBatchStatus } from '../../utils/batch';

import PrintIcon from '@mui/icons-material/Print';
import BatchAccountOrderDetails from './BatchAccountOrderDetails';
import BatchTaskDetails from './BatchTaskDetails';
import BatchAccountTranscript from './BatchAccountTranscript';
import PageTitle from '../../utils/PageTitle';

const BatchAccountDetails = () => {
  const { lotNumber } = useParams();
  let navigate = useNavigate();
  const [batchDetails, setBatchDetails] = useState({});
  const [open, setOpen] = useState(false);

  const fetchDetails = async () => {
    const response = await fetchBatchDetail(lotNumber);
    if (response) {
      setBatchDetails(response);
    }
  };

  useEffect(() => {
    (async () => await fetchDetails())();
  }, []);

  const SmallStatCard = ({ title, content, bgcolor }) => {
    return (
      <Stack
        sx={{ mr: 1, mb: 0.5 }}
        spacing={1}
        px={1}
        py={1}
        borderRadius={2}
        border={2}
        borderColor="rgba(0,0,0,0.1)"
        minWidth={100}
        bgcolor={bgcolor}>
        <Typography fontSize="0.75rem" color="grey.600" fontWeight={500}>
          {title}
        </Typography>
        <Typography fontWeight={700} fontSize={13}>
          {content}
        </Typography>
      </Stack>
    );
  };

  const totalStandardTime = () => {
    const total = batchDetails?.taskInfo?.tasks?.reduce((prev, task) => {
      const hour = task.standardTimeHour ? task.standardTimeHour : 0;
      const minute = task.standardTimeMinute ? task.standardTimeMinute / 60 : 0;

      const durationInHour = hour + minute;

      const hourToMs = durationInHour * 60 * 60 * 1000;

      return prev + hourToMs;
    }, 0);

    return total;
  };

  return (
    <Stack
      width="80%"
      height="100%"
      minHeight="80vh"
      boxSizing="border-box"
      mx="auto"
      pt={3}>
      <PageTitle title="Batch Account Details" />
      <Stack
        width="100%"
        direction="row"
        justifyContent="flex-start"
        mt={1}
        mb={2}>
        <Button onClick={() => navigate(-1)} variant="contained">
          <ArrowBackIosIcon fontSize="15px" />
          <Typography fontSize="15px">Back</Typography>
        </Button>
      </Stack>
      <Divider></Divider>
      {batchDetails?.lotNumber ? (
        <Stack>
          <Grid mt={2} container spacing={2} width="100%">
            <Grid item md={5.5} sm={10}>
              <Stack
                sx={{
                  height: '100%',
                  mx: 'auto',
                  border: '2px solid rgba(0,0,0,0.2)',
                  p: 2,
                  borderRadius: 1.5,
                }}>
                <Stack spacing={2} direction="row">
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between">
                    <Stack spacing={0.5}>
                      <Typography fontSize={18} fontWeight={800}>
                        Batch #{batchDetails.lotNumber.split('-')[1]}
                      </Typography>
                      <Typography fontSize="0.85rem" fontWeight={700}>
                        Weight {formatNumber(batchDetails.totalFabricWeight)} Kg
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Chip
                          color={getBatchStatus(batchDetails.status).color}
                          label={getBatchStatus(batchDetails.status).label}
                          size="small"
                          sx={{ maxWidth: 'max-content' }}
                        />
                        <Chip label={batchDetails.batchType} size="small" />
                        <Chip label={batchDetails.finishingType} size="small" />
                      </Stack>
                      <Typography fontSize={14}>
                        Scheduled At: {formatTime(batchDetails.loadingTime)}
                      </Typography>
                      {batchDetails.actualStartTime && (
                        <Typography fontSize={14}>
                          Loaded At: {formatTime(batchDetails.actualStartTime)}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" flexWrap="wrap" alignItems="center">
                  <SmallStatCard
                    title="Machine"
                    content={`${batchDetails.machineInfo.name} (${(
                      (batchDetails.totalFabricWeight /
                        batchDetails.machineInfo.maxCapacity) *
                      100
                    ).toFixed()} %)`}
                  />
                  <SmallStatCard
                    title="STD. Runtime"
                    content={milisecondsToTime(totalStandardTime())}
                  />
                  <SmallStatCard
                    title="EST. Completion"
                    content={formatTime(batchDetails.estEndTime)}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item md={6.5} sm={2}>
              <Stack
                width="100%"
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpen(true);
                  }}>
                  <Stack direction="row" spacing={0.5}>
                    <Typography>Print</Typography>
                    <PrintIcon />
                  </Stack>
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <BatchAccountOrderDetails
            orderList={batchDetails?.orderInfo}
            fabricList={batchDetails?.fabricInfo}
          />
          <BatchTaskDetails
            taskList={batchDetails?.taskInfo}
            recipeList={batchDetails?.recipeInfo}
          />
          <BatchAccountTranscript
            open={open}
            setOpen={setOpen}
            batchDetails={batchDetails}
          />
        </Stack>
      ) : (
        <Stack
          justifyContent="center"
          width="100%"
          height="100%"
          alignItems="center">
          <CircularProgress />
        </Stack>
      )}
    </Stack>
  );
};

export default BatchAccountDetails;
