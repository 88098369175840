import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  // InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { OrderTranscationAmountValidator } from '../../validators/order';
import Button from '../../widgets/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { successNoitif } from '../../utils/notification';
import { createTransaction } from '../../api/order';
import { convertNumberToWords, formatNumber } from '../../utils/number';
import { useNavigate } from 'react-router-dom';

const DeliveryBillTransactionModal = ({
  show,
  toggleModal,
  accountInfo,
  deliveryInfo,
  checked,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [isTypeValid, setIsTypeValid] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  let navigate = useNavigate();

  const calculateTotalQuantity = (fabric) => {
    let totalQuantity = 0;

    fabric.orderDeliveryFabricBatches.map((batch) => {
      totalQuantity += batch.netQuantity;
    });

    return totalQuantity;
  };

  const calculateTotalNetQuantity = () => {
    let totalQuantity = 0;
    deliveryInfo.orderDeliveryFabrics.map((fabric) => {
      totalQuantity += calculateTotalQuantity(fabric);
    });
    return totalQuantity;
  };

  const calculateTotalAmount = (fabric) => {
    let totalAmount = 0;
    if (checked) {
      fabric.orderDeliveryFabricBatches.map((batch) => {
        totalAmount += batch.netQuantity * batch.fabricRate;
      });
    } else {
      fabric.orderDeliveryFabricBatches.map((batch) => {
        totalAmount += batch.deliveryQuantity * batch.fabricRate;
      });
    }
    return totalAmount;
  };

  const calculateFullAmount = () => {
    let totalAmount = 0;

    deliveryInfo.orderDeliveryFabrics.map((fabric) => {
      totalAmount += calculateTotalAmount(fabric);
    });

    return totalAmount;
  };

  const onClose = () => {
    handleCreationFormClose();
  };
  const resolver = yupResolver(OrderTranscationAmountValidator);
  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      transactionAmount: 0,
      transactionDate: new Date(),
      transactionReason: '',
      paymentby: 'CASH',
      chequeDate: new Date(),
    },
    resolver,
  });

  const transactionAmount = useWatch({ control, name: 'transactionAmount' });
  const transactionDate = useWatch({ control, name: 'transactionDate' });
  const chequeDate = useWatch({ control, name: 'chequeDate' });
  const transactionStatus = useWatch({ control, name: 'transactionStatus' });
  const transactionReason = useWatch({ control, name: 'transactionReason' });
  const paymentBy = useWatch({ control, name: 'paymentBy' });
  const handleCreationFormClose = () => {
    reset({
      transactionAmount: 0,
      transactionDate: new Date(),
      transactionStatus: 'DUE ADJUSTMENT',
      transactionReason: '',
    });

    toggleModal(false);
  };

  useEffect(() => {
    if (transactionAmount > calculateDue() || transactionAmount <= 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [transactionAmount]);

  useEffect(() => {
    if (transactionStatus === 'OTHER' && transactionReason === '') {
      setIsTypeValid(false);
    } else {
      setIsTypeValid(true);
    }
  }, [transactionStatus, transactionReason]);

  useEffect(() => {
    const date = new Date();
    setValue('transactionDate', date);
    setValue('transactionStatus', 'DUE ADJUSTMENT');
    setValue('transactionReason', '');
  }, []);

  const handleTransactionChangeSubmission = (data) => {
    setTransactionData(data);
    setShowConfirmation(true);
    toggleModal(false);
  };

  const onCloseConfirmation = () => {
    handleCloseConfirmation();
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handelConfirmation = async () => {
    const formData = {
      uid: accountInfo.uid,
      transactionAmount,
      transactionDate,
      transactionType: transactionStatus,
      transactionReason,
      paymentBy: transactionData?.paymentBy,
      chequeDate: transactionData?.chequeDate,
      chequeNo: transactionData?.chequeNo,
      bankName: transactionData?.bankName,
      deliveryBillId: deliveryInfo?.id,
      quantityChoice: checked ? 'NET' : 'GROSS',
      totalDeliveryAmount: calculateFullAmount(),
    };
    const response = await createTransaction(formData);

    if (response.status === 200) {
      successNoitif('Transaction Created Successfully');
      navigate(-1);
      const date = new Date();
      setValue('transactionDate', date);
      setValue('transactionStatus', 'DUE ADJUSTMENT');
      reset({
        transactionAmount: 0,
        transactionDate: date,
        transactionStatus: 'DUE ADJUSTMENT',
        transactionReason: '',
      });

      setShowConfirmation(false);
    }
  };

  const calculateDue = () => {
    const due = calculateFullAmount() - deliveryInfo?.paidAmount;
    return due;
  };

  return (
    <>
      <Dialog
        onClose={handleCreationFormClose}
        open={show}
        fullWidth
        maxWidth="sm"
        width="40%"
        yesText="Save Information"
        noHandler={onClose}>
        <DialogTitle id="simple-dialog-title">Add Transaction</DialogTitle>
        <form>
          <Stack
            sx={{
              paddingLeft: 3,
              paddingRight: 3,
              paddingTop: 3,
              paddingBottom: 3,
            }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
            <Stack
              sx={{
                padding: 1,
                border: '1px solid gray',
                borderStyle: 'dashed',
              }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4.5}>
                  <Typography sx={{ textAlign: 'start' }}>
                    Total Gross Quantity:
                  </Typography>
                </Grid>
                <Grid width="100%" item xs={4.5}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.2 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700} sx={{ textAlign: 'end' }}>
                    {deliveryInfo?.totalDeliveryQuantity}(KG)
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography sx={{ textAlign: 'start' }}>
                    Total Net Quantity:
                  </Typography>
                </Grid>
                <Grid width="100%" item xs={5}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.2 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700} sx={{ textAlign: 'end' }}>
                    {calculateTotalNetQuantity()}(KG)
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography sx={{ textAlign: 'start' }}>
                    Total Amount:
                  </Typography>
                </Grid>
                <Grid width="100%" item xs={6}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.2 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700} sx={{ textAlign: 'end' }}>
                    ৳{formatNumber(calculateFullAmount())}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ textAlign: 'start' }}>
                    Paid Amount:
                  </Typography>
                </Grid>
                <Grid width="100%" item xs={6}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.2 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700} sx={{ textAlign: 'end' }}>
                    ৳{formatNumber(deliveryInfo?.paidAmount)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.5 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography sx={{ textAlign: 'start' }}>
                    Payable Amount:
                  </Typography>
                </Grid>
                <Grid width="100%" item xs={5.5}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.2 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700} sx={{ textAlign: 'end' }}>
                    ৳{formatNumber(calculateDue())}
                  </Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography sx={{ textAlign: 'start' }}>
                    Received Amount:
                  </Typography>
                </Grid>
                <Grid width="100%" item xs={5.5}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.2 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700} sx={{ textAlign: 'end' }}>
                    ৳{formatNumber(transactionAmount)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.5 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ textAlign: 'start' }}>Due:</Typography>
                </Grid>
                <Grid width="100%" item xs={7}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.2 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={700} sx={{ textAlign: 'end' }}>
                    ৳{formatNumber(calculateDue() - transactionAmount)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack width="100%" sx={{ background: 'gray', opacity: 0.5 }}>
                    <Divider></Divider>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack width="100%">
                    <Typography>In Words,</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}>
                    <Typography>Total Payable:</Typography>
                    <Typography fontSize="small">
                      {convertNumberToWords(calculateDue())} Only
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}>
                    <Typography>Received amount:</Typography>
                    <Typography fontSize="small">
                      {convertNumberToWords(transactionAmount)}
                    </Typography>
                    {transactionAmount > 0 ? (
                      <Typography pl={0.2} fontSize="small">
                        Only
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item xs={5}>
                <Typography>Received Amount:</Typography>
              </Grid>
              <Grid item xs={7}>
                <Stack>
                  <TextField
                    size="small"
                    required
                    placeholder="Received Amount"
                    {...register('transactionAmount')}
                    error={
                      errors?.transactionAmount ||
                      transactionAmount > calculateDue()
                    }
                    helperText={
                      errors?.transactionAmount?.message ||
                      transactionAmount > calculateDue()
                        ? 'transaction amount is greater than due'
                        : ''
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
            {/* transaction date  */}
            <Grid
              container
              irection="row"
              justifyContent="center"
              alignItems="center">
              <Grid item xs={5}>
                <Typography>Transaction Date:</Typography>
              </Grid>
              <Grid item xs={7}>
                <Stack>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      maxDate={new Date()}
                      value={transactionDate}
                      onChange={(value) => setValue('transactionDate', value)}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          type="date"
                          {...params}
                          style={{ width: '100%' }}
                          error={errors?.transactionDate}
                          helperText={errors?.transactionDate?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item xs={5}>
                <Typography>Transaction Type:</Typography>
              </Grid>
              <Grid item xs={7}>
                <Stack>
                  <FormControl>
                    <Select
                      size="small"
                      control={control}
                      defaultValue="DUE ADJUSTMENT"
                      labelId="type123"
                      id="demo-simple-select"
                      {...register('transactionStatus')}
                      placeholder="Select Transaction Type">
                      <MenuItem value="DUE ADJUSTMENT">Due Adjustment</MenuItem>
                      <MenuItem value="ADVANCE">Advance</MenuItem>
                      <MenuItem value="OTHER">Other</MenuItem>
                    </Select>
                    {errors?.transactionStatus && (
                      <FormHelperText>
                        {errors?.transactionStatus?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
            {transactionStatus === 'OTHER' ? (
              <>
                <Stack mt={2} width="100%">
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography>Transaction reason:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Stack>
                        <TextField
                          style={{ width: '100%' }}
                          multiline
                          rows={3}
                          placeholder="reason"
                          {...register('transactionReason')}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </>
            ) : (
              <></>
            )}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item xs={5}>
                <Typography>Payment By:</Typography>
              </Grid>
              <Grid item xs={7}>
                <Stack>
                  <FormControl>
                    <Select
                      size="small"
                      control={control}
                      defaultValue="CASH"
                      labelId="type123"
                      id="demo-simple-select"
                      {...register('paymentBy')}
                      placeholder="Select Payment way">
                      <MenuItem value="CASH">Cash</MenuItem>
                      <MenuItem value="CHEQUE">Cheque</MenuItem>
                    </Select>
                    {errors?.paymentBy && (
                      <FormHelperText>
                        {errors?.paymentBy?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
            {paymentBy === 'CHEQUE' ? (
              <>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid item xs={5}>
                    <Typography>Bank Name:</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Stack>
                      <TextField
                        size="small"
                        required
                        placeholder="Enter Bank Name"
                        {...register('bankName')}
                        error={errors?.bankName}
                        helperText={errors?.bankName?.message}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid item xs={5}>
                    <Typography>Cheque No:</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Stack>
                      <TextField
                        size="small"
                        required
                        placeholder="Enter Cheque No"
                        {...register('chequeNo')}
                        error={paymentBy === 'CHEQUE' && errors?.chequeNo}
                        helperText={
                          paymentBy === 'CHEQUE' && errors?.chequeNo?.message
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  container
                  irection="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid item xs={5}>
                    <Typography>Cheque Date:</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Stack>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          // maxDate={new Date()}
                          value={chequeDate}
                          onChange={(value) => setValue('chequeDate', value)}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              type="date"
                              {...params}
                              style={{ width: '100%' }}
                              error={errors?.chequeDate}
                              helperText={errors?.chequeDate?.message}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Stack>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 3, paddingBottom: 3 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red' }}
              color="error"
              onClick={handleCreationFormClose}>
              Cancel
            </Button>
            <Tooltip
              title={
                isValid
                  ? ''
                  : `Transaction ammount must be positive & can not be greater than ${accountInfo?.totalAmount}BDT`
              }>
              <Stack>
                <Button
                  variant="contained"
                  disabled={!isDirty || !isValid || !isTypeValid ? true : false}
                  onClick={handleSubmit(handleTransactionChangeSubmission)}>
                  submit
                </Button>
              </Stack>
            </Tooltip>
          </Stack>
        </form>
      </Dialog>
      <Stack>
        <Dialog
          onClose={onCloseConfirmation}
          open={showConfirmation}
          maxWidth="sm">
          <DialogTitle id="simple-dialog-title">
            Are you sure you want to add this transaction?
          </DialogTitle>
          <Stack
            direction="row"
            gap={1}
            justifyContent="flex-end"
            sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button
              variant="outlined"
              sx={{ borderColor: 'red', color: 'red' }}
              onClick={() => {
                setShowConfirmation(false);
                toggleModal(true);
              }}>
              No
            </Button>
            <Button variant="contained" onClick={handelConfirmation}>
              Yes
            </Button>
          </Stack>
        </Dialog>
      </Stack>
    </>
  );
};

export default DeliveryBillTransactionModal;
