import {
  Stack,
  CircularProgress,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Button,
} from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AllDeliveryListColumns } from '../../constants/table-column/client';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Link } from 'react-router-dom';
import { ORDER_DELIVERY } from '../../constants/routeUrls';
import { fetchSingleOrderDeliveries } from '../../api/order';
import NoDataOverlay from '../global/NoDataOverlay';
const DeliveryTable = ({ orderInfo }) => {
  const [deliveryList, setDeliveryList] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const [DeliveryStatusTextField, setDeliveryStatusTextField] = useState(0);
  const [deliveryTypeTextField, setDeliveryTypeTextField] = useState(0);
  const [deliveryStatus, setDeliveryStatus] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [deliveryNumber, setDeliveryNumber] = useState('');
  const textInput = React.useRef(null);
  const fetchDeliveryList = async ({
    deliveryNumber,
    deliveryStatus,
    page,
    pageSize,
  }) => {
    setIsloading(true);

    const response = await fetchSingleOrderDeliveries({
      fcNumber: orderInfo.fcNumber,
      searchKey: deliveryNumber,
      status: deliveryStatus,
      page: page,
      pageSize: pageSize,
    });
    setDeliveryList(response.deliveries);
    setTotalEntries(response.total);
    setIsloading(false);
  };

  const resetDeliverySearch = () => {
    setDeliveryStatus('');
    setDeliveryStatusTextField(0);
    textInput.current.value = '';
    setDeliveryTypeTextField(0);
    setDeliveryNumber('');
    fetchDeliveryList('', '', page, pageSize);
  };

  const handleDeliverySubmit = (event) => {
    event.preventDefault();
    setDeliveryNumber(deliveryNumber);

    fetchDeliveryList({ deliveryNumber, deliveryStatus, page, pageSize });
  };

  useEffect(() => {
    if (orderInfo?.fcNumber) {
      (async () => {
        await fetchDeliveryList({});
      })();
    }
  }, [orderInfo?.fcNumber]);

  useEffect(() => {
    if (orderInfo?.fcNumber) {
      (async () =>
        await fetchDeliveryList({
          deliveryNumber,
          deliveryStatus,
          page,
          pageSize,
        }))();
    }
  }, [page, pageSize]);

  return (
    <>
      <Stack mt={3}>
        {!deliveryList ? (
          <Stack
            justifyContent="center"
            width="100%"
            height="100%"
            alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack>
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="h6" sx={{ fontWeight: 'bold' }} pb={2}>
                Delivery Summary
              </Typography>
              <Button
                variant="contained"
                component={Link}
                to={`${ORDER_DELIVERY}/${orderInfo?.fcNumber}`}>
                Create Delivery
              </Button>
            </Stack>

            <Stack my={1}>
              <form onSubmit={handleDeliverySubmit}>
                <Stack
                  mt={2}
                  direction={{ md: 'row', xs: 'column', sm: 'row' }}
                  width="100%"
                  spacing={2}
                  alignItems="left"
                  justifyContent="left">
                  <TextField
                    size="small"
                    onChange={(event) => setDeliveryNumber(event.target.value)}
                    inputRef={textInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    flexWrap="wrap"
                    fullWidth
                    id="outlined-basic"
                    label="Search"
                    sx={{ maxWidth: 300 }}
                    variant="outlined"
                  />
                  <FormControl fullWidth sx={{ maxWidth: 200 }}>
                    <InputLabel id="SearchStatus">Search Status</InputLabel>
                    <Select
                      size="small"
                      defaultValue={DeliveryStatusTextField}
                      value={deliveryTypeTextField}
                      labelId="SearchStatus"
                      id="demo-simple-select"
                      label="Search Status"
                      placeholder="Search Status"
                      onChange={(event) => {
                        setDeliveryStatus(event.target.value);

                        setDeliveryTypeTextField(event.target.value);
                      }}>
                      <MenuItem value="WAITING FOR APPROVAL">
                        Waiting for approval
                      </MenuItem>
                      <MenuItem value="IN QUEUE">In Queue</MenuItem>
                      <MenuItem value="DELIVERED">Delivered</MenuItem>
                      <MenuItem value="REJECTED">Rejected</MenuItem>
                    </Select>
                  </FormControl>
                  <Stack direction={{ md: 'row', xs: 'row' }} spacing={2}>
                    <IconButton
                      title="Edit"
                      aria-label="edit"
                      type="submit"
                      sx={{
                        bgcolor: '#171820',
                        color: 'white',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#303030' },
                      }}>
                      <SearchIcon />
                    </IconButton>

                    {/* reset button  */}
                    <IconButton
                      title="Edit"
                      aria-label="edit"
                      onClick={() => resetDeliverySearch()}
                      sx={{
                        bgcolor: '#171820',
                        color: 'white',
                        borderRadius: 1,
                        ':hover': { bgcolor: '#303030' },
                      }}>
                      <RestartAltIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </form>
            </Stack>
            <Box
              width="100%"
              height={300}
              sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <DataGrid
                columns={AllDeliveryListColumns}
                rows={deliveryList}
                rowCount={totalEntries}
                getRowId={(row) => row.id}
                page={page}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                paginationMode="server"
                filterMode="server"
                onPageChange={(value) => setPage(value)}
                onPageSizeChange={(value) => setPageSize(value)}
                loading={isLoading}
                components={{
                  NoRowsOverlay: NoDataOverlay,
                }}
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default DeliveryTable;
